import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import nutritionDetailComponent from '@/components/nutritionDetail/nutritionDetail/index.vue';
import elementResumeComponent from '@/components/nutritionDetail/elementResume/index.vue';
import productResumeComponent from '@/components/nutritionDetail/productResume/index.vue';
import fertilizationService from '@/services/fertilizationService';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'nutrition-detail',
  components: {
    navbar,
    nutritionDetailComponent,
    elementResumeComponent,
    productResumeComponent,
    DxButton,
  },
  props: ['id'],
  data() {
    return {
      fertilization: {},
      dataLoaded: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    this.dataLoaded = false;
    fertilizationService.getById(this.id, this.currentPlantation.companyId).then((response) => {
      this.dataLoaded = true;
      this.fertilization = response;
    });
  },
  methods: {
    back() {
      this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
    },
  },
};
