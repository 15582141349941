import moment from 'moment-timezone';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    // OBSERVATIONS
    observations: [],
    idCurrentObservation: '',
    edit: false,
    // MAIN DATA
    date: moment().format('YYYY-MM-DD'),
    idGeolocationPoint: '',
    scoutingPoints: [],
    currentScoutingPoint: null,
    category: '',
    subcategory: '',
    categoriesOfTemplates: [],
    templatesByCategory: [],
    subcategoryName: '',
    // MULTIMEDIA
    multimedia: [],
    multimediaRemovedIds: [],
    file: '',
    fileDescription: '',
    // TEMPLATE FORM
    showTemplateForm: false,
    templateId: '',
    templateForm: [],
    templateValue: {},
    // OBSERVATIONS POINTS
    name: '',
    latitude: '',
    longitude: '',
    area: '',
    typePoint: '',
  },
  getters: {
    currentValuesMainData(state) {
      return {
        date: state.date, idGeolocationPoint: state.idGeolocationPoint, category: state.category, subcategory: state.subcategory, subcategoryName: state.subcategoryName,
      };
    },
  },
  mutations: {
    // OBSERVATIONS
    GET_OBSERVATIONS(state, value) {
      state.observations = value;
    },
    GET_OBSERVATION_DETAIL(state, value) {
      state.idCurrentObservation = value.id;
      state.idGeolocationPoint = value.point;
      state.date = value.date;
      state.category = value.category;
      state.subcategory = value.subcategory;
      state.templateValue = value.templateValue;
      state.multimedia = value.multimedia;
      state.templateForm = value.templateForm;
      state.subcategoryName = value.subcategoryName;
    },
    SET_ID_CURRENT_OBSERVATION(state, value) {
      state.idCurrentObservation = value;
    },
    // MAIN DATA
    SET_DATE(state, value) {
      state.date = value;
    },
    SET_GEOLOCATION(state, value) {
      state.idGeolocationPoint = value;
    },
    GET_SCOUTING_POINTS(state, value) {
      state.scoutingPoints = value;
    },
    SET_CATEGORY(state, value) {
      state.category = value;
    },
    SET_SUBCATEGORY(state, value) {
      state.subcategory = value;
      state.templateId = value;
    },
    GET_CATEGORIES_OF_TEMPLATES(state, value) {
      state.categoriesOfTemplates = value;
    },
    GET_TEMPLATES_BY_CATEGORY(state, value) {
      state.templatesByCategory = value;
    },
    GET_TEMPLATE_BY_ID(state, value) {
      state.templateForm = value;
    },
    SET_TEMPLATE_FORM(state, value) {
      state.templateForm = value;
    },
    // MULTIMEDIA
    SET_FILE(state, value) {
      state.file = value;
    },
    ADD_MULTIMEDIA(state, value) {
      state.multimedia.push(value);
    },
    SET_MULTIMEDIA(state, value) {
      state.multimedia = value;
    },
    SET_FILE_DESCRIPTION(state, value) {
      state.fileDescription = value;
    },
    // TEMPLATE FORM
    SET_SHOW_TEMPLATE_FORM(state, value) {
      state.showTemplateForm = value;
    },
    SET_TEMPLATE_VALUE(state, value) {
      state.templateValue = value;
    },
    // OBSERVATIONS POINTS
    SET_NAME(state, value) {
      state.name = value;
    },
    SET_LATITUDE(state, value) {
      state.latitude = value;
    },
    SET_LONGITUDE(state, value) {
      state.longitude = value;
    },
    SET_AREA(state, value) {
      state.area = value;
    },
    SET_TYPE_POINT(state, value) {
      state.typePoint = value;
    },
    SET_EDIT(state, value) {
      state.edit = value;
    },
    setCurrentScoutingPoint(state, newScoutingPoint) {
      state.currentScoutingPoint = newScoutingPoint;
    },
    resetCurrentScoutingPoint(state) {
      state.currentScoutingPoint = null;
    },
    removeFile(state, value) {
      if (typeof value.name !== 'undefined') {
        state.multimediaRemovedIds.push(value.name);
        state.multimedia = state.multimedia.filter(
          (file) => file.name !== value.name,
        );
      } else {
        state.multimedia = state.multimedia.filter(
          (file) => file.tempName !== value.tempName,
        );
      }
    },
    clearMultimediaRemovedIds(state) {
      state.multimediaRemovedIds = [];
    },
  },
  actions: {
    async getObservations({ commit }, { companyId, currentPlantationId }) {
      try {
        const xhr = await Api.getObservations(companyId, currentPlantationId);
        const observations = JSON.parse(xhr.response).data;
        const observationsFormatted = [];
        for (const observation of observations) {
          let coordinates = [];
          let pointName = '';
          if (observation.scouting_point.type === 'POINT') {
            coordinates = [observation.scouting_point.longitude, observation.scouting_point.latitude];
            pointName = observation.scouting_point.name;
          }
          observationsFormatted.push({
            id: observation.id,
            date: observation.date,
            category: observation.category,
            subcategory: observation.subcategory,
            coordinates,
            pointName,
          });
        }
        commit('GET_OBSERVATIONS', observationsFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getObservationDetail({ commit }, observationToDetail) {
      try {
        const xhr = await Api.getObservationDetail(observationToDetail.currentPlantation.companyId, observationToDetail.idCurrentObservation);
        const observationDetail = JSON.parse(xhr.response);
        const templateValue = {};
        for (const fieldForm of observationDetail.template_form) {
          templateValue[fieldForm.field] = observationDetail[fieldForm.field];
        }
        const observationDetailFormatted = {
          id: observationDetail.id,
          point: observationDetail.point,
          date: observationDetail.date,
          category: observationDetail.category,
          subcategory: observationDetail.template,
          templateValue,
          multimedia: observationDetail.multimedia,
          templateForm: observationDetail.template_form,
          subcategoryName: observationDetail.subcategory,
        };
        commit('GET_OBSERVATION_DETAIL', observationDetailFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createObservation({ state }, currentPlantation) {
      try {
        await Api.createNewObservation(
          currentPlantation.companyId,
          {
            ...{
              plantation: currentPlantation.id,
              scouting_point: state.idGeolocationPoint,
              date: state.date,
              multimedia: state.multimedia,
              template: state.templateId,
            },
            ...state.templateValue,
          },
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateObservation({ state, commit }, currentPlantation) {
      try {
        const multimedia = [];
        for (const file of state.multimedia) {
          if (file.attachment.split(':')[0] === 'data') {
            multimedia.push(file);
          }
        }
        await Api.updateObservation(
          currentPlantation.companyId,
          state.idCurrentObservation,
          {
            ...{
              plantation: currentPlantation.id,
              scouting_point: state.idGeolocationPoint,
              date: state.date,
              multimedia,
            },
            ...state.templateValue,
          },
        );
        for (const file of state.multimediaRemovedIds) {
          Api.removeObservationFile(currentPlantation.companyId, state.idCurrentObservation, file);
        }
        commit('clearMultimediaRemovedIds');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteObservation(context, observationToDelete) {
      try {
        await Api.deleteObservation(observationToDelete.companyId, observationToDelete.observationId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getScoutingPoints({ commit }, currentPlantation) {
      try {
        const xhr = await Api.getScoutingPoints(currentPlantation.companyId, currentPlantation.id);
        const scoutingPoints = JSON.parse(xhr.response).data;
        const scoutingPointsFormatted = [];
        for (const scoutingPoint of scoutingPoints) {
          scoutingPointsFormatted.push({
            id: scoutingPoint.id,
            name: scoutingPoint.name,
            type: scoutingPoint.type,
          });
        }
        commit('GET_SCOUTING_POINTS', scoutingPointsFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getCategoriesOfTemplates({ commit }) {
      try {
        const xhr = await Api.getCategoriesOfTemplates();
        const categoriesOfTemplates = JSON.parse(xhr.response).data;
        commit('GET_CATEGORIES_OF_TEMPLATES', categoriesOfTemplates);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTemplatesByCategory(context, category) {
      try {
        const xhr = await Api.getTemplatesByCategory(category);
        const templatesByCategory = JSON.parse(xhr.response).data;
        const templatesByCategoryFormatted = [];
        for (const templateByCategory of templatesByCategory) {
          templatesByCategoryFormatted.push({
            id: templateByCategory.id,
            category: templateByCategory.category,
            subcategory: templateByCategory.subcategory,
          });
        }
        context.commit('GET_TEMPLATES_BY_CATEGORY', templatesByCategoryFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTemplateById({ dispatch, commit }, id) {
      commit('SET_SHOW_TEMPLATE_FORM', false);
      try {
        const xhr = await Api.getTemplateById(id);
        const templateById = JSON.parse(xhr.response).form;
        dispatch('formattingTemplate', templateById);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    formattingTemplate(context, template) {
      const templateByIdFormatted = [];
      for (const itemForm of template) {
        let editorOptions = null;
        let type = '';
        switch (itemForm.type) {
          case 'INT':
            type = 'dxNumberBox';
            editorOptions = {
              value: context.state.templateValue[itemForm.field],
              max: itemForm.max_value,
              min: itemForm.min_value,
              mode: 'number',
            };
            break;
          case 'FLOAT':
            type = 'dxNumberBox';
            editorOptions = {
              value: context.state.templateValue[itemForm.field],
              max: itemForm.max_value,
              min: itemForm.min_value,
              format: `${'#'.repeat([(15 - itemForm.decimal_places)])}0.${'#'.repeat([itemForm.decimal_places])}`,
              mode: 'number',
            };
            break;
          case 'BOOLEAN':
            type = 'dxCheckBox';
            editorOptions = {
              value: itemForm.default,
            };
            break;
          case 'DATE':
            type = 'dxDateBox';
            editorOptions = {
              value: moment().format('YYYY-MM-DD'),
              displayFormat: 'dd/MM/yyyy',
            };
            break;
          case 'DATETIME':
            type = 'dxDateBox';
            editorOptions = {
              type: 'datetime',
              value: moment().format('YYYY-MM-DD HH:mm'),
              displayFormat: 'dd/MM/yyyy HH:mm',
            };
            break;
          case 'SELECT':
            type = 'dxSelectBox';
            editorOptions = {
              items: itemForm.choices,
              value: context.state.templateValue[itemForm.field],
              searchEnabled: true,
              searchTimeout: 200,
              minSearchLength: 0,
              showDataBeforeSearch: false,
            };
            break;
          case 'LIST':
            type = 'dxTagBox';
            editorOptions = {
              dataSource: itemForm.choices,
              value: context.state.templateValue[itemForm.field],
              showSelectionControls: true,
              applyValueMode: 'useButtons',
              searchEnabled: true,
              searchTimeout: 200,
              minSearchLength: 0,
              showDataBeforeSearch: false,
            };
            break;
          case 'STRING':
          default:
            type = 'dxTextBox';
            editorOptions = {
              value: context.state.templateValue[itemForm.field],
              maxLength: itemForm.max_length,
              minLength: itemForm.min_length,
            };
            break;
        }
        templateByIdFormatted.push({
          field: itemForm.field,
          description: itemForm.description,
          required: itemForm.required,
          allowNull: itemForm.allow_null,
          allowBlank: itemForm.allow_blank,
          type,
          editorOptions,
        });
      }
      context.commit('GET_TEMPLATE_BY_ID', templateByIdFormatted);
      context.commit('SET_SHOW_TEMPLATE_FORM', true);
    },
    async createPointObservation({ state }, currentPlantation) {
      try {
        await Api.createPointObservation(currentPlantation.companyId,
          {
            name: state.name,
            type: state.typePoint,
            plantation: currentPlantation.id,
            latitude: state.latitude,
            longitude: state.longitude,
            area: state.area,
          });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setDate(context, value) {
      context.commit('SET_DATE', value);
    },
    setGeolocation(context, value) {
      context.commit('SET_GEOLOCATION', value);
    },
    setCategory(context, value) {
      context.commit('SET_CATEGORY', value);
    },
    setSubCategory(context, value) {
      context.commit('SET_SUBCATEGORY', value);
    },
    setTemplateValue(context, value) {
      context.commit('SET_TEMPLATE_VALUE', value);
    },
    setFile(context, value) {
      context.commit('SET_FILE', value);
    },
    setIdCurrentObservation(context, value) {
      context.commit('SET_ID_CURRENT_OBSERVATION', value);
    },
    addMultimedia(context, value) {
      context.commit('ADD_MULTIMEDIA', value);
    },
    initStore({ commit }) {
      commit('SET_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_GEOLOCATION', '');
      commit('SET_CATEGORY', '');
      commit('SET_SUBCATEGORY', '');
      commit('GET_CATEGORIES_OF_TEMPLATES', []);
      commit('GET_TEMPLATES_BY_CATEGORY', []);
      commit('SET_MULTIMEDIA', []);
      commit('SET_TEMPLATE_FORM', []);
    },
    setName(context, value) {
      context.commit('SET_NAME', value);
    },
    setLatitude(context, value) {
      context.commit('SET_LATITUDE', value);
    },
    setLongitude(context, value) {
      context.commit('SET_LONGITUDE', value);
    },
    setArea(context, value) {
      context.commit('SET_AREA', value);
    },
    setTypePoint(context, value) {
      context.commit('SET_TYPE_POINT', value);
    },
    setFileDescription(context, value) {
      context.commit('SET_FILE_DESCRIPTION', value);
    },
    setEdit(context, value) {
      context.commit('SET_EDIT', value);
    },
    setShowTemplateForm(context, value) {
      context.commit('SET_SHOW_TEMPLATE_FORM', value);
    },
    async fetchCurrentScoutingPoint({ commit }, { companyId, scoutingPointId }) {
      try {
        const xhr = await Api.getScoutingPointById(companyId, scoutingPointId);
        commit('setCurrentScoutingPoint', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    resetCurrentScoutingPoint({ commit }) {
      commit('resetCurrentScoutingPoint');
    },
    removeFile(context, value) {
      context.commit('removeFile', value);
    },
  },
};
