import {
  DxChart,
  DxSeries,
  DxTooltip,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
} from 'devextreme-vue/chart';
import { mapGetters } from 'vuex';

export default {
  name: 'plan-compost',
  components: {
    DxChart,
    DxTooltip,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
  },
  props: [],
  data() {
    return {
      planCompostSerie: [],
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
  },
  mounted() {
    this.stepData.dosageProduct.forEach((item, index) => {
      const itemValue = this.stepData.timeValues[index];
      for (const prop in itemValue) {
        this.planCompostSerie.push({
          productName: item.name,
          monthWeek: prop,
          value: itemValue[prop],
        });
      }
    });
  },
  methods: {
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${point.value} kg/ha</span> `,
      };
    },
  },
};
