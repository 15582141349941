<template>
  <div class="main-gis-view-clients">
    <div class="content-gis-map">
      <div
        v-if="(treeLocations.length !== 0)"
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
      <div
        v-else
        style="display: block; text-align: center;"
      >
        {{ $t('GISGeoJsonMap_not_locations') }}
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISViewClients',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '65vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      marker: null,
      initialDate: Date.now(),
      plantationSelectedId: '',
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['plantations']),
  },
  mounted() {
    if (this.treeLocations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            this.layersEvents(layer);
            this.centerMapGeoJsonLayer(layer);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
              this.layersEvents(layer2, geoData.properties.owner_Farm);
            }
          }
        }
        this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        this.setMarker();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
    },

    layersEvents(target, client) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            self.openLayerDataPopup(e.target.feature.properties, e, client);
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    setMarker() {
      this.marker = new L.Marker({
        lat: this.center.latitude,
        lng: this.center.longitude,
      },
      {
        draggable: false,
      }).addTo(this.map);
      const redIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      this.marker.setIcon(redIcon);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="fa fa-home" style="line-height:1.65 blue;"></i>',
            zoomHomeTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
              `${controlName}-home`, container, this.zoomHome);

            return container;
          },

          zoomHome() {
            self.map.setView(self.marker.getLatLng());
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openLayerDataPopup(properties, e, client) {
      if (typeof properties.plantation_id !== 'undefined') {
        this.plantationSelectedId = properties.plantation_id;
        let htmlString = "<div class='popup-content'>";
        let listHtml = '<ul>';
        listHtml += `<li>${this.$t('client')}: ${client}</li>
        <li>${this.$t('Explotation')}: ${properties.explotation}</li>
        <li>${this.$t('Parcel')}: ${properties.parcel}</li>
        <li>${this.$t('Sector')}: ${properties.sector}</li>
        <li>${this.$t('Plantation')}: ${properties.plantation_name}</li>`;
        listHtml += '</ul>';
        listHtml += `<button id="go-button">${this.$t('Go')}</button>`;
        htmlString += `${listHtml}</div>`;
        this.openPopup(htmlString, e.latlng);
      }
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
      const button = document.getElementById('go-button');
      button.addEventListener('click', () => {
        this.goToMainMenu();
      });
    },
    goToMainMenu() {
      for (const plantation in this.plantations) {
        if (Object.hasOwnProperty.call(this.plantations, plantation)) {
          const currentPlantation = this.plantations[plantation];
          if (currentPlantation.id === this.plantationSelectedId) {
            const data = {
              companyId: currentPlantation.company_id,
              plantationId: this.plantationSelectedId,
            };
            this.getPlantationById(data);
            this.setCurrentPlantation({
              id: currentPlantation.id,
              companyId: currentPlantation.company_id,
              company: currentPlantation.company,
              name: currentPlantation.name,
              province: currentPlantation.province,
              explotation: currentPlantation.explotation,
              parcel: currentPlantation.parcel,
              sector: currentPlantation.sector,
              campaign: currentPlantation.campaign,
              variety: currentPlantation.variety,
              initDate: currentPlantation.init_date,
              endDate: currentPlantation.end_date,
              species: currentPlantation.species,
              area: currentPlantation.area,
              coordinates: currentPlantation.coordinates,
              sectorId: currentPlantation.sector_id,
            });
            this.$f7.views.main.router.navigate('/searcher/home/', { reloadCurrent: true });
          }
        }
      }
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
