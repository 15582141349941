import {
  DxDataGrid, DxColumn, DxScrolling, DxStateStoring,
} from 'devextreme-vue/data-grid';
import { mapState, mapGetters, mapActions } from 'vuex';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';
import { fertilizationService, fertilizationStatus } from '../../../services/fertilizationService';

export default {
  name: 'nutrition-plan-list',
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxButton,
    DxStateStoring,
  },
  props: {
    calledFromPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newFormattedFertilization: [],
      loaded: false,
      path: '/searcher/home/',
    };
  },
  computed: {
    ...mapGetters('fertilizationStore', ['fertilization']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('nutritionStepper', ['stepData']),
  },
  async beforeMount() {
    if (this.isPlantationSelected) {
      try {
        const data = {
          companyId: this.currentPlantation.companyId,
          plantationId: this.currentPlantation.id,
        };
        await this.getPlantationById(data);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    }
  },
  async mounted() {
    try {
      await this.getFertilization({
        companyId: this.currentPlantation.companyId,
        id: this.currentPlantation.id,
      });
      await this.formattedFertilization();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    getNameStatus(status) {
      return this.$t(status);
    },
    getClassStatus(status) {
      switch (status) {
        case fertilizationStatus.ACTIVE:
          return 'label-success';
        case fertilizationStatus.CANCELLED:
          return 'label-danger';
        default:
        case fertilizationStatus.PLANNED:
          return 'label-primary';
      }
    },
    getStatusFertilization(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('nutritionalList.assets');
      } if (endDate < this.actualDate) {
        return this.$t('nutritionalList.archived');
      }
      return this.$t('nutritionalList.planned');
    },
    async addNutrition() {
      await this.resetWizard();
      this.$f7.views.main.router.navigate('/utilities/nutrition/nutrition-step-one',
        { reloadCurrent: true });
    },

    deleteItem(item) {
      const that = this;
      this.$f7.dialog.create({
        title: this.$t('Confirm_Delete_Title'),
        text: this.$t('Confirm_Delete_Text'),
        closeByBackdropClick: 'true',
        buttons: [
          {
            text: this.$t('No'),
          },
          {
            text: this.$t('Ok'),
            onClick() {
              fertilizationService.delete(item.id, that.currentPlantation.companyId).finally(() => {
                that.getFertilization({ companyId: that.currentPlantation.companyId, id: that.currentPlantation.id });
                that.$f7.views.main.router.navigate(that.path, { reloadCurrent: true });
              });
            },
          },
        ],
      }).open();

      // fertilizationService.getById(item.id,
      // this.currentPlantation.companyId).then((response) => {
      //   response.status = fertilizationStatus.CANCELLED;
      //   fertilizationService.delete(item.id).finally(() => {
      //     this.getFertilization();
      //   });
      // });
    },
    viewItem(item) {
      this.setCurrentFertilizationId(item.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-detail',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    editItem(item) {
      this.setIsPlantationSelected(false);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-step-one',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    formattedFertilization() {
      for (const fertilization of this.fertilization) {
        this.newFormattedFertilization.push({
          description: fertilization.description,
          cost: fertilization.cost,
          n: fertilization.n,
          p205: fertilization.p205,
          k20: fertilization.k20,
          ca0: fertilization.ca0,
          mg0: fertilization.mg0,
          s03: fertilization.s03,
          status: this.getStatusFertilization(moment(fertilization.init_date).format('YYYY-MM-DD'), moment(fertilization.end_date).format('YYYY-MM-DD')),
          id: fertilization.id,
          production: fertilization.production,
        });
      }
    },
    ...mapActions('nutritionStepper', ['resetWizard']),
    ...mapActions('fertilizationStore', ['getFertilization', 'setCurrentFertilizationId']),
    ...mapActions('Plantation', ['setIsPlantationSelected']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
  },
};
