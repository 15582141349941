<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.productsTitle')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block>{{ $t('Edit_product') }}</f7-block>
      <ProductForm
        :current-product="currentProduct"
        :product-families="productFamilies"
        :product-subfamilies="productSubfamilies"
        :toxicities="toxicities"
        :dose-units="doseUnits"
        :tipologies="tipologies"
      />
      <f7-row>
        <f7-col>
          <MainElements :main-elements="mainElements" />
        </f7-col>
        <f7-col>
          <AlterElements :alter-elements="alterElements" />
        </f7-col>
      </f7-row>
      <f7-row>
        <MicroElements :micro-elements="microElements" />
      </f7-row>
    </div>
    <f7-row v-if="loaded">
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToProducts"
        >
          {{ $t("Go_Back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="callToUpdateProduct"
        >
          {{ $t("Button_save_text") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ProductForm from '../../../components/configuration/products/productForm/index.vue';
import MainElements from '../../../components/configuration/products/mainElements/index.vue';
import AlterElements from '../../../components/configuration/products/alterElements/index.vue';
import MicroElements from '../../../components/configuration/products/microElements/index.vue';

export default {
  name: 'EditProduct',
  components: {
    navbar,
    ProductForm,
    MainElements,
    AlterElements,
    MicroElements,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    mainElements() {
      return [
        {
          NT: this.currentProduct.NT,
          P2015_Total: this.currentProduct.P2015_Total,
          K2O: this.currentProduct.K2O,
        },
      ];
    },
    alterElements() {
      return [
        {
          CaO: this.currentProduct.CaO,
          MgO: this.currentProduct.MgO,
          SO3: this.currentProduct.SO3,
        },
      ];
    },
    microElements() {
      return [
        {
          B: this.currentProduct.B,
          Cu: this.currentProduct.Cu,
          Fe: this.currentProduct.Fe,
          Mn: this.currentProduct.Mn,
          Zn: this.currentProduct.Zn,
          Se: this.currentProduct.Se,
          MO: this.currentProduct.MO,
          I: this.currentProduct.I,
        },
      ];
    },
    ...mapState('Product', ['currentProduct', 'productFamilies', 'productSubfamilies', 'toxicities', 'doseUnits', 'tipologies']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getProductById(this.currentProduct.id);
      await this.fetchFamilyProducts();
      await this.fetchSubfamilyProducts(this.currentProduct.family);
      await this.fetchToxicities();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async callToUpdateProduct() {
      if (this.currentProduct.code === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationCode'));
      } else if (this.currentProduct.name === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationName'));
      } else if (this.currentProduct.ecologic === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationEcologic'));
      } else if (this.currentProduct.family === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationFamily'));
      } else if (this.currentProduct.subfamily === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationSubfamily'));
      } else if (this.currentProduct.dosis.unit === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationSapunit'));
      } else if (this.currentProduct.dosis.max_dosis === null || typeof this.currentProduct.dosis.max_dosis === 'undefined' || this.currentProduct.dosis.max_dosis === 0) {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationMaxDose'));
      } else if (this.currentProduct.tipology === '') {
        this.$f7.dialog.alert(this.$t('StoreProduct_ValidationTipology'));
      } else {
        this.$f7.preloader.show();
        try {
          await this.updateProduct(this.currentProduct);
          this.$f7.views.main.router.navigate('/viewProductDetail/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    goToProducts() {
      this.initStore();
      this.$f7.views.main.router.navigate('/products/', { reloadCurrent: true });
    },
    ...mapActions('Product', ['updateProduct', 'initStore', 'fetchFamilyProducts', 'fetchSubfamilyProducts', 'fetchToxicities', 'getProductById']),
  },

};
</script>
