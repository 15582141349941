<template>
  <f7-page name="treatment-create-order">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.campaign} > ${currentPlantation.name} > ${$t('treatment.title')}`" />

    <Treatment-edit-order-component
      :id="id"
      @loaded="setHasLoaded"
    />
  </f7-page>
</template>
<script>
import navbar from '@/components/NavBar.vue';
import { mapState } from 'vuex';
import TreatmentEditOrderComponent from '../../../components/wizardTreatment/treatmentEditOrder/index.vue';

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'treatment-edit-order',
  components: {
    navbar,
    TreatmentEditOrderComponent,
  },
  props: ['id'],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    this.$f7.preloader.show();
  },
  methods: {
    setHasLoaded(loadValue) {
      this.loaded = loadValue;
      this.$f7.preloader.hide();
    },
  },

};

</script>
