import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import numeral from 'numeral';
import moment from 'moment';
import { treatmentOrderService } from '../../../services/treatmentOrderService';
import { keyValuesService } from '../../../services/keyValuesService';

const numeralFormat = '0,0.00';

export default {
  name: 'treatment-create-order',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxAutocomplete,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSummary,
    DxTotalItem,
    DxEditing,

  },
  props: [],
  data() {
    return {
      totalUnitCost: 0,
      totalTotal: 0,
      currentRow: 0,
      totalCost: 0,
      unitCost: 0,
      productVademecums: [],
      productResume: [],

      treatmentTypes: [
        this.$t('treatment.treatmentCreateOrder.form.foliar'),
        this.$t('treatment.treatmentCreateOrder.form.fertigacion'),
      ],
      dosages: [
        'kg-L / ha',
        'kg-L / 1000 L',
      ],
      productVademecumSelected: '',
      treatmentOrder: {
        description: '',
        dosage: '',
        date: '',
        plagueAgent: '',
        treatmentType: '',
        volume: 0,
        area: 1024,
      },
      currentAgent: '',
      productVademecumSelectedValue: '',
    };
  },
  computed: {
    formatedTotalUnitCost() {
      return parseFloat(this.totalUnitCost).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    formatedTotalTotal() {
      return parseFloat(this.totalTotal).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    dosageIsHA() {
      return this.treatmentOrder.dosage.toUpperCase() === 'KG-L/HA';
    },
    treatmentTypeIsFoliar() {
      return this.treatmentOrder.treatmentType.toUpperCase() === 'FOLIAR';
    },
    ...mapGetters('productFertilizerStore', ['productFertilizers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('treatmentStepper', ['treatmentProducts', 'treatmentProducts', 'treatmentProduct']),
    ...mapState('Vademecum', ['optionsPlagueFilter']),

  },
  async beforeMount() {
    await this.getOptionsPlagueFilter(this.currentPlantation.species);
  },
  created() {
    keyValuesService.getByFamily('TREATMENT_TYPE').then((response) => {
      this.treatmentTypes = response[0].values;
    });
    keyValuesService.getByFamily('DOSE_TYPE').then((response) => {
      this.dosages = response[0].values;
    });
    this.setArea();
  },
  methods: {
    treatmentOrderVolumeChanged() {
      this.$refs.refDataGrid.instance.saveEditData();
      this.calculateTotals();
    },
    calculateTotals() {
      let total = 0;
      let totalUnit = 0;
      // const rows = this.$refs.refDataGrid.instance.getVisibleRows();
      const rows = this.$refs.refDataGrid.instance.getDataSource()._items;
      rows.forEach((row) => {
        if (typeof row !== 'undefined') {
          total += row.totalCost;
          totalUnit += row.unitCost;
        }
      });
      this.totalUnitCost = totalUnit;
      this.totalTotal = total;
    },
    rowUpdated() {
      this.calculateTotals();
    },
    onCellPrepared(e) {
      if (e.rowType === 'data' && e.column.dataField === 'dosageTotalKg' && !this.dosageIsHA) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
      if (e.rowType === 'data' && e.column.dataField === 'dosageTotalL' && this.dosageIsHA) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
      if (e.rowType === 'data' && (e.column.dataField === 'totalCost' || e.column.dataField === 'unitCost' || e.column.dataField === 'name' || e.column.dataField === 'dosageRange' || e.column.dataField === 'dosageTotalKgL')) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
    },
    selectProductItem(e) {
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
      }
      if (e.previousValue !== this.productVademecumSelected.id) {
        this.productVademecumSelected = this.productVademecums
          .find((item) => item.id === e.value);
      }
    },
    selectProductItemAutoComplete(e) {
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
      }
      if (!e.event && e.previousValue !== this.productVademecumSelected.id) {
        this.productVademecumSelected = this.productVademecums
          .find((item) => item.id === e.value);
        this.productVademecumSelectedValue = this.productVademecumSelected.name;
      }
    },
    async addProductFertilizer() {
      if (typeof this.productVademecumSelected.id === 'undefined' || this.productVademecumSelected.id === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.product_validation'));
        return;
      }
      await this.fetchTreatmentProduct({
        id: this.productVademecumSelected.id,
        crop: this.currentPlantation.species,
        agent: this.currentAgent,
      });
      let dosageRange = '';

      if (this.treatmentProduct.dose !== null) {
        dosageRange = `${parseFloat(this.treatmentProduct.dose.min).toFixed(2)} - ${parseFloat(this.treatmentProduct.dose.max).toFixed(2)} (${this.treatmentProduct.dose.unit})`;
      }
      this.productResume.push({
        name: this.treatmentProduct.name,
        dosageRange,
        dosageTotalL: 0,
        dosageTotalKg: 0,
        price: 0,
        unitCost: 0,
        totalCost: 0,
      });
    },
    async onChangePlague(agent) {
      try {
        this.$f7.preloader.show();
        await this.fetchTreatmentProducts({ crop: this.currentPlantation.species, agent: agent.value });
        this.productVademecums = this.treatmentProducts;
        this.productVademecumSelected = '';
        this.productVademecumSelectedValue = '';
        this.currentAgent = agent.value;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    calculateDosageTotalKg(rowData) {
      if (this.dosageIsHA === false) {
        rowData.dosageTotalL = parseFloat(rowData.dosageTotalL.toString().replace(/,/g, ''));
        rowData.dosageTotalKg = numeral(parseFloat(rowData.dosageTotalL / 1000) * this.treatmentOrder.volume).format(numeralFormat);
        return numeral(rowData.dosageTotalKg).format(numeralFormat);
      }
      rowData.dosageTotalKg = numeral(rowData.dosageTotalKg).format(numeralFormat);
      return numeral(rowData.dosageTotalKg).format(numeralFormat);
    },
    calculateDosageTotalL(rowData) {
      if (this.dosageIsHA) {
        rowData.dosageTotalKg = parseFloat(rowData.dosageTotalKg.toString().replace(/,/g, ''));
        rowData.dosageTotalL = (rowData.dosageTotalKg * 1000) / this.treatmentOrder.volume;
        return numeral(rowData.dosageTotalL).format(numeralFormat);
      }
      rowData.dosageTotalL = numeral(rowData.dosageTotalL).format(numeralFormat);
      return numeral(rowData.dosageTotalL).format(numeralFormat);
    },
    calculateUnitCost(rowData) {
      rowData.unitCost = rowData.price === 0 ? 0 : numeral(rowData.dosageTotalKg).value() * rowData.price;
      return numeral(rowData.unitCost).format(numeralFormat);
    },
    calculateTotalCost(rowData) {
      rowData.totalCost = numeral(rowData.unitCost).value() * this.treatmentOrder.area;
      return numeral(rowData.totalCost)
        .format(numeralFormat);
    },
    calculateDosageTotalKGL(row) {
      return parseFloat(row.dosageTotalKg * this.treatmentOrder.area).toFixed(2);
    },
    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
    },
    validateForm() {
      if (this.treatmentOrder.description === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.description_validation'));
        return false;
      }
      if (typeof this.treatmentOrder.dosage === 'undefined' || this.treatmentOrder.dosage === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.dosage_validation'));
        return false;
      }
      if (typeof this.treatmentOrder.date === 'undefined' || this.treatmentOrder.date === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.date_validation'));
        return false;
      } if (typeof this.treatmentOrder.treatmentType === 'undefined' || this.treatmentOrder.treatmentType === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.treatmentType_validation'));
        return false;
      }
      if (typeof this.productResume === 'undefined' || this.productResume.length <= 0) {
        this.$f7.dialog.alert(this.$t('treatment.treatmentCreateOrder.form.productResume_validation'));
        return false;
      }
      return true;
    },
    async save() {
      if (this.validateForm()) {
        try {
          await treatmentOrderService.save({
            plantation: this.currentPlantation.id,
            description: this.treatmentOrder.description,
            date: moment(this.treatmentOrder.date).format('YYYY-MM-DD'),
            treatment_type: this.treatmentOrder.treatmentType,
            dose_type: this.treatmentOrder.dosage,
            volume: this.treatmentOrder.volume,
            agent: this.currentAgent,
            status: 'PLANIFICADO',
            products: this.productResume.map((item) => ({
              product: item.name,
              total_dose: item.dosageTotalKg,
              price: item.price,
              dosage_range: item.dosageRange,
            })),
          }, this.currentPlantation.companyId);
          this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      }
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(this.$t('dosageProduct.deleteDialogMessage',
        { product: this.productResume[item.row.rowIndex].name }),
      this.$t('dosageProduct.deleteDialogTitle'),
      () => {
        this.productResume.splice(item.row.rowIndex, 1);
        const that = this;
        setTimeout(() => { that.calculateTotals(); }, 500);
      });
    },
    setArea() {
      this.treatmentOrder.area = parseFloat(parseFloat(this.currentPlantation.area).toFixed(2));
    },
    ...mapActions('treatmentStepper', ['fetchTreatmentProducts', 'fetchTreatmentProduct']),
    ...mapActions('Vademecum', ['getOptionsPlagueFilter']),
  },
};
