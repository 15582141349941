<template>
  <div
    v-if="loaded"
    class="content-devx"
  >
    <DxDataGrid
      id="nutritionalProducts-grid"
      :data-source="nutritionalProducts"
      :remote-operations="true"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="nutritionalProducts"
      />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter
        :visible="true"
        :allow-search="true"
        :texts="searchPanelTexts"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="typeProduct"
        :caption="$t('Type_Product')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="commercialName"
        :caption="$t('Commercial_Name')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="holder"
        :caption="$t('Holder')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="registrationNumber"
        :caption="$t('Vademecum_product_code')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        type="buttons"
      >
        <DxButton
          icon="find"
          :on-click="showDetails"
        />
      </DxColumn>
    </dxdatagrid>
  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NutritionalProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
  },
  data() {
    return {
      keyName: 'nutritionalProducts',
      pageSizes: [5, 10, 20],
      loaded: false,
      searchPanelTexts: {
        cancel: this.$t('Button_cancel_text'), emptyValue: '', ok: this.$t('Search'),
      },
    };
  },
  computed: {
    ...mapState('Vademecum', ['nutritionalProducts']),
  },
  async beforeMount() {
    this.setCurrentPage(this.keyName);
    this.$f7.preloader.show();
    try {
      await this.getNutritionalProducts();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async showDetails(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.getNutritionalProductDetail(rowSelected.row.data.id);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/viewNutritionalProductDetail/', { reloadCurrent: true });
      }
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('nutritionalProducts');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'nutritionalProducts.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Vademecum', ['getNutritionalProducts', 'getNutritionalProductDetail']),
    ...mapActions('Utilities', ['setCurrentPage']),
  },
};
</script>
<style lang="scss" scoped>
@import './NutritionalProducts.styles.scss';
</style>
