<template>
  <div class="content__weatherforescast content-devx no-padding-horizontal no-margin">
    <f7-block v-if="!loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <f7-block v-else>
      <div class="label-text-data">
        <span v-text="$t('WeatherForecast_title')" />
      </div>
      <DxTabPanel
        id="panel"
        :data-source="farmsData"
        :selected-index.sync="selectedIndex"
        :loop="false"
        :animation-enabled="true"
        :show-nav-buttons="true"
      >
        <template
          #title="{ data: dataset }"
        >
          <span>{{ dataset.farm }}</span>
        </template>
        <template #item="{ data: dataset }">
          <div class="tabpanel-item bg-color-brown display-flex flex-direction-column justify-content-space-evenly">
            <div class="content-weather">
              <div
                v-for="(week, index) in dataset.data"
                :key="week.numberday"
              >
                <div
                  v-if="index < 2"
                  class="content-weather-item"
                >
                  <div>
                    <img :src="photoSrc(week.icon)">
                    <div class="content-weather-item__temperatures">
                      <div class="content-weather-item__temperatures--max">
                        {{ week.maxTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.minTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.speedValue.toFixed(0) }} <i class="f7-icons">wind</i> {{ $t(week.windDirecction) }}
                      </div>
                    </div>
                  </div>
                  <div class="content-weather-item__text">
                    {{ week.numberday }} {{ $t(`weekDays.${week.day }`) }} {{ ' ' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="content-weather">
              <div
                v-for="(week, index) in dataset.data"
                :key="week.numberday"
              >
                <div
                  v-if="index > 1"
                  class="content-weather-item"
                >
                  <div>
                    <img :src="photoSrc(week.icon)">
                    <div class="content-weather-item__temperatures">
                      <div class="content-weather-item__temperatures--max">
                        {{ week.maxTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.minTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.speedValue.toFixed(0) }} <i class="f7-icons">wind</i> {{ $t(week.windDirecction) }}
                      </div>
                    </div>
                  </div>
                  <div class="content-weather-item__text">
                    {{ week.numberday }} {{ $t(`weekDays.${week.day }`) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DxTabPanel>
    </f7-block>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import DxTabPanel from 'devextreme-vue/tab-panel';
import icCloud from '../../../../../static/img/ic_cloud.svg';
import icCloudMoon from '../../../../../static/img/ic_cloudmoon.svg';
import icCloudsun from '../../../../../static/img/ic_cloudsun.svg';
import icFog from '../../../../../static/img/ic_fog.svg';
import icHail from '../../../../../static/img/ic_hail.svg';
import icMoon from '../../../../../static/img/ic_moon.svg';
import icRain from '../../../../../static/img/ic_rain.svg';
import icSnow from '../../../../../static/img/ic_snow.svg';
import icSun from '../../../../../static/img/ic_sun.svg';
import icThunder from '../../../../../static/img/ic_thunder.svg';
import icTornado from '../../../../../static/img/ic_tornado.svg';
import icWind from '../../../../../static/img/ic_wind.svg';
import EventBus from '../../../../../js/event-bus';

export default {
  components: {
    DxTabPanel,
  },
  data() {
    return {
      loaded: false,
      selectedIndex: 0,
      day: 0,
      barpading: 0,
      cornerRadius: 0,
      dateFields: [[], []],
      dateIcons: [[], []],
    };
  },
  computed: {
    ...mapState('weatherForecast', ['farmsData']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
  },
  async beforeMount() {
    try {
      await this.getWeatherForecastParameter({
        id: this.currentPlantation.id,
        companyId: this.currentPlantation.companyId,
        locations: this.treeLocations,
      });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
    }
  },
  mounted() {
    EventBus.$on('updateLocations', this.prepareStructure);
  },
  beforeDestroy() {
    EventBus.$off('updateLocations', this.prepareStructure);
  },
  methods: {
    photoSrc(item) {
      switch (item) {
        case 'clear-night':
          return icMoon;
        case 'rain':
          return icRain;
        case 'snow':
          return icSnow;
        case 'wind':
          return icWind;
        case 'fog':
          return icFog;
        case 'cloudy':
          return icCloud;
        case 'partly-cloudy-day':
          return icCloudsun;
        case 'partly-cloudy-night':
          return icCloudMoon;
        case 'hail':
          return icHail;
        case 'thunderstorm':
          return icThunder;
        case 'tornado':
          return icTornado;
        default:
          return icSun;
      }
    },
    ...mapActions('weatherForecast', ['getWeatherForecastParameter']),
  },
};
</script>
<style lang="scss">
@import '../forecastWeather/forecastWeather.styles.scss';

</style>
