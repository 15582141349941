import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';

export default {
  name: 'nutritional-balance',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
  },
  props: [],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData']),
  },
  created() {
    if (this.stepData.nutritionalBalanceData.length > 0) {
      return;
    }
    this.stepData.nutritionalBalanceData = [];
    this.stepData.nutritionalBalanceData.push(
      this.invertRowDataSource(this.stepData.nutritionalNeeds[1]),
    );
    this.stepData.nutritionalBalanceData[0].uf = this.$t('nutritionalBalance.necNutritional');

    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.availableGround')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.irrigationWater')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.harvestScraps')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.availableTotal')));
    this.stepData.nutritionalBalanceData.push(this.getRowDataSource(this.$t('nutritionalBalance.advanceFertilization')));
  },
  methods: {
    getRowDataSource(titleColumn) {
      return {
        uf: titleColumn, n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
    },
    invertRowDataSource(element) {
      return {
        n: element.n * -1,
        p: element.p * -1,
        k: element.k * -1,
        c: element.c * -1,
        m: element.m * -1,
        s: element.s * -1,
      };
    },
    calcTotalBalance(array, param) {
      array[4][param] = this.roundValue(array[1][param] + array[2][param] + array[3][param]);
      array[5][param] = this.roundValue(array[4][param] + array[0][param]);
    },
    onEditorPreparing(e) {
      if (e.row.dataIndex === 0 || e.row.dataIndex > 3) {
        e.editorOptions.disabled = true;
      }
    },
    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
  },
  watch: {
    'stepData.nutritionalBalanceData': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val, 'n');
        this.calcTotalBalance(val, 'p');
        this.calcTotalBalance(val, 'k');
        this.calcTotalBalance(val, 'c');
        this.calcTotalBalance(val, 'm');
        this.calcTotalBalance(val, 's');
      },
      deep: true,
    },
  },
};
