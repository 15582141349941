<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('LeftPanel_subtitle_effortAgriFarm')" />
    <div class="content-devx">
      <CalendarInitEndDate
        style="z-index: 9999999; margin-top: 15px;"
        :key-name="keyName"
      />
      <f7-block class="content-calendar">
        <DxLocationSelector
          :start-level="0"
          :end-level="2"
          :events="['updateGIS', 'updateEffort']"
        />
      </f7-block>
      <!--     <f7-row>
        <f7-col
          width="100"
        >
          <GisEffort
            :init="checkedInitEndDate.init"
            :end="checkedInitEndDate.end"
            :fixed-level="2"
            :type="'_total_hours_ha'"
          />
        </f7-col>
      </f7-row> -->
      <f7-row class="datatable">
        <f7-col>
          <DxEffort
            :init="checkedInitEndDate.init"
            :end="checkedInitEndDate.end"
          />
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          <DxEffortActivity
            :init="checkedInitEndDate.init"
            :end="checkedInitEndDate.end"
          />
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          <DxSideBySideBarTypeOfWorker
            :init="checkedInitEndDate.init"
            :end="checkedInitEndDate.end"
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import DxEffort from '../../components/reporting/DxEffort.vue';
import DxEffortActivity from '../../components/reporting/DxEffortActivity.vue';
import GisEffort from '../../components/reporting/GisEffort.vue';
import DxLocationSelector from '../../components/reporting/DxLocationSelector.vue';
import DxSideBySideBarTypeOfWorker from '../../components/chartPlanned/DxSideBySideTypeOfWorker.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import Api from '../../services/Api';

export default {
  components: {
    navbar,
    DxEffort,
    DxEffortActivity,
    GisEffort,
    DxLocationSelector,
    DxSideBySideBarTypeOfWorker,
    CalendarInitEndDate,
  },
  data() {
    return {
      keyName: 'effortActivity',
      checkedInitEndDate: {},
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  methods: {
    beforein() {
      this.checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  margin-bottom: 1.2rem;
}
</style>
