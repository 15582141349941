<template>
  <f7-page
    class="main-viewplantationid"
  >
    <navbar
      v-if="loaded"
      :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualPlantation.explotation} > ${actualPlantation.parcel} > ${actualPlantation.farms[0].name} > ${actualPlantation.campaign} > ${actualPlantation.name}`"
    />
    <f7-row v-if="loaded">
      <f7-col>
        <ViewPlantation :properties="properties" />
      </f7-col>
      <f7-col class="padding-top padding-right">
        <GISGeoJsonMap
          :zoom="zoom"
          :name-map="mainMap"
          :tree-locations="treeLocations"
        />
      </f7-col>
    </f7-row>
    <div
      v-if="loaded"
      class="go-back-button margin-left margin-top-half"
    >
      <f7-button
        class="dx-btn-cancel padding-horizontal"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t('Button_go_to_back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ViewPlantation from '../../../components/plantation/viewPlantation/index.vue';
import navbar from '../../../components/NavBar.vue';
import GISGeoJsonMap from '../../../components/Gis/GISGeoJsonMap/index.vue';

export default {
  name: 'ViewPlantationId',
  components: {
    ViewPlantation,
    navbar,
    GISGeoJsonMap,
  },
  data() {
    return {
      properties: {},
      zoom: 17,
      mainMap: 'plantationMainMap',
      loaded: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation', 'isEdit', 'isPlantationCreated', 'treeLocations']),
    ...mapState('Plantation', ['id', 'currentPlantation']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
    ...mapState('Campania', ['isView', 'viewCampaignId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      let currentPlantationId = this.id;
      if (currentPlantationId === '') {
        currentPlantationId = this.actualPlantation.id;
      }
      const data = {
        companyId: this.actualUser.id,
        plantationId: currentPlantationId,
      };
      // Si no se está creando una plantación no hace falta cambiar la plantación de nuevo
      if (!this.isPlantationCreated) {
        await this.getPlantationById(data);
      }

      this.actualPlantationFormated();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async goToBack() {
      if (this.isEdit) {
        switch (this.isView) {
          case true: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
          case undefined: {
            this.$f7.views.main.router.navigate('/viewCampania/', { reloadCurrent: true });
            break;
          }
          case false: {
            await this.getCampaignsById(this.viewCampaignId);
            this.$f7.views.main.router.navigate('/viewCampania/', { reloadCurrent: true });
            break;
          }
          default: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
        }
      } else {
        switch (this.isView) {
          case false: {
            this.$f7.views.main.router.navigate('/viewCampania/', { reloadCurrent: true });
            break;
          }
          case true: {
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            break;
          }
          case undefined: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
          default: {
            this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
            break;
          }
        }
      }
    },
    goToSearcher() {
      this.$f7.views.main.router.navigate('/searcher/home/', { reloadCurrent: true });
    },
    actualPlantationFormated() {
      this.properties.variety = '';
      this.properties.area = '';
      this.properties.irrigation_type = '';
      this.properties.flow = '';
      this.properties.dripper_per_plant = '';
      this.properties.ground_texture = '';
      this.properties.sprinkler_number = '';
      this.properties.transformed_surface = '';
      this.properties.planting_density = this.actualLocation.planting_density;
      this.properties.plants_density = this.actualLocation.plants_density;
      for (const propertie of this.actualPlantation.farms[0].properties) {
        this.properties.variety = propertie.key === 'variety' ? propertie.value : this.properties.variety;
        this.properties.area = propertie.key === 'area' ? propertie.value : this.properties.area;
        this.properties.irrigation_type = propertie.key === 'irrigation_type' ? propertie.value : this.properties.irrigation_type;
        this.properties.flow = propertie.key === 'flow' ? propertie.value : this.properties.flow;
        this.properties.dripper_per_plant = propertie.key === 'dripper_per_plant' ? propertie.value : this.properties.dripper_per_plant;
        this.properties.ground_texture = propertie.key === 'ground_texture' ? propertie.value : this.properties.ground_texture;
        this.properties.sprinkler_number = propertie.key === 'sprinkler_number' ? propertie.value : this.properties.sprinkler_number;
        this.properties.transformed_surface = propertie.key === 'transformed_surface' ? propertie.value : this.properties.transformed_surface;
      }
    },
    ...mapActions('Plantation', ['fetchTreeLocationById']),
    ...mapActions('RegisterPlantation', ['getPlantationById', 'fetchLocationsByPlantation']),
    ...mapActions('Campania', ['setIsView', 'getCampaignsById']),
  },
};
</script>

<style lang="scss">
.main-viewplantationid{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
