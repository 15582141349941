import Api from './Api';
import db from './DexieDatabase';
import EventBus from '../js/event-bus';

export default {
  /**
   * Descarga el listado completo de registros a la BBDD de dexie
   */
  downloadNewData() {
    return Promise.all([
      Api.getAllRecordsToSync('sync/product'),
      Api.getAllRecordsToSync('sync/vademecum'),
      // Api.getAllRecordsToSync('sync/analysis'),
      // Api.getAllRecordsToSync('sync/fertilization'),
      // Api.getAllRecordsToSync('sync/plantation'),
      // Api.getAllRecordsToSync('sync/treatment_order'),
      // Api.getAllRecordsToSync('sync/treatment_plan'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.product.bulkPut(values[0]);
          db.vademecum.bulkPut(values[1]);
          // db.plantation.bulkPut(values[4]);
        }
        return values;
      }).catch((e) => {
        // Other error such as a string was thrown
        console.error(e);
      });
  },

  /**
   * Descarga el listado de tablas seleccionadas de registros a la BBDD de dexie
   */
  refreshDexieData() {
    // TODO: Mihai. Añadir llamadas que necesiten refrescar siempre
    return Promise.all([
      Api.getAllRecordsToSync('sync/product'),
      Api.getAllRecordsToSync('sync/plantation'),
      Api.getAllRecordsToSync('sync/analysis'),
      Api.getAllRecordsToSync('sync/fertilization'),
      Api.getAllRecordsToSync('sync/treatment_order'),
      Api.getAllRecordsToSync('sync/treatment_plan'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
          db.product.clear();
          db.plantation.clear();

          db.product.bulkPut(values[0]);
          db.plantation.bulkPut(values[1]);
        }
      });
  },

  /**
   * Limpiamos la BBDD completa
   */
  clearDatabase() {
    db.product.clear();
    db.vademecum.clear();
    db.plantation.clear();
  },

  /**
   * Descarga el listado completo de registros a la BBDD de dexie
   */
  async completeSyncService() {
    return this.downloadNewData().catch(
      (e) => {
        console.log('Error DEL FRONT:', e);
        const jsonError = JSON.stringify({
          error_code: e,
        });
        EventBus.$emit('UPDATE_SYNC_ERROR_TEXT', jsonError);
        return [0];
      },
    ).finally();
  },

  /**
   * Descarga el listado de tablas seleccionadas de registros a la BBDD de dexie
   */
  async updateDataBaseRecords() {
    return this.refreshDexieData().catch(
      (e) => {
        console.log('Error DEL FRONT:', e);
        const jsonError = JSON.stringify({
          error_code: e,
        });
        EventBus.$emit('UPDATE_SYNC_ERROR_TEXT', jsonError);
        return [0];
      },
    ).finally();
  },

};
