<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block>{{ $t('TaskDefinition.detail_task') }}</f7-block>
      <TaskDetail :current-task-definition="currentTaskDefinition" />
    </div>
    <f7-block-footer
      v-if="loaded"
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToTasks"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import TaskDetail from '../../../components/configuration/tasks/taskDetail/index.vue';

export default {
  name: 'ViewTaskDetail',
  components: {
    TaskDetail,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    ...mapState('TaskDefinition', ['currentTaskDefinition']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getTaskDefinitionById(this.currentTaskDefinition.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToTasks() {
      this.$f7.views.main.router.navigate('/tasks/', { reloadCurrent: true });
    },
    ...mapActions('TaskDefinition', ['getTaskDefinitionById']),

  },
};
</script>
