<template>
  <div
    class="content-devx no-margin margin-top margin-left"
  >
    <f7-row>
      <f7-col width="85">
        <f7-block-title>
          {{ $t('parcel.detail.title') }}
        </f7-block-title>
      </f7-col>
      <f7-col width="15">
        <f7-block-title>
          <f7-button
            class="dx-btn-edit"
            raised
            type="default"
            styling-mode="outlined"
            @click="editCurrentParcel"
          >
            <f7-icon
              md="f7:pencil"
            />
          </f7-button>
        </f7-block-title>
      </f7-col>
    </f7-row>

    <f7-block>
      <f7-row>
        <f7-col>
          {{ $t('parcel.detail.client') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentClient }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('parcel.detail.location') }}
        </f7-col>
        <f7-col>
          {{ $t('parcel.detail.parcelName') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentParcel.explotation }}
        </f7-col>
        <f7-col>
          {{ currentParcel.name }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('parcel.detail.area') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ parseFloat(area).toFixed(2) }} ha
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: 'ParcelDetail',
  props: {
    currentParcel: {
      type: Object,
      default: () => {},
    },
    currentClient: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    area() {
      return this.$helpers.getProperty(this.currentParcel.properties, 'area');
    },
  },
  methods: {
    editCurrentParcel() {
      this.setCurrentName(this.currentParcel.name);
      this.$f7.views.main.router.navigate('/registerParcelStep1/', { reloadCurrent: true });
    },
    ...mapActions('Parcel', ['setCurrentName']),
  },
};
</script>
<style lang="scss" scoped>
@import './ParcelDetail.styles.scss';
</style>
