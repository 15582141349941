<template>
  <DxChart
    id="chart"
    :data-source="effortFormated"
    :title="$t('ChartPlanned_StackedBarPlanned')"
    @legend-click="onLegendClick"
  >
    <DxCommonSeriesSettings
      argument-field="Task"
      type="bar"
    />
    <DxValueAxis
      :name="$t('Workers_StackedBarPlanned')"
      position="right"
    >
      <DxTitle :text="$t('Workers_StackedBarPlanned')" />
    </DxValueAxis>
    <DxValueAxis
      :name="$t('machinary_StackedBarPlanned')"
      position="left"
    >
      <DxTitle :text="$t('machinary_StackedBarPlanned')" />
    </DxValueAxis>
    <DxSeries
      :axis="$t('Workers_StackedBarPlanned')"
      value-field="PlannWorkers"
      :name="$t('plannWorkers_StackedBarPlanned')"
      color="#FF2D00"
    />
    <DxSeries
      :axis="$t('Workers_StackedBarPlanned')"
      value-field="Workers"
      :name="$t('Workers_StackedBarPlanned')"
      color="#991E03"
    />
    <DxSeries
      :axis="$t('machinary_StackedBarPlanned')"
      value-field="PlannMachinary"
      :name="$t('plannMachinery_StackedBarPlanned')"
      color="#001095"
    />
    <DxSeries
      :axis="$t('machinary_StackedBarPlanned')"
      value-field="machinary"
      :name="$t('machinary_StackedBarPlanned')"
    />
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="top"
    />
    <DxExport :enabled="true" />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
      location="edge"
    />
    <DxScrollBar :visible="true" />
    <DxZoomAndPan argument-axis="both" />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxScrollBar,
  DxZoomAndPan,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart';

import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Api from '../../services/Api';

export default {
  components: {
    DxChart,
    DxSeries,
    DxScrollBar,
    DxZoomAndPan,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  props: {
    init: {
      type: String,
      default: encodeURIComponent(`${moment(new Date()).format('YYYY-MM-DD')}`),
    },
    end: {
      type: String,
      default: encodeURIComponent(`${moment(new Date()).format('YYYY-MM-DD')}`),
    },
  },
  data() {
    return {
      effortFormated: [],
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.loaded = true;
    try {
      this.formattingEffort();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = false;
    }
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.seriesName} ${pointInfo.valueText}`,
      };
    },
    // ---- OBTENCIóN Y FORMATEO DE ACTIVITIES Y TASKS ----
    async formattingEffort() {
      this.effortFormated.length = 0;
      this.$f7.preloader.show();
      try {
        const xhr = await Api.getStackedBarPlanned(this.init, this.end);
        const plannings = JSON.parse(xhr.response).data;
        for (const planning of plannings) {
          this.effortFormated.push({
            Task: planning.task,
            PlannWorkers: this.getTotalWorkersPlanner(planning.planner.workers),
            Workers: this.getWorkersIn(planning.workers),
            PlannMachinary: this.getTotalMachinaryPlanner(planning.planner.items),
            machinary: this.getMachinary(planning.workers, planning.items_drivers),
          });
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    getMachinary(workers, itemsDrivers) {
      let x = 0;
      for (let i = 0; i < workers.length; i += 1) {
        if (typeof workers[i].end_date === 'undefined' || workers[i].end_date === null) {
          x = itemsDrivers.length;
          break;
        }
      }
      return x;
    },
    getWorkersIn(workers) {
      let x = 0;
      if (typeof workers !== 'undefined' && workers !== null) {
        for (let i = 0; i < workers.length; i += 1) {
          if (typeof workers[i].end_date === 'undefined' || workers[i].end_date === null) {
            x += 1;
          }
        }
      }
      return x;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      if (typeof workersPlanner !== 'undefined' && workersPlanner !== null) {
        for (let i = 0; i < workersPlanner.length; i += 1) {
          element += workersPlanner[i].count;
        }
      }
      return element;
    },
    getTotalMachinaryPlanner(workersPlanner) {
      let element = 0;
      if (typeof workersPlanner !== 'undefined' && workersPlanner !== null) {
        for (let i = 0; i < workersPlanner.length; i += 1) {
          element += workersPlanner[i].count;
        }
      }
      return element;
    },
  },
};
</script>
<style>
#chart {
    height: 100%;
    width: 100%;
}
</style>
