<template>
  <div class="content-devx">
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link="#Point"
        tab-link-active
        @click="toShowPointTab()"
      >
        {{ $t('Searcher.observations.point') }}
      </f7-link>
      <f7-link
        tab-link="#Sector"
        @click="toShowSectorTab()"
      >
        {{ $t('Searcher.observations.sector') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs animated>
      <f7-tab
        id="Point"
        class="page-content bg-light"
        tab-active
      >
        <PointTab v-if="!flagToShowTabs" />
      </f7-tab>
      <f7-tab
        id="Sector"
        class="page-content bg-light"
      >
        <SectorTab v-if="flagToShowTabs" />
      </f7-tab>
    </f7-tabs>
  </div>
</template>
<script>
import PointTab from './pointTab/index.vue';
import SectorTab from './sectorTab/index.vue';

export default {
  name: 'ObservationPointForm',
  components: {
    PointTab,
    SectorTab,
  },
  data() {
    return {
      flagToShowTabs: false,
    };
  },
  methods: {
    toShowPointTab() {
      this.flagToShowTabs = false;
    },
    toShowSectorTab() {
      this.flagToShowTabs = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ObservationPointForm.styles.scss';
</style>
