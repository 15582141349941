<template>
  <div v-if="!isWorkOrderCreate">
    <div
      class="content-devx no-shadow content-dx-daily-planning content-block-table"
    >
      <DxDataGrid
        id="toolsTable"
        ref="toolsTable"
        :data-source="tools.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
        @row-inserting="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxToolsTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="allowUpdating"
          :allow-deleting="allowDeleting"
          :allow-adding="allowAdding"
          mode="form"
        >
          <DxForm>
            <DxItem
              :col-count="1"
              :col-span="2"
              item-type="group"
            >
              <DxItem
                v-if="isPostWork"
                data-field="item"
                :caption="$t('DxToolsMonthlyTable_type_caption')"
                :set-cell-value="actualActiveType"
                editor-type="dxSelectBox"
              />
              <DxItem
                v-else
                data-field="item"
                :caption="$t('DxToolsMonthlyTable_type_caption')"
                editor-type="dxSelectBox"
              />
              <DxItem
                v-if="isPostWork"
                :col-span="20"
                data-field="type"
                :caption="$t('DxToolsTable_tools')"
                editor-type="dxSelectBox"
              >
                <DxLookup
                  :data-source="filteredItem"
                />
              </DxItem>
              <DxItem
                v-else
                :col-span="20"
                data-field="count"
                :caption="$t('DxToolsTable_tools')"
                editor-type="dxNumberBox"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="fuel_consumption"
                :caption="$t('DxToolsMonthlyTable_consumption_caption')"
              />
              <DxItem
                data-field="fuel_type"
                :camption="$t('DxToolsMonthlyTable_consumption_type_caption')"
              />
            </DxItem>
          </DxForm>
        </DxEditing>
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          v-if="!isPostWork"
          data-field="item"
          name="item"
          :set-cell-value="actualActiveType"
          alignment="left"
          :caption="$t('DxTools_dxColumn_TypeTool')"
        >
          <DxLookup
            :data-source="toolsTypesAvailables"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          v-else
          data-field="item"
          name="item"
          :set-cell-value="actualActiveType"
          alignment="left"
          :caption="$t('DxTools_dxColumn_TypeTool')"
        >
          <DxLookup
            :data-source="toolsTypesAvailables"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          v-if="isPostWork"
          data-field="type"
          :caption="$t('DxToolsTable_tools')"
          alignment="left"
        >
          <DxLookup
            :data-source="filteredItem"
            display-expr="description"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          v-else
          data-field="count"
          name="count"
          data-type="number"
          :caption="$t('DxTool_dxColumn_QuantityTool')"
          alignment="left"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="fuel_consumption"
          alignment="right"
          name="fuel_consumption"
          data-type="number"
          :caption="$t('MonthlyComponent_consumption_caption')"
        />
        <DxColumn
          data-field="fuel_type"
          name="fuel_type"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        >
          <DxLookup
            :data-source="typeCosumptionAvailable"
            :display-expr="dysplayConsumptionType"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
  <div v-else>
    <div
      class="content-devx no-shadow content-dx-daily-planning content-block-table"
    >
      <DxDataGrid
        id="toolsTable"
        ref="toolsTable"
        :data-source="tools.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :column-auto-width="true"
        @row-updated="onChangeRow"
        @row-inserting="onInsertedRow"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxToolsTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="allowUpdating"
          :allow-deleting="allowDeleting"
          :allow-adding="allowAdding"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="itemId"
          name="itemId"
          alignment="left"
          :caption="$t('DxTools_dxColumn_TypeTool')"
          :allow-editing="false"
        >
          <DxLookup
            :data-source="toolsTypesAvailables"
            display-expr="name"
            value-expr="name"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="tool_id"
          name="toolId"
          :caption="$t('DxToolsTable_tools')"
          alignment="left"
        >
          <DxLookup
            :data-source="filteredItem"
            display-expr="description"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="fuel_consumption"
          alignment="right"
          name="fuelConsumption"
          data-type="number"
          :caption="$t('MonthlyComponent_consumption_caption')"
        />
        <DxColumn
          data-field="fuel_type"
          name="fuelType"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        >
          <DxLookup
            :data-source="typeCosumptionAvailable"
            :display-expr="dysplayConsumptionType"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
  DxForm,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';

export default {
  name: 'DxToolsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxForm,
    DxItem,
    DxStateStoring,
  },
  props: {
    toolsTypesAvailables: { type: Array, default: () => [] },
    planning: { type: Object, default: null },
    allowUpdating: { type: Boolean, default: true },
    allowDeleting: { type: Boolean, default: true },
    allowAdding: { type: Boolean, default: true },
    toolsAvailables: { type: Array, default: () => [] },
    isPostWork: { type: Boolean, default: false },
    isWorkOrderCreate: { type: Boolean, default: false },
    isEditWorkOrder: { type: Boolean, default: false },
    isTaskOrderCompleted: { type: Boolean, default: false },
    actualWorkOrder: { type: Object, default: () => {} },
  },
  data() {
    return {
      typeCosumptionAvailable: ['', 'DIESEL', 'GASOLINE'],
      actualActiveType(rowData, value) {
        rowData.type = null;
        this.defaultSetCellValue(rowData, value);
      },
      planningToolFormated: [],
      toolsFormatted: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['tools']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    overlay.baseZIndex(9999);
    // se formatean los datos para edit work order.
    if (this.isTaskOrderCompleted) {
      for (const tool of this.actualWorkOrder.tools) {
        this.toolsFormatted.push({
          type: tool.item,
          item: tool.data.id,
          fuel_consumption: tool.fuel_consumption,
          fuel_type: tool.fuel_type,
        });
      }
      this.setTools(this.toolsFormatted);
    } else if (this.planning == null && !this.isTaskOrderCompleted) {
      this.setTools([]);
    } else if (this.isWorkOrderCreate && !this.isTaskOrderCompleted) {
      this.getSelectedToolFormated();
      this.actualItems = this.planningToolFormated;
      this.setTools(this.actualItems);
    } else {
      this.actualItems = this.planning.Tools;
      this.setTools(this.actualItems);
    }
  },
  methods: {
    getSelectedToolFormated() {
      for (const tool of this.planning.Tools) {
        for (let i = 0; i < tool.count; i += 1) {
          this.planningToolFormated.push({
            tool_id: tool.data.id,
            itemId: tool.data.subfamily,
            fuel_consumption: tool.fuel_consumption,
            fuel_type: tool.fuel_type,
          });
        }
      }
      // }
      return '';
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      if (e === 'GASOLINE') {
        return this.$t('GASOLINE');
      }
      return '';
    },
    filteredType(e) {
      let item = '';
      try {
        if (typeof e.values[0] === 'undefined') {
          return [{ id: '', name: '' }];
        }
        for (const tool of this.toolsTypesAvailables) {
          if (typeof tool.id !== 'undefined' && tool.id === e.values[0]) {
            item = tool.id;
          }
          if (tool.id === e.values[0]) {
            item = tool.name;
          }
        }
        if (this.toolsAvailables.filter((tools) => tools.item === item).length === 0) {
          return this.tools.filter(
            (tools) => tools.item === item,
          );
        }
        return this.tools.filter(
          (tools) => tools.item === item,
        );
      } catch (error) {
        return this.tools;
      }
    },

    checkToolIfExistType(idType) {
      for (const tool of this.tools.values) {
        if (typeof tool.type !== 'undefined') {
          if (tool.type !== null && tool.type === idType) return true;
          if (tool.type === null && tool.item === idType) return true;
          if (tool.type === '') return true;
        } else {
          if (tool.toolId !== null && tool.toolId === idType) return true;
          if (tool.toolId === null && tool.item === idType) return true;
          if (tool.toolId === '') return false;
        }
      }
      return false;
    },
    onChangeRow() {
      const newItems = this.tools.values;
      this.setTools(newItems);
    },
    onInsertedRow() {
      this.tools.values.count = 1;
      const newItems = this.tools.values;
      this.setTools(newItems);
    },
    onRemovedRow() {
      const newItems = this.tools.values;
      this.setTools(newItems);
    },
    onRowValidating(e) {
      let typeTool = e.newData.item;
      if (typeof typeTool === 'undefined') typeTool = e.oldData.item;
      if (this.checkToolIfExistType(typeTool)) {
        this.$f7.dialog.alert(this.$t('DxToolsTable_Add_Change_typeTools'));
        e.isValid = false;
      } else {
        e.isValid = true;
      }
    },
    onRowValidatingItem(e) {
      let { type } = e.newData;
      if (typeof type === 'undefined' && e.newData.toolId !== null) type = e.oldData.type;
      if (e.newData.toolId === null) type = e.newData.toolId;
      if (e.newData.toolId !== '' && e.oldData.toolId === '') type = e.newData.toolId;
      if (this.checkToolIfExistType(type)) {
        this.$f7.dialog.alert(this.$t('DxToolsTable_Add_Change_typeTools'));
        e.isValid = false;
      } else {
        e.isValid = true;
      }
    },
    filteredItem(e) {
      try {
        if (e.values !== undefined) {
          const listaFiltrado = this.toolsAvailables.filter(
            (elemento) => elemento.item === e.values[0],
          );
          return listaFiltrado;
        }
        return this.toolsAvailables;
      } catch (error) {
        return [{ id: '', name: '' }];
      }
    },
    ...mapActions('PlanningManager', ['setTools']),
  },
};
</script>
