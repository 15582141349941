<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Machinery_title')}`" />
    <DxMachineryDatabase />
  </f7-page>
</template>

<script>

import DxMachineryDatabase from '../../../components/configuration/machinery/DxMachineryDatabase.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  components: {
    DxMachineryDatabase,
    navbar,
  },
};
</script>

<style>

</style>
