<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Activity.title')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block>{{ $t('Activity.detail_activity') }}</f7-block>
      <ActivityDetail
        :current-activity="currentActivity"
      />
    </div>
    <f7-block-footer
      v-if="loaded"
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToActivities"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ActivityDetail from '../../../components/configuration/activities/activityDetail/index.vue';

export default {
  name: 'ViewActivityDetail',
  components: {
    navbar,
    ActivityDetail,
  },
  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    ...mapState('Activity', ['currentActivity']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getActivityById(this.currentActivity.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToActivities() {
      this.initStore();
      this.$f7.views.main.router.navigate('/activities/', { reloadCurrent: true });
    },
    ...mapActions('Activity', ['getActivityById', 'initStore']),

  },
};
</script>
