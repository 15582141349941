import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'converter-nutritional-solutions',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsNutritionalSolutions']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsNutritionalSolutions[0]);
      this.unitSelected = { ...this.itemUnitsNutritionalSolutions[0] };
      this.unitStatic = { ...this.itemUnitsNutritionalSolutions[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitSelected = { ...e.value };
    },
    changeElement(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.oxide = this.roundValue(this.getFactor() * value);
    },
    changeOxide(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.element = this.roundValue(value / this.getFactor());
    },
    getFactor() {
      return parseFloat(this.unitStatic.oxide / this.unitStatic.element);
    },
    roundValue(val) {
      return parseFloat(val.toFixed(5));
    },
  },
};
