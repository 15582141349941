// Import Vue & Framework7
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

// Importamos la librería de traducción
import VueI18Next from '@panter/vue-i18next';

// Styles
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.scss';

// Import DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.lime.light.compact.css';
import * as overlay from 'devextreme/ui/overlay';

// Import App Component
import VCalendar from 'v-calendar';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import i18n from './i18n.config';
import App from '../components/app.vue';
import store from '../store/index';

// Importamos librerías de apoyo
import Helpers from './helpers';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Inicializamos las librerías de apoyo
Vue.use(VCalendar);

overlay.baseZIndex(9999);

// Init App
Vue.prototype.$helpers = Helpers;
// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  // Register App Component
  components: {
    app: App,
  },
  render: (h) => h(App),
  i18n,
  store,
});
