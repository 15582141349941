<template>
  <div class="content__weatherforescast">
    <f7-block v-if="loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <f7-block v-else>
      <div class="label">
        <span v-text="$t('WeatherForecast_title')" />
      </div>
      <DxTabPanel
        id="panel"
        :data-source="farmsData"
        :selected-index.sync="selectedIndex"
        :loop="false"
        :animation-enabled="true"
        :show-nav-buttons="true"
      >
        <template
          #title="{ data: dataset }"
        >
          <span>{{ dataset.farm }}</span>
        </template>
        <template #item="{ data: dataset }">
          <div class="tabpanel-item">
            <div class="content-weather">
              <div
                v-for="(week, index) in dataset.data"
                :key="week.numberday"
              >
                <div
                  v-if="index < 2"
                  class="content-weather-item"
                >
                  <div>
                    <img :src="photoSrc(week.icon)">
                    <div class="content-weather-item__temperatures">
                      <div class="content-weather-item__temperatures--max">
                        {{ week.maxTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.minTemp.toFixed(0) }} °C
                      </div>
                    </div>
                  </div>
                  <div class="content-weather-item__text">
                    {{ week.numberday }} {{ week.day }}
                  </div>
                </div>
              </div>
            </div>
            <div class="content-weather">
              <div
                v-for="(week, index) in dataset.data"
                :key="week.numberday"
              >
                <div
                  v-if="index > 1"
                  class="content-weather-item"
                >
                  <div>
                    <img :src="photoSrc(week.icon)">
                    <div class="content-weather-item__temperatures">
                      <div class="content-weather-item__temperatures--max">
                        {{ week.maxTemp.toFixed(0) }} °C
                      </div>
                      <div class="content-weather-item__temperatures--min">
                        {{ week.minTemp.toFixed(0) }} °C
                      </div>
                    </div>
                  </div>
                  <div class="content-weather-item__text">
                    {{ week.numberday }} {{ week.day }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DxTabPanel>
    </f7-block>
  </div>
</template>
<script>
import moment from 'moment';
import DxTabPanel from 'devextreme-vue/tab-panel';
import DxChart, {
  DxSeries,
  DxValueAxis,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxGrid,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';
import icCloud from '../../static/img/ic_cloud.svg';
import icCloudMoon from '../../static/img/ic_cloudmoon.svg';
import icCloudsun from '../../static/img/ic_cloudsun.svg';
import icFog from '../../static/img/ic_fog.svg';
import icHail from '../../static/img/ic_hail.svg';
import icMoon from '../../static/img/ic_moon.svg';
import icRain from '../../static/img/ic_rain.svg';
import icSnow from '../../static/img/ic_snow.svg';
import icSun from '../../static/img/ic_sun.svg';
import icThunder from '../../static/img/ic_thunder.svg';
import icTornado from '../../static/img/ic_tornado.svg';
import icWind from '../../static/img/ic_wind.svg';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxTabPanel,
    DxChart,
    DxSeries,
    DxValueAxis,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxGrid,
  },
  data() {
    return {
      loaded: true,
      selectedIndex: 0,
      day: 0,
      barpading: 0,
      cornerRadius: 0,
      dateFields: [[], []],
      dateIcons: [[], []],
      weatherForecast: [],
      farmsData: [],
      locationsAvailables: [],
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
  },
  updated() {
    this.loaded = false;
  },
  mounted() {
    EventBus.$on('updateLocations', this.prepareStructure);
  },
  beforeDestroy() {
    EventBus.$off('updateLocations', this.prepareStructure);
  },
  methods: {
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    photoSrc(item) {
      switch (item) {
        case 'clear-night':
          return icMoon;
        case 'rain':
          return icRain;
        case 'snow':
          return icSnow;
        case 'wind':
          return icWind;
        case 'fog':
          return icFog;
        case 'cloudy':
          return icCloud;
        case 'partly-cloudy-day':
          return icCloudsun;
        case 'partly-cloudy-night':
          return icCloudMoon;
        case 'hail':
          return icHail;
        case 'thunderstorm':
          return icThunder;
        case 'tornado':
          return icTornado;
        default:
          return icSun;
      }
    },
    async prepareStructure() {
      this.$f7.preloader.show();
      this.farmsData.length = 0;
      this.loaded = true;
      try {
        await this.getLocationsAvailables();
        const forecastData = await Api.getClimate(this.selectedFarmList, 'FORECAST',
          moment.utc().format('YYYY-MM-DDT00:00:00'),
          moment.utc().add(4, 'd').format('YYYY-MM-DDT23:59:59'), ['TEMPERATURE', 'SKY_STATE'], true);
        this.weatherForecast = JSON.parse(forecastData.response);
        for (let i = 0; i < this.weatherForecast.length; i += 1) {
          const checkLocation = this.selectedFarmList.indexOf(this.weatherForecast[i].location);
          // Comprobamos que el id leido del JSON existe en el array de ids de vuex.
          if (checkLocation !== -1) {
            this.dateFields = [[], []];
            this.dateIcons = [[], []];
            const nameFarm = this.getLocationNameById(
              this.weatherForecast[i].location,
            );

            const temperatures = this.weatherForecast[i].parameters.TEMPERATURE;
            const skyState = this.weatherForecast[i].parameters.SKY_STATE;
            // Cargo en una estructura los iconos del tiempo de cada día a las 12:00,
            // para luego añadirlos a la estructura final
            if (skyState) {
              for (let j = 0; j < skyState.icon.length; j += 1) {
                const actualHour = moment(skyState.icon[j].date).format('H');
                if (actualHour === '12') {
                  const actualDate = moment(skyState.icon[j].date).format(
                    'YYYY-MM-DD',
                  );
                  this.dateIcons[0].push(actualDate);
                  this.dateIcons[1].push(skyState.icon[j].value);
                }
              }
            }

            this.buildTemperature(temperatures);
            // Añado la granja con todos sus datos de predicciones
            this.farmsData.push({ farm: nameFarm, data: this.dateFields[1] });
          }
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
        this.loaded = false;
      }
    },
    buildTemperature(temperatures) {
      // eslint-disable-next-line guard-for-in
      for (const key in temperatures) {
        for (let j = 0; j < temperatures[key].length; j += 1) {
          const actualDate = moment(temperatures[key][j].date).format(
            'YYYY-MM-DD',
          );
          const actualValue = temperatures[key][j].value;
          const checkExist = this.dateFields[0].indexOf(actualDate);
          if (checkExist === -1) {
            // Si no existe el día para la farm actual, lo añado tal cual
            const weekday = moment(temperatures[key][j].date).format('dddd');
            const numDay = moment(temperatures[key][j].date)
              .format('YYYY-MM-DD')
              .split('-');
            const checkExistIcon = this.dateIcons[0].indexOf(actualDate);
            // Establezco el icono que venga del dataset, o en caso de no haberlo, clear-day
            let iconWeather = 'clear-day';
            if (checkExistIcon !== -1) {
              iconWeather = this.dateIcons[1][checkExistIcon];
            }
            this.dateFields[0].push(actualDate);
            this.dateFields[1].push({
              day: weekday,
              numberday: numDay[2],
              minTemp: actualValue,
              maxTemp: actualValue,
              averageTemp: actualValue,
              numTemps: 1,
              temperatures: [
                {
                  nameBar: '',
                  temperatureMin: actualValue,
                  temperatureMax: actualValue,
                },
              ],
              icon: iconWeather,
            });
          } else {
            // Si ya existe el día, lo actualizo
            const changeField = this.dateFields[1][checkExist];
            if (changeField.minTemp > actualValue) {
              changeField.minTemp = actualValue;
            }
            if (changeField.maxTemp < actualValue) {
              changeField.maxTemp = actualValue;
            }
            changeField.numTemps += 1;
            changeField.averageTemp = (changeField.maxTemp + changeField.minTemp) / 2;
            changeField.temperatures = [
              {
                nameBar: '',
                temperatureMin: changeField.minTemp,
                temperatureMax: changeField.maxTemp,
              },
            ];

            this.dateFields[1][checkExist] = changeField;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
#panel {
  width: 100%;
}
.content__weatherforescast
.dx-tabpanel .dx-tab {
  width: auto!important;
  padding: 0;
  font-size: 12px;
  letter-spacing: -0.02em;
  span {
    padding: 4px 8px;
  }
}
.content__weatherforescast{
  #chart {
    height: 25px;
    max-height: 25px;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
    background: linear-gradient(90deg, #4A90E2 30%, #F9C300 30%);
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
    @keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

    @-moz-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

    @-webkit-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

    @-o-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

    @-ms-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
  svg{
    height: 25px;
    max-height: 25px;
    g{
      height: 25px;
      max-height: 25px;
    }
    g rect{
      height: 25px;
      max-height: 25px;
    }

  }
  .dxc-legend{
    display: none;
  }
  }
  .tabpanel-item{
    padding: 0;
    tr{
      &:nth-child(even){
        background: #eeeeee;
        th{
          background: #eeeeee;
        }
      }
      &:nth-child(odd){
        background: #ffffff;
        th{
          background: #ffffff;
        }
      }
      width: 100%;
      padding: 4px 5px 0;
      display: inline-table;
      margin-bottom: 2px;
      th{
        display: table-caption;
        text-align: left;
        padding: 5px 0 0 15px;
        font-size: 12px;
        text-transform: uppercase;
      }
      td{
        &:nth-child(2){
          width: 45px;
          text-align: center;
        }
        &:nth-child(3){
          width: 60px;
          min-width: 60px;
          text-align: right;
          color: var(--ha-blue);
          font-weight: 600;
        }
        &:nth-child(4){
          width: 200px;
        }
        &:nth-child(5){
          width: 50px;
          text-align: left;
          color: var(--ha-orange);
          font-weight: 600;
        }
        .grados{
          font-weight: 300;
        }
      }
    }
  }
  &__day{

  }
}
.dxc-val-line path{
  opacity: 0;
}
.content-weather{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px 0 15px!important;
  width: 100%;
  .content-weather-item{
    >div{
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__temperatures{
      display: flex;
      flex-direction: column;
    }
  }
  &:first-child{
    &>:nth-child(3), &>:nth-child(4), &>:nth-child(5){
      display: none!important;
    }
    .content-weather-item{
      background-color: #F9F7F1;
      padding: 15px 20px;
      >div{
        img{
          width: 80px;
          height: 70px;
        }
      }
      &__temperatures{
        font-size: 30px;
        line-height: 35px;
        align-items: flex-end;
        margin-left: 25px;
        &--max{
          color: #F29500!important;
          font-weight: 600;
        }
        &--min{
          color: #6FA2DF;
        }
      }
      &__text{
        padding-top: 10px;
        color: #24253D;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  &:nth-child(2){
    padding-bottom: 15px!important;
    &>:nth-child(1), &>:nth-child(2){
      display: none!important;
    }
    .content-weather-item{
      background-color: #F9F7F1;
      padding: 15px;
      >div{
        img{
          width: 50px;
          height: 40px;
        }
      }
      &__temperatures{
        font-size: 20px;
        line-height: 25px;
        align-items: flex-end;
        margin-left: 10px;
        &--max{
          color: #F29500!important;
          font-weight: 600;
        }
        &--min{
          color: #6FA2DF;
        }
      }
      &__text{
        padding-top: 5px;
        color: #24253D;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>
