import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import treatmentOrderService from '@/services/treatmentOrderService';
import numeral from 'numeral';
import treatmentOrderProducts from '../treatmentOrderProducts/index.vue';

const numeralFormat = '0,0.00';

export default {
  name: 'treatment-detail-order',
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxButton,
    treatmentOrderProducts,
  },
  props: ['id'],
  data() {
    return {
      treatmentOrder: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    treatmentOrderService.getById(this.id, this.currentPlantation.companyId).then((response) => {
      this.treatmentOrder = response;
    });
  },
  methods: {
    calculateDosageTotalKg(rowData) {
      rowData.dosageTotalKg = rowData.dosageTotalL * this.treatmentOrder.area;
      return numeral(rowData.dosageTotalKg).format(numeralFormat);
    },
    calculateUnitCost(rowData) {
      rowData.unitCost = rowData.price === 0 ? 0
        : numeral(rowData.dosageTotalKg).value() / rowData.price;
      return numeral(rowData.unitCost).format(numeralFormat);
    },
    calculateTotalCost(rowData) {
      rowData.totalCost = numeral(rowData.unitCost).value() * this.treatmentOrder.area;
      return numeral(rowData.totalCost)
        .format(numeralFormat);
    },
    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
    },
  },
};
