<template>
  <div class="content-devx margin-half">
    <f7-block>
      {{ $t('LeftPanel_subtitle_price_lonjas') }}
    </f7-block>
    <f7-block>
      <DxForm>
        <DxGroupItem :col-count="3">
          <DxSimpleItem
            :data-field="$t('Slices_simpleItem_slice')"
            :caption="$t('Slices_simpleItem_slice')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: allSlices,
              placeholder: $t('Slices_placeholder_slice'),
              onValueChanged: onSelectedSlice,
              searchEnabled:true
            }"
          >
            <DxRequiredRule :message="$t('Slices_validate_slice')" />
          </DxSimpleItem><DxSimpleItem
            name="init-date"
            :data-field="$t('Slices_simpleItem_initDate')"
            editor-type="dxDateBox"
            :editor-options="{
              displayFormat: 'dd/MM/yyyy',
              placeholder: $t('Slices_placeholder_initDate'),
              invalidDateMessage: $t('dates_isInvalid'),
              onValueChanged: onSelectedInitDate,
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_init_date_require')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="end-date"
            :data-field="$t('Slices_simpleItem_endDate')"
            editor-type="dxDateBox"
            :editor-options="{
              displayFormat: 'dd/MM/yyyy',
              placeholder: $t('Slices_placeholder_endDate'),
              invalidDateMessage: $t('dates_isInvalid'),
              onValueChanged: onSelectedEndDate,
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem
          v-if="endDate !== ''"
          :col-count="3"
        >
          <DxSimpleItem
            :data-field="$t('Slices_simpleItem_family')"
            :caption="$t('Slices_simpleItem_family')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: allFamilies.families,
              value: actualFamily,
              placeholder: $t('Slices_placeholder_family'),
              onValueChanged: onSelectedFamily,
              searchEnabled:true
            }"
          >
            <DxRequiredRule :message="$t('Slices_validate_family')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Slices_simpleItem_product')"
            :caption="$t('Slices_simpleItem_product')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: aLLProduct,
              value: actualProduct,
              placeholder: $t('Slices_placeholder_product'),
              onValueChanged: onSelectedProduct,
              searchEnabled:true
            }"
          >
            <DxRequiredRule :message="$t('Slices_validate_product')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Slices_simpleItem_origin')"
            :caption="$t('Slices_simpleItem_origin')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: allOrigins,
              value: actualOrigin,
              placeholder: $t('Slices_placeholder_origin'),
              onValueChanged: onSelectedOrigin,
              searchEnabled:true
            }"
          >
            <DxRequiredRule :message="$t('Slices_validate_origin')" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';

export default {
  name: 'Slices',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      actualFamily: '',
      actualProduct: '',
      actualOrigin: '',
    };
  },
  computed: {
    ...mapState('Slices', ['allSlices', 'allProvince', 'allFamilies', 'aLLProduct', 'allOrigins', 'initDate', 'endDate']),
  },
  beforeMount() {
    this.getAllSlices();
    this.initStoreGraph();
    this.initStoreRegister();
  },
  methods: {
    sundays(date) {
      return [0].indexOf(date.getDay()) >= 0;
    },
    onSelectedSlice(e) {
      this.setSlice(e.value);
      this.setFamily('');
      this.actualFamily = '';
      this.setProduct('');
      this.actualProduct = '';
      this.setOrigin('');
      this.actualOrigin = '';
      // llamada a la familia con el valor de lonja.
      this.getAllFamilies();
    },
    onSelectedFamily(e) {
      this.actualFamily = e.value;
      this.setFamily(e.value);
      this.setProduct('');
      this.actualProduct = '';
      this.setOrigin('');
      this.actualOrigin = '';
      // llamada al producto con el valor de lonja y de familia.
      if (this.actualFamily !== '') {
        this.getProductForFamilyAndSlices();
      }
    },
    onSelectedProduct(e) {
      this.setProduct(e.value);
      this.setOrigin('');
      this.actualOrigin = '';
      this.actualProduct = e.value;
      // llamada al origen con el valor de lonja,familia y fechas.
      if (this.actualProduct !== '') {
        this.getOrigins();
      }
    },
    onSelectedInitDate(e) {
      this.setInitDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    onSelectedEndDate(e) {
      this.setEndDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    onSelectedOrigin(e) {
      this.setOrigin(e.value);
    },
    ...mapActions('Slices', ['getAllSlices', 'setSlice', 'setFamily',
      'setInitDate', 'setEndDate', 'setOrigin', 'setProduct', 'getAllCities', 'getAllFamilies',
      'getProductForFamilyAndSlices', 'getData', 'getOrigins', 'initStoreGraph', 'initStoreRegister']),
  },
};
</script>

<style>

</style>
