import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import { mapState, mapActions, mapGetters } from 'vuex';

import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
  DxSorting,
  DxScrolling,
  DxPaging,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';
import { keyValuesService } from '../../../services/keyValuesService';
import { treatmentPlanPeriod } from '../../../services/treatmentPlanService';

export default {
  name: 'dosage-product',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxAutocomplete,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxScrolling,
    DxPaging,
    DxSorting,
  },
  props: [],
  data() {
    return {
      productVademecums: [],
      productResume: [],
      treatmentTypes: [
        this.$t('treatment.treatmentCreateOrder.form.foliar'),
        this.$t('treatment.treatmentCreateOrder.form.fertigacion'),
      ],
      planPeriods: [],
      dosages: [
        'kg-L / ha',
        'kg-L / 1000 L',
      ],
      productVademecumSelected: '',
      productVademecumSelectedValue: '',
      currentAgent: '',
    };
  },
  computed: {
    ...mapState('treatmentStepper', ['treatmentPlan', 'treatmentProducts', 'treatmentProduct']),
    ...mapState('Vademecum', ['optionsPlagueFilter']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapGetters('treatmentStepper', ['treatmentError']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      await this.getOptionsPlagueFilter(this.currentPlantation.species);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },

  created() {
    keyValuesService.getByFamily('TREATMENT_TYPE').then((response) => {
      this.treatmentTypes = response[0].values;
    });
    keyValuesService.getByFamily('DOSE_TYPE').then((response) => {
      this.dosages = response[0].values;
    });
    keyValuesService.getByFamily('PLAN_PERIOD_TYPE').then((response) => {
      this.planPeriods = response[0].values;
    });
    if (!this.treatmentPlan.init_date) {
      this.treatmentPlan.init_date = moment().toString();
      this.treatmentPlan.end_date = moment().add(3, 'M').toString();
    }

    if (this.treatmentPlan.agent) {
      this.updatePlagueProduct(this.treatmentPlan.agent);
    }
  },
  methods: {
    selectProductItem(e) {
      let eEvent = !e.event;
      if (this.currentAgent !== 'TIMAC') {
        eEvent = true;
      }
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
      }
      if (eEvent && e.previousValue !== this.productVademecumSelected.id) {
        this.productVademecumSelected = this.productVademecums
          .find((item) => item.id === e.value);
        this.productVademecumSelectedValue = this.productVademecumSelected.name;
      }
    },
    async addProductFertilizer() {
      try {
        if (this.productVademecumSelected === '') {
          this.$f7.dialog.alert(this.$t('blank_agent'));
          Promise.resolve();
          return;
        }
        this.$f7.preloader.show();
        await this.fetchTreatmentProduct({
          id: this.productVademecumSelected.id,
          crop: this.currentPlantation.species,
          agent: this.currentAgent,
        });
        let dosageRange = '';
        if (this.treatmentProduct.dose !== null) {
          dosageRange = `${parseFloat(this.treatmentProduct.dose.min).toFixed(2)} - ${parseFloat(this.treatmentProduct.dose.max).toFixed(2)} (${this.treatmentProduct.dose.unit})`;
        }
        let noApplications = 0;
        if (this.treatmentProduct.application_num !== null) {
          noApplications = this.treatmentProduct.application_num.max;
        }
        this.treatmentPlan.products.push({
          name: this.treatmentProduct.name,
          dosage_range: dosageRange,
          dose: this.treatmentProduct.dose ? {
            min: this.treatmentProduct.dose.min,
            max: this.treatmentProduct.dose.max,
          } : null,
          noApplications,
        });
        this.addTimeValues();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async onChangePlague(e) {
      await this.updatePlagueProduct(e.value);
    },
    async updatePlagueProduct(agent) {
      try {
        this.$f7.preloader.show();
        await this.fetchTreatmentProducts({ crop: this.currentPlantation.species, agent });
        this.productVademecums = this.treatmentProducts;
        this.productVademecumSelected = '';
        this.productVademecumSelectedValue = '';
        this.currentAgent = agent;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    addTimeValues() {
      const timeValues = [];
      const dateStart = moment(this.treatmentPlan.init_date).locale('es');
      const dateEnd = moment(this.treatmentPlan.end_date).locale('es');
      const arrayIndex = timeValues.length;
      switch (this.treatmentPlan.period) {
        case treatmentPlanPeriod.WEEKLY:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
            if (!timeValues[arrayIndex]) {
              timeValues.push({ [varName]: 0 });
            } else {
              timeValues[arrayIndex][varName] = 0;
            }
            dateStart.add(1, 'week');
          }
          break;
        case treatmentPlanPeriod.MONTHLY:
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
            if (!timeValues[arrayIndex]) {
              timeValues.push({ [varName]: 0 });
            } else {
              timeValues[arrayIndex][varName] = 0;
            }
            dateStart.add(1, 'month');
          }
          break;
      }
      if (typeof this.treatmentPlan.totalApplications === 'undefined') {
        this.treatmentPlan.totalApplications = [];
      }

      this.treatmentPlan.timeValues = this.treatmentPlan.timeValues.concat(timeValues);
      this.treatmentPlan.totalApplications.push({ total: 0 });
      this.treatmentPlan.dosages.push({ dosage: 0 });
    },
    calcTotalBalance(array) {
      array.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          if (prop) {
            totalProps += item[prop];
          }
        }
        this.treatmentPlan.dosages[index].dosage = totalProps;
      });
    },
    calcTotalApplications(array) {
      array.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          if (prop) {
            if (item[prop] > 0) {
              totalProps += 1;
            }
          }
        }

        this.treatmentPlan.totalApplications[index].total = totalProps;
      });
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(this.$t('dosageProduct.deleteDialogMessage',
        { product: this.treatmentPlan.products[item.row.rowIndex].name }),
      this.$t('dosageProduct.deleteDialogTitle'),
      () => {
        this.treatmentPlan.timeValues.splice(item.row.rowIndex, 1);
        this.treatmentPlan.dosages.splice(item.row.rowIndex, 1);
        this.treatmentPlan.products.splice(item.row.rowIndex, 1);
        this.treatmentPlan.totalApplications.splice(item.row.rowIndex, 1);
      });
    },
    resetDosageProducts() {
      this.treatmentPlan.products = [];
      this.treatmentPlan.dosages = [];
      this.treatmentPlan.timeValues = [];
    },
    ...mapActions('Vademecum', ['getOptionsPlagueFilter']),
    ...mapActions('treatmentStepper', ['fetchTreatmentProducts', 'fetchTreatmentProduct']),
  },
  watch: {
    'treatmentPlan.timeValues': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val);
        this.calcTotalApplications(val);
      },
      deep: true,
    },
  },
};
