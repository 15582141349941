<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Title_page_weather')}`" />
    <WeatherComponent />
    <f7-block class="display-flex justify-content-flex-end">
      <f7-button
        class="dx-btn-success margin-top-half"
        style="min-width: 120px;"
        raised
        type="success"
        styling-mode="contained"
        @click="addSeriesGraphic"
      >
        {{ $t("Button_add_weather") }}
      </f7-button>
    </f7-block>
    <GraphicWeatherComponent
      v-if="loaded"
      :date="date"
    />
  </f7-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import WeatherComponent from '../../components/analisys/weather/registerWeather/index.vue';
import GraphicWeatherComponent from '../../components/analisys/weather/graphicWeather/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'WeatherPage',
  components: {
    WeatherComponent,
    GraphicWeatherComponent,
    navbar,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('weather', ['initDate', 'endDate', 'plantationSelected', 'campaign', 'parameterSelected', 'dataDate', 'clientSelected']),
  },
  beforeMount() {
    this.initStoreRegister();
    this.initStoreGraph();
  },
  methods: {
    async addSeriesGraphic() {
      this.loaded = false;
      if (this.clientSelected === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_name_require'));
      } else if (this.campaign === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_campaign_require'));
      } else if (this.parameterSelected === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_parameter_required'));
      } else if (this.initDate === '' || this.initDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_validation'));
      } else if (this.endDate === '' || this.endDate === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_finalizacion_validation'));
      } else if (moment(this.endDate).diff(moment(this.initDate), 'days') > 30) {
        this.$f7.dialog.alert(this.$t('error_range_date'));
      } else if (this.initDate > this.endDate) {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'));
      } else if (this.plantationSelected === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_plantation_require'));
      } else {
        try {
          this.$f7.preloader.show();
          await this.getWeatherParameter();
          this.date = this.dataDate;
          this.loaded = true;
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },

    ...mapActions('weather', ['getWeatherParameter', 'initStoreRegister', 'initStoreGraph']),
  },
};
</script>
<style scoped>
@import './weather.styles.scss';
.dx-btn-success{
  width: auto;
}
</style>
