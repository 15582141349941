<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${$t('LeftPanel_subtitle_campaña')}`" />
    <RegisterCampania :current-campaign="{name: name, initDate: init_date, endDate: end_date }" />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewCampania"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterCampania from '../../components/campaña/viewCampania/registerCampania/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'RegisterCampaniaPage',
  components: {
    RegisterCampania,
    navbar,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Campania', ['name', 'init_date', 'end_date', 'id']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  async beforeMount() {
    this.setShowRegisterSuccessCampaignPopup(false);
    // cargamos las localizaciones de nivel 0 para cuando le demos a guardar.
    try {
      await this.getListExplotation();
      if (this.id !== '') {
        await this.getCampaignsById(this.id);
      }
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    async createNewCampania() {
      if (this.name === '') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_nombre_validation'));
      } else if (this.init_date === '' || this.init_date === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_validation'));
      } else if (this.end_date === '' || this.end_date === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_finalizacion_validation'));
      } else if (this.init_date > this.end_date) {
        this.$f7.dialog.alert(this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'));
      } else {
        try {
          if (this.id === '') {
            await this.createCampaign();
          } else {
            await this.updateCampaign(this.id);
          }
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.setShowRegisterSuccessCampaignPopup(true);
        }
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    ...mapActions('Campania', ['setShowRegisterSuccessCampaignPopup', 'getPlantationByCompany', 'getListExplotation', 'getCampaignsById', 'createCampaign', 'updateCampaign']),
  },
};
</script>

<style scoped>
</style>
