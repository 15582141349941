<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block>{{ $t('TaskDefinition.edit_task') }}</f7-block>
      <TaskDefinitionForm
        :activities="activities"
        :performance-units="performanceUnits"
        :current-task-definition="currentTaskDefinition"
      />
    </div>
    <f7-row v-if="loaded">
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          @click="goToTasks"
        >
          {{ $t("Go_Back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="editTask"
        >
          {{ $t("Button_save_text") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import TaskDefinitionForm from '../../../components/configuration/tasks/taskDefinitionForm/index.vue';

export default {
  name: 'EditTaskDefinition',
  components: {
    navbar,
    TaskDefinitionForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('TaskDefinition', ['activities', 'performanceUnits', 'currentTaskDefinition']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchActivities();
      await this.fetchPerformanceUnits();
      await this.getTaskDefinitionById(this.currentTaskDefinition.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async editTask() {
      if (this.currentTaskDefinition.code === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.code_validation'));
      } else if (this.currentTaskDefinition.name === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.name_validation'));
      } else if (this.currentTaskDefinition.activityCode === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.activity_code_validation'));
      } else if (this.currentTaskDefinition.unit === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.unit_validation'));
      } else if (this.currentTaskDefinition.performanceUnit === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.unit_performance_validation'));
      } else if (this.currentTaskDefinition.price === '') {
        this.$f7.dialog.alert(this.$t('TaskDefinition.price_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          const currentTaskDefinitionFormatted = {
            id: this.currentTaskDefinition.id,
            code: this.currentTaskDefinition.code,
            name: this.currentTaskDefinition.name,
            activity: this.getActivityNameByCode(this.currentTaskDefinition.activity_code),
            activity_code: this.currentTaskDefinition.activity_code,
            unit: this.currentTaskDefinition.unit,
            performance_unit: this.currentTaskDefinition.performance_unit,
            price: this.currentTaskDefinition.price,
            prescription: this.currentTaskDefinition.prescription,
            nursery: this.currentTaskDefinition.nursery,
          };
          await this.updateTaskDefinition(currentTaskDefinitionFormatted);
          this.$f7.views.main.router.navigate('/viewTaskDetail/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    getActivityNameByCode(activityCode) {
      for (const activity of this.activities) {
        if (activity.code === activityCode) {
          return activity.name;
        }
      }
      return '';
    },
    goToTasks() {
      this.initStore();
      this.$f7.views.main.router.navigate('/tasks/', { reloadCurrent: true });
    },
    ...mapActions('TaskDefinition', ['updateTaskDefinition', 'getTaskDefinitionById', 'fetchActivities', 'fetchPerformanceUnits', 'initStore']),
  },

};
</script>
