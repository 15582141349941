<template>
  <f7-page>
    <navbar :text="$t('ReportingWorker_navbar_title')" />
    <DxWorker />
  </f7-page>
</template>

<script>
import DxWorker from '../../components/reporting/DxWorker.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ReportingWorker',
  components: {
    DxWorker,
    navbar,
  },
};
</script>
