import { mapActions, mapGetters, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'stepper-navigation',
  components: { DxButton },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('treatmentStepper', ['treatmentSteps', 'currentStep', 'stepIndex']),
    ...mapState('Plantation', ['isPlantationSelected', 'oldPlantation']),
  },
  mounted() {

  },
  methods: {
    getNextLabel() {
      if (this.stepIndex === this.treatmentSteps.length - 1) {
        return this.$t('Utilities.stepperForm.save');
      }
      return this.$t('Utilities.stepperForm.next');
    },
    nextStep() {
      if (this.stepIndex === this.treatmentSteps.length - 1) {
        this.$emit('save');
        return;
      }
      this.next();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    backStep() {
      if (this.isPlantationSelected && this.stepIndex === 0) {
        this.setCurrentPlantation(this.oldPlantation);
        this.$f7.views.main.router.navigate('/viewCopyTreatmentPlan/', { reloadCurrent: true });
        return;
      }
      if (this.stepIndex === 0) {
        this.$f7.views.main.router.navigate('/searcher/treatment/',
          { reloadCurrent: true });
        return;
      }
      this.back();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    ...mapActions('treatmentStepper', ['next', 'back']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
  },
};
