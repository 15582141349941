<template>
  <f7-page class="main-viewcampania">
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualCampaign.name}`" />
    <ViewCampania />
    <div class="go-back-button margin-left margin-top">
      <f7-button
        class="dx-btn-cancel no-margin padding-horizontal margin-bottom"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t('Button_go_to_back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import ViewCampania from '../../components/campaña/viewCampania/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    ViewCampania,
    navbar,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Campania', ['actualCampaign']),
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
  },
};
</script>

<style lang="sass">
.main-viewcampania{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
