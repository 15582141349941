var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-block',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"col-span":1,"data-field":_vm.$t('layerOptions_select_index'),"caption":_vm.$t('layerOptions_select_index'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.indexAvailables,
          value: _vm.currentIndexes[("" + _vm.keyId)],
          placeholder: _vm.$t('layerOptions_index_availables'),
          onValueChanged: _vm.onSelectedIndex,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"init-date","data-field":_vm.$t('DxRegisterCampania_fecha_inicio'),"editor-type":"dxDateBox","editor-options":{
          value: _vm.actualInitDate,
          onValueChanged: _vm.onChangeInitDate,
          displayFormat: 'dd/MM/yyyy',
          placeholder: _vm.$t('registerClima_initDate_placeholder'),
          invalidDateMessage: _vm.$t('dates_isInvalid'),
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('DxRegisterCampania_fecha_finalizacion'),"editor-type":"dxDateBox","editor-options":{
          value: _vm.actualEndDate,
          onValueChanged: _vm.onChangeEndDate,
          displayFormat: 'dd/MM/yyyy',
          placeholder: _vm.$t('registerClima_endDate_placeholder'),
          invalidDateMessage: _vm.$t('dates_isInvalid'),
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }