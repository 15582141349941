import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
} from 'devextreme-vue/chart';
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { mapState, mapActions } from 'vuex';
import DxButton from 'devextreme-vue/button';
import treatmentPlanService from '@/services/treatmentPlanService';
import numeral from 'numeral';
import moment from 'moment';

const numeralFormat = '0,0.00';

export default {
  name: 'treatment-detail-plan',
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary,
    DxTotalItem,
    DxEditing,
    DxButton,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
  },
  props: {
    id: { type: String, default: '' },
    hideBackButton: { type: Boolean, default: false },
    hideDetail: { type: Boolean, default: false },
    hideReportButton: { type: Boolean, default: false },
    calledFromViewPlan: { type: Boolean, default: false },
  },
  data() {
    return {
      treatment: {},
      chartSeries: [],
      dosageIsHA: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Plans', ['currentPlan']),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      if (this.calledFromViewPlan) {
        await this.getPlanById({ planId: this.id, typePlan: 'treatments' });
        this.treatment = this.currentPlan;
      } else {
        this.treatment = await treatmentPlanService
          .getById(this.id, this.currentPlantation.companyId);
      }
      this.dosageIsHA = this.treatment.dose_type.toUpperCase() === 'KG-L/HA';
      const timeValuesTmp = this.treatment.products.map((product, index) => {
        const timeValues = this.getTimeValues(moment(this.treatment.init_date, 'YYYY/MM/DD'),
          moment(this.treatment.end_date, 'YYYY/MM/DD'),
          this.treatment.period);
        let counter = -1;
        for (const prop in timeValues) {
          timeValues[prop] = product.periods[counter += 1];
        }
        return timeValues;
      });

      const response = await treatmentPlanService.getById(this.treatment.id, this.currentPlantation.companyId);
      response.species = this.currentPlantation.species;
      await this.updateTreatmentPlan(response);

      this.treatment.products.forEach((item, index) => {
        const itemValue = timeValuesTmp[index];
        for (const prop in itemValue) {
          this.chartSeries.push({
            productName: item.product,
            monthWeek: prop,
            value: itemValue[prop],
          });
        }
      });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    customizeTotal(total) {
      return parseFloat(total.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('Dose')}: ${point.value} KG-L/HA</span> `,
      };
    },
    getTimeValues(dateStart, dateEnd, period) {
      const timeValues = {};
      switch (period) {
        case 'SEMANAL':
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
            timeValues[varName] = 0;
            dateStart.add(1, 'week');
          }
          break;
        case 'MENSUAL':
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MMM')} - ${dateStart.format('yy')}`;
            timeValues[varName] = 0;
            dateStart.add(1, 'month');
          }
          break;
      }
      return timeValues;
    },
    goToViewCopyTreatmentPlan() {
      this.$f7.views.main.router.navigate('/viewCopyTreatmentPlan/', { reloadCurrent: true });
    },
    calculateDosage(rowData) {
      let totalDosage = 0;
      if (this.dosageIsHA) {
        totalDosage = parseFloat((rowData.dosage_total * rowData.volumen) / 1000).toFixed(2);
      } else {
        totalDosage = rowData.periods.reduce((total, num) => total + num);
      }
      return numeral(totalDosage).format(numeralFormat);
    },
    calculateCaldoMedium(rowData) {
      return numeral(rowData.volumen).format(numeralFormat);
    },
    calculateDosageTotalKGL(row) {
      return parseFloat(row.dosage_total * this.currentPlantation.area).toFixed(2);
    },
    calculateTotalDosage(rowData) {
      if (this.dosageIsHA === false) {
        const totalL = parseFloat(rowData.total_cost.toString().replace(/,/g, ''));
        const kg = numeral(parseFloat(totalL / 1000) * rowData.volume).format(numeralFormat);
        return numeral(kg).format(numeralFormat);
      }
      return numeral(rowData.total_cost).format(numeralFormat);
    },
    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
    },
    viewReport() {
      this.$f7.views.main.router.navigate('/viewTreatmentReport/', { reloadCurrent: true });
    },
    ...mapActions('Plans', ['getPlanById']),
    ...mapActions('treatmentStepper', ['updateTreatmentPlan']),
  },
};
