import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'converter-molecular-weight',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsMolecularWeight']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsMolecularWeight[0]);
      this.unitSelected = { ...this.itemUnitsMolecularWeight[0] };
      this.unitStatic = { ...this.itemUnitsMolecularWeight[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitStatic = { ...e.value };
      this.unitSelected = { ...this.unitStatic };
    },
    changeEq(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.percent = this.roundValue(value * this.unitStatic.percent);
      this.unitSelected.moles = this.roundValue(value);
    },
    changePercent(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.eq = this.roundValue(value / this.unitStatic.percent);
      this.unitSelected.moles = this.roundValue(value / this.unitStatic.percent);
    },
    changeMol(e) {
      const value = parseFloat(e.event.target.value);
      this.unitSelected.percent = this.roundValue(value * this.unitStatic.percent);
      this.unitSelected.eq = this.roundValue(value);
    },
    roundValue(val) {
      return parseFloat(val.toFixed(5));
    },
  },
};
