<template>
  <div class="content-devx main-dx-binsinfoform no-shadow">
    <f7-block-title class="no-padding">
      {{ $t('BareRootsForm_bare_root_info') }}
    </f7-block-title>
    <DxForm class="content-binsinfoform">
      <template v-if="creating">
        <DxSimpleItem
          v-model="currentBareRootName"
          :validation-rules="validationRules.name"
          :editor-options="{
            mode: 'text',
            onValueChanged: bareRootNameChanged
          }"
          :data-field="$t('DxBinsInfoForm_name_caption')"
        />
      </template>
      <template v-else>
        <DxSimpleItem
          :validation-rules="validationRules.name"
          :editor-options="{
            value: currentBareRootName,
            mode: 'text',
            onValueChanged: bareRootNameChanged
          }"
          :data-field="$t('DxBinsInfoForm_name_caption')"
        />
      </template>
      <DxSimpleItem
        :data-field="$t('DxBinsInfoForm_date_caption')"
        name="date"
      >
        <template #default>
          <f7-input
            id="datepicker-multiple"
            class="form-calendar"
            type="datepicker"
            disabled
            readonly
            :calendar-params="{
              openIn: 'customModal',
              header: false,
              footer: true,
              dateFormat: 'yyyy-mm-dd'
            }"
            :value="[currentDate]"
          />
        </template>
      </DxSimpleItem>
      
      <DxSimpleItem
        name="plantingYear"
        :data-field="$t('DxPointForm_plantingYear_caption')"
        editor-type="dxDateBox"
        :editor-options="{
          value: getPlantingYear(),
          onValueChanged: plantingYearChanged,
          maxZoomLevel:'decade',
          minZoomLevel:'decade',
          max: currentDate,
          zoomLevel: 'decade',
          displayFormat: 'yyyy',
        }"
      />
      <DxSimpleItem
        name="levelZeroLocation"
        editor-type="dxSelectBox"
        :data-field="$t('DxBinsInfoForm_bins_location_caption')"
        :validation-rules="validationRules.levelZeroLocation"
        :editor-options="{
          dataSource: usersLevelZeroNames,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
          onItemClick: updateSelectedLevelZeroLocation,
          onValueChanged: updateSelectedLevelZeroLocation,
          value: bareRootsLocationName,
        }"
      />
    </DxForm>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment-timezone';
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';

import EventBus from '../../js/event-bus';

export default {
  name: 'BareRootsInfoForm',
  components: {
    DxForm,
    DxSimpleItem,

  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentBareRootName: '',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      validationRules: {
        name: [
          {
            type: 'required',
            message: 'Name is required',
          },
        ],
        levelZeroLocation: [
          {
            type: 'required',
            message: 'Farm is required',
          },
        ],
      },
      currentDate: moment().format('MM/DD/YYYY'),
      currentYear: moment().format('MM/DD/YYYY'),
    };
  },
  computed: {
    ...mapState('greenhouse',
      [
        'isLocationDisabled',
        'bareRootsLocationName',
      ]),
    ...mapGetters('greenhouse',
      [
        'getBareRootToEdit',
        'usersLevelZeroNames',
      ]),
    creating() {
      return !this.editing;
    },
  },
  beforeMount() {
    if (this.editing) {
      this.currentBareRootName = this.getBareRootToEdit.name;
      this.currentDate = this.getBareRootToEdit.creation;
      this.currentYear = moment(`01-31-${this.getBareRootToEdit.plant_year}`).format('MM/DD/YYYY');
    }
    this.updateBareRootsPlantingYear(moment(this.currentYear).format('YYYY'));
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'updateBareRootsLocationName',
        'updateBareRootsName',
        'updateBareRootsPlantingYear',
        'updateBareRootsLocationId',
      ]),
    getPlantingYear() {
      if (this.editing) {
        return moment(`01-31-${this.getBareRootToEdit.plant_year}`).format('MM/DD/YYYY');
      }
      return this.currentYear;
    },
    updateSelectedLevelZeroLocation(e) {
      if (e.itemData !== undefined) {
        this.updateBareRootsLocationName(e.itemData);
      } else if (e.value !== undefined) {
        this.updateBareRootsLocationName(e.value);
      }
      this.updateBareRootsLocationId('');
      EventBus.$emit('level-zero-location-changed');
    },
    bareRootNameChanged(e) {
      this.currentBareRootName = e.value;
      this.updateBareRootsName(e.value);
    },
    plantingYearChanged(e) {
      this.currentYear = moment(e.value).format('MM/DD/YYYY');
      this.updateBareRootsPlantingYear(moment(e.value).format('YYYY'));
    },
  },

};
</script>

<style lang="scss" scoped>
.ha-card {
    padding: 20px;
    margin-top: 30px;
}

</style>
