<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning content-products no-margin no-shadow"
    >
      <DxDataGrid
        id="productsGrid"
        ref="productsGrid"
        :data-source="productsFormated"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxViewWorkOrderProducts"
        />
        <DxExport
          :enabled="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="product.description"
          name="product.description"
          :caption="$t('DxProducts_dxColumn_productName')"
          :allow-editing="false"
        />
        <DxColumn
          data-field="activeIngredients"
          name="Description"
          :caption="$t('DxProducts_dxColumn_activeIngredients')"
        />
        <DxColumn
          data-field="qty_planned"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_qtyPlanned')"
        />
        <DxColumn
          data-field="qty_planned_unit"
          alignment="left"
          data-type="string"
          name="qty_planned_unit"
          :caption="$t('DxProductPerformance_unit')"
          :allow-editing="false"
          :calculate-cell-value="getStandardUnit"
          :width="100"
        />
        <DxColumn
          data-field="qty_collected"
          data-type="number"
          :caption="$t('DxProductPerformance_products_qtyCollected')"
          width="120"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="qty_collected_unit"
          alignment="left"
          data-type="string"
          name="qty_collected_unit"
          :caption="$t('DxProductPerformance_unit')"
          :allow-editing="false"
          :calculate-cell-value="getStandardUnit"
          :width="100"
        />
        <DxColumn
          data-field="total_real_prescription"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_totalRealPrescription')"
        />
        <DxColumn
          data-field="qty_used"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_qtyUsed')"
          :allow-editing="true"
        />
        <DxColumn
          data-field="qty_used_unit"
          alignment="left"
          data-type="string"
          name="qty_used_unit"
          :caption="$t('DxProductPerformance_unit')"
          :allow-editing="false"
          :calculate-cell-value="getStandardUnit"
        />
        <DxColumn
          data-field="dose_used"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_doseUsed')"
          :calculate-cell-value="calculateDoseUsed"
          :allow-editing="false"
        />
        <DxColumn
          data-field="dose_used_unit"
          alignment="left"
          data-type="string"
          name="dose_used_unit"
          :caption="$t('DxProductPerformance_unit')"
          :calculate-cell-value="calculateDoseUsedUnit"
          :allow-editing="false"
        />
        <DxColumn
          data-field="qty_return_warehouse"
          data-type="number"
          :editor-options="numberColumnOptions"
          :caption="$t('DxProductPerformance_products_qtyReturnWarehouse')"
          :calculate-cell-value="calculateQtyReturnWarehouse"
          :allow-editing="false"
        />
        <DxColumn
          data-field="qty_return_warehouse_unit"
          alignment="left"
          data-type="string"
          name="qty_return_warehouse_unit"
          :caption="$t('DxProductPerformance_unit')"
          :calculate-cell-value="getStandardUnit"
          :allow-editing="false"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxRequiredRule,
  DxExport,
  DxSearchPanel,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxPostWorkProducts',
  components: {
    DxDataGrid,
    DxColumn,
    DxRequiredRule,
    DxExport,
    DxSearchPanel,
    DxStateStoring,
  },
  props: {
    editable: { type: Boolean, default: () => true },
    products: { type: Array, default: () => [] },
    productPopup: { type: Array, default: () => [] },
  },
  data() {
    return {
      numberColumnOptions: {
        showSpinButtons: true, min: 0, showClearButton: true,
      },
      productsFormated: [],
    };
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    for (const product of this.products) {
      const productPerformance = this.getProductsPopupById(product.product.id);
      const productFormated = {
        id: product.product.id,
        activeIngredients: product.activeIngredients,
        caution: product.caution,
        dilution_factor: product.dilution_factor,
        dosis: product.dosis,
        dosisUt: product.dosisUt,
        dosis_total: product.dosis_total,
        product: product.product,
        productName: product.productName,
        solution: product.solution,
        solutionUt: product.solutionUt,
        solution_total: product.solution_total,
        qty_collected: productPerformance.qty_collected,
        qty_planned: productPerformance.qty_planned,
        qty_task: productPerformance.qty_task,
        qty_used: productPerformance.qty_used,
        total_real_prescription: productPerformance.total_real_prescription,
        updated: productPerformance.updated,
      };
      this.productsFormated.push(productFormated);
    }
  },
  methods: {
    getStandardUnit(rowData) {
      return (rowData.dosisUt.toLowerCase().includes('kg/')
      || rowData.dosisUt.toLowerCase().includes('gr/')) ? 'kg' : 'l';
    },
    getProductsPopupById(id) {
      for (let i = 0; i < this.productPopup.length; i += 1) {
        if (this.productPopup[i].item_id === id) {
          return this.productPopup[i];
        }
      }
      return null;
    },
    calculateQtyReturnWarehouse(rowData) {
      const value = rowData.qty_collected - rowData.qty_used;
      return `${value}`;
    },
    calculateDoseUsed(rowData) {
      return (rowData.dosisUt.toLowerCase().includes('gr/')
      || rowData.dosisUt.toLowerCase().includes('cc/')) ? rowData.qty_used * 1000 : rowData.qty_used;
    },
    calculateDoseUsedUnit(rowData) {
      return rowData.dosisUt.toLowerCase().substr(0, rowData.dosisUt.toLowerCase().indexOf('/'));
    },
  },
};
</script>
