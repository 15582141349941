import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '../../../components/wizardNutrition/stepper-navigation/index.vue';
import ElementResume from '../../../components/wizardNutrition/elementResume/index.vue';
import ProductResume from '../../../components/wizardNutrition/productResume/index.vue';
import { fertilizationService } from '../../../services/fertilizationService';

export default {
  name: 'nutrition-step-five',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    ProductResume,
    ElementResume,
    DxLoadPanel,
  },
  props: [],
  data() {
    return {
      loadingVisible: true,
      position: { of: '#nutrition-step-five' },
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapGetters('referenceValueStore', ['referenceValues']),
    ...mapState('nutritionStepper', ['productsNotDeleted']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('fertilizationStore', ['currentFertilizationId']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  mounted() {
    this.setProductsNotDeleted(false);
    if (this.productsNotInNutritionPlan.length > 0) {
      for (let i = 0; i < this.stepData.dosageProduct.length; i += 1) {
        if (this.stepData.dosageProduct[i].isProductDeleted) {
          this.setProductsNotDeleted(true);
          break;
        }
      }
    }
  },
  methods: {
    async save() {
      this.loadingVisible = true;
      try {
        // A veces estos valores están vacíos y el error no está controlado.
        if (this.referenceValues.length == 0) {
          throw new Error(this.$t('nutritionDetail.emptyReferenceValues'));
        }

        const payload = {
          plantation: this.currentPlantation.id,
          description: this.stepData.planDescription,
          init_date: moment(this.stepData.initDate).format('YYYY-MM-DD'),
          end_date: moment(this.stepData.endDate).format('YYYY-MM-DD'),
          period: this.stepData.temporalFrame.value,
          reference_values: {
            n: this.referenceValues[0].n,
            p205: this.referenceValues[0].p,
            k20: this.referenceValues[0].k,
            ca0: this.referenceValues[0].c,
            mg0: this.referenceValues[0].m,
            s03: this.referenceValues[0].s,
            production: this.referenceValues[0].production,
          },
          nutritional_needs_reference: {
            n: this.stepData.nutritionalNeeds[0].n,
            p205: this.stepData.nutritionalNeeds[0].p,
            k20: this.stepData.nutritionalNeeds[0].k,
            ca0: this.stepData.nutritionalNeeds[0].c,
            mg0: this.stepData.nutritionalNeeds[0].m,
            s03: this.stepData.nutritionalNeeds[0].s,
            expected_production: this.stepData.nutritionalNeeds[0].production,
          },
          nutritional_needs: {
            n: this.stepData.nutritionalNeeds[1].n,
            p205: this.stepData.nutritionalNeeds[1].p,
            k20: this.stepData.nutritionalNeeds[1].k,
            ca0: this.stepData.nutritionalNeeds[1].c,
            mg0: this.stepData.nutritionalNeeds[1].m,
            s03: this.stepData.nutritionalNeeds[1].s,
            production: this.stepData.nutritionalNeeds[1].production,
          },
          uf_available_soil: {
            n: this.stepData.nutritionalBalanceData[1].n,
            p205: this.stepData.nutritionalBalanceData[1].p,
            k20: this.stepData.nutritionalBalanceData[1].k,
            ca0: this.stepData.nutritionalBalanceData[1].c,
            mg0: this.stepData.nutritionalBalanceData[1].m,
            s03: this.stepData.nutritionalBalanceData[1].s,
          },
          uf_irrigation_water: {
            n: this.stepData.nutritionalBalanceData[2].n,
            p205: this.stepData.nutritionalBalanceData[2].p,
            k20: this.stepData.nutritionalBalanceData[2].k,
            ca0: this.stepData.nutritionalBalanceData[2].c,
            mg0: this.stepData.nutritionalBalanceData[2].m,
            s03: this.stepData.nutritionalBalanceData[2].s,
          },
          uf_rest_harvest: {
            n: this.stepData.nutritionalBalanceData[3].n,
            p205: this.stepData.nutritionalBalanceData[3].p,
            k20: this.stepData.nutritionalBalanceData[3].k,
            ca0: this.stepData.nutritionalBalanceData[3].c,
            mg0: this.stepData.nutritionalBalanceData[3].m,
            s03: this.stepData.nutritionalBalanceData[3].s,
          },
          contribution_products: this.stepData.dosageProduct.map((product, index) => {
            product.product = product.id;
            product.p205 = product.p;
            product.k20 = product.k;
            product.ca0 = product.c;
            product.mg0 = product.m;
            product.s03 = product.s;
            product.periods = [];
            for (const prop in this.stepData.timeValues[index]) {
              product.periods.push(this.stepData.timeValues[index][prop]);
            }
            product.total_cost = this.stepData.productResume[index].costTotal;
            product.unit_cost = this.stepData.productResume[index].costUnit;
            product.price = this.stepData.productResume[index].price;
            return product;
          }),
        };

        if (this.productsNotDeleted) {
          this.$f7.dialog.alert(this.$t('nutritionDosificationProblem'));
          return;
        }

        this.setProductsNotInNutritionPlan([]);

        if (this.isPlantationSelected) {
          await fertilizationService.save(payload, this.currentPlantation.companyId);

          this.initNutritionalSteps();
          this.setOldPlantation(this.currentPlantation);

          if (this.currentFertilizationId) {
            this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
          } else {
            this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
          }

          return;
        }

        if (this.stepData.nutritionalId !== '') {
          await fertilizationService.edit(this.stepData.nutritionalId, payload, this.currentPlantation.companyId);
          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        } else {
          if (typeof payload.description === 'undefined' || payload.description === '') {
            this.$f7.dialog.alert(this.$t('nutritionDetail.descriptionValidation'));
            return;
          }
          await fertilizationService.save(payload, this.currentPlantation.companyId);

          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        }
      } catch (error) {
        let errorMessage = '';

        // Los errores pueden ser de varios tipos. En el caso de que sea un objeto Error,
        //   el mensage viene en la propiedad 'message'.
        // Si es otro tipo de error, por ejemplo, un string. Se usa como tal.
        if (error.message) {
          errorMessage = error.message;
        } else {
          errorMessage += error;
        }

        if (errorMessage.includes('date_range_invalid')) {
          this.$f7.dialog.alert(this.$t('nutritionDetail.dateRangeInvalid'));
        } else {
          this.$f7.dialog.alert(error);
        }
      } finally {
        this.loadingVisible = false;
      }
    },
    initNutritionalSteps() {
      this.stepData.nutritionalNeeds = [];
      this.stepData.expectedProduction = 1;
      this.stepData.planDescription = '';
      this.stepData.initDate = '';
      this.stepData.endDate = '';
      this.stepData.temporalFrame = '';
      this.stepData.nutritionalBalanceData = [];
      this.stepData.timeValues = [];
      this.stepData.dosages = [];
      this.stepData.dosageProduct = [];
      this.stepData.dosageProductBalance = [];
      this.stepData.productResume = [];
    },
    ...mapActions('Plantation', ['setOldPlantation']),
    ...mapActions('nutritionStepper', ['setProductsNotDeleted']),
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};
