<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.productsTitle')}`" />
    <ProductsDatabase
      v-if="loaded"
      :products="products"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ProductsDatabase from '../../../components/configuration/products/productsDatabase/index.vue';

export default {
  name: 'Products',
  components: {
    navbar,
    ProductsDatabase,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Product', ['products']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchProducts();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('Product', ['fetchProducts']),
  },
};
</script>
