<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Observations_Title')}`" />
    <ObservationsFormsDatabase
      :observations-templates="observationsTemplates"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ObservationsFormsDatabase from '../../../components/configuration/observations/observationsFormsDatabase/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Observations',
  components: {
    ObservationsFormsDatabase,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Observations', ['observationsTemplates']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchObservationsTemplates();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('Observations', ['fetchObservationsTemplates']),
  },
};
</script>
