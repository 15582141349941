var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"show-colon-after-label":false}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Searcher.observations.name'),"editor-options":{
        onValueChanged: _vm.onChangeName,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Searcher.observations.latitude'),"editor-options":{
        onValueChanged: _vm.onChangeLatitude,
        value: _vm.currentPoint.latitude,
        readOnly:true,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Searcher.observations.longitude'),"editor-options":{
        onValueChanged: _vm.onChangeLongitude,
        value: _vm.currentPoint.longitude,
        readOnly:true,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }