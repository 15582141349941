<template>
  <div
    v-if="loaded"
    class="content-devx"
  >
    <f7-block-title>
      <span>{{ $t('title_task_by_company') }}</span>
    </f7-block-title>

    <DxDataGrid
      :data-source="tasks"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      @exporting="onExporting"
      @RowRemoved="callToDeleteProduct"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="Tasks"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxExport
        :enabled="false"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-deleting="true"
        :allow-updating="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxColumn
        data-field="activity"
        :caption="$t('taskByCompany_group')"
      />
      <DxColumn
        data-field="name"
        :caption="$t('taskByCompany_Task')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="init_date"
        :caption="$t('taskByCompany_init_date')"
        alignment="right"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />
      <DxColumn
        data-field="end_date"
        :caption="$t('taskByCompany_end_date')"
        alignment="right"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
      <DxColumn
        type="buttons"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="callToViewTask"
        />
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editTaskByCompany"
        />
        <DxButton
          name="delete"
          icon="trash"
        />
      </DxColumn>
    </DxDataGrid>

    <PopUpRegisterTask />
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
  DxEditing,
  DxTexts,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import PopUpRegisterTask from '../registerTask/index.vue';

export default {
  name: 'ListtaskByCompany',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxPaging,
    PopUpRegisterTask,
    DxEditing,
    DxTexts,
    DxButton,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('TaskByCompany', ['tasks', 'showPopUp']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async beforeMount() {
    this.loaded = false;
    this.$f7.preloader.show();
    try {
      // await this.getTasks(this.currentPlantation.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
      this.initStore();
    }
  },
  methods: {
    async callToViewTask(rowSelected) {
      try {
        await this.getTaskById(rowSelected.row.data.id);
        this.setIsView(true);
        this.setShowPopUp(true);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('listTaskByCompany');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'listTaskByCompany.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initStore();
            this.setShowPopUp(true);
          },
        },
      });
    },
    // Open edit pop up
    async editTaskByCompany(rowSelected) {
      try {
        await this.getTaskById(rowSelected.row.data.id);
        this.setIdTaskSelected(rowSelected.row.data.id);
        this.setShowPopUp(true);
        this.setIsEdit(true);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    callToDeleteProduct(rowSelected) {
      this.deleteTask(rowSelected.data.id);
    },
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText).format('DD-MM-YYYY');
    },
    ...mapActions('TaskByCompany', ['getTasks', 'setShowPopUp', 'setActualTask', 'deleteTask',
      'initStore', 'getTaskById', 'setIsEdit', 'setIdTaskSelected', 'setIdPlantation',
      'setIdCompany', 'setIsView']),
  },

};
</script>
<style scoped>
</style>
