<template>
  <div class="content-devx">
    <f7-block-title>
      <span>{{ `${$t('sector.register.title')}` }}</span>
    </f7-block-title>
    <f7-block>{{ $t('sector.register.step1') }}</f7-block>
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="2">
          <DxGroupItem :col-span="1">
            <DxSimpleItem
              :data-field="$t('sector.register.sectorName')"
              :editor-options="{
                value: currentSector.sectorName,
                mode: 'text',
                placeholder: $t('sector.register.sectorName'),
                showClearButton: true,
                onValueChanged: onValueChanged,
              }"
            >
              <DxRequiredRule :message="$t('sector.register.required_name')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('sector.register.groundTexture')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: groundTexturesAvailables,
                onValueChanged: onValueChanged,
                value: currentSector.groundTexture,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                placeholder: $t('select_placeholder'),
              }"
            >
              <DxRequiredRule :message="$t('sector.register.required_groundTexture')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('sector.register.irrigationType')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: irrigationTypesAvailables,
                onValueChanged: onValueChanged,
                value: currentSector.irrigationType,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                placeholder: $t('select_placeholder'),
              }"
            >
              <DxRequiredRule :message="$t('sector.register.required_irrigationType')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem :col-span="1" />
        </DxGroupItem>
      </DxForm>
      <DxForm
        v-if="currentSector.irrigationType === 'GOTEO'"
        id="formGoteo"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('sector.register.flowPerPlant')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.flow,
              placeholder: $t('sector.register.flowPerPlant'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
          <DxSimpleItem
            :data-field="$t('sector.register.dripperPerPlant')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.dripperPerPlant,
              placeholder: $t('sector.register.dripperPerPlant'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
        </DxGroupItem>
      </DxForm>
      <DxForm
        v-if="currentSector.irrigationType === 'ASPERSIÓN'"
        id="formAspersion"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="3">
          <DxSimpleItem
            :data-field="$t('sector.register.sprinklersNumber')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.sprinklersNumber,
              placeholder: $t('sector.register.sprinklersNumber'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
          <DxSimpleItem
            :data-field="$t('sector.register.flow360')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.flow,
              placeholder: $t('sector.register.flow360'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
          <DxSimpleItem
            :data-field="$t('sector.register.transformedArea')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.transformedArea,
              placeholder: $t('sector.register.transformedArea'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
        </DxGroupItem>
      </DxForm>
      <DxForm
        v-if="currentSector.irrigationType === 'INUNDACIÓN'"
        id="formInundacion"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :col-span="1"
            :data-field="$t('sector.register.flow')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentSector.flow,
              placeholder: $t('sector.register.flow'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          />
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapActions } from 'vuex';

export default {
  name: 'RegisterSectorStep1',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    groundTexturesAvailables: {
      type: Array,
      default: () => [],
    },
    irrigationTypesAvailables: {
      type: Array,
      default: () => [],
    },
    currentSector: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      loaded: true,
    };
  },
  methods: {
    onValueChanged(e) {
      this.loaded = false;
      let keyToUpdate = '';
      switch (e.element.getElementsByTagName('input')[0].getAttribute('name')) {
        case this.$t('sector.register.sectorName'):
          keyToUpdate = 'sectorName';
          break;
        case this.$t('sector.register.groundTexture'):
          keyToUpdate = 'groundTexture';
          break;
        case this.$t('sector.register.irrigationType'):
          keyToUpdate = 'irrigationType';
          this.resetDynamicForm();
          break;
        case this.$t('sector.register.dripperPerPlant'):
          keyToUpdate = 'dripperPerPlant';
          break;
        case this.$t('sector.register.sprinklersNumber'):
          keyToUpdate = 'sprinklersNumber';
          break;
        case this.$t('sector.register.transformedArea'):
          keyToUpdate = 'transformedArea';
          break;
        case this.$t('sector.register.flowPerPlant'):
        case this.$t('sector.register.flow360'):
        case this.$t('sector.register.flow'):
          keyToUpdate = 'flow';
          break;
        default:
          break;
      }
      this.updateCurrentSectorKey({ currentSectorKey: keyToUpdate, newValue: e.value });
      this.loaded = true;
    },
    ...mapActions('Sector', ['updateCurrentSectorKey', 'resetDynamicForm']),
  },
};
</script>
<style>
@import './RegisterSectorStep1.styles.scss';
</style>
