import {
  APPLICATION_NAME,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  APP_LOGO,
} from '../../js/constants';
import EventBus from '../../js/event-bus';
import Sync from '../../services/Sync.js';

export default {
  namespaced: true,

  state: {
    appConfig: {
      name: APPLICATION_NAME,
      logoUrl: APP_LOGO,
      apiBaseUrl: null,
      primaryColor: DEFAULT_PRIMARY_COLOR,
      secondaryColor: DEFAULT_SECONDARY_COLOR,
    },
    loggedUser: null,
    wantToRemember: false,
    wantToRecoveryPass: false,
    changePassword: false,
    messageChangePassword: false,
    currentLocation: null,
    user: null,
    actualMenuSelected: undefined,
  },

  getters: {
    getWantToRemember: (state) => state.wantToRemember,
    getPermissions: (state) => state.user.user.contact.permissions,
    getActualMenuSelected: (state) => state.actualMenuSelected,
  },

  actions: {
    initialize(context) {
      if (context.state.loggedUser) {
        EventBus.$emit('newLoggedUser', context.state.loggedUser);
      }
      EventBus.$on('invalidTokenDetected', () => context.dispatch('logout'));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('setLoggedUser', newLoggedUser));
      EventBus.$on('newLoggedUser', (newLoggedUser) => context.commit('setUser', JSON.parse(newLoggedUser)));
    },

    async logout(context) {
      context.commit('setLoggedUser', null);
      context.commit('setWantToRemember', false);
      context.commit('setActualMenuSelected', undefined);
      Sync.clearDatabase();
      await context.dispatch('AccessControl/destroy', null, { root: true });

      window.localStorage.clear();
      window.location.reload();
    },

    selectLocation(context, value) {
      context.commit('setCurrentLocation', value);
    },

    wantToRecoveryPass(context) {
      context.commit('wantToRecoveryPass');
    },

    changePassword(context) {
      context.commit('changePassword');
    },

    setMessageChangePassword(context) {
      context.commit('setMessageChangePassword');
    },
    setActualMenuSelected({ commit }, payload) {
      commit('setActualMenuSelected', payload);
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setLoggedUser(state, user) {
      state.loggedUser = user;
    },

    setAppConfig(state, value) {
      state.appConfig = value;
    },

    setWantToRemember(state) {
      state.wantToRemember = !state.wantToRemember;
    },

    wantToRecoveryPass(state) {
      state.wantToRecoveryPass = !state.wantToRecoveryPass;
    },

    changePassword(state) {
      state.changePassword = !state.changePassword;
    },

    setMessageChangePassword(state) {
      state.messageChangePassword = !state.messageChangePassword;
    },
    setActualMenuSelected(state, payload) {
      state.actualMenuSelected = payload;
    },
  },
};
