<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Vademecum_Title')}`" />
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link
        href="./phytosanitary/"
        route-tab-id="tab-phytosanitary"
        @click="toShowPhytosanitaryTab()"
      >
        {{ $t('Phytosanitary_Products') }}
      </f7-link>
      <f7-link
        tab-link
        href="./nutritional/"
        route-tab-id="tab-nutritional"
        @click="toShowNutritionalTab()"
      >
        {{ $t('Nutritional_Products') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      animated
      routable
    >
      <f7-tab
        id="tab-phytosanitary"
        class="page-content block"
      >
        <phytosanitaryProducts v-if="!flagToShowTabs" />
      </f7-tab>
      <f7-tab
        id="tab-nutritional"
        class="page-content block"
      >
        <nutritionalProducts v-if="flagToShowTabs" />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';
import phytosanitaryProducts from '../../../components/utilities/vademecum/phytosanitaryProducts/index.vue';
import nutritionalProducts from '../../../components/utilities/vademecum/nutritionalProducts/index.vue';

export default {
  name: 'Vademecum',
  components: {
    navbar,
    phytosanitaryProducts,
    nutritionalProducts,
  },

  data() {
    return {
      flagToShowTabs: false,
    };
  },
  methods: {
    toShowPhytosanitaryTab() {
      this.flagToShowTabs = false;
    },
    toShowNutritionalTab() {
      this.flagToShowTabs = true;
    },
  },
};
</script>
