<template>
  <DxForm :col-count="2">
    <DxGroupItem>
      <DxSimpleItem
        name="code"
        :data-field="$t('code')"
        :editor-options="{
          value: currentProduct.code,
          onValueChanged: onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="name"
        :data-field="$t('DxProduct_nameCommercial')"
        :editor-options="{
          value: currentProduct.name,
          onValueChanged: onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="ecology"
        :data-field="$t('DxProduct_ecology')"
        :editor-options="{
          value: currentProduct.ecologic,
          onValueChanged: onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="family"
        :data-field="$t('DxFormProduct_family_caption')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: productFamilies,
          value: currentProduct.family,
          onValueChanged: onValueChanged,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="subfamily"
        :data-field="$t('DxFormProduct_subfamily_caption')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: productSubfamilies,
          value: currentProduct.subfamily,
          onValueChanged: onValueChanged,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        :visible="currentProduct.family === 'PHITOSANITARY' || currentProduct.family === 'SUBSTRATE'"
        name="component"
        :data-field="$t('DxFormProduct_component_caption')"
        :editor-options="{
          value: currentProduct.component,
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="doseUnit"
        :data-field="$t('DxFormProduct_sapunit_caption')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: doseUnits,
          value: currentProduct.dosis.unit,
          onValueChanged: onValueChanged,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="MaxDosisPermitida"
        :data-field="$t('DxFormProduct_maxdosis')"
        editor-type="dxNumberBox"
        :editor-options="{
          showSpinButtons: true,
          mode: 'number',
          value: currentProduct.dosis.max_dosis,
          showClearButton: true,
          min: '0',
          onValueChanged: onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="Tipology"
        :data-field="$t('DxProduct_tipology')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: tipologies,
          value: currentProduct.tipology,
          onValueChanged: onValueChanged,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
        }"
      >
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="toxicity"
        :data-field="$t('DxFormProduct_toxicity_caption')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: toxicities,
          value: $helpers.getProperty(currentProduct.properties, 'toxicity'),
          onValueChanged: onValueChanged,
          searchEnabled: true,
          searchTimeout: searchTimeoutOption,
          minSearchLength: minSearchLengthOption,
          showDataBeforeSearch: showDataBeforeSearchOption,
        }"
      />
    </DxGroupItem>
    <DxGroupItem>
      <DxSimpleItem
        name="status"
        :data-field="$t('DxFormProduct_status_caption')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: currentProduct.status,
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="SCSauthorized"
        :data-field="$t('DxFormProduct_SCS_autorizado')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: formattingValue($helpers.getProperty(currentProduct.properties, 'SCS_authorized')),
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="EUauthorized"
        :data-field="$t('DxFormProduct_EU_autorizado')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: formattingValue($helpers.getProperty(currentProduct.properties, 'EU_authorized')),
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="preHarvest"
        :data-field="$t('DxFormProduct_preHarvest')"
        editor-type="dxNumberBox"
        :editor-options="{
          showSpinButtons: true,
          mode: 'number',
          showClearButton: true,
          min: '0',
          value: $helpers.getProperty(currentProduct.properties, 'preharvest'),
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="IntervalIn"
        :data-field="$t('DxFormProduct_currentIn')"
        editor-type="dxNumberBox"
        :editor-options="{
          showSpinButtons: true,
          mode: 'number',
          value: $helpers.getProperty(currentProduct.properties, 'intervalIn'),
          showClearButton: true,
          min: '0',
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="description"
        :data-field="$t('DxFormProduct_description_caption')"
        editor-type="dxTextArea"
        :editor-options="{
          value: $helpers.getProperty(currentProduct.properties, 'description'),
          onValueChanged: onValueChangedDescription,
          maxLength: 256,
        }"
      />
      <DxSimpleItem
        name="comment"
        :data-field="$t('DxFormProduct_comment_caption')"
        editor-type="dxTextArea"
        :editor-options="{
          value: $helpers.getProperty(currentProduct.properties, 'comment'),
          onValueChanged: onValueChangedComment,
          maxLength: 256,
        }"
      />
    </DxGroupItem>
  </DxForm>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxForm, DxSimpleItem, DxGroupItem, DxRequiredRule,
} from 'devextreme-vue/form';

export default {
  name: 'ProductForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    currentProduct: {
      type: Object,
      default: () => {},
    },
    productFamilies: {
      type: Array,
      default: () => [],
    },
    productSubfamilies: {
      type: Array,
      default: () => [],
    },
    toxicities: {
      type: Array,
      default: () => [],
    },
    doseUnits: {
      type: Array,
      default: () => [],
    },
    tipologies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      filteredProductSubfamilies: [],
    };
  },
  methods: {
    onValueChanged(e) {
      const inputChanged = e.element.getElementsByTagName('input')[0].getAttribute('name');
      let property = '';
      let { value } = e;
      switch (inputChanged) {
        case this.$t('code'):
          property = 'code';
          break;
        case this.$t('DxProduct_nameCommercial'):
          property = 'name';
          break;
        case this.$t('DxProduct_ecology'):
          property = 'ecologic';
          break;
        case this.$t('DxFormProduct_status_caption'):
          property = 'status';
          break;
        case this.$t('DxFormProduct_family_caption'):
          property = 'family';
          this.fetchSubfamilyProducts(value);
          this.updateCurrentProductProperty({ property: 'subfamily', newValue: '' });
          if (value !== 'PHITOSANITARY') {
            this.updateCurrentProductProperty({ property: 'component', newValue: '' });
          }
          break;
        case this.$t('DxFormProduct_subfamily_caption'):
          property = 'subfamily';
          break;
        case this.$t('DxFormProduct_component_caption'):
          property = 'component';
          break;
        case this.$t('DxFormProduct_sapunit_caption'):
          property = 'dosis';
          value = { max_dosis: this.currentProduct.dosis.max_dosis, unit: e.value };
          break;
        case this.$t('DxFormProduct_maxdosis'):
          property = 'dosis';
          value = { max_dosis: e.value, unit: this.currentProduct.dosis.unit };
          break;
        case this.$t('DxProduct_tipology'):
          property = 'tipology';
          break;
        case this.$t('DxFormProduct_SCS_autorizado'):
          property = 'properties';
          value = { key: 'SCS_authorized', value: e.value.toString() };
          break;
        case this.$t('DxFormProduct_EU_autorizado'):
          property = 'properties';
          value = { key: 'EU_authorized', value: e.value.toString() };
          break;
        case this.$t('DxFormProduct_toxicity_caption'):
          property = 'properties';
          value = { key: 'toxicity', value: e.value };
          break;
        case this.$t('DxFormProduct_description_caption'):
          property = 'properties';
          value = { key: 'description', value: e.value };
          break;
        case this.$t('DxFormProduct_comment_caption'):
          property = 'properties';
          value = { key: 'comment', value: e.value };
          break;
        case this.$t('DxFormProduct_preHarvest'):
          property = 'properties';
          value = { key: 'preharvest', value: e.value };
          break;
        case this.$t('DxFormProduct_currentIn'):
          property = 'properties';
          value = { key: 'intervalIn', value: e.value };
          break;
        default:
          break;
      }
      this.updateCurrentProductProperty({ property, newValue: value });
    },
    onValueChangedDescription(e) {
      this.updateCurrentProductProperty({ property: 'properties', newValue: { key: 'description', value: e.value } });
    },
    onValueChangedComment(e) {
      this.updateCurrentProductProperty({ property: 'properties', newValue: { key: 'comment', value: e.value } });
    },
    formattingValue(value) {
      let formattedValue = false;
      if (value === 'true') {
        formattedValue = true;
      } else {
        formattedValue = false;
      }
      return formattedValue;
    },
    ...mapActions('Product', ['updateCurrentProductProperty', 'fetchSubfamilyProducts']),
  },
};
</script>
<style lang="scss" scoped>
@import './ProductForm.styles.scss';
</style>
