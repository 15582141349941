<template>
  <DxPopup
    id="varietyPopup-popup"
    :title="$t('variety')"
    :visible="showPopup"
    :show-close-button="true"
    :on-hidden="onHidden"
    :close-on-outside-click="false"
    height="auto"
    :width="700"
  >
    <DxForm :show-colon-after-label="false">
      <DxGroupItem>
        <DxSimpleItem
          :col-span="2"
          :data-field="$t('group')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: groupsAvailables,
            value: currentGroup,
            onValueChanged: onChangeGroup,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
        </DxSimpleItem>
        <DxSimpleItem
          :col-span="2"
          :data-field="$t('specie')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: speciesAvailables,
            value: currentSpecie,
            onValueChanged: onChangeSpecie,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
          }"
        >
          <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
        </DxSimpleItem>
        <DxSimpleItem
          :col-span="2"
          :data-field="$t('name')"
          :editor-options="{
            value: currentName,
            onValueChanged: onChangeName,
          }"
        >
          <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>

    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-save"
        raised
        styling-mode="outlined"
        name="ButtonSave"
        @click="savePopup"
      >
        {{ $t('Button_save_text') }}
      </f7-button>
    </f7-block-footer>
  </DxPopup>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'VarietyPopup',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    groupsAvailables: {
      type: Array,
      default: () => [],
    },
    speciesAvailables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
    };
  },
  computed: {
    ...mapState('Varieties', ['currentGroup', 'currentSpecie', 'currentName', 'showPopup', 'varietyDetail']),
  },
  methods: {
    onHidden() {
      this.setShowPopup(false);
      this.setCurrentGroup('');
      this.setCurrentSpecie('');
      this.setCurrentName('');
      this.setVarietyDetail({});
    },
    async savePopup() {
      this.$f7.preloader.show();
      try {
        if (Object.keys(this.varietyDetail).length === 0) {
          await this.createVariety();
        } else {
          await this.updateVariety(this.varietyDetail.id);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.setCurrentGroup('');
        this.setCurrentSpecie('');
        this.setCurrentName('');
        this.setVarietyDetail({});
        this.$f7.views.main.router.navigate('/varieties/', { reloadCurrent: true });
        this.setShowPopup(false);
      }
    },
    onChangeDescription(e) {
      this.setFileDescription(e.value);
    },
    onChangeGroup(e) {
      this.setCurrentGroup(e.value);
      if (e.value !== '') {
        this.getSpeciesAvailables(e.value);
      }
    },
    onChangeSpecie(e) {
      this.setCurrentSpecie(e.value);
    },
    onChangeName(e) {
      this.setCurrentName(e.value);
    },
    ...mapActions('Varieties', ['setCurrentGroup', 'setCurrentSpecie', 'setCurrentName', 'getSpeciesAvailables', 'setShowPopup', 'createVariety', 'updateVariety', 'setVarietyDetail']),
  },
};
</script>
<style lang="scss" scoped>
@import './VarietyPopup.styles.scss';
</style>
