<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-work-orders main-dx-work-orders"
  >
    <CalendarInitEndDate class="margin-top" style="position:relative; justify-content: flex-start;" :key-name="keyName" />
    <DxDataGrid
      id="gridContainer"
      :data-source="detailDailyTasks"
      :remote-operations="true"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        :storage-key="`${keyName}`"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxSelection
        mode="single"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="short_id"
        name="short_id"
        :caption="$t('DxWorkOrder_short_id')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="Activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :width="200"
        css-class="text-expand"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="variety"
        name="variety"
        :caption="$t('DxWorkOrders_variety_caption')"
        :allow-header-filtering="true"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="planned_area"
        name="planned_area"
        data-type="number"
        :caption="$t('DxDailyPlanning_workingArea_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="230"
      />
      <DxColumn
        data-field="real_area_effective"
        name="real_area_effective"
        data-type="number"
        :caption="$t('DxWorkOrder_RealworkingArea_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="190"
      />
      <DxColumn
        v-if="(keyName !== 'harvesting')"
        data-field="goal_performance"
        name="goal_performance"
        :caption="$t('DxWorkOrders_productivity_planned_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        v-else
        data-field="goal_performance"
        name="goal_performance"
        :caption="$t('DxMonthlyPlanning_goal_harvesting_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="unit"
        name="unit"
        :caption="$t('DxWorker_performanceUnitProductivity_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="productivity"
        name="productivity"
        :caption="$t('DxWorkOrders_productivity_caption')"
        :allow-header-filtering="false"
        alignment="center"
        width="140"
      />
      <DxColumn
        data-field="plannedWorkers"
        name="workers"
        :caption="$t('DxDailyPlanning_plannedWorkers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="realWorkers"
        name="workers"
        :caption="$t('DxDailyPlanning_realWorkers_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="Supervisor"
        name="supervisor"
        :caption="$t('DxDailyPlanning_supervisors_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        v-if="reporting"
        data-field="creation"
        name="creation"
        :caption="$t('DxDailyPlanning_creation_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        v-if="keyName === 'harvesting'"
        data-field="manualPerformance.total"
        name="manualPerformance"
        :caption="$t('DxWorkOrders_manualPerformance_caption')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="TaskStatus"
        name="task_status"
        :caption="$t('DxDailyPlanning_task_status_caption')"
        alignment="center"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="taskStatusTemplate"
      />
      <DxColumn
        data-field="WorkOrderStatus"
        name="work_order_status"
        width="160"
        alignment="center"
        :caption="$t('DxDailyPlanning_work_order_status_caption')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataWordTaskStatus }"
        cell-template="workOrderStatusTemplate"
      />
      <DxColumn
        data-field="additionalInfoWorkOrder"
        name="notes"
        :caption="$t('DxWorkOrders_notes_caption')"
        :allow-header-filtering="false"
        alignment="left"
        width="400"
        css-class="text-expand"
      />
      <DxColumn
        :buttons="optionsButtons"
        type="buttons"
        width="120"
        :fixed="true"
        fixed-position="right"
      />
      <template #taskStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.taskStatusTemplateClass">
          {{ detailDailyTasks.data.taskStatusTranslated }}
        </div>
      </template>
      <template #workOrderStatusTemplate="{ data: detailDailyTasks }">
        <div :class="detailDailyTasks.data.workOrderStatusTemplateClass">
          {{ detailDailyTasks.data.workOrderStatusTranslated }}
        </div>
      </template>
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template #master-detail="{ data: detailDailyTasks}">
        <DxDetailTabs
          :current-task-id="detailDailyTasks.data.ID"
          :locations-tree="locationTree"
        />
      </template>
      <template #exportAll>
        <ExportDataToExcel
          :fetch-tasks="true"
          :include-activities="includeActivities"
          :exclude-activities="excludeActivities"
          :key-name="keyName"
          :reporting="reporting"
          :supervisors-availables="supervisors"
        />
      </template>
    </DxDataGrid>
    <DxPopupWorkOrder
      v-if="visiblePopupWorkOrder"
      :machinery-popup="machineryPopup"
      :product-popup="productPopup"
      :tool-popup="toolPopup"
      :supervisor="supervisorPopup"
      :supervisors-availables="supervisors"
      :drivers-availables="drivers"
      :machineries="allItemsMachineries"
      :back="back"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import DxDetailTabs from './DxDetailTabs.vue';
import DxPopupWorkOrder from './DxPopupWorkOrder.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';
import ExportDataToExcel from '../ExportDataToExcel.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxPopupWorkOrder,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
    ExportDataToExcel,
  },
  props: {
    includeActivities: { type: Array, default: () => [] },
    excludeActivities: { type: Array, default: () => [] },
    keyName: { type: String, default: '' },
    reporting: { type: Boolean, default: false },
    plannedAndUnplanned: { type: Boolean, default: false },
  },
  data() {
    return {
      loaded: false,
      arrayExcelColumnTranslations: [],
      detailDailyTasks: [],
      finalLevelLocation: [],
      taskStatusTranslated: '',
      workOrderStatusTranslated: '',
      machineryPopup: [],
      productPopup: [],
      toolPopup: [],
      ocultarBotton: false,
      optionsButtons: [{
        icon: 'check',
        hint: this.$t('Confirm'),
        visible: this.isConfirmIconVisible,
        onClick: this.showConfirmationView,
      },
      {
        icon: 'edit',
        hint: this.$t('DxWorkOrders_edit_hint'),
        visible: this.isEditIconVisible,
        onClick: this.editWorkOrder,
      },
      {
        icon: 'trash',
        hint: this.$t('DxDailyPlanning_delete_hint'),
        visible: this.isDeleteIconVisible,
        onClick: this.deleteTask,
      },
      {
        icon: 'find',
        hint: this.$t('DxWorkOrder_Button_View_hint'),
        visible: this.isViewconVisible,
        onClick: this.viewtask,
      }],
      dataTaskStatus: [{
        text: this.$t('Status_Open'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Completed'),
        value: 'COMPLETED',
      },
      {
        text: this.$t('Status_InProgress'),
        value: 'IN_PROGRESS',
      }],
      dataWordTaskStatus: [{
        text: this.$t('Status_reallyNot_Closed'),
        value: 'NOT_COMPLETED',
      }, {
        text: this.$t('Status_Closed'),
        value: 'COMPLETED',
      }, {
        text: this.$t('Draft'),
        value: 'DRAFT',
      }],
      supervisorPopup: '',
      pageSizes: [5, 10, 20],
      allItemsMachineries: [],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
    ...mapState('PlanningManager', ['visiblePopupWorkOrder', 'back']),
    ...mapState('greenhouse', ['locationTree']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode', 'getLocationNameLevelSelectedChildId']),
    ...mapState('Employee', ['normalEmployees', 'supervisors', 'drivers']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    if (this.keyName === 'harvesting') {
      this.setBack('harvesting');
    } else {
      this.setBack('workOrders');
    }
    try {
      await this.fetchEmployeesSupervisorsDrivers();
      await this.fetchLocationTree();
      await this.fetchBinsContentFamilies();
      let xhr = await Api.fetchItemsSubfamilyByType('MACHINERY');
      this.allItemsMachineries = JSON.parse(xhr.response);
      this.allItemsMachineries = this.allItemsMachineries.filter((item) => item.id === 'VEHICLE');
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      xhr = await Api.fetchTasks(this.plannedAndUnplanned, this.includeActivities, this.excludeActivities, checkedInitEndDate.init, checkedInitEndDate.end, false);
      const dailyTasks = JSON.parse(xhr.response).data;
      for (const task of dailyTasks) {
        if (task.nursery) {
          task.nursery.forEach((element) => {
            const varietyName = this.getVarietyNameByCode(element.variety);
            const categoryName = this.getCategoryNameByCode(element.category);
            const classificationName = this.getClassificationNameByCode(element.class_type);
            element.variety = varietyName !== '' ? varietyName : element.variety;
            element.category = varietyName !== '' ? categoryName : element.category;
            element.class_type = classificationName !== '' ? classificationName : element.class_type;
          });
        }
        if (task.planner != null) {
          if (this.reporting && (task.status !== 'COMPLETED' || task.worder_status !== 'COMPLETED')) {
            continue;
          }
          let manuallyOrPlanner = '';
          if (task.planner.planner_id !== null) {
            manuallyOrPlanner = this.$t('DxDailyPlanning_CreationPlanned_caption');
          } else {
            manuallyOrPlanner = this.$t('DxDailyPlanning_CreationManually_caption');
          }
          const newTask = {
            ID: task.id,
            short_id: task.short_id,
            Date: moment(task.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            Activity: task.activity,
            Task: task.task,
            group: task.planner.group,
            shift: task.planner.shift,
            locationLevel2: this.getLocationsFormated(task.locations_tree, task.location, task.activity_code),
            variety: task.variety,
            planned_area: task.planner.area_effective,
            real_area_effective: this.calculateRealAreaEfective(task.workers),
            goal_performance: this.getGoalPerformance(task.planner),
            unit: this.getUnit(task.planner),
            productivity: this.calculateProductivity(task.workers),
            Workers: `${this.getTotalWorkers(task.workers)} / ${this.getTotalWorkersPlanner(task.planner.workers)}`,
            plannedWorkers: `${this.getTotalWorkersPlanner(task.planner.workers)}`,
            realWorkers: `${this.getTotalWorkers(task.workers)}`,
            Supervisor: this.getNameSupervisor(task.responsible),
            drivers: this.getVehicleDrivers(task.items_drivers),
            creation: manuallyOrPlanner,
            manualPerformance: task.performance,
            TaskStatus: task.status,
            taskStatusTemplateClass: this.setClassByTaskStatus(task.status),
            taskStatusTranslated: this.taskStatusTranslated,
            WorkOrderStatus: task.worder_status,
            workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(task.worder_status),
            workOrderStatusTranslated: this.workOrderStatusTranslated,
            additionalInfoWorkOrder: task.comment,
            Planner: task.planner,
            locations_tree: task.locations_tree,
            toolPopup: task.tools,
          };
          this.detailDailyTasks.push(newTask);
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getItems(itemsDriver) {
      const items = [];
      for (const itemDriver of itemsDriver) {
        items.push(itemDriver.item);
      }
      return items;
    },
    // POR CADA FILA QUE SEA TASKSTATUS = COMPLETED Y WORKWORDERSTATUS = COMPLETED DESHABILITO LOS BOTONES EDITAR Y BORRAR
    isDeleteIconVisible(e) {
      if (e.row.data.TaskStatus === 'COMPLETED' || e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isConfirmIconVisible(e) {
      if (!this.$helpers.havePermission(this.getPermissions.task_draft_update)) {
        return false;
      }
      if (!(e.row.data.WorkOrderStatus === 'DRAFT')) {
        return false;
      }
      return true;
    },
    isEditIconVisible(e) {
      if (e.row.data.WorkOrderStatus === 'COMPLETED' || e.row.data.WorkOrderStatus === 'DRAFT') {
        return false;
      }
      if (e.row.data.TaskStatus === 'IN_PROGRESS') {
        return false;
      }
      return true;
    },
    isViewconVisible(e) {
      return (e.row.data.WorkOrderStatus === 'COMPLETED' || e.row.data.WorkOrderStatus === 'DRAFT');
    },
    getLocationsFormated(locationsCompleted, ids, activityCode) {
      if (locationsCompleted.length !== 0) {
        const locationsNameCompleted = [];
        this.$helpers.fetchLocationsNames(locationsNameCompleted, locationsCompleted, this.locationTree);
        return locationsNameCompleted;
      }
      return this.getNamesByBdIds(ids, activityCode);
    },
    getNamesByBdIds(ids, activityCode) {
      const names = [];
      const level = this.$helpers.getLevelLocationToDisplay(activityCode);
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    getGoalPerformance(planner) {
      if (planner.goal_performance.unit === '') {
        return '';
      }
      return `${parseFloat(planner.goal_performance.count).toFixed(2)}`;
    },
    // GET WORKERS - SUPERVISORS
    async getSupervisors() {
      const xhr = await Api.getSupervisors();
      return JSON.parse(xhr.response).data;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisors) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    // EDIT, VIEW AND DELETE TASK
    async editWorkOrder(rowSelected) {
      try {
        const currentTaskFormatted = await this.getTask(rowSelected.row.data.ID);
        if (currentTaskFormatted.TaskStatus === 'OPEN') {
        // Comprobación para editar productos / prescripcion
          if (currentTaskFormatted.Planner.prescription) {
            this.setPrescription(currentTaskFormatted.Planner.prescription);
          } else {
            this.setNewPrescription();
          }
          // Comprobación para editar tools
          if (currentTaskFormatted.Planner) {
            currentTaskFormatted.ToolsDetailTab = currentTaskFormatted.Planner.tools;
          }
          const binsContentPlannedToEdit = currentTaskFormatted.binsDetail.map((content) => ({
            bin: content.bin.bin_id,
            category: content.category,
            classification: content.class_type,
            content: content.code,
            variety: content.variety,
            plants: content.count,
          }));
          this.setBinsContentPlannedToEdit(binsContentPlannedToEdit);
          this.setTaskSelectedByTaskCode(currentTaskFormatted.taskCode);
          this.setActualWorkOrder(currentTaskFormatted);
          this.setProducts(currentTaskFormatted.products);
          this.$f7.views.main.router.navigate(`/editOrder/${this.back}/`, { reloadCurrent: true });
        } else if (currentTaskFormatted.TaskStatus === 'COMPLETED' && currentTaskFormatted.WorkOrderStatus !== 'COMPLETED') {
          this.machineryPopup = currentTaskFormatted.machineryPopup;
          this.productPopup = currentTaskFormatted.productPopup;
          this.supervisorPopup = currentTaskFormatted.responsible;
          this.toolPopup = currentTaskFormatted.toolPopup;
          if (currentTaskFormatted.responsible === null) {
            this.supervisorPopup = '';
            currentTaskFormatted.responsible = '';
          }
          this.setActualWorkOrder(currentTaskFormatted);
          this.setVisiblePopupWorkOrder(true);
        } else {
          this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogUpdate_noOpen'));
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    async showConfirmationView(rowSelected) {
      try {
        this.$f7.preloader.show();
        const xhr = await Api.getDataWorkOrder(rowSelected.row.data.ID);
        const dataWorkOrder = JSON.parse(xhr.response);
        this.setActualWorkOrder(dataWorkOrder);
        // this.setIsWorderStatusCompleted(true);
        this.$f7.views.main.router.navigate(`/editWorkOrder/${this.back}/`, { reloadCurrent: true });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async deleteTask(rowSelected) {
      if (rowSelected.row.data.TaskStatus !== 'OPEN') {
        this.$f7.dialog.alert(this.$t('DxDailyWorkOrders_dialogDelete_noOpen'));
        return false;
      }
      const taskId = rowSelected.row.data.ID;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyWorkOrders_dialogDelete_title'),
        text: this.$t('DxDailyWorkOrders_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyWorkOrders_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deleteTask(taskId);
                f7.views.main.router.navigate('/workOrders/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
      return false;
    },
    async viewtask(rowSelected) {
      try {
        const currentTaskFormatted = await this.getTask(rowSelected.row.data.ID);
        if (currentTaskFormatted.TaskStatus === 'COMPLETED') {
        // Comprobación para editar productos / prescripcion
          if (currentTaskFormatted.Planner.prescription) {
            this.setPrescription(currentTaskFormatted.Planner);
          }
          // Comprobación para editar tools
          if (currentTaskFormatted.Planner) {
            currentTaskFormatted.ToolsDetailTab = currentTaskFormatted.Planner.tools;
          }
          this.setActualWorkOrder(currentTaskFormatted);
          this.setProducts(currentTaskFormatted.products);
          this.$f7.views.main.router.navigate('/ViewPostWork/', { reloadCurrent: true });
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    // SET CSS CLASSES TO CELLS
    setClassByTaskStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'OPEN':
          colorCell = 'open';
          this.taskStatusTranslated = this.$t('Status_Open');
          break;
        case 'IN_PROGRESS':
          colorCell = 'in-progress';
          this.taskStatusTranslated = this.$t('Status_InProgress');
          break;
        case 'COMPLETED':
          colorCell = 'completed';
          this.taskStatusTranslated = this.$t('Status_Completed');
          break;
        case 'PENDING':
          colorCell = 'pending';
          this.taskStatusTranslated = this.$t('Status_Pending');
          break;
        case 'DELETED':
          colorCell = 'deleted';
          this.taskStatusTranslated = this.$t('Status_Deleted');
          break;
        default:
          break;
      }
      return colorCell;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case 'DRAFT':
          colorCell = 'work-order-draft';
          this.workOrderStatusTranslated = this.$t('Draft');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Status_reallyNot_Closed');
          break;
      }
      return colorCell;
    },
    calculateProductivity(workers) {
      let productivityTotal = 0;
      for (const worker of workers) {
        productivityTotal += parseFloat((worker.unit === '') ? worker.working_area : worker.performance);
      }
      return productivityTotal.toFixed(2);
    },
    calculateRealAreaEfective(workers) {
      let totalArea = 0;
      workers.forEach((w) => {
        totalArea += parseFloat(w.working_area);
      });
      return parseFloat(totalArea).toFixed(2);
    },
    getUnit(planner) {
      if (planner.goal_performance.unit === '') {
        return 'HA';
      }
      return planner.goal_performance.unit;
    },
    getMachineryDescriptionCode(machineryId) {
      for (const machinery of this.allItemsMachineries) {
        if (machinery.id === machineryId) {
          return this.$helpers.machineryItemToString(machinery.item);
        }
      }
      return '';
    },
    getDriverFullName(driverId) {
      for (const driver of this.drivers) {
        if (driver.id === driverId) {
          return `${driver.name} ${driver.surname}`;
        }
      }
      return '';
    },
    getVehicleDrivers(machineriesDrivers) {
      let vehicleDrivers = '';
      for (const machineryDriver of machineriesDrivers) {
        const vehicle = `${this.getMachineryDescriptionCode(machineryDriver.item)}:`;
        const driver = this.getDriverFullName(machineryDriver.driver);
        vehicleDrivers += `${vehicle} ${driver};`;
      }
      return vehicleDrivers;
    },
    async getTask(id) {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.getTaskByID(id);
        const currentTask = JSON.parse(xhr.response);
        const currentTaskFormatted = {
          ID: currentTask.id,
          Date: moment(currentTask.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          Task: currentTask.task,
          taskCode: currentTask.task_code,
          Activity: currentTask.activity,
          activityCode: currentTask.activity_code,
          TaskStatus: currentTask.status,
          taskStatusTemplateClass: this.setClassByTaskStatus(currentTask.status),
          taskStatusTranslated: this.taskStatusTranslated,
          WorkOrderStatus: currentTask.worder_status,
          workOrderStatusTemplateClass: this.setClassByWorkOrderStatus(currentTask.worder_status),
          workOrderStatusTranslated: this.workOrderStatusTranslated,
          Supervisor: this.getNameSupervisor(currentTask.responsible),
          Workers: `${this.getTotalWorkers(currentTask.workers)} / ${this.getTotalWorkersPlanner(currentTask.planner.workers)}`,
          WorkersDetailTab: currentTask.workers,
          ItemsDetailTab: this.getItems(currentTask.items_drivers),
          Attachments: currentTask.attachments_vehicles,
          AttachmentsDetailTab: currentTask.attachments,
          Tools: currentTask.planner.tools,
          ToolsDetailTab: currentTask.tools,
          itemsTypes: currentTask.planner.items,
          items: this.getItems(currentTask.items_drivers),
          itemsDriversWorkOrder: currentTask.items_drivers,
          locationLevel2: this.getLocationsFormated(currentTask.locations_tree, currentTask.location, currentTask.activity_code),
          area_effective: currentTask.area_effective,
          real_area_effective: this.calculateRealAreaEfective(currentTask.workers),
          goal_performance: this.getGoalPerformance(currentTask.planner),
          location: currentTask.location,
          Planner: currentTask.planner,
          responsible: currentTask.responsible,
          additionalInfoWorkOrder: currentTask.comment,
          machineryPopup: currentTask.machinery_performance,
          products: currentTask.products,
          prescription: currentTask.prescription,
          productPopup: currentTask.product_performance,
          variety: currentTask.variety,
          binsDetail: currentTask.nursery,
          short_id: currentTask.short_id,
          manualPerformance: currentTask.performance,
          supervisorsLocation: currentTask.responsible,
          group: currentTask.planner.group,
          shift: currentTask.planner.shift,
          shortId: currentTask.short_id,
          productivity: this.calculateProductivity(currentTask.workers),
          unit: this.getUnit(currentTask.planner),
          drivers: this.getVehicleDrivers(currentTask.items_drivers),
          locations_tree: currentTask.locations_tree,
          toolPopup: currentTask.tools,
        };
        return currentTaskFormatted;
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
      return null;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'exportAll',
      });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('WorkOrders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'WorkOrders.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Employee', ['fetchEmployeesSupervisorsDrivers']),
    ...mapActions('PlanningManager', ['setActualWorkOrder', 'setVisiblePopupWorkOrder', 'setIsWorderStatusCompleted',
      'setBack']),
    ...mapActions('Prescription', ['setProducts', 'setPrescription', 'setNewPrescription']),
    ...mapActions('greenhouse', [
      'fetchBinsContentFamilies',
      'setBinsContentPlannedToEdit',
      'setTaskSelectedByTaskCode',
      'fetchLocationTree',
    ]),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed, .work-order-draft {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
.work-order-draft{
  color: #fff;
  background: var(--fr-inprogress);
}
</style>
