import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    actualUser: {},
    allClients: [],
  },
  getters: {
    actualUser: (state) => state.actualUser.id,
  },
  mutations: {
    UPDATE_ACTUAL_USER(state, payload) {
      state.actualUser = payload;
    },
    UPDATE_REGISTER_DATA(state, value) {
      state.actualUser.register_data = value;
    },
    UPDATE_NUMBER_MOBILE(state, value) {
      state.actualUser.mobile = value;
    },
    UPDATE_PROVINCE(state, value) {
      state.actualUser.province = value;
    },
    UPDATE_CITY(state, value) {
      state.actualUser.city = value;
    },
    UPDATE_ATC_PROP(state, value) {
      state.actualUser.atcProp = value;
    },
    UPDATE_CLIENTS(state, value) {
      state.allClients = value;
    },
  },
  actions: {
    async fetchAllClients({ commit, state }) {
      try {
        if (state.allClients.length > 0) return Promise.resolve();
        const xhr = await Api.getClient();
        const allClients = JSON.parse(xhr.response);
        const user = allClients.contact;
        const dataClients = [];
        for (let i = 0; i < user.companies.length; i += 1) {
          dataClients.push({
            id: user.companies[i].id,
            name: user.companies[i].name,
            city: user.companies[i].city,
            province: user.companies[i].province,
            phone: user.companies[i].phone,
            mobile_phone: user.companies[i].mobile_phone,
            postal_code: user.companies[i].postal_code,
            registration_date: user.companies[i].registration_date,
            deal: `${user.name} ${user.surname}`,
          });
        }
        commit(
          'UPDATE_CLIENTS',
          dataClients,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setActualUser(context, value) {
      context.commit('UPDATE_ACTUAL_USER', value);
    },
    setRegisterData(context, value) {
      context.commit('UPDATE_REGISTER_DATA', value);
    },
    setNumberMobile(context, value) {
      context.commit('UPDATE_NUMBER_MOBILE', value);
    },
    setProvince(context, value) {
      context.commit('UPDATE_PROVINCE', value);
    },
    setCity(context, value) {
      context.commit('UPDATE_CITY', value);
    },
    setATCPROP(context, value) {
      context.commit('UPDATE_ATC_PROP', value);
    },
  },
};
