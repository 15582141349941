<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Tasks_Title')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block>{{ $t('Product_Detail_Title') }}</f7-block>
      <ProductDetail
        :current-product="currentProduct"
      />
      <f7-row>
        <f7-col>
          <MainElements
            :main-elements="mainElements"
            :allow-editing="false"
          />
        </f7-col>
        <f7-col>
          <AlterElements
            :alter-elements="alterElements"
            :allow-editing="false"
          />
        </f7-col>
      </f7-row>
      <f7-row>
        <MicroElements
          :micro-elements="microElements"
          :allow-editing="false"
        />
      </f7-row>
    </div>
    <f7-block-footer
      v-if="loaded"
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToProducts"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ProductDetail from '../../../components/configuration/products/productDetail/index.vue';
import MainElements from '../../../components/configuration/products/mainElements/index.vue';
import AlterElements from '../../../components/configuration/products/alterElements/index.vue';
import MicroElements from '../../../components/configuration/products/microElements/index.vue';

export default {
  name: 'ViewProductDetail',
  components: {
    navbar,
    ProductDetail,
    MainElements,
    AlterElements,
    MicroElements,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    mainElements() {
      return [
        {
          NT: this.currentProduct.NT,
          P2015_Total: this.currentProduct.P2015_Total,
          K2O: this.currentProduct.K2O,
        },
      ];
    },
    alterElements() {
      return [
        {
          CaO: this.currentProduct.CaO,
          MgO: this.currentProduct.MgO,
          SO3: this.currentProduct.SO3,
        },
      ];
    },
    microElements() {
      return [
        {
          B: this.currentProduct.B,
          Cu: this.currentProduct.Cu,
          Fe: this.currentProduct.Fe,
          Mn: this.currentProduct.Mn,
          Zn: this.currentProduct.Zn,
          Se: this.currentProduct.Se,
          MO: this.currentProduct.MO,
          I: this.currentProduct.I,
        },
      ];
    },
    ...mapState('Product', ['currentProduct']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getProductById(this.currentProduct.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToProducts() {
      this.initStore();
      this.$f7.views.main.router.navigate('/products/', { reloadCurrent: true });
    },
    ...mapActions('Product', ['getProductById', 'initStore']),
  },
};
</script>
