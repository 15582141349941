<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Title_page_analisys_treatment')}`" />
    <div class="margin-top-half">
      <div class="content-devx">
        <f7-block>
          {{ $t('Title_page_analisys_treatment') }}
        </f7-block>

        <f7-block>
          <f7-row>
            <f7-col>
              <DxDataGrid
                :data-source="plansOnDisplay"
                :remote-operations="false"
                :allow-column-reordering="true"
                :row-alternation-enabled="true"
                :show-borders="true"
                @toolbar-preparing="onToolbarPreparing($event)"
              >
                <template #periodFilterTemplate>
                  <div>
                    <DxButton
                      class="margin-right-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.monthly')"
                      type="default"
                      :styling-mode="currentPeriod == 'MENSUAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('MENSUAL')"
                    />
                    <DxButton
                      class="margin-left-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.weekly')"
                      type="default"
                      :styling-mode="currentPeriod == 'SEMANAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('SEMANAL')"
                    />
                  </div>
                </template>

                <DxSearchPanel
                  :visible="true"
                  :width="200"
                  :highlight-case-sensitive="true"
                  :placeholder="$t('Search_placeholder')"
                />

                <DxColumn
                  data-field="description"
                  caption=""
                  data-type="string"
                />

                <DxColumn
                  data-field="species"
                  :caption="$t('Plans.crop')"
                  data-type="string"
                />

                <DxColumn
                  data-field="company_name"
                  :caption="$t('client')"
                  data-type="string"
                />

                <DxColumn
                  data-field="explotation"
                  :caption="$t('Explotation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="plantation"
                  :caption="$t('nutritionDetail.report.plantation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="campaign"
                  :caption="$t('nutritionDetail.report.campaign')"
                  data-type="string"
                />

                <DxColumn
                  data-field="cost"
                  :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.cost')"
                  data-type="string"
                />

                <DxColumn
                  data-field="period"
                  :caption="$t('Searcher.observations.template')"
                  data-type="string"
                />
                <DxColumn
                  width="48px"
                  data-field="id"
                  caption=""
                  cell-template="grid-cell"
                />
                <template #grid-cell="{ data }">
                  <div>
                    <DxButton
                      v-if="isTreatmentselectedTreatmentPlanId(data.text)"
                      name="delete"
                      type="danger"
                      icon="close"
                      @click="deselectTreatmentPlan(data.text)"
                    />
                    <DxButton
                      v-else
                      name="edit"
                      type="success"
                      icon="add"
                      @click="selectTreatmentPlan(data.text)"
                    />
                  </div>
                </template>
                <DxPaging :page-size="5" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :allowed-page-sizes="pageSizes"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
              </DxDataGrid>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>

      <DxLoadPanel
        :visible="loading"
        shading-color="rgba(0,0,0,0.4)"
      />

      <div class="content-devx">
        <div class="card-header">
          {{ $t('treatment.treatmentPlan.treatmentDetailPlan.chartTitle') }}
        </div>
        <div class="card-body">
          <section>
            <DxChart
              v-if="!loading"
              palette="SoftPastel"
              :data-source="chartData"
              @legend-click="onLegendClick"
            >
              <DxSeries
                v-if="selectedTreatmentPlanIds.length > 0"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanFirst}`"
                :name="firstProduct + ' - ' + `${currentPlanFirst}`"
                type="stackedbar"
                stack="primero"
              />
              <DxSeries
                v-if="selectedTreatmentPlanIds.length >= 2"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanSecond}`"
                :name="secondProduct + ' - ' + `${currentPlanSecond}`"
                type="stackedbar"
                stack="segundo"
              />
              <DxSeries
                v-if="selectedTreatmentPlanIds.length === 3"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanThird}`"
                :name="thirdProduct + ' - ' + `${currentPlanThird}`"
                type="stackedbar"
                stack="tercero"
              />
              <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                item-text-position="right"
              />
              <DxTooltip
                :enabled="true"
                :z-index="999999"
                :customize-tooltip="customizeTooltip"
              />
              <DxScrollBar :visible="true" />
              <DxZoomAndPan argument-axis="both" />
            </DxChart>
          </section>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

import DxButton from 'devextreme-vue/button';
import {
  DxChart,
  DxLegend,
  DxTooltip,
  DxSeries,
  DxScrollBar,
  DxZoomAndPan,
} from 'devextreme-vue/chart';

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';

import { DxLoadPanel } from 'devextreme-vue/load-panel';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'TreatmentPage',
  components: {
    navbar,
    DxChart,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxLegend,
    DxSeries,
    DxPager,
    DxLoadPanel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxPaging,
  },
  data() {
    return {
      selectedTreatmentPlanIds: [],
      chartData: [],
      pageSizes: [5, 10, 20],
      currentPeriod: 'MENSUAL',
      plansOnDisplay: [],

      currentPlans: [],
      currentPlanFirst: '',
      currentPlanSecond: '',
      currentPlanThird: '',
      firstProduct: '',
      secondProduct: '',
      thirdProduct: '',
      loading: false,
    };
  },
  watch: {
    chartData(newVal) {
      const objNewVal = { ...newVal };

      if (this.selectedTreatmentPlanIds.length === 1) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanFirst}`]) {
            this.firstProduct = objNewVal[property][`productName - ${this.currentPlanFirst}`];
            break;
          }
        }
      }

      if (this.selectedTreatmentPlanIds.length === 2) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanSecond}`]) {
            this.secondProduct = objNewVal[property][`productName - ${this.currentPlanSecond}`];
            break;
          }
        }
      }

      if (this.selectedTreatmentPlanIds.length === 3) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanThird}`]) {
            this.thirdProduct = objNewVal[property][`productName - ${this.currentPlanThird}`];
            break;
          }
        }
      }
    },
  },
  computed: {
    ...mapState('treatment', ['treatmentPlans', 'treatmentPlansDetailById']),
  },
  async mounted() {
    this.loading = true;
    try {
      await this.getTreatmentDataForAnalisis();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(error));
    }

    this.selectPeriod('MENSUAL');

    this.loading = false;
  },
  methods: {
    ...mapActions('treatment', ['getTreatmentDataForAnalisis', 'getTreatmentPlanDetails']),
    // FIXME: Maybe move this to a service?
    // COPY from: src/components/wizardTreatment/treatmentDetailPlan/treatmentDetailPlan.js
    getTimeValues(dateStart, dateEnd, period) {
      const timeValues = {};
      switch (period) {
        case 'SEMANAL':
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `W${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
            timeValues[varName] = 0;
            dateStart.add(1, 'week');
          }
          break;
        case 'MENSUAL':
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MM')} - ${dateStart.format('yy')}`;
            timeValues[varName] = 0;
            dateStart.add(1, 'month');
          }
          break;
      }
      return timeValues;
    },

    async recalculateGraphData() {
      const newChartData = [];

      this.loading = true;
      this.currentPlans = [];
      for (const id of this.selectedTreatmentPlanIds) {
        try {
          await this.getTreatmentPlanDetails({ treatmentId: id });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
        this.currentPlans.push(this.treatmentPlansDetailById[id]);

        if (this.selectedTreatmentPlanIds.length === 1) {
          this.currentPlanFirst = this.treatmentPlansDetailById[id].description;
          this.currentPlanSecond = '';
          this.currentPlanThird = '';
        }

        if (this.selectedTreatmentPlanIds.length === 2) {
          this.currentPlanFirst = this.treatmentPlansDetailById[this.currentPlans[0].id].description;
          this.currentPlanSecond = this.treatmentPlansDetailById[id].description;
          this.currentPlanThird = '';
        }

        if (this.selectedTreatmentPlanIds.length === 3) {
          this.currentPlanThird = this.treatmentPlansDetailById[id].description;
        }
      }

      // Se ordenan los planes usando el índce de la semana, es necesario para que la gráfica salga ordenada
      this.currentPlans = this.currentPlans.sort((planA, planB) => {
        const monthPlanA = moment(planA.init_date).week();
        const monthPlanB = moment(planB.init_date).week();
        return monthPlanA - monthPlanB;
      });

      for (const current of this.currentPlans) {
        const timeValuesTmp = current.products.map((product, index) => {
          const timeValues = this.getTimeValues(
            moment(current.init_date, 'YYYY/MM/DD'),
            moment(current.end_date, 'YYYY/MM/DD'),
            current.period,
          );
          let counter = -1;
          for (const prop in timeValues) {
            if (Object.hasOwnProperty.call(timeValues, prop)) {
              timeValues[prop] = product.periods[counter += 1];
            }
          }
          return timeValues;
        });

        current.products.forEach((item, index) => {
          const itemValue = timeValuesTmp[index];
          for (const prop in itemValue) {
            if (Object.hasOwnProperty.call(itemValue, prop)) {
              newChartData.push({
                [`productName - ${current.description}`]: item.product,
                monthWeek: prop,
                [`value - ${current.description}`]: itemValue[prop],
                plan: current.description,
              });
            }
          }
        });
      }

      this.loading = false;

      this.chartData = newChartData;
    },

    selectPeriod(period) {
      this.currentPeriod = period;
      this.selectedTreatmentPlanIds = [];

      this.plansOnDisplay = this.treatmentPlans.data.filter((plan) => plan.period === this.currentPeriod);

      this.recalculateGraphData();
    },

    // -----------------------
    // Selection Helpers
    // -----------------------
    isTreatmentselectedTreatmentPlanId(treatmentPlanId) {
      for (const id of this.selectedTreatmentPlanIds) {
        if (treatmentPlanId === id) {
          return true;
        }
      }
      return false;
    },

    selectTreatmentPlan(treatmentPlanId) {
      const planFound = this.selectedTreatmentPlanIds.find((plan) => plan.toString() === treatmentPlanId.toString());
      if (this.selectedTreatmentPlanIds.length === 3 && !planFound) {
        this.selectedTreatmentPlanIds.pop();
        this.selectedTreatmentPlanIds.push(treatmentPlanId);
        this.recalculateGraphData();
        return;
      }
      if (this.selectedTreatmentPlanIds.length === 3) return;

      this.selectedTreatmentPlanIds.push(treatmentPlanId);

      this.recalculateGraphData();
    },

    deselectTreatmentPlan(treatmentPlanId) {
      if (this.selectedTreatmentPlanIds.length === 3) {
        this.currentPlanFirst = '';
        this.thirdProduct = '';
        this.firstProduct = this.secondProduct;
      } else if (this.selectedTreatmentPlanIds.length === 2) {
        this.secondProduct = '';
      } else if (this.selectedTreatmentPlanIds.length === 1) {
        this.firstProduct = '';
      }

      const index = this.selectedTreatmentPlanIds.indexOf(treatmentPlanId);
      if (index > -1) {
        this.selectedTreatmentPlanIds.splice(index, 1);
      }
      this.recalculateGraphData();
    },

    clearSelection() {
      this.selectedTreatmentPlanIds = [];
      this.recalculateGraphData();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      /** const product = pointInfo.seriesName.toUpperCase();
      let total = 0;
      pointInfo.point.series.pointsByArgument[pointInfo.argument].forEach((element) => {
        total += element.originalValue;
      }); */
      return {
        html: `<div><div class='tooltip-header'>${
          pointInfo.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold">${this.$t('weather_parameter_value')} </span> ${pointInfo.valueText} kG-L/HA
          <br>
          <span class="bold"> ${this.$t('treatment.treatmentPlan.title')}:</span> ${pointInfo.point.data.plan}
          <br>
        </span> </div></div></div>`,
      };
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({ location: 'before', template: 'periodFilterTemplate' });
    },
  },
};
</script>

<style>
</style>
