var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',{staticClass:"margin-bottom"},[_c('f7-row',[_c('f7-col',[_c('f7-block-title',[_vm._v("\n          "+_vm._s(_vm.isNotEditable ? _vm.$t('Searcher.observations.detail_observation_title') : _vm.$t('Searcher.observations.edit_observation_title'))+"\n        ")])],1),_vm._v(" "),(_vm.hideBtn)?_c('f7-col',{staticStyle:{"width":"fit-content"}},[_c('DxButton',{attrs:{"icon":"edit","name":"btnEditar","on-click":_vm.goToEditObservation}}),_vm._v(" "),_c('DxButton',{attrs:{"icon":"trash","name":"btnBorrar","on-click":_vm.callToDeleteObservation}})],1):_vm._e()],1)],1),_vm._v(" "),_c('f7-row',{attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"85"}},[_c('DxForm',{attrs:{"id":"mainData-form","show-colon-after-label":false,"read-only":_vm.readOnly}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"date","data-field":_vm.$t('Searcher.observations.date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeDate,
              value: _vm.currentValuesMainData.date,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.dateValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"geolocation","data-field":_vm.$t('Searcher.observations.geolocation'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.scoutingPoints,
              onValueChanged: _vm.onGeolocationChange,
              value: _vm.currentValuesMainData.idGeolocationPoint,
              displayExpr: 'name',
              valueExpr: 'id',
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.geolocationValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"categoryName","data-field":_vm.$t('Searcher.observations.category'),"editor-options":{
              value: _vm.currentValuesMainData.category,
              readOnly: true,
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subcategoryName","data-field":_vm.$t('Searcher.observations.subcategory'),"editor-options":{
              value: _vm.currentValuesMainData.subcategoryName,
              readOnly: true,
            }}})],1)],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"10"}},[(!_vm.readOnly)?_c('DxForm',{attrs:{"id":"form","show-colon-after-label":false}},[_c('DxSimpleItem',{attrs:{"data-field":"","editor-options":{
            disabled: true,
            height: 41
          }}}),_vm._v(" "),_c('DxButtonItem',{attrs:{"button-options":_vm.addGeolocationPoint,"horizontal-alignment":"center"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }