import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    osm: {
      route: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      type: 'OSM',
      properties: {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      },
    },
    googleHybrid: {
      route: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}?apikey=AIzaSyAlUKxY4pMzMwUK7OQ9N737Zj0TbLcW6TA',
      type: 'Google',
      properties: {
        maxZoom: 19,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      },
    },
    currentLayersMap: [],
    selectedLayerMap: null,
    showSecondMap: false,
    dateList: [],
    currentOverlayLayer: '',
    currentPoint: {
      latitude: '',
      longitude: '',
    },
    currentIndexes: {},
    indexAvailables: ['NDVI', 'SAVI', 'ARVI', 'EVI', 'VARI', 'LAI', 'NDRE', 'RECI', 'NDMI'],
    currentGraphicLayer: {},
    currentInitIntervalDates: {},
    currentEndIntervalDates: {},
    datesList: [],
    featureInfo: {},
    currentGeojsonFeature: {},
    histogram: null,
    timelineIndexes: null,
    histogramValues: [],
  },
  getters: {
    osm: (state) => state.osm,
    googleHybrid: (state) => state.googleHybrid,
    currentLayersMap: (state) => state.currentLayersMap,
    selectedLayerMap: (state) => state.selectedLayerMap,
    showSecondMap: (state) => state.showSecondMap,
  },
  actions: {
    resetCurrentPoint(context) {
      context.commit('setCurrentPoint', {
        latitude: '',
        longitude: '',
      });
    },
    setCurrentPoint(context, newCurrentPoint) {
      context.commit('setCurrentPoint', newCurrentPoint);
    },
    setOsm(context, value) {
      context.commit('setOsm', value);
    },
    setGoogleHybrid(context, value) {
      context.commit('setGoogleHybrid', value);
    },
    setCurrentLayersMap(context, value) {
      context.commit('setCurrentLayersMap', value);
    },
    setSelectedLayerMap(context, value) {
      context.commit('setSelectedLayerMap', value);
    },
    setShowSecondMap(context) {
      context.commit('setShowSecondMap');
    },
    setDateList(context, value) {
      context.commit('setDateList', value);
    },
    addCurrentLayersMap({ commit, state }, value) {
      for (const layerMap of state.currentLayersMap) {
        if (layerMap === value) return;
      }
      commit('addCurrentLayersMap', value);
    },
    removeCurrentLayersMap(context, value) {
      context.commit('removeCurrentLayersMap', value);
    },
    setCurrentOverlayLayer(context, value) {
      context.commit('setCurrentOverlayLayer', value);
    },
    async fetchGraphicLayer({ commit, state }, { currentOverlayLayer, key }) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServer()}/get_raster_stats/?layer=${currentOverlayLayer}&dates=${state.currentInitIntervalDates},${state.currentEndIntervalDates}&index=${state.currentIndexes[key].toLowerCase()}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setCurrentGraphicLayer', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchDatesLayer({ commit }, layer) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerRasterDates()}?layer=${layer}&server=gis.hispatecanalytics.com&ws=${Api.getGeoServerWorkspace()}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setDatesLayer', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchFeatureInfo({ commit }, { queryParams }) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerPort()}/${queryParams}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setFeatureInfo', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchCurrentGeojsonFeature({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            commit('setCurrentGeojsonFeature', result);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    setCurrentIndexes({ commit }, { key, newCurrentIndex }) {
      commit('setCurrentIndexes', { key, newCurrentIndex });
    },
    setCurrentInitIntervalDates({ commit }, { key, newCurrentInitIntervalDate }) {
      commit('setCurrentInitIntervalDates', { key, newCurrentInitIntervalDate });
    },
    setCurrentEndIntervalDates({ commit }, { key, newCurrentEndIntervalDate }) {
      commit('setCurrentEndIntervalDates', { key, newCurrentEndIntervalDate });
    },
    setHistogram({ commit }, value) {
      commit('setHistogram', value);
    },
    async fetchHistogram({ commit }, {
      location, date, company,
    }) {
      try {
        const xhr = await Api.getGeoServerTemperatureHistogram(location, date, company);
        const result = JSON.parse(xhr.response).data;

        commit('setHistogramValues', result.length > 0 ? result[0].values : []);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchTimelineIndexes({ commit }, {
      location, initDate, endDate, companyId,
    }) {
      try {
        const xhr = await Api.fetchTimelineIndexes(location, initDate, endDate, companyId);
        const result = JSON.parse(xhr.response).data;

        commit('setTimelineIndexes', result);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setTimelineIndexes({ commit }, value) {
      commit('setTimelineIndexes', value);
    },
    setHistogramValues({ commit }, value) {
      commit('setHistogramValues', value);
    },
  },
  mutations: {

    setCurrentGeoJSONLayer(state, newCurrentGeoJSONLayer) {
      state.currentGeoJSONLayer = newCurrentGeoJSONLayer;
    },
    setCurrentPoint(state, newCurrentPoint) {
      state.currentPoint = newCurrentPoint;
    },
    setOsm(state, value) {
      state.osm = value;
    },
    setGoogleHybrid(state, value) {
      state.googleHybrid = value;
    },
    setCurrentLayersMap(state, value) {
      state.currentLayersMap = value;
    },
    setSelectedLayerMap(state, value) {
      state.selectedLayerMap = value;
    },
    setShowSecondMap(state) {
      state.showSecondMap = !state.showSecondMap;
    },
    setDateList(state, value) {
      state.dateList = value;
    },
    addCurrentLayersMap(state, value) {
      state.currentLayersMap.push(value);
    },
    removeCurrentLayersMap(state, value) {
      const newCurrentLayersMap = [];
      for (const currentLayersMap of state.currentLayersMap) {
        if (currentLayersMap !== value) {
          newCurrentLayersMap.push(currentLayersMap);
        }
      }
      state.currentLayersMap = newCurrentLayersMap;
    },
    setCurrentOverlayLayer(state, value) {
      state.currentOverlayLayer = value;
    },
    setCurrentGraphicLayer(state, newCurrentGraphicLayer) {
      state.currentGraphicLayer = newCurrentGraphicLayer;
    },
    setDatesLayer(state, newDatesLayer) {
      state.datesList = newDatesLayer;
    },
    setFeatureInfo(state, newFeatureInfor) {
      state.featureInfo = newFeatureInfor;
    },
    setCurrentGeojsonFeature(state, newCurrentGeojsonFeature) {
      state.currentGeojsonFeature = newCurrentGeojsonFeature;
    },
    setCurrentIndexes(state, { key, newCurrentIndex }) {
      state.currentIndexes[key] = newCurrentIndex;
    },
    setCurrentIntervalDates(state, { key, newIntervalDate }) {
      state.currentIntervalDates[key] = newIntervalDate;
    },
    setCurrentInitIntervalDates(state, { key, newCurrentInitIntervalDate }) {
      state.currentInitIntervalDates[key] = newCurrentInitIntervalDate;
    },
    setCurrentEndIntervalDates(state, { key, newCurrentEndIntervalDate }) {
      state.currentEndIntervalDates[key] = newCurrentEndIntervalDate;
    },
    setHistogram(state, value) {
      state.histogram = value;
    },
    setTimelineIndexes(state, value) {
      state.timelineIndexes = value;
    },
    setHistogramValues(state, value) {
      state.histogramValues = value;
    },
  },
};
