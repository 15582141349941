<template>
  <div class="main-ExportDataToExcel">
    <f7-button
      class="dx-btn-success buttonExport"
      raised
      styling-mode="contained"
      name="btnExportData"
      :disabled="disabled"
      @click="downloadFile"
    >
      {{ $t("ExportDataToExcel_Button") }}
    </f7-button>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import Api from '../services/Api';

export default {
  name: 'ExportDataToExcel',
  components: {
  },
  props: {
    dataToExport: { type: Array, default: () => [] },
    columnTitles: { type: Array, default: () => [] },
    nameFile: { type: String, default: 'file' },
    disabled: { type: Boolean, default: false },
    includeActivities: { type: Array, default: () => [] },
    excludeActivities: { type: Array, default: () => [] },
    keyName: { type: String, default: '' },
    reporting: { type: Boolean, default: false },
    supervisorsAvailables: { type: Array, default: () => [] },
  },
  data() {
    return {
      titles: [],
      detailDailyTasksToExport: [],
      nameFileToExport: '',
      arrayExcelColumnTranslations: [],
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getLocationNameLevelSelectedChildId']),
  },
  beforeMount() {
    this.titles = this.columnTitles;
  },
  methods: {
    async downloadFile() {
      if (this.dataToExport.length === 0) {
        await this.fetchTasks();
        this.titles = this.arrayExcelColumnTranslations;
      }
      const data = XLSX.utils.json_to_sheet(this.dataToExport.length !== 0 ? this.dataToExport : this.detailDailyTasksToExport);
      const range = XLSX.utils.decode_range(data['!ref']);
      for (let C = 0; C <= range.e.c; C += 1) {
        const celda = `${XLSX.utils.encode_col(C)}1`; // <-- first row, column number C
        if (data[celda] && this.titles.length > 0) {
          data[celda].v = this.titles.splice(0, 1).shift(); // Asigna valor de titulo y elimina el titulo del array
        } else {
          break;
        }
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, this.nameFile !== '' ? this.nameFile : this.nameFileToExport);
      XLSX.writeFile(workbook, `${this.nameFile !== '' ? this.nameFile : this.nameFileToExport}.xlsx`);
    },
    async fetchTasks() {
      try {
        const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
        const xhr = await Api.getDailyTasks(true, this.includeActivities, this.excludeActivities, checkedInitEndDate.init, checkedInitEndDate.end, this.reporting);
        const tasks = JSON.parse(xhr.response);
        for (const taskTypeDetail of tasks.detail) {
          for (const task of taskTypeDetail.tasks) {
            const taskFormatted = {
              short_id: task.short_id,
              Date: moment(task.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              Activity: task.activity,
              Task: task.task,
              group: task.planner.group,
              shift: task.planner.shift,
              locationLevel2: this.getNamesByBdIds(task.location, task.activity_code),
              variety: task.variety,
              real_area_effective: this.calculateRealAreaEfective(task.workers),
              goal_performance: this.getGoalPerformance(task.planner),
              unit: this.getUnit(task.planner),
              Workers: `${this.getTotalWorkers(task.workers)} / ${this.getTotalWorkersPlanner(task.planner.workers)}`,
              Supervisor: this.getNameSupervisor(task.responsible),
              drivers: this.getVehicleDrivers(task.items_drivers),
              Planner: task.planner,
              itemsTypes: task.planner.items,
              Attachments: task.attachments_vehicles,
              Tools: task.planner.tools,
              products: task.products,
              WorkersDetailTab: task.workers,
              binsDetail: task.nursery,
              itemsDriversWorkOrder: task.items_drivers,
            };
            this.addElementToExport(taskFormatted);
          }
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    // MÉTODOS PARA AÑADIR ELEMENTOS FORMATEAçDOS PARA EXPORTAR TODO:
    addElementToExport(newTask) {
      let machinary = '';
      if (newTask.itemsTypes.length > 0) {
        try {
          machinary = this.formatMachinary(newTask.itemsTypes);
        } catch (error) {
          console.error(error);
        }
      }
      let attachments = '';
      if (newTask.Attachments.length > 0) {
        try {
          attachments = this.formatAttachments(newTask.Attachments);
        } catch (error) {
          console.error(error);
        }
      }
      let tools = '';
      if (newTask.Tools.length > 0) {
        try {
          tools = this.formatTools(newTask.Tools);
        } catch (error) {
          console.error(error);
        }
      }
      let products = [];
      if (newTask.products.length > 0) {
        try {
          products = this.formatProducts(newTask);
        } catch (error) {
          console.error(error);
        }
      }
      let locations = [];
      if (newTask.locationLevel2.length > 0) {
        try {
          locations = this.formatLocations(newTask.locationLevel2);
        } catch (error) {
          console.error(error);
        }
      }
      let workers = [];
      if (newTask.WorkersDetailTab.length > 0) {
        try {
          workers = this.formatWorkers(newTask.WorkersDetailTab);
        } catch (error) {
          console.error(error);
        }
      }

      let nursery = [];
      if (newTask.binsDetail.length > 0) {
        try {
          nursery = this.formatNursery(newTask.binsDetail);
        } catch (error) {
          console.error(error);
        }
      }

      let drivers = [];
      if (newTask.itemsDriversWorkOrder.length > 0) {
        try {
          drivers = this.formatDrivers(newTask.itemsDriversWorkOrder);
        } catch (error) {
          console.error(error);
        }
      }

      // Construir campos a exportar por orden
      const baseFields = {
        short_id: newTask.short_id,
        Date: newTask.Date,
        Activity: newTask.Activity,
        Task: newTask.Task,
        Location: '',
        area_effective: parseFloat(newTask.real_area_effective).toFixed(2),
      };
      const emptyFields = {
        short_id: newTask.short_id,
        Date: '',
        Activity: '',
        Task: '',
        Location: '',
        area_effective: '',
      };
      this.arrayExcelColumnTranslations = [this.$t('DxWorkOrder_short_id').toUpperCase(), this.$t('DxDailyPlanning_date_caption').toUpperCase(),
        this.$t('DxDailyPlanning_activity_caption').toUpperCase(), this.$t('DxDailyPlanning_task_caption').toUpperCase(),
        this.$t('DxDailyPlanning_location_caption').toUpperCase(), this.$t('DxDailyPlanning_workingArea_caption').toUpperCase()];

      // Campos si estamos en cosecha
      let goalCaption = this.$t('DxMonthlyPlanning_goal_caption').toUpperCase();
      baseFields.goal_performance = newTask.goal_performance;
      emptyFields.goal_performance = '';
      if (this.keyName === 'harvesting') {
        goalCaption = this.$t('DxMonthlyPlanning_goal_harvesting_caption').toUpperCase();
        this.arrayExcelColumnTranslations.push(goalCaption);
        // Variedad
        emptyFields.variety = '';
        baseFields.variety = newTask.variety;
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_Variety').toUpperCase());
      } else {
        this.arrayExcelColumnTranslations.push(goalCaption);
      }

      // Workers
      baseFields.Workers = newTask.Workers;
      emptyFields.Workers = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_caption').toUpperCase());
      // Supervisor
      baseFields.Supervisor = newTask.Supervisor;
      emptyFields.Supervisor = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_supervisors_caption').toUpperCase());
      // taskStatusTranslated
      baseFields.taskStatusTranslated = newTask.taskStatusTranslated;
      emptyFields.taskStatusTranslated = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_task_status_caption').toUpperCase());
      // workOrderStatusTranslated
      baseFields.workOrderStatusTranslated = newTask.workOrderStatusTranslated;
      emptyFields.workOrderStatusTranslated = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_work_order_status_caption').toUpperCase());
      // machinary
      baseFields.machinary = machinary;
      emptyFields.machinary = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_machinary_title').toUpperCase());
      // drivers
      baseFields.drivers = '';
      emptyFields.drivers = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_driver_title').toUpperCase());
      // attachments
      baseFields.attachments = attachments;
      emptyFields.attachments = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_attachment_title').toUpperCase());
      // tools
      baseFields.tools = tools;
      emptyFields.tools = '';
      this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_tools_title').toUpperCase());
      // employes
      baseFields.employes = '';
      emptyFields.employes = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_caption').toUpperCase());
      // employes_type
      baseFields.employes_type = '';
      emptyFields.employes_type = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_WorkerType').toUpperCase());
      // employes_performance
      baseFields.employes_performance = '';
      emptyFields.employes_performance = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_performance_caption').toUpperCase());
      // employes_performance_unit
      baseFields.employes_performance_unit = '';
      emptyFields.employes_performance_unit = '';
      this.arrayExcelColumnTranslations.push(this.$t('DxDailyPlanning_workers_performance_unit_caption').toUpperCase());

      if (this.keyName !== 'harvesting') {
        // products
        baseFields.products = '';
        emptyFields.products = '';
        this.arrayExcelColumnTranslations.push(this.$t('DetailTabs_products_title').toUpperCase());
        // quantityPlanned
        baseFields.quantityPlanned = '';
        emptyFields.quantityPlanned = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDetailTabs_products_quantityPlanned').toUpperCase());
        // solutionPlanned
        baseFields.solutionPlanned = '';
        emptyFields.solutionPlanned = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxDetailTabs_products_waterPlanned').toUpperCase());
        // nursery_code
        baseFields.nursery_code = '';
        emptyFields.nursery_code = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryCode').toUpperCase());
        // nursery_variety
        baseFields.nursery_variety = '';
        emptyFields.nursery_variety = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryVariety').toUpperCase());
        // nursery_category
        baseFields.nursery_category = '';
        emptyFields.nursery_category = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryCategory').toUpperCase());
        // nursery_class
        baseFields.nursery_class = '';
        emptyFields.nursery_class = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryClassification').toUpperCase());
        // nursery_numPlants
        baseFields.nursery_numPlants = '';
        emptyFields.nursery_numPlants = '';
        this.arrayExcelColumnTranslations.push(this.$t('DxWorkOrders_ExportTranslation_NurseryNumPlants').toUpperCase());
      }
      this.insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, false);
      this.nameFileToExport = `ReportWO_${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    },
    calculateRealAreaEfective(workers) {
      let totalArea = 0;
      workers.forEach((w) => {
        totalArea += parseFloat(w.working_area);
      });
      return parseFloat(totalArea).toFixed(2);
    },

    formatMachinary(machinaryArray) {
      let machinaryFormated = '';
      machinaryArray.forEach((element) => {
        if (machinaryFormated !== '') {
          machinaryFormated += '\n';
        }
        machinaryFormated += ` ${element.type}: ${element.count} `;
      });
      return machinaryFormated;
    },
    formatAttachments(attachmentsArray) {
      let attachmentsFormated = '';
      attachmentsArray.forEach((element) => {
        if (attachmentsFormated !== '') {
          attachmentsFormated += '\n';
        }
        if (element.attachment_data) {
          attachmentsFormated += ` ${element.attachment_data.description}: ${element.vehicle_data.description} `;
        } else {
          attachmentsFormated += element.vehicle_data.description;
        }
      });
      return attachmentsFormated;
    },
    formatTools(toolsArray) {
      let toolsFormated = '';
      toolsArray.forEach((element) => {
        if (toolsFormated !== '') {
          toolsFormated += '\n';
        }
        toolsFormated += ` ${element.data.description}: ${element.count} `;
      });
      return toolsFormated;
    },
    formatProducts(task) {
      const listProducts = task.products;
      const newProducts = [];
      if (listProducts !== null) {
        for (let i = 0; i < listProducts.length; i += 1) {
          const productSelected = listProducts[i];
          const calcSolutionTotal = (productSelected.solution !== '0.00') ? (parseFloat(productSelected.solution) * parseFloat(task.Planner.area_effective)) : 0;
          const quantityPlanned = this.calulateQuantityPlanned(task, productSelected);
          const unitQuantityPlanned = this.calulateUnitQuantityPlanned(productSelected);
          newProducts.push({
            quantityPlanned: `${quantityPlanned} ${unitQuantityPlanned}`,
            solutionPlanned: `${calcSolutionTotal.toFixed(0).toString()} L`,
            productName: listProducts[i].product.description,
          });
        }
      }
      return newProducts;
    },
    calulateQuantityPlanned(selectedObject, seletedProduct) {
      let quantityPlanned = parseFloat(selectedObject.area_effective * seletedProduct.dosis);
      if (!this.isKilogrameOrliter(seletedProduct)) {
        quantityPlanned = parseFloat((quantityPlanned * seletedProduct.dilution_factor) / 1000).toFixed(2);
      }
      return quantityPlanned;
    },
    calulateUnitQuantityPlanned(seletedProduct) {
      const unitQuantityPlanned = (typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('gr/'))
        ? 'Kg' : 'L';
      return unitQuantityPlanned;
    },
    isKilogrameOrliter(seletedProduct) {
      return typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('l/');
    },

    formatWorkers(workersArray) {
      const newWorkesArray = [];
      workersArray.forEach((element) => {
        let unit;
        let performance;
        if (element.unit === '') {
          performance = element.working_area;
          unit = 'HA';
        } else {
          performance = element.performance;
          unit = element.unit;
        }
        newWorkesArray.push({
          fullName: `${element.name} ${element.surname}`, type: element.type, performance, unit,
        });
      });
      return newWorkesArray;
    },
    formatLocations(locationsArray) {
      const newLocationsArray = [];
      locationsArray.forEach((element) => {
        newLocationsArray.push({ locationName: `${element}` });
      });
      return newLocationsArray;
    },
    formatNursery(nurseryArray) {
      const newNurseryArray = [];
      nurseryArray.forEach((element) => {
        newNurseryArray.push({
          nursery_code: element.code,
          nursery_variety: element.variety,
          nursery_category: element.category,
          nursery_class: element.class_type,
          nursery_numPlants: element.count,
        });
      });
      return newNurseryArray;
    },
    formatDrivers(itemDriverArray) {
      const dataDrivers = [];
      for (let i = 0; i < itemDriverArray.length; i += 1) {
        dataDrivers.push({
          text: `${itemDriverArray[i].item_data.description}(${itemDriverArray[i].item_data.code}): ${itemDriverArray[i].driver_data.name} ${itemDriverArray[i].driver_data.surname}`,
        });
      }
      return dataDrivers;
    },
    insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, childRow) {
      let newObject = {};
      if (childRow) {
        newObject = JSON.parse(JSON.stringify(emptyFields));
      } else {
        newObject = JSON.parse(JSON.stringify(baseFields));
      }
      if (drivers.length > 0) {
        newObject.drivers = drivers[0].text;
        drivers.splice(0, 1);
      }
      if (nursery.length > 0) {
        newObject.nursery_code = nursery[0].nursery_code;
        newObject.nursery_variety = nursery[0].nursery_variety;
        newObject.nursery_category = nursery[0].nursery_category;
        newObject.nursery_class = nursery[0].nursery_class;
        newObject.nursery_numPlants = nursery[0].nursery_numPlants;
        nursery.splice(0, 1);
      }
      if (workers.length > 0) {
        newObject.employes = workers[0].fullName;
        newObject.employes_type = workers[0].type;
        newObject.employes_performance = workers[0].performance;
        newObject.employes_performance_unit = workers[0].unit;
        workers.splice(0, 1);
      }
      if (products.length > 0) {
        newObject.products = products[0].productName;
        newObject.quantityPlanned = products[0].quantityPlanned;
        newObject.solutionPlanned = products[0].solutionPlanned;
        products.splice(0, 1);
      }
      if (locations.length > 0) {
        newObject.Location = locations[0].locationName;
        locations.splice(0, 1);
      }
      this.detailDailyTasksToExport.push(newObject);
      if (workers.length > 0 || drivers.length > 0 || products.length > 0 || nursery.length > 0 || locations.length > 0) {
        this.insertNewElementToExport(baseFields, emptyFields, drivers, products, workers, nursery, locations, true);
      }
    },
    getNamesByBdIds(ids, activityCode) {
      const names = [];
      const level = this.$helpers.getLevelLocationToDisplay(activityCode);
      for (const id of ids) {
        const newName = this.getLocationNameLevelSelectedChildId(id, level);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names;
    },
    getGoalPerformance(planner) {
      if (planner.goal_performance.unit === '') {
        return '';
      }
      return `${parseFloat(planner.goal_performance.count).toFixed(2)}`;
    },
    getUnit(planner) {
      if (planner.goal_performance.unit === '') {
        return 'HA';
      }
      return planner.goal_performance.unit;
    },
    getTotalWorkers(workers) {
      return workers.length;
    },
    getTotalWorkersPlanner(workersPlanner) {
      let element = 0;
      for (let i = 0; i < workersPlanner.length; i += 1) {
        element += workersPlanner[i].count;
      }
      return element;
    },
    getNameSupervisor(idSupervisor) {
      for (const supervisor of this.supervisorsAvailables) {
        if (supervisor.id === idSupervisor) {
          return `${supervisor.name} ${supervisor.surname}`;
        }
      }
      return '';
    },
    getVehicleDrivers(drivers) {
      let toReturn = '';
      drivers.forEach((element) => {
        const vehicle = `${element.item_data.description} ${element.item_data.code}:`;
        const driver = `${element.driver_data.name} ${element.driver_data.surname}`;
        toReturn += `${vehicle} ${driver};`;
      });
      return toReturn;
    },
    // FIN MÉTODOS PARA AÑADIR ELEMENTOS FORMATEADOS PARA EXPORTAR TODO:
  },
};
</script>
<style lang="scss">
.main-ExportDataToExcel {
  .buttonExport {
    width: 130px;
    line-height: 2.5!important;
    font-size: 12px;
    margin-left: -5px;
  }
}
</style>
