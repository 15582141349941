<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${currentParcel.name} > ${$t('sectors')}`" />
    <RegisterSectorStep1
      :ground-textures-availables="groundTexturesAvailables"
      :irrigation-types-availables="irrigationTypesAvailables"
      :current-sector="currentSector"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="goToStep2"
        >
          {{ $t("next") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <AutoRegisterSectorCoordinatesPopup />
    <PopupRegisterSectorStep2 />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterSectorStep1 from '../../components/sector/registerSectorStep1/index.vue';
import navbar from '../../components/NavBar.vue';
import AutoRegisterSectorCoordinatesPopup from './autoRegisterSectorCoordinatesPopup/index.vue';
import PopupRegisterSectorStep2 from '../../components/sector/popupRegisterSectorStep2/index.vue';

export default {
  name: 'RegisterSectorStep1Page',
  components: {
    RegisterSectorStep1,
    navbar,
    AutoRegisterSectorCoordinatesPopup,
    PopupRegisterSectorStep2,
  },
  data() {
    return {
      groundTexturesAvailables: [],
      irrigationTypesAvailables: [],
    };
  },
  computed: {
    ...mapState('Sector', ['currentSector', 'currentKeyValues']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        await this.fetchKeyValuesByFamily('GROUND_TEXTURE');
        this.groundTexturesAvailables = this.currentKeyValues;
        await this.fetchKeyValuesByFamily('IRRIGATION_TYPE');
        this.irrigationTypesAvailables = this.currentKeyValues;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goToStep2() {
      if (this.validationForm()) {
        // Esto es para el cuadro de diálogo que crea un sector que ocupa toda la parcela.
        if (this.currentParcel.children.length === 0 && !this.currentSector.id) {
          this.setShowAutoSector(true);
        } else {
          this.$f7.views.main.router.navigate('/registerSectorStep2Page/', { reloadCurrent: true });
        }
      }
    },
    goToBack() {
      if (typeof this.currentSector.id === 'undefined') {
        this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
      }
    },
    validationForm() {
      let validationMainForm = false;
      let validationIrrigationForm = false;
      if (this.currentSector.sectorName === '') {
        this.$f7.dialog.alert(this.$t('sector.register.required_name'));
      } else if (this.currentSector.groundTexture === '' || this.currentSector.groundTexture === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_groundTexture'));
      } else if (this.currentSector.irrigationType === '' || this.currentSector.irrigationType === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_irrigationType'));
      } else {
        validationMainForm = true;
      }

      if (this.currentSector.irrigationType !== ''
      && this.currentSector.irrigationType !== null) {
        switch (this.currentSector.irrigationType) {
          case 'GOTEO':
            validationIrrigationForm = this.validationIrrigationTypeGoteo();
            break;
          case 'ASPERSIÓN':
            validationIrrigationForm = this.validationIrrigationTypeAspersion();
            break;
          case 'INUNDACIÓN':
            validationIrrigationForm = this.validationIrrigationTypeInundacion();
            break;
          default:
            validationIrrigationForm = true;
            break;
        }
      }
      return validationMainForm && validationIrrigationForm;
    },
    validationIrrigationTypeGoteo() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      if (this.currentSector.dripperPerPlant === 0 || this.currentSector.dripperPerPlant === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_dripperPerPlant'));
        return false;
      }
      return true;
    },
    validationIrrigationTypeAspersion() {
      if (this.currentSector.sprinklersNumber === 0 || this.currentSector.sprinklersNumber === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_sprinklersNumber'));
        return false;
      }
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      if (this.currentSector.transformedArea === 0 || this.currentSector.transformedArea === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_transformedArea'));
        return false;
      }
      return true;
    },
    validationIrrigationTypeInundacion() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$f7.dialog.alert(this.$t('sector.register.required_flowPerPlant'));
        return false;
      }
      return true;
    },
    ...mapActions('Sector', ['fetchKeyValuesByFamily']),
    ...mapActions('Sector', ['setShowAutoSector']),
  },
};
</script>

<style scoped>

</style>
