<template>
  <f7-page>
    <navbar :text="$t('ReportingTimeWorked_navbar_title')" />
    <DXTimeWorked />
  </f7-page>
</template>

<script>
import DXTimeWorked from '../../components/reporting/DXTimeWorked.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'TimeWorked',
  components: {
    DXTimeWorked,
    navbar,
  },
};
</script>
