<template>
  <f7-block>
    <div class="margin-top-half">
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('Activity.code') }}
        </f7-col>
        <f7-col>
          {{ $t('Activity.name') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ currentActivity.code }}
        </f7-col>
        <f7-col>
          {{ currentActivity.name }}
        </f7-col>
      </f7-row>
    </div>
  </f7-block>
</template>
<script>

export default {
  name: 'ActivityDetail',
  props: {
    currentActivity: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ActivityDetail.styles.scss';
</style>
