var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"id":"form","caption":_vm.$t('Campania_title')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('TaskDefinition.code'),"editor-options":{
        value: _vm.currentTaskDefinition.code,
        onValueChanged: _vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.code_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('TaskDefinition.name'),"editor-options":{
        value:_vm.currentTaskDefinition.name,
        onValueChanged:_vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.name_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"activity","data-field":_vm.$t('TaskDefinition.activity'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.activities,
        value:_vm.currentTaskDefinition.activity_code,
        onValueChanged: _vm.onValueChanged,
        displayExpr: 'name',
        valueExpr: 'code',
        searchEnabled: true,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.activity_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"unit","data-field":_vm.$t('TaskDefinition.unit'),"editor-options":{
        value:_vm.currentTaskDefinition.unit,
        onValueChanged:_vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.unit_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"performance_unit","data-field":_vm.$t('TaskDefinition.performance_unit'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.performanceUnits,
        value:_vm.currentTaskDefinition.performance_unit,
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.unit_performance_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"price","data-field":_vm.$t('TaskDefinition.price'),"editor-options":{
        value:_vm.currentTaskDefinition.price,
        onValueChanged:_vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('TaskDefinition.price_validation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"prescription","data-field":_vm.$t('TaskDefinition.prescription'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.currentTaskDefinition.prescription,
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"nursery","data-field":_vm.$t('TaskDefinition.nursery'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.currentTaskDefinition.nursery,
        onValueChanged: _vm.onValueChanged,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }