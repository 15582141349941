<template>
  <DxChart
    id="chart"
    :data-source="histogramFormatted"
    :bar-group-width="100"
    palette="Material"
  >
    <DxTitle
      :text="$t('histogram')"
    />
    <DxSeries
      argument-field="x"
      value-field="y"
      :name="`${index} - ${formattingShowingDate}`"
      type="bar"
    />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    />
    <DxScrollBar :visible="true" />
    <DxZoomAndPan argument-axis="both" />
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
    />
  </DxChart>
</template>
<script>
import {
  DxChart, DxSeries, DxTooltip, DxScrollBar, DxZoomAndPan, DxLegend, DxTitle,
} from 'devextreme-vue/chart';
import moment from 'moment';

export default {
  name: 'HistogramGraph',
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxLegend,
    DxTitle,
  },
  props: {
    histogram: {
      type: Object,
      default: () => {},
    },
    showingDate: {
      type: String,
      default: '',
    },
    index: {
      type: String,
      default: '',
    },
  },
  computed: {
    histogramFormatted() {
      const auxHistogramFormatted = [];
      for (let i = 0; i < this.histogram.hist_X.length - 1; i += 1) {
        auxHistogramFormatted.push({ x: (this.histogram.hist_X[i] + this.histogram.hist_X[i + 1]) / 2, y: this.histogram.hist_Y[i] });
      }
      return auxHistogramFormatted;
    },
    formattingShowingDate() {
      return moment(this.showingDate).format('DD-MM-YYYY');
    },
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `
          <span class='bold'>${this.$t('index')}: ${pointInfo.argument.toFixed(2)}</span><br>
          <span class='bold'>${this.$t('count')}: ${pointInfo.value.toFixed(2)}</span>
        `,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './HistogramGraph.styles.scss';
</style>
