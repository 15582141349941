<template>
  <div>
    <DxDataGrid
      id="gridProductPerformance"
      ref="gridProductPerformance"
      :visible="productPopup.length !== 0"
      :data-source="productPopup"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="false"
      :on-row-updated="showButtonSave"
      @exporting="onExportingProductPopup"
    >
      <DxEditing
        :allow-updating="true"
        :select-text-on-edit-start="selectTextOnEditStart"
        :start-edit-action="startEditAction"
        mode="batch"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="item_id"
        :caption="$t('DxProductPerformance_products_name')"
        :customize-text="lookingForName"
        :allow-editing="false"
      />
      <DxColumn
        data-field="qty_planned"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_qtyPlanned')"
        :calculate-cell-value="calculateQtyPlanned"
      />
      <DxColumn
        data-field="qty_planned_unit"
        alignment="left"
        data-type="string"
        name="qty_planned_unit"
        :caption="$t('DxProductPerformance_unit')"
        :allow-editing="false"
        :calculate-cell-value="getStandardUnit"
        :width="100"
      />
      <DxColumn
        data-field="qty_collected"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_qtyCollected')"
        :calculate-cell-value="calculateQtyCollected"
      />
      <DxColumn
        data-field="qty_collected_unit"
        alignment="left"
        data-type="string"
        name="qty_collected_unit"
        :caption="$t('DxProductPerformance_unit')"
        :calculate-cell-value="getStandardUnit"
        :allow-editing="false"
      />
      <!-- <DxColumn
        data-field="total_real_prescription"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_totalRealPrescription')"
        :calculate-cell-value="calculateTotalRealPrescription"
      /> -->
      <DxColumn
        data-field="qty_used"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_qtyUsed')"
        :allow-editing="true"
        :calculate-cell-value="calculateQtyUsed"
      />
      <DxColumn
        data-field="qty_used_unit"
        alignment="left"
        data-type="string"
        name="qty_used_unit"
        :caption="$t('DxProductPerformance_unit')"
        :allow-editing="false"
        :calculate-cell-value="getStandardUnit"
      />
      <DxColumn
        data-field="dose_used"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_doseUsed')"
        :calculate-cell-value="calculateDoseUsed"
        :allow-editing="false"
      />
      <DxColumn
        data-field="dose_used_unit"
        alignment="left"
        data-type="string"
        name="dose_used_unit"
        :caption="$t('DxProductPerformance_unit')"
        :calculate-cell-value="calculateDoseUsedUnit"
        :allow-editing="false"
      />
      <DxColumn
        data-field="qty_return_warehouse"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_qtyReturnWarehouse')"
        :calculate-cell-value="calculateQtyReturnWarehouse"
        :allow-editing="false"
      />
      <DxColumn
        data-field="qty_return_warehouse_unit"
        alignment="left"
        data-type="string"
        name="qty_return_warehouse_unit"
        :caption="$t('DxProductPerformance_unit')"
        :calculate-cell-value="getStandardUnit"
        :allow-editing="false"
      />
    </DxDataGrid>

    <DxDataGrid
      id="dxSolutionGrid"
      ref="dxSolutionGrid"
      :data-source="computedSolution"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      style="margin-top: 20px;"
      @row-updated="updatedSolutionVuex"
    >
      <DxEditing
        :allow-updating="true"
        :allow-deleting="false"
        :allow-adding="false"
        mode="row"
      />
      <DxColumn
        data-field="total_real_prescription"
        data-type="number"
        :editor-options="numberColumnOptions"
        :caption="$t('DxProductPerformance_products_totalRealPrescription')"
        :calculate-cell-value="calculateTotalRealPrescription"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../services/Api';

export default {
  name: 'DxProductPerformance',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxEditing,
  },
  props: {
    productPopup: { type: Array, default: () => [] },
  },
  data() {
    return {
      productsAvaibles: [],
      selectTextOnEditStart: true,
      startEditAction: 'click',
      numberColumnOptions: {
        showSpinButtons: true, min: 0, showClearButton: true,
      },
    };
  },
  computed: {
    ...mapState('PlanningManager', ['actualWorkOrder']),
    computedSolution() {
      if (this.productPopup.length > 0) {
        return [this.productPopup[0]];
      }
      return [];
    },
  },
  methods: {
    calculateQtyReturnWarehouse(rowData) {
      const value = rowData.qty_collected - rowData.qty_used;
      return `${value}`;
    },
    calculateDoseUsed(rowData) {
      const selectedProduct = this.getProductsById(rowData.item_id);
      return (selectedProduct.dosisUt.toLowerCase().includes('gr/')
      || selectedProduct.dosisUt.toLowerCase().includes('cc/')) ? rowData.qty_used * 1000 : rowData.qty_used;
    },
    calculateDoseUsedUnit(rowData) {
      const selectedProduct = this.getProductsById(rowData.item_id);
      return selectedProduct.dosisUt.toLowerCase().substr(0, selectedProduct.dosisUt.toLowerCase().indexOf('/'));
    },
    calculateTotalRealPrescription(rowData) {
      return `${rowData.total_real_prescription}`;
    },
    getStandardUnit(rowData) {
      const selectedProduct = this.getProductsById(rowData.item_id);
      if (selectedProduct === null) return `${rowData.dosisUt}`;
      return (selectedProduct.dosisUt.toLowerCase().includes('kg/')
      || selectedProduct.dosisUt.toLowerCase().includes('gr/')) ? 'Kg' : 'L';
    },
    getProductsById(id) {
      for (let i = 0; i < this.actualWorkOrder.products.length; i += 1) {
        if (this.actualWorkOrder.products[i].product.id === id) return this.actualWorkOrder.products[i];
      }
      return null;
    },
    calculateQtyPlanned(rowData) {
      return `${rowData.qty_planned}`;
    },
    // TODO: REVISAR LOOKINFORNAME SI LOS DATOS DE PRODUCT PERFORMANCE Y PRODUCTS SON LOS MISMOS. OPTIMIZACIÓN DE CÓDIGO.
    lookingForName(e) {
      for (let i = 0; i < this.actualWorkOrder.products.length; i += 1) {
        if (this.actualWorkOrder.products[i].product.id === e.value) {
          return this.actualWorkOrder.products[i].product.description;
        }
      }
      return '';
    },
    calculateTotal(e) {
      for (let i = 0; i < this.actualWorkOrder.products.length; i += 1) {
        if (this.actualWorkOrder.products[i].product.id === e.value) {
          const totalValue = this.productPopup[i].qty_collected - this.productPopup[i].qty_used;
          return totalValue.toString();
        }
      }
      return '';
    },
    async getProducts() {
      const xhr = await Api.getDataSubfamilyByType('PRODUCT');
      return JSON.parse(xhr.response);
    },
    // UTILITIES
    showButtonSave() {
      this.setSaveButtonPopupWorkOrder(false);
    },
    // EXPORT EXCEL
    onExportingProductPopup(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ProductPerformance');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProductPerformance.xlsx');
          });
      });
      e.cancel = true;
    },
    calculateQtyCollected(rowdata) {
      if (rowdata.qty_collected < rowdata.qty_used) {
        return rowdata.qty_used;
      }
      return rowdata.qty_collected;
    },
    calculateQtyUsed(rowdata) {
      if (rowdata.qty_used > rowdata.qty_collected) {
        return rowdata.qty_collected;
      }
      return rowdata.qty_used;
    },
    updatedSolutionVuex(e) {
      for (let i = 0; i < this.productPopup.length; i += 1) {
        this.productPopup[i].total_real_prescription = e.data.total_real_prescription;
      }
      this.showButtonSave();
    },
    ...mapActions('PlanningManager', ['setSaveButtonPopupWorkOrder']),
  },

};
</script>
