<template>
  <div
    class="content-devx no-margin margin-top margin-left"
  >
    <f7-row>
      <f7-col width="85">
        <f7-block-title>
          {{ $t('sector.detail.title') }}
        </f7-block-title>
      </f7-col>
      <f7-col width="15">
        <f7-block-title>
          <f7-button
            class="dx-btn-edit"
            raised
            type="default"
            styling-mode="outlined"
            @click="editCurrentSector"
          >
            <f7-icon
              md="f7:pencil"
            />
          </f7-button>
        </f7-block-title>
      </f7-col>
    </f7-row>
    <f7-block>
      <f7-row>
        <f7-col>
          {{ $t('parcel.detail.client') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentClient }}
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('sector.detail.location') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.parcelName') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.sectorName') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentSector.explotation }}
        </f7-col>
        <f7-col>
          {{ currentSector.parcelName }}
        </f7-col>
        <f7-col>
          {{ currentSector.sectorName }}
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('sector.detail.area') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.groundTexture') }}
        </f7-col>
        <f7-col />
      </f7-row>
      <f7-row class="bold">
        <f7-col v-if="currentSector.area">
          {{ currentSector.area.toFixed(2) }} ha
        </f7-col>
        <f7-col>
          {{ currentSector.groundTexture }}
        </f7-col>
        <f7-col />
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('sector.detail.irrigationType') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentSector.irrigationType }}
        </f7-col>
      </f7-row>
      <div v-if="currentSector.irrigationType === 'GOTEO'">
        <f7-row>
          <f7-col>
            {{ $t('sector.detail.flow') }}
          </f7-col>
          <f7-col>
            {{ $t('sector.detail.dripperPerPlant') }}
          </f7-col>
          <f7-col />
        </f7-row>
        <f7-row class="bold">
          <f7-col>
            {{ currentSector.flow }} {{ $t('sector.detail.lH') }}
          </f7-col>
          <f7-col>
            {{ currentSector.dripperPerPlant }}
          </f7-col>
          <f7-col />
        </f7-row>
      </div>
      <div v-if="currentSector.irrigationType === 'ASPERSIÓN'">
        <f7-row>
          <f7-col>
            {{ $t('sector.detail.sprinklersNumber') }}
          </f7-col>
          <f7-col>
            {{ $t('sector.detail.flow') }}
          </f7-col>
          <f7-col>
            {{ $t('sector.detail.transformedArea') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold">
          <f7-col>
            {{ currentSector.sprinklersNumber }}
          </f7-col>
          <f7-col>
            {{ currentSector.flow }} {{ $t('sector.detail.lH') }}
          </f7-col>
          <f7-col>
            {{ currentSector.transformedArea }} {{ $t('sector.detail.m2') }}
          </f7-col>
        </f7-row>
      </div>
      <div v-if="currentSector.irrigationType === 'INUNDACIÓN'">
        <f7-row>
          <f7-col>
            {{ $t('sector.detail.flow') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold">
          <f7-col>
            {{ currentSector.flow }} {{ $t('sector.detail.lH') }}
          </f7-col>
        </f7-row>
      </div>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'SectorDetail',
  props: {
    currentSector: {
      type: Object,
      default: () => {},
    },
    currentClient: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    editCurrentSector() {
      this.$f7.views.main.router.navigate('/registerSectorStep1Page/', { reloadCurrent: true });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './SectorDetail.styles.scss';
</style>
