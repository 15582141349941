import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    tasksDefinitions: [],
    currentTaskDefinition: {},
    activities: [],
    performanceUnits: [],
  },

  actions: {
    async fetchTasksDefinitions({ commit }) {
      try {
        const xhr = await Api.fetchTasksDefinitions();
        const tasks = JSON.parse(xhr.response).data;
        commit('fetchTasksDefinitions', tasks);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTaskDefinitionById({ commit }, taskID) {
      try {
        const xhr = await Api.getTaskDefinitionById(taskID);
        const task = JSON.parse(xhr.response);
        commit('setCurrentTaskDefinition', task);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createTaskDefinition({ commit }, newTask) {
      try {
        const xhr = await Api.createTaskDefinition(newTask);
        const task = JSON.parse(xhr.response);
        commit('updateCurrentTaskDefinitionProperty', { property: 'id', newValue: task.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateTaskDefinition({ commit }, currentTaskDefinition) {
      try {
        const xhr = await Api.updateTaskDefinition(currentTaskDefinition);
        const task = JSON.parse(xhr.response);
        commit('updateCurrentTaskDefinitionProperty', { property: 'id', newValue: task.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteTaskDefinition(_, taskID) {
      try {
        await Api.deleteTaskDefinition(taskID);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchActivities({ commit }) {
      try {
        const xhr = await Api.fetchActivitiesAvailables();
        const activities = JSON.parse(xhr.response).data;
        commit('fetchActivities', activities);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchPerformanceUnits({ commit }) {
      try {
        const xhr = await Api.fetchKeyValuesByFamily('PERFORMANCE_UNITS');
        const performanceUnits = JSON.parse(xhr.response)[0].values;
        commit('fetchPerformanceUnits', performanceUnits);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    updateCurrentTaskDefinitionProperty({ commit }, { property, newValue }) {
      commit('updateCurrentTaskDefinitionProperty', { property, newValue });
    },
    initStore({ commit }) {
      commit('initStore');
    },
  },
  mutations: {
    fetchTasksDefinitions(state, value) {
      state.tasksDefinitions = value;
    },
    setCurrentTaskDefinition(state, value) {
      state.currentTaskDefinition = value;
    },
    updateCurrentTaskDefinitionProperty(state, { property, newValue }) {
      state.currentTaskDefinition[property] = newValue;
    },
    fetchActivities(state, value) {
      state.activities = value;
    },
    fetchPerformanceUnits(state, value) {
      state.performanceUnits = value;
    },
    initStore(state) {
      state.currentTaskDefinition = {};
      state.currentTaskDefinition.code = '';
      state.currentTaskDefinition.name = '';
      state.currentTaskDefinition.activity = '';
      state.currentTaskDefinition.activity_code = '';
      state.currentTaskDefinition.unit = '';
      state.currentTaskDefinition.performance_unit = '';
      state.currentTaskDefinition.price = 0;
      state.currentTaskDefinition.prescription = false;
      state.currentTaskDefinition.nursery = false;
    },

  },
};
