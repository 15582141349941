<template>
  <f7-block v-if="loaded">
    <f7-row>
      <f7-col>
        <ObservationsDatagrid
          :observations="observations"
          :company-id="currentPlantation.companyId"
        />
      </f7-col>
      <f7-col>
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISGeoJsonObservationMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            :observations="observations"
          />
        </f7-block>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import ObservationsDatagrid from './observationsDatagrid/index.vue';
import GISGeoJsonObservationMap from '../../../Gis/GISGeoJsonObservationMap/index.vue';

export default {
  name: 'ObservationsDatabase',
  components: {
    ObservationsDatagrid,
    GISGeoJsonObservationMap,
  },
  data() {
    return {
      loaded: false,
      loadedGIS: false,
      zoom: 17,
      mainMap: 'ObservationsDataBase',
    };
  },
  computed: {
    ...mapState('SearcherObservations', ['observations']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationsByPlantation();
      this.loadedGIS = true;
      await this.getObservations({ companyId: this.currentPlantation.companyId, currentPlantationId: this.currentPlantation.id });
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('SearcherObservations', ['getObservations']),
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
  },
};
</script>
<style lang="scss" scoped>
@import './ObservationsDatabase.styles.scss';
</style>
