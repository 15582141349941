<template>
  <div>
    <DxPopup
      :visible="showInsertedPopUP"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :show-close-button="true"
      :width="500"
      height="auto"
      :title="$t('registerAnalaysisTitle')"
    >
      <template v-if="!edit">
        <form
          id="form"
          :ref="formRefName"
          method="post"
          action=""
          enctype="multipart/form-data"
        >
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisSampling_date') }}
              </div>
              <DxDateBox
                name="sampleDate"
                class="dx-field-value"
                display-format="dd-MM-yyyy"
                value=""
                @value-changed="onChangeSampleDate"
              />
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisSampling_type') }}
              </div>
              <DxSelectBox
                :items="sampleType"
                name="sampleType"
                class="dx-field-value"
                value=""
                @value-changed="onChangeSampleType"
              />
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisDescription') }}
              </div>
              <DxTextBox
                name="sampleDate"
                class="dx-field-value"
                value=""
                @value-changed="onChangeDescription"
              />
            </div>
            <div class="dx-field main-fileupload">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisFile_Attachment') }}
              </div>
              <div class="fileuploader-container">
                <Attach-a-file />
              </div>
              <span class="note">
                {{ $t('RegisterAnalysisFile_allowed_extension') }}
                <span>.pdf, .jpeg, .png</span>.
              </span>
              <br></br>
              <div class="dx-field">
                <div class="dx-field-label">
                  {{ $t('RegisterAnalysisInterpretation') }}
                </div>
                <DxTextArea
                  :placeholder="$t('RegisterAnalysisInterpretation_textArea')"
                  name="Interpretatino"
                  class="dx-field-value"
                  value=""
                  @value-changed="onChangeInterpretation"
                />
              </div>
              <div>
                <f7-button
                  class="dx-btn-success"
                  raised
                  type="success"
                  styling-mode="contained"
                  @click="postAnalaysis"
                >
                  {{ $t("Button_go_create_new_Campania") }}
                </f7-button>
              </div>
            </div>
          </div>
        </form>
      </template>
      <template v-else>
        <form
          id="form"
          :ref="formRefName"
          method="post"
          action=""
          enctype="multipart/form-data"
        >
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisSampling_date') }}
              </div>
              <DxDateBox
                name="sampleDate"
                class="dx-field-value"
                display-format="dd-MM-yyyy"
                :value="actualLaboratoryAnalysis.date"
                @value-changed="onChangeSampleDate"
              />
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisSampling_type') }}
              </div>
              <DxSelectBox
                :items="sampleType"
                name="sampleType"
                class="dx-field-value"
                :value="actualLaboratoryAnalysis.sample_type"
                @value-changed="onChangeSampleType"
              />
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('RegisterAnalysisDescription') }}
              </div>
              <DxTextBox
                name="description"
                class="dx-field-value"
                :value="actualLaboratoryAnalysis.description"
                @value-changed="onChangeDescription"
              />
            </div>
            <div class="dx-field main-fileupload">
              <div class="dx-field-label text-align-left">
                {{ $t('RegisterAnalysisFile_Attachment') }}
              </div>
              <div class="fileuploader-container">
                <Attach-a-file />
              </div>
              <span class="note">
                {{ $t('RegisterAnalysisFile_allowed_extension') }}
                <span>.pdf, .jpeg, .png</span>.
              </span>
              <br></br>
              <div class="dx-field">
                <div class="dx-field-label">
                  {{ $t('RegisterAnalysisInterpretation') }}
                </div>
                <DxTextArea
                  :placeholder="$t('RegisterAnalysisInterpretation_textArea')"
                  name="Interpretatino"
                  class="dx-field-value"
                  :value="actualLaboratoryAnalysis.interpretation"
                  @value-changed="onChangeInterpretation"
                />
              </div>
              <div>
                <f7-button
                  class="dx-btn-success"
                  raised
                  type="success"
                  styling-mode="contained"
                  @click="updAnalysis"
                >
                  {{ $t("Button_go_create_new_Campania") }}
                </f7-button>
              </div>
            </div>
          </div>
        </form>
      </template>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import moment from 'moment-timezone';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import AttachAFile from './attachAFile.vue';

export default {
  name: 'DxPopUpRegisterPloit',
  components: {
    DxPopup,
    DxTextBox,
    DxSelectBox,
    DxTextArea,
    DxDateBox,
    AttachAFile,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actualPlot: '',
      formRefName: 'tree-view',
      sampleType: ['Agua', 'Suelo', 'Material vegetal', 'Solución suelo', 'Solución Fertilización de riego'],
      files: [],
      base64: '',
      actualDateSample: '',
      actualTypeSample: '',
      actualDescription: '',
      actualInterpretation: '',
    };
  },
  computed: {
    ...mapState('Explotaciones', ['showInsertedPopUP']),
    ...mapState('Nutrition', ['actualLaboratoryAnalysis', 'description', 'date', 'sample_type']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    this.setShowInsertedPopUP(false);
  },
  methods: {
    onHidden() {
      this.setShowInsertedPopUP(false);
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    onChangeSampleDate(e) {
      this.setDate(moment(e.value).format('YYYY-MM-DD'));
    },
    onChangeSampleType(e) {
      this.setSampleType(e.value);
    },
    onChangeDescription(e) {
      this.setDescription(e.value);
    },
    onChangeInterpretation(e) {
      this.setInterpretation(e.value);
    },
    async postAnalaysis() {
      try {
        if (this.date === '') {
          this.$f7.dialog.alert(this.$t('laboratory_nutrition_date_required'));
        } else if (this.sample_type === '') {
          this.$f7.dialog.alert(this.$t('laboratory_nutrition_type_required'));
        } else if (this.description === '') {
          this.$f7.dialog.alert(this.$t('laboratory_nutrition_description_required'));
        } else {
          await this.postNewAnalysis(this.currentPlantation);
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    async updAnalysis() {
      try {
        await this.updateAnalysis(this.currentPlantation);
        this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    ...mapActions('Explotaciones', ['setShowInsertedPopUP']),
    ...mapActions('Nutrition', ['postNewAnalysis', 'setAttachment', 'setDate',
      'setInterpretation', 'setDescription', 'setPlantation', 'setSampleType',
      'updateAnalysis']),
  },
};
</script>

<style scoped>
.main-fileupload {
  display: flex;
  flex-direction: column;
  text-align: left;
}
</style>
