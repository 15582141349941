<template>
  <f7-block>
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link
        href="./gisWMS/"
        route-tab-id="tab-gisWMS"
        @click="setTabActive('gisWMS')"
      >
        {{ $t('Searcher.gis.gisWMS.title') }}
      </f7-link>
      <f7-link
        tab-link
        href="./gisTimeDimension/"
        route-tab-id="tab-gisTimeDimension"
        @click="setTabActive('gisTimeDimension')"
      >
        {{ $t('Searcher.gis.gisTimeDimension.title') }}
      </f7-link>
      <f7-link
        tab-link
        href="./gisScanning/"
        route-tab-id="tab-gisScanning"
        @click="setTabActive('gisScanning')"
      >
        {{ $t('Searcher.gis.gisScanning.title') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      animated
      routable
    >
      <f7-tab
        id="tab-gisWMS"
        class="page-content"
      >
        <GisWMS v-if="currentSubTab === 'gisWMS'" />
      </f7-tab>
      <f7-tab
        id="tab-gisTimeDimension"
        class="page-content"
      >
        <GisTimeDimension v-if="currentSubTab === 'gisTimeDimension'" />
      </f7-tab>
      <f7-tab
        id="tab-gisScanning"
        class="page-content"
      >
        <GisScanning v-if="currentSubTab === 'gisScanning'" />
      </f7-tab>
    </f7-tabs>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GisWMS from './gisWMS/index.vue';
import GisTimeDimension from './gisTimeDimension/index.vue';
import GisScanning from './gisScanning/index.vue';

export default {
  name: 'GisSearcher',
  components: {
    GisWMS,
    GisTimeDimension,
    GisScanning,
  },
  computed: {
    ...mapState('Utilities', ['currentSubTab']),
  },
  async beforeMount() {
    this.setCurrentSubTab('gisWMS');
  },
  methods: {
    setTabActive(tabSelected) {
      this.setCurrentSubTab(tabSelected);
    },
    ...mapActions('Utilities', ['setCurrentSubTab']),
  },
};
</script>

<style lang="scss" scoped>
@import './Gis.styles.scss';
</style>
