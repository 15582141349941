<template>
  <f7-page>
    <navbar :text="$t('EffortTask_navbar_title')" />
    <f7-row>
      <f7-col width="35">
        <DxEffort />
      </f7-col>
      <f7-col width="65">
        <!-- <GisEffort /> -->
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col width="50">
        <DxEffortTask />
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>

import navbar from '../../components/NavBar.vue';
import DxEffort from '../../components/reporting/DxEffort.vue';
import DxEffortTask from '../../components/reporting/DxEffortTask.vue';
import GisEffort from '../../components/reporting/GisEffort.vue';

export default {
  components: {
    navbar,
    DxEffort,
    DxEffortTask,
    GisEffort,
  },
};
</script>

<style>

</style>
