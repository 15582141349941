<template>
  <div v-if="loaded">
    <div
      class="content-devx main-batchesTable"
    >
      <DxDataGrid
        id="batchesTable"
        ref="batchesTable"
        :data-source="batchlot"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
      >
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <!-- <DxColumn
          type="buttons"
          data-field=""
        >
          <DxButton
            icon="edit"
            :hint="$t('DxDailyPlanning_edit_hint')"
            :on-click="editBatch"
          />
          <DxButton
            icon="trash"
            :hint="$t('DxDailyPlanning_delete_hint')"
            :on-click="deteleBatch"
          />
        </DxColumn> -->
        <DxColumn
          type="buttons"
          data-field=""
          :allow-header-filtering="false"
          cell-template="buttonsTemplate"
        />
        <DxColumn
          data-field="code"
          name="code"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_Code')"
        />
        <DxColumn
          data-field="creation"
          name="creation"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_CreationDate')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_NameBatch')"
        />
        <DxColumn
          data-field="bins"
          name="bins"
          alignment="left"
          :caption="$t('DxBatches_dxColumn_BinsList')"
        />
        <DxColumn
          data-field="state"
          name="state"
          alignment="center"
          :caption="$t('DxBinsTable_dxColumn_State')"
          :allow-header-filtering="true"
          :header-filter="{ dataSource: dataStatus }"
          cell-template="statusTemplate"
        />
        <template #statusTemplate="{ data: batchlot }">
          <div :class="batchlot.data.statusTemplateClass">
            {{ batchlot.data.state }}
          </div>
        </template>
        <DxMasterDetail
          :enabled="true"
          template="master-detail"
        />
        <template #master-detail="{ data: batchlot }">
          <DxDetailTabsBatches :master-detail-data="batchlot" />
        </template>
        <template #buttonsTemplate="{ data: batchlot }">
          <!-- REGISTRADO -->
          <div
            v-if="batchlot.data.statusTemplateClass == 'registered'"
            class="templateButtons"
          >
            <!-- editar -->
            <button
              class="dx-icon-edit boton-editar"
              @click="editBatch(batchlot.data)"
            />
            <!-- enviar -->
            <button
              class="boton-enviar"
              @click="sendActualBatch(batchlot.data)"
            >
              {{ $t('DxBinsTable_ButtonText_Send') }}
            </button>
            <!-- cerrar -->
            <button
              class="boton-cerrar"
              @click="closeBatch(batchlot.data.id)"
            >
              {{ $t('DxBinsTable_ButtonText_Close') }}
            </button>
            <!-- borrar -->
            <button
              class="dx-icon-trash boton-borrar"
              @click="deteleBatch(batchlot.data)"
            />
          </div>

          <!-- RECIBIDO -->
          <div
            v-else-if="batchlot.data.statusTemplateClass == 'received'"
            class="templateButtons"
          >
            <!-- editar -->
            <button
              class="dx-icon-edit boton-editar"
              @click="editBatch(batchlot.data)"
            />
          </div>
          <!-- ENVIADO y CERRADO sin acciones-->
        </template>
      </DxDataGrid>
    </div>
    <DxPopup
      :visible.sync="popupOpened"
      :title="$t('DxSelectLevel0Location_modal_title')"
      :show-close-button="true"
      width="auto"
      height="auto"
    >
      <template>
        <DxSelectLevel0Location
          :level-zero-locations="levelZeroFilteredLocations"
        />
        <f7-block-footer
          class="content-action-botton"
        >
          <f7-button
            class="dx-btn-cancel margin-right"
            raised
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="closePopup"
          >
            {{ $t('DxBinsTable_button_cancel') }}
          </f7-button>
          <f7-button
            class="dx-btn-success"
            raised
            type="success"
            styling-mode="contained"
            name="ButtonSave"
            :disabled="isLocationToSendSelected"
            @click="confirmSendActualBatch()"
          >
            {{ $t("DxBinsTable_button_send") }}
          </f7-button>
        </f7-block-footer>
      </template>
    </DxPopup>
  </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxMasterDetail,
  DxExport,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import Api from '../../services/Api';
import DxDetailTabsBatches from './DxDetailTabsBatches.vue';
import DxSelectLevel0Location from './DxSelectLevel0Location.vue';

export default {
  name: 'DxBatchesTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxMasterDetail,
    DxDetailTabsBatches,
    DxExport,
    DxPopup,
    DxSelectLevel0Location,
  },
  data() {
    return {
      batchlot: [],
      loaded: false,
      statusTranslated: '',
      popupOpened: false,
      levelZeroFilteredLocations: [],
      dataStatus: [{
        text: this.$t('DxBinsTable_State_Created'),
        value: 'CREATED',
      },
      {
        text: this.$t('DxBinsTable_State_Registered'),
        value: 'REGISTERED',
      }, {
        text: this.$t('DxBinsTable_State_Closed'),
        value: 'CLOSED',
      },
      {
        text: this.$t('DxBinsTable_State_Sent'),
        value: 'SENT',
      }],
      dataWordTaskStatus: [{
        text: this.$t('DxBinsTable_State_Received'),
        value: 'RECEIVED',
      }, {
        text: this.$t('DxBinsTable_State_ToReceive'),
        value: 'TO_RECEIVE',
      }],
    };
  },
  computed: {
    ...mapState('greenhouse', [
      'selectedFarmToSend',
    ]),
    ...mapGetters('greenhouse', [
      'levelZeroLocationsNamesToSendBin',
      'getLocationNameByLocationId',
    ]),
    isLocationToSendSelected() {
      return this.selectedFarmToSend === '';
    },
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getBatches();
      const batches = JSON.parse(xhr.response);
      for (let i = 0; i < batches.data.length; i += 1) {
        this.batchlot.push({
          code: batches.data[i].code,
          creation: batches.data[i].creation,
          name: batches.data[i].name,
          bins: this.binsFromBatch(batches.data[i].bins),
          binsDetail: batches.data[i].bins,
          id: batches.data[i].id,
          locationId: batches.data[i].farm,
          statusTemplateClass: this.setClassByStatus(batches.data[i].status),
          state: this.statusTranslated,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    sendActualBatch(data) {
      this.levelZeroFilteredLocations = this.levelZeroLocationsNamesToSendBin.filter(
        (elemento) => elemento !== this.getLocationNameByLocationId(data.locationId),
      );
      this.setIdBatchToSend(data.id);
      this.setSelectedFarmToSend('');
      this.popupOpened = true;
    },
    async confirmSendActualBatch() {
      try {
        const response = await this.fetchSendBatch();
        if (response.status === 200) {
          this.setActualMenuSelected('/batches/');
          this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
        } else {
          const message = this.$helpers.getFilteredErrorMessage(response);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    closePopup() {
      this.popupOpened = false;
    },
    async closeBatch(bdId) {
      try {
        const response = await Api.closeBatch(bdId);
        if (response.status === 200) {
          this.setActualMenuSelected('/batches/');
          this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
        } else {
          const message = this.$helpers.getFilteredErrorMessage(response);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    binsFromBatch(bins) {
      let binslist = '';
      for (let i = 0; i < bins.length; i += 1) {
        binslist += bins[i].code;
        if (bins.length - 1 > i) {
          binslist += ' - ';
        }
      }
      return binslist;
    },
    async deteleBatch(rowSelected) {
      try {
        const response = await this.fetchDeleteBatch(rowSelected.id);
        if (response.status === 204) {
          this.setActualMenuSelected('/batches/');
          this.$f7.views.main.router.navigate('/batches/', { reloadCurrent: true });
        } else {
          const message = this.$helpers.getFilteredErrorMessage(response);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        }
      } catch (e) {
        const message = this.$helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    editBatch(rowSelected) {
      this.setLocationBatch(rowSelected.locationId);
      this.setIdBatch(rowSelected.id);
      this.setNameBatch(rowSelected.name);
      this.setDateBatch(rowSelected.creation);
      this.setLotBins(rowSelected.binsDetail);
      this.setSelectedBins(rowSelected.binsDetail);
      this.setActualMenuSelected('/batches/edit/');
      this.$f7.views.main.router.navigate('/batches/edit/', { reloadCurrent: true });
    },
    // SET CSS CLASSES TO CELLS
    setClassByStatus(data) {
      this.statusTranslated = '';
      let colorCell = '';
      let status = data;
      if (status === 'SENT' && this.binToReceive === true) {
        status = 'TO_RECEIVE';
      }
      this.binToReceive = false;
      switch (status) {
        // CREATED REGISTERED SENT TO_RECEIVE RECEIVED CLOSED
        case 'CREATED':
          colorCell = 'created';
          this.statusTranslated = this.$t('DxBinsTable_State_Created');
          break;
        case 'REGISTERED':
          colorCell = 'registered';
          this.statusTranslated = this.$t('DxBinsTable_State_Registered');
          break;
        case 'SENT':
          colorCell = 'sent';
          this.statusTranslated = this.$t('DxBinsTable_State_Sent');
          break;
        case 'TO_RECEIVE':
          colorCell = 'to_receive';
          this.statusTranslated = this.$t('DxBinsTable_State_ToReceive');
          break;
        case 'RECEIVED':
          colorCell = 'received';
          this.statusTranslated = this.$t('DxBinsTable_State_Received');
          break;
        case 'CLOSED':
          colorCell = 'closed';
          this.statusTranslated = this.$t('DxBinsTable_State_Closed');
          break;
        default:
          break;
      }
      return colorCell;
    },
    ...mapActions('greenhouse', [
      'setNameBatch',
      'setDateBatch',
      'setLotBins',
      'setSelectedBins',
      'setIdBatch',
      'fetchDeleteBatch',
      'setLocationBatch',
      'setSelectedFarmToSend',
      'setIdBatchToSend',
      'fetchSendBatch',
    ]),
    ...mapActions('authentication', ['setActualMenuSelected']),
  },
};
</script>
<style lang="scss">
.templateButtons {
  display: flex;
  justify-content: flex-start;
}
.main-batchesTable, .templateButtons{
  .content-status-cell {
    padding: 0;
  }
  .created, .registered, .closed, .sent, .received, .to_receive{
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 2px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .created {
    color: var(--fr-open);
    background: #F4F5F7;
    border: 1px solid rgba(47, 72, 89, 0.35);
  }
  .registered {
    color: white;
    background: #556080;
  }
  .closed {
    background: var(--fr-deleted-tint);
    color: var(--fr-deleted);
    border: 1px solid var(--fr-deleted);
  }
  .received {
    background: var(--fr-finished);
    color: var(--fr-finished-tint);
  }
  .sent {
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange);
  }
  .to_receive {
    color: white;
    background: #4A98F7;
  }
  .boton-enviar .boton-cerrar {
    font-size: 13px;
    line-height: 1.2;
    padding: 4px 8px 3px!important;
    width: auto;
    min-width: 26px;
    margin: 0 2px;
    border: 0;
    border-radius: 2px;
    outline:none;
  }
  .boton-enviar {
    background-color: #daeed2;
    border: 1px solid rgba(54,121,14,0.35);
    color: var(--ha-dark-green);
    &:hover{
      background: #acd99a;
    }
  }
  .boton-cerrar {
    background-color: #e6e6e6;
    border: 1px solid rgba(135,119,135,0.35);
    color: #877787;
    &:hover{
      background:#dddddd;
    }
  }
  .boton-editar, .boton-borrar {
    width: auto;
    padding: 3px 6px;
    font-weight: 500;
    border: 1px solid var(--ha-gray);
    text-transform: uppercase;
    margin-right: 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-decoration: none;
    outline:none;
  }
  .boton-editar {
    background: var(--fr-inprogress-tint);
    border-color: var(--ha-yellow);
    color: var(--ha-orange);
    &:hover {
      background: var(--ha-orange);
      border-color: var(--ha-orange);
      color: var(--fr-inprogress-tint);
    }
  }
  .boton-borrar {
    background: var(--fr-deleted-tint);
    border-color: var(--fr-deleted);
    color: var(--fr-deleted);
    &:hover {
      background: var(--ha-red);
      color: white;
    }
  }
}
</style>
