import Api from '../../services/Api';
import Helpers from '../../js/helpers';
import Product from '../../js/models/Product';

export default {
  namespaced: true,
  state: {
    products: [],
    currentProduct: {},
    productFamilies: [],
    productSubfamilies: [],
    toxicities: [],
    doseUnits: ['GR', 'KG', 'L', 'M3', 'PU', 'TON'],
    tipologies: [0, 1, 2, 3, 4],
  },
  actions: {
    async fetchProducts({ commit }) {
      try {
        const xhr = await Api.getProducts();
        const productAvailables = JSON.parse(xhr.response).data;
        commit('fetchProducts', productAvailables);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getProductById({ commit }, idProduct) {
      try {
        const xhr = await Api.getProductById(idProduct);
        const product = JSON.parse(xhr.response);
        commit('setCurrentProduct', product);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createProduct({ commit }, newProduct) {
      try {
        const xhr = await Api.createProduct(newProduct);
        const product = JSON.parse(xhr.response);
        commit('updateCurrentProductProperty', { property: 'id', newValue: product.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateProduct({ commit }, currentProduct) {
      try {
        const xhr = await Api.updateProduct(currentProduct);
        const product = JSON.parse(xhr.response);
        commit('updateCurrentProductProperty', { property: 'id', newValue: product.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteProduct(_, idProduct) {
      try {
        await Api.deleteProduct(idProduct);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchFamilyProducts({ commit }) {
      try {
        const xhr = await Api.fetchKeyValuesByFamily('PRODUCT_FAMILY');
        const productFamilies = JSON.parse(xhr.response)[0].values;
        commit('fetchFamilyProducts', productFamilies);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchSubfamilyProducts({ commit }, productFamily) {
      try {
        const xhr = await Api.fetchKeyValuesByFamily(`PRODUCT_SUBFAMILY_${productFamily}`);
        const productSubfamilies = JSON.parse(xhr.response)[0].values;
        commit('fetchSubfamilyProducts', productSubfamilies);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchToxicities({ commit }) {
      try {
        const xhr = await Api.fetchKeyValuesByFamily('TOXICITY');
        const toxicities = JSON.parse(xhr.response)[0].values;
        commit('fetchToxicities', toxicities);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    initStore({ commit }) {
      commit('initStore');
    },
    updateCurrentProductProperty({ commit }, { property, newValue }) {
      commit('updateCurrentProductProperty', { property, newValue });
    },
  },
  mutations: {
    fetchProducts(state, value) {
      state.products = value;
    },
    fetchFamilyProducts(state, value) {
      state.productFamilies = value;
    },
    fetchSubfamilyProducts(state, value) {
      state.productSubfamilies = value;
    },
    fetchToxicities(state, value) {
      state.toxicities = value;
    },
    setCurrentProduct(state, value) {
      state.currentProduct = value;
    },
    updateCurrentProductProperty(state, { property, newValue }) {
      if (property === 'properties') {
        for (const currentProperty of state.currentProduct.properties) {
          if (currentProperty.key === newValue.key) {
            currentProperty.value = newValue.value;
          }
        }
      } else {
        state.currentProduct[property] = newValue;
      }
    },
    initStore(state) {
      state.currentProduct = {};
      state.currentProduct.code = '';
      state.currentProduct.name = '';
      state.currentProduct.component = '';
      state.currentProduct.family = '';
      state.currentProduct.subfamily = '';
      state.currentProduct.dosis = { max_dosis: 0, unit: '' };
      state.currentProduct.tipology = '';
      state.currentProduct.ecologic = '';
      state.currentProduct.status = true;
      state.currentProduct.properties = [
        {
          key: 'toxicity',
          value: '',
        },
        {
          key: 'intervalIn',
          value: 0,
        },
        {
          key: 'SCS_authorized',
          value: 'false',
        },
        {
          key: 'EU_authorized',
          value: 'false',
        },
        {
          key: 'preharvest',
          value: 0,
        },
        {
          key: 'description',
          value: '',
        },
        {
          key: 'comment',
          value: '',
        },
      ];
      state.currentProduct.NT = 0;
      state.currentProduct.P2015_Total = 0;
      state.currentProduct.K2O = 0;
      state.currentProduct.CaO = 0;
      state.currentProduct.MgO = 0;
      state.currentProduct.SO3 = 0;
      state.currentProduct.B = 0;
      state.currentProduct.Cu = 0;
      state.currentProduct.Fe = 0;
      state.currentProduct.Mn = 0;
      state.currentProduct.Zn = 0;
      state.currentProduct.Se = 0;
      state.currentProduct.MO = 0;
      state.currentProduct.I = 0;
    },
  },
};
