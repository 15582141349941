var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_vm._v("\n    "+_vm._s(_vm.$t('Register_campaign'))+"\n  ")]),_vm._v(" "),_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('Campania_title')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxRegisterCampania_nombre'),"name":"name","caption":_vm.$t('DxRegisterCampania_nombre'),"editor-options":{
            value: _vm.currentCampaign.name,
            showClearButton: true,
            onValueChanged: _vm.setNameChanged,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_name_require')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"name":"init-date","data-field":_vm.$t('DxRegisterCampania_fecha_inicio'),"editor-type":"dxDateBox","editor-options":{
            value: _vm.currentCampaign.initDate,
            onValueChanged: _vm.onChangeInitDate,
            displayFormat: 'dd/MM/yyyy',
            invalidDateMessage: _vm.$t('dates_isInvalid'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_init_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('DxRegisterCampania_fecha_finalizacion'),"editor-type":"dxDateBox","editor-options":{
            value: _vm.currentCampaign.endDate,
            onValueChanged: _vm.onChangeEndDate,
            displayFormat: 'dd/MM/yyyy',
            invalidDateMessage: _vm.$t('dates_isInvalid'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1)],1),_vm._v(" "),_c('DxPopUpRegisterCamapnia')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }