<template>
  <f7-block>
    <DxForm>
      <DxGroupItem :col-count="3">
        <DxSimpleItem
          :col-span="1"
          :data-field="$t('layerOptions_select_index')"
          :caption="$t('layerOptions_select_index')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: indexAvailables,
            value: currentIndexes[`${keyId}`],
            placeholder: $t('layerOptions_index_availables'),
            onValueChanged: onSelectedIndex,
          }"
        >
          <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="init-date"
          :data-field="$t('DxRegisterCampania_fecha_inicio')"
          editor-type="dxDateBox"
          :editor-options="{
            value: actualInitDate,
            onValueChanged: onChangeInitDate,
            displayFormat: 'dd/MM/yyyy',
            placeholder: $t('registerClima_initDate_placeholder'),
            invalidDateMessage: $t('dates_isInvalid'),
          }"
        />
        <DxSimpleItem
          name="end-date"
          :data-field="$t('DxRegisterCampania_fecha_finalizacion')"
          editor-type="dxDateBox"
          :editor-options="{
            value: actualEndDate,
            onValueChanged: onChangeEndDate,
            displayFormat: 'dd/MM/yyyy',
            placeholder: $t('registerClima_endDate_placeholder'),
            invalidDateMessage: $t('dates_isInvalid'),
          }"
        />
      </DxGroupItem>
    </DxForm>
  </f7-block>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import EventBus from '../../../js/event-bus';

export default {
  name: 'LayerOptions',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    keyId: {
      type: String,
      default: 'layerOptions',
    },
    defaultIndex: {
      type: String,
      default: 'NDVI',
    },
  },
  data() {
    return {
      actualInitDate: '',
      actualEndDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapState('Gis', ['indexAvailables', 'currentIndexes', 'currentInitIntervalDates', 'currentEndIntervalDates', 'currentOverlayLayer', 'timelineIndexes']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    this.setCurrentIndexes({
      key: this.keyId,
      newCurrentIndex: this.defaultIndex,
    });
    this.actualInitDate = moment(this.currentPlantation.initDate);
  },
  methods: {
    onChangeInitDate(e) {
      let date;
      if (e.value === null) {
        date = '1970/01/01';
      } else {
        date = e.value;
      }
      const dateInit = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00');
      this.setCurrentInitIntervalDates({
        key: this.keyId,
        newCurrentInitIntervalDate: dateInit,
      });
      EventBus.$emit(`updateTimeDimension${this.keyId}`);
      EventBus.$emit('filteringTasks');
      this.callToFetchTimelineIndexes();
    },
    onChangeEndDate(e) {
      let date;
      if (e.value === null) {
        date = '3000/01/01';
      } else {
        date = e.value;
      }
      const dateEnd = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDT23:59:59');
      this.setCurrentEndIntervalDates({
        key: this.keyId,
        newCurrentEndIntervalDate: dateEnd,
      });
      EventBus.$emit(`updateTimeDimension${this.keyId}`);
      EventBus.$emit('filteringTasks');
      this.callToFetchTimelineIndexes(false);
    },
    onSelectedIndex(e) {
      this.setCurrentIndexes({
        key: this.keyId,
        newCurrentIndex: e.value,
      });
      EventBus.$emit(`indexLayer${this.keyId}`, e.value);
      this.callToFetchTimelineIndexes(true);
    },
    async callToFetchTimelineIndexes(indexChanged) {
      if (indexChanged) {
        const currentTimeLineIndexes = this.timelineIndexes;
        this.setTimelineIndexes(null);
        this.setTimelineIndexes(currentTimeLineIndexes);
        return;
      }

      try {
        this.setTimelineIndexes(null);
        await this.fetchTimelineIndexes({
          location: this.currentPlantation.sectorId,
          initDate: this.currentInitIntervalDates.GisTimeDimension !== '' ? moment(this.currentInitIntervalDates.GisTimeDimension).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate: this.currentEndIntervalDates.GisTimeDimension !== '' ? moment(this.currentEndIntervalDates.GisTimeDimension).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          companyId: this.currentPlantation.companyId,
        });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchTimelineIndexes', 'setTimelineIndexes']),
  },
};
</script>

<style lang="scss" scoped>
@import './LayerOptions.styles.scss';
</style>
