<template>
  <f7-page>
    <navbar :text="$t('DxTaskOrders_navbar_Name')" />
    <DxTaskOrders />
  </f7-page>
</template>

<script>

import DxTaskOrders from '../../components/tasks/DxTaskOrders.vue';
import navbar from '../../components/NavBar.vue';

export default {
  components: {
    DxTaskOrders,
    navbar,
  },
};
</script>

<style>

</style>