export default {
  namespaced: true,

  state: {
    itemUnitsWater: [
      {
        meq: 1, label: 'Ca2+', mg: 20.04, kg: 120.2, m3: 6000,
      },
      {
        meq: 1, label: 'Mg2+', mg: 12.16, kg: 73.0, m3: 6000,
      },
      {
        meq: 1, label: 'Na+', mg: 23.00, kg: 138.0, m3: 6000,
      },
      {
        meq: 1, label: 'K+', mg: 39.10, kg: 234.6, m3: 6000,
      },
      {
        meq: 1, label: 'H+', mg: 10.00, kg: 60.0, m3: 6000,
      },
      {
        meq: 1, label: 'Al3+', mg: 8.99, kg: 53.9, m3: 6000,
      },
      {
        meq: 1, label: 'Zn2+', mg: 32.69, kg: 196.1, m3: 6000,
      },
      {
        meq: 0.00556, label: 'NH4+', mg: 0.10, kg: 0.6, m3: 6000,
      },
      {
        meq: 0.348, label: 'NO3-', mg: 21.58, kg: 129.5, m3: 6000,
      },
      {
        meq: 1, label: 'PO43-', mg: 31.66, kg: 190.0, m3: 6000,
      },
      {
        meq: 1, label: 'HPO4=', mg: 47.99, kg: 287.9, m3: 6000,
      },
      {
        meq: 1, label: 'SO4=', mg: 48.50, kg: 291.0, m3: 6000,
      },
      {
        meq: 1, label: 'Fe++', mg: 27.93, kg: 167.6, m3: 6000,
      },
      {
        meq: 1, label: 'Mn++', mg: 27.47, kg: 164.8, m3: 6000,
      },
      {
        meq: 1, label: 'Cu++', mg: 31.77, kg: 190.6, m3: 6000,
      },
      {
        meq: 1, label: 'BO3---', mg: 19.60, kg: 117.6, m3: 6000,
      },
      {
        meq: 1, label: 'Cl-', mg: 35.46, kg: 212.8, m3: 6000,
      },
      {
        meq: 1, label: 'CO3=', mg: 30.00, kg: 180.0, m3: 6000,
      },
      {
        meq: 1, label: 'CO3H-', mg: 61.01, kg: 366.1, m3: 6000,
      },
      {
        meq: 1, label: 'B3+', mg: 3.50, kg: 21.0, m3: 6000,
      },
      {
        meq: 0.00165, label: 'H2PO4-', mg: 0.16, kg: 1.0, m3: 6000,
      },
    ],
    itemUnitsGround: [
      { meq: 1, label: 'Ca2+', ppm: 200.40 },
      { meq: 1, label: 'Mg2+', ppm: 121.60 },
      { meq: 1, label: 'Na+', ppm: 229.97 },
      { meq: 1, label: 'K+', ppm: 390.96 },
      { meq: 1, label: 'H+', ppm: 10.00 },
      { meq: 1, label: 'Al3+', ppm: 89.90 },
      { meq: 1, label: 'Zn2+', ppm: 326.85 },
      { meq: 1, label: 'NH4+', ppm: 180.42 },
      { meq: 1, label: 'NO3-', ppm: 620.10 },
      { meq: 1, label: 'PO43-', ppm: 316.57 },
      { meq: 1, label: 'SO4=', ppm: 480.30 },
      { meq: 1, label: 'Fe++', ppm: 279.25 },
      { meq: 1, label: 'Mn++', ppm: 274.65 },
      { meq: 1, label: 'Cu++', ppm: 317.70 },
      { meq: 1, label: 'BO3---', ppm: 588.10 },
      { meq: 1, label: 'Cl-', ppm: 354.60 },
      { meq: 1, label: 'CO32-', ppm: 300.00 },
      { meq: 1, label: 'CO3H-', ppm: 610.10 },
      { meq: 1, label: 'P', ppm: 34.50 },
    ],
    apparentDensity: 1.25,
    soilDepth: 0.2,
    itemUnitsNutritionalSolutions: [
      {
        mol: 1, label: 'NO3-', element: 14, oxide: 62,
      },
      {
        mol: 1, label: 'NH4+', element: 14, oxide: 18,
      },
      {
        mol: 1, label: 'H2PO4-', element: 30.97, oxide: 0,
      },
      {
        mol: 1, label: 'K+', element: 39.1, oxide: 47.1,
      },
      {
        mol: 1, label: 'Ca2+', element: 40.08, oxide: 56.082,
      },
      {
        mol: 1, label: 'Mg2+', element: 24.31, oxide: 40.3,
      },
      {
        mol: 1, label: 'SO42-', element: 32.06, oxide: 80.03,
      },
    ],
    itemUnitsMolecularWeight: [
      {
        eq: 1, label: 'NN por kg ', percent: 1.4, moles: 1,
      },
      {
        eq: 1, label: 'NA por kg', percent: 1.4, moles: 1,
      },
      {
        eq: 1, label: 'H2PO4-por kg', percent: 7.1, moles: 1,
      },
      {
        eq: 1, label: 'K por kg', percent: 4.71, moles: 1,
      },
      {
        eq: 1, label: 'Ca2+ por kg', percent: 2.8, moles: 1,
      },
      {
        eq: 1, label: 'Mg2+ por kg', percent: 2.015, moles: 1,
      },
      {
        eq: 1, label: 'SO42- por kg', percent: 4, moles: 1,
      },
    ],
  },

  getters: {
    itemUnitsWater: (state) => state.itemUnitsWater,
  },

  actions: {
    setItemUnitsWater(context, value) {
      context.commit('setItemUnitsWater', value);
    },
  },

  mutations: {
    setItemUnitsWater(state, itemUnitsWater) {
      state.itemUnitsWater = itemUnitsWater;
    },
  },
};
