<template>
  <div
    class="content-devx margin-top-half"
  >
    <DxDataGrid
      id="Plans-datagrid"
      :data-source="plans"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="plansDataGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="description"
        :caption="$t('Plans.planName')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="species"
        :caption="$t('Plans.crop')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('Plans.variety')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="province"
        :caption="$t('Plans.province')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="cost"
        :caption="$t('Plans.cost')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="period"
        :caption="$t('Plans.timeFrame')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="owner"
        :caption="$t('Plans.atcOwner')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        type="buttons"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewPlanDetail"
        />
      </DxColumn>
      <template #titlePlans>
        {{ $t('Plans.title') }}
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'Plans',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
  },
  props: {
    plans: {
      type: Array,
      default: () => [],
    },
    currentTypePlan: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    async viewPlanDetail(e) {
      this.setCurrentPlanId(e.row.data.id);
      this.setOldPlantation(e.row.data);
      this.setCurrentTypePlan(this.currentTypePlan);
      this.$f7.views.main.router.navigate('/viewPlanDetail/', { reloadCurrent: true });
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'titlePlans',
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Plans');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Plans.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Plans', ['setCurrentPlanId', 'setCurrentTypePlan']),
    ...mapActions('Plantation', ['setOldPlantation']),
  },
};
</script>
<style lang="scss" scoped>
@import './Plans.styles.scss';
</style>
