import {
  f7,
} from 'framework7-vue';
import moment from 'moment-timezone';
import Request from './Request';
import EventBus from '../js/event-bus';

let email = '';
let token = '';
let headerAuth = {};
let company = '';

const geoServerUrl = 'https://gis.pre.hispatecanalytics.com/';
const geoServerTimacagro = 'https://gis.pre.hispatecanalytics.com/geoserver/client_efemis/wms?';
const geoServerRasterDates = 'https://gis.pre.hispatecanalytics.com/gisw/ws/get_raster_dates/';
const geoServer = 'https://gis.pre.hispatecanalytics.com/gisw/ws';
const geoServerPort = 'https://gis.pre.hispatecanalytics.com/geoserver';
const geoServerSigpacMap = 'https://gis.pre.hispatecanalytics.com/gisw/ws/getmap/';
const geoServerFeatureInfo = 'https://gis.pre.hispatecanalytics.com/gisw/ws/getfeatureinfo/';
const geoServerTemperatureHistogram = 'https://efemis.development.hispatecanalytics.com/index';
const baseUrl = 'https://efemis.development.hispatecanalytics.com'; // Endpoint estable desarrollo
const geoServerWorkspace = 'client_efemis';
// const baseUrl = 'https://mobile.development.hispatecanalytics.com'; // Endpoint beta
// const baseUrl = 'https://api.pre.hcoagriplatform.com'; // Endpoint preproducción
// const baseUrl = 'https://api.hcoagriplatform.com'; // Endpoint producción
const azureAuthCallback = 'http://localhost/'; // Callback azure dev
// const azureAuthCallback = 'https://timac.pre.hispatecanalytics.com/'; // Callback azure pre
// const azureAuthCallback = 'https://timac.hispatecanalytics.com/'; // Callback azure pro

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  email = data.user.email;
  token = data.access;
  headerAuth = `Bearer ${token}`;
  company = data.user.contact.companies[0].id;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!email) return;
        email = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getAzureAuthCallback() {
    return azureAuthCallback;
  },
  getToken() {
    return token;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getBaseUrl() {
    return baseUrl;
  },
  getGeoServer() {
    return geoServer;
  },
  getGeoServerTimacagro() {
    return geoServerTimacagro;
  },
  getGeoServerRasterDates() {
    return geoServerRasterDates;
  },
  getGeoServerPort() {
    return geoServerPort;
  },
  getGeoServerSigpacMap() {
    return geoServerSigpacMap;
  },
  getGeoServerFeatureInfo() {
    return geoServerFeatureInfo;
  },
  getGeoServerWorkspace() {
    return geoServerWorkspace;
  },
  // Login
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        email: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  // Get authorize Azure
  AzureAuthorization() {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json');
  },
  AzureGetToken(code) {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: JSON.stringify({
        code,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (typeof actualFilter !== 'undefined' && actualFilter.init !== 'Invalid date' && actualFilter.end !== 'Invalid date') {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    if (init === '') {
      init = moment().subtract(7, 'days').format('YYYY-MM-DD');
      end = moment().format('YYYY-MM-DD');
    }
    return { init, end };
  },
  getCampaignsByCompany(idCompanyHeader) {
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  createCampaign(idCompanyHeader, newCampaignData) {
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  updateCampaign(idCompanyHeader, newCampaignData, campaignId) {
    const url = `${baseUrl}/campaign/${campaignId}`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getLocationByLevel(idCompanyHeader, level = '') {
    const url = `${baseUrl}/location?level=${level}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getTreeLocationByCompany(idCompanyHeader, plantationId = '', enabledCache = false) {
    let url = `${baseUrl}/tree/location`;
    const queryParams = new URLSearchParams();
    if (plantationId !== '') {
      queryParams.set('plantation', plantationId);
    }
    if (enabledCache) {
      queryParams.set('token', token);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getTreeLocation(plantationId = '', enabledCache = true) {
    let url = `${baseUrl}/tree/location`;
    const queryParams = new URLSearchParams();
    if (plantationId !== '') {
      queryParams.set('plantation', plantationId);
    }
    if (enabledCache) {
      queryParams.set('token', token);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createLocation(idCompanyHeader, newLocationData) {
    const url = `${baseUrl}/location`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  updateLocation(idCompanyHeader, newLocationData, sectorId) {
    const url = `${baseUrl}/location/${sectorId}`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompanyHeader);
  },
  fetchTreeLocationById(idCompanyHeader, locationId) {
    const url = `${baseUrl}/tree/location?location=${locationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getClient() {
    const url = `${baseUrl}/user`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getSales(idCompanyHeader) {
    const url = `${baseUrl}/sale`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  fetchProducts() {
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getProductById(idProduct) {
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createProduct(newProduct) {
    const url = `${baseUrl}/product`;
    const payload = {
      data: JSON.stringify(newProduct),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateProduct(product) {
    const url = `${baseUrl}/product/${product.id}`;
    const payload = {
      data: JSON.stringify(product),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  deleteProduct(idProduct) {
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  getListAnalysis(idCompanyHeader, plantationId) {
    let url = `${baseUrl}/laboratory_analysis`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  createNewAnalysis(dataAnalysis, idCompanyHeader) {
    const url = `${baseUrl}/laboratory_analysis`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getPhytosanitaryProducts(filterCrop, filterAgent) {
    let url = `${baseUrl}/vademecum`;
    const queryParams = new URLSearchParams();
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('cultive', filterCrop);
    }
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('agent', filterAgent);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getNutritionalProducts() {
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getOptionsCultiveFilter() {
    const url = `${baseUrl}/cultive/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getOptionsPlagueFilter(crop) {
    let url = `${baseUrl}/treatment/plague`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getOptionsPlagueFilterVademecum(crop) {
    let url = `${baseUrl}/plague/vademecum`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getOptionsCommercialNameFilter() {
    const url = `${baseUrl}/commercial_name/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getPhytosanitaryProductDetail(product) {
    let url = `${baseUrl}/vademecum/${product.id}`;
    const queryParams = new URLSearchParams();
    if (typeof product.agent !== 'undefined') {
      queryParams.set('agent', product.agent);
    }
    if (typeof product.crop !== 'undefined') {
      queryParams.set('cultive', product.crop);
    }
    url += `?${queryParams.toString()}`;

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getNutritionalProductDetail(idProduct) {
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload,
      'GET', false, 'application/json', headerAuth);
  },
  getPlantationByCampaign(idCompanyHeader, campaignId = '') {
    let url = `${baseUrl}/plantation`;
    if (campaignId !== '') {
      url += `?campaign=${campaignId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getAllCampaignsForUser(idCompanyHeader) {
    const url = `${baseUrl}/contact/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getlocationById(idCompanyHeader, Id) {
    const url = `${baseUrl}/location/${Id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getLocationByCampaign(idCompanyHeader, idCampaign) {
    const url = `${baseUrl}/location?campaign=${idCampaign}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getWeather(idCompanyHeader, locations, category, initDate, endDate, parameter) {
    const url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getWeatherByPlantation(idCompanyHeader, plantation, category, initDate, endDate, parameter) {
    const url = `${baseUrl}/climate/plantation/data?plantation=${plantation}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getWeatherForParameter(idCompanyHeader, locations, category, initDate, endDate, parameters = []) {
    let url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getWeatherForParameterByPlantation(companyId, plantationId, category, initDate, endDate, parameters = []) {
    let url = `${baseUrl}/climate/plantation/data?plantation=${plantationId}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  getVarieties() {
    const url = `${baseUrl}/variety`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createNewVariety(newVariety) {
    const url = `${baseUrl}/variety`;
    const payload = {
      data: JSON.stringify(newVariety),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getVarietyDetail(idVariety) {
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updateVariety(variety, idVariety) {
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: JSON.stringify(variety),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  deleteVariety(idVariety) {
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  getObservations(idCompanyHeader, currentPlantationId = '') {
    const url = `${baseUrl}/scouting?plantation=${currentPlantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  createNewObservation(idCompanyHeader, newObservation) {
    const url = `${baseUrl}/scouting`;
    const payload = {
      data: JSON.stringify(newObservation),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getObservationDetail(idCompanyHeader, idObservation) {
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  updateObservation(idCompanyHeader, idObservation, observation) {
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: JSON.stringify(observation),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompanyHeader);
  },
  deleteObservation(idCompanyHeader, idObservation) {
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, idCompanyHeader);
  },
  removeObservationFile(idCompanyHeader, idObservation, fileName) {
    const url = `${baseUrl}/scouting/${idObservation}/file/${fileName}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getTasks() {
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createNewTask(newTask) {
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  getTaskDetail(idTask) {
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updateTask(task, idTask) {
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: JSON.stringify(task),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  deleteTask(idTask) {
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  getDataMarketSlices() {
    const url = `${baseUrl}/datamarket/markets`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDataMarketFamilies(nameSlice) {
    const url = `${baseUrl}/datamarket/families?market=${nameSlice}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDataMarketProduct(nameSlice, nameFamily) {
    const url = `${baseUrl}/datamarket/products?market=${nameSlice}&family=${nameFamily}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDataMarketData(nameSlice, nameFamily, nameProduct, nameOrigin, initDate, endDate) {
    const url = `${baseUrl}/datamarket/data?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&origin=${nameOrigin}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getDataMarketOrigins(nameSlice, nameFamily, nameProduct, initDate, endDate) {
    const url = `${baseUrl}/datamarket/origins?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getAllProvince() {
    const url = `${baseUrl}/geonames/country/ES/province`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getAllCities(codeProvince) {
    const url = `${baseUrl}/geonames/country/ES/city?admin1_code=${codeProvince}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getContactPlantation(status) {
    const url = `${baseUrl}/contact/plantation?status=${status}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getContactPlantationByDate(status, initDate, endDate) {
    const url = `${baseUrl}/contact/plantation?status=${status}&init_date=${initDate}&end_date=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getIrrigation(idCompany, plantationId = '') {
    let url = `${baseUrl}/irrigation`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompany);
  },
  postIrrigation(dataIrrigation, idCompany) {
    const url = `${baseUrl}/irrigation`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompany);
  },
  putIrrigation(dataIrrigation, irrigationId, idCompany) {
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompany);
  },
  deleteIrrigation(irrigationId, companyId) {
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, companyId);
  },
  updateAnalysis(dataAnalysis, idCompanyHeader, idAnalysis) {
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompanyHeader);
  },
  deleteAnalysis(idCompanyHeader, idAnalysis) {
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, idCompanyHeader);
  },
  // GIS Methods
  geoserverGetBBOX(addedQuery) {
    const url = `${geoServer}/${addedQuery}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', '');
  },
  geoserverWMSLayerDataRequest(addedQuery) {
    const url = `${geoServerPort}/${addedQuery}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', '');
  },
  getCapabilities(workSpaces) {
    const url = `https://gis.pre.hispatecanalytics.com:8443/geoserver/${workSpaces}/wms?service=WMS&version=1.3.0&request=GetCapabilities&format=text/xml`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/xml', headerAuth);
  },
  getTasksForCompany(idCompany, plantationId = '') {
    let url = `${baseUrl}/task`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompany);
  },
  getTasksActivity() {
    const url = `${baseUrl}/task_activity`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getTasksDefinition(activiy) {
    const url = `${baseUrl}/task_definition?activity=${activiy}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  fetchTasksDefinitions() {
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getTaskDefinitionById(id) {
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  fetchActivities() {
    const url = `${baseUrl}/activity`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createTaskDefinition(newTaskDefinition) {
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: JSON.stringify(newTaskDefinition),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateTaskDefinition(taskDefinition) {
    const url = `${baseUrl}/task_definition/${taskDefinition.id}`;
    const payload = {
      data: JSON.stringify(taskDefinition),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  deleteTaskDefinition(id) {
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  getScoutingPoints(idCompanyHeader, idPlantation) {
    const url = `${baseUrl}/scouting_point?plantation=${idPlantation}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getCategoriesOfTemplates() {
    const url = `${baseUrl}/template_category`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTemplatesByCategory(category) {
    const url = `${baseUrl}/template_form?category=${category}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTemplateById(id) {
    const url = `${baseUrl}/template_form/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createNewTaskByActivity(newTask) {
    const url = `${baseUrl}/task`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  deleteTaskByCompany(idTask) {
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  updateTaskByCompany(updTask, idTask) {
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: JSON.stringify(updTask),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  getTaskByID(idTask) {
    const url = `${baseUrl}/daily_detail/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createPointObservation(idCompanyHeader, pointObservation) {
    const url = `${baseUrl}/scouting_point`;
    const payload = {
      data: JSON.stringify(pointObservation),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getGroupsAvailables() {
    const url = `${baseUrl}/variety_group`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getSpeciesAvailables(group) {
    const url = `${baseUrl}/variety_specie?group=${group}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getLaboratoryAnalysisById(idAnalysis, idCompanyHeader) {
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getScoutingPointById(companyIdHeader, id) {
    const url = `${baseUrl}/scouting_point/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyIdHeader);
  },
  getExplotation(idCompanyHeader) {
    const url = `${baseUrl}/explotation`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getKeyValues() {
    const url = `${baseUrl}/keyvalues`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getVarietyBySpecie(specie) {
    const url = `${baseUrl}/variety?specie=${specie}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createPlantation(idCompanyHeader, newPlantationData) {
    const url = `${baseUrl}/plantation`;
    const payload = {
      data: JSON.stringify(newPlantationData),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getParcelByExplotation(idCompanyHeader, id) {
    const url = `${baseUrl}/explotation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getSectorByParcel(idCompanyHeader, id) {
    const url = `${baseUrl}/parcel/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getPlantationById(idCompanyHeader, id) {
    const url = `${baseUrl}/plantation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getCampaignsById(idCompanyHeader, id) {
    const url = `${baseUrl}/campaign/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getTreatmentProducts(crop, agent, companyId) {
    const url = `${baseUrl}/treatment/product?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  getTreatmentProduct(crop, agent, id, companyId) {
    const url = `${baseUrl}/treatment/product/${id}?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  getTreatmentDataForAnalisis() {
    const url = `${baseUrl}/analysis/treatment`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getParcelById(idCompanyHeader, parcelId) {
    const url = `${baseUrl}/parcel/${parcelId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getSectorById(idCompanyHeader, sectorId) {
    const url = `${baseUrl}/sector/${sectorId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  fetchKeyValuesByFamily(family) {
    const url = `${baseUrl}/keyvalues?family=${family}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updatePlantation(idCompanyHeader, updateDataPLantation, plantationId) {
    const url = `${baseUrl}/plantation/${plantationId}`;
    const payload = {
      data: JSON.stringify(updateDataPLantation),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getContactLocations(latitude, longitude, radio = 0) {
    let url = `${baseUrl}/contact/location?latitude=${latitude}&longitude=${longitude}`;

    const queryParams = new URLSearchParams();
    if (radio > 0) {
      queryParams.set('radio', radio);
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getRegistersResume(companyId, plantationId) {
    const url = `${baseUrl}/register?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  fetchFertilizations() {
    const url = `${baseUrl}/utils/fertilization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  fetchTreatments() {
    const url = `${baseUrl}/utils/treatment`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getFertilizationById(fertilizationId) {
    const url = `${baseUrl}/utils/fertilization/${fertilizationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTreatmentById(treatmentId) {
    const url = `${baseUrl}/utils/treatment/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getTreatmentPlanByIdandCompanyId(companyId, treatmentId) {
    const url = `${baseUrl}/treatment_plan/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  fetchObservationsTemplates() {
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  deleteObservationTemplateById(observationTemplateId) {
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  createObservationTemplate(observationTemplate) {
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  updateObservationTemplate(observationTemplateId, observationTemplate) {
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  fetchFertilizationsForAnalysis() {
    const url = `${baseUrl}/analysis/fertilization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  fetchFertilizationByIdDetailsForAnalysis(fertId) {
    const url = `${baseUrl}/utils/fertilization/${fertId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  fetchIrrigationDataForAnalysis(plantationId) {
    const url = `${baseUrl}/analysis/irrigation?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getCompanyById(companyId) {
    const url = `${baseUrl}/company/${companyId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  fetchItemsSubfamilyByType(type) {
    const url = `${baseUrl}/items/getitemsubfamily?&type=${type}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getTasksDefinitionFerrero() {
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getAllCosts() {
    const url = `${baseUrl}/cost`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getCost(costId) {
    const url = `${baseUrl}/cost/${costId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createCost(newCost) {
    const url = `${baseUrl}/cost`;
    const payload = {
      data: JSON.stringify(newCost),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateCost(ID, newCost) {
    const url = `${baseUrl}/cost/${ID}`;
    const payload = {
      data: JSON.stringify(newCost),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  deleteCost(costId) {
    const url = `${baseUrl}/cost/${costId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  getSummaryTools() {
    const url = `${baseUrl}/tool`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  updateTool(toolId, newData) {
    const url = `${baseUrl}/tool/${toolId}`;
    const payload = {
      data: JSON.stringify(newData),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  createTool(newData) {
    const url = `${baseUrl}/tool`;
    const payload = {
      data: JSON.stringify(newData),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getItemsType() {
    const url = `${baseUrl}/keyvalues`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  editEmployee(newEmployee, employeeId) {
    const url = `${baseUrl}/employees/${employeeId}`;
    const payload = {
      data: JSON.stringify(newEmployee),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  createEmployee(newEmployee) {
    const url = `${baseUrl}/employees`;
    const payload = {
      data: JSON.stringify(newEmployee),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getEmployeesAllFullName(limit = 0, page = 0, fullname = '', status = 'all') {
    let url = `${baseUrl}/employees?limit=${limit}&offset=${page}`;
    if (fullname !== '') {
      url += `&fullname=${fullname}`;
    }
    if (status !== '') {
      url += `&status=${status}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  createMachinery(newMachinery) {
    const url = `${baseUrl}/machinery`;
    const payload = {
      data: JSON.stringify(newMachinery),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateMachinery(ID, newMachinery) {
    const url = `${baseUrl}/machinery/${ID}`;
    const payload = {
      data: JSON.stringify(newMachinery),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  getDataSubfamilyByType(typeData) {
    const url = `${baseUrl}/items/getitemsubfamily?&type=${typeData}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  fetchActivitiesAvailables() {
    const url = `${baseUrl}/activity`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getActivityById(activityID) {
    const url = `${baseUrl}/activity/${activityID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createActivity(newActivity) {
    const url = `${baseUrl}/activity`;
    const payload = {
      data: JSON.stringify(newActivity),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateActivity(activity) {
    const url = `${baseUrl}/activity/${activity.id}`;
    const payload = {
      data: JSON.stringify(activity),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  deleteActivity(activityID) {
    const url = `${baseUrl}/activity/${activityID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  getSupervisors(limit = 0, page = 0, enabledCache = true) {
    let url = `${baseUrl}/employees?role=supervisor&limit=${limit}&offset=${page}`;
    const payload = {
      data: null,
    };
    if (enabledCache) {
      url += `&token=${token}`;
    }
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company, enabledCache);
  },
  /**
   * Devuelve un listado de ordenes de trabajo
   *
   * @param {boolean} all Devuelve las planificadas y las no planificadas
   * @param {array} includeActivities Solo devuelve ordenes de trabajo que su actividad esté en este array
   * @param {array} excludeActivities No devuelve ordenes que su actividad esté en este array
   * @param {date} init Devuelve ordenes que hayan sido planificadas a partir de esta fecha (incluida la misma)
   * @param {date} end Devuelve ordenes que hayan sido planificadas hasta esta fecha (incluida la misma)
   * @param {boolean} unplanned Devuelve sólo las no planificadas
   * @returns Un objeto con la estructura para listados estandar que contiene ordenes de trabajo
   */
  fetchTasks(all = false, includeActivities = [], excludeActivities = [], init = '', end = '', unplanned = false) {
    let url = `${baseUrl}/daily_resume?all=${all}`;
    const queryParams = new URLSearchParams();
    for (const activities of excludeActivities) {
      url += `&exclude_activities=${activities}`;
    }
    for (const activities of includeActivities) {
      url += `&activities=${activities}`;
    }
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    if (unplanned) {
      queryParams.set('unplanned', 'true');
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getEmployeesAll(limit = 0, page = 0, status = 'all', enabledCache = true) {
    let url = `${baseUrl}/employees?limit=${limit}&offset=${page}`;
    if (status !== '') {
      url += `&status=${status}`;
    }
    const payload = {
      data: null,
    };
    if (enabledCache) {
      url += `&token=${token}`;
    }
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company, enabledCache);
  },
  getActivities(enabledCache = true) {
    let url = `${baseUrl}/activity`;
    const payload = {
      data: null,
    };
    if (enabledCache) {
      url += `?token=${token}`;
    }
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company, enabledCache);
  },
  sendPostWork(datos) {
    const url = `${baseUrl}/tasks/postwork`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getPostWork(init, end) {
    let url = `${baseUrl}tasks/postwork?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  // NURSERY
  getBareRoots() {
    const url = `${baseUrl}/nursery/stock`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  deleteBareRoot(ID) {
    const url = `${baseUrl}/nursery/stock/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  editBareRoot(ID, editedBareRootToSave) {
    const url = `${baseUrl}/nursery/stock/${ID}`;
    const payload = {
      data: JSON.stringify(editedBareRootToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  closeBatch(ID) {
    const url = `${baseUrl}/lote/${ID}/close`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getNurseryClassificationReport(init = '', end = '') {
    let url = `${baseUrl}/nursery/reports/classification`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getNurseryEgresosReport(init = '', end = '') {
    let url = `${baseUrl}/nursery/reports/egresos`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getNurseryStockReport(init = '', end = '') {
    let url = `${baseUrl}/nursery/reports/stock`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsCreateLote() {
    const url = `${baseUrl}/bins?create_lote`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsCreateLoteByIdLocation(ID) {
    const url = `${baseUrl}/bins?create_lote&farm=${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBatches() {
    const url = `${baseUrl}/lote`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsContentFamilies() {
    const url = `${baseUrl}/keyvalues?family=BINS_CONTENT_CATEGORY&family=BINS_CONTENT_VARIETY&family=BINS_CONTENT_CLASS`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getKeysToxicity() {
    const url = `${baseUrl}/keyvalues?family=TOXICITY`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  createBatch(newBatch) {
    const url = `${baseUrl}/lote`;
    const payload = {
      data: JSON.stringify(newBatch),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updateBatch(ID, batch) {
    const url = `${baseUrl}/lote/${ID}`;
    const payload = {
      data: JSON.stringify(batch),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  createBin(newBin) {
    const url = `${baseUrl}/bin`;
    const payload = {
      data: JSON.stringify(newBin),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  createBareRoot(newBareRoot) {
    const url = `${baseUrl}/nursery/stock`;
    const payload = {
      data: JSON.stringify(newBareRoot),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getBareRootsWithoutBinsByFarm(selectedFarm, idBin = '') {
    let url = `${baseUrl}/nursery/stock?farm=${selectedFarm}&bin=false`;
    if (idBin !== '') {
      url += `&editing=${idBin}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBins() {
    const url = `${baseUrl}/bins`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  changeBinState(ID, data) {
    const url = `${baseUrl}/bin/${ID}/status`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  deleteBin(ID) {
    const url = `${baseUrl}/bin/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  sendBin(ID, data) {
    const url = `${baseUrl}/bin/${ID}/shipment`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  sendBatch(ID, data) {
    const url = `${baseUrl}/lote/${ID}/shipment`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  editBin(ID, editedBinToSave) {
    const url = `${baseUrl}/bin/${ID}`;
    const payload = {
      data: JSON.stringify(editedBinToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  reclassifyBin(ID, reclassifiedBinToSave) {
    const url = `${baseUrl}/bin/${ID}/reclassification`;
    const payload = {
      data: JSON.stringify(reclassifiedBinToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  moveBinsContent(ID, binsContentMovesToSave) {
    const url = `${baseUrl}/bin/${ID}/movement`;
    const payload = {
      data: JSON.stringify(binsContentMovesToSave),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  deleteBatch(ID) {
    const url = `${baseUrl}/lote/${ID}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  binsToReclassify() {
    const url = `${baseUrl}/bins?status=REGISTERED&status=RECEIVED`;
    const payload = {
      // eslint-disable-next-line no-dupe-keys
      data: { limit: 10 },
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getFarmsToSend() {
    const url = `${baseUrl}/locations/farms`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBareRootCategories() {
    const url = `${baseUrl}/nursery/stock_category`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsEgress() {
    const url = `${baseUrl}/nursery/egreso`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsContentCategories() {
    const url = `${baseUrl}/nursery/category`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsContentClassifications() {
    const url = `${baseUrl}/nursery/classtype`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getBinsContentsForPlanning(locations) {
    const url = `${baseUrl}/bins/planning`;
    const payload = {
      data: JSON.stringify({ location: locations }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getBPASSummary(init, end) {
    let url = `${baseUrl}/report/bpas/summary?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getEffortByLocations(locations = [], init = '', end = '') {
    let url = `${baseUrl}/report/effort?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `init=${init}&`;
    url += `end=${end}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getEffortByGrouped(locations = [], init = '', end = '', grouped = '') {
    let url = `${baseUrl}/report/effort?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `init=${init}&`;
    url += `end=${end}`;
    if (grouped !== '') {
      url += `&grouped=${grouped}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getDailyTasks(all = false, includeActivities = [], excludeActivities = [], init = '', end = '', unplanned = false, warehouse = false, machinery = false) {
    if (init !== '') {
      all = false;
    }
    let url = `${baseUrl}/tasks/daily?all=${all}`;
    const queryParams = new URLSearchParams();
    for (const activities of excludeActivities) {
      url += `&exclude_activities=${activities}`;
    }
    for (const activities of includeActivities) {
      url += `&activities=${activities}`;
    }
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    if (unplanned) {
      queryParams.set('unplanned', 'true');
    }
    if (warehouse) {
      queryParams.set('warehouse', 'true');
    }
    if (machinery) {
      queryParams.set('machinery', 'true');
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getErrorLogs(init, end) {
    let url = `${baseUrl}/log?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getRealPerformance(init = '', end = '') {
    let url = `${baseUrl}/real_performance`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getClockInOut(init, end, enabledCache = true) {
    let url = `${baseUrl}/employees/list/access?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    if (enabledCache) {
      url += `&token=${token}`;
    }
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, enabledCache, company);
  },
  uploadFile(file, urlToSend) {
    const url = `${baseUrl}/${urlToSend}`;
    const payload = {
      data: JSON.stringify({ file }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getClimate(locations, category, init = '', end = '', parameters = [], enabledCache = false) {
    let url = `${baseUrl}/report/climate?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    url += `category=${category}&init=${init}&end=${end}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    if (enabledCache) {
      url += `&token=${token}`;
    }
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, enabledCache, company);
  },
  getWorkersSummary(init, end) {
    let url = `${baseUrl}/report/workers/summary?`;
    const queryParams = new URLSearchParams();

    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getReportHarvestByDays(init = '', end = '') {
    let url = `${baseUrl}/report/harvest/days`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getReportHarvest(init = '', end = '') {
    let url = `${baseUrl}/report/harvest`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getReportHarvestByLocations(locations = [], dateInit = '', dateEnd = '') {
    let url = `${baseUrl}/report/harvest/workers?`;
    for (let i = 0; i < locations.length; i += 1) {
      url += `locations=${locations[i]}&`;
    }
    const queryParams = new URLSearchParams();
    if (dateInit !== '') {
      queryParams.set('date_init', dateInit);
    }
    if (dateEnd !== '') {
      queryParams.set('date_end', dateEnd);
    }
    url += `${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getDailyRealPerformance(init = '', end = '') {
    let url = `${baseUrl}/real_performance`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getMachinerySummary(init, end) {
    let url = `${baseUrl}/report/machinery/summary?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  // Budget
  EditTaskMonthlyPlanning(newTaskMonthlyPlanning, badgetId, versionId, taskId) {
    const url = `${baseUrl}/badget/${badgetId}/version/${versionId}/task/${taskId}`;
    const payload = {
      data: JSON.stringify(newTaskMonthlyPlanning),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  addTaskMonthlyPlanning(newTaskMonthlyPlanning, badgetId, versionId) {
    const url = `${baseUrl}/badget/${badgetId}/version/${versionId}/task`;
    const payload = {
      data: JSON.stringify(newTaskMonthlyPlanning),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getMonthlyPlanning(badgetId, versionId, init = '', end = '') {
    let url = `${baseUrl}/badget/${badgetId}/version/${versionId}?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getMonthlyTaskPlanningId(badgetId, versionId, taskId) {
    const url = `${baseUrl}/badget/${badgetId}/version/${versionId}/task/${taskId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  // Monthly Plan
  getMonthlyTasks(init = '', end = '') {
    let url = `${baseUrl}/badget?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init_date', init);
    }
    if (end !== '') {
      queryParams.set('end_date', end);
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  closeBadget(id) {
    const url = `${baseUrl}/badget/${id}/closed`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  createAnnualBudget(newAnnualBudget) {
    const url = `${baseUrl}/badget`;
    const payload = {
      data: JSON.stringify(newAnnualBudget),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  cloneAnnualBudgetVersion(newAnnualBudgetVersion) {
    const url = `${baseUrl}/badget`;
    const payload = {
      data: JSON.stringify(newAnnualBudgetVersion),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getAllDataBudget(badgetId, versionId) {
    const url = `${baseUrl}/badget/${badgetId}/version/${versionId}/export`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getAllVariety() {
    const url = `${baseUrl}/variety`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getItems(type, family, subfamily = '', textsearch = '') {
    let url = `${baseUrl}/items?type=${type}&family=${family}`;
    if (subfamily !== '') {
      url += `&subfamily=${subfamily}`;
    }
    if (textsearch !== '') {
      url += `&textsearch=${textsearch}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getDailyPlan(init, end, status = '') {
    let url = `${baseUrl}/tasks/planning?`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('date_init', init);
    }
    if (end !== '') {
      queryParams.set('date_end', end);
    }
    if (status !== '') {
      queryParams.set('status', status);
    }
    url += queryParams.toString();

    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  deletePlanning(planningId) {
    const url = `${baseUrl}/tasks/planning/${planningId}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
  createPlanning(newPlanning) {
    const url = `${baseUrl}/tasks/planning`;
    const payload = {
      data: JSON.stringify({
        task_code: newPlanning.task_code,
        activity_code: newPlanning.activity_code,
        date: newPlanning.date,
        shift: newPlanning.shift,
        group: newPlanning.group,
        grouped: newPlanning.grouped,
        workers: newPlanning.workers,
        items: newPlanning.items,
        locations: newPlanning.locations,
        attachments: newPlanning.attachments,
        tools: newPlanning.tools,
        products: newPlanning.products,
        prescription: newPlanning.prescription,
        area_effective: newPlanning.area_effective,
        distributed: newPlanning.distributed,
        goal_performance: newPlanning.goal_performance,
        variety: newPlanning.variety,
        nursery: newPlanning.nursery,
        locations_tree: newPlanning.locations_tree,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  updatePlanning(ID, newPlanning) {
    const url = `${baseUrl}/tasks/planning/${ID}`;
    const payload = {
      data: JSON.stringify({
        task_code: newPlanning.task_code,
        activity_code: newPlanning.activity_code,
        date: newPlanning.date,
        shift: newPlanning.shift,
        group: newPlanning.group,
        grouped: newPlanning.grouped,
        workers: newPlanning.workers,
        items: newPlanning.items,
        locations: newPlanning.locations,
        attachments: newPlanning.attachments,
        tools: newPlanning.tools,
        products: newPlanning.products,
        prescription: newPlanning.prescription,
        area_effective: newPlanning.area_effective,
        distributed: newPlanning.distributed,
        goal_performance: newPlanning.goal_performance,
        variety: newPlanning.variety,
        nursery: newPlanning.nursery,
        locations_tree: newPlanning.locations_tree,
      }),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  createOrders(newOrders) {
    const url = `${baseUrl}/tasks/planning/${newOrders.planner}/generate`;
    const payload = {
      data: JSON.stringify({
        tasks: newOrders.tasks,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getPrescriptions(family = '', enabledCache = false) {
    let url = `${baseUrl}/prescription?`;
    const queryParams = new URLSearchParams();
    if (family !== '') {
      queryParams.set('family', family);
    }
    if (enabledCache) {
      queryParams.set('token', token);
    }
    url += queryParams.toString();
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  updateOrder(orderId, updateOrder) {
    const url = `${baseUrl}/tasks/${orderId}`;
    const payload = {
      data: JSON.stringify(updateOrder),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth, company);
  },
  getDataWorkOrder(id) {
    const url = `${baseUrl}/tasks/daily/fast/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  getParcelSystemTreeLocation(idCompanyHeader, campaignId = '') {
    let url = `${baseUrl}/tree/plantation`;
    const queryParams = new URLSearchParams();
    if (campaignId !== '') {
      queryParams.set('campaign', campaignId);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  getGeoServerTemperatureHistogram(location, date, idCompanyHeader) {
    const url = `${geoServerTemperatureHistogram}?location=${location}&date=${date}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  fetchTimelineIndexes(location, initDate, endDate, companyId) {
    const url = `${baseUrl}/index/without_histogram?location=${location}&init_date=${initDate}&end_date=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, companyId);
  },
  async getAllRecordsToSync(endpoint) {
    const url = `${baseUrl}/${endpoint}`;
    const payload = {
      data: null,
    };
    return this.request(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    let responseServer = null;
    const xhr = await Request.async(url, payload, verb, processData, contentType, headerAuth, company);
    try {
      responseServer = JSON.parse(xhr.response);
      responseServer = (responseServer.data) ? responseServer.data : responseServer;
    } catch (error) {
      responseServer = xhr.status;
    }
    return responseServer;
  },
  sendPrescriptions(datos) {
    const url = `${baseUrl}/prescription`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  getVademecum() {
    const url = `${baseUrl}/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
  },
  updatePrescriptions(id, datos) {
    const url = `${baseUrl}/prescription/${id}`;
    const payload = {
      data: JSON.stringify(datos),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth, company);
  },
  daletePrescriptions(id) {
    const url = `${baseUrl}/prescription/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth, company);
  },
};
