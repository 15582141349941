<template>
  <f7-block>
    <f7-row no-gap>
      <f7-col width="50">
        <WeatherForecast />
      </f7-col>
      <f7-col width="50">
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row
      no-gap
      class="margin-top-half"
    >
      <f7-col>
        <div v-if="!loadedRegistersResume">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <RegistersResume
            :registers="registersResume"
          />
        </div>
      </f7-col>
      <f7-col>
        <NutritionPlanList called-from-path="home" />
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WeatherForecast from './weather/forecastWeather/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import NutritionPlanList from '../../wizardNutrition/nutritionPlanList/index.vue';
import RegistersResume from './registersResume/index.vue';

export default {
  name: 'Home',
  components: {
    WeatherForecast,
    GISGeoJsonMap,
    NutritionPlanList,
    RegistersResume,
  },
  data() {
    return {
      loadedGIS: false,
      loadedRegistersResume: false,
      zoom: 17,
      mainMap: 'Home',
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Registers', ['registersResume']),
  },
  async beforeMount() {
    try {
      await this.fetchLocationsByPlantation();
      await this.getCompanyById(this.currentPlantation.companyId);
      this.loadedGIS = true;
      await this.fetchRegistersResume({
        companyId: this.currentPlantation.companyId,
        plantationId: this.currentPlantation.id,
      });
      this.loadedRegistersResume = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Registers', ['fetchRegistersResume']),
    ...mapActions('Explotaciones', ['getCompanyById']),
  },
};
</script>

<style lang="scss" scoped>
@import './Home.styles.scss';
</style>
