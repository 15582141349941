import Request from './Request';
import Api from './Api';

const productFertilizerService = {

  async getAll(companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/product`,
      { data: {} }, 'GET',
      false, 'application/json', Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getById(id) {
    const xhr = await Api.getProductById(id);

    return JSON.parse(xhr.response);
  },
};

export default productFertilizerService;
