<template>
  <DxForm
    v-if="data.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <DxSimpleItem
        v-for="(item, index) in data"
        :key="index"
        :data-field="item.type"
        :name="item.type"
        :editor-options="{ value: item.count, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_items"
  >
    {{ $t('DetailTabWorkers_sin_items') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    data: { type: Array, default: () => [] },
    /* itemsSelected: { type: Array, default: () => [] },
    itemsAvailables: { type: Array, default: () => [] }, */
  },
  data() {
    return {
      //itemsFormated: [],
    };
  },
  /*  beforeMount() {
    Algo ha cambiado, lo comento para el futuro si hiciese falta
    for (let i = 0; i < this.itemsSelected.length; i += 1) {
      for (let j = 0; j < this.itemsAvailables.length; j += 1) {
        if (this.itemsSelected[i] === this.itemsAvailables[j].id) {
          this.itemsFormated.push(this.formatingItems(this.itemsAvailables[j]));
        }
      }
    }
  },
  methods: {
    formatingItems(itemRaw) {
      const { item } = itemRaw;
      let itemName = `${item.description}`;
      for (let j = 0; j < item.properties.length; j += 1) {
        itemName += ` ${item.properties[j].value}`;
      }
      itemRaw.nameFormated = itemName;
      return itemRaw;
    },
  }, */

};
</script>
