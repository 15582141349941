<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('DxListTaskGroupedActivivty_NavBarName')}`" />
    <DxListTaskAgrupedActivity />
  </f7-page>
</template>

<script>

import navbar from '../../../components/NavBar.vue';
import DxListTaskAgrupedActivity from '../../../components/configuration/activityTasks/DxListTaskAgrupedActivity.vue';

export default {
  components: {
    navbar,
    DxListTaskAgrupedActivity,
  },
};
</script>

<style>

</style>
