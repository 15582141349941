<template>
  <f7-page
    @page:beforein="beforein"
  >
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.campaign} > ${currentPlantation.name} > ${$t('Searcher.observations.observations_title')}`" />
    <f7-row>
      <f7-col>
        <f7-row>
          <f7-col>
            <ViewMainData
              :company-id="currentPlantation.companyId"
              :scouting-points="scoutingPoints"
              :current-values-main-data="currentValuesMainData"
              :read-only="true"
              :id-current-observation="idCurrentObservation"
            />
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <MultimediaData
              :multimedia="multimedia"
              :read-only="true"
            />
          </f7-col>
        </f7-row>
      </f7-col>
      <f7-col>
        <f7-block v-if="!loadedGIS || !showGIS">
          <div class="loadingFalse">
            <div
              v-if="!loadedGIS"
              class="preloader"
            />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISPointsMap
            v-if="currentScoutingPoint.type === 'POINT'"
            :zoom="zoom"
            :name-map="mainMap1"
            :tree-locations="treeLocations"
            :current-point="{latitude: currentScoutingPoint.latitude, longitude: currentScoutingPoint.longitude}"
            :only-read="true"
          />
          <GISGeoJsonMap
            v-else
            :zoom="zoom"
            :name-map="mainMap2"
            :tree-locations="treeLocations"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <TemplateData
          v-if="showTemplateForm && loaded"
          :template-form="templateForm"
          :read-only="true"
        />
      </f7-col>
    </f7-row>
    <f7-block
      class="footer"
    >
      <f7-row>
        <f7-col width="10">
          <f7-button
            class="dx-btn-back margin-left"
            raised
            type="default"
            styling-mode="contained"
            name="ButtonBack"
            @click="goToObservations"
          >
            {{ $t('Go_Back') }}
          </f7-button>
        </f7-col>
        <f7-col width="90" />
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ViewMainData from '../../../components/searcher/observations/observationForm/viewMainData/index.vue';
import MultimediaData from '../../../components/searcher/observations/observationForm/multimediaData/index.vue';
import TemplateData from '../../../components/searcher/observations/observationForm/templateData/index.vue';
import GISPointsMap from '../../../components/Gis/GISPointsMap/index.vue';
import GISGeoJsonMap from '../../../components/Gis/GISGeoJsonMap/index.vue';

export default {
  name: 'ViewObservation',
  components: {
    navbar,
    ViewMainData,
    MultimediaData,
    TemplateData,
    GISPointsMap,
    GISGeoJsonMap,
  },
  data() {
    return {
      zoom: 20,
      mainMap1: 'viewObservationPoint',
      mainMap2: 'viewObservationParcel',
      loadedGIS: false,
      loaded: false,
    };
  },
  computed: {
    showGIS() {
      return this.currentScoutingPoint !== null;
    },
    ...mapState('SearcherObservations', ['currentScoutingPoint', 'templateForm', 'showTemplateForm', 'scoutingPoints', 'idCurrentObservation', 'multimedia']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapGetters('SearcherObservations', ['currentValuesMainData']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        await this.getObservationDetail({ currentPlantation: this.currentPlantation, idCurrentObservation: this.idCurrentObservation });
        await this.getScoutingPoints(this.currentPlantation);
        this.formattingTemplate(this.templateForm);
        this.resetCurrentPoint();
        await this.fetchLocationsByPlantation();
        this.loadedGIS = true;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    goToObservations() {
      this.$f7.views.main.router.navigate('/searcher/observations/', { reloadCurrent: true });
    },
    ...mapActions('SearcherObservations', ['getScoutingPoints', 'getCategoriesOfTemplates', 'updateObservation', 'getObservationDetail', 'formattingTemplate']),
    ...mapActions('Gis', ['resetCurrentPoint']),
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
  },
};
</script>

<style scoped>
.dx-btn-save{
    background: var(--f7-theme-color);
    color: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-save:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .dx-btn-back{
    background: #FFFFFF;
    color: #74838C;
    border: 1px solid #74838C;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-back:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .footer{
      margin-top: 10px;
  }
</style>
