<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-worker main-dx-bpas"
  >
    <CalendarInitEndDate class="margin-top" :key-name="keyName" />
    <DxDataGrid
      id="worker-grid"
      :data-source="BPASFormated"
      :remote-operations="true"
      :column-auto-width="true"
      :allow-column-reordering="false"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxBpas"
      />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="shortId"
        :caption="$t('DxBpas_short_id')"
        :allow-header-filtering="false"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="date"
        :caption="$t('DxDxBpas_Date_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="worker"
        :caption="$t('DxDxBpas_Worker_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="activityCode"
        :caption="$t('DxDxBpas_activityCode_caption')"
        :allow-header-filtering="false"
        alignment="right"
        width="100"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('DxDxBpas_activity_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="taskCode"
        :caption="$t('DxDxBpas_CodeTask_caption')"
        :allow-header-filtering="false"
        alignment="right"
        width="80"
      />
      <DxColumn
        data-field="task"
        :caption="$t('DxDxBpas_Task_caption')"
        :allow-header-filtering="true"
        alignment="left"
        css-class="bold"
      />
      <DxColumn
        data-field="farms"
        :caption="$t('DxDxBpas_Farm_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="locations"
        :caption="$t('DxDxBpas_Location_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="workingArea"
        :caption="$t('DxDxBpas_Working_Area_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="solution_total"
        :caption="$t('DxDxBpas_SolutionTotal_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="solution_unit"
        :caption="$t('DxDxBpas_SolutionUnit_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="prescription"
        :caption="$t('DxDxBpas_Formulas_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="productName"
        :caption="$t('DxDxBpas_Name_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="activeIngredients"
        :caption="$t('DxDxBpas_ActiveIngredients_caption')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="dosis_planned"
        :caption="`${$t('DxDxBpas_dosisUt_caption')} (grs/cc)/100`"
        :allow-header-filtering="false"
        alignment="right"
        width="200"
      />
      <DxColumn
        data-field="totalRealPrescription"
        :caption="$t('DxDxBpas_totalRealPrescription_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="qty_used"
        :caption="$t('DxDxBpas_QtyUsed_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="qtyUsedUnit"
        :caption="$t('DxDxBpas_QtyUsedUnit_caption')"
        :allow-header-filtering="false"
        alignment="right"
      />
      <DxColumn
        data-field="machinery"
        :caption="$t('DxDxBpas_Machinary_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="attachment"
        :caption="$t('DxDxBpas_attachment_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="internalCode"
        :caption="$t('DxBpas_Machinary_InternalCodecaption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="machineryFamily"
        :caption="$t('DxDxBpas_machineryfamily_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="attachmentFamily"
        :caption="$t('DxDxBpas_attachmentFamily_caption')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="status"
        name="status"
        :caption="$t('DxDailyPlanning_task_status_caption')"
        alignment="center"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="taskStatusTemplate"
      />
      <DxColumn
        data-field="worder_status"
        name="worder_status"
        width="160"
        alignment="center"
        :caption="$t('DxDailyPlanning_work_order_status_caption')"
        :allow-header-filtering="true"
        :header-filter="{ dataSource: dataWordTaskStatus }"
        cell-template="workOrderStatusTemplate"
      />
      <template #taskStatusTemplate="{ data: BPASFormated }">
        <div :class="BPASFormated.data.taskStatusTemplateClass">
          {{ BPASFormated.data.taskStatusTranslated }}
        </div>
      </template>
      <template #workOrderStatusTemplate="{ data: BPASFormated }">
        <div :class="BPASFormated.data.workOrderStatusTemplateClass">
          {{ BPASFormated.data.workOrderStatusTranslated }}
        </div>
      </template>
      <DxScrolling column-rendering-mode="virtual" />
    </DxDataGrid>
  </div>
</template>

<script>

import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxBpas',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      BPASFormated: [],
      machineryFormated: [],
      keyName: 'reportingBpas',
      pageSizes: [5, 10, 20],
      dataTaskStatus: [{
        text: this.$t('Status_Open'),
        value: 'OPEN',
      }, {
        text: this.$t('Status_Completed'),
        value: 'COMPLETED',
      },
      {
        text: this.$t('Status_InProgress'),
        value: 'IN_PROGRESS',
      }],
      dataWordTaskStatus: [{
        text: this.$t('Status_reallyNot_Closed'),
        value: 'NOT_COMPLETED',
      }, {
        text: this.$t('Status_Closed'),
        value: 'COMPLETED',
      }, {
        text: this.$t('Draft'),
        value: 'DRAFT',
      }],
      workOrderStatusTranslated: '',
      taskStatusTranslated: '',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getLocationNameLevelTwoByChildId']),
    ...mapState('greenhouse', ['locationTree']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchLocationTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getBPASSummary(checkedInitEndDate.init, checkedInitEndDate.end);
      const reportingBPAS = JSON.parse(xhr.response).data;
      for (let i = 0; i < reportingBPAS.length; i += 1) {
        const { date } = reportingBPAS[i];
        if (moment(new Date()).isSameOrAfter(moment(date))) {
          const shortId = reportingBPAS[i].short_id;
          const { task } = reportingBPAS[i];
          const taskCode = reportingBPAS[i].task_code;
          const { activity } = reportingBPAS[i];
          const activityCode = reportingBPAS[i].task_code.substr(0, 2);
          const taskType = reportingBPAS[i].task_type;
          const farms = this.getLevel0NamesByBdIds(reportingBPAS[i].locations);
          const locations = this.getNamesByBdIds(reportingBPAS[i].locations);
          const workingArea = reportingBPAS[i].area_effective;
          const { comment } = reportingBPAS[i];
          const { prescription } = reportingBPAS[i];
          const { worder_status } = reportingBPAS[i];
          const { status } = reportingBPAS[i];
          const taskStatusTemplateClass = this.setClassByTaskStatus(reportingBPAS[i].status);
          const workOrderStatusTemplateClass = this.setClassByWorkOrderStatus(reportingBPAS[i].worder_status);
          let internalCode = '';
          let machinery = '';
          let attachment = '';
          let qtyUsedUnit = '';
          for (const product of reportingBPAS[i].products) {
            internalCode = product.machinery_code;
            machinery = product.machinery;
            attachment = product.attachment;
            qtyUsedUnit = '';
            if (product.dosisUt === 'Gr/Hl' || product.dosisUt === 'Kg/Ha') {
              qtyUsedUnit = 'Kg';
            }
            if (product.dosisUt === 'Cc/Hl' || product.dosisUt === 'L/Ha') {
              qtyUsedUnit = 'L';
            }
            const dosisTotal = ((parseFloat(product.qty_used) * 1000) / 100);
            const solutionTotal = (parseFloat(product.total_real_prescription) / workingArea);
            this.BPASFormated.push({
              shortId,
              activity,
              activityCode,
              productName: product.name,
              activeIngredients: product.activeIngredients,
              variety: product.variety,
              prescription,
              dosisTotal: `${dosisTotal.toFixed(2)}`,
              solution_total: `${parseFloat(solutionTotal).toFixed(2)}`,
              solution_unit: product.solutionUt,
              qty_used: `${parseFloat(product.qty_used).toFixed(2)}`,
              dosis_planned: `${parseFloat(product.planned.dosis).toFixed(2)}`,
              qtyUsedUnit,
              machinery,
              attachment,
              worker: product.worker,
              totalRealPrescription: product.total_real_prescription,
              date,
              task,
              taskCode,
              taskType,
              farms,
              locations,
              workingArea,
              comment,
              internalCode,
              machineryFamily: product.machinery_family,
              attachmentFamily: product.attachment_family,
              worder_status,
              status,
              taskStatusTemplateClass,
              workOrderStatusTemplateClass,
              workOrderStatusTranslated: this.workOrderStatusTranslated,
              taskStatusTranslated: this.taskStatusTranslated,
            });
          }
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('greenhouse', ['fetchLocationTree']),
    getTotalWorkingHours(init, end) {
      if (end === null) {
        const ms = moment().diff(moment(init));
        const d = moment.duration(ms);
        return `${d.hours()}`;
      }
      const ms = moment(end).diff(moment(init));
      const d = moment.duration(ms);
      return `${d.hours()}`;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('reportingBPAS');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportingBPAS.xlsx');
          });
      });
      e.cancel = true;
    },
    getNamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        const newName = this.getLocationNameLevelTwoByChildId(id);
        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names.toString();
    },
    getLevel0NamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        const newName = this.$helpers.getLocationLevel0NameById(id, this.locationTree);

        if (names.indexOf(newName) === -1) {
          names.push(newName);
        }
      }
      return names.toString();
    },
    setClassByTaskStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'OPEN':
          colorCell = 'open';
          this.taskStatusTranslated = this.$t('Status_Open');
          break;
        case 'IN_PROGRESS':
          colorCell = 'in-progress';
          this.taskStatusTranslated = this.$t('Status_InProgress');
          break;
        case 'COMPLETED':
          colorCell = 'completed';
          this.taskStatusTranslated = this.$t('Status_Completed');
          break;
        case 'PENDING':
          colorCell = 'pending';
          this.taskStatusTranslated = this.$t('Status_Pending');
          break;
        case 'DELETED':
          colorCell = 'deleted';
          this.taskStatusTranslated = this.$t('Status_Deleted');
          break;
        default:
          break;
      }
      return colorCell;
    },
    setClassByWorkOrderStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'COMPLETED':
          colorCell = 'work-order-closed';
          this.workOrderStatusTranslated = this.$t('Closed');
          break;
        case 'DRAFT':
          colorCell = 'work-order-draft';
          this.workOrderStatusTranslated = this.$t('Draft');
          break;
        case null:
        default:
          colorCell = 'work-order-not-closed';
          this.workOrderStatusTranslated = this.$t('Status_reallyNot_Closed');
          break;
      }
      return colorCell;
    },
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.open, .in-progress, .completed, .pending, .deleted, .rejected, .work-order-closed, .work-order-not-closed, .work-order-draft {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.open {
  color: var(--fr-open);
  background: var( --f7-checkbox-inactive-color);
}
.in-progress {
  background: var(--ha-yellow);
  color: var(--fr-inprogress-tint);
}
.completed {
  background: var(--fr-finished);
  color: var(--fr-finished-tint);
}
.pending {
  color: var(--fr-pending);
  background: var(--fr-pending-tint);
}
.deleted {
  color: var(--fr-deleted);;
  background: var(--fr-deleted-tint);
}
.work-order-closed{
  color: var(--ha-dark-green);
  background: var(--ha-light-green);
}
.work-order-not-closed{
  color: #ffffff;
  background: var(--fr-nutella);
}
.work-order-draft {
  color: #fff;
  background: var(--fr-inprogress);
}
</style>
