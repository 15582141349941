import navbar from '@/components/NavBar.vue';
import NutritionPlanList from '@/components/wizardNutrition/nutritionPlanList/index.vue';

export default {
  name: 'nutrition-panel',
  components: { navbar, NutritionPlanList },
  props: [],
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
