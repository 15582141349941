<template>
  <div
    class="content-devx"
  >
    <f7-block class="margin-bottom-title">
      <f7-row>
        <f7-col width="20">
          {{ $t('Uses_Doses_Detail_Title') }}
        </f7-col>
        <f7-col width="80" />
      </f7-row>
    </f7-block>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-row class="margin-bottom">
            <f7-col>
              {{ $t('Crop') }}
            </f7-col>
            <f7-col>
              {{ $t('Filter_Agent') }}
            </f7-col>
            <f7-col />
          </f7-row>
          <f7-row class="bold margin-bottom">
            <f7-col>
              {{ recommendations.crop }}
            </f7-col>
            <f7-col>
              {{ recommendations.agent }}
            </f7-col>
            <f7-col />
          </f7-row>
          <f7-row class="margin-bottom">
            <f7-col>
              {{ $t('Application_Num') }}
            </f7-col>
            <f7-col>
              {{ `${$t('Dose')} (%)` }}
            </f7-col>
            <f7-col>
              {{ `${$t('Liquid_Vol')} (L/ha)` }}
            </f7-col>
          </f7-row>
          <f7-row class="bold">
            <f7-col>
              {{ recommendations.applicationNum }}
            </f7-col>
            <f7-col>
              {{ recommendations.dose }}
            </f7-col>
            <f7-col>
              {{ recommendations.liquidVol }}
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row class="margin-bottom">
            <f7-col>
              {{ $t('Application_Info') }}
            </f7-col>
          </f7-row>
          <f7-row class="bold">
            <f7-col>
              {{ recommendations.applicationInfo }}
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'UsesDosesAuthPlague',
  props: {
    usesDosesAuth: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      recommendations: {},
    };
  },
  beforeMount() {
    if (this.usesDosesAuth.recommendations.length === 0) {
      this.recommendations = {
        crop: '',
        agent: '',
        applicationNum: '',
        dose: '',
        liquidVol: '',
        applicationInfo: '',
      };
    } else {
      this.recommendations = {
        crop: this.usesDosesAuth.recommendations[0].cultive,
        agent: this.usesDosesAuth.recommendations[0].agent,
        applicationNum: this.usesDosesAuth.recommendations[0].application_num !== null ? parseFloat(this.usesDosesAuth.recommendations[0].application_num.max) : '',
        dose: this.usesDosesAuth.recommendations[0].dose !== null ? `${parseFloat(this.usesDosesAuth.recommendations[0].dose.min)}-${parseFloat(this.usesDosesAuth.recommendations[0].dose.max)}` : '',
        liquidVol: this.usesDosesAuth.recommendations[0].liquid_vol !== null ? `${parseFloat(this.usesDosesAuth.recommendations[0].liquid_vol.min)}-${parseFloat(this.usesDosesAuth.recommendations[0].liquid_vol.max)}` : '',
        applicationInfo: this.usesDosesAuth.recommendations[0].application_info,
      };
    }
  },
};
</script>
<style lang="scss" scoped>
@import './UsesDosesAuthPlague.styles.scss';
</style>
