import { mapState, mapActions } from 'vuex';
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import moment from 'moment';

export default {
  name: 'stepper',
  components: {
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
  },
  props: [],
  data() {
    return {
      title: this.$t('Register_fertilization_plan'),
      stepSelected: {},
      temporalFrameId: 0,
      temporalFrameItems: [
        { id: 0, label: this.$t('Utilities.stepperForm.monthly'), value: 'MENSUAL' },
        { id: 1, label: this.$t('Utilities.stepperForm.weekly'), value: 'SEMANAL' },
      ],
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['nutritionSteps', 'stepData', 'currentStep', 'stepIndex', 'enableWatchers']),
  },
  mounted() {
    this.updateClasses();

    if (!this.stepData.temporalFrame) {
      this.setStepDataTemporalFrame(this.temporalFrameItems[0]);
    } else {
      this.temporalFrameId = this.stepData.temporalFrame.id;
      // Se vuelve a asignar el marco temporal para añadir la traducción correcta.
      this.setStepDataTemporalFrame(this.temporalFrameItems[this.temporalFrameId]);
    }
    if (!this.stepData.initDate) {
      this.setStepDataInitDate(moment());
      this.setStepDataEndDate(moment().add(3, 'M'));
    }
  },
  methods: {
    formatInitDate() {
      return moment(this.stepData.initDate).format('MM/yyyy');
    },
    formatEndDate() {
      return moment(this.stepData.endDate).format('MM/yyyy');
    },
    setCurrentStep(index) {
      this.setStep(index);
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    initData() {
      if (!this.enableWatchers) {
        return;
      }
      this.stepData.nutritionalBalanceData = [];
      this.stepData.timeValues = [];
      this.stepData.dosages = [];
      this.stepData.dosageProduct = [];
      this.stepData.dosageProductBalance = [];
      this.stepData.productResume = [];
    },
    getCompleteClassStatus(step) {
      if (!step || !step.statusClass) {
        return 'no-pointer-event';
      }
      return !step.statusClass.includes('complete') ? 'no-pointer-event' : '';
    },
    updateTemporalFrame({ value }) {
      this.initData();
      this.setStepDataTemporalFrame(this.temporalFrameItems[value]);
    },
    ...mapActions('nutritionStepper', ['next', 'back', 'findSelectedIndex', 'updateClasses', 'setStep',
      'setStepDataTemporalFrame',
      'setStepDataInitDate',
      'setStepDataEndDate']),
  },
};
