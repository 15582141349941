<template>
  <f7-page
    class="main-viewsector"
  >
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name} > ${currentParcel.name} > ${currentSector.sectorName}`" />
    <f7-row v-if="loaded">
      <f7-col>
        <SectorDetail
          :current-sector="currentSector"
          :current-client="actualUser.name"
        />
      </f7-col>
      <f7-col>
        <f7-block class="no-padding padding-top padding-right">
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <PlantationsDataGrid
          :plantations-availables="plantationAvailableFormatted"
          :current-client-id="actualUser.id"
        />
      </f7-col>
    </f7-row>
    <div
      v-if="loaded"
      class="go-back-button margin-left margin-top-half"
    >
      <f7-button
        class="dx-btn-cancel padding-horizontal"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t('Button_go_to_back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment-timezone';
import SectorDetail from '../../components/sector/sectorDetail/index.vue';
import GISGeoJsonMap from '../../components/Gis/GISGeoJsonMap/index.vue';
import PlantationsDataGrid from '../../components/sector/plantationsDataGrid/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ViewSector',
  components: {
    SectorDetail,
    GISGeoJsonMap,
    PlantationsDataGrid,
    navbar,
  },
  data() {
    return {
      zoom: 17,
      loaded: false,
      mainMap: 'sectorMainMap',
      plantationAvailableFormatted: [],
      actualDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapState('Parcel', ['currentParcelId', 'currentParcel']),
    ...mapState('Sector', ['currentSectorId', 'currentSector']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getSectorById({ companyId: this.actualUser.id, currentSectorId: this.currentSectorId });
      await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: this.currentSectorId });
      this.formattingPlantationsAvailables();
      this.plantationAvailableFormatted = [...this.currentSector.plantation];
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    formattingPlantationsAvailables() {
      for (const plantationAvailable of this.currentSector.plantation) {
        plantationAvailable.status = this.getStatusCampaign(plantationAvailable.init_date, plantationAvailable.end_date);
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
    },
    ...mapActions('Sector', ['getSectorById']),
    ...mapActions('Plantation', ['fetchTreeLocationById']),
  },
};
</script>

<style lang="scss">
.main-viewsector{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
