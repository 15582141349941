<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      id="ObservationsDatagrid-datagrid"
      :data-source="observations"
      :remote-operations="true"
      :column-min-width="45"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @RowRemoved="callToDeleteObservation"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="ObservationsDatagrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxColumn
        alignment="center"
        cell-template="categoryColor"
      />
      <DxColumn
        data-field="category"
        :min-width="100"
        :caption="$t('Searcher.observations.category')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="subcategory"
        :min-width="100"
        :caption="$t('Searcher.observations.subcategory')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date"
        :caption="$t('Searcher.observations.date')"
        :allow-header-filtering="false"
        alignment="left"
        :calculate-display-value="$helpers.dxDateFormatting"
      />
      <DxColumn
        type="buttons"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewObservation"
        />
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editObservation"
        />
        <DxButton
          name="delete"
          icon="trash"
        />
      </DxColumn>
      <template #categoryColor="{ data }">
        <f7-icon
          f7="app_fill"
          :color="setColorByCategory(data.data.category)"
        />
      </template>
      <template #titleDataGrid>
        {{ $t('Searcher.observations.observations_title') }}
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxButton,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'ObservationsDatagrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxEditing,
    DxButton,
    DxTexts,
  },
  props: {
    observations: {
      type: Array,
      default: () => [],
    },
    companyId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    viewObservation(rowSelected) {
      this.setIdCurrentObservation(rowSelected.row.data.id);
      this.$f7.views.main.router.navigate('/viewObservation/', { reloadCurrent: true });
    },
    editObservation(rowSelected) {
      this.setIdCurrentObservation(rowSelected.row.data.id);
      this.$f7.views.main.router.navigate('/editObservation/', { reloadCurrent: true });
    },
    async callToDeleteObservation(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.deleteObservation({ companyId: this.companyId, observationId: rowSelected.data.id });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setColorByCategory(category) {
      if (category === 'Incidencia') {
        return 'red';
      }
      return 'blue';
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.$f7.views.main.router.navigate('/createObservation/', { reloadCurrent: true });
          },
        },
      },
      {
        location: 'before',
        template: 'titleDataGrid',
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ObservationsDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ObservationsDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('SearcherObservations', ['getObservationDetail', 'createObservation', 'deleteObservation', 'setIdCurrentObservation']),
  },
};
</script>
<style lang="scss" scoped>
@import './ObservationsDatagrid.styles.scss';
</style>
