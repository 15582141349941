import navbar from '@/components/NavBar.vue';
import treatmentCreateOrderComponent from '@/components/wizardTreatment/treatmentCreateOrder/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'treatment-create-order',
  components: {
    navbar,
    treatmentCreateOrderComponent,
  },
  props: [],
  data() {
    return {

    };
  },

  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};
