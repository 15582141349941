<template>
  <DxTabPanel class="backgroundColor">
    <DxItem
      name="DetailTabs_products_title"
      :title="$t('DetailTabs_products_title')"
      template="products-tab"
    />
    <DxDetailTabProducts
      slot="products-tab"
      :products="detailProducts.data.products"
      type-tab="products"
    />
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxDetailTabProducts from './DxDetailTabProducts.vue';

export default {
  components: {
    DxTabPanel, DxItem, DxDetailTabProducts,
  },
  props: {
    detailProducts: { type: Object, default: () => {} },
  },
};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
