import { mapActions, mapGetters, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'stepper-navigation',
  components: { DxButton },
  props: { },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['nutritionSteps', 'currentStep', 'stepIndex']),
    ...mapState('Plantation', ['isPlantationSelected', 'oldPlantation']),
  },
  mounted() {

  },
  methods: {
    getNextLabel() {
      if (this.stepIndex === this.nutritionSteps.length - 1) {
        return this.$t('Utilities.stepperForm.save');
      }
      return this.$t('Utilities.stepperForm.next');
    },
    nextStep() {
      if (this.stepIndex === this.nutritionSteps.length - 1) {
        this.$emit('save');
        return;
      }
      this.next();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    backStep() {
      if (this.isPlantationSelected && this.stepIndex === 0) {
        this.setCurrentPlantation(this.oldPlantation);
        this.$f7.views.main.router.navigate('/viewCopyNutritionPlan/', { reloadCurrent: true });
        return;
      }

      if (this.stepIndex === 0) {
        this.$f7.views.main.router.navigate('/searcher/nutrition/',
          { reloadCurrent: true });
        return;
      }
      this.back();
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    ...mapActions('nutritionStepper', ['next', 'back']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
  },
};
