<template>
  <DxForm
    id="selectLevelZero"
  >
    <DxSimpleItem
      :data-field="$t('DxSelectLevel0Location_farm')"
      :editor-options="{
        items: levelZeroLocations,
        searchEnabled: true,
        searchTimeout: searchTimeoutOption,
        minSearchLength: minSearchLengthOption,
        showDataBeforeSearch: showDataBeforeSearchOption,
        onValueChanged: onLevelZeroChange,
        value: selectedFarmToSend,
      }"
      editor-type="dxSelectBox"
    >
      <DxRequiredRule :message="$t('DxSelectLevel0Location_requiredRule_farm')" />
    </DxSimpleItem>
  </DxForm>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxSelectLevel0Location',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    levelZeroLocations: { type: Array, default: () => [] },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      setDisabled: false,
    };
  },
  computed: {
    ...mapState('greenhouse', ['selectedFarmToSend']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
  },
  methods: {
    onLevelZeroChange(e) {
      this.setSelectedFarmToSend(e.value);
    },
    ...mapActions('greenhouse', [
      'setSelectedFarmToSend',
    ]),
  },
};
</script>
<style>
