import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardTreatment/stepper/index.vue';
import StepperNavigation from '../../../components/wizardTreatment/stepper-navigation/index.vue';
import DosageProduct from '../../../components/wizardTreatment/dosageProduct/index.vue';
import { treatmentPlanService } from '../../../services/treatmentPlanService';

export default {
  name: 'treatment-step-one',
  components: {
    Stepper,
    StepperNavigation,
    navbar,
    DosageProduct,
    DxLoadPanel,
  },
  props: ['id'],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  async mounted() {
    this.loaded = false;
    if (this.id) {
      const response = await treatmentPlanService
        .getById(this.id, this.currentPlantation.companyId);
      response.species = this.currentPlantation.species;
      await this.updateTreatmentPlan(response);
    }

    this.loaded = true;
    this.setStep(0);
  },
  methods: {
    ...mapActions('treatmentStepper', ['setStep', 'updateTreatmentPlan']),
  },
};
