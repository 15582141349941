<template>
  <f7-block>
    <f7-row>
      <f7-col width="100">
        <f7-block v-if="!loadedGIS || (currentIndexes[`${mainMap}Left`] === '' && currentIndexes[`${mainMap}Right`] === '')">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISWMSSwipeMap
            :zoom="zoom"
            :name-map="mainMap"
            :options-left-key="`${mainMap}Left`"
            :options-right-key="`${mainMap}Right`"
            :tree-locations="treeLocations"
            :layer="layer"
            :indexes="[currentIndexes[`${mainMap}Left`], currentIndexes[`${mainMap}Right`]]"
            :center="center"
            :height="'450px'"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col width="50">
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Left`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
        />
      </f7-col>
      <f7-col width="50">
        <GISLayerOptionsComparator
          :key-id="`${mainMap}Right`"
          :default-index="'NDVI'"
          :dates-list="currentDatesList"
        />
      </f7-col>
    </f7-row>
  </f7-block>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSSwipeMap from '../../../Gis/GISWMSSwipeMap/index.vue';
import GISLayerOptionsComparator from '../../../Gis/LayerOptionsComparator/index.vue';
import Api from '../../../../services/Api';

export default {
  name: 'GisScanning',
  components: {
    GISWMSSwipeMap,
    GISLayerOptionsComparator,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'gisScanning',
      currentDatesList: {},
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'datesList']),
  },
  async beforeMount() {
    try {
      this.layer.options.layers = `client_efemis:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.fetchDatesLayer(this.currentPlantation.sectorId);
      this.currentDatesList = this.datesList;
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['fetchDatesLayer', 'resetCurrentIntervalDates']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisScanning.styles.scss';
</style>
