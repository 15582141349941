<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-daily-planning"
  >
    <CalendarInitEndDate class="margin-top" :key-name="keyName" />
    <DxDataGrid
      id="gcDailyPlanning"
      :data-source="detailPlanning"
      :remote-operations="true"
      :column-min-width="130"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxDailyPlanning"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="Date"
        name="date"
        :caption="$t('DxDailyPlanning_date_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :caption="$t('DxDailyPlanning_task_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        :width="400"
        :caption="$t('DxDailyPlanning_location_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="area_effective"
        name="workingarea"
        alignment="center"
        :caption="$t('DxDailyPlanning_workingArea_caption')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="PerformanceFormated"
        name="performance"
        :caption="$t('DxDailyPlanning_performanceha_caption')"
        alignment="center"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="Workers"
        name="workers"
        :caption="$t('DxDailyPlanning_workers_caption')"
        alignment="center"
        :allow-header-filtering="false"
      />
      <DxColumn
        type="buttons"
        alignment="left"
        :width="90"
        :fixed="true"
        fixed-position="right"
      >
        <!-- TODO El orden de los botones implica su color y estilo, no tocar -->
        <DxButton
          icon="add"
          :hint="$t('DxDailyPlanning_new_order_hint')"
          :on-click="newOrder"
        />
        <DxButton
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editTask"
        />
        <DxButton
          icon="trash"
          :hint="$t('DxDailyPlanning_delete_hint')"
          :on-click="deletePlanning"
        />
      </DxColumn>
      <DxMasterDetail
        :enabled="true"
        template="master-detail"
      />
      <template
        #master-detail="{ data: detailPlanning }"
      >
        <DxDetailTabs
          :master-detail-data="detailPlanning"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
  DxSelection,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import DxDetailTabs from './DxDetailTabs.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxDailyPlanning',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    DxDetailTabs,
    DxSelection,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
    DxPager,
  },
  data() {
    return {
      loaded: false,
      locationsFake: ['5f2d2597a3b29a5c2b858204', '5f2d2597a3b29a5c2b858205', '5f2d2597a3b29a5c2b858206', '5f2d2597a3b29a5c2b858207'],
      detailPlanning: [],
      finalLevelLocation: [],
      widthSearchPanel: 500,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      keyName: 'dailyPlanning',
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapGetters('greenhouse', ['getVarietyNameByCode', 'getCategoryNameByCode', 'getClassificationNameByCode', 'getLocationNameLevelSelectedChildId']),
    ...mapState('PlanningManager', ['actualLocationsTreeFormated']),
    ...mapState('greenhouse', ['locationTree']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.tasksAvailables = await this.loadTasksAvailables();
      this.updateAvailableTasks(this.tasksAvailables);
      await this.fetchLocationTree();
      const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      const xhr = await Api.getDailyPlan(checkedInitEndDate.init, checkedInitEndDate.end, 'PLANNING');
      await this.fetchBinsContentFamilies();
      const planning = JSON.parse(xhr.response).data;
      this.detailPlanning.length = 0;
      for (let i = 0; i < planning.length; i += 1) {
        let prescriptions = [];
        let prescriptionsDetails = [];
        let toolsDetailTab = [];
        for (let j = 0; j < planning[i].tools.length; j += 1) {
          toolsDetailTab = planning[i].tools[j].data.properties;
        }
        if (planning[i].prescription !== null) {
          for (let k = 0; k < planning[i].prescription.products.length; k += 1) {
            prescriptions = planning[i].prescription.products[k];
            prescriptionsDetails = planning[i].prescription.products[k].product;
          }
        }

        // Establedemos los nombres de variedad/categoria/clasificacion a través del code
        planning[i].nursery.forEach((element) => {
          const varietyName = this.getVarietyNameByCode(element.variety);
          const categoryName = this.getCategoryNameByCode(element.category);
          const classificationName = this.getClassificationNameByCode(element.class_type);
          element.variety = varietyName !== '' ? varietyName : element.variety;
          element.category = varietyName !== '' ? categoryName : element.category;
          element.class_type = classificationName !== '' ? classificationName : element.class_type;
        });
        this.locationsFake = [...planning[i].locations];
        this.detailPlanning.push({
          ID: planning[i].id,
          Date: this.formattingDates(planning[i].date),
          Task: planning[i].task_name,
          taskCode: planning[i].task_code,
          task: { name: planning[i].task_name, code: planning[i].task_code, id: planning[i].task },
          Activity: planning[i].activity,
          activityCode: planning[i].activity_code,
          activity: { name: planning[i].activity, code: planning[i].activity_code },
          performanceUnit: planning[i].goal_performance.unit,
          performanceCount: planning[i].goal_performance.count,
          PerformanceFormated: this.formattingPerformance(planning[i].goal_performance.unit, planning[i].goal_performance.count),
          Grouped: planning[i].grouped,
          Workers: this.getTotalWorkers(planning[i].workers),
          WorkersDetailTab: planning[i].workers,
          ItemsDetailTab: planning[i].items,
          AttachmentsDetailTab: planning[i].attachments,
          Tools: planning[i].tools,
          ToolsDetailTab: toolsDetailTab,
          locationLevel2: this.getLocationsFormated(planning[i].locations_tree),
          locations: planning[i].locations,
          areaHa: this.areaLevel2.toFixed(2),
          shift: planning[i].shift,
          groupName: planning[i].group,
          totalAreaSelected: planning[i].totalAreaSelected,
          prescription: planning[i].prescription,
          pres: prescriptions,
          prescriptionDetailsTab: prescriptionsDetails,
          products: planning[i].products,
          distributed: planning[i].distributed,
          area_effective: planning[i].area_effective,
          variety: planning[i].variety === null || planning[i].variety === 'ALL' ? '' : planning[i].variety,
          binsDetail: planning[i].nursery,
        });
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async loadTasksAvailables() {
      const xhr = await Api.fetchTasksDefinitions();
      return JSON.parse(xhr.response).data;
    },
    editTask(rowSelected) {
      const binsContentPlannedToEdit = rowSelected.row.data.binsDetail.map((content) => ({
        bin: content.bin.bin_id,
        category: content.category,
        classification: content.class_type,
        content: content.code,
        variety: content.variety,
        plants: content.count,
      }));
      this.setBinsContentPlannedToEdit(binsContentPlannedToEdit);
      this.setTaskSelectedByTaskCode(rowSelected.row.data.taskCode);
      this.setActualDailyPlanning(rowSelected.row.data);
      if (rowSelected.row.data.prescription) {
        this.setPrescription(rowSelected.row.data.prescription);
      } else {
        this.setNewPrescription();
      }
      this.setProducts(rowSelected.row.data.products);
      this.$f7.views.main.router.navigate('/editPlanning/', { reloadCurrent: true });
    },
    async deletePlanning(rowSelected) {
      const planningId = rowSelected.row.data.ID;
      const f7 = this.$f7;
      this.$f7.dialog.create({
        title: this.$t('DxDailyPlanning_dialogDelete_title'),
        text: this.$t('DxDailyPlanning_dialogDelete_text'),
        buttons: [
          {
            text: this.$t('DxDailyPlanning_dialogDelete_cancel'),
          },
          {
            text: this.$t('DxDailyPlanning_dialogDelete_confirm'),
            async onClick() {
              try {
                f7.preloader.show();
                await Api.deletePlanning(planningId);
                f7.views.main.router.navigate('/dailyPlanning/', { reloadCurrent: true });
              } catch (e) {
                f7.dialog.alert(e);
              } finally {
                f7.preloader.hide();
              }
            },
          },
        ],
      }).open();
    },
    newOrder(rowSelected) {
      const binsContentPlannedToEdit = rowSelected.row.data.binsDetail.map((content) => ({
        bin: content.bin.bin_id,
        category: content.category,
        classification: content.class_type,
        content: content.code,
        variety: content.variety,
        plants: content.count,
      }));
      this.setAttachments([]);
      this.setBinsContentPlannedToEdit(binsContentPlannedToEdit);
      this.setTaskSelectedByTaskCode(rowSelected.row.data.taskCode);
      this.setActualDailyPlanning(rowSelected.row.data);
      if (rowSelected.row.data.prescription) {
        const { prescription } = rowSelected.row.data;
        prescription.products = rowSelected.row.data.products;
        this.setPrescription(prescription);
      } else {
        this.setNewPrescription();
      }
      this.setProducts(rowSelected.row.data.products);
      this.deleteActualWorkOrder();
      this.$f7.views.main.router.navigate('/createOrder/', { reloadCurrent: true });
    },
    getLocationsFormated(locationsCompleted) {
      if (locationsCompleted.length !== 0) {
        const locationsNameCompleted = [];
        this.$helpers.fetchLocationsNames(locationsNameCompleted, locationsCompleted, this.locationTree);
        return locationsNameCompleted;
      }
      return '';
    },
    getTotalWorkers(workers) {
      let totalWorker = 0;
      workers.forEach((worker) => {
        totalWorker += worker.count;
      });
      return totalWorker;
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('YYYY-MM-DD'));
      }
      return formatedDates;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('DailyPlaning');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DailyPlaning.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setActualDailyPlanning(null);
            this.initStore();
            this.updateSelectedTask(null);
            this.$f7.views.main.router.navigate('/createPlanning/', { reloadCurrent: true });
          },
        },
      });
    },
    formattingPerformance(unit, count) {
      if (unit === '') {
        return '';
      }
      return `${count} ${unit}`;
    },
    ...mapActions('PlanningManager', ['setActualDailyPlanning', 'deleteActualWorkOrder', 'initStore', 'setAttachments']),
    ...mapActions('Prescription', ['setPrescription', 'setProducts', 'setNewPrescription']),
    ...mapActions('greenhouse', [
      'fetchBinsContentFamilies',
      'setBinsContentPlannedToEdit',
      'setTaskSelectedByTaskCode',
      'updateAvailableTasks',
      'updateSelectedTask',
      'fetchLocationTree',
    ]),
  },
};
</script>
