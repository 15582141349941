import moment from 'moment';
import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    binsLocationName: '',
    binsName: '',
    binsGuideNumber: '',
    locationTree: [],
    binsCurrentContent: [],
    isLocationDisabled: false,
    binsCurrentContentToMove: [],
    selectedLocationToMove: '',
    nameBatch: '',
    dateBatch: '',
    idBatch: '',
    selectedBins: [],
    allBins: [],
    lotBins: [],
    binData: [],
    binToEdit: {},
    selectedFarmToSend: '',
    idBinToSend: '',
    levelZeroLocationsToSendBin: [],
    binsContentVarieties: [],
    binsContentCategories: [],
    binsContentClassifications: [],
    selectedTask: {},
    availableTasks: [],
    binsContentsForPlanning: [],
    binsContentPlanned: [],
    binsContentPlannedToEdit: [],
    bareRootsLocationName: '',
    bareRootsName: '',
    bareRootsPlantingYear: '',
    bareRootsVariety: '',
    bareRootsCategory: '',
    bareRootsClass: '',
    bareRootsPlants: '',
    bareRootsNote: '',
    bareRootsId: '',
    bareRootsCreationDate: '',
    bareRootsLocationId: '',
    locationBatch: '',
    fetchedBareRootsWithoutBin: [],
    selectedBareRoots: [],
    binsStocks: [],
    idBatchToSend: '',
    bareRootCategories: [],
    binEgress: [],
  },
  getters: {
    availableBareRootsForBins(state, getters) {
      if (state.fetchedBareRootsWithoutBin.length > 0) {
        return state.fetchedBareRootsWithoutBin.map((bareRoot) => ({
          id: bareRoot.id,
          creation: bareRoot.creation,
          name: bareRoot.name,
          location: getters.getLocationNameByLocationId(bareRoot.location),
          plantingYear: bareRoot.plant_year,
          variety: getters.getVarietyNameByCode(bareRoot.variety),
          category: getters.getCategoryNameByCode(bareRoot.category),
          classification: getters.getClassificationNameByCode(bareRoot.class_type),
          plants: bareRoot.count,
        }));
      }
      return [];
    },
    bareRootsCategories(_state, getters) {
      return getters.availableBareRootCategoryNames;
    },
    bareRootsClasses(_state, getters) {
      return getters.availableClassificationNames;
    },
    bareRootsVarieties(_state, getters) {
      return getters.availableVarietyNames;
    },
    levelZeroLocationsNamesToSendBin(state) {
      if (state.levelZeroLocationsToSendBin.length > 0) {
        const levelZeroLocationsNamesToSendBin = state.levelZeroLocationsToSendBin.map(
          (location) => location.name,
        );
        return levelZeroLocationsNamesToSendBin;
      }
      return [];
    },
    userFarmsIds(_state) {
      const farmsZeroId = [];
      for (const location of _state.locationTree) {
        farmsZeroId.push(location.bd_id);
      }
      return farmsZeroId;
    },
    usersLevelZeroNames(_state) {
      const nameFarmsZero = [];
      for (const location of _state.locationTree) {
        nameFarmsZero.push(location.name);
      }
      return nameFarmsZero;
    },
    locationTreeByName(state) {
      if (state.binsLocationName !== '') {
        const locationTreeByName = state.locationTree.filter(
          (location) => location.name === state.binsLocationName,
        );
        return locationTreeByName;
      }
      if (state.bareRootsLocationName !== '') {
        const locationTreeByName = state.locationTree.filter(
          (location) => location.name === state.bareRootsLocationName,
        );
        return locationTreeByName;
      }
      return state.locationTree;
    },
    locationIdBySelectedLevelZeroLocation(state) {
      if (state.binsLocationName !== '') {
        const locationArray = state.locationTree.filter(
          (location) => location.name === state.binsLocationName,
        );
        return locationArray[0].bd_id;
      }
      if (state.bareRootsLocationName !== '') {
        const locationArray = state.locationTree.filter(
          (location) => location.name === state.bareRootsLocationName,
        );
        return locationArray[0].bd_id;
      }
      return '';
    },
    locationIdByLocationName: (_state, getters) => (name) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.name === name,
      );
      if (locationArray.length > 0) {
        return locationArray[0].bd_id;
      }
      return '';
    },
    newBinToCreate(state, getters) {
      const content = state.selectedBareRoots.map((bareRoot) => ({
        stock: bareRoot.id,
        location: getters.locationIdByLocationName(bareRoot.location),
        variety: getters.getVarietyCodeByName(bareRoot.variety),
        category: getters.getCategoryCodeByName(bareRoot.category),
        class_type: getters.getClassificationCodeByName(bareRoot.classification),
        count: bareRoot.plants,
        plant_year: moment(bareRoot.plant_year).format('YYYY'),
      }));
      const newBin = {
        name: state.binsName,
        farm: getters.locationIdBySelectedLevelZeroLocation,
        date: moment().format('YYYY-MM-DD'),
        content,
        guide_number: state.binsGuideNumber,
      };

      return newBin;
    },
    newBareRootToCreate(state, getters) {
      const newBareRoot = {
        name: state.bareRootsName,
        farm: getters.locationIdBySelectedLevelZeroLocation,
        location: state.selectedLocationToMove[0],
        variety: getters.getVarietyCodeByName(state.bareRootsVariety),
        category: getters.getCategoryCodeByName(state.bareRootsCategory),
        class_type: getters.getClassificationCodeByName(state.bareRootsClass),
        count: state.bareRootsPlants,
        plant_year: state.bareRootsPlantingYear,
        comments: state.bareRootsNote,
      };
      if (state.bareRootsId !== '') {
        newBareRoot.creation = moment(state.bareRootsCreationDate).format('YYYY-MM-DD');
      } else {
        newBareRoot.creation = moment().format('YYYY-MM-DD');
      }
      return newBareRoot;
    },
    getBareRootToEdit(state, getters) {
      const newBareRoot = {
        name: state.bareRootsName,
        creation: moment(state.bareRootsCreationDate).format('YYYY-MM-DD'),
        farm: getters.getLocationNameLevelZeroByChildId(state.bareRootsLocationId),
        location: getters.getLocationNameByLocationId(state.bareRootsLocationId),
        locationId: state.bareRootsLocationId,
        variety: state.bareRootsVariety,
        category: state.bareRootsCategory,
        class_type: state.bareRootsClass,
        count: state.bareRootsPlants,
        plant_year: state.bareRootsPlantingYear,
        comments: state.bareRootsNote,
        id: state.bareRootsId,
      };
      return newBareRoot;
    },
    isBareRootFormValid(state) {
      if (
        state.bareRootsCategory !== ''
        && state.bareRootsVariety !== ''
        && state.bareRootsClass !== ''
        && state.bareRootsName !== ''
        && state.bareRootsPlantingYear !== ''
        && state.bareRootsPlants > 0
        && state.bareRootsLocationName !== ''
        && state.selectedLocationToMove.length > 0
      ) {
        return true;
      }
      return false;
    },
    locationNameByLocationId: (state) => (id) => {
      const locationArray = state.locationTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        return locationArray[0].name;
      }
      return '';
    },
    getLocationNameByLocationId: (_state, getters) => (id) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        return locationArray[0].name;
      }
      return '';
    },
    getLocationNameLevelZeroByChildId: (_state, getters) => (id) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        if (locationArray[0].level === 0) {
          return locationArray[0].name;
        }
        return getters.getLocationNameLevelZeroByChildId(locationArray[0].parent);
      }
      return '';
    },
    getLocationNameLevelTwoByChildId: (_state, getters) => (id) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        // Si el nivel es 2, cogemos el nombre
        if (locationArray[0].level === 2) {
          return locationArray[0].name;
        }
        // Si el nivel es menor que 2, cogemos cada hijo
        if (locationArray[0].level < 2) {
          let nameOfChildLevel2 = '';
          locationArray[0].children.forEach((element) => {
            if (nameOfChildLevel2 !== '') {
              nameOfChildLevel2 += ', ';
            }
            nameOfChildLevel2 += getters.getLocationNameLevelTwoByChildId(element.bd_id);
          });
          return nameOfChildLevel2;
        }
        // Si el nivel es mayor que 2, cogemos al padre.
        return getters.getLocationNameLevelTwoByChildId(locationArray[0].parent);
      }
      return '';
    },
    getLocationNameLevelSelectedChildId: (state, getters) => (id, level) => {
      const locationArray = getters.formattedTree.filter(
        (location) => location.bd_id === id,
      );
      if (locationArray[0]) {
        // Si el nivel es seleccionado, cogemos el nombre
        if (locationArray[0].level === level) {
          return locationArray[0].name;
        }
        // Si el nivel es menor que el seleccionado, cogemos cada hijo
        if (locationArray[0].level < level) {
          let nameOfChildAtLevel = '';
          locationArray[0].children.forEach((element) => {
            if (nameOfChildAtLevel !== '') {
              nameOfChildAtLevel += ', ';
            }
            nameOfChildAtLevel += getters.getLocationNameLevelSelectedChildId(element.bd_id, level);
          });
          return nameOfChildAtLevel;
        }
        // Si el nivel es mayor que el nivel seleccionado, cogemos al padre.
        return getters.getLocationNameLevelSelectedChildId(locationArray[0].parent, level);
      }
      return '';
    },
    availableEgressNames(state) {
      if (state.binEgress.length > 0) {
        return state.binEgress.map((egress) => egress.name);
      }
      return [];
    },
    availableBareRootCategoryNames(state) {
      if (state.bareRootCategories.length > 0) {
        return state.bareRootCategories.map((category) => category.name);
      }
      return [];
    },
    availableCategoryNames(state) {
      if (state.binsContentCategories.length > 0) {
        return state.binsContentCategories.map((category) => category.name);
      }
      return [];
    },
    getBinEgressCodeByName: (state) => (egressName) => {
      const egressCodes = state.binEgress
        .filter(
          (egress) => egress.name === egressName,
        )
        .map(
          (egress) => egress.code,
        );
      if (egressCodes.length > 0) {
        return egressCodes[0];
      }
      return '';
    },
    getBinEgressNameByCode: (state) => (egressCode) => {
      const egressNames = state.binEgress
        .filter(
          (egress) => egress.code === egressCode,
        )
        .map(
          (egress) => egress.name,
        );
      if (egressNames.length > 0) {
        return egressNames[0];
      }
      return '';
    },
    getBareRootCategoryCodeByName: (state) => (categoryName) => {
      const categoryCodes = state.bareRootCategories
        .filter(
          (category) => category.name === categoryName,
        )
        .map(
          (category) => category.code,
        );
      if (categoryCodes.length > 0) {
        return categoryCodes[0];
      }
      return '';
    },
    getBareRootCategoryNameByCode: (state) => (categoryCode) => {
      const categoryNames = state.bareRootCategories
        .filter(
          (category) => category.code === categoryCode,
        )
        .map(
          (category) => category.name,
        );
      if (categoryNames.length > 0) {
        return categoryNames[0];
      }
      return '';
    },

    getCategoryCodeByName: (state) => (categoryName) => {
      const categoryCodes = state.binsContentCategories
        .filter(
          (category) => category.name === categoryName,
        )
        .map(
          (category) => category.code,
        );
      if (categoryCodes.length > 0) {
        return categoryCodes[0];
      }
      return '';
    },
    getCategoryNameByCode: (state) => (categoryCode) => {
      const categoryNames = state.binsContentCategories
        .filter(
          (category) => category.code === categoryCode,
        )
        .map(
          (category) => category.name,
        );
      if (categoryNames.length > 0) {
        return categoryNames[0];
      }
      return '';
    },
    availableVarietyNames(state) {
      if (state.binsContentVarieties.length > 0) {
        return state.binsContentVarieties.map((variety) => variety.name);
      }
      return [];
    },
    getVarietyCodeByName: (state) => (varietyName) => {
      const varietyCodes = state.binsContentVarieties
        .filter(
          (variety) => variety.name === varietyName,
        )
        .map(
          (variety) => variety.code,
        );
      if (varietyCodes.length > 0) {
        return varietyCodes[0];
      }
      return '';
    },
    getVarietyNameByCode: (state) => (varietyCode) => {
      const varietyNames = state.binsContentVarieties
        .filter(
          (variety) => variety.code === varietyCode,
        )
        .map(
          (variety) => variety.name,
        );
      if (varietyNames.length > 0) {
        return varietyNames[0];
      }
      return '';
    },
    availableClassificationNames(state) {
      if (state.binsContentClassifications.length > 0) {
        return state.binsContentClassifications.map((variety) => variety.name);
      }
      return [];
    },
    getClassificationCodeByName: (state) => (classificationName) => {
      const classificationCodes = state.binsContentClassifications
        .filter(
          (classification) => classification.name === classificationName,
        )
        .map(
          (classification) => classification.code,
        );
      if (classificationCodes.length > 0) {
        return classificationCodes[0];
      }
      return '';
    },
    getClassificationNameByCode: (state) => (classificationCode) => {
      const classificationNames = state.binsContentClassifications
        .filter(
          (classification) => classification.code === classificationCode,
        )
        .map(
          (classification) => classification.name,
        );
      if (classificationNames.length > 0) {
        return classificationNames[0];
      }
      return '';
    },
    getMergedBins(state, getters) {
      const mergedBins = [...state.lotBins, ...state.allBins];
      mergedBins.forEach((element) => {
        const idOrigin = element.shipments[element.shipments.length - 1].origin;
        const idDestination = element.shipments[element.shipments.length - 1].destination;
        let location = idOrigin;
        if (idDestination) {
          location = idDestination;
        }
        const nameOrigin = getters.locationNameByLocationId(location);
        element.origin = nameOrigin;
      });
      return mergedBins;
    },
    newBatchToCreate(state, getters) {
      const fechaCreacion = state.dateBatch;
      const lote = {
        name: state.nameBatch,
        creation: moment(fechaCreacion).format('YYYY-MM-DD'),
        bins: getters.getIdBins,
        farm: getters.locationBatch,
      };
      return lote;
    },
    getIdBins(state) {
      const idsBins = [];
      for (let i = 0; i < state.selectedBins.length; i += 1) {
        idsBins.push(state.selectedBins[i].id);
      }
      return idsBins;
    },
    formattedTree(state) {
      const locationsTreeFormatted = [];
      const setLocationTreeFormat = (idParent, item) => {
        if (item.level > 3) {
          locationsTreeFormatted.push({
            location: `${item.name} -${item.properties.variety_code}- ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        } else {
          locationsTreeFormatted.push({
            location: `${item.name} - ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        }
      };
      const recursiveAddChildren = (idParent, children) => {
        for (let i = 0; i < children.length; i += 1) {
          setLocationTreeFormat(idParent, children[i]);
          recursiveAddChildren(children[i].bd_id, children[i].children);
        }
      };

      for (const item of state.locationTree) {
        setLocationTreeFormat(undefined, item);
        recursiveAddChildren(item.bd_id, item.children);
      }
      return locationsTreeFormatted;
    },
    binsInfo(state) {
      if (state.binToEdit) {
        return {
          name: state.binToEdit.name,
          farm: state.binToEdit.origin,
          date: state.binToEdit.date,
          guideNumber: state.binToEdit.guideNumber,
        };
      }
      return {};
    },
    binsContent(state) {
      const binsContent = [];
      if (state.binToEdit.binsDetail) {
        state.binToEdit.binsDetail.map((content) => binsContent.push({
          category: content.category,
          classification: content.class_type,
          location_level_0: state.binToEdit.origin,
          location_level_last: content.locationName,
          plantsCount: content.count,
          variety: content.variety,
          code: content.code,
          parent_code: content.parent,
          egreso: content.egreso,
          plant_year: moment(`${content.plant_year}/12/12`, 'YYYY/MM/DD').format('yyyy'),
        }));
      }
      return binsContent;
    },
    binsId(state) {
      return state.binToEdit.bd_id;
    },
    binsContentCodesToMove(state) {
      return state.binsCurrentContentToMove.map((element) => element.code);
    },
    binsContentMovesToSave(state, getters) {
      return {
        elements: getters.binsContentCodesToMove,
        location: state.selectedLocationToMove[0],
      };
    },
    editedBinToSave(state, getters) {
      const content = state.selectedBareRoots.map((bareRoot) => ({
        stock: bareRoot.id,
        location: getters.locationIdByLocationName(bareRoot.location),
        variety: getters.getVarietyCodeByName(bareRoot.variety),
        category: getters.getCategoryCodeByName(bareRoot.category),
        class_type: getters.getClassificationCodeByName(bareRoot.classification),
        count: bareRoot.plants,
        plant_year: moment(bareRoot.plant_year).format('YYYY'),
      }));
      return {
        name: state.binsName,
        farm: getters.locationIdByLocationName(state.binsLocationName),
        content,
        guide_number: state.binsGuideNumber,
      };
    },
    reclassifiedBinToSave(state, getters) {
      const content = state.binsCurrentContent.map((element) => {
        if (!element.code) {
          const parentContent = state.binsCurrentContent.filter(
            (parentElement) => parentElement.code === element.parent_code,
          );
          const elementFulfilled = {
            variety: getters.getVarietyCodeByName(parentContent[0].variety),
            category: getters.getCategoryCodeByName(element.category),
            class_type: getters.getClassificationCodeByName(element.classification),
            count: element.plantsCount,
            location: '',
            parent: element.parent_code,
            plant_year: moment(element.plant_year).format('YYYY'),
            egreso: getters.getBinEgressCodeByName(element.egreso),
          };

          if (parentContent[0].location_level_last && parentContent[0].location_level_last !== '') {
            elementFulfilled.location = getters
              .locationIdByLocationName(parentContent[0].location_level_last);
          }

          return elementFulfilled;
        }

        if (element.location_level_last !== '') {
          return {
            location: getters.locationIdByLocationName(element.location_level_last),
            variety: getters.getVarietyCodeByName(element.variety),
            category: getters.getCategoryCodeByName(element.category),
            class_type: getters.getClassificationCodeByName(element.classification),
            count: element.plantsCount,
            code: element.code,
            parent: element.parent_code,
            plant_year: moment(element.plant_year).format('YYYY'),
            egreso: getters.getBinEgressCodeByName(element.egreso),
          };
        }
        return {
          location: '',
          variety: getters.getVarietyCodeByName(element.variety),
          category: getters.getCategoryCodeByName(element.category),
          class_type: getters.getClassificationCodeByName(element.classification),
          count: element.plantsCount,
          code: element.code,
          parent: element.parent_code,
          plant_year: moment(element.plant_year).format('YYYY'),
          egreso: getters.getBinEgressCodeByName(element.egreso),
        };
      });
      return {
        farm: getters.locationIdByLocationName(state.binsLocationName),
        content,
      };
    },
    showBinsPlanningForm(state) {
      if (state.selectedTask && state.selectedTask.properties) {
        return (state.selectedTask.properties.find((property) => property.key === 'nursery').value === 'True');
      }
      return false;
    },
    binsAvailableForPlanning(state) {
      if (state.binsContentsForPlanning.length > 0) {
        const binsAvailableForPlanning = state.binsContentsForPlanning.map((element) => {
          element.bin.nameCode = `${element.bin.name} - ${element.bin.code}`;
          return element.bin;
        });
        return binsAvailableForPlanning;
      }
      return [];
    },
    varietiesAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const varietiesAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => {
              variety.binId = element.bin.bin_id;
              return {
                bin_id: variety.binId,
                name: getters.getVarietyNameByCode(variety.code),
              };
            }));
        return varietiesAvailableForPlanning.flat(2);
      }
      return [];
    },
    categoriesAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const categoriesAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => {
                category.binId = element.bin.bin_id;
                return {
                  bin_id: category.binId,
                  variety: getters.getVarietyNameByCode(variety.code),
                  name: getters.getCategoryNameByCode(category.code),
                };
              })));
        return categoriesAvailableForPlanning.flat(3);
      }
      return [];
    },
    classificationsAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const classificationsAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => category.class_types
                .map((classification) => {
                  classification.binId = element.bin.bin_id;
                  return {
                    bin_id: classification.binId,
                    variety: getters.getVarietyNameByCode(variety.code),
                    category: getters.getCategoryNameByCode(category.code),
                    name: getters.getClassificationNameByCode(classification.code),
                  };
                }))));
        return classificationsAvailableForPlanning.flat(4);
      }
      return [];
    },
    contentsAvailableForPlanning(state, getters) {
      if (state.binsContentsForPlanning.length > 0) {
        const contentsAvailableForPlanning = state.binsContentsForPlanning
          .map((element) => element.varieties
            .map((variety) => variety.categories
              .map((category) => category.class_types
                .map((classification) => classification.data
                  .map((content) => ({
                    bin_id: content.bin.bin_id,
                    variety: getters.getVarietyNameByCode(content.variety),
                    category: getters.getCategoryNameByCode(content.category),
                    classification: getters.getClassificationNameByCode(content.class_type),
                    name: content.code,
                    plants_count: content.count,
                  }))))));
        return contentsAvailableForPlanning.flat(5);
      }
      return [];
    },
    filteredContentsForPlanning: (_state, getters) => (binId, varietyName, categoryName, classificationName) => {
      const filteredContentsForPlanning = getters.contentsAvailableForPlanning
        .filter(
          (content) => content.bin_id === binId
            && content.variety === varietyName
            && content.category === categoryName
            && content.classification === classificationName,
        );
      return filteredContentsForPlanning;
    },
    nameBatch: (state) => state.nameBatch,
    dateBatch: (state) => state.dateBatch,
    selectedBins: (state) => state.selectedBins,
    lotBins: (state) => state.lotBins,
    allBins: (state) => state.allBins,
    idBatch: (state) => state.idBatch,
    binData: (state) => state.binData,
    binToEdit: (state) => state.binToEdit,
    binsName: (state) => state.binsName,
    binsCurrentContent: (state) => state.binsCurrentContent,
    selectedLocationToMove: (state) => state.selectedLocationToMove,
    binsCurrentContentToMove: (state) => state.binsCurrentContentToMove,
    bareRootsId: (state) => state.bareRootsId,
    locationBatch: (state) => state.locationBatch,
    idBatchToSend: (state) => state.idBatchToSend,
  },
  mutations: {
    SET_BINS_LOCATION_NAME(state, payload) {
      state.binsLocationName = payload;
    },
    SET_BINS_GUIDE_NUMBER(state, payload) {
      state.binsGuideNumber = payload;
    },
    SET_BARE_ROOTS_LOCATION_NAME(state, payload) {
      state.bareRootsLocationName = payload;
    },
    SET_BARE_ROOTS_PLANTING_YEAR(state, payload) {
      state.bareRootsPlantingYear = payload;
    },
    SET_BARE_ROOTS_VARIETY(state, payload) {
      state.bareRootsVariety = payload;
    },
    SET_BARE_ROOTS_CATEGORY(state, payload) {
      state.bareRootsCategory = payload;
    },
    SET_BARE_ROOTS_CLASS(state, payload) {
      state.bareRootsClass = payload;
    },
    SET_BARE_ROOTS_PLANTS(state, payload) {
      state.bareRootsPlants = payload;
    },
    SET_BARE_ROOTS_NOTE(state, payload) {
      state.bareRootsNote = payload;
    },
    SET_BARE_ROOTS_ID(state, payload) {
      state.bareRootsId = payload;
    },
    SET_BARE_ROOTS_CREATION_DATE(state, payload) {
      state.bareRootsCreationDate = payload;
    },
    SET_BARE_ROOTS_LOCATION_ID(state, payload) {
      state.bareRootsLocationId = payload;
    },
    SET_FETCHED_BARE_ROOTS_WITHOUT_BIN(state, payload) {
      state.fetchedBareRootsWithoutBin = payload;
    },
    SET_SELECTED_BARE_ROOTS(state, payload) {
      state.selectedBareRoots = payload;
    },
    SET_LOCATIONS_TREE(state, payload) {
      state.locationTree = payload;
    },
    DISABLE_BINS_LOCATION_SELECT(state) {
      state.isLocationDisabled = true;
    },
    ENABLE_BINS_LOCATION_SELECT(state) {
      state.isLocationDisabled = false;
    },
    SET_BINS_NAME(state, payload) {
      state.binsName = payload;
    },
    SET_BARE_ROOTS_NAME(state, payload) {
      state.bareRootsName = payload;
    },
    SET_BINS_CURRENT_CONTENT(state, payload) {
      state.binsCurrentContent = payload;
    },
    SET_BINS_CURRENT_CONTENT_TO_MOVE(state, payload) {
      state.binsCurrentContentToMove = payload;
    },
    SET_SELECTED_LOCATION_TO_MOVE(state, payload) {
      state.selectedLocationToMove = payload;
    },
    SET_LEVEL_ZERO_LOCATIONS_TO_SEND_BIN(state, payload) {
      state.levelZeroLocationsToSendBin = payload;
    },
    SET_BINS_CONTENT_VARIETIES(state, payload) {
      state.binsContentVarieties = payload;
    },
    SET_BINS_CONTENT_CLASSIFICATIONS(state, payload) {
      state.binsContentClassifications = payload;
    },
    SET_BINS_CONTENT_CATEGORIES(state, payload) {
      state.binsContentCategories = payload;
    },
    SET_BINS_EGRESS(state, payload) {
      state.binEgress = payload;
    },
    SET_BARE_ROOT_CATEGORIES(state, payload) {
      state.bareRootCategories = payload;
    },
    SET_SELECTED_TASK(state, payload) {
      state.selectedTask = payload;
    },
    SET_BINS_CONTENT_PLANNED(state, payload) {
      state.binsContentPlanned = payload;
    },
    setNameBatch(state, value) {
      state.nameBatch = value;
    },
    setDateBatch(state, value) {
      state.dateBatch = value;
    },
    setSelectedBins(state, value) {
      state.selectedBins = value;
    },
    setLotBins(state, value) {
      state.lotBins = value;
    },
    setAllBins(state, value) {
      state.allBins = value;
    },
    setIdBatch(state, value) {
      state.idBatch = value;
    },
    setBinData(state, value) {
      state.binData = value;
    },
    setBinToEdit(state, value) {
      state.binToEdit = value;
    },
    setIdBinToSend(state, value) {
      state.idBinToSend = value;
    },
    setIdBatchToSend(state, value) {
      state.idBatchToSend = value;
    },
    setSelectedFarmToSend(state, value) {
      state.selectedFarmToSend = value;
    },
    SET_BINS_CONTENTS_FOR_PLANNING(state, value) {
      state.binsContentsForPlanning = value;
    },
    SET_BINS_CONTENT_PLANNED_TO_EDIT(state, value) {
      state.binsContentPlannedToEdit = value;
    },
    SET_AVAILABLE_TASKS(state, value) {
      state.availableTasks = value;
    },
    SET_LOCATION_BATCH(state, value) {
      state.locationBatch = value;
    },
    SET_BINS_STOCKS(state, value) {
      state.binsStocks = value;
    },
  },
  actions: {
    setBareRootToEdit({ commit }, payload) {
      commit('SET_BARE_ROOTS_NAME', payload.name);
      commit('SET_BARE_ROOTS_LOCATION_NAME', payload.farm);
      commit('SET_BARE_ROOTS_PLANTING_YEAR', payload.plant_year);
      commit('SET_BARE_ROOTS_VARIETY', payload.variety);
      commit('SET_BARE_ROOTS_CATEGORY', payload.category);
      commit('SET_BARE_ROOTS_CLASS', payload.class_type);
      commit('SET_BARE_ROOTS_PLANTS', payload.numPlants);
      commit('SET_BARE_ROOTS_NOTE', payload.comments);
      commit('SET_BARE_ROOTS_ID', payload.id);
      commit('SET_BARE_ROOTS_CREATION_DATE', payload.creationDate);
      commit('SET_BARE_ROOTS_LOCATION_ID', payload.location);
    },
    updateBinsGuideNumber({ commit }, payload) {
      commit('SET_BINS_GUIDE_NUMBER', payload);
    },
    updateSelectedBareRoots({ commit }, payload) {
      commit('SET_SELECTED_BARE_ROOTS', payload);
    },
    updateSelectedBareRootsToEdit({ commit, state }) {
      commit('SET_SELECTED_BARE_ROOTS', state.binsStocks);
    },
    updateBinsStocks({ commit }, payload) {
      commit('SET_BINS_STOCKS', payload);
    },
    updateBinsLocationName({ commit }, payload) {
      commit('SET_BINS_LOCATION_NAME', payload);
    },
    updateBareRootsLocationName({ commit }, payload) {
      commit('SET_BARE_ROOTS_LOCATION_NAME', payload);
    },
    updateBareRootsPlantingYear({ commit }, payload) {
      commit('SET_BARE_ROOTS_PLANTING_YEAR', payload);
    },
    updateBareRootsVariety({ commit }, payload) {
      commit('SET_BARE_ROOTS_VARIETY', payload);
    },
    updateBareRootsCategory({ commit }, payload) {
      commit('SET_BARE_ROOTS_CATEGORY', payload);
    },
    updateBareRootsClass({ commit }, payload) {
      commit('SET_BARE_ROOTS_CLASS', payload);
    },
    updateBareRootsPlants({ commit }, payload) {
      commit('SET_BARE_ROOTS_PLANTS', payload);
    },
    updateBareRootsNote({ commit }, payload) {
      commit('SET_BARE_ROOTS_NOTE', payload);
    },
    updateBareRootsId({ commit }, payload) {
      commit('SET_BARE_ROOTS_ID', payload);
    },
    updateBareRootsLocationId({ commit }, payload) {
      commit('SET_BARE_ROOTS_LOCATION_ID', payload);
    },
    updateBinsName({ commit }, payload) {
      commit('SET_BINS_NAME', payload);
    },
    updateBareRootsName({ commit }, payload) {
      commit('SET_BARE_ROOTS_NAME', payload);
    },
    updateBinsCurrentContent({ commit }, payload) {
      commit('SET_BINS_CURRENT_CONTENT', payload);
    },
    updateBinsCurrentContentToMove({ commit }, payload) {
      commit('SET_BINS_CURRENT_CONTENT_TO_MOVE', payload);
    },
    updateSelectedLocationToMove({ commit }, payload) {
      commit('SET_SELECTED_LOCATION_TO_MOVE', payload);
    },
    disableBinsLocationSelect({ commit }) {
      commit('DISABLE_BINS_LOCATION_SELECT');
    },
    resetStates({ commit }) {
      commit('SET_FETCHED_BARE_ROOTS_WITHOUT_BIN', []);
      commit('SET_BINS_STOCKS', []);
      commit('SET_SELECTED_BARE_ROOTS', []);
      commit('SET_BINS_GUIDE_NUMBER', '');
      commit('SET_BINS_LOCATION_NAME', '');
      commit('SET_BINS_NAME', '');
      commit('SET_BARE_ROOTS_LOCATION_NAME', '');
      commit('SET_BARE_ROOTS_NAME', '');
      commit('SET_BARE_ROOTS_PLANTING_YEAR', '');
      commit('SET_BARE_ROOTS_VARIETY', '');
      commit('SET_BARE_ROOTS_CATEGORY', '');
      commit('SET_BARE_ROOTS_CLASS', '');
      commit('SET_BARE_ROOTS_PLANTS', '');
      commit('SET_BARE_ROOTS_NOTE', '');
      commit('SET_BARE_ROOTS_ID', '');
      commit('SET_BARE_ROOTS_CREATION_DATE', '');
      commit('SET_BARE_ROOTS_LOCATION_ID', '');
      commit('ENABLE_BINS_LOCATION_SELECT');
      commit('SET_BINS_CURRENT_CONTENT', []);
      commit('setNameBatch', '');
      commit('setDateBatch', '');
      commit('setSelectedBins', []);
      commit('setLotBins', []);
      commit('setAllBins', []);
      commit('setIdBatch', '');
      commit('setIdBatchToSend', '');
      commit('setBinToEdit', {});
      commit('SET_SELECTED_LOCATION_TO_MOVE', '');
      commit('SET_BINS_CURRENT_CONTENT_TO_MOVE', []);
      commit('SET_BINS_CONTENTS_FOR_PLANNING', []);
      commit('SET_SELECTED_TASK', {});
      commit('SET_BINS_CONTENT_PLANNED', []);
      commit('SET_LOCATION_BATCH', '');
    },
    updateSelectedTask({ commit }, payload) {
      commit('SET_SELECTED_TASK', payload);
    },
    updateBinsContentPlanned({ commit }, payload) {
      commit('SET_BINS_CONTENT_PLANNED', payload);
    },
    setBinsContentPlannedToEdit({ commit }, payload) {
      commit('SET_BINS_CONTENT_PLANNED_TO_EDIT', payload);
    },
    updateAvailableTasks({ commit }, payload) {
      commit('SET_AVAILABLE_TASKS', payload);
    },
    setTaskSelectedByTaskCode({ commit, state }, payload) {
      commit('SET_SELECTED_TASK', state.availableTasks.find((task) => task.code === payload));
    },
    async fetchBareRootsWithoutBinBySelectedFarm({
      dispatch, commit, state, getters,
    }) {
      const selectedFarm = getters.locationIdByLocationName(state.binsLocationName);
      if (selectedFarm !== '') {
        try {
          let fetchedBareRootsWithoutBin = '';
          if (getters.binsId) {
            fetchedBareRootsWithoutBin = await Api.getBareRootsWithoutBinsByFarm(selectedFarm, getters.binsId);
          } else {
            fetchedBareRootsWithoutBin = await Api.getBareRootsWithoutBinsByFarm(selectedFarm);
          }
          commit('SET_FETCHED_BARE_ROOTS_WITHOUT_BIN', (JSON.parse(fetchedBareRootsWithoutBin.response)).data);
          dispatch('updateSelectedBareRootsToEdit');
          Promise.resolve();
        } catch (error) {
          Promise.reject(error);
        }
      }
    },
    async fetchLocationTree({ commit }) {
      try {
        const locationTree = await Api.getTreeLocation();
        commit('SET_LOCATIONS_TREE', JSON.parse(locationTree.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentFamilies({ dispatch }) {
      try {
        await dispatch('fetchBinsContentVarieties');
        await dispatch('fetchBinsContentClassifications');
        await dispatch('fetchBinsContentCategories');
        await dispatch('fetchBareRootCategories');
        await dispatch('fetchBinsEgress');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentVarieties({ commit }) {
      try {
        const binsContentVarieties = await Api.getVarieties();
        commit('SET_BINS_CONTENT_VARIETIES', JSON.parse(binsContentVarieties.response).data);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentClassifications({ commit }) {
      try {
        const binsContentClassifications = await Api.getBinsContentClassifications();
        commit('SET_BINS_CONTENT_CLASSIFICATIONS', JSON.parse(binsContentClassifications.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsEgress({ commit }) {
      try {
        const binsEgress = await Api.getBinsEgress();
        commit('SET_BINS_EGRESS', JSON.parse(binsEgress.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBareRootCategories({ commit }) {
      try {
        const bareRootContentCategories = await Api.getBareRootCategories();
        commit('SET_BARE_ROOT_CATEGORIES', JSON.parse(bareRootContentCategories.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsContentCategories({ commit }) {
      try {
        const binsContentCategories = await Api.getBinsContentCategories();
        commit('SET_BINS_CONTENT_CATEGORIES', JSON.parse(binsContentCategories.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsCreateLote({ commit }) {
      try {
        const binsCreateLote = await Api.getBinsCreateLote();
        const xhs = JSON.parse(binsCreateLote.response);
        commit('setAllBins', xhs.data);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async fetchBinsFromLocationSelected({ commit }, payload) {
      try {
        const binsCreateLote = await Api.getBinsCreateLoteByIdLocation(payload);
        const xhs = JSON.parse(binsCreateLote.response);
        commit('setAllBins', xhs.data);
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async createNewBin({ dispatch, getters }) {
      try {
        await Api.createBin(getters.newBinToCreate);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async createNewBareRoot({ dispatch, getters }) {
      try {
        await Api.createBareRoot(getters.newBareRootToCreate);
        dispatch('resetStates');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveEditedBareRoot({ dispatch, getters }) {
      try {
        await Api.editBareRoot(getters.bareRootsId, getters.newBareRootToCreate);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveEditedBin({ dispatch, getters }) {
      try {
        await Api.editBin(getters.binsId, getters.editedBinToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveReclassifiedBin({ dispatch, getters }) {
      try {
        await Api.reclassifyBin(getters.binsId, getters.reclassifiedBinToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async saveBinsContentMoves({ dispatch, getters }) {
      try {
        await Api.moveBinsContent(getters.binsId, getters.binsContentMovesToSave);
        dispatch('resetStates');
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async createBatch({ state, dispatch, getters }) {
      try {
        if (state.idBatch === '') {
          await Api.createBatch(getters.newBatchToCreate);
        } else {
          await Api.updateBatch(state.idBatch, getters.newBatchToCreate);
        }
        dispatch('resetStates');
        Promise.resolve();
      } catch (e) {
        Promise.reject(e);
      }
    },
    async fetchChangeBinStatus({ commit }, payload) {
      let response = '';
      try {
        response = await Api.changeBinState(payload.id, payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchDeleteBareRoot({ commit }, payload) {
      let response = '';
      try {
        response = await Api.deleteBareRoot(payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchDeleteBin({ commit }, payload) {
      let response = '';
      try {
        response = await Api.deleteBin(payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchDeleteBatch({ commit }, payload) {
      let response = '';
      try {
        response = await Api.deleteBatch(payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchSendBatch({ state, getters }) {
      let response = '';
      try {
        const idFarm = getters.locationIdByLocationName(state.selectedFarmToSend);
        const idBatch = state.idBatchToSend;
        const payload = { farm: idFarm, date: moment().format('YYYY-MM-DD') };
        response = await Api.sendBatch(idBatch, payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async fetchSendBin({ state, getters }) {
      let response = '';
      try {
        const idFarm = getters.locationIdByLocationName(state.selectedFarmToSend);
        const idBin = state.idBinToSend;
        const payload = { farm: idFarm };
        response = await Api.sendBin(idBin, payload);
        Promise.resolve();
      } catch (error) {
        response = error;
        Promise.reject(error);
      }
      return response;
    },
    async getLevelZeroLocationsToSendBin({ commit }) {
      try {
        const levelZeroLocationsToSendBin = await Api.getFarmsToSend();
        commit('SET_LEVEL_ZERO_LOCATIONS_TO_SEND_BIN', JSON.parse(levelZeroLocationsToSendBin.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    async getBinsContentsFilteredByLocation({ state, commit }) {
      try {
        const binsContentsForPlanning = await Api.getBinsContentsForPlanning(state.selectedLocationToMove);
        commit('SET_BINS_CONTENTS_FOR_PLANNING', JSON.parse(binsContentsForPlanning.response));
        Promise.resolve();
      } catch (error) {
        Promise.reject(error);
      }
    },
    setNameBatch(context, value) {
      context.commit('setNameBatch', value);
    },
    setDateBatch(context, value) {
      context.commit('setDateBatch', value);
    },
    setSelectedBins(context, value) {
      context.commit('setSelectedBins', value);
    },
    setLotBins(context, value) {
      context.commit('setLotBins', value);
    },
    setAllBins(context, value) {
      context.commit('setAllBins', value);
    },
    setIdBatch(context, value) {
      context.commit('setIdBatch', value);
    },
    setBinData(context, value) {
      context.commit('setBinData', value);
    },
    setBinToEdit(context, value) {
      context.commit('setBinToEdit', value);
    },
    setIdBinToSend(context, value) {
      context.commit('setIdBinToSend', value);
    },
    setIdBatchToSend(context, value) {
      context.commit('setIdBatchToSend', value);
    },
    setSelectedFarmToSend(context, value) {
      context.commit('setSelectedFarmToSend', value);
    },
    setLocationBatch(context, value) {
      context.commit('SET_LOCATION_BATCH', value);
    },
  },
};
