<template>
  <div class="content__giseffort">
    <f7-block v-if="!loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <f7-block v-else>
      <div class="label">
        <span v-text="$t('GisMap_title')" />
      </div>
      <div>
        <!--       <iframe
          id="mapGIS"
          title="GIS MAP"
          :src="`./static/ferrero-gis/gis/level_map${type}.html?baseurl=${baseUrl}&agrifarm=${agriFarm}&init=${init}&end=${end}&token=${token}&locations=${locationsFormated}&farms=${farmsAvailables}`"
          style="min-height: 594px;"
        /> -->
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import EventBus from '../../js/event-bus';
import Api from '../../services/Api';

export default {
  name: 'GisEffort',
  props: {
    init: {
      type: String,
      default: encodeURIComponent(`${moment.utc().startOf('year').format('YYYY-MM-DD')}`),
    },
    end: {
      type: String,
      default: encodeURIComponent(`${moment.utc().add(1, 'd').format('YYYY-MM-DD')}`),
    },
    fixedLevel: {
      type: Number,
      default: -1,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      efforts: {},
      token: encodeURIComponent(`${Api.getToken()}`),
      baseUrl: encodeURIComponent(`${Api.getBaseUrl()}`),
      // agriFarm: encodeURIComponent(`${Api.getAgriFarm()}`),
      locationsFormated: encodeURIComponent(JSON.stringify(this.locations)),
      event: 'updateGIS',
      locationsFixed: [],
    };
  },
  computed: {
    locations() {
      if (this.fixedLevel > 0) {
        this.updateLocationsFixed(this.locationsTree, false);
        return this.locationsFixed;
      }
      return this.selectedFarmList;
    },
    farmsAvailables() {
      return encodeURIComponent(JSON.stringify(this.user.farms));
    },
    ...mapState('Reporting', ['selectedFarmList', 'currentLocation', 'currentLocationName', 'locationsTree']),
    ...mapState('authentication', ['user']),
  },
  mounted() {
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    updateLocationsFixed(locations, selected) {
      for (const location of locations) {
        if (location.level === this.fixedLevel
          && (selected || (this.selectedFarmList.includes(location.bd_id)))) {
          this.locationsFixed.push(location.bd_id);
        } else {
          this.updateLocationsFixed(location.children,
            (selected || (this.selectedFarmList.includes(location.bd_id))));
        }
      }
    },
    async updateLocations() {
      this.loaded = false;
      try {
        this.locationsFixed.length = 0;
        this.locationsFormated = encodeURIComponent(JSON.stringify(this.locations));
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
#mapGIS{
  width:100%;
  height:100%;
  border: none;
  margin-bottom: 15px;
}
.content__giseffort .label {
  font-size: 11px;
  line-height: 1.2;
  padding: 4px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--fr-chocolat);
  margin: 15px 0 3px;
}
</style>
