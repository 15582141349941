import moment from 'moment-timezone';
import i18n from '../../js/i18n.config';
import productFertilizerService from '../../services/productFertilizerService';
import ProductDataFormatter from '../../pages/wizardNutrition/report-formatter/product-data-formatter';

function getTimeValues(dateStart, dateEnd, period) {
  const timeValues = {};
  switch (period) {
    case 'SEMANAL':
      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
        timeValues[varName] = 0;
        dateStart.add(1, 'week');
      }
      break;
    case 'MENSUAL':
    default:
      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
        timeValues[varName] = 0;
        dateStart.add(1, 'month');
      }
      break;
  }
  return timeValues;
}

function generateTemporalFrame(period) {
  // Copied from: src/components/wizardNutrition/stepper/stepper.js
  if (period === 'MENSUAL') {
    return { id: 0, label: '', value: 'MENSUAL' };
  }
  if (period === 'SEMANAL') {
    return { id: 1, label: '', value: 'SEMANAL' };
  }

  throw new Error(`Unexpected period on nutritioStepper.generateTemporalFrame: ${period}`);
}

const state = {

  stepIndex: 0,
  nutritionSteps: [
    {
      label: 'Paso 1', status: 1, complete: false, page: '/utilities/nutrition/nutrition-step-one',
    },
    {
      label: 'Paso 2', status: 0, complete: false, page: '/utilities/nutrition/nutrition-step-two',
    },
    {
      label: 'Paso 3', status: 0, complete: false, page: '/utilities/nutrition/nutrition-step-three',
    },
    {
      label: 'Paso 4', status: 0, complete: false, page: '/utilities/nutrition/nutrition-step-four',
    },
    {
      label: 'Paso 5', status: 0, complete: false, page: '/utilities/nutrition/nutrition-step-five',
    },
  ],
  currentStep: {},
  stepData: {
    referenceValues: [],
    nutritionalNeeds: [],
    nutritionalId: '',
    nutritionalBalanceData: [],
    timeValues: [],
    dosages: [],
    dosageProduct: [],
    dosageProductBalance: [],
    productResume: [],
  },
  enableWatchers: true,
  productsNotDeleted: false,
};

// to handle state
const getters = {
  nutritionSteps: (stateL) => stateL.nutritionSteps,
  stepData: (stateL) => stateL.stepData,
  currentStep: (stateL) => stateL.currentStep,
  stepIndex: (stateL) => stateL.stepIndex,
};

// to handle actions
const actions = {
  nutritionSteps({ commit }) {
    commit('SET_NUTRITION_STEPS', state.nutritionSteps);
  },
  getCurrentStep({ commit }) {
    commit('SET_CURRENT_STEP', state.currentStep);
  },
  getStepIndex({ commit }) {
    commit('SET_STEP_INDEX', state.stepIndex);
  },
  findSelectedIndex(context) {
    for (let i = 0; context.state.nutritionSteps; i += 1) {
      if (context.state.nutritionSteps[i].status === 0) {
        if (i === 0) {
          return 0;
        }
        return i - 1;
      }
    }
    return 0;
  },
  updateEnableWatchers({ commit }, value) {
    commit('SET_ENABLE_WATCHERS', value);
  },
  updateClasses(context) {
    context.state.nutritionSteps.forEach((step, index) => {
      switch (step.status) {
        case 1:
          step.statusClass = 'dot-activate';
          break;
        default:
        case 0:
          step.statusClass = '';
          break;
      }
      if (index < context.state.stepIndex) {
        step.statusClass += ' dot-complete';
      }
    });
    context.state.nutritionSteps[context.state.stepIndex].statusClass += ' dot-current';
  },
  next(context) {
    context.state.stepIndex += 1;

    if (context.state.stepIndex >= context.state.nutritionSteps.length) {
      context.state.stepIndex = context.state.nutritionSteps.length - 1;
    }
    context.state.nutritionSteps[context.state.stepIndex].status = 1;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  back(context) {
    context.state.stepIndex -= 1;
    if (context.state.stepIndex < 0) {
      context.state.stepIndex = 0;
    }
    context.state.nutritionSteps[context.state.stepIndex + 1].status = 0;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  setStep(context, stepIndex) {
    context.state.stepIndex = stepIndex;
    context.state.nutritionSteps.forEach((step) => {
      step.status = 0;
    });

    context.state.nutritionSteps.forEach((step, index) => {
      if (index <= stepIndex) {
        step.status = 1;
      }
    });
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  setStepDataTemporalFrame({ commit }, temporalFrame) {
    commit('SET_STEP_TEMPORAL_FRAME', temporalFrame);
  },
  resetWizard({ commit }) {
    const newStep = {
      endDate: undefined,
      initDate: undefined,
      referenceValues: [],
      nutritionalNeeds: [],
      nutritionalId: '',
      nutritionalBalanceData: [],
      timeValues: [],
      dosages: [],
      dosageProduct: [],
      dosageProductBalance: [],
      productResume: [],
    };

    commit('SET_STEPS_DATA', { ...newStep });
  },
  async updateWizardData({ commit, dispatch }, stepperData) {
    dispatch('Nutrition/setProductsNotInNutritionPlan', [], { root: true });
    const timeValuesTmp = stepperData.contribution_products.map((product) => {
      const timeValues = getTimeValues(moment(stepperData.init_date, 'YYYY/MM/DD').locale('es'),
        moment(stepperData.end_date, 'YYYY/MM/DD').locale('es'),
        stepperData.period);
      let counter = -1;
      for (const prop in timeValues) {
        if (prop) {
          timeValues[prop] = product.periods[counter += 1];
        }
      }
      return timeValues;
    });

    const dosages = timeValuesTmp.map(() => ({ dosages: 0 }));

    const formatter = new ProductDataFormatter(stepperData);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
      dispatch('Nutrition/setProductsNotInNutritionPlan', notFoundNutritionProducts, { root: true });
    }

    formatter.productResume.pop();
    const editStepData = {
      expectedProduction: stepperData.nutritional_needs.production,
      referenceValues: [{
        production: stepperData.reference_values.production,
        n: stepperData.reference_values.n,
        p: stepperData.reference_values.p205,
        k: stepperData.reference_values.k20,
        c: stepperData.reference_values.ca0,
        m: stepperData.reference_values.mg0,
        s: stepperData.reference_values.s03,
      }],
      nutritionalNeeds: [{
        n: stepperData.nutritional_needs_reference.n,
        p: stepperData.nutritional_needs_reference.p205,
        k: stepperData.nutritional_needs_reference.k20,
        c: stepperData.nutritional_needs_reference.ca0,
        m: stepperData.nutritional_needs_reference.mg0,
        s: stepperData.nutritional_needs_reference.s03,
        production: stepperData.nutritional_needs_reference.expected_production,
      },
      {
        n: stepperData.nutritional_needs.n,
        p: stepperData.nutritional_needs.p205,
        k: stepperData.nutritional_needs.k20,
        c: stepperData.nutritional_needs.ca0,
        m: stepperData.nutritional_needs.mg0,
        s: stepperData.nutritional_needs.s03,
        production: stepperData.nutritional_needs.production,
      }],
      nutritionalId: stepperData.id,
      planDescription: stepperData.description,
      initDate: moment(stepperData.init_date, 'YYYY/MM/DD'),
      endDate: moment(stepperData.end_date, 'YYYY/MM/DD'),
      temporalFrame: generateTemporalFrame(stepperData.period),

      nutritionalBalanceData: [
        {
          uf: i18n.t('nutritionalBalance.necNutritional'),
          n: stepperData.nutritional_needs.n,
          p: stepperData.nutritional_needs.p205,
          k: stepperData.nutritional_needs.k20,
          c: stepperData.nutritional_needs.ca0,
          m: stepperData.nutritional_needs.mg0,
          s: stepperData.nutritional_needs.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.availableGround'),
          n: stepperData.uf_available_soil.n,
          p: stepperData.uf_available_soil.p205,
          k: stepperData.uf_available_soil.k20,
          c: stepperData.uf_available_soil.ca0,
          m: stepperData.uf_available_soil.mg0,
          s: stepperData.uf_available_soil.s03,
        },

        {
          uf: i18n.t('nutritionalBalance.irrigationWater'),
          n: stepperData.uf_irrigation_water.n,
          p: stepperData.uf_irrigation_water.p205,
          k: stepperData.uf_irrigation_water.k20,
          c: stepperData.uf_irrigation_water.ca0,
          m: stepperData.uf_irrigation_water.mg0,
          s: stepperData.uf_irrigation_water.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.harvestScraps'),
          n: stepperData.uf_rest_harvest.n,
          p: stepperData.uf_rest_harvest.p205,
          k: stepperData.uf_rest_harvest.k20,
          c: stepperData.uf_rest_harvest.ca0,
          m: stepperData.uf_rest_harvest.mg0,
          s: stepperData.uf_rest_harvest.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.availableTotal'),
          n: stepperData.uf_available_soil.n + stepperData.uf_irrigation_water.n
              + stepperData.uf_rest_harvest.n,
          p: stepperData.uf_available_soil.p205 + stepperData.uf_irrigation_water.p205
              + stepperData.uf_rest_harvest.p205,
          k: stepperData.uf_available_soil.k20 + stepperData.uf_irrigation_water.k20
              + stepperData.uf_rest_harvest.k20,
          c: stepperData.uf_available_soil.ca0 + stepperData.uf_irrigation_water.ca0
              + stepperData.uf_rest_harvest.ca0,
          m: stepperData.uf_available_soil.mg0 + stepperData.uf_irrigation_water.mg0
              + stepperData.uf_rest_harvest.mg0,
          s: stepperData.uf_available_soil.s03 + stepperData.uf_irrigation_water.s03
              + stepperData.uf_rest_harvest.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.advanceFertilization'),
          n: stepperData.uf_available_soil.n + stepperData.uf_irrigation_water.n
              + stepperData.uf_rest_harvest.n - stepperData.nutritional_needs.n,
          p: stepperData.uf_available_soil.p205 + stepperData.uf_irrigation_water.p205
              + stepperData.uf_rest_harvest.p205 - stepperData.nutritional_needs.p205,
          k: stepperData.uf_available_soil.k20 + stepperData.uf_irrigation_water.k20
              + stepperData.uf_rest_harvest.k20 - stepperData.nutritional_needs.k20,
          c: stepperData.uf_available_soil.ca0 + stepperData.uf_irrigation_water.ca0
              + stepperData.uf_rest_harvest.ca0 - stepperData.nutritional_needs.ca0,
          m: stepperData.uf_available_soil.mg0 + stepperData.uf_irrigation_water.mg0
              + stepperData.uf_rest_harvest.mg0 - stepperData.nutritional_needs.mg0,
          s: stepperData.uf_available_soil.s03 + stepperData.uf_irrigation_water.s03
              + stepperData.uf_rest_harvest.s03 - stepperData.nutritional_needs.s03,
        },
      ],
      timeValues: timeValuesTmp,
      dosages,
      dosageProduct: await Promise.all(stepperData.contribution_products.map(async (product) => {
        let productService;
        try {
          productService = await productFertilizerService.getById(product.product);
        } catch (e) {
          return {
            c: product.ca0,
            id: product.product,
            k: product.k20,
            m: product.mg0,
            n: product.n,
            name: product.product_name,
            p: product.p205,
            s: product.s03,
            price: product.price,
            totalCost: product.total_cost,
            unitCost: product.unit_cost,
            isProductDeleted: true,
          };
        }

        return {
          c: product.ca0,
          id: product.product,
          k: product.k20,
          m: product.mg0,
          n: product.n,
          name: productService.name,
          p: product.p205,
          s: product.s03,
          price: product.price,
          totalCost: product.total_cost,
          unitCost: product.unit_cost,
        };
      })),
      dosageProductBalance: [{
        uf: i18n.t('nutritionalBalance.advanceFertilization'),
        n: stepperData.uf_available_soil.n + stepperData.uf_irrigation_water.n
            + stepperData.uf_rest_harvest.n - stepperData.nutritional_needs.n,
        p: stepperData.uf_available_soil.p205 + stepperData.uf_irrigation_water.p205
            + stepperData.uf_rest_harvest.p205 - stepperData.nutritional_needs.p205,
        k: stepperData.uf_available_soil.k20 + stepperData.uf_irrigation_water.k20
            + stepperData.uf_rest_harvest.k20 - stepperData.nutritional_needs.k20,
        c: stepperData.uf_available_soil.ca0 + stepperData.uf_irrigation_water.ca0
            + stepperData.uf_rest_harvest.ca0 - stepperData.nutritional_needs.ca0,
        m: stepperData.uf_available_soil.mg0 + stepperData.uf_irrigation_water.mg0
            + stepperData.uf_rest_harvest.mg0 - stepperData.nutritional_needs.mg0,
        s: stepperData.uf_available_soil.s03 + stepperData.uf_irrigation_water.s03
            + stepperData.uf_rest_harvest.s03 - stepperData.nutritional_needs.s03,
      }],
      productResume: formatter.productResume,
    };

    // Este codigo se puede volver a reutilizar en el caso de que se quieran eliminar las dosificaciones al editar
    /**    if (rootState.Nutrition.productsNotInNutritionPlan.length > 0) {
      editStepData.dosageProduct.forEach((dosage, index) => {
        if (!dosage) {
          editStepData.dosages.splice(index, 1);
          editStepData.timeValues.splice(index, 1);
          editStepData.dosageProduct.splice(index, 1);
        }
      });
    } */
    commit('SET_STEPS_DATA', { ...editStepData });

    //   contribution_products: this.stepData.dosageProduct.map((product, index) => {
    //     product.product = product.id;
    //     product.p205 = product.p;
    //     product.k20 = product.k;
    //     product.ca0 = product.c;
    //     product.mg0 = product.m;
    //     product.s03 = product.s;
    //     product.periods = [];
    //     for (const prop in this.stepData.timeValues[index]) {
    //       product.periods.push(this.stepData.timeValues[index][prop]);
    //     }
    //     product.total_cost = this.stepData.productResume[index].costTotal;
    //     product.unit_cost = this.stepData.productResume[index].costUnit;
    //     product.price = this.stepData.productResume[index].price;
    //     return product;
    //   }),
    // }
  },
  setStepDataInitDate({ commit }, initDate) {
    commit('SET_STEP_INIT_DATE', initDate);
  },
  setStepDataEndDate({ commit }, endDate) {
    commit('SET_STEP_END_DATE', endDate);
  },
  setProductsNotDeleted({ commit }, value) {
    commit('setProductsNotDeleted', value);
  },

};

// to handle mutations
const mutations = {
  SET_NUTRITION_STEPS(stateL, nutritionSteps) {
    stateL.nutritionSteps = nutritionSteps;
  },
  SET_CURRENT_STEP(stateL, currentStep) {
    stateL.currentStep = currentStep;
  },
  SET_STEP_INDEX(stateL, stepIndex) {
    stateL.stepIndex = stepIndex;
  },
  SET_STEPS_DATA(stateL, stepData) {
    stateL.stepData = stepData;
  },
  SET_ENABLE_WATCHERS(stateL, enableWatchers) {
    stateL.enableWatchers = enableWatchers;
  },
  SET_STEP_TEMPORAL_FRAME(stateL, temporalFrame) {
    stateL.stepData.temporalFrame = temporalFrame;
  },
  SET_STEP_INIT_DATE(stateL, initDate) {
    stateL.stepData.initDate = initDate;
  },
  SET_STEP_END_DATE(stateL, endDate) {
    stateL.stepData.endDate = endDate;
  },
  setProductsNotDeleted(stateL, value) {
    stateL.productsNotDeleted = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
