// import Request from './Request';
// import Api from './Api';

// const company = '609aab9090cca00ff2285aa9';

const plantationService = {

  async getById(id) {
    // const xhr = await Request.async(`${Api.getBaseUrl()}/plantation/${id}`,
    //   { data: {} },
    //   'GET', false,
    //   'application/json',
    //   Api.getHeaderAuth(),
    //   company);
    // return JSON.parse(xhr.response);

    return new Promise((resolve) => {
      resolve({
        id: '609aafbf90cca00ff2285aae',
        name: 'Test Plan 1',
        management: 'Convencional',
        protection: 'test',
        crop_type: 'test',
        init_date: '2021-01-01',
        end_date: '2021-12-31',
        species: 'test',
        plants: 33,
        variety: 'test',
        plants_density: 10,
        cup_diameter: 0,
        farms: [
          '60755c6a457d90305ac5341b',
        ],
        campaign: '6081294990cca074985ffefe',
      });
    });
  },
};

export default plantationService;

export { plantationService };
