var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxForm',{attrs:{"col-count":2}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"code","data-field":_vm.$t('code'),"editor-options":{
        value: _vm.currentProduct.code,
        onValueChanged: _vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('DxProduct_nameCommercial'),"editor-options":{
        value: _vm.currentProduct.name,
        onValueChanged: _vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"ecology","data-field":_vm.$t('DxProduct_ecology'),"editor-options":{
        value: _vm.currentProduct.ecologic,
        onValueChanged: _vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"family","data-field":_vm.$t('DxFormProduct_family_caption'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.productFamilies,
        value: _vm.currentProduct.family,
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"subfamily","data-field":_vm.$t('DxFormProduct_subfamily_caption'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.productSubfamilies,
        value: _vm.currentProduct.subfamily,
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.currentProduct.family === 'PHITOSANITARY' || _vm.currentProduct.family === 'SUBSTRATE',"name":"component","data-field":_vm.$t('DxFormProduct_component_caption'),"editor-options":{
        value: _vm.currentProduct.component,
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"doseUnit","data-field":_vm.$t('DxFormProduct_sapunit_caption'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.doseUnits,
        value: _vm.currentProduct.dosis.unit,
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"MaxDosisPermitida","data-field":_vm.$t('DxFormProduct_maxdosis'),"editor-type":"dxNumberBox","editor-options":{
        showSpinButtons: true,
        mode: 'number',
        value: _vm.currentProduct.dosis.max_dosis,
        showClearButton: true,
        min: '0',
        onValueChanged: _vm.onValueChanged,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"Tipology","data-field":_vm.$t('DxProduct_tipology'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.tipologies,
        value: _vm.currentProduct.tipology,
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"toxicity","data-field":_vm.$t('DxFormProduct_toxicity_caption'),"editor-type":"dxSelectBox","editor-options":{
        items: _vm.toxicities,
        value: _vm.$helpers.getProperty(_vm.currentProduct.properties, 'toxicity'),
        onValueChanged: _vm.onValueChanged,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
      }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"status","data-field":_vm.$t('DxFormProduct_status_caption'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.currentProduct.status,
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"SCSauthorized","data-field":_vm.$t('DxFormProduct_SCS_autorizado'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.formattingValue(_vm.$helpers.getProperty(_vm.currentProduct.properties, 'SCS_authorized')),
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"EUauthorized","data-field":_vm.$t('DxFormProduct_EU_autorizado'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.formattingValue(_vm.$helpers.getProperty(_vm.currentProduct.properties, 'EU_authorized')),
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"preHarvest","data-field":_vm.$t('DxFormProduct_preHarvest'),"editor-type":"dxNumberBox","editor-options":{
        showSpinButtons: true,
        mode: 'number',
        showClearButton: true,
        min: '0',
        value: _vm.$helpers.getProperty(_vm.currentProduct.properties, 'preharvest'),
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"IntervalIn","data-field":_vm.$t('DxFormProduct_currentIn'),"editor-type":"dxNumberBox","editor-options":{
        showSpinButtons: true,
        mode: 'number',
        value: _vm.$helpers.getProperty(_vm.currentProduct.properties, 'intervalIn'),
        showClearButton: true,
        min: '0',
        onValueChanged: _vm.onValueChanged,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"description","data-field":_vm.$t('DxFormProduct_description_caption'),"editor-type":"dxTextArea","editor-options":{
        value: _vm.$helpers.getProperty(_vm.currentProduct.properties, 'description'),
        onValueChanged: _vm.onValueChangedDescription,
        maxLength: 256,
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"comment","data-field":_vm.$t('DxFormProduct_comment_caption'),"editor-type":"dxTextArea","editor-options":{
        value: _vm.$helpers.getProperty(_vm.currentProduct.properties, 'comment'),
        onValueChanged: _vm.onValueChangedComment,
        maxLength: 256,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }