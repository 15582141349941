<template>
  <div
    v-if="loaded"
    class="content-devx content-dx-list-task-agruped-activity"
  >
    <DxDataGrid
      :data-source="tasksAvailables"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxListTaskAgrupedActivity"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxExport
        :enabled="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="activity"
        :group-index="0"
        :caption="$t('DxListTaskGroupedActivivty_Activity')"
      />
      <DxColumn
        data-field="name"
        :caption="$t('DxListTaskGroupedActivivty_task')"
      />
      <DxColumn
        data-field="code"
        :caption="$t('DxListTaskGroupedActivivty_taskCode')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="performance_unit"
        :caption="$t('DxListTaskGroupedActivivty_performanceUnit')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as overlay from 'devextreme/ui/overlay';
import { mapState } from 'vuex';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxPaging,
  },
  data() {
    return {
      loaded: false,
      tasksAvailables: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
  },
  mounted() {
    overlay.baseZIndex(9999);
    EventBus.$on(this.event, this.updateLocations);
  },
  async beforeMount() {
    try {
      this.tasksAvailables = await this.loadTasksAvailables();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
    this.loaded = true;
  },
  methods: {
    async loadTasksAvailables() {
      const xhr = await Api.fetchTasksDefinitions();
      return JSON.parse(xhr.response).data;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('CurrentActivityEffort');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CurrentActivityEffort.xlsx');
          });
      });
      e.cancel = true;
    },
  },

};
</script>
