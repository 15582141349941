<template>
  <DxForm>
    <DxGroupItem>
      <DxSimpleItem
        name="alterElement"
        editor-type="dxNumberBox"
      >
        <template #default>
          <div>
            <p>{{ $t('DxProduct_secondaryElement') }}</p>
            <DxDataGrid
              id="SecondaryElement"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :column-auto-width="true"
              :data-source="alterElements"
              :customize-columns="customizeColumns"
              @row-updated="onValueChanged"
            >
              <DxEditing
                :allow-updating="allowEditing"
                :select-text-on-edit-start="selectTextOnEditStart"
                :start-edit-action="startEditAction"
                mode="cell"
              />
            </DxDataGrid>
          </div>
        </template>
      </DxSimpleItem>
    </DxGroupItem>
  </DxForm>
</template>

<script>
import { mapActions } from 'vuex';
import {
  DxForm, DxSimpleItem, DxGroupItem,
} from 'devextreme-vue/form';
import { DxDataGrid, DxEditing } from 'devextreme-vue/data-grid';

export default {
  name: 'AlterElements',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxDataGrid,
    DxEditing,
  },
  props: {
    alterElements: {
      type: Array,
      default: () => [],
    },
    allowEditing: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectTextOnEditStart: true,
      startEditAction: 'click',
    };
  },
  methods: {
    customizeColumns(columns) {
      for (let i = 0; i < columns.length; i += 1) {
        columns[i].dataType = 'number';
      }
    },
    onValueChanged(e) {
      const row = e.data;
      for (const cell in row) {
        if (Object.hasOwnProperty.call(row, cell)) {
          if (!row[cell]) {
            row[cell] = 0;
          }
          this.updateCurrentProductProperty({ property: cell, newValue: row[cell] });
        }
      }
    },
    ...mapActions('Product', ['updateCurrentProductProperty']),
  },
};
</script>

<style lang="scss" scoped>
@import './AlterElements.styles.scss';
</style>
