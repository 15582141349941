<template>
  <f7-row>
    <f7-col width="60">
      <DxForm
        id="form"
      >
        <DxSimpleItem
          v-for="(item, index) in itemsPlanningFormated"
          :key="index"
          :data-field="`${index+1} ${$t(item)}`"
          :editor-options="{
            items: itemsSubfamilyAllValues[index],
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: onItemChange,
            value: itemsSubfamilyValuesDefault[index],
          }"
          editor-type="dxSelectBox"
        />
      </DxForm>
    </f7-col>
    <f7-col width="40">
      <DxForm
        id="form2"
      >
        <DxSimpleItem
          v-for="(item, index2) in itemsPlanningFormated"
          :key="index2"
          :data-field="`${index2+1} ${$t('Driver')}`"
          :editor-options="{
            items: nameDriversAvailables,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            onValueChanged: onDriverChange,
            value: nameDriversSelected[index2],
          }"
          editor-type="dxSelectBox"
        />
      </DxForm>
    </f7-col>
  </f7-row>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DxSelectItemsDriver',
  components: {
    DxForm,
    DxSimpleItem,
  },
  props: {
    itemsPlanning: { type: Array, default: () => [] },
    itemsAvailables: { type: Array, default: () => [] },
    itemsWorkOrderSelected: { type: Array, default: () => [] },
    driversAvailables: { type: Array, default: () => [] },
    itemsDriversWorkOrderSelected: { type: Array, default: () => [] },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      itemsPlanningFormated: [],
      idsInSelects: [],
      isReset: false,
      itemsSubfamily: [],
      itemsSubfamilyAllValues: [],
      itemsSubfamilyValuesDefault: [],
      itemsWorkOrder: [],
      itemsDriversWorkOrder: [],
      itemsNoInSelect: [],
      nameDriversAvailables: [],
      nameDriversSelected: [],
      items: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['attachments', 'actualWorkOrder']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    for (const driverAvailable of this.driversAvailables) {
      this.nameDriversAvailables.push(`${driverAvailable.name} ${driverAvailable.surname}`);
    }
    for (let i = 0; i < this.itemsPlanning.length; i += 1) {
      for (let j = 0; j < this.itemsPlanning[i].count; j += 1) {
        this.itemsPlanningFormated.push(this.itemsPlanning[i].type);
      }
    }
    for (const subfamily of this.itemsPlanningFormated) {
      this.itemsSubfamilyAllValues.push(this.getItemsSubfamily(subfamily));
    }
    if (this.itemsWorkOrderSelected.length !== 0) {
      for (const driver of this.itemsDriversWorkOrderSelected) {
        this.nameDriversSelected.push(this.getDriverById(driver.driver));
      }
      this.itemsNoInSelect = this.itemsWorkOrderSelected;
      for (const subfamily of this.itemsPlanningFormated) {
        const item = this.getItemName(subfamily);
        this.itemsSubfamilyValuesDefault.push(item.name);
        this.updateItemsWorkOrder(item.family, item.subfamily, item.id, null, []);
      }
      for (const itemDriver of this.itemsDriversWorkOrderSelected) {
        this.itemsDriversWorkOrder.push({ item: itemDriver.item, driver: itemDriver.driver });
      }

      this.setItemsWorkOrder(this.itemsWorkOrder);
      this.setItemsDriversWorkOrder(this.itemsDriversWorkOrder);
    } else {
      for (let i = 0; i < this.itemsPlanningFormated.length; i += 1) {
        this.itemsSubfamilyValuesDefault.push('');
        this.itemsDriversWorkOrder.push({ item: '', driver: '' });
      }
      for (const driver of this.itemsDriversWorkOrder) {
        this.nameDriversSelected.push(this.getDriverById(driver.driver));
      }
      this.setItemsDriversWorkOrder(this.itemsDriversWorkOrder);
    }
    this.setmachinarySelected(this.items);
  },
  methods: {
    onItemChange(e) {
      const posItemSelected = e.element.getElementsByTagName('input')[0].getAttribute('name').split(' ')[0] - 1;
      const subfamilySelected = this.itemsPlanningFormated[posItemSelected];
      // const familySelected = this.getFamilyItemSelected(subfamilySelected);
      const familySelected = 'VEHICLE';
      const itemsSubfamily = this.itemsAvailables.filter((item) => item.id === subfamilySelected);
      this.itemsDriversWorkOrder[posItemSelected].item = this.getIdItemSelected(e.value, itemsSubfamily[0].items); // 0 porque el filtro debe encontrar solo 1
      this.items.splice(posItemSelected, 0, this.itemsDriversWorkOrder[posItemSelected].item); // añado al array los ids de maquinaria que quiero mostrar en la tabla attachment
      if (this.items.length > this.itemsDriversWorkOrder.length) {
        this.items.splice(posItemSelected + 1, 1); // una vez rellenados todas los vehiculos, si cambiamos, eliminamos el que cambiamos
      }
      this.setmachinarySelected(this.items); // se envia a vuex los vehiculos que marcamos
      if (e.value === null) {
        this.deleteItemWorkOrder(familySelected, subfamilySelected, this.getIdItemSelected(e.previousValue, itemsSubfamily[0].items));
      } else if (!this.updateItemsWorkOrder(familySelected, subfamilySelected, this.getIdItemSelected(e.value, itemsSubfamily[0].items), e.previousValue, itemsSubfamily[0].items)) {
        this.$f7.dialog.alert(`${e.value} - ${this.$t('DxSelectedItems_ya_esta_seleccionado_alert')}`);
        this.isReset = true;
        e.component.reset();
      }
      this.setItemsWorkOrder(this.itemsWorkOrder);
      this.setItemsDriversWorkOrder(this.itemsDriversWorkOrder);
      this.checkAttachments();
    },
    updateItemsWorkOrder(family, subfamily, idItem, previousValue, itemsSubfamily) {
      if (previousValue !== null && previousValue !== '') {
        this.deleteItemWorkOrder(family, subfamily, this.getIdItemSelected(previousValue, itemsSubfamily));
      }
      if (this.itemsWorkOrder.length === 0) {
        this.itemsWorkOrder.push({ family, subfamily, ids: [idItem] });
        return true;
      }
      for (let i = 0; i < this.itemsWorkOrder.length; i += 1) {
        const item = this.itemsWorkOrder[i];
        if (item.family === family && item.subfamily === subfamily) {
          if (item.ids.indexOf(idItem) === -1) {
            this.itemsWorkOrder[i].ids.push(idItem);
            return true;
          }
          return false;
        }
      }
      this.itemsWorkOrder.push({ family, subfamily, ids: [idItem] });
      return true;
    },
    deleteItemWorkOrder(family, subfamily, previousId) {
      if (!this.isReset) {
        for (let i = 0; i < this.itemsWorkOrder.length; i += 1) {
          const item = this.itemsWorkOrder[i];
          if (item.family === family && item.subfamily === subfamily) {
            if (item.ids.indexOf(previousId) !== -1) {
              this.itemsWorkOrder[i].ids = this.itemsWorkOrder[i].ids.filter((id) => id !== previousId);
              break;
            }
          }
        }
      } else {
        this.isReset = false;
      }
    },
    getItemsSubfamily(subfamily) {
      const fullInfoItemsSubfamily = [];
      const itemsSubfamily = this.itemsAvailables.filter((item) => item.id === subfamily);
      for (let i = 0; i < itemsSubfamily.length; i += 1) {
        for (let j = 0; j < itemsSubfamily[i].items.length; j += 1) {
          const elemento = itemsSubfamily[i].items[j];

          const itemName = `${elemento.description} ${elemento.code}`;
          fullInfoItemsSubfamily.push(itemName);
        }
      }
      return fullInfoItemsSubfamily;
    },
    getItemName(subfamily) {
      const itemsSubfamily = this.itemsAvailables.filter((item) => item.id === subfamily);
      const { items } = itemsSubfamily[0];
      for (let i = 0; i < items.length; i += 1) {
        const elemento = items[i];
        const filtrado = this.itemsNoInSelect.filter((objeto) => objeto.item === elemento.id);
        if (filtrado.length > 0) {
          const nombre = `${elemento.description} ${elemento.code}`;
          this.itemsNoInSelect = this.itemsNoInSelect.filter((item) => item.item !== elemento.id);
          return {
            id: elemento.id,
            name: nombre,
            family: 'TRACTOR',
            subfamily,
          };
        }
      }
      return { id: '', name: '' };
    },
    getIdItemSelected(valueSelect, itemsSubfamily) {
      for (let i = 0; i < itemsSubfamily.length; i += 1) {
        if (valueSelect && valueSelect.includes(itemsSubfamily[i].code)) {
          return itemsSubfamily[i].id;
        }
      }
      return '';
    },
    getFamilyItemSelected(subfamilySelected) {
      for (let i = 0; i < this.itemsAvailables.length; i += 1) {
        if (this.itemsAvailables[i].subfamily === subfamilySelected) {
          return this.itemsAvailables[i].family;
        }
      }
      return '';
    },
    onDriverChange(e) {
      const posDriverSelected = e.element.getElementsByTagName('input')[0].getAttribute('name').split(' ')[0] - 1;
      this.itemsDriversWorkOrder[posDriverSelected].driver = this.getDriverByName(e.value);
      this.setItemsDriversWorkOrder(this.itemsDriversWorkOrder);
    },
    getDriverByName(name) {
      for (const driverAvailable of this.driversAvailables) {
        if (name === `${driverAvailable.name} ${driverAvailable.surname}`) {
          return driverAvailable.id;
        }
      }
      return '';
    },
    getDriverById(id) {
      for (const driverAvailable of this.driversAvailables) {
        if (id === driverAvailable.id) {
          return `${driverAvailable.name} ${driverAvailable.surname}`;
        }
      }
      return '';
    },
    checkAttachments() {
      for (const attachment of this.attachments.values) {
        let exists = false;
        for (const itemDriver of this.actualWorkOrder.itemsDriversWorkOrder) {
          if (attachment.vehicle === itemDriver.item) {
            exists = true;
          }
        }
        if (!exists) {
          attachment.vehicle = '';
          attachment.attachment = '';
        }
      }
      this.setAttachments(this.attachments.values);
    },
    ...mapActions('PlanningManager', ['setItemsWorkOrder', 'setItemsDriversWorkOrder', 'setmachinarySelected', 'setAttachments']),
  },
};
</script>
<style>
.status-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 8px;
}
.middle {
  vertical-align: middle;
}
</style>
