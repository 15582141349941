var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx margin-half"},[_c('f7-block',[_vm._v("\n    "+_vm._s(_vm.$t('LeftPanel_subtitle_price_lonjas'))+"\n  ")]),_vm._v(" "),_c('f7-block',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Slices_simpleItem_slice'),"caption":_vm.$t('Slices_simpleItem_slice'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.allSlices,
            placeholder: _vm.$t('Slices_placeholder_slice'),
            onValueChanged: _vm.onSelectedSlice,
            searchEnabled:true
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Slices_validate_slice')}})],1),_c('DxSimpleItem',{attrs:{"name":"init-date","data-field":_vm.$t('Slices_simpleItem_initDate'),"editor-type":"dxDateBox","editor-options":{
            displayFormat: 'dd/MM/yyyy',
            placeholder: _vm.$t('Slices_placeholder_initDate'),
            invalidDateMessage: _vm.$t('dates_isInvalid'),
            onValueChanged: _vm.onSelectedInitDate,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_init_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('Slices_simpleItem_endDate'),"editor-type":"dxDateBox","editor-options":{
            displayFormat: 'dd/MM/yyyy',
            placeholder: _vm.$t('Slices_placeholder_endDate'),
            invalidDateMessage: _vm.$t('dates_isInvalid'),
            onValueChanged: _vm.onSelectedEndDate,
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1),_vm._v(" "),(_vm.endDate !== '')?_c('DxGroupItem',{attrs:{"col-count":3}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Slices_simpleItem_family'),"caption":_vm.$t('Slices_simpleItem_family'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.allFamilies.families,
            value: _vm.actualFamily,
            placeholder: _vm.$t('Slices_placeholder_family'),
            onValueChanged: _vm.onSelectedFamily,
            searchEnabled:true
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Slices_validate_family')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Slices_simpleItem_product'),"caption":_vm.$t('Slices_simpleItem_product'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.aLLProduct,
            value: _vm.actualProduct,
            placeholder: _vm.$t('Slices_placeholder_product'),
            onValueChanged: _vm.onSelectedProduct,
            searchEnabled:true
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Slices_validate_product')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('Slices_simpleItem_origin'),"caption":_vm.$t('Slices_simpleItem_origin'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.allOrigins,
            value: _vm.actualOrigin,
            placeholder: _vm.$t('Slices_placeholder_origin'),
            onValueChanged: _vm.onSelectedOrigin,
            searchEnabled:true
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Slices_validate_origin')}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }