<template>
  <div class="content-devx">
    <f7-block-title>
      <span>{{ `${$t('parcel.register.title')}` }}</span>
    </f7-block-title>
    <f7-block>{{ $t('parcel.register.step1') }}</f7-block>
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :show-colon-after-label="false"
      >
        <DxGroupItem>
          <DxGroupItem :col-count="2">
            <DxSimpleItem
              :data-field="$t('parcel.register.name.field')"
              name="name"
              :caption="$t('parcel.register.name.caption')"
              :editor-options="{
                value: currentName,
                mode: 'text',
                placeholder: $t('parcel.register.name.placeholder'),
                showClearButton: true,
                onValueChanged: onNameChanged,
              }"
            >
              <DxRequiredRule :message="$t('parcel.register.name.requiredRule')" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapActions } from 'vuex';

export default {
  name: 'RegisterParcelStep1',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    currentName: {
      type: String,
      default: '',
    },
  },
  methods: {
    onNameChanged(e) {
      this.setCurrentName(e.value);
    },
    ...mapActions('Parcel', ['setCurrentName']),
  },
};
</script>
<style>
@import './RegisterParcelStep1.styles.scss';
</style>
