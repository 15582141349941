<template>
  <div v-if="loaded">
    <DxPopup
      :visible="showPopUp"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :show-close-button="true"
      :width="500"
      height="auto"
      :title="popUpTitle"
    >
      <template>
        <div class="content-form">
          <DxForm>
            <DxSimpleItem
              :data-field="$t('taskByCompany_group')"
              :caption="$t('taskByCompany_group')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: taskActivity,
                value: taskActivitySelected,
                readOnly: isView,
                searchEnabled: true,
                placeholder: $t('taskByCompany_placeholder_group'),
                onValueChanged: onSelectedGroupTask,
              }"
            />
            <DxSimpleItem
              :data-field="$t('taskByCompany_Task')"
              :caption="$t('taskByCompany_Task')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: taskDefinition,
                value: taskDefinitionSelected,
                displayExpr: 'name',
                valueExpr: 'id',
                readOnly: isView,
                searchEnabled: true,
                placeholder: $t('taskByCompany_placeholder_task'),
                onValueChanged: onSelectedTask,
              }"
            />
            <DxSimpleItem
              :data-field="$t('taskByCompany_init_date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeInitDate,
                displayFormat: 'dd/MM/yyyy',
                value: initDate,
                readOnly: isView,
                placeholder: $t('taskByCompany_placeholder_init_date'),
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_init_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('taskByCompany_end_date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeEndDate,
                displayFormat: 'dd/MM/yyyy',
                value: endDate,
                readOnly: isView,
                placeholder: $t('taskByCompany_placeholder_init_date'),
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('taskByCompany_description')"
              :caption="$t('taskByCompany_description')"
              editor-type="dxTextArea"
              :editor-options="{
                onValueChanged: onChangeDescription,
                value: description,
                readOnly: isView,
                placeholder: $t('taskByCompany_placeholder_description'),
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
          </DxForm>
        </div>
      </template>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          v-if="!isView"
          class="dx-btn-cancel margin-right"
          raised
          type="success"
          styling-mode="contained"
          @click="validateForm"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-block-footer>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';

export default {
  name: 'DxPopUpRegisterCampania',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    popUpTitle() {
      if (this.isEdit) {
        return this.$t('Configuration.Edit_Task');
      }
      return this.$t('Configuration.Register_Task');
    },
    ...mapState('TaskByCompany', ['showPopUp', 'taskActivity', 'taskDefinition', 'actualTask',
      'initDate', 'endDate', 'description', 'taskActivitySelected', 'taskDefinitionSelected',
      'isEdit', 'isView']),
  },
  beforeMount() {
    this.loaded = false;
    this.initStore();
    this.$f7.preloader.show();
    this.getTasksForActivity();
    this.loaded = true;
    this.$f7.preloader.hide();
  },
  methods: {
    onHidden() {
      this.setDescription('');
      this.initStore();
      this.setIsView(false);
      this.setIsEdit(false);
      this.setShowPopUp(false);
    },
    validateForm() {
      if (this.taskDefinitionSelected === '') {
        this.$f7.dialog.alert(this.$t('taskByCompany_invalid_task_selection'));
      } else if (this.description === '') {
        this.$f7.dialog.alert(this.$t('taskByCompany_description_validation'));
      } else {
        this.goToBack();
      }
    },
    async goToBack() {
      try {
        if (this.isEdit) {
          await this.updateTask();
          this.setIsEdit(false);
        } else {
          await this.createTask();
        }
        this.setShowPopUp(false);
        this.setDescription('');
        this.$f7.views.main.router.navigate('/searcher/tasks/', { reloadCurrent: true });
        return Promise.resolve();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(error));
        return Promise.reject(this.$t(error));
      }
    },
    onChangeInitDate(e) {
      this.setInitDate(moment(e.value).format('YYYY-MM-DD'));
    },
    onChangeEndDate(e) {
      this.setEndDate(moment(e.value).format('YYYY-MM-DD'));
    },
    onSelectedGroupTask(e) {
      this.seTaskActivitySelected(e.value);
      this.getTasksForDefinition(e.value);
      this.seTaskDefinitionSelected('');
    },
    onSelectedTask(e) {
      this.seTaskDefinitionSelected(e.value);
    },
    onChangeDescription(e) {
      this.setDescription(e.value);
    },
    ...mapActions('TaskByCompany', ['setShowPopUp', 'createTask', 'getTasksForActivity', 'getTasksForDefinition',
      'seTaskActivitySelected', 'seTaskDefinitionSelected', 'initStore', 'setDescription', 'setInitDate',
      'setEndDate', 'setIsEdit', 'updateTask', 'setIsView']),
  },
};
</script>

<style>
@import '../registerTask.styles.scss';
</style>
