var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-location"},[_c('DxForm',[(_vm.showGrouped)?_c('DxSimpleItem',{attrs:{"css-class":"content-location__grouped","data-field":_vm.$t('PlanningComponent_grouped_caption'),"name":"grouped","caption":_vm.$t('PlanningComponent_grouped_caption'),"editor-type":"dxCheckBox","editor-options":{
        value: _vm.groupedSelected,
        onValueChanged: _vm.groupedSelectedEvent,
        readOnly: _vm.isTaskOrderCompleted,
      }}}):_vm._e()],1),_vm._v(" "),_c('DxTreeList',{ref:"treeList",staticClass:"tree-planning__treelist",style:(("max-height: " + _vm.maxHeight + "px")),attrs:{"id":"locationsTree","data-source":_vm.locationsTreeFormated,"show-row-lines":true,"show-borders":true,"column-auto-width":true,"expanded-row-keys":_vm.expandedRowKeys,"selected-row-keys":_vm.selectedRowKeys,"key-expr":"ID","parent-id-expr":"HEAD_ID","disabled":_vm.isTaskOrderCompleted},on:{"update:selectedRowKeys":function($event){_vm.selectedRowKeys=$event},"update:selected-row-keys":function($event){_vm.selectedRowKeys=$event},"selection-changed":_vm.saveLocations}},[(_vm.editable)?_c('DxSelection',{attrs:{"recursive":_vm.recursive,"mode":_vm.selectionMode}}):_vm._e(),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"location"}})],1),_vm._v(" "),(_vm.isNurseryURL)?_c('f7-block',[_c('p',[_vm._v(_vm._s(_vm.$t('Nursery_OnlyOneLastLevelMessage')))])]):_vm._e(),_vm._v(" "),_c('f7-block',{staticClass:"content-summary no-padding"},[(_vm.showAreasSummary)?_c('DxForm',[_c('DxGroupItem',{staticClass:"tree-planning__summary",attrs:{"name":"","caption":_vm.$t('totalArea_title')}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('totalArea_field'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.totalArea.toFixed(2),
            mode: 'number',
            disabled: true,
          }}}),_vm._v(" "),_vm._l((_vm.level1DataArea),function(item,index){return _c('DxSimpleItem',{key:index,attrs:{"data-field":item.name,"editor-type":"dxNumberBox","editor-options":{
            value: item.area.toFixed(2),
            mode: 'number',
            disabled: true,
          }}})})],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }