<template>
  <div class="content-devx no-margin margin padding-bottom">
    <f7-block-title>
      {{ $t('laboratoryAnalysis.viewDetail.title_view_deatils') }}
      <div class="icon-download">
        <DxButton
          icon="download"
          @click="openFile(actualLaboratoryAnalysis.attachment)"
        />
      </div>
    </f7-block-title>
    <f7-row>
      <f7-col>
        {{ $t('laboratoryAnalysis.viewDetail.date') }}
      </f7-col>
      <f7-col>
        {{ $t('laboratoryAnalysis.viewDetail.sampleType') }}
      </f7-col>
      <f7-col>
        {{ $t('laboratoryAnalysis.viewDetail.interpretation') }}
      </f7-col>
      <f7-col>
        {{ $t('laboratoryAnalysis.viewDetail.description') }}
      </f7-col>
    </f7-row>
    <f7-row class="bold">
      <f7-col>
        {{ actualLaboratoryAnalysis.date }}
      </f7-col>
      <f7-col>
        {{ actualLaboratoryAnalysis.sample_type }}
      </f7-col>
      <f7-col>
        {{ actualLaboratoryAnalysis.interpretation }}
      </f7-col>
      <f7-col>
        {{ actualLaboratoryAnalysis.description }}
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import { Device } from 'framework7/framework7-lite.esm.bundle';

export default {
  name: 'ViewLaboratoryAnalysis',
  components: {
    DxButton,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Nutrition', ['actualLaboratoryAnalysis']),
  },
  methods: {
    openFile(urlFile) {
      if (Device.desktop) {
        const that = this;
        window.URL = window.URL || window.webkitURL;
        const xhr = new XMLHttpRequest();
        const a = document.createElement('a');
        let file;

        const fileName = urlFile.split('/').pop().split('?')[0];
        const fileExt = fileName.split('.').pop();

        xhr.open('GET', urlFile, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          file = new Blob([xhr.response], { type: 'application/octet-stream' });
          a.href = window.URL.createObjectURL(file);
          a.download = `${that.actualLaboratoryAnalysis.description}.${fileExt}`;
          a.click();
        };
        xhr.send();
      } else {
        this.downloadForMobile(urlFile);
      }
    },
    checkForReadWritePermissions() {
      const { permissions } = cordova.plugins;
      const list = [
        permissions.READ_EXTERNAL_STORAGE,
        permissions.WRITE_EXTERNAL_STORAGE,
      ];
      permissions.hasPermission(list, success, null);

      function error() {
        this.$f7.dialog.alert(this.$t('PermissionsReadWrite'));
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            (status) => {
              if (!status.hasPermission) error();
            },
            error,
          );
        }
      }
    },

    downloadForMobile(urlFile) {
      this.checkForReadWritePermissions();
      const path = `${cordova.file.externalRootDirectory}Download/`;
      const fileName = urlFile.split('/').pop().split('?')[0];
      const fileExt = fileName.split('.').pop();
      const filename = `${this.actualLaboratoryAnalysis.description}.${fileExt}`;
      const uri = encodeURI(urlFile);
      const fileTransfer = new FileTransfer();

      fileTransfer.download(
        uri,
        path + filename,
        () => {
          this.$f7.dialog.alert(this.$t('FileDownloadedInDownloads'));
        },
        (error) => {
          if (error.code === 1) {
            this.$f7.dialog.alert(this.$t('PermissionsReadWrite'));
          } else {
            this.$f7.dialog.alert(`Error:${error.code}`);
          }
        },
        false,
        {
          headers: {
            Authorization: 'Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA==',
          },
        },
      );
    },
  },
};
</script>

<style scoped>

@import '../viewLaboratoryAnalysis/viewLaboratoryAnalysis.styles.scss';
</style>
