import Api from '../../services/Api';
import Helpers from '../../js/helpers';
import db from '../../services/DexieDatabase';

export default {
  namespaced: true,
  state: {
    plantations: [],
    currentPlantation: {},
    treeLocations: [],
    id: '',
    plantationsFormatted: [],
    sectorIdLeftComparator: '',
    sectorIdRightComparator: '',
    isPlantationSelected: '',
    oldPlantation: {},
    LeftComparatorCoordinates: [],
    RightComparatorCoordinates: [],
  },
  getters: {
    treeLocationFromExplotation: (state) => state.treeLocations,
  },
  actions: {
    setCurrentPlantation({ commit }, newPlantation) {
      commit('setCurrentPlantation', newPlantation);
    },
    setIdPlantation({ commit }, idPlantation) {
      commit('setIdPlantation', idPlantation);
    },
    async fetchPlantations({ commit }) {
      try {
        const xhr = await Api.getContactPlantation('ACTIVE');

        commit('setPlantations', JSON.parse(xhr.response).data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchPlantationsByDate({ commit }, date) {
      try {
        const xhr = await Api.getContactPlantationByDate('ACTIVE', date.init, date.end);
        commit('setPlantations', JSON.parse(xhr.response).data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchLocationsByPlantation({ state, commit }) {
      try {
        const xhr = await Api.getTreeLocationByCompany(state.currentPlantation.companyId, state.currentPlantation.id);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchLocationsByCompany({ commit }, companyId) {
      try {
        const xhr = await Api.getTreeLocationByCompany(companyId);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchTreeLocationById({ commit }, { companyId, locationId }) {
      try {
        const xhr = await Api.fetchTreeLocationById(companyId, locationId);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchTreeLocationParcelSystemById({ commit }, { companyId, locationId }) {
      try {
        const xhr = await Api.getParcelSystemTreeLocation(companyId, locationId);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchClientLocationsByCoords({ commit }, {
      latitude,
      longitude,
      radio,
    }) {
      try {
        const xhr = await Api.getContactLocations(latitude, longitude, radio);
        commit('setTreeLocations', JSON.parse(xhr.response));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setTreeLocations({ commit }, newTreeLocations) {
      commit('setTreeLocations', newTreeLocations);
    },
    setPlantationsFormatted({ commit }, plantationsFormatted) {
      commit('setPlantationsFormatted', plantationsFormatted);
    },
    setIsPlantationSelected({ commit }, id) {
      commit('setIsPlantationSelected', id);
    },
    setOldPlantation({ commit }, oldPlantation) {
      commit('setOldPlantation', oldPlantation);
    },
    setSectorIdLeftComparator({ commit }, idSector) {
      commit('setSectorIdLeftComparator', idSector);
    },
    setSectorIdRightComparator({ commit }, idSector) {
      commit('setSectorIdRightComparator', idSector);
    },
    updateLeftComparatorPlantationsFormatted({ commit }, id) {
      commit('updateLeftComparatorPlantationsFormatted', id);
    },
    updateRightComparatorPlantationsFormatted({ commit }, id) {
      commit('updateRightComparatorPlantationsFormatted', id);
    },
    updateIsPlantationSelected({ commit }, id) {
      commit('updateIsPlantationSelected', id);
    },
  },
  mutations: {
    setCurrentPlantation(state, newPlantation) {
      state.currentPlantation = newPlantation;
    },
    setPlantations(state, newPlantations) {
      state.plantations = newPlantations;
    },
    setTreeLocations(state, newTreeLocations) {
      state.treeLocations = newTreeLocations;
    },
    setIdPlantation(state, idPlantation) {
      state.id = idPlantation;
    },
    setPlantationsFormatted(state, value) {
      state.plantationsFormatted = value;
    },
    setSectorIdLeftComparator(state, value) {
      state.sectorIdLeftComparator = value;
    },
    setIsPlantationSelected(state, value) {
      state.isPlantationSelected = value;
    },
    setOldPlantation(state, value) {
      state.oldPlantation = value;
    },
    setSectorIdRightComparator(state, value) {
      state.sectorIdRightComparator = value;
    },
    updateLeftComparatorPlantationsFormatted(state, id) {
      for (const plantation of state.plantationsFormatted) {
        if (plantation.id === id) {
          if (!plantation.sectorIdLeftComparator) {
            plantation.sectorIdLeftComparator = true;
            state.sectorIdLeftComparator = plantation.sectorId;
            state.LeftComparatorCoordinates = plantation.coordinates;
          } else {
            plantation.sectorIdLeftComparator = false;
            state.sectorIdLeftComparator = '';
            state.LeftComparatorCoordinates = [];
          }
        } else {
          plantation.sectorIdLeftComparator = false;
        }
      }
    },
    updateIsPlantationSelected(state, id) {
      for (const plantation of state.plantationsFormatted) {
        if (plantation.id === id) {
          if (!plantation.isPlantationSelected) {
            plantation.isPlantationSelected = true;
            state.isPlantationSelected = plantation.sectorId;
          } else {
            plantation.isPlantationSelected = false;
            state.isPlantationSelected = '';
          }
        } else {
          plantation.isPlantationSelected = false;
        }
      }
    },
    updateRightComparatorPlantationsFormatted(state, id) {
      for (const plantation of state.plantationsFormatted) {
        if (plantation.id === id) {
          if (!plantation.sectorIdRightComparator) {
            plantation.sectorIdRightComparator = true;
            state.sectorIdRightComparator = plantation.sectorId;
            state.RightComparatorCoordinates = plantation.coordinates;
          } else {
            plantation.sectorIdRightComparator = false;
            state.sectorIdRightComparator = '';
            state.RightComparatorCoordinates = [];
          }
        } else {
          plantation.sectorIdRightComparator = false;
        }
      }
    },
  },
};
