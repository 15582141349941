<template>
  <section

    class="treatment-create-order"
  >
    <div class="main-container">
      <div class="content-devx treatment-order-form">
        <div class="card-header">
          {{ $t('treatment.treatmentEditOrder.form.title') }}
        </div>
        <div class="card-body">
          <section class="data-form">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.form.description') }}
              </div>
              <div class="dx-field-value-static">
                <DxTextBox
                  v-model="treatmentOrder.description"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.form.dosage') }}
              </div>
              <div class="dx-field-value">
                <DxSelectBox
                  v-model="dosage"
                  :items="dosages"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.form.date') }}
              </div>
              <div class="dx-field-value">
                <DxDateBox
                  v-model="treatmentOrder.date"
                  display-format="dd/MM/yyyy"
                  width="100%"
                  type="date"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.form.plagueAgent') }}
              </div>
              <div class="dx-field-value">
                <DxSelectBox
                  v-model="treatmentOrder.plagueAgent"
                  :items="optionsPlagueFilter"
                  :search-enabled="true"
                  :placeholder="$t('treatment.treatmentEditOrder.form.plagueAgentPlaceholder')"
                  :no-data-text="$t('No_Data')"
                  :on-value-changed="onChangePlague"
                  width="auto"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.form.treatmentType') }}
              </div>
              <div class="dx-field-value">
                <DxSelectBox
                  v-model="treatmentType"
                  :items="treatmentTypes"
                />
              </div>
            </div>
            <div class="dx-field">
              <div
                v-if="treatmentTypeIsFoliar"
                class="dx-field-label"
              >
                {{ $t('Liquid_Vol') }}
              </div>
              <div
                v-else
                class="dx-field-label"
              >
                {{ $t('treatment.treatmentCreateOrder.form.volume') }}
              </div>
              <div class="dx-field-value-static">
                <DxNumberBox
                  v-model="treatmentOrder.volume"
                  @value-changed="treatmentOrderVolumeChanged"
                />
              </div>
              <span>{{ $t('treatment.treatmentEditOrder.form.lHa') }}</span>
            </div>
          </section>
        </div>
      </div>
      <div class="content-devx treatment-order-product-list">
        <div class="card-header">
          {{ $t('treatment.treatmentEditOrder.products.title') }}
        </div>
        <div class="card-body">
          <section class="search">
            <div class="dx-field volume">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.products.area') }}
              </div>
              <div class="dx-field-value-static">
                <DxNumberBox
                  v-model="treatmentOrder.area"
                  :read-only="true"
                />
              </div>
              <span>{{ $t('treatment.treatmentEditOrder.products.ha') }}</span>
            </div>
            <div class="dx-field product-finder">
              <div class="dx-field-label">
                {{ $t('treatment.treatmentEditOrder.products.productFinder') }}
              </div>

              <div
                v-if="currentAgent==='TIMAC'"
                class="dx-field-value-static"
              >
                <DxAutocomplete
                  :data-source="productVademecums"
                  :value="productVademecumSelectedValue"
                  item-template="product-name"
                  :placeholder="$t('treatment.treatmentEditOrder.products.typeToSearch')"
                  :open-on-field-click="true"
                  :show-drop-down-button="true"
                  value-expr="id"
                  search-expr="name"
                  :show-clear-button="true"
                  @value-changed="selectProductItemAutoComplete"
                >
                  <template #product-name="{ data }">
                    <span>
                      {{ data.name }}
                    </span>
                  </template>
                </DxAutocomplete>
              </div>
              <div
                v-else
                class="dx-field-value-static"
              >
                <DxSelectBox
                  :v-model="currentAgent"
                  :items="productVademecums"
                  :search-enabled="true"
                  value-expr="id"
                  display-expr="name"
                  search-expr="name"
                  :placeholder="$t('treatment.treatmentEditOrder.products.typeToSearch')"
                  :no-data-text="$t('No_Data')"
                  width="auto"
                  @value-changed="selectProductItem"
                />
              </div>
              <DxButton
                :text="$t('dosageProduct.add')"
                type="default"
                @click="addProductFertilizer"
              />
            </div>
          </section>
          <section class="product-table">
            <DxDataGrid
              ref="refDataGrid"
              class="data-grid-balance-value"
              :data-source="productResume"
              :word-wrap-enabled="true"
              :show-borders="true"
              @row-updated="rowUpdated"
              @cell-prepared="onCellPrepared"
            >
              <DxEditing
                :allow-updating="true"
                mode="cell"
              />
              <DxColumn
                data-field="name"
                :caption="$t('treatment.treatmentEditOrder.products.product')"
                alignment="center"
                :allow-sorting="false"
                :allow-editing="false"
              />
              <DxColumn
                :caption="$t('treatment.treatmentEditOrder.products.dosageRange')"
                data-field="dosage_range"
                alignment="center"
                :allow-editing="false"
              />
              <DxColumn

                :caption="$t('treatment.treatmentEditOrder.products.dosageTotalL')"
                data-field="dosageTotalL"
                alignment="center"
                :allow-sorting="false"
                :allow-editing="!dosageIsHA"
                :calculate-display-value="calculateDosageTotalL"
              />

              <DxColumn
                :caption="$t('treatment.treatmentEditOrder.products.dosageTotalKg')"
                data-field="dosageTotalKg"
                alignment="center"
                :allow-sorting="false"
                :allow-editing="dosageIsHA"
                :calculate-display-value="calculateDosageTotalKg"
              />
              <DxColumn
                :caption="$t('productResume.dosageTotalKgL')"
                data-field="dosageTotalKgL"
                data-type="number"
                :allow-sorting="false"
                :allow-editing="false"
                :calculate-display-value="calculateDosageTotalKGL"
              />
              <DxColumn
                :caption="$t('treatment.treatmentEditOrder.products.price')"
                data-field="price"
                alignment="center"
                :allow-sorting="false"
              />
              <DxColumn
                :caption="$t('treatment.treatmentEditOrder.products.unitCost')"
                data-field="unitCost"
                alignment="center"
                :allow-sorting="false"
                :allow-editing="false"
                :calculate-display-value="calculateUnitCost"
              />
              <DxColumn
                :caption="$t('treatment.treatmentEditOrder.products.totalCost')"
                data-field="totalCost"
                alignment="center"
                :allow-sorting="false"
                :allow-editing="false"
                :calculate-display-value="calculateTotalCost"
              />
              <DxColumn
                cell-template="buttonsColumnTemplate"
                alignment="center"
              />

              <template #buttonsColumnTemplate="{ data }">
                <div class="column-buttons">
                  <DxButton
                    styling-mode="text"
                    type="danger"
                    icon="trash"
                    @click="deleteItem(data)"
                  />
                </div>
              </template>
            </DxDataGrid>
            <f7-block>
              <f7-row>
                <f7-col />
                <f7-col />
                <f7-col />
                <f7-col />
                <f7-col />
                <f7-col />
                <f7-col class="alignRightBold">
                  {{ formatedTotalUnitCost }}
                </f7-col>
                <f7-col class="alignRightBold">
                  {{ formatedTotalTotal }}
                </f7-col>
                <f7-col />
                </f7-col>
                </f7-col>
              </f7-row>
            </f7-block>
          </section>
        </div>
      </div>
      <section class="buttons">
        <DxButton
          :text="$t('treatment.treatmentEditOrder.cancel')"
          type="normal"
          @click="cancel"
        />
        <DxButton
          :text="$t('treatment.treatmentEditOrder.save')"
          type="default"
          @click="save"
        />
      </section>
    </div>
  </section>
</template>
<script>

import { DxDateBox } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import numeral from 'numeral';
import moment from 'moment';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import { treatmentOrderService } from '../../../services/treatmentOrderService';
import { keyValuesService } from '../../../services/keyValuesService';

const numeralFormat = '0,0.00';

export default {
  name: 'TreatmentEditOrder',
  components: {
    DxDateBox,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxEditing,
    DxAutocomplete,
  },
  props: ['id'],
  data() {
    return {
      totalUnitCost: 0,
      totalTotal: 0,
      loaded: false,
      totalCost: 0,
      unitCost: 0,
      productVademecums: [],
      productResume: [],

      treatmentTypes: [
        this.$t('treatment.treatmentEditOrder.form.foliar'),
        this.$t('treatment.treatmentEditOrder.form.fertigacion'),
      ],
      dosages: [],
      productVademecumSelected: '',
      treatmentOrder: {},
      treatmentType: '',
      dosage: '',
      currentRow: 0,
      currentAgent: '',
      productVademecumSelectedValue: '',
    };
  },

  computed: {
    formatedTotalUnitCost() {
      return parseFloat(this.totalUnitCost).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    formatedTotalTotal() {
      return parseFloat(this.totalTotal).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    treatmentTypeIsFoliar() {
      return this.treatmentType.toUpperCase() === 'FOLIAR';
    },
    dosageIsHA() {
      return this.dosage === 'KG-L/HA';
    },

    ...mapGetters('productFertilizerStore', ['productFertilizers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('treatmentStepper', ['treatmentProducts', 'treatmentProducts', 'treatmentProduct']),
    ...mapState('Vademecum', ['optionsPlagueFilter']),

  },
  async beforeMount() {
    await this.getOptionsPlagueFilter(this.currentPlantation.species);
  },
  async mounted() {
    await treatmentOrderService.getById(this.id, this.currentPlantation.companyId).then((response) => {
      this.treatmentOrder = response;
      this.currentAgent = response.agent;
      this.treatmentType = response.treatment_type.toUpperCase();
      this.dosage = response.dose_type.toUpperCase();
      this.productResume = response.products.map(({
        product, total_dose, unit_cost, total_cost, ...rest
      }) => ({
        ...rest, name: product, dosageTotalKg: total_dose, unitCost: unit_cost, totalCost: total_cost,
      }));
    });
    this.treatmentOrder.plagueAgent = this.currentAgent;
  },
  async created() {
    this.loaded = false;
    keyValuesService.getByFamily('TREATMENT_TYPE').then((response) => {
      this.treatmentTypes = response[0].values;
    });
    keyValuesService.getByFamily('DOSE_TYPE').then((response) => {
      this.dosages = response[0].values;
      this.dosages = this.dosages.map((dosage) => dosage.toUpperCase());
    });
    this.setArea();
  },

  methods: {
    treatmentOrderVolumeChanged() {
      this.$refs.refDataGrid.instance.saveEditData();
      this.calculateTotals();
    },
    calculateTotals() {
      let total = 0;
      let totalUnit = 0;
      const rows = this.$refs.refDataGrid.instance.getDataSource()._items;
      rows.forEach((row) => {
        if (typeof row !== 'undefined') {
          total += row.totalCost;
          totalUnit += row.unitCost;
        }
      });
      this.totalUnitCost = totalUnit;
      this.totalTotal = total;
    },
    rowUpdated() {
      this.calculateTotals();
    },
    onCellPrepared(e) {
      if (e.rowType === 'data' && e.column.dataField === 'dosageTotalKg' && !this.dosageIsHA) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
      if (e.rowType === 'data' && e.column.dataField === 'dosageTotalL' && this.dosageIsHA) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
      if (e.rowType === 'data' && (e.column.dataField === 'totalCost' || e.column.dataField === 'unitCost' || e.column.dataField === 'name' || e.column.dataField === 'dosageRange' || e.column.dataField === 'dosageTotalKgL')) {
        e.cellElement.style.backgroundColor = 'lightgrey';
      }
    },
    selectProductItem(e) {
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
      }
      if (e.previousValue !== this.productVademecumSelected.id) {
        this.productVademecumSelected = this.productVademecums
          .find((item) => item.id === e.value);
      }
    },
    selectProductItemAutoComplete(e) {
      if (e.value === '' || e.value === null) {
        this.productVademecumSelected = '';
      }
      if (!e.event && e.previousValue !== this.productVademecumSelected.id) {
        this.productVademecumSelected = this.productVademecums
          .find((item) => item.id === e.value);
        this.productVademecumSelectedValue = this.productVademecumSelected.name;
      }
    },
    async addProductFertilizer() {
      if (typeof this.productVademecumSelected.id === 'undefined' || this.productVademecumSelected.id === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.product_validation'));
        return;
      }

      await this.fetchTreatmentProduct({
        id: this.productVademecumSelected.id,
        crop: this.currentPlantation.species,
        agent: this.currentAgent,
      });
      let dosageRange = '';

      if (this.treatmentProduct.dose !== null) {
        dosageRange = `${parseFloat(this.treatmentProduct.dose.min).toFixed(2)} - ${parseFloat(this.treatmentProduct.dose.max).toFixed(2)} (${this.treatmentProduct.dose.unit})`;
      }
      this.productResume.push({
        name: this.treatmentProduct.name,
        dosage_range: dosageRange,
        dosageTotalL: 0,
        dosageTotalKg: 0,
        price: 0,
        unitCost: 0,
        totalCost: 0,
      });
    },

    async onChangePlague(agent) {
      try {
        this.$f7.preloader.show();
        await this.fetchTreatmentProducts({ crop: this.currentPlantation.species, agent: agent.value });

        this.productVademecums = this.treatmentProducts;
        this.productVademecumSelected = '';
        this.productVademecumSelectedValue = '';
        this.currentAgent = agent.value;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    calculateDosageTotalKg(rowData) {
      if (this.dosageIsHA === false || this.loaded === false) {
        rowData.dosageTotalL = parseFloat(rowData.dosageTotalL.toString().replace(/,/g, ''));

        rowData.dosageTotalKg = numeral(parseFloat(rowData.dosageTotalL / 1000) * this.treatmentOrder.volume).format(numeralFormat);
        return numeral(rowData.dosageTotalKg).format(numeralFormat);
      }
      rowData.dosageTotalKg = numeral(rowData.dosageTotalKg).format(numeralFormat);
      return numeral(rowData.dosageTotalKg).format(numeralFormat);
    },
    calculateDosageTotalKGL(row) {
      return parseFloat(row.dosageTotalKg * this.treatmentOrder.area).toFixed(2);
    },
    calculateDosageTotalL(rowData) {
      if (this.dosageIsHA || this.loaded === false) {
        rowData.dosageTotalKg = parseFloat(rowData.dosageTotalKg.toString().replace(/,/g, ''));
        rowData.dosageTotalL = (rowData.dosageTotalKg * 1000) / this.treatmentOrder.volume;
        return numeral(rowData.dosageTotalL).format(numeralFormat);
      }
      rowData.dosageTotalL = numeral(rowData.dosageTotalL).format(numeralFormat);
      return numeral(rowData.dosageTotalL).format(numeralFormat);
    },
    calculateUnitCost(rowData) {
      rowData.unitCost = rowData.price === 0 ? 0 : numeral(rowData.dosageTotalKg).value() * rowData.price;
      return numeral(rowData.unitCost).format(numeralFormat);
    },
    calculateTotalCost(rowData) {
      this.currentRow += 1;
      if (this.productResume.length === this.currentRow) {
        this.loaded = true;
        this.calculateTotals();
        this.$emit('loaded', true);
      }
      rowData.totalCost = numeral(rowData.unitCost).value() * this.treatmentOrder.area;
      return numeral(rowData.totalCost).format(numeralFormat);
    },

    cancel() {
      this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
    },
    validateForm() {
      if (this.treatmentOrder.description === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.description_validation'));
        return false;
      }
      if (typeof this.dosage === 'undefined' || this.dosage === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.dosage_validation'));
        return false;
      }
      if (typeof this.treatmentOrder.date === 'undefined' || this.treatmentOrder.date === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.date_validation'));
        return false;
      } if (typeof this.treatmentType === 'undefined' || this.treatmentOrder.treatmentType === '') {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.treatmentType_validation'));
        return false;
      }
      if (typeof this.productResume === 'undefined' || this.productResume.length <= 0) {
        this.$f7.dialog.alert(this.$t('treatment.treatmentEditOrder.form.productResume_validation'));
        return false;
      }
      return true;
    },
    async save() {
      if (this.validateForm()) {
        try {
          await treatmentOrderService.edit(this.id, {
            plantation: this.currentPlantation.id,
            description: this.treatmentOrder.description,
            date: moment(this.treatmentOrder.date).format('YYYY-MM-DD'),
            treatment_type: this.treatmentType,
            dose_type: this.dosage,
            volume: this.treatmentOrder.volume,
            status: 'PLANIFICADO',
            products: this.productResume.map((item) => ({
              product: item.name,
              total_dose: item.dosageTotalKg,
              price: item.price,
              dosage_range: item.dosage_range,
            })),
          }, this.currentPlantation.companyId);
          this.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      }
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(this.$t('dosageProduct.deleteDialogMessage',
        { product: this.productResume[item.row.rowIndex].name }),
      this.$t('dosageProduct.deleteDialogTitle'),
      () => {
        this.productResume.splice(item.row.rowIndex, 1);
        const that = this;
        setTimeout(() => { that.calculateTotals(); }, 500);
      });
    },
    setArea() {
      this.treatmentOrder.area = parseFloat(parseFloat(this.currentPlantation.area).toFixed(2));
    },
    ...mapActions('treatmentStepper', ['fetchTreatmentProducts', 'fetchTreatmentProduct']),
    ...mapActions('Vademecum', ['getOptionsPlagueFilter']),

  },
};

</script>

<style lang="scss" scoped>
@import './treatmentEditOrder.scss';
</style>
