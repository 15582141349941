import referenceValueService from '../../services/referenceValueService';

// to handle state
const state = {
  referenceValues: [],
  nutritionalNeeds: [],
};

// to handle state
const getters = {
  referenceValues: (stateL) => stateL.referenceValues,
  nutritionalNeeds: (stateL) => stateL.nutritionalNeeds,
};

// to handle actions
const actions = {
  getReferenceValues({ commit }, species) {
    return new Promise((resolve) => {
      referenceValueService.getAll(species)
        .then((response) => {
          const mapData = response.data.map((data) => ({
            production: 1,
            id: data.id,
            n: data.reference_values.n,
            p: data.reference_values.p205,
            k: data.reference_values.k20,
            c: data.reference_values.ca0,
            m: data.reference_values.mg0,
            s: data.reference_values.s03,
          }));
          const mapNeedsData = response.data.map((data) => ({
            production: data.nutritional_needs_reference.production,
            id: data.id,
            n: data.nutritional_needs_reference.n,
            p: data.nutritional_needs_reference.p205,
            k: data.nutritional_needs_reference.k20,
            c: data.nutritional_needs_reference.ca0,
            m: data.nutritional_needs_reference.mg0,
            s: data.nutritional_needs_reference.s03,
            referenceProduction: data.reference_production,
            observation: data.observation,
          }));
          commit('SET_REFERENCE_VALUES', mapNeedsData);
          commit('SET_NUTRITIONAL_NEEDS', mapData);
          resolve(mapData);
        });
    });
  },
};

// to handle mutations
const mutations = {
  SET_REFERENCE_VALUES(stateL, referenceValues) {
    stateL.referenceValues = referenceValues;
  },
  SET_NUTRITIONAL_NEEDS(stateL, nutritionalNeeds) {
    stateL.nutritionalNeeds = nutritionalNeeds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
