<template>
  <f7-block class="no-padding-right">
    <div
      v-if="loaded"
      class="content-devx"
    >
      <f7-block-title>
        <span>{{ `${$t('Campania_title')}` }}</span>
      </f7-block-title>
      <DxDataGrid
        id="gridContainer"
        :data-source="campaignDataFormate"
        :remote-operations="true"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="`${keyName}`"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxSelection
          mode="single"
        />
        <DxPaging :page-size="5" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :highlight-case-sensitive="true"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="name"
          name="name"
          :caption="$t('ParcelSystemCampania_designacion')"
          :allow-header-filtering="true"
          alignment="left"
        />
        <DxColumn
          data-field="status"
          name="status"
          :caption="$t('ParcelSystemCampania_status')"
          alignment="center"
          :allow-header-filtering="true"
          :header-filter="{ dataSource: dataTaskStatus }"
          cell-template="cellTemplate"
        />
        <DxColumn
          :buttons="editButtons"
          type="buttons"
          width="80"
        />
        <DxColumn
          data-field="id"
          :visible="false"
        />
        <template #cellTemplate="{ data: campaignDataFormate }">
          <div :class="setClassByStatus(campaignDataFormate.data.status)">
            {{ campaignDataFormate.data.status }}
          </div>
        </template>
      </DxDataGrid>
    </div>
  </f7-block>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';

export default {
  name: 'ParcelSystemCampania',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  props: {

    keyName: { type: String, default: 'Campania' },
  },
  data() {
    return {
      loaded: false,
      editButtons: [{
        icon: 'find',
        onClick: this.viewCampaign,
      }],
      dataTaskStatus: [{
        text: this.$t('status_campania_active'),
        value: this.$t('status_campania_active'),
      }, {
        text: this.$t('status_campania_end'),
        value: this.$t('status_campania_end'),
      },
      {
        text: this.$t('status_campania_pending'),
        value: this.$t('status_campania_pending'),
      }],
      pageSizes: [5, 10, 20],
      campaignDataFormate: [],
      actualDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapState('Campania', ['name', 'init_date', 'end_date', 'allcampaign']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getCampaignsByCompany();
      for (let i = 0; i < this.allcampaign.length; i += 1) {
        this.campaignDataFormate.push({
          name: this.allcampaign[i].name,
          init_date: this.allcampaign[i].init_date,
          end_date: this.allcampaign[i].end_date,
          status: this.getStatusCampaign(moment(this.allcampaign[i].init_date).format('YYYY-MM-DD'), moment(this.allcampaign[i].end_date).format('YYYY-MM-DD')),
          id: this.allcampaign[i].id,
        });
      }
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {

    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    // SET CSS CLASSES TO CELLS
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    async viewCampaign(e) {
      this.setCampaignId(e.row.data.id);
      this.setIsFromCampaign(true);
      try {
        await this.getCampaignsById(e.row.data.id);
        await this.getPlantationByCompany();
        this.$f7.views.main.router.navigate('/viewCampania/', { reloadCurrent: true });
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ParcelSystemCampania');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ParcelSystemCampania.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initStore();
            this.$f7.views.main.router.navigate('/registerCampania/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Campania', ['getCampaignsByCompany', 'setActualCampaign', 'getPlantationByCompany', 'setCampaignId',
      'getCampaignsById', 'setIsView', 'initStore', 'setIsFromCampaign']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}


.Activa {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
    border-style: solid;
    border-color: #219653;
}
.Finalizada {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
    border-style: solid;
    border-color: #74838C;
}
.Pendiente {
  color: #23465E;
  background: white;
  border-width: 1px;
    border-style: solid;
    border-color: #23465E;
}
.Activa, .Finalizada, .Pendiente {
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 2px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 2!important;
}
</style>
