<template>
  <f7-page>
    <navbar :text="$t('DxHarvestActivitiesReport_menu_title')" />
    <div
      v-if="loaded"
      class="content-devx content-dx-worker main-dx-logs"
    >
      <CalendarInitEndDate
        class="margin-top-half"
        style="position: relative; justify-content: flex-start;"
        :key-name="keyName"
      />

      <DxTreePlanning
        :show-grouped="false"
        :show-summary="false"
        :max-height="300"
        :locations-tree="locationTree"
        :location-selected="actualLocationsSelected"
      />

      <!-- TODO: Cuando se arregle el problema de GIS se pondrá
        <f7-col width="50">
          <GisReporting
            :location-selected="actualLocationsSelected"
            :date-init="checkedInitEndDate.init"
            :date-end="checkedInitEndDate.end"
          />
        </f7-col>
        -->

      <DxHarvestActivitiesReport
        :reports="reports"
        :locations-tree="locationTree"
      />
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DxHarvestActivitiesReport from '../../components/reporting/DxHarvestActivitiesReport.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import DxTreePlanning from '../../components/DxTreePlanning.vue';
import GisReporting from '../../components/reporting/GisReporting.vue';

export default {
  name: 'HarvestActivitiesReportPage',
  components: {
    DxHarvestActivitiesReport,
    navbar,
    CalendarInitEndDate,
    DxTreePlanning,
    GisReporting,
  },
  data() {
    return {
      loaded: false,
      logsFormated: [],
      keyName: 'harvestActivitiesReport',
      pageSizes: [5, 10, 20],
      locationTree: [],
      reports: [],
      checkedInitEndDate: {},
    };
  },
  computed: {
    actualLocationsSelectedParents() {
      return this.actualLocationsSelected; // TODO: Se tiene que devolver un listado con los padres de las localizaciones seleccionadas
    },
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('PlanningManager', ['actualLocationsSelected']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      let xhr = await Api.getTreeLocation();
      this.locationTree = JSON.parse(xhr.response);
      xhr = await Api.getReportHarvestByLocations(this.actualLocationsSelected, this.checkedInitEndDate.init, this.checkedInitEndDate.end);
      this.reports = JSON.parse(xhr.response).data;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
};
</script>
