<template>
  <DxTabPanel class="backgroundColor tab-panel-detalle">
    <!-- machinery -->
    <DxItem
      name="DetailTabs_machinary_title"
      :title="$t('DetailTabs_machinary_title')"
      template="machinary-tab"
    />
    <DxDetailTab
      slot="machinary-tab"
      :data="masterDetailData.data.ItemsDetailTab"
      type-tab="machinary"
    />
    <!-- Attachment-->
    <DxItem
      name="DetailTabs_attachment_title"
      :title="$t('DetailTabs_attachment_title')"
      template="attachment-tab"
    />
    <DxDetailTab
      slot="attachment-tab"
      :data="masterDetailData.data.AttachmentsDetailTab"
      type-tab="attachment"
    />
    <!-- tools -->
    <DxItem
      name="DetailTabs_tools_title"
      :title="$t('DetailTabs_tools_title')"
      template="tool-tab"
    />
    <DxDetailTab
      slot="tool-tab"
      :data="nameTools"
      type-tab="tool"
    />
    <!-- Products -->
    <DxItem
      name="DetailTabs_products_title"
      :title="$t('DetailTabs_products_title')"
      template="products-tab"
    />
    <DxDetailTab
      slot="products-tab"
      :data="nameProducts"
      type-tab="products"
    />
    <!-- workers -->
    <DxItem
      name="DetailTabs_workers_title"
      :title="$t('DetailTabs_workers_title')"
      template="workers-tab"
    />
    <DxDetailTab
      slot="workers-tab"
      :data="masterDetailData.data.WorkersDetailTab"
      type-tab="workers"
    />
    <DxItem
      name="DetailTabs_locations_title"
      :title="$t('DetailTabs_locations_title')"
      template="locations-tab"
    />
    <DxDetailTabLocations
      slot="locations-tab"
      :locations="masterDetailData.data.locations"
      :locations-tree="locationsTree"
      type-tab="locations"
    />
    <DxItem
      name="DetailTabs_nursery_title"
      :title="$t('DetailTabs_nursery_title')"
      template="nursery-tab"
    />
    <DxContentBin
      slot="nursery-tab"
      :master-detail-data="masterDetailData"
      type-tab="nursery"
    />
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxDetailTab from './DxDetailTab.vue';
import DxContentBin from '../greenhouse/DxContentBin.vue';
import DxDetailTabLocations from './DxDetailTabLocations.vue';
import Api from '../../services/Api';

export default {
  name: 'DxDetailTabsDailyPlan',
  components: {
    DxTabPanel, DxItem, DxDetailTab, DxDetailTabLocations, DxContentBin,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locationsTree: [],
      nameTools: [],
      nameProducts: [],
    };
  },
  async beforeMount() {
    this.nameToolsFunc();
    this.nameProductsFunc();
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getTreeLocation();
      this.locationsTree = JSON.parse(xhr.response);
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    nameToolsFunc() {
      if (this.masterDetailData.data.Tools.length !== 0) {
        for (let i = 0; i < this.masterDetailData.data.Tools.length; i += 1) {
          this.nameTools.push({
            count: this.masterDetailData.data.Tools[i].count,
            type: this.masterDetailData.data.Tools[i].data.description,
          });
        }
      }
    },
    nameProductsFunc() {
      if (this.masterDetailData.data.products.length !== 0) {
        for (let i = 0; i < this.masterDetailData.data.products.length; i += 1) {
          const productSelected = this.masterDetailData.data.products[i];
          const calcSolutionTotal = (productSelected.solution !== '0.00') ? (parseFloat(productSelected.solution) * parseFloat(this.masterDetailData.data.area_effective)) : 0;
          const selectedObject = this.masterDetailData.data;
          const seletedProduct = this.masterDetailData.data.products[i];
          const quantityPlanned = this.calulateQuantityPlanned(selectedObject, seletedProduct);
          const unitQuantityPlanned = this.calulateUnitQuantityPlanned(selectedObject, seletedProduct);
          this.nameProducts.push({
            count: `${this.$t('DxDetailTabs_products_quantityPlanned')}: ${quantityPlanned} ${unitQuantityPlanned}
            ${this.$t('DxDetailTabs_products_waterPlanned')}: ${calcSolutionTotal.toFixed(0).toString()} L`,
            type: this.masterDetailData.data.products[i].product.description,
          });
        }
      }
    },
    calulateQuantityPlanned(selectedObject, seletedProduct) {
      let quantityPlanned = parseFloat(selectedObject.area_effective * seletedProduct.dosis);
      if (!this.isKilogrameOrliter(seletedProduct)) {
        const dilutionFactor = seletedProduct.solution / 100;
        quantityPlanned = parseFloat((quantityPlanned * dilutionFactor) / 1000).toFixed(2);
      }
      return quantityPlanned;
    },
    calulateUnitQuantityPlanned(selectedObject, seletedProduct) {
      const unitQuantityPlanned = (typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('gr/'))
        ? 'Kg' : 'L';
      return unitQuantityPlanned;
    },
    isKilogrameOrliter(seletedProduct) {
      return typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('l/');
    },
  },

};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
