<template>
  <f7-page>
    <div>
      <navbar :text="`${$t('Configuration.name')} > ${$t('Activity.title')}`" />
      <div
        v-if="loaded"
        class="content-devx"
      >
        <f7-block>{{ $t('Activity.edit_activity') }}</f7-block>
        <ActivityForm
          :current-activity="currentActivity"
        />
      </div>
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel margin-right"
            raised
            type="default"
            text-color="grey"
            styling-mode="outlined"
            @click="goToActivities"
          >
            {{ $t("Go_Back") }}
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button
            class="dx-btn-success"
            raised
            type="success"
            styling-mode="contained"
            @click="callToEditActivity"
          >
            {{ $t("Button_save_text") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ActivityForm from '../../../components/configuration/activities/activityForm/index.vue';

export default {
  name: 'EditActivity',
  components: {
    navbar,
    ActivityForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Activity', ['currentActivity']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getActivityById(this.currentActivity.id);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async callToEditActivity() {
      if (this.currentActivity.name === '') {
        this.$f7.dialog.alert(this.$t('Activity.name_validation'));
      } else if (this.currentActivity.code === '') {
        this.$f7.dialog.alert(this.$t('Activity.code_validation'));
      } else {
        this.$f7.preloader.show();
        try {
          await this.updateActivity(this.currentActivity);
          this.$f7.views.main.router.navigate('/viewActivityDetail/', { reloadCurrent: true });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    goToActivities() {
      this.initStore();
      this.$f7.views.main.router.navigate('/activities/', { reloadCurrent: true });
    },
    ...mapActions('Activity', ['getActivityById', 'updateActivity', 'initStore']),
  },

};
</script>
