<template>
  <div class="content-devx">
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('title_register_plantation')"
        :align-item-labels-in-all-groups="false"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('registerPlantation.specie_botanic')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: speciesAvailables,
              value: specieSelected,
              showClearButton: true,
              searchEnabled:true,
              onValueChanged: onChangeSpecieBotanic,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('registerPlantation.variety')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: varieties,
              displayExpr: 'name',
              valueExpr: 'name',
              value: varietySelected,
              searchEnabled:true,
              onValueChanged: onChangeVariety,
              showDataBeforeSearch: false,
              minSearchLength: 3,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.distance_between_streets')} (m)`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: mulUno,
              onValueChanged: onChangeMulUno,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.distance_between_feets')} (m)`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: mulDos,
              onValueChanged: onChangeMulDos,
            }"
          >
            <DxRequiredRule message="" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="`${$t('registerPlantation.plants_density')}(${$t('registerPlantation.unit_plantas_ha')})`"
            editor-type="dxNumberBox"
            :editor-options="{
              value: calculatePlantsDensity,
              onValueChanged: onChangePlantDensity,
              readOnly: true,
            }"
          />
          <DxSimpleItem
            :data-field="$t('registerPlantation.plants_number')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: calculatePlantsNumber,
              onValueChanged: onChangePlantsNumber,
              readOnly: true,
            }"
          />
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RegisterPlantationHorticola',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      hectareToM2: 10000,
      seedForKg: 500,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['plantationName', 'protectionTypeSelected', 'managementTypeSelected',
      'initDate', 'endDate', 'cropTypeSelected', 'specieSelected', 'varietySelected', 'speciesAvailables', 'varieties',
      'mulUno', 'mulDos', 'sectorArea', 'isEdit', 'actualPlantation']),
    calculatePlantsNumber() {
      if (this.mulUno !== 0 && this.mulDos !== 0) return parseInt((this.sectorArea * this.hectareToM2) / (this.mulUno * this.mulDos), 10);
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      return 0;
    },
    calculatePlantsDensity() {
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      if (this.mulUno !== 0 && this.mulDos !== 0) {
        return parseInt(Math.ceil(10000 / (this.mulUno * this.mulDos)), 10);
      }
      return 0;
    },
  },
  async beforeMount() {
    if (this.isEdit) {
      for (const propertie of this.actualPlantation.farms[0].properties) {
        if (propertie.key === 'area') {
          this.setSectorArea(propertie.value);
        }
      }
      this.setSpecieBotanic(this.actualPlantation.species);
      this.setVariety(this.actualPlantation.variety);
      this.setMulUno(this.actualPlantation.plantation_frame.x);
      this.setMulDos(this.actualPlantation.plantation_frame.y);
      this.setPlantsDensity(this.actualPlantation.plants_density);
      this.setPlantsNumber(this.actualPlantation.plants);
      try {
        await this.getSelectBoxSpecies(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.cropTypeSelected : this.actualPlantation.crop_type);
        await this.getSelectBoxVariety(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.specieSelected : this.actualPlantation.species);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    }
  },
  methods: {
    async onChangeSpecieBotanic(e) {
      this.setSpecieBotanic(e.value);
      try {
        await this.getSelectBoxVariety(e.value);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    onChangeVariety(e) {
      this.setVariety(e.value);
    },
    onChangeMulUno(e) {
      this.setMulUno(e.value);
    },
    onChangeMulDos(e) {
      this.setMulDos(e.value);
    },
    onChangePlantDensity(e) {
      this.setPlantsDensity(e.value);
    },
    onChangePlantsNumber(e) {
      this.setPlantsNumber(e.value);
    },
    ...mapActions('RegisterPlantation', ['setPlantationName', 'setProtectionType', 'setManagementType', 'setInitDate', 'setEndDate', 'setCultiveType',
      'setSpecieBotanic', 'setVariety', 'setPlantsNumber', 'getSelectBoxVariety', 'setMulUno', 'setSectorArea',
      'setMulDos', 'setPlantsDensity', 'initStore', 'getSelectBoxSpecies']),
  },
};
</script>
