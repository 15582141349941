var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-top-half"},[_c('div',{staticClass:"content-devx"},[_c('f7-block',[_vm._v("\n      "+_vm._s(_vm.$t('Title_page_weather'))+"\n    ")]),_vm._v(" "),_c('div',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_client'),"caption":_vm.$t('weather_client'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allClients,
              displayExpr: 'name',
              valueExpr: 'id',
              placeholder: _vm.$t('weather_client_placeholder'),
              onValueChanged: _vm.onSelectedClient,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_campaign'),"caption":_vm.$t('weather_campaign'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.campaignsByClient,
              displayExpr: 'name',
              valueExpr: 'id',
              readOnly:_vm.clientSelected==='',
              placeholder: _vm.$t('registerClima_campaign_placeholder'),
              onValueChanged: _vm.onSelectedCampaign,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_parameters'),"caption":_vm.$t('weather_parameters'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.parameters,
              placeholder: _vm.$t('registerClima_parameter_placeholder'),
              onValueChanged: _vm.onSelectedParameter,
              displayExpr: _vm.displayTranslatedParameter,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"init-date","data-field":_vm.$t('DxRegisterCampania_fecha_inicio'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeInitDate,
              displayFormat: 'dd/MM/yyyy',
              placeholder: _vm.$t('registerClima_initDate_placeholder'),
              invalidDateMessage: _vm.$t('dates_isInvalid'),
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_init_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('DxRegisterCampania_fecha_finalizacion'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeEndDate,
              displayFormat: 'dd/MM/yyyy',
              placeholder: _vm.$t('registerClima_endDate_placeholder'),
              invalidDateMessage: _vm.$t('dates_isInvalid'),
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_plantation'),"caption":_vm.$t('weather_plantation'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allPlantations,
              displayExpr: 'name',
              valueExpr: 'id',
              readOnly:_vm.campaign==='',
              placeholder: _vm.$t('registerClima_parcel_placeholder'),
              onValueChanged: _vm.onSelectedPlantation,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }