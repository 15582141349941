<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning no-shadow no-margin"
    >
      <DxDataGrid
        id="postworkMachinaryDriverTable"
        ref="postworkMachinaryDriverTable"
        :data-source="items.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
        @row-validating="checkItems"
      >
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxPostWorkMachinaryDriverTable"
        />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="updating"
          :allow-deleting="deleting"
          :allow-adding="adding"
          mode="form"
        >
          <DxPopup
            :show-title="true"
            title="Machinary Info"
          >
            <DxPosition
              my="top"
              at="top"
              of="window"
            />
          </DxPopup>
          <DxForm>
            <DxItem
              :col-count="1"
              :col-span="2"
              item-type="group"
            >
              <DxItem
                :col-span="20"
                data-field="type"
                :set-cell-value="actualActiveType"
                editor-type="dxSelectBox"
              />
              <DxItem
                :col-span="20"
                data-field="item"
                editor-type="dxSelectBox"
              >
                <DxLookup
                  :data-source="filteredVehicles"
                />
              </DxItem>
              <DxItem
                :col-span="20"
                data-field="driver"
              >
                <DxLookup
                  :data-source="filteredDrivers"
                />
              </DxItem>
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="km_start"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="km_finish"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="horometer_start"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="horometer_finish"
              />
              <DxItem
                :editor-options="{ showSpinButtons: false,
                                   mode: 'number',
                                   placeholder: '0',
                                   showClearButton: true,
                                   min: '0',
                                   step: '1',
                }"
                editor-type="dxNumberBox"
                data-field="fuel_consumption"
              />
              <DxItem
                data-field="fuel_type"
                :caption="$t('MonthlyComponent_typeconsumption_caption')"
              />
              <DxItem
                :col-span="20"
                data-field="comment"
                editor-type="dxTextArea"
                :editor-options="{
                  maxLength: 256
                }"
              />
            </DxItem>
          </DxForm>
        </DxEditing>
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          :min-width="100"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
          :set-cell-value="actualActiveType"
          alignment="left"
        >
          <DxLookup
            :data-source="types"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="item"
          :min-width="400"
          name="item"
          alignment="left"
          :caption="$t('DxAttachmentMachinary_dxColumn_Machinary')"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="filteredVehicles"
            display-expr="name"
            value-expr="id"
          />
        </DxColumn>
        <DxColumn
          data-field="driver"
          :min-width="250"
          name="driver"
          alignment="left"
          :caption="$t('DxWorkOrders_driver_caption')"
          :set-cell-value="actualActiveDriver"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="filteredDrivers"
            display-expr="name"
            value-expr="id"
          />
        </DxColumn>
        <DxColumn
          data-field="km_start"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateKmStart"
          :editor-options="numberColumnOptions"
          :caption="$t('DxWorkOrders_km_start_caption')"
        />
        <DxColumn
          data-field="km_finish"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateKmFinish"
          :editor-options="numberColumnOptions"
          :caption="$t('DxWorkOrders_km_finish_caption')"
        />
        <DxColumn
          data-field="horometer_start"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateHoroStart"
          :editor-options="numberColumnOptions"
          :caption="$t('DxWorkOrders_horometer_start_caption')"
        />
        <DxColumn
          data-field="horometer_finish"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateHoroFinish"
          :editor-options="numberColumnOptions"
          :caption="$t('DxWorkOrders_horometer_finish_caption')"
        />
        <DxColumn
          :data-field="$t('DxWorkOrders_fuel_horometerDiff_caption')"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateHorometerDiff"
          :editor-options="numberColumnOptions"
        />
        <DxColumn
          data-field="fuel_consumption"
          data-type="number"
          alignment="right"
          :calculate-cell-value="calculateFuel"
          :editor-options="numberColumnOptions"
          :caption="$t('DxWorkOrders_fuel_consumption_caption')"
        />
        <DxColumn
          data-field="fuel_type"
          name="fuel_type"
          alignment="left"
          :caption="$t('MonthlyComponent_typeconsumption_caption')"
        >
          <DxLookup
            :data-source="typeCosumptionAvailable"
            :display-expr="dysplayConsumptionType"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="comment"
          alignment="left"
          :caption="$t('DxWorkOrders_aditional_info_caption')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxForm,
  DxPopup,
  DxTextArea,
  DxRequiredRule,
  DxLookup,
  DxPosition,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import * as overlay from 'devextreme/ui/overlay';
import { DxItem } from 'devextreme-vue/form';
import EventBus from '../../js/event-bus';

export default {
  name: 'DxPostWorkMachinaryDriverTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxForm,
    DxPopup,
    DxItem,
    DxTextArea,
    DxRequiredRule,
    DxLookup,
    DxPosition,
    DxStateStoring,
  },
  props: {
    driversAvailables: { type: Array, default: () => [] },
    itemsAvailables: { type: Array, default: () => [] },
    updating: { type: Boolean, default: () => true },
    deleting: { type: Boolean, default: () => false },
    adding: { type: Boolean, default: () => false },
    types: { type: Array, default: () => [] },
    actualWorkOrder: { type: Object, default: () => {} },
    isTaskOrderCompleted: { type: Boolean, default: false },
  },
  data() {
    return {
      allItems: [],
      allItemsNames: [],
      allTractors: [],
      allDrivers: [],
      typeCosumptionAvailable: ['DIESEL', 'GASOLINE'],
      numberColumnOptions: {
        showSpinButtons: false, min: 0, showClearButton: true,
      },
      actualActiveDriver(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveTractor(rowData, value) {
        rowData.driver = null;
        this.defaultSetCellValue(rowData, value);
      },
      actualActiveType(rowData, value) {
        rowData.driver = null;
        rowData.item = null;
        this.defaultSetCellValue(rowData, value);
      },
      machineryPerformanceFormatted: [],
    };
  },
  computed: {
    ...mapState('PlanningManager', ['items']),
  },
  mounted() {
    overlay.baseZIndex(9999);
    // obtenemos el type de la maquinaría. para edit work order.
    if (this.isTaskOrderCompleted) {
      for (const machinery of this.actualWorkOrder.machinery_performance) {
        let type = '';
        for (const itemDriver of this.actualWorkOrder.items_drivers) {
          if (machinery.item_id === itemDriver.item) {
            type = itemDriver.item_data.subfamily;
          }
        }
        this.machineryPerformanceFormatted.push({
          comment: machinery.comment,
          driver: machinery.driver_id,
          fuel_consumption: machinery.fuel_consumption,
          fuel_type: machinery.fuel_type,
          horometer_finish: machinery.horometer_finish,
          horometer_start: machinery.horometer_start,
          item_id: machinery.item_id,
          item: machinery.item_id,
          km_finish: machinery.km_finish,
          km_start: machinery.km_start,
          working_area: machinery.working_area,
          type,
        });
      }
      this.setItems(this.machineryPerformanceFormatted);
    }
    // Seteamos un listado con los tractores
    for (let i = 0; i < this.itemsAvailables.length; i += 1) {
      const typeMachinary = [];
      this.allItemsNames.push(this.itemsAvailables[i].id);
      for (let j = 0; j < this.itemsAvailables[i].items.length; j += 1) {
        const tractor = this.itemsAvailables[i].items[j];
        const nameTractor = `${tractor.description} ${tractor.code}`;
        const idTractor = tractor.id;
        typeMachinary.push({ id: idTractor, name: nameTractor });
        this.allTractors.push({ id: idTractor, name: nameTractor });
      }
      this.allItems.push(typeMachinary);
    }
    // Seteamos un listado con los conductores
    for (let i = 0; i < this.driversAvailables.length; i += 1) {
      this.allDrivers.push({
        id: this.driversAvailables[i].id,
        name: `${this.driversAvailables[i].name} ${this.driversAvailables[i].surname}`,
      });
    }
  },
  methods: {
    calculateKmStart(rowData) {
      if (typeof (rowData.km_start) === 'undefined' || rowData.km_start === null) return 0;
      return rowData.km_start;
    },
    calculateKmFinish(rowData) {
      if (typeof (rowData.km_finish) === 'undefined' || rowData.km_finish === null) return 0;
      return rowData.km_finish;
    },
    calculateHoroStart(rowData) {
      if (typeof (rowData.horometer_start) === 'undefined' || rowData.horometer_start === null) return 0;
      return rowData.horometer_start;
    },
    calculateHoroFinish(rowData) {
      if (typeof (rowData.horometer_finish) === 'undefined' || rowData.horometer_finish === null) return 0;
      return rowData.horometer_finish;
    },
    calculateFuel(rowData) {
      if (typeof (rowData.fuel_consumption) === 'undefined' || rowData.fuel_consumption === null) return 0;
      return rowData.fuel_consumption;
    },
    dysplayConsumptionType(e) {
      if (e === 'DIESEL') {
        return this.$t('DIESEL');
      }
      return this.$t('GASOLINE');
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.$refs.postworkMachinaryDriverTable.instance.getDataSource().items();
      const newItems = this.items;
      newItems.values = [];
      for (let i = 0; i < arrayItems.length; i += 1) {
        newItems.values.push({
          type: arrayItems[i].type,
          item: arrayItems[i].item,
          driver: arrayItems[i].driver,
          km_start: arrayItems[i].km_start,
          km_finish: arrayItems[i].km_finish,
          horometer_start: arrayItems[i].horometer_start,
          horometer_finish: arrayItems[i].horometer_finish,
          fuel_consumption: arrayItems[i].fuel_consumption,
          fuel_type: arrayItems[i].fuel_type,
          comment: arrayItems[i].comment,
          driver_id: arrayItems[i].driver,
          item_id: arrayItems[i].item,
        });
      }
      this.setItems(newItems.values);
      EventBus.$emit('setMachinery');
    },
    onInsertedRow() {
      const newItems = this.items;
      for (let i = 0; i < newItems.values.length; i += 1) {
        newItems.values[i].driver_id = newItems.values[i].driver;
        newItems.values[i].item_id = newItems.values[i].item;
        newItems.values[i].item = newItems.values[i].item;
        newItems.values[i].working_area = 0;
        if (this.isTaskOrderCompleted) {
          newItems.values[i].comment = this.actualWorkOrder.comment;
        } else {
          newItems.values[i].comment = newItems.values[i].comment;
        }
        this.setItems(newItems.values);
        EventBus.$emit('setMachinery');
      }
    },
    filteredVehicles(e) {
      try {
        if (this.allItemsNames.indexOf(e.values[0]) === -1) {
          return [{ id: '', name: '' }];
        }
        const filtered = this.allItems[this.allItemsNames.indexOf(e.values[0])];
        return filtered;
      } catch (error) {
        return this.allTractors;
      }
    },
    filteredDrivers(e) {
      try {
        if (e.values[0] === undefined) {
          return [{ id: '', name: '' }];
        }
        return this.allDrivers;
      } catch (error) {
        return this.allDrivers;
      }
    },
    onRemovedRow() {
      const newItems = this.items;
      this.setItems(newItems.values);
      EventBus.$emit('setMachinery');
    },
    checkItems(e) {
      let kmStart = e.newData.km_start;
      let kmFinish = e.newData.km_finish;
      let horometerStart = e.newData.horometer_start;
      let horometerFinish = e.newData.horometer_finish;
      if (typeof e.oldData === 'undefined') {
        e.oldData = {
          km_start: 0, km_finish: 0, horometer_start: 0, horometer_finish: 0,
        };
      }
      for (const item of this.items.values) {
        if (typeof e.newData.item !== 'undefined' && item.item_id === e.newData.item) {
          e.errorText = this.$t('DxPostWorkMachinaryDriverTable_machinery_validating');
          e.isValid = false;
        }
      }
      if (typeof (kmStart) === 'undefined' || kmStart === null) {
        if (typeof (kmStart) === 'undefined' && e.oldData.km_start !== 0) {
          e.newData.km_start = e.oldData.km_start;
        } else {
          e.newData.km_start = 0;
        }
      }
      if (typeof (kmFinish) === 'undefined' || kmFinish === null) {
        if (typeof kmFinish === 'undefined' && e.oldData.km_finish !== 0) {
          e.newData.km_finish = e.oldData.km_finish;
        } else {
          e.newData.km_finish = 0;
        }
      }
      if (typeof (horometerStart) === 'undefined' || horometerStart === null) {
        if (typeof (horometerStart) === 'undefined' && e.oldData.horometer_start !== 0) {
          e.newData.horometer_start = e.oldData.horometer_start;
        } else {
          e.newData.horometer_start = 0;
        }
      }
      if (typeof (horometerFinish) === 'undefined' || horometerFinish === null) {
        if (typeof horometerFinish === 'undefined' && e.oldData.horometer_finish !== 0) {
          e.newData.horometer_finish = e.oldData.horometer_finish;
        } else {
          e.newData.horometer_finish = 0;
        }
      }
      kmStart = parseInt(e.newData.km_start, 10);
      kmFinish = parseInt(e.newData.km_finish, 10);
      horometerStart = parseInt(e.newData.horometer_start, 10);
      horometerFinish = parseInt(e.newData.horometer_finish, 10);
      if (kmStart === 0 && kmFinish === 0 && horometerStart === 0 && horometerFinish === 0) {
        e.errorText = this.$t('DxPostWorkMachinaryDriverTable_km_or_horometer_validating');
        e.isValid = false;
      }
      if ((horometerStart === 0 && horometerFinish === 0) && (kmStart !== 0 || kmFinish !== 0)) {
        if ((kmStart >= kmFinish) || (kmStart === 0)) {
          e.errorText = this.$t('DxPostWorkMachinaryDriverTable_km_validating');
          e.isValid = false;
        }
      }
      if ((kmStart === 0 && kmFinish === 0) && (horometerStart !== 0 || horometerFinish !== 0)) {
        if (horometerStart >= horometerFinish || horometerStart === 0) {
          e.errorText = this.$t('DxPostWorkMachinaryDriverTable_horometer_validating');
          e.isValid = false;
        }
      }
    },
    calculateHorometerDiff(rowData) {
      if (typeof (rowData.horometer_finish) !== 'undefined' && rowData.horometer_finish !== null && typeof (rowData.horometer_start) !== 'undefined' && rowData.horometer_start !== null) {
        return rowData.horometer_finish - rowData.horometer_start;
      }
      return 0;
    },
    ...mapActions('PlanningManager', ['setItems']),
  },
};
</script>
