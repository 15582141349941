<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('LeftPanel_title_search_page')" />
    <f7-block
      v-if="loaded"
    >
      <f7-row>
        <f7-col width="100">
          <f7-block
            v-if="!loadedGIS"
            class="text-align-center"
          >
            <div class="padding-top padding-bottom-half">
              {{ $t('searchEngine_gis_view') }}
            </div>
          </f7-block>
          <f7-block v-else>
            <GISViewClients
              :zoom="zoom"
              :name-map="mainMap"
              :tree-locations="treeLocations"
              :center="{latitude, longitude}"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <div class="content-range-date searchFilterDates">
            <CalendarInitEndDate :key-name="keyName" />
          </div>
          <SearchEngine
            :plantations="plantations"
            :go-to-view="'/searcher/home/'"
          />
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SearchEngine from '../../components/searchEngine/index.vue';
import navbar from '../../components/NavBar.vue';
import GISViewClients from '../../components/Gis/GISViewClients/index.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';

export default {
  name: 'SearchEnginePage',
  components: {
    SearchEngine,
    navbar,
    GISViewClients,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      loadedGIS: false,
      mainMap: 'searchEngine',
      radio: 2,
      latitude: null,
      longitude: null,
      zoom: 17,
      keyName: 'searchEngine',
    };
  },
  computed: {
    ...mapState('Plantation', ['plantations', 'treeLocations']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        if (typeof this.actualFilter.searchEngine !== 'undefined') {
          await this.fetchPlantationsByDate({ init: this.actualFilter.searchEngine.init, end: this.actualFilter.searchEngine.end });
        } else {
          await this.fetchPlantations();
        }
        // Establecer geolocalización
        const navigatorOptions = {
          enableHighAccuracy: true,
          maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(async (position) => {
          try {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            await this.fetchClientLocationsByCoords({
              latitude: this.latitude,
              longitude: this.longitude,
              radio: this.radio,
            });
            if (this.treeLocations.length > 0) {
              this.loadedGIS = true;
            }
          } catch (error) {
            this.$f7.dialog.alert(this.$t(`${error}`));
          }
        },
        (error) => {
          this.$f7.dialog.alert(`${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`);
        },
        navigatorOptions);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    ...mapActions('Plantation', ['fetchPlantations', 'fetchPlantationsByDate', 'fetchClientLocationsByCoords']),
  },
};
</script>

<style scoped>
.searchFilterDates{
  position: absolute;
  width: 100%;
  justify-content: flex-start;
  margin: 28px 0 0 8px;
}
</style>
