<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Employee_title')}`" />
    <DxEmployees />
  </f7-page>
</template>

<script>

import DxEmployees from '../../../components/configuration/employees/DxEmployees.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  components: {
    DxEmployees,
    navbar,
  },
};
</script>

<style>

</style>
