<template>
  <DxTextArea
    :value="additionalInfo"
    :on-value-changed="additionalInfoEvent"
    max-length="256"
  />
</template>

<script>
import { mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import DxTextArea from 'devextreme-vue/text-area';

export default {
  name: 'DxAdditionalInfo',
  components: {
    DxTextArea,
  },
  props: {
    actualAdditionalInfo: { type: String, default: '' },
  },
  data() {
    return {
      additionalInfo: '',
    };
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    this.additionalInfo = this.actualAdditionalInfo;
    this.setAdditionalInfoWorkOrder(this.additionalInfo);
  },
  methods: {
    additionalInfoEvent(e) {
      const newValue = (e.value) ?? 0;
      this.setAdditionalInfoWorkOrder(newValue);
      this.additionalInfo = newValue;
    },
    ...mapActions('PlanningManager', ['setAdditionalInfoWorkOrder']),
  },
};
</script>

<style>

</style>
