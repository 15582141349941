<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Activity.title')}`" />
    <div class="content-devx">
      <ActivitiesDatagrid
        :activities="activities"
      />
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ActivitiesDatagrid from '../../../components/configuration/activities/activitiesDatagrid/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Activities',
  components: {
    navbar,
    ActivitiesDatagrid,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Activity', ['activities']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchActivities();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('Activity', ['fetchActivities']),

  },
};
</script>
