// - General
import logo from '../static/img/LogoCoopsAgroEs.png';

export const ENV = process.env.NODE_ENV || 'development';
export const { TARGET } = process.env;
export const APP_VERSION = '0.10.0-dev';
export const APPLICATION_NAME = 'C3';

export const DATABASE_NAME = 'dbEFEMIS';

// - Backend API
export const SECONDS_TO_REFRESH_AUTOMATICALLY = 60;
export const MILISECONDS_TO_REFRESH_AUTOMATICALLY = 180 * 1000; // 3 minutos
export const SECONDS_BETWEEN_SCANS = 3;

// - Color Theme
export const DEFAULT_PRIMARY_COLOR = '#42526E';
export const DEFAULT_SECONDARY_COLOR = '#003caa';

// Routes
export const APP_LOGO = logo;
