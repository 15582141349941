<template>
  <DxForm
    v-if="workersFormated.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <DxSimpleItem
        v-for="(item, index) in workersFormated"
        :key="index"
        :data-field="item.type"
        :name="item.subfamily"
        :editor-options="{ value: item.nameFormated, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_trabajadores"
  >
    {{ $t('DetailTabWorkers_sin_trabajadores') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    workersSelected: { type: Array, default: () => [] },
  },
  data() {
    return {
      workersFormated: [],
    };
  },
  beforeMount() {
    for (let i = 0; i < this.workersSelected.length; i += 1) {
      this.workersSelected[i].nameFormated = `${this.workersSelected[i].name} ${this.workersSelected[i].surname}`;
      this.workersFormated.push(this.workersSelected[i]);
    }
  },

};
</script>
