<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Page_subtitle_variety')}`" />
    <VarietyDetail :variety-detail="varietyDetail" />
    <f7-block-footer
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToVarieties"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import VarietyDetail from '../../../components/configuration/varieties/varietyDetail/index.vue';

export default {
  name: 'ViewVarietyDetail',
  components: {
    VarietyDetail,
    navbar,
  },
  computed: {
    ...mapState('Varieties', ['varietyDetail']),
  },
  methods: {
    goToVarieties() {
      this.setCurrentName('');
      this.setCurrentSpecie('');
      this.setCurrentGroup('');
      this.$f7.views.main.router.navigate('/varieties/', { reloadCurrent: true });
    },
    ...mapActions('Varieties', ['setCurrentName', 'setCurrentSpecie', 'setCurrentGroup']),
  },
};
</script>
