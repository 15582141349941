<template>
  <div
    class="content-devx content-dx-daily-planning"
  >
    <CalendarInitEndDate
      :key-name="keyName"
      :start="start"
      :end="end"
      class="margin-top"
    />
    <DxDataGrid
      id="DxPlannedVsReal-grid"
      :data-source="filteredBadgetsTasks"
      :column-min-width="70"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxPlannedVsReal"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="18" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="Activity"
        name="activity"
        alignment="left"
        :caption="$t('DxDailyPlanning_activity_caption')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="activityCode"
        name="activityCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_activitycode_caption')"
      />
      <DxColumn
        data-field="taskCode"
        name="taskCode"
        :allow-header-filtering="false"
        :caption="$t('PlanningComponent_taskCode_caption')"
      />
      <DxColumn
        data-field="Task"
        name="task"
        :allow-header-filtering="false"
        alignment="left"
        :caption="$t('DxDailyPlanning_task_caption')"
      />
      <DxColumn
        data-field="locationLevel2"
        name="location"
        alignment="left"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_location_caption')"
      />
      <DxColumn
        data-field="goal"
        name="goal"
        :calculate-display-value="formatDEGoal"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxMonthlyPlanning_goal_caption')"
      />
      <DxColumn
        data-field="goalUnit"
        name="goalUnit"
        :allow-header-filtering="false"
        alignment="right"
        :caption="$t('DxWorker_performanceUnitProductivity_caption')"
      />
      <DxColumn
        data-field="realGoal"
        name="realGoal"
        :calculate-display-value="formatDERealGoal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realGoal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="goalVsReal"
        name="goalVsReal"
        :calculate-display-value="formatDEGoalVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_goalVsReal_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="cost"
        name="cost"
        :calculate-display-value="formatDECost"
        :allow-header-filtering="false"
        :caption="`${$t('DxDailyPlanning_coste')} €`"
        alignment="right"
      />
      <DxColumn
        data-field="realCost"
        name="realCost"
        :calculate-display-value="formatDERealCost"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_realCost')"
        alignment="right"
      />
      <DxColumn
        data-field="CostVsReal"
        name="CostVsReal"
        :calculate-display-value="formatDECostVsReal"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_CostVsReal')"
        alignment="right"
      />
      <DxColumn
        data-field="versionName"
        name="versionName"
        :allow-header-filtering="false"
        :caption="$t('DxDailyPlanning_CostVsReal_versionName')"
        alignment="right"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import * as overlay from 'devextreme/ui/overlay';
import saveAs from 'file-saver';
import CalendarInitEndDate from '../CalendarInitEndDate.vue';

export default {
  name: 'DxPlannedVsReal',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    CalendarInitEndDate,
  },
  props: {
    locationsTree: {
      type: Array,
      default: () => [],
    },
    badgets: {
      type: Array,
      default: () => [],
    },
    listOfCosts: {
      type: Array,
      default: () => [],
    },
    dailyRealPerformanceTasks: {
      type: Array,
      default: () => [],
    },
    initYearBadget: {
      type: String,
      default: '',
    },
    endYearBadget: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      locationsTreeAux: [],
      finalLevelLocation: [],
      filteredBadgetsTasks: [],
      totalCostFilteredBadgetsTask: 0,
      realGoalFilteredBadgetsTask: 0,
      currentLevel2Name: '',
      level2Data: [],
      areaLevel2: 0,
      keyName: 'plannedVsReal',
      start: moment.tz(`${moment().subtract(1, 'years').format('YYYY')}-09-01`, 'UTC').format('YYYY-MM-DD'),
      end: moment.tz(`${moment().format('yyyy')}-10-31`, 'UTC').format('YYYY-MM-DD'),
    };
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    for (const badget of this.badgets) {
      if (moment(badget.year, 'YYYY').isBetween(this.initYearBadget, this.endYearBadget, 'year', '[]')) {
        const lastVersion = badget.version[badget.version.length - 1];
        for (const task of lastVersion.task) {
          this.locationsTreeAux = [...task.locations];
          const taskBadget = {
            badgeId: badget.id,
            versionId: lastVersion.id,
            versionName: lastVersion.name,
            ID: task.id,
            Date: this.formattingDates(task.dates),
            days: task.days,
            Task: task.task_name,
            taskName: task.task,
            taskCode: task.task_code,
            Activity: task.activity,
            activityCode: task.activity_code,
            goal: parseFloat(task.goal),
            goalUnit: task.goal_unit,
            locationLevel2: this.getLocationLevel2(),
            locations: task.locations,
            temporary: task.temporary,
            permanent: task.permanent,
            contratist: task.contratist,
            cost: parseFloat(task.cost),
            realGoal: 0.00,
            goalVsReal: 0.00,
            realCost: 0.00,
            CostVsReal: 0.00,
          };
          if (this.filteredBadgetsTasks.filter((filteredTask) => filteredTask.taskCode === taskBadget.taskCode).length === 0) {
            this.filteredBadgetsTasks.push(taskBadget);
          }
        }
      }
    }
    for (const dailyRealPerformanceTask of this.dailyRealPerformanceTasks) {
      this.totalCostFilteredBadgetsTask = 0;
      this.realGoalFilteredBadgetsTask = 0;
      for (const filteredBadgetsTask of this.filteredBadgetsTasks) {
        if (filteredBadgetsTask.taskCode === dailyRealPerformanceTask.task_code && filteredBadgetsTask.locations.filter((loc) => dailyRealPerformanceTask.planner.locations.includes(loc))) {
          this.locationsTreeAux = [...dailyRealPerformanceTask.location];
          const taskRealPerformance = {
            ID: dailyRealPerformanceTask.id,
            Date: moment(dailyRealPerformanceTask.date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            Task: dailyRealPerformanceTask.task,
            taskCode: dailyRealPerformanceTask.task_code,
            Activity: dailyRealPerformanceTask.activity,
            activityCode: dailyRealPerformanceTask.activity_code,
            TaskStatus: dailyRealPerformanceTask.status,
            Workers: dailyRealPerformanceTask.workers,
            Attachments: dailyRealPerformanceTask.attachments_vehicles,
            Tools: dailyRealPerformanceTask.planner.tools,
            itemsTypes: dailyRealPerformanceTask.planner.items,
            locationLevel2: this.getLocationLevel2(),
            Planner: dailyRealPerformanceTask.planner,
            machinery: dailyRealPerformanceTask.machinery_performance,
            products: dailyRealPerformanceTask.products,
            productPerfor: dailyRealPerformanceTask.product_performance,
            variety: dailyRealPerformanceTask.variety,
          };
          // CALCULO DE COSTES TOTALES
          this.costingWorkers(taskRealPerformance, filteredBadgetsTask);
          this.costingMachinery(taskRealPerformance);
          this.costingProducts(taskRealPerformance);
          this.costingTools(taskRealPerformance);
          filteredBadgetsTask.realCost += this.totalCostFilteredBadgetsTask;
          // Objetivo planificadovsReal
          filteredBadgetsTask.realGoal += this.realGoalFilteredBadgetsTask;
          filteredBadgetsTask.goalVsReal = (filteredBadgetsTask.realGoal / filteredBadgetsTask.goal) * 100;
          // Coste Completado %
          if (filteredBadgetsTask.realCost > 0 && filteredBadgetsTask.cost > 0) {
            filteredBadgetsTask.CostVsReal = (filteredBadgetsTask.realCost / filteredBadgetsTask.cost) * 100;
          }
        }
      }
    }
  },
  methods: {
    costingWorkers(taskRealPerformance) {
      taskRealPerformance.Workers.forEach((worker) => {
        this.realGoalFilteredBadgetsTask += parseFloat(worker.performance);
        this.totalCostFilteredBadgetsTask += (parseFloat(this.getCostByCode(worker.type) * parseFloat(worker.performance)) / 8);
      });
    },
    costingMachinery(taskRealPerformance) {
      taskRealPerformance.machinery.forEach((machinery) => {
        this.totalCostFilteredBadgetsTask += parseFloat(this.getCostByCode(machinery.fuel_type) * parseFloat(machinery.fuel_consumption));
      });
    },
    costingProducts(taskRealPerformance) {
      taskRealPerformance.productPerfor.forEach((productPerf) => {
        const filteredProducts = taskRealPerformance.products.filter((pro) => pro.productName === productPerf.item_id);
        if (filteredProducts.length > 0) {
          const { family } = filteredProducts[0];
          const { subfamily } = filteredProducts[0];
          const dosis = filteredProducts[0].dosisUt;
          this.totalCostFilteredBadgetsTask += this.getProductCost(family, subfamily, dosis, productPerf.qty_used);
        }
      });
    },
    costingTools(taskRealPerformance) {
      if (typeof taskRealPerformance.Tools !== 'undefined') {
        taskRealPerformance.Tools.forEach((tool) => {
          this.totalCostFilteredBadgetsTask += parseFloat(parseFloat(this.getCostByCode(tool.fuel_type)) * parseFloat(tool.fuel_consumption));
        });
      }
    },
    getProductCost(family, subfamily, dosis, qtyUsed) {
      let subFamilyPrice = 0;
      if (family === 'FERTILIZERS') {
        switch (dosis) {
          case 'Cc/Hl':
            subFamilyPrice = this.getCostByCode('FERTILIZER_L');
            break;
          case 'Gr/Hl':
            subFamilyPrice = this.getCostByCode('FERTILIZER_KG');
            break;
          case 'Kg/Ha':
            subFamilyPrice = this.getCostByCode('FERTILIZER_KG');
            break;
          case 'L/Ha':
            subFamilyPrice = this.getCostByCode('FERTILIZER_L');
            break;
          default:
            break;
        }
      } else if (family === 'SUBSTRATE') {
        subFamilyPrice = this.getCostByCode('PESTICIDE');
      } else {
        subFamilyPrice = this.getCostByCode(subfamily);
      }
      return (parseFloat(subFamilyPrice) * parseFloat(qtyUsed));
    },
    getCostByCode(type) {
      for (let i = 0; i < this.listOfCosts.length; i += 1) {
        if (this.listOfCosts[i].code === type) return this.listOfCosts[i].price;
      }
      return 0;
    },
    getLocationLevel2() {
      this.currentLevel2Name = '';
      this.level2Data = [];
      this.areaLevel2 = 0;
      for (let i = 0; i < this.locationsTree.length; i += 1) {
        if (this.locationsTreeAux.length === 0) {
          break;
        }
        this.recursiveGetChildrens(this.locationsTree[i]);
      }
      return this.level2Data;
    },
    recursiveGetChildrens(items) {
      if (items.level === 2) {
        this.currentLevel2Name = items.name;
      }
      if (items.children.length === 0) {
        const check = this.locationsTreeAux.indexOf(items.bd_id);
        if (check !== -1) {
          const alreadyInserted = this.level2Data.indexOf(this.currentLevel2Name);
          if (alreadyInserted === -1) {
            this.level2Data.push(this.currentLevel2Name);
          }
          this.areaLevel2 += items.area;
          this.locationsTreeAux.splice(check, 1);
        }
      } else if (this.locationsTreeAux.length !== 0) {
        for (let j = 0; j < items.children.length; j += 1) {
          this.recursiveGetChildrens(items.children[j]);
        }
      }
    },
    formattingDates(dates) {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date).format('YYYY-MM-DD'));
      }
      return formatedDates;
    },
    formatDEGoal(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.goal).toFixed(2));
    },
    formatDECostVsReal(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.CostVsReal).toFixed(2));
    },
    formatDERealCost(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.realCost).toFixed(2));
    },
    formatDECost(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.cost).toFixed(2));
    },
    formatDEGoalVsReal(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.goalVsReal).toFixed(2));
    },
    formatDERealGoal(rowData) {
      return new Intl.NumberFormat('de-DE').format(parseFloat(rowData.realGoal).toFixed(2));
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PlannedVsReal');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PlannedVsReal.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('TaskBadget', ['setBadgedId', 'setVersionId', 'setTaskId', 'setActualTaskBadget']),
    ...mapActions('Prescription', ['setPrescription']),
  },
};
</script>
<style scoped>
.total-group-count{
    font-size: 16px;
    font-weight: 700;
    border: 1px solid rgba(133,103,103,0.2);
    margin-right: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FBF7EF;
    color: #470000;
}
.total-group-count span {
  font-weight: 400;
  font-size: 13px;
}
</style>
