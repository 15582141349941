<template>
  <div class="margin-top-half">
    <f7-block>
      <f7-row class="margin-bottom">
        <f7-col>
          {{ $t('TaskDefinition.code') }}
        </f7-col>
        <f7-col>
          {{ $t('TaskDefinition.name') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ currentTaskDefinition.code }}
        </f7-col>
        <f7-col>
          {{ currentTaskDefinition.name }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-bottom">
        <f7-col>
          {{ $t('TaskDefinition.activity') }}
        </f7-col>
        <f7-col>
          {{ $t('TaskDefinition.unit') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ currentTaskDefinition.activity }}
        </f7-col>
        <f7-col>
          {{ currentTaskDefinition.unit }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-bottom">
        <f7-col>
          {{ $t('TaskDefinition.performance_unit') }}
        </f7-col>
        <f7-col>
          {{ $t('TaskDefinition.price') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ currentTaskDefinition.performance_unit }}
        </f7-col>
        <f7-col>
          {{ currentTaskDefinition.price }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-bottom">
        <f7-col>
          {{ $t('TaskDefinition.prescription') }}
        </f7-col>
        <f7-col>
          {{ $t('TaskDefinition.nursery') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ currentTaskDefinition.prescription ? $t('yes') : $t('no') }}
        </f7-col>
        <f7-col>
          {{ currentTaskDefinition.nursery ? $t('yes') : $t('no') }}
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'TaskDetail',
  props: {
    currentTaskDefinition: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './TaskDetail.styles.scss';
</style>
