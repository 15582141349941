import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    currentSectorId: '',
    currentSector: {
      sectorName: '',
      groundTexture: '',
      irrigationType: '',
      dripperPerPlant: 0,
      sprinklersNumber: 0,
      transformedArea: 0,
      flow: 0,
      currentCentroid: {},
    },
    currentKeyValues: [],
    showPopup: false,
    // Esto es para el cuadro de diálogo que crea un sector que ocupa toda la parcela.
    showAutoSector: false
  },
  actions: {
    setCurrentSectorId({ commit }, newCurrentSectorId) {
      commit('setCurrentSectorId', newCurrentSectorId);
    },
    updateCurrentSectorKey({ commit }, { currentSectorKey, newValue }) {
      commit('updateCurrentSectorKey', { currentSectorKey, newValue });
    },
    initCurrentSector({ commit }) {
      commit('initCurrentSector');
    },
    resetDynamicForm({ commit }) {
      commit('resetDynamicForm');
    },
    async getSectorById({ commit }, { companyId, currentSectorId }) {
      try {
        const xhr = await Api.getSectorById(companyId, currentSectorId);
        const currentSector = JSON.parse(xhr.response);
        const currentSectorFormatted = {
          id: currentSector.id,
          area: currentSector.area,
          explotation: currentSector.explotation,
          geoFeature: currentSector.geo_feature,
          sectorName: currentSector.name,
          parcelName: currentSector.parcel,
          plantation: currentSector.plantation,
          currentCentroid: currentSector.coordinates,
          groundTexture: Helpers.getProperty(currentSector.properties, 'ground_texture'),
          irrigationType: Helpers.getProperty(currentSector.properties, 'irrigation_type'),
          dripperPerPlant: Helpers.getProperty(currentSector.properties, 'dripper_per_plant'),
          sprinklersNumber: Helpers.getProperty(currentSector.properties, 'sprinkler_number'),
          transformedArea: Helpers.getProperty(currentSector.properties, 'transformed_surface'),
          flow: Helpers.getProperty(currentSector.properties, 'flow'),
        };
        commit('setCurrentSector', currentSectorFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createSector({ commit }, { companyId, newSector }) {
      try {
        const xhr = await Api.createLocation(companyId, newSector);
        commit('setCurrentSectorId', JSON.parse(xhr.response).id);
        commit('setCurrentCentroid', JSON.parse(xhr.response).coordinates);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateSector(context, { companyId, sectorUpdated, sectorId }) {
      try {
        await Api.updateLocation(companyId, sectorUpdated, sectorId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchKeyValuesByFamily({ commit }, key) {
      try {
        const xhr = await Api.fetchKeyValuesByFamily(key);
        commit('setCurrentKeyValues', JSON.parse(xhr.response)[0].values);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setShowPopup({ commit }, value) {
      commit('setShowPopup', value);
    },

    setShowAutoSector({ commit }, value) {
      commit('setShowAutoSector', value);
    }
  },
  mutations: {
    setCurrentSectorId(state, value) {
      state.currentSectorId = value;
    },
    setCurrentSector(state, newCurrentSector) {
      state.currentSector = newCurrentSector;
    },
    setCurrentKeyValues(state, value) {
      state.currentKeyValues = value;
    },
    updateCurrentSectorKey(state, { currentSectorKey, newValue }) {
      state.currentSector[currentSectorKey] = newValue;
    },
    initCurrentSector(state) {
      state.currentSector = {
        sectorName: '',
        groundTexture: '',
        irrigationType: '',
        dripperPerPlant: 0,
        sprinklersNumber: 0,
        transformedArea: 0,
        flow: 0,
        currentCentroid: {},
      };
    },
    resetDynamicForm(state) {
      state.currentSector.dripperPerPlant = 0;
      state.currentSector.sprinklersNumber = 0;
      state.currentSector.transformedArea = 0;
      state.currentSector.flow = 0;
    },
    setShowPopup(state, value) {
      state.showPopup = value;
    },

    setShowAutoSector(state, value) {
      state.showAutoSector = value;
    },
    setCurrentCentroid(state, coordiantes) {
      state.currentSector.currentCentroid = coordiantes;
    },
  },
};
