<template>
  <div class="margin-top-half">
    <div class="content-devx">
      <f7-block>
        {{ $t('Title_page_weather') }}
      </f7-block>
      <div>
        <DxForm>
          <DxGroupItem :col-count="2">
            <DxSimpleItem
              :data-field="$t('weather_client')"
              :caption="$t('weather_client')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: allClients,
                displayExpr: 'name',
                valueExpr: 'id',
                placeholder: $t('weather_client_placeholder'),
                onValueChanged: onSelectedClient,
                searchEnabled:true
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('weather_campaign')"
              :caption="$t('weather_campaign')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: campaignsByClient,
                displayExpr: 'name',
                valueExpr: 'id',
                readOnly:clientSelected==='',
                placeholder: $t('registerClima_campaign_placeholder'),
                onValueChanged: onSelectedCampaign,
                searchEnabled:true
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('weather_parameters')"
              :caption="$t('weather_parameters')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: parameters,
                placeholder: $t('registerClima_parameter_placeholder'),
                onValueChanged: onSelectedParameter,
                displayExpr: displayTranslatedParameter,
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              name="init-date"
              :data-field="$t('DxRegisterCampania_fecha_inicio')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeInitDate,
                displayFormat: 'dd/MM/yyyy',
                placeholder: $t('registerClima_initDate_placeholder'),
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_init_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              name="end-date"
              :data-field="$t('DxRegisterCampania_fecha_finalizacion')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeEndDate,
                displayFormat: 'dd/MM/yyyy',
                placeholder: $t('registerClima_endDate_placeholder'),
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('weather_plantation')"
              :caption="$t('weather_plantation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: allPlantations,
                displayExpr: 'name',
                valueExpr: 'id',
                readOnly:campaign==='',
                placeholder: $t('registerClima_parcel_placeholder'),
                onValueChanged: onSelectedPlantation,
                searchEnabled:true
              }"
            >
              <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem :col-count="3" />
        </DxForm>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'Weather',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      campaignsByClient: [],
    };
  },
  computed: {
    ...mapState('weather', ['parameters', 'allCampaigns', 'campaign', 'allPlantations', 'parcel', 'endDate',
      'initDate', 'allClients', 'clientSelected']),
    campaignSelected() {
      return this.campaign === '';
    },
  },
  async beforeMount() {
    try {
      await this.getAllCampaigns();
      await this.getAllClients();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }

    this.campaignsByClient = this.allCampaigns;
  },
  methods: {
    sundays(date) {
      return [0].indexOf(date.getDay()) >= 0;
    },
    async onSelectedCampaign(e) {
      this.setCampaign(e.value);
      try {
        this.getPlantationByCompany();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    onSelectedPlantation(e) {
      this.setPlantationId(e.value);
      for (const plantation of this.allPlantations) {
        if (e.value === plantation.id) {
          this.setNamePlantation(plantation.name);
          this.setCampaign(plantation.campaign);
        }
      }
      try {
        this.getPlantationByCompany();
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    onSelectedParameter(e) {
      this.setParameterSelected(e.value);
    },
    onChangeInitDate(e) {
      this.setInitDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
    },
    onChangeEndDate(e) {
      this.setEndDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DDT00:00:00'));
    },
    onSelectedClient(e) {
      const clientId = e.value;
      this.campaignsByClient = this.allCampaigns.filter((campaign) => clientId === campaign.company);

      this.setClient(e.value);
    },
    displayTranslatedParameter(parameter) {
      if (parameter === null) {
        return '';
      }
      return `${this.$t(`WeatherParameter.${parameter}`)}`;
    },
    ...mapActions('weather', ['getAllCampaigns', 'setCampaign', 'getPlantationByCompany', 'setParcel',
      'initStoreRegister', 'setInitDate', 'setEndDate', 'setParameterSelected', 'setNameParcel', 'setClient',
      'getAllClients', 'setPlantationId', 'setNamePlantation']),
  },
};
</script>

<style>

</style>
