<template>
  <f7-popup
    animate
    :opened="opened"
  >
    <f7-block
      name="infoUpdate"
      medium-inset
      class="padding"
    >
      <div class="label-text">
        {{ $t("Sync.InfoUpdate.Title") }}
      </div>
      <div class="content-text">
        <h3>
          {{ $t("Sync.InfoUpdate.Text") }}
        </h3>
      </div>
    </f7-block>
    <f7-block
      v-if="isSyncing"
      name="infoLoading"
    >
      <div class="content-block text-align-center">
        <div class="content-text">
          <f7-preloader />
          <p>
            <strong class="label-text">{{ $t("Sync.InfoLoading.Title") }}</strong>
          </p>
          <small>
            {{ $t("Sync.InfoLoading.Text") }}
          </small>
        </div>
      </div>
    </f7-block>
    <f7-block v-if="syncError">
      <p><strong>{{ $t("Sync.TextError") }}</strong> {{ syncError }}.</p>
    </f7-block>
    <f7-block>
      <div class="content-block">
        <f7-button
          v-if="!isSyncing"
          name="btnSync"
          round
          large
          fill
          @click="$emit('syncRequested')"
        >
          {{ $t("Sync.ButtonSync") }}
        </f7-button>
      </div>
    </f7-block>
  </f7-popup>
</template>
<script>
export default {
  name: 'SyncModal',
  props: {
    opened: Boolean,
    syncError: { type: String, default: '' },
    isSyncing: Boolean,
  },
};
</script>

<style lang="scss" scoped>
div[name="infoUpdate"], div[name="infoLoading"] {
  text-align: center;
}
.label-text{
  font-size: 18px;
  text-align: center;
}
.content-text{
  color: black;
}
</style>
