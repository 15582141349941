var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxPopup',{attrs:{"id":"varietyPopup-popup","title":_vm.$t('variety'),"visible":_vm.showPopup,"show-close-button":true,"on-hidden":_vm.onHidden,"close-on-outside-click":false,"height":"auto","width":700}},[_c('DxForm',{attrs:{"show-colon-after-label":false}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"col-span":2,"data-field":_vm.$t('group'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.groupsAvailables,
          value: _vm.currentGroup,
          onValueChanged: _vm.onChangeGroup,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"col-span":2,"data-field":_vm.$t('specie'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.speciesAvailables,
          value: _vm.currentSpecie,
          onValueChanged: _vm.onChangeSpecie,
          searchEnabled: true,
          searchTimeout: _vm.searchTimeoutOption,
          minSearchLength: _vm.minSearchLengthOption,
          showDataBeforeSearch: _vm.showDataBeforeSearchOption,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"col-span":2,"data-field":_vm.$t('name'),"editor-options":{
          value: _vm.currentName,
          onValueChanged: _vm.onChangeName,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1)],1),_vm._v(" "),_c('f7-block-footer',{staticClass:"content-action-botton"},[_c('f7-button',{staticClass:"dx-btn-save",attrs:{"raised":"","styling-mode":"outlined","name":"ButtonSave"},on:{"click":_vm.savePopup}},[_vm._v("\n      "+_vm._s(_vm.$t('Button_save_text'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }