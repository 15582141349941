import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    varieties: [],
    varietyDetail: {},
    groupsAvailables: [],
    speciesAvailables: [],
    currentGroup: '',
    currentSpecie: '',
    currentName: '',
    showPopup: false,
  },
  mutations: {
    GET_VARIETIES(state, value) {
      state.varieties = value;
    },
    GET_VARIETY_DETAIL(state, value) {
      state.varietyDetail = value;
      state.currentGroup = value.group;
      state.currentSpecie = value.specie;
      state.currentName = value.name;
    },
    GET_GROUPS_AVAILABLES(state, value) {
      state.groupsAvailables = value;
    },
    GET_SPECIES_AVAILABLES(state, value) {
      state.speciesAvailables = value;
    },
    SET_CURRENT_GROUP(state, value) {
      state.currentGroup = value;
    },
    SET_CURRENT_SPECIE(state, value) {
      state.currentSpecie = value;
    },
    SET_CURRENT_NAME(state, value) {
      state.currentName = value;
    },
    SET_VARIETY_DETAIL(state, value) {
      state.varietyDetail = value;
    },
    SET_SHOW_POPUP(state, value) {
      state.showPopup = value;
    },
  },
  actions: {
    async getVarieties({ commit }) {
      try {
        const xhr = await Api.getVarieties();
        const varieties = JSON.parse(xhr.response).data;
        const varietiesFormatted = [];
        for (const variety of varieties) {
          varietiesFormatted.push({
            id: variety.id,
            group: variety.group,
            specie: variety.specie,
            name: variety.name,
            status: variety.status,
          });
        }
        commit('GET_VARIETIES', varietiesFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getVarietyDetail(context, idVariety) {
      try {
        const xhr = await Api.getVarietyDetail(idVariety);
        const varietyDetail = JSON.parse(xhr.response);
        context.commit('GET_VARIETY_DETAIL', varietyDetail);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createVariety({ state }) {
      try {
        await Api.createNewVariety(
          {
            group: state.currentGroup,
            specie: state.currentSpecie,
            name: state.currentName,
          },
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateVariety({ state }, varietyId) {
      try {
        await Api.updateVariety(
          {
            group: state.currentGroup,
            specie: state.currentSpecie,
            name: state.currentName,
          },
          varietyId,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteVariety(context, variety) {
      try {
        await Api.deleteVariety(variety.data.id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getGroupsAvailables({ commit }) {
      try {
        const xhr = await Api.getGroupsAvailables();
        const groupsAvailables = JSON.parse(xhr.response).data;
        commit('GET_GROUPS_AVAILABLES', groupsAvailables);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getSpeciesAvailables({ commit }, group) {
      try {
        const xhr = await Api.getSpeciesAvailables(group);
        const speciesAvailables = JSON.parse(xhr.response).data;
        commit('GET_SPECIES_AVAILABLES', speciesAvailables);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setCurrentGroup(context, value) {
      context.commit('SET_CURRENT_GROUP', value);
    },
    setCurrentSpecie(context, value) {
      context.commit('SET_CURRENT_SPECIE', value);
    },
    setCurrentName(context, value) {
      context.commit('SET_CURRENT_NAME', value);
    },
    setVarietyDetail(context, value) {
      context.commit('SET_VARIETY_DETAIL', value);
    },
    setShowPopup(context, value) {
      context.commit('SET_SHOW_POPUP', value);
    },
  },
};
