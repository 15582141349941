<template>
  <div class="content-devx">
    <f7-block-title><span>{{ $t('laboratoryAnalaysisTitle') }}</span></f7-block-title>
    <DxDataGrid
      id="Nutrition"
      :data-source="allDataAnalysis"
      :remote-operations="true"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :repaint-changes-only="true"
      @RowRemoved="callToDeleteProduct"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxNutrition"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('confirmDeleteMessage')"
        />
      </DxEditing>
      <DxColumn
        data-field="date"
        :width="90"
        :caption="$t('laboratory_nutrition_date_sample')"
        :calculate-display-value="formatingDate"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="sample_type"
        :caption="$t('laboratory_nutrition_type_sample')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="description"
        :caption="$t('laboratory_nutrition_type_description')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        type="buttons"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewDetailsLaboratoryAnalysis"
        />
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editAnalysis"
        />
        <DxButton
          name="delete"
          icon="trash"
        />
      </DxColumn>
    </DxDataGrid>

    <DxPopUpInsertedAnalysis :edit="edit" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxButton,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import Api from '../../../services/Api';
import DxPopUpInsertedAnalysis from '../popUpLaboratoryNutrition/index.vue';

export default {
  name: 'DxNutrition',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
    DxPopUpInsertedAnalysis,
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      edit: false,
    };
  },
  computed: {
    ...mapState('Nutrition', ['allDataAnalysis']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async beforeMount() {
    try {
      const data = {
        companyId: this.currentPlantation.companyId,
        idPlantation: this.currentPlantation.id,
      };
      await this.getDataAnalysis(data);
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    formatingDate(e) {
      return moment(e.date).format('DD/MM/YYYY');
    },
    editAnalysis(rowSelected) {
      const { companyId } = this.currentPlantation;
      const idAndCompanyId = {
        companyId,
        id: rowSelected.row.data.id,
      };
      this.getViewDetails(idAndCompanyId);
      this.setId(rowSelected.row.data.id);
      this.edit = true;
      this.setShowInsertedPopUP(true);
    },
    async callToDeleteProduct(rowSelected) {
      this.$f7.preloader.show();
      const { companyId } = this.currentPlantation;
      try {
        await Api.deleteAnalysis(companyId, rowSelected.data.id);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    viewDetailsLaboratoryAnalysis(rowdata) {
      const { companyId } = this.currentPlantation;
      const idAndCompanyId = {
        companyId,
        id: rowdata.row.data.id,
      };
      this.getViewDetails(idAndCompanyId);
      this.$f7.views.main.router.navigate('/viewLaboratoryAnalaysis/', { reloadCurrent: true });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Nutrition');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Nutrition.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.edit = false;
            this.setShowInsertedPopUP(true);
          },
        },
      });
    },
    ...mapActions('Explotaciones', ['setShowInsertedPopUP']),
    ...mapActions('Nutrition', ['getDataAnalysis', 'delAnalysis', 'setId', 'getViewDetails']),
  },
};
</script>

<style>

</style>
