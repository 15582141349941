<template>
  <div
    class="content-devx dx-card"
  >
    <f7-block-title>
      {{ $t('nutritionDetail.report.explotationData') }}
    </f7-block-title>

    <f7-block>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.campaign') }}: <b>{{ explotationData.campaign }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.cropType') }}: <b>{{ explotationData.crop_type }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.area') }}: <b>{{ parseFloat($helpers.getProperty(explotationData.farms[0].properties, 'area')).toFixed(2) }} ha</b>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.explotation') }}: <b>{{ explotationData.explotation }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.specie') }}: <b>{{ explotationData.species }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.plagueAgent') }}: <b>{{ treatmentPlan.agent }}</b>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.parcel') }}: <b>{{ explotationData.parcel }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.variety') }}: <b>{{ explotationData.variety }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.dosage') }}: <b>{{ treatmentPlan.dose_type }}</b>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.sector') }}: <b>{{ explotationData.farms[0].name }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.plantsDensity') }}: <b>{{ explotationData.planting_density }} {{ $t('nutritionDetail.report.plantsHa') }}</b>
        </f7-col>
        <f7-col />
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.plantation') }}: <b>{{ explotationData.name }}</b>
        </f7-col>
        <f7-col>
          {{ $t('nutritionDetail.report.plantsNumber') }}: <b>{{ explotationData.plants }}</b>
        </f7-col>
        <f7-col />
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'ExplotationData',
  props: {
    explotationData: {
      type: Object,
      default: () => {},
    },
    treatmentPlan: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ExplotationData.styles.scss';
</style>
