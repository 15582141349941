import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    treatmentPlans: [],
    treatmentPlansDetailById: {},
  },
  mutations: {
    UPDATE_TREATMENT_DATA(state, value) {
      state.treatmentPlans = value;
    },
    UPDATE_TREATMENT_PLANS_DETAIL(state, value) {
      state.treatmentPlansDetailById = value;
    },
    PUSH_TREATMENT_PLAN_DETAIL(state, value) {
      state.treatmentPlansDetailById[value.id] = value;
    },
  },
  actions: {
    async getTreatmentDataForAnalisis({ commit }) {
      try {
        const xhr = await Api.getTreatmentDataForAnalisis();
        const treatmentPlans = JSON.parse(xhr.response);

        commit('UPDATE_TREATMENT_DATA', treatmentPlans);
        // Vaciamos el diccionario de detalles de planes al inicio.
        commit('UPDATE_TREATMENT_PLANS_DETAIL', {});

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTreatmentPlanDetails({ commit, state }, { treatmentId }) {
      if (state.treatmentPlansDetailById[treatmentId] != undefined) { Promise.resolve(); return; }
      try {
        const xhr = await Api.getTreatmentById(treatmentId);
        const treatmentData = JSON.parse(xhr.response);

        commit(
          'PUSH_TREATMENT_PLAN_DETAIL',
          treatmentData,
        );

        return Promise.resolve();
      } catch (error) {
        console.error(error);
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
};
