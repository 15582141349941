<template>
  <f7-page>
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.campaign} > ${currentPlantation.name}`" />
    <f7-toolbar
      tabbar
      top
      class="content-tabs-custom  no-shadow"
    >
      <f7-link
        tab-link
        route-tab-id="tab-detail"
        href="./detail/"
        @click="setTabActive('detail')"
      >
        {{ $t('Searcher.detail.title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-home"
        href="./home/"
        @click="setTabActive('home')"
      >
        {{ $t('home') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-weatherGraph"
        href="./weatherGraph/"
        @click="setTabActive('weatherGraph')"
      >
        {{ $t('Searcher.weatherGraph.title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-nutrition"
        href="./nutrition/"
        @click="setTabActive('nutrition')"
      >
        {{ $t('Searcher.nutrition.title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-treatment"
        href="./treatment/"
        @click="setTabActive('treatment')"
      >
        {{ $t('Searcher.treatment.title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-irrigation"
        href="./irrigation/"
        @click="setTabActive('irrigation')"
      >
        {{ $t('Searcher.irrigation.title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-observations"
        href="./observations/"
        @click="setTabActive('observations')"
      >
        {{ $t('Searcher.observations.observations_title') }}
      </f7-link>
      <f7-link
        tab-link
        route-tab-id="tab-gis"
        href="./gissearcher/"
        @click="setTabActive('gis')"
      >
        {{ $t('GIS') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      routable
      animated
    >
      <f7-tab
        id="tab-detail"
        class="page-content padding-top-half"
      >
        <Detail v-if="currentTab === 'detail'" />
      </f7-tab>
      <f7-tab
        id="tab-home"
        class="page-content padding-top-half"
      >
        <Home v-if="currentTab === 'home'" />
      </f7-tab>
      <f7-tab
        id="tab-weatherGraph"
        class="page-content no-padding-top"
      >
        <WeatherGraph v-if="currentTab === 'weatherGraph'" />
      </f7-tab>
      <f7-tab
        id="tab-nutrition"
        class="page-content padding-top-half"
      >
        <Nutrition v-if="currentTab === 'nutrition'" />
      </f7-tab>
      <f7-tab
        id="tab-treatment"
        class="page-content block padding-top-half"
      >
        <Treatment v-if="currentTab === 'treatment'" />
      </f7-tab>
      <f7-tab
        id="tab-irrigation"
        class="page-content padding-top-half"
      >
        <Irrigation v-if="currentTab === 'irrigation'" />
      </f7-tab>
      <f7-tab
        id="tab-observations"
        class="page-content padding-top-half"
      >
        <Observations v-if="currentTab === 'observations'" />
      </f7-tab>
      <f7-tab
        id="tab-gis"
        class="page-content padding-top-half"
      >
        <GISSearcher v-if="currentTab === 'gis'" />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
import Home from '../../components/searcher/home/index.vue';
import Observations from '../../components/searcher/observations/observationsDatabase/index.vue';
import Nutrition from '../../components/searcher/nutrition/index.vue';
import Treatment from '../../components/searcher/treatment/index.vue';
import Irrigation from '../../components/searcher/irrigation/index.vue';
import Tasks from '../../components/searcher/tasks/index.vue';
import WeatherGraph from '../../components/searcher/weatherGraph/index.vue';
import Detail from '../../components/searcher/detail/index.vue';
import GISSearcher from '../../components/searcher/gis/index.vue';

export default {
  name: 'Searcher',
  components: {
    navbar,
    Home,
    Observations,
    Nutrition,
    Irrigation,
    Tasks,
    Treatment,
    WeatherGraph,
    Detail,
    GISSearcher,
  },
  computed: {
    ...mapState('Utilities', ['currentTab']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  methods: {
    setTabActive(tabSelected) {
      this.setCurrentTab(tabSelected);
    },
    ...mapActions('Utilities', ['setCurrentTab']),
  },
};
</script>
