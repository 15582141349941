<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${currentParcel.name} > ${$t('sectors')}`" />
    <f7-block class="content-devx">
      <f7-block-title>
        <span>{{ `${$t('sector.register.title')}` }}</span>
      </f7-block-title>
      {{ $t("sector.register.step2") }}
    </f7-block>
    <GISEditor
      :zoom="zoom"
      :name-map="mainMap"
      :center="currentParcel.coordinates"
      :tree-locations="treeLocations"
      :disabled-edit-sector="typeof currentSector.id === 'undefined'"
      :create-sector="typeof currentSector.id === 'undefined'"
    />
    <Parcel-info
      :land-size="areaToDisplay"
      :lang="lngToDisplay"
      :lat="latToDisplay"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerSector"
        >
          {{ $t("save") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <PopupRegisterSectorStep2 />
  </f7-page>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import GISEditor from '../../components/Gis/GISEditor/index.vue';
import ParcelInfo from '../../components/parcel/parcelInfo/index.vue';
import PopupRegisterSectorStep2 from '../../components/sector/popupRegisterSectorStep2/index.vue';
import Formatter from './formatter';

export default {
  name: 'RegisterSectorStep2Page',
  components: {
    navbar,
    GISEditor,
    ParcelInfo,
    PopupRegisterSectorStep2,
  },
  data() {
    return {
      zoom: 20,
      mainMap: 'registerSectorStep2',
      editing: false,

    };
  },

  computed: {
    areaToDisplay() {
      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.area;
      }
      return this.area;
    },
    lngToDisplay() {
      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.longitude;
      }
      return this.viewCentroide.lng;
    },
    latToDisplay() {
      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.latitude;
      }
      return this.viewCentroide.lat;
    },
    ...mapState('RegisterLocation', [
      'name',
      'registerNumber',
      'province',
      'mobile',
      'farm',
      'ownerFarm',
    ]),
    ...mapState('GisEditor', ['area', 'centroide', 'currentGeoFeature']),
    ...mapState('Sector', ['currentSector']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
    ...mapState('Plantation', ['treeLocations']),
    ...mapGetters('GisEditor', ['viewCentroide']),
  },
  beforeMount() {
    if (typeof this.currentSector.id === 'undefined') {
      this.editing = false;
      this.resetCentroide();
    } else {
      this.editing = true;
    }
  },
  methods: {
    async registerSector() {
      if (
        typeof this.actualUser.id !== 'undefined'
        && this.actualUser.id !== ''
        && typeof this.currentParcel.id !== 'undefined'
        && this.currentParcel.id !== ''
      ) {
        if (
          (this.currentGeoFeature.length !== null
          && this.currentGeoFeature.length !== {}
          && this.centroide !== null) || this.editing
        ) {
          this.$f7.preloader.show();
          try {
            let geoFeature = null;

            if (this.area === 0) {
              this.setCentroide({ lat: this.currentSector.currentCentroid.latitude, lng: this.currentSector.currentCentroid.longitude });
              this.setArea(this.currentSector.area);
              geoFeature = this.currentSector.geoFeature;
            } else if (this.currentGeoFeature.features[0].geometry.coordinates.length !== 0) {
              geoFeature = this.currentGeoFeature;
            } else {
              geoFeature = this.currentSector.geoFeature;
            }
            const companyId = this.actualUser.id;
            const newSector = {
              name: this.currentSector.sectorName,
              geo_feature: geoFeature,
              type: 'AGRARIAN', // Para sectores siempre tiene ese valor
              coordinates: {
                latitude: this.centroide.lat,
                longitude: this.centroide.lng,
              },
              code: `${this.currentSector.sectorName}${Date.now()}`,
              parent: this.currentParcel.id,
              properties: Formatter.formatSectorProperties(this.currentSector, this.area),
            };

            if (typeof this.currentSector.id === 'undefined') {
              await this.createSector({ companyId, newSector });
            } else {
              await this.updateSector({
                companyId,
                sectorUpdated: newSector,
                sectorId: this.currentSector.id,
              });
            }
          } catch (error) {
            this.$f7.dialog.alert(this.$t(`${error}`));
          } finally {
            this.$f7.preloader.hide();
            this.setShowPopup(true);
          }
        } else {
          this.$f7.dialog.alert(this.$t('sector.register.gisValidation'));
        }
      } else {
        this.$f7.dialog.alert(this.$t('sector.register.registerValidation'));
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/registerSectorStep1Page/', {
        reloadCurrent: true,
      });
    },
    ...mapActions('Sector', ['createSector', 'updateSector', 'setShowPopup']),
    ...mapActions('GisEditor', ['resetCentroide', 'setArea', 'setCentroide']),
  },
};
</script>

<style scoped>
</style>
