<template>
  <div
    class="content-devx"
  >
    <f7-block-title>
      <span>{{ $t('title_task_by_company') }}</span>
    </f7-block-title>

    <DxDataGrid
      :data-source="tasks"
      :show-borders="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      :column-min-width="70"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="timelineTasksGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="5" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxColumn
        data-field="activity"
        :caption="$t('taskByCompany_group')"
        width="300"
      />
      <DxColumn
        data-field="name"
        :caption="$t('taskByCompany_Task')"
        :allow-header-filtering="false"
        width="250"
      />
      <DxColumn
        data-field="init_date"
        :caption="$t('taskByCompany_init_date')"
        alignment="right"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
        width="100"
      />
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import moment from 'moment';

export default {
  name: 'TimelineTasksGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxPaging,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText).format('DD-MM-YYYY');
    },
  },

};
</script>
<style lang="scss" scoped>
@import './TimelineTasksGrid.styles.scss';
</style>
