<template>
  <div>
    <div class="content-devx no-shadow content-dx-daily-planning content-block-table">
      <DxDataGrid
        id="attachmentTable"
        ref="attachmentTable"
        :data-source="attachments.values"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        @row-inserted="onInsertedRow"
        @row-updated="onChangeRow"
        @row-removed="onRemovedRow"
      >
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />
        <DxSearchPanel
          :visible="true"
          :width="100"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="type"
          name="type"
          :caption="$t('DxAttachment_dxColumn_TypeAttachment')"
        >
          <DxLookup
            :data-source="attachment"
            display-expr="name"
            value-expr="id"
          />
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="count"
          :width="70"
          name="count"
          data-type="number"
          :caption="$t('DxAttachment_dxColumn_QuantityAttachment')"
          :calculate-cell-value="setDefaultValue"
        >
          <DxRequiredRule />
          <DxRangeRule
          min="1"
          :message="$t('Rules_count_more_than_zero')"
        />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxRequiredRule,
  DxLookup,
} from 'devextreme-vue/data-grid';
import {
  DxRangeRule,
} from 'devextreme-vue/validator';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'DxAttachmentTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxRequiredRule,
    DxLookup,
    DxRangeRule,
  },
  props: {
    attachment: { type: Array, default: () => [] },
    planning: { type: Object, default: null },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('PlanningManager', ['attachments']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    if (this.planning == null) {
      this.setAttachments([]);
    } else {
      this.actualItems = this.planning.AttachmentsDetailTab;
      this.setAttachments(this.actualItems);
    }
  },
  methods: {
    setDefaultValue(e) {
      e.count = (typeof (e.count) === 'undefined') ? 1 : e.count;
      return e.count;
    },
    // SET DATA SOURCE TO PRODUCTS VUEX
    onChangeRow() {
      const arrayItems = this.$refs.attachmentTable.instance.getDataSource().items();
      const newItems = this.attachments;
      newItems.values = [];
      for (let i = 0; i < arrayItems.length; i += 1) {
        newItems.values.push({ type: arrayItems[i].type, count: arrayItems[i].count });
      }
      this.setAttachments(newItems.values);
    },
    onInsertedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    onRemovedRow() {
      const newItems = this.attachments;
      this.setAttachments(newItems.values);
    },
    ...mapActions('PlanningManager', ['setAttachments']),
  },
};
</script>
