import {
  DxColumn, DxDataGrid, DxEditing,
  DxPaging, DxScrolling, DxSorting,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState } from 'vuex';
import plantationService from '@/services/plantationService';

export default {
  name: 'product-resume',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSelectBox,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    DxNumberBox,
    DxSummary,
    DxTotalItem,
  },
  props: [],
  data() {
    return {
      plantation: {},
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData', 'enableWatchers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
  },
  beforeMount() {
    this.stepData.area = this.currentPlantation.area;
    plantationService.getById(0).then((response) => {
      this.stepData.productResume = this.stepData.dosageProduct.map((product, index) => {
        const dosageUnit = this.stepData.dosages[index].dosage;
        return {
          product: product.name,
          dosageUnit,
          dosagePlant: this.roundValue((dosageUnit / this.actualPlantation.plants_density) * 1000),
          dosageTotal: this.roundValue(dosageUnit * this.currentPlantation.area),
          price: product.price,
          costUnit: 0, // dosageUnit * price
          costTotal: 0, // costUnit * area
        };
      });
    });
  },
  methods: {
    calcTotalCost(element) {
      if (!this.enableWatchers) {
        return;
      }
      element.forEach((item) => {
        const calcCostUnit = this.roundValue(item.dosageUnit * item.price) ? this.roundValue(item.dosageUnit * item.price) : 0;
        const calcCostTotal = this.roundValue(item.costUnit * this.currentPlantation.area) ? this.roundValue(item.costUnit * this.currentPlantation.area) : 0;

        item.costUnit = calcCostUnit;
        item.costTotal = calcCostTotal;
      });
    },
    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
  },
  watch: {
    'stepData.productResume': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalCost(val);
      },
      deep: true,
    },
  },
};
