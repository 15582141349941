<template>
  <f7-page>
    <navbar :text="$t('CreatePrescription_Title')" />
    <DxInformationPrescription />
    <DxProducts />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToprescriptionManager"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createPlanning"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DxProducts from '../../../components/configuration/prescriptions/DxProducts.vue';
import DxInformationPrescription from '../../../components/configuration/prescriptions/DxInformationPrescription.vue';

import navbar from '../../../components/NavBar.vue';
import Api from '../../../services/Api';

export default {
  name: 'CreatePrescription',
  components: {
    DxProducts,
    DxInformationPrescription,
    navbar,
  },
  computed: {
    ...mapState('Prescription', ['prescription']),
  },
  beforeDestroy() {
    this.setNewPrescription();
  },
  methods: {
    goToprescriptionManager() {
      this.$f7.views.main.router.navigate('/presciptionManager/', { reloadCurrent: true });
    },
    async createPlanning(e) {
      e.preventDefault();
      if (this.prescription.active !== 'Yes') {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationActive'));
      } else if (this.prescription.dateActivation === 'Invalid date') {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationDate'));
      } else if (this.prescription.type === '') {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationType'));
      } else if (this.prescription.method === '') {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationMethod'));
      } else if (this.prescription.products.length === 0) {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationProducts'));
      } else if (this.prescription.name === '') {
        this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationName'));
      } else {
        this.calculateDoseSolution();
        this.checkDoseUt();
        const respuesta = await Api.sendPrescriptions(this.prescription);
        if (respuesta.status === 409) {
          this.$f7.dialog.alert(this.$t('CreatePrescription_ValidationNameDuplicated'));
        } else {
          this.$f7.views.main.router.navigate('/presciptionManager/', { reloadCurrent: true });
        }
      }
    },
    calculateDoseSolution() {
      for (let i = 0; i < this.prescription.products.length; i += 1) {
        const producto = this.prescription.products[i];
        let dosisTotal = 0;
        let solutionTotal = 0;
        if (producto.dosisUt.includes('Hl')) {
          dosisTotal = parseFloat(producto.dosis) * parseFloat(producto.solution);
          solutionTotal = (parseFloat(producto.solution) * dosisTotal) / parseFloat(producto.dosis);
        }
        this.prescription.products[i].dosis_total = dosisTotal.toFixed(2).toString();
        this.prescription.products[i].solution_total = solutionTotal.toFixed(2).toString();
      }
    },
    checkDoseUt() {
      for (let i = 0; i < this.prescription.products.length; i += 1) {
        const producto = this.prescription.products[i];
        if (producto.dosisUt.includes('Ha')) {
          this.prescription.products[i].dilution_factor = '0.00';
        } else {
          this.prescription.products[i].solutionUt = 'L/Ha';
          this.prescription.products[i].dilution_factor = (parseFloat(producto.solution) / 100).toFixed(2);
        }
      }
    },
    workersNotEmpty() {
      for (let i = 0; i < this.workers.values.length; i += 1) {
        if (this.workers.values[i].count !== 0) {
          return true;
        }
      }
      return false;
    },
    ...mapActions('Prescription', ['setNewPrescription']),
  },
};
</script>
