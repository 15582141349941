<template>
  <div class="margin-top-half">
    <div
      class="content-devx"
    >
      <f7-block v-if="!loaded">
        <div class="loadingFalse">
          <div class="preloader" />
        </div>
      </f7-block>
      <f7-block v-else>
        <DxDataGrid
          id="Clients"
          :data-source="allClients"
          :remote-operations="true"
          :column-min-width="70"
          :column-auto-width="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          @exporting="onExporting"
        >
          <DxSelection
            mode="single"
          />
          <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="DxSystemParcelClientV2"
          />
          <DxHeaderFilter :visible="true" />
          <DxExport
            :enabled="false"
          />
          <DxPaging :page-size="10" />
          <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
          />
          <DxSearchPanel
            :visible="true"
            :width="200"
            :placeholder="$t('Search_placeholder')"
          />
          <DxColumn
            data-field="registration_date"
            :width="90"
            :caption="$t('DxClients_fecha_alta')"
            :calculate-display-value="formatingDate"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="name"
            :caption="$t('client')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="mobile_phone"
            :caption="$t('DxClients_numero_movil')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="phone"
            :caption="$t('DxClients_telefono')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="province"
            :caption="$t('DxClients_provincia')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="city"
            :caption="$t('DxClients_localidad')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            type="buttons"
            cell-template="ventas-cell-template"
          />
          <DxColumn
            type="buttons"
            cell-template="diff-cell-template"
          />
          <template #ventas-cell-template="{data}">
            <div
              class="Pendiente"
              @click="showViewClient(data)"
            >
              {{ $t('DxClients_sales') }}
            </div>
          </template>
          <template #diff-cell-template="{ data}">
            <button
              class="dx-btn-cancel"
              :width="120"
              @click="showInfo(data)"
            >
              {{ $t("Button_go_parcel") }}
            </button>
          </template>
        </DxDataGrid>
      </f7-block>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment-timezone';

export default {
  name: 'DxSystemParcelClients',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
      dataFormated: [],
      refreshMode: 'reshape',
    };
  },
  computed: {
    ...mapState('Clients', ['allClients']),
  },
  async beforeMount() {
    try {
      await this.fetchAllClients();
      this.loaded = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    rowInserted() {
      this.setShowInsertedPopUP(true);
    },
    showInfo(rowSelected) {
      this.setActualUser(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    showViewClient(rowSelected) {
      this.setActualUser(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/viewClient/systemParcelClients/', { reloadCurrent: true });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SystemParcelClients');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SystemParcelClients.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setShowInsertedPopUP(true);
          },
        },
      });
    },
    formatingDate(e) {
      return moment(e.date).format('DD/MM/YYYY');
    },
    ...mapActions('Explotaciones', ['setActualUser', 'setShowInsertedPopUP']),
    ...mapActions('Clients', ['fetchAllClients']),
  },
};
</script>
<style scoped>

</style>
