<template>
  <f7-block>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('code') }}: <b>{{ currentProduct.code }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_status_caption') }}: <b>{{ formattingValue(currentProduct.status) }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxProduct_nameCommercial') }}: <b>{{ currentProduct.name }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_SCS_autorizado') }}: <b>{{ formattingValue($helpers.getProperty(currentProduct.properties, 'SCS_authorized')) }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxProduct_ecology') }}: <b>{{ currentProduct.ecologic }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_EU_autorizado') }}: <b>{{ formattingValue($helpers.getProperty(currentProduct.properties, 'EU_authorized')) }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxFormProduct_family_caption') }}: <b>{{ currentProduct.family }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_preHarvest') }}: <b>{{ $helpers.getProperty(currentProduct.properties, 'preharvest') }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxFormProduct_subfamily_caption') }}: <b>{{ currentProduct.subfamily }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_currentIn') }}: <b>{{ $helpers.getProperty(currentProduct.properties, 'intervalIn') }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col v-if="currentProduct.family === 'PHITOSANITARY'">
        {{ $t('DxFormProduct_component_caption') }}: <b>{{ currentProduct.component }}</b>
      </f7-col>
      <f7-col v-else>
        {{ $t('DxFormProduct_component_caption') }}: <b>-</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_description_caption') }}: <b>{{ $helpers.getProperty(currentProduct.properties, 'description') }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxFormProduct_sapunit_caption') }}: <b>{{ currentProduct.dosis.unit }}</b>
      </f7-col>
      <f7-col>
        {{ $t('DxFormProduct_comment_caption') }}: <b>{{ $helpers.getProperty(currentProduct.properties, 'comment') }}</b>
      </f7-col>
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxFormProduct_maxdosis') }}: <b>{{ currentProduct.dosis.max_dosis }}</b>
      </f7-col>
      <f7-col />
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxProduct_tipology') }}: <b>{{ currentProduct.tipology }}</b>
      </f7-col>
      <f7-col />
    </f7-row>
    <f7-row class="margin-top-half">
      <f7-col>
        {{ $t('DxFormProduct_toxicity_caption') }}: <b>{{ $helpers.getProperty(currentProduct.properties, 'toxicity') }}</b>
      </f7-col>
      <f7-col />
    </f7-row>
  </f7-block>
</template>
<script>

export default {
  name: 'ProductDetail',
  props: {
    currentProduct: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formattingValue(value) {
      let formattedValue = '';
      if (value === 'true' || value === true) {
        formattedValue = this.$t('yes');
      } else {
        formattedValue = this.$t('no');
      }
      return formattedValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ProductDetail.styles.scss';
</style>
