<template>
  <div
    class="content-devx"
  >
    <f7-block class="margin-bottom-title">
      <f7-row>
        <f7-col width="30">
          {{ $t('Uses_Doses_Detail_Title') }}
        </f7-col>
        <f7-col width="70" />
      </f7-row>
    </f7-block>
    <f7-block>
      <f7-row>
        <DxDataGrid
          id="usesDosesAuthProduct-grid"
          :data-source="usesDosesAuth.recommendations"
          :column-min-width="90"
          :column-auto-width="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          @exporting="onExporting"
        >
          <DxSelection
            mode="single"
          />
          <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="usesDosesAuthProduct"
          />
          <DxExport
            :enabled="false"
          />
          <DxPaging :page-size="10" />
          <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
          />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel
            :visible="true"
            :width="200"
            :placeholder="$t('Search_placeholder')"
          />
          <DxColumnFixing :enabled="true" />
          <DxColumn
            data-field="cultive"
            :caption="$t('Crop')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="agent"
            :caption="$t('Agent')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="application_num"
            :calculate-cell-value="setApplicationNumCell"
            :caption="$t('Application_Num')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="liquid_vol"
            :calculate-cell-value="setLiquidCell"
            :caption="`${$t('Liquid_Vol')} (L/ha)`"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            type="buttons"
          >
            <DxButton
              icon="find"
              :on-click="showDetails"
            />
          </DxColumn>
        </Dxdatagrid>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapActions } from 'vuex';

export default {
  name: 'UsesDosesAuthProduct',
  components: {

    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,

  },
  props: {
    usesDosesAuth: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    async showDetails(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.getPhytosanitaryProductDetail({ id: this.usesDosesAuth.id, crop: rowSelected.row.data.cultive, agent: rowSelected.row.data.agent });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/viewPhytosanitaryProductPlagueDetail/', { reloadCurrent: true });
      }
    },
    setLiquidCell(rowData) {
      if (rowData.liquid_vol !== null) {
        return `${parseFloat(rowData.liquid_vol.min)}-${parseFloat(rowData.liquid_vol.max)}`;
      }
      return '';
    },
    setApplicationNumCell(rowData) {
      if (rowData.application_num !== null) {
        return parseFloat(rowData.application_num.max);
      }
      return '';
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('usesDosesAuthProduct');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'usesDosesAuthProduct.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Vademecum', ['getPhytosanitaryProductDetail']),
  },
};
</script>
<style lang="scss" scoped>
@import './usesDosesAuthProduct.styles.scss';
</style>
