import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import { treatmentOrderService, treatmentOrderStatus } from '@/services/treatmentOrderService';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'treatment-order',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
  },
  props: [],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    const dataPlantation = {
      companyId: this.currentPlantation.companyId,
      idPlantation: this.currentPlantation.id,
    };
    treatmentOrderService.getAll(dataPlantation).then((response) => {
      this.items = response.data;
    });
  },
  methods: {
    add() {
      this.$f7.views.main.router.navigate('/utilities/treatment/treatment-create-order',
        { reloadCurrent: true });
    },
    getNameStatus(status) {
      switch (status) {
        case treatmentOrderStatus.EXECUTED:
          return this.$t('treatment.treatmentPanel.executed');
        case treatmentOrderStatus.CANCELLED:
          return this.$t('treatment.treatmentPanel.cancel');
        default:
        case treatmentOrderStatus.PLANNED:
          return this.$t('treatment.treatmentPanel.planned');
      }
    },
    getClassStatus(status) {
      switch (status) {
        case treatmentOrderStatus.EXECUTED:
          return 'label-success';
        case treatmentOrderStatus.CANCELLED:
          return 'label-danger';
        default:
        case treatmentOrderStatus.PLANNED:
          return 'label-primary';
      }
    },
    viewItem(item) {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-detail-order',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    editItem(item) {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-edit-order',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    deleteItem(item) {
      const that = this;
      this.$f7.dialog.create({
        title: this.$t('Confirm_Delete_Title'),
        text: this.$t('Confirm_Delete_Text'),
        closeByBackdropClick: 'true',
        buttons: [
          {
            text: this.$t('No'),
          },
          {
            text: this.$t('Ok'),
            onClick() {
              const { companyId } = that.currentPlantation;
              treatmentOrderService.delete(item.id, companyId);
              that.$f7.views.main.router.navigate('/searcher/treatment/', { reloadCurrent: true });
            },
          },
        ],
      }).open();
    },
    formatingDate(e) {
      return moment(e.date).format('DD/MM/YYYY');
    },
  },
};
