<template>
  <div
    class="content-devx"
  >
    <f7-block class="margin-bottom">
      <f7-row>
        <f7-col>
          <f7-block-title>
            {{ isNotEditable ? $t('Searcher.observations.detail_observation_title') : $t('Searcher.observations.edit_observation_title') }}
          </f7-block-title>
        </f7-col>
        <f7-col
          v-if="hideBtn"
          style="width: fit-content"
        >
          <DxButton
            icon="edit"
            name="btnEditar"
            :on-click="goToEditObservation"
          />
          <DxButton
            icon="trash"
            name="btnBorrar"
            :on-click="callToDeleteObservation"
          />
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-row no-gap>
      <f7-col width="85">
        <DxForm
          id="mainData-form"
          :show-colon-after-label="false"
          :read-only="readOnly"
        >
          <DxGroupItem>
            <DxSimpleItem
              name="date"
              :data-field="$t('Searcher.observations.date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeDate,
                value: currentValuesMainData.date,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.dateValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              name="geolocation"
              :data-field="$t('Searcher.observations.geolocation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: scoutingPoints,
                onValueChanged: onGeolocationChange,
                value: currentValuesMainData.idGeolocationPoint,
                displayExpr: 'name',
                valueExpr: 'id',
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.geolocationValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              name="categoryName"
              :data-field="$t('Searcher.observations.category')"
              :editor-options="{
                value: currentValuesMainData.category,
                readOnly: true,
              }"
            />
            <DxSimpleItem
              name="subcategoryName"
              :data-field="$t('Searcher.observations.subcategory')"
              :editor-options="{
                value: currentValuesMainData.subcategoryName,
                readOnly: true,
              }"
            />
          </DxGroupItem>
        </DxForm>
      </f7-col>
      <f7-col width="10">
        <DxForm
          v-if="!readOnly"
          id="form"
          :show-colon-after-label="false"
        >
          <DxSimpleItem
            data-field=""
            :editor-options="{
              disabled: true,
              height: 41
            }"
          />
          <DxButtonItem
            :button-options="addGeolocationPoint"
            horizontal-alignment="center"
          />
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
  DxGroupItem,
  DxButtonItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ViewMainData',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxGroupItem,
    DxButtonItem,
    DxButton,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    scoutingPoints: {
      type: Array,
      default: () => [],
    },
    currentValuesMainData: {
      type: Object,
      default: () => {},
    },
    hideBtn: {
      type: Boolean,
      default: true,
    },
    idCurrentObservation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      isNotEditable: this.hideBtn,
      showDataBeforeSearchOption: false,
      addGeolocationPoint: {
        icon: 'add',
        onClick: () => {
          this.$f7.views.main.router.navigate('/createObservationPoint/', { reloadCurrent: true });
        },
      },
    };
  },
  methods: {
    async updateCurrentScoutingPoint(scoutingPointId) {
      if (scoutingPointId !== '') {
        this.$f7.preloader.show();
        try {
          await this.fetchCurrentScoutingPoint({ companyId: this.companyId, scoutingPointId });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    goToEditObservation() {
      this.$f7.views.main.router.navigate('/editObservation/', {
        reloadCurrent: true,
      });
    },
    async callToDeleteObservation() {
      this.$f7.preloader.show();
      try {
        await this.deleteObservation({ companyId: this.companyId, observationId: this.idCurrentObservation });
        this.$f7.views.main.router.navigate('/searcher/observations/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    onChangeDate(e) {
      this.setDate(e.value);
    },
    onGeolocationChange(e) {
      this.resetCurrentScoutingPoint();
      this.updateCurrentScoutingPoint(e.value);
      this.setGeolocation(e.value);
    },
    ...mapActions('SearcherObservations', ['resetCurrentScoutingPoint', 'deleteObservation', 'setDate', 'setGeolocation', 'fetchCurrentScoutingPoint']),
  },
};
</script>

<style lang="scss" scoped>
@import './ViewMainData.styles.scss';
</style>
