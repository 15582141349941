<template>
  <DxTabPanel
    v-if="loaded"
    class="backgroundColor"
  >
    <DxItem
      name="DetailTabs_machinary_title"
      :title="$t('DetailTabs_machinary_title')"
      template="machinary-tab"
    />
    <DxDetailTabMachinary
      slot="machinary-tab"
      :data="currentTaskFormatted.machinery"
      type-tab="machinary"
    />
    <DxItem
      name="DetailTabs_driver_title"
      :title="$t('DetailTabs_driver_title')"
      template="drivers-tab"
    />
    <DxDetailTabDriver
      slot="drivers-tab"
      :data="currentTaskFormatted.drivers"
      type-tab="drivers"
    />
    <!-- Attachments -->
    <DxItem
      name="DetailTabs_attachment_title"
      :title="$t('DetailTabs_attachment_title')"
      template="Attachments-tab"
    />
    <DxDetailTabAttachment
      slot="Attachments-tab"
      :attachments-selected="currentTaskFormatted.Attachments"
      type-tab="Attachment"
    />
    <!-- tools -->
    <DxItem
      name="DetailTabs_tools_title"
      :title="$t('DetailTabs_tools_title')"
      template="tools-tab"
    />
    <DxDetailTab
      slot="tools-tab"
      :tools-selected="currentTaskFormatted.Tools"
      type-tab="tools"
    />
    <!-- Products -->
    <DxItem
      name="DetailTabs_products_title"
      :title="$t('DetailTabs_products_title')"
      template="products-tab"
    />
    <DxDetailTabProduct
      slot="products-tab"
      :products-selected="currentTaskFormatted.products"
      type-tab="products"
    />
    <DxItem
      name="DetailTabs_workers_title"
      :title="$t('DetailTabs_workers_title')"
      template="workers-tab"
    />
    <DxDetailTabWorkers
      slot="workers-tab"
      :workers-selected="currentTaskFormatted.workers"
      type-tab="workers"
    />
    <DxItem
      name="DetailTabs_locations_title"
      :title="$t('DetailTabs_locations_title')"
      template="locations-tab"
    />
    <DxDetailTabLocations
      slot="locations-tab"
      :locations="currentTaskFormatted.location"
      :locations-tree="locationsTree"
      type-tab="locations"
    />

    <DxItem
      name="DetailTabs_nursery_title"
      :title="$t('DetailTabs_nursery_title')"
      template="nursery-tab"
    />
    <DxContentBin
      slot="nursery-tab"
      :nursery="currentTaskFormatted.nursery"
      type-tab="nursery"
    />
  </DxTabPanel>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxDetailTabMachinary from './DxDetailTabMachinary.vue';
import DxDetailTabDriver from '../workOrder/DxDetailTabDriver.vue';
import DxDetailTabLocations from './DxDetailTabLocations.vue';
import DxDetailTabWorkers from './DxDetailTabWorkers.vue';
import DxDetailTabAttachment from './DxDetailTabAttachment.vue';
import DxDetailTabProduct from './DxDetailTabProduct.vue';
import DxContentBin from '../greenhouse/DxContentBin.vue';
import DxDetailTab from './DxDetailTab.vue';
import Api from '../../services/Api';

export default {
  name: 'DxDetailTabsTasks',
  components: {
    DxTabPanel, DxItem, DxDetailTabMachinary, DxDetailTabLocations, DxDetailTabWorkers, DxDetailTab, DxDetailTabAttachment, DxDetailTabProduct, DxContentBin, DxDetailTabDriver,
  },
  props: {
    currentTaskId: {
      type: String,
      default: '',
    },
    locationsTree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      dataDrivers: [],
      currentTaskFormatted: {},
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getTaskByID(this.currentTaskId);
      const currentTask = JSON.parse(xhr.response);
      this.currentTaskFormatted = {
        Planner: currentTask.planner,
        machinery: this.formattingMachinery(currentTask.planner.items),
        drivers: this.formattingDrivers(currentTask.items_drivers),
        attachments: currentTask.attachments_vehicles,
        tools: currentTask.planner.tools,
        workers: currentTask.workers,
        location: currentTask.location,
        nursery: currentTask.nursery,
        products: currentTask.products,
      };
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async getItems(type, family) {
      const xhr = await Api.getItems(type, family);
      return JSON.parse(xhr.response);
    },
    formattingMachinery(items) {
      const machineryFormatted = [];
      for (const item of items) {
        machineryFormatted.push({
          count: item.count,
          type: item.type,
        });
      }
      return machineryFormatted;
    },
    formattingDrivers(drivers) {
      const driversFormatted = [];
      for (const driver of drivers) {
        driversFormatted.push({
          vehicle: `${driver.item_data.description} ${driver.item_data.code}`,
          driver: `${driver.driver_data.name} ${driver.driver_data.surname}`,
        });
      }
      return driversFormatted;
    },
  },
};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
