<template>
  <f7-block>
    <div
      v-if="(treeLocations.length !== 0 && observations.length !== 0)"
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
    <div
      v-else
      style="display: block; text-align: center;"
    >
      {{ $t('GISGeoJsonMap_not_observations') }}
    </div>
  </f7-block>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISGeoJsonObservationMap',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '450px' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    observations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      initialDate: Date.now(),
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    if (this.treeLocations.length !== 0 && this.observations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            if (typeof children.coordinates !== 'undefined' && children.coordinates !== null) {
              this.map.setView([children.coordinates.latitude, children.coordinates.longitude], this.zoom);
            }
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            this.layersEvents(layer);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
              this.layersEvents(layer2);
            }
          }
        }
        const markerLayer = L.layerGroup().addTo(this.map);

        let marker;
        const markers = this.observations;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].coordinates.length > 0) {
            marker = L.marker([markers[i].coordinates[1], markers[i].coordinates[0]], {
              properties: {
                date: markers[i].date,
                category: markers[i].category,
                subcategory: markers[i].subcategory,
                name: markers[i].pointName,
              },
            });
            markerLayer.addLayer(marker);

            const blueIcon = new L.Icon({
              iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            });
            marker.setIcon(blueIcon);
          }
        }
        this.layersEvents(markerLayer);
        if (this.center !== null) {
          this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        } else {
          const bounds = layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(layer);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            // eslint-disable-next-line no-prototype-builtins
            if (e.target.hasOwnProperty('feature')) {
              self.openLayerDataPopup(e.target.feature.properties, e);
            } else {
              self.openLayerDataPopup(e.target.options.properties, e);
            }
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="fa fa-home" style="line-height:1.65 blue;"></i>',
            zoomHomeTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
              `${controlName}-home`, container, this.zoomHome);

            return container;
          },

          zoomHome() {
            self.map.fitBounds(self.layerBounds);
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null || property === 'id' || property === 'plantation_id' || property === 'company_id') continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${this.$t(`${property}_popup`)}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
