<template>
  <div>
    <DxPopup
      :visible="showRegisterSuccessCampaignPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :on-hidden="onHidden"
      :show-title="true"
      :width="500"
      height="auto"
      :show-close-button="false"
      :title="$t('campañia_register')"
    >
      <template>
        <f7-block
          class="text-align-center center"
        >
          <div class="title">
            {{ $t('Register_completed') }}
          </div>
        </f7-block>
      </template>
      <f7-block-footer
        class="content-action-botton"
      >
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewPlantation"
        >
          {{ $t("End_Campaign") }}
        </f7-button>
      </f7-block-footer>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'DxPopUpRegisterCampania',
  components: {
    DxPopup,
  },
  data() {
    return {
      actualPlot: '',
    };
  },
  computed: {
    ...mapState('Campania', ['showRegisterSuccessCampaignPopup']),
  },
  methods: {
    onHidden() {
      this.setShowRegisterSuccessCampaignPopup(false);
    },
    async createNewPlantation() {
      this.setShowRegisterSuccessCampaignPopup(false);
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    ...mapActions('Campania', ['setShowRegisterSuccessCampaignPopup', 'createCampaign']),
  },
};
</script>

<style>
.title {
  margin: 1rem;
  padding: 1rem;
  /* IMPORTANTE */
  text-align: center;
}
.finishedRegister {
    margin-left: 55%;
}
.center{
  padding-top: 3%;
  font-weight: bold;
}
</style>
