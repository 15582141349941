var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation'),"align-item-labels-in-all-groups":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.specie_botanic'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.speciesAvailables,
            value: _vm.specieSelected,
            showClearButton: true,
            searchEnabled:true,
            onValueChanged: _vm.onChangeSpecieBotanic,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.variety'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.varieties,
            displayExpr: 'name',
            valueExpr: 'name',
            value: _vm.varietySelected,
            searchEnabled:true,
            onValueChanged: _vm.onChangeVariety,
            showDataBeforeSearch: false,
            minSearchLength: 3,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.distance_between_streets')) + " (m)"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulUno,
            onValueChanged: _vm.onChangeMulUno,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.distance_between_feets')) + " (m)"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulDos,
            onValueChanged: _vm.onChangeMulDos,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":((_vm.$t('registerPlantation.plants_density')) + "(" + (_vm.$t('registerPlantation.unit_plantas_ha')) + ")"),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.calculatePlantsDensity,
            onValueChanged: _vm.onChangePlantDensity,
            readOnly: true,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.plants_number'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.calculatePlantsNumber,
            onValueChanged: _vm.onChangePlantsNumber,
            readOnly: true,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }