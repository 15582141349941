<template>
  <div class="content-devx">
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('title_register_plantation')"
      >
        <DxGroupItem :col-count="2">
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.specie_botanic')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: speciesAvailables,
                value: specieSelected,
                showClearButton: true,
                searchEnabled:true,
                onValueChanged: onChangeSpecieBotanic,
                searchEnabled:true
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.variety')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: varieties,
                displayExpr: 'name',
                valueExpr: 'name',
                value: varietySelected,
                searchEnabled:true,
                onValueChanged: onChangeVariety,
                showDataBeforeSearch: false,
                minSearchLength: 3,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="`${$t('registerPlantation.distance_between_streets')} (m)`"
              editor-type="dxNumberBox"
              :editor-options="{
                value: mulUno,
                onValueChanged: onChangeMulUno,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="`${$t('registerPlantation.distance_between_feets')} (m)`"
              editor-type="dxNumberBox"
              :editor-options="{
                value: mulDos,
                onValueChanged: onChangeMulDos,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="`${$t('registerPlantation.plants_density')}(${$t('unit_plantas_ha')})`"
              editor-type="dxNumberBox"
              :editor-options="{
                value: calculatePlantsDensity,
                readOnly: true,
                onValueChanged: onChangePlantsDensity,
              }"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              name="plants_numbere"
              editor-type="dxNumberBox"
              :data-field="$t('registerPlantation.plants_number')"
              :editor-options="{
                value: calculatePlantsNumber,
                readOnly: true,
                onValueChanged: onChangePlantsNumber,
              }"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              editor-type="dxNumberBox"
              :data-field="`${$t('registerPlantation.cup_diameter')} (m)`"
              :editor-options="{
                value: cupDiameter,
                onValueChanged: onChangeCupDiameter,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('registerPlantation.plantation_date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangePlantationDate,
                value: isEdit && actualPlantation.plantation_date !== null ? actualPlantation.plantation_date : plantationDate,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RegisterLenoso',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      actualName: '',
      hectareToM2: 10000,
      seedForKg: 500,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['plantationName', 'protectionTypeSelected', 'managementTypeSelected',
      'initDate', 'endDate', 'cropTypeSelected', 'varietySelected', 'specieSelected', 'varieties', 'speciesAvailables',
      'plantationDate', 'mulUno', 'mulDos', 'sectorArea', 'seedDensity', 'actualPlantation', 'isEdit', 'cupDiameter']),

    calculatePlantsNumber() {
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      if (this.mulUno !== 0 && this.mulDos !== 0) return parseInt((this.sectorArea * this.hectareToM2) / (this.mulUno * this.mulDos), 10);
      return 0;
    },
    calculatePlantsDensity() {
      if (this.mulUno === 0 && this.mulDos === 0) return 0;
      if (this.mulUno !== 0 && this.mulDos !== 0) return parseInt(Math.ceil(((1 / (this.mulUno * this.mulDos)) * this.hectareToM2)), 10);
      return 0;
    },
  },
  async beforeMount() {
    if (this.isEdit) {
      for (const propertie of this.actualPlantation.farms[0].properties) {
        if (propertie.key === 'area') {
          this.setSectorArea(propertie.value);
        }
      }
      this.setSpecieBotanic(this.actualPlantation.species);
      this.setVariety(this.actualPlantation.variety);
      this.setMulUno(this.actualPlantation.plantation_frame.x);
      this.setMulDos(this.actualPlantation.plantation_frame.y);
      this.setCupDiameter(this.actualPlantation.cup_diameter);
      this.setPlantationDate(this.actualPlantation.plantation_Date);
      this.setPlantsNumber(this.actualPlantation.plants);
      this.setPlantsDensity(this.actualPlantation.plants_density);
      try {
        await this.getSelectBoxSpecies(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.cropTypeSelected : this.actualPlantation.crop_type);
        await this.getSelectBoxVariety(this.actualPlantation.crop_type !== this.cropTypeSelected ? this.specieSelected : this.actualPlantation.species);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    }
  },
  methods: {
    async onChangeSpecieBotanic(e) {
      this.setSpecieBotanic(e.value);
      try {
        await this.getSelectBoxVariety(e.value);
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      }
    },
    onChangeVariety(e) {
      this.setVariety(e.value);
    },
    onChangeMulUno(e) {
      this.setMulUno(e.value);
    },
    onChangeMulDos(e) {
      this.setMulDos(e.value);
    },
    onChangeCupDiameter(e) {
      this.setCupDiameter(e.value);
    },
    onChangePlantationDate(e) {
      this.setPlantationDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    onChangePlantsNumber(e) {
      this.setPlantsNumber(e.value);
    },
    onChangePlantsDensity(e) {
      this.setPlantsDensity(e.value);
    },
    ...mapActions('RegisterPlantation', ['setPlantationName', 'setProtectionType', 'setManagementType',
      'setInitDate', 'setEndDate', 'setPlantationDate', 'setSpecieBotanic', 'setVariety', 'setMulUno', 'setMulDos',
      'setCupDiameter', 'setCultiveType', 'setPlantationDate', 'setPlantsNumber', 'setPlantsDensity', 'getSelectBoxVariety',
      'initStore', 'getSelectBoxSpecies', 'setSectorArea']),
  },
};
</script>
