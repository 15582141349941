import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
} from 'devextreme-vue/chart';
import ProductDataFormatter from '@/pages/wizardNutrition/report-formatter/product-data-formatter';
import {
  DxDataGrid, DxColumn,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'product-resume',
  components: {
    DxDataGrid,
    DxColumn,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
  },
  props: ['fertilization'],
  data() {
    return {
      productResume: [],
      stackProduct: [],
      plantation: {},
    };
  },
  computed: {
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  async mounted() {
    this.setProductsNotInNutritionPlan([]);
    const formatter = new ProductDataFormatter(this.fertilization);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
      this.setProductsNotInNutritionPlan(notFoundNutritionProducts);
    }

    this.productResume = formatter.productResume;
    this.stackProduct = formatter.stackProduct;
    this.plantation = formatter.plantation;
  },
  methods: {
    customizeTooltip(point) {
      return {
        html: `<div><div class='tooltip-header'>${
          point.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: ${point.seriesName} </span>
          </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}: ${point.value} kg/ha</span> `,
      };
    },
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};
