<template>
  <f7-block>
    <GISLayerOptionsComparator
      v-if="(typeof dateList.avaiable_dates) !== 'undefined'"
      :key-id="`${mainMap}`"
      :default-index="'NDVI'"
      :dates-list="dateList"
      :set-three-cols="true"
    />
    <f7-row>
      <f7-col>
        <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}`] === ''">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISWMSMap
            :zoom="zoom"
            :name-map="`${mainMap}`"
            :layer="layer"
            :index="currentIndexes[`${mainMap}`]"
            :height="'450px'"
            :center="center"
          />
        </f7-block>
      </f7-col>
      <!-- TODO: Se oculta hasta que le histograma esté integrado en back
      <f7-col>
        <f7-block v-if="!histogram">
          <div class="loadingFalse">
            <div v-if="dataUnavailable">
              <p>{{ $t('No_Data') }}</p>
            </div>
            <div
              v-else
              class="preloader"
            />
          </div>
        </f7-block>
        <f7-row v-else>
          <HistogramGraph
            :histogram="histogram[0].histogram"
            :showing-date="histogram[0].iso_date"
            :index="currentIndexes.GisWMS"
          />
        </f7-row>
      </f7-col>
      -->
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import GISWMSMap from '../../../Gis/GISWMSMap/index.vue';
import GISLayerOptionsComparator from '../../../Gis/LayerOptionsComparator/index.vue';
import HistogramGraph from '../histogramGraph/index.vue';
import Api from '../../../../services/Api';

export default {
  name: 'GisWMS',
  components: {
    GISWMSMap,
    GISLayerOptionsComparator,
    HistogramGraph,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'GisWMS',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      dateList: {},
      dataUnavailable: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'datesList', 'histogram', 'currentInitIntervalDates', 'histogramValues']),
  },
  async beforeMount() {
    try {
      this.setHistogram(null);
      this.layer.options.layers = `client_efemis:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchDatesLayer(this.layer.options.layers.split(':')[1]);
      this.dateList = this.datesList;
      await this.fetchHistogram({
        location: `${this.currentPlantation.sectorId}`, date: moment(this.dateList.avaiable_dates ? this.dateList.avaiable_dates[this.dateList.avaiable_dates.length - 1] : this.dateList[this.dateList.length - 1]).format('YYYY-MM-DD'), company: this.currentPlantation.companyId,
      });
      this.filterHistogramByIndex();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loadedGIS = true;
    }
  },
  methods: {
    resetConfig(key) {
      this.setCurrentIndexes({
        key,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key,
        newCurrentInitIntervalDate: '',
      });
      this.setCurrentEndIntervalDates({
        key,
        newCurrentEndIntervalDate: '',
      });
    },
    goToGisGrid() {
      this.$f7.views.main.router.navigate('/gisGrid/', { reloadCurrent: true });
    },
    filterHistogramByIndex() {
      const histogramDate = this.dateList.avaiable_dates ? this.dateList.avaiable_dates[this.dateList.avaiable_dates.length - 1] : this.dateList[this.dateList.length - 1];
      const histogramInformation = [];

      if (this.histogramValues.length < 1) {
        this.setHistogram(null);
        this.dataUnavailable = true;
        return;
      }

      this.histogramValues.forEach((histogramWithIndex) => {
        if (histogramWithIndex.index.toString().toUpperCase() === this.currentIndexes.GisWMS.toString()) {
          histogramInformation.push({ histogram: histogramWithIndex.histogram, iso_date: histogramDate });
        }
      });

      this.setHistogram(histogramInformation);
    },
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchDatesLayer', 'fetchHistogram', 'setHistogram']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisWMS.styles.scss';
</style>
