var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-devx"},[_c('f7-block',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t('registerPlantation.step2')))]),_vm._v("  "+_vm._s(_vm.$t('registerPlantation.subtitle_step2'))+"\n    ")]),_vm._v(" "),_c('f7-block',[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.plantationName'),"name":"name","editor-options":{
              value: _vm.plantationName,
              showClearButton: true,
              onValueChanged: _vm.onChangeName,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"cultive_type","data-field":_vm.$t('registerPlantation.cultive_type'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.cropTypes,
              value: _vm.cropTypeSelected,
              onValueChanged: _vm.onChangeCultiveType,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"protected","data-field":_vm.$t('registerPlantation.protected'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.protectionTypes,
              value: _vm.protectionTypeSelected,
              onValueChanged: _vm.onChangeProtectionType,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.init_date'),"name":"init_date","editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeInitDate,
              value: _vm.initDate,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"manejo","data-field":_vm.$t('registerPlantation.manejo'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.managementTypes,
              value: _vm.managementTypeSelected,
              onValueChanged: _vm.onChangeManagement,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('registerPlantation.end_date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeEndDate,
              value: _vm.endDate,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1)],1)],1)],1),_vm._v(" "),(_vm.cropTypeSelected === 'HORTÍCOLA')?_c('div',[_c('RegisterHorticola',{attrs:{"species-availables":_vm.speciesAvailables}})],1):_vm._e(),_vm._v(" "),(_vm.cropTypeSelected === 'HERBÁCEO')?_c('div',[_c('RegisterHebacea',{attrs:{"species-availables":_vm.speciesAvailables}})],1):_vm._e(),_vm._v(" "),(_vm.cropTypeSelected === 'LEÑOSO')?_c('div',[_c('RegisterLenioso',{attrs:{"species-availables":_vm.speciesAvailables}})],1):_vm._e(),_vm._v(" "),_c('DxPopUPRegisterParcelSectorByPlantation',{attrs:{"parcels":_vm.parcels,"sectors":_vm.sectors}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }