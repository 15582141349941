<template>
  <f7-block v-if="!loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <f7-block v-else>
    <DxDataGrid
      if="effortGrid"
      :data-source="effortFormated"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      @exporting="onExporting"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxEffort"
      />
      <DxExport :enabled="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="level"
        :caption="$t('DxEffort_level_caption')"
      />
      <DxColumn
        data-field="totalHours"
        :caption="$t('DxEffort_totalHours_caption')"
        alignment="right"
      />
      <DxColumn
        data-field="ha"
        caption="HA"
        alignment="right"
      />
      <DxColumn
        data-field="totalHoursHa"
        :caption="$t('DxEffort_totalHoursHa_caption')"
        alignment="right"
      />
    </DxDataGrid>
  </f7-block>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState } from 'vuex';
import moment from 'moment';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
  },
  props: {
    init: {
      type: String,
      default: moment.utc().startOf('year').format('YYYY-MM-DD'),
    },
    end: {
      type: String,
      default: moment.utc().add(1, 'd').format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      loaded: false,
      effort: [],
      effortFormated: [],
      locationsAvailables: [],
      event: 'updateEffort',
    };
  },
  computed: {
    ...mapState('Reporting', ['selectedFarmList', 'locationsTree']),
  },
  mounted() {
    EventBus.$on(this.event, this.updateLocations);
  },
  beforeDestroy() {
    EventBus.$off(this.event, this.updateLocations);
  },
  methods: {
    // ---- OBTENCIÓN Y FORMATEO DE LOCATIONS ----
    async getLocationsAvailables() {
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsAvailables.push({
        name: item.name,
        bd_id: item.bd_id,
        area: item.area,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    // ---- OBTENCIóN Y FORMATEO DE ACTIVITIES Y TASKS ----
    async getEffort() {
      const xhr = await Api.getEffortByLocations(this.selectedFarmList, this.init, this.end);
      return JSON.parse(xhr.response);
    },
    formattingEffort() {
      const totalHoursLocations = this.getTotalHoursLocations();
      this.effortFormated.length = 0;
      for (const item of this.effort) {
        let totalHoursHa = 0;
        try {
          totalHoursHa = ((item.parameters.hours * 100) / totalHoursLocations).toFixed(2);
          if (totalHoursHa.isNaN()) {
            totalHoursHa = 0;
          }
        } catch (e) {
          totalHoursHa = 0;
        }
        this.effortFormated.push({
          level: this.getLocationNameById(item.location),
          hrHours: (item.parameters.hours).toFixed(2).toString(),
          totalHours: (item.parameters.hours).toFixed(2).toString(),
          ha: (this.getAreaLocationById(item.location)).toFixed(2).toString(),
          totalHoursHa: (
            (item.parameters.hours / this.getAreaLocationById(item.location)).toFixed(2)
          ).toString(),
          totalHoursTotalHa: `${totalHoursHa}%`,
          progressCompleted: this.getProgressComplete(item.parameters),
        });
      }
    },
    getProgressComplete(item) {
      if (item.real_performance !== 0 && item.goal_performance !== 0) {
        return `${((item.real_performance * 100) / item.goal_performance).toFixed(0)}%`;
      }
      return `${0}%`;
    },
    getTotalHoursLocations() {
      let totalHours = 0;
      for (const item of this.effort) {
        totalHours += item.parameters.hours;
      }
      return totalHours;
    },
    getLocationNameById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.name;
        }
      }
      return '';
    },
    getAreaLocationById(id) {
      for (const location of this.locationsAvailables) {
        if (location.bd_id === id) {
          return location.area;
        }
      }
      return 0;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('CurrentEffort');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'CurrentEffort.xlsx',
          );
        });
      });
      e.cancel = true;
    },
    async updateLocations() {
      this.loaded = false;
      try {
        await this.getLocationsAvailables();
        this.effort = await this.getEffort();
        this.formattingEffort();
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.loaded = true;
      }
    },
    getEffortByLocations(locations = [], init = '', end = '') {
      let url = `${baseUrl}/report/effort?`;
      for (let i = 0; i < locations.length; i += 1) {
        url += `locations=${locations[i]}&`;
      }
      url += `init=${init}&`;
      url += `end=${end}`;
      const payload = {
        data: null,
      };
      return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, company);
    },
  },
};
</script>
<style scoped>
</style>
