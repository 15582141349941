<template>
  <f7-page
    resizable
  >
    <navbar
      v-if="(!isView || isEdit) && !isFromCampaign"
      :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name ? actualLocation.name : actualPlantation.explotation} > ${currentParcel.name ? currentParcel.name : actualPlantation.parcel} > ${currentSector.sectorName ? currentSector.sectorName : actualPlantation.farms[0].name} > ${actualCampaign.name ? actualCampaign.name : actualPlantation.campaign} > ${$t('registerPlantation.title_register_plantation')}`"
    />
    <navbar
      v-else
      :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualCampaign ? actualCampaign.name + ' > ' : ''} ${$t('registerPlantation.title_register_plantation')}`"
    />
    <RegisterPlantationStep1 />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="goToStep2"
        >
          {{ $t("Next") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterPlantationStep1 from '../../../../components/plantation/registerPlantation/registerPlantationStep1/index.vue';
import navbar from '../../../../components/NavBar.vue';

export default {
  name: 'RegisterPlantStep01',
  components: {
    RegisterPlantationStep1,
    navbar,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation', 'parcels', 'sectors', 'parcelSelected', 'sectorSelected', 'isEdit']),
    ...mapState('Campania', ['isView', 'actualCampaign', 'viewCampaignId', 'isFromCampaign']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Sector', ['currentSector']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
  },
  methods: {
    async goToBack() {
      if (this.isEdit) {
        this.$f7.views.main.router.navigate('/ViewPlantation/', { reloadCurrent: true });
      } else if (this.isView) {
        await this.getCampaignsById(this.viewCampaignId);
        this.$f7.views.main.router.navigate('/viewCampania/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
      }
    },
    goToStep2() {
      if (this.parcelSelected === '') {
        this.$f7.dialog.alert(this.$t('parcel_is_required'));
      } else if (this.sectorSelected === '') {
        this.$f7.dialog.alert(this.$t('sector_is_required'));
      } else {
        this.$f7.views.main.router.navigate('/RegisterPlantationStep2/', { reloadCurrent: true });
      }
    },
    ...mapActions('RegisterPlantation', ['getParcelByExplotation', 'resetSelectBoxStepUno']),
    ...mapActions('Campania', ['setActualPlot', 'setIsView', 'getCampaignsById']),
  },
};
</script>
<style lang="scss">

</style>
