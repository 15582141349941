<template>
  <div class="content-gis-editor">
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import turf from 'turf';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISEditor',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '80vh' },
    nameMap: { type: String, default: '' },
    center: {
      type: Object,
      default: () => {},
    },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    disabledEditParcel: {
      type: Boolean,
      default: true,
    },
    disabledEditSector: {
      type: Boolean,
      default: true,
    },
    createSector: {
      type: Boolean,
      default: false,
    },
    showMarker: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: true,
    },
    showGPSButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      parcel: null,
      sector: null,
      initialDate: Date.now(),
      layerGroup: L.layerGroup(),
      layerGroupParcel: L.layerGroup(),
      copyLayer: null,
      pmControlConfig: {
        position: 'topright',
        drawPolygon: true,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawCircleMarker: false,
      },
      pmControlConfigEdit: {
        position: 'topright',
        drawPolygon: false,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: true,
        dragMode: false,
        cutPolygon: false,
        removalMode: true,
        drawCircleMarker: false,
      },
      hectareToM2: 10000,
      layerStyles: {
        default: {
          color: 'rgba(255,0,224,0.7)',
          weight: 1,
          fillOpacity: 0.5,
        },
        active: {
          weight: 3,
          color: 'rgba(241,234,234,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
        selected: {
          weight: 2,
          color: 'rgba(250,250,120,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
      },
      markerLayer: L.layerGroup(),
      layer: {},
      layer2: {},
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'currentGeoJSONLayer',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    this.renderMap();
    this.addFuncionalities();
    this.addOverlayLayer();
    this.addDrawControl();
    this.createLayer();
    this.layerEvents();
    this.layerParcelEvents();
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        if (this.treeLocations.length !== 0) {
          for (const geoData of this.treeLocations) {
            for (const children of geoData.children) {
              if (typeof children.coordinates !== 'undefined' && children.coordinates !== null) {
                this.map.setView([children.coordinates.latitude, children.coordinates.longitude], this.zoom);
              }
              this.layer = L.geoJson(children.geo_feature, { pmIgnore: this.disabledEditParcel }).addTo(this.map);
              this.parcel = this.layer;
              if (!this.disabledEditParcel) {
                this.layerGroup.addLayer(this.layer);
              }
              if (!this.createSector) {
                for (const children2 of children.children) {
                  this.layer2 = L.geoJson(children2.geo_feature, { pmIgnore: this.disabledEditSector }).addTo(this.map);
                  this.layer2.setStyle(this.layerStyles.active);
                  if (!this.disabledEditSector) {
                    this.layerGroup.addLayer(this.layer2);
                  }
                }
              }
            }
          }
        }
        if (this.center !== null) {
          this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
          if (this.showMarker) {
            this.setMarker();
          }
        } else {
          const bounds = this.layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(this.layer);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.layerGroup.addTo(this.map);
      this.markerLayer.addTo(this.map);
      this.layerGroupParcel.addTo(this.map);
    },

    setMarker() {
      const marker = new L.Marker({
        lat: this.center.latitude,
        lng: this.center.longitude,
      },
      {
        draggable: false,
      }).addTo(this.map);
      const redIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      marker.setIcon(redIcon);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        if (this.showHomeButton) {
          this.addHomeButton();
        }
        if (this.showGPSButton) {
          this.addGPSButton();
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fa fa-home" style="line-height:1.65 blue;"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
            `${controlName}-home`, container, this.zoomHome);

          return container;
        },

        zoomHome() {
          if (self.center !== null) {
            self.map.setView([self.center.latitude, self.center.longitude], self.zoom);
          } else {
            self.map.fitBounds(self.layerBounds);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="fa fa-crosshairs" style="line-height:1.65 blue;"></i>',
          zoomLocationTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(options.zoomLocationText, options.zoomLocationTitle,
            `${controlName}-home`, container, this.zoomLocation);

          return container;
        },

        zoomLocation() {
          let marker;

          self.map.locate({
            setView: true,
            maxZoom: 120,
          }).on('locationfound', (e) => {
            if (!marker) {
              marker = new L.Marker(e.latlng, {
                draggable: false,
              }).addTo(self.markerLayer);
              const redIcon = new L.Icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
              });
              marker.setIcon(redIcon);
              self.map.setZoom(self.zoom);
            } else {
              marker.setLatLng(e.latlng);
            }
          }).on('locationerror', (error) => {
            if (marker) {
              self.map.removeLayer(marker);
              marker = undefined;
            }
          });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },

    addDrawControl() {
      if (this.treeLocations.length === 0 || this.createSector) {
        this.map.pm.addControls(this.pmControlConfig);
      } else {
        this.map.pm.addControls(this.pmControlConfigEdit);
      }
    },

    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (this.parcel !== null && this.disabledEditParcel) {
            const sector = e.layer;

            const sectorToGeoJson = sector.toGeoJSON();
            const parcelToGeoJson = this.parcel.toGeoJSON();

            const sectorToTurf = turf.polygon(sectorToGeoJson.geometry.coordinates);
            const parcelToTurf = turf.polygon(parcelToGeoJson.features[0].geometry.coordinates);

            const intersection = turf.intersect(sectorToTurf, parcelToTurf);

            const result = L.geoJson(intersection);
            result.setStyle(this.layerStyles.active);
            this.layerGroup.addLayer(result);
            this.map.removeLayer(sector);
            this.sector = result;
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(this.sector, 0));
            this.layerEvents();
          } else {
            this.layerGroup.addLayer(e.layer);
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(e.layer, 0));
            this.layerEvents();
          }
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${this.$helpers.getFilteredErrorMessage(error)}`));
        } finally {
          self.$f7.preloader.hide();
        }
      });
    },
    getNewLocation(layer, index) {
      const polygon = layer.toGeoJSON();
      let coordinates = [];
      // eslint-disable-next-line no-underscore-dangle
      if (typeof layer._latlngs !== 'undefined') {
        coordinates = this.$helpers.getCoordinates(layer.getLatLngs()[index]);
        coordinates[0].push(coordinates[0][0]);
      } else {
        coordinates = this.$helpers.getCoordinates(layer.pm._layers[0].getLatLngs()[0]);
        coordinates[0].push(coordinates[0][0]);
      }
      return {
        newCoordinates: coordinates,
        index,
        area: turf.area(polygon) / this.hectareToM2,
        centroide: layer.getBounds().getCenter(),
      };
    },
    layerEvents() {
      const self = this;
      this.layerGroup.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            try {
              self.$f7.preloader.show();
              if (this.parcel !== null && this.disabledEditParcel) {
                const sector = e.layer;

                const sectorToGeoJson = sector.toGeoJSON();
                const parcelToGeoJson = self.parcel.toGeoJSON();

                const sectorToTurf = turf.polygon(sectorToGeoJson.geometry.coordinates);
                const parcelToTurf = turf.polygon(parcelToGeoJson.features[0].geometry.coordinates);

                const intersection = turf.intersect(sectorToTurf, parcelToTurf);

                const result = L.geoJson(intersection);
                result.setStyle(self.layerStyles.active);
                this.layerGroup.addLayer(result);

                this.sector = result;
                self.map.removeLayer(sector);

                self.layerEvents();
                self.setLocation(self.getNewLocation(this.sector, 0));
              } else {
                self.setLocation(self.getNewLocation(e.layer, 0));
              }
            } catch (error) {
              this.$f7.dialog.alert(e);
            } finally {
              self.$f7.preloader.hide();
            }
          },
          // eslint-disable-next-line no-unused-vars
          'pm:remove': (e) => {
            self.map.pm.addControls(self.pmControlConfig);
            self.resetAll();
          },
        });
      });
    },
    layerParcelEvents() {
      const self = this;
      this.layerGroupParcel.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            const parcel = e.layer;
            const latlngs = parcel.getLatLngs();
            this.createTemporalLayer(parcel);
            for (let i = 0; i < latlngs[0].length; i += 1) {
              const vertexMarkers = L.marker([latlngs[0][i].lat, latlngs[0][i].lng]);
              const result = self.sector.getBounds().contains(vertexMarkers._latlng);
              if (result) {
                self.layerGroupParcel.clearLayers();
                self.layerGroupParcel.addLayer(this.copyLayer);
                this.parcel = this.copyLayer;
                self.layerParcelEvents();
              }
            }
          },
        });
      });
    },
    createTemporalLayer(shape) {
      const geojsonFeature = {
        type: 'Feature',
        properties: {
          explotation: shape.feature.properties.explotation,
          id: shape.feature.properties.id,
          parcel: shape.feature.properties.parcel,
        },
        geometry: {
          type: 'Polygon',
          coordinates: shape.feature.geometry.coordinates,
        },
      };

      this.copyLayer = L.geoJson(geojsonFeature);
    },
    ...mapActions('GisEditor', ['setLocation', 'resetAll']),
  },

};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
