<template>
  <div>
    <div
      class="content-devx content-dx-daily-planning"
    >
      <DxDataGrid
        id="bareRootsTable"
        ref="bareRootsTable"
        :data-source="availableBareRootsForBins"
        :column-min-width="70"
        :allow-column-reordering="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :row-alternation-enabled="true"
        :selected-row-keys="selectedBareRoots"
        :column-auto-width="true"
        @selection-changed="bareRootsSelectionChanged"
      >
        <DxExport
          :enabled="true"
        />
        <DxPaging :page-size="18" />
        <DxEditing
          :allow-updating="false"
          :allow-deleting="false"
          :allow-adding="false"
          mode="row"
        />
        <DxSelection
          mode="multiple"
          :allow-select-all="true"
          show-check-boxes-mode="always"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="creation"
          name="creation"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_CreationDate')"
        />
        <DxColumn
          data-field="name"
          name="name"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_Name')"
        />
        <DxColumn
          data-field="location"
          name="location"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_Origin')"
        />
        <DxColumn
          data-field="plantingYear"
          name="plantingYear"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_PlantYear')"
        />
        <DxColumn
          data-field="variety"
          name="variety"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_Variety')"
        />
        <DxColumn
          data-field="category"
          name="category"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_Category')"
        />
        <DxColumn
          data-field="classification"
          name="classification"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_ClassType')"
        />
        <DxColumn
          data-field="plants"
          name="plants"
          alignment="left"
          :caption="$t('DxBareRootTable_dxColumn_NumPlants')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxSearchPanel,
  DxExport,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'DxBinsCreateGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxExport,
    DxSelection,

  },
  data() {
    return {
      loaded: false,

    };
  },
  computed: {
    ...mapState('greenhouse',
      [
        'selectedBareRoots',
      ]),
    ...mapGetters('greenhouse',
      [
        'availableBareRootsForBins',
      ]),
  },
  beforeDestroy() {
    this.updateSelectedBareRoots([]);
  },
  beforeMount() {
    const selectedFullBareRoots = [];
    this.availableBareRootsForBins.forEach((element) => {
      if (this.selectedBareRoots.filter((id) => id === element.id).length > 0) {
        selectedFullBareRoots.push(element);
      }
    });
    this.updateSelectedBareRoots(selectedFullBareRoots);
  },
  methods: {
    ...mapActions('greenhouse',
      [
        'updateSelectedBareRoots',
      ]),
    bareRootsSelectionChanged(e) {
      if (e.selectedRowKeys.length > 0) {
        this.updateSelectedBareRoots(e.selectedRowKeys);
      }
    },
  },
};
</script>
