import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    normalEmployees: [],
    supervisors: [],
    drivers: [],
  },
  mutations: {
    setEmployees(state, payload) {
      state.normalEmployees = payload;
    },
    setSupervisors(state, payload) {
      state.supervisors = payload;
    },
    setDrivers(state, payload) {
      state.drivers = payload;
    },
  },
  actions: {
    async fetchEmployeesSupervisorsDrivers({ commit }) {
      try {
        const xhr = await Api.getEmployeesAll();
        const allEmployees = JSON.parse(xhr.response).data;
        const employees = [];
        const supervisor = [];
        const drivers = [];
        for (const employee of allEmployees) {
          if (!employee.supervisor) {
            employees.push(employee);
            if (employee.driver) {
              drivers.push(employee);
            }
          } else {
            supervisor.push(employee);
          }
        }
        commit('setEmployees', employees);
        commit('setSupervisors', supervisor);
        commit('setDrivers', drivers);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setEmployees(context, newEmployees) {
      context.commit('setEmployees', newEmployees);
    },
  },
};
