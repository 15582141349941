<template>
  <div
    v-if="loaded"
    class="content-devx main-dx-machinery-database"
  >
    <DxDataGrid
      id="machineryGrid"
      :data-source="machineryFormated"
      :column-min-width="90"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxMachineryDatabase"
      />
      <DxExport
        :enabled="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="statusColumn"
        name="status"
        :caption="$t('DxMachineryDatabase_column_status')"
        :allow-header-filtering="true"
      />
      <!--sE CAMBIA CODE POR EL CODIGO INTERNO.-->
      <DxColumn
        data-field="code"
        name="code"
        :caption="$t('DxMachineryDatabase_column_code')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="description"
        name="description"
        :caption="$t('DxMachineryDatabase_column_description')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="attachmentColumn"
        name="attachment"
        :caption="$t('DxMachineryDatabase_column_attachment')"
        :allow-header-filtering="true"
        :width="120"
        alignment="center"
      />
      <DxColumn
        data-field="family"
        name="family"
        :caption="$t('DxMachineryDatabase_column_family')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="subfamily"
        name="subfamily"
        :caption="$t('DxMachineryDatabase_column_subfamily')"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="brand"
        name="brand"
        :caption="$t('DxMachineryDatabase_column_brand')"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="$helpers.havePermission(getPermissions.company_update_master_data)"
        type="buttons"
        :fixed="true"
        fixed-position="right"
      >
        <DxButton
          icon="edit"
          :hint="$t('DxDailyPlanning_edit_hint')"
          :on-click="editMachinery"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxButton,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import Api from '../../../services/Api';

export default {
  name: 'DxMachineryDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxButton,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      machineryFormated: [],
      familyAvailables: [],
      subfamilyAvailables: [],
      locationsTree: [],
      locationsTreeFormated: [],
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    ...mapGetters('authentication', ['getPermissions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr2 = await Api.getTreeLocation();
      this.locationsTree = JSON.parse(xhr2.response);
      for (const item of this.locationsTree) {
        this.setLocationTreeFormat(item);
        this.recursiveAddChildrens(item.children);
      }
      const xhr = await Api.getDataSubfamilyByType('MACHINERY');
      const machineryAvailables = JSON.parse(xhr.response);
      for (let i = 0; i < machineryAvailables.length; i += 1) {
        for (let j = 0; j < machineryAvailables[i].items.length; j += 1) {
          for (let x = 0; x < machineryAvailables[i].items[j].items.length; x += 1) {
            this.machineryFormated.push({
              statusColumn: this.formattingStatusColumn(machineryAvailables[i].items[j].items[x].status),
              status: machineryAvailables[i].items[j].items[x].status,
              family: machineryAvailables[i].id,
              subfamily: machineryAvailables[i].items[j].id,
              description: machineryAvailables[i].items[j].items[x].description,
              brand: machineryAvailables[i].items[j].items[x].brand,
              code: machineryAvailables[i].items[j].items[x].code,
              attachment: machineryAvailables[i].items[j].items[x].attachment,
              attachmentColumn: machineryAvailables[i].items[j].items[x].attachment ? this.$t('Yes') : this.$t('No'),
              model: machineryAvailables[i].items[j].items[x].model,
              serialNumber: machineryAvailables[i].items[j].items[x].serial_number,
              plate: machineryAvailables[i].items[j].items[x].plate,
              purchase: this.$helpers.getProperty(machineryAvailables[i].items[j].items[x].properties, 'purchase') ?? moment('1970-01-01').format('DD.MM.YY'), // Se pone esa fecha para que se den cuenta que no proporcionaron fecha de compra en el MD original
              id: machineryAvailables[i].items[j].items[x].id,
            });
          }
        }
      }
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    createMachinery() {
      this.$f7.views.main.router.navigate('/createMachinery/', { reloadCurrent: true });
    },
    editMachinery(rowSelected) {
      this.setActualMachinery(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/editMachinery/', { reloadCurrent: true });
    },
    // UTILITIES
    formattingStatusColumn(value) {
      if (value === true) {
        return this.$t('DxMachineryDatabase_column_active');
      }
      return this.$t('DxMachineryDatabase_column_inactive');
    },
    // CREACION BOTON CREAR MAQUINARIA
    onToolbarPreparing(e) {
      if (!this.$helpers.havePermission(this.getPermissions.company_update_master_data)) return;
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.createMachinery();
          },
        },
      });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MachineryDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MachineryDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    setLocationTreeFormat(item) {
      this.locationsTreeFormated.push({
        bd_id: item.bd_id,
        children: item.children,
        name: item.name,
      });
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    getNamesByBdIds(ids) {
      const names = [];
      for (const id of ids) {
        names.push(this.getNameById(id));
      }
      return names;
    },
    getNameById(id) {
      for (const locationTreeFormated of this.locationsTreeFormated) {
        if (locationTreeFormated.bd_id === id) {
          return locationTreeFormated.name;
        }
      }
      return '';
    },
    ...mapActions('Machinery', ['setActualMachinery']),
  },
};
</script>
