<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('TaskDefinition.title')}`" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <TasksDatabase
        :tasks-definitions="tasksDefinitions"
      />
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import TasksDatabase from '../../../components/configuration/tasks/tasksDatabase/index.vue';

export default {
  name: 'Tasks',
  components: {
    navbar,
    TasksDatabase,
  },

  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('TaskDefinition', ['tasksDefinitions']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchTasksDefinitions();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('TaskDefinition', ['fetchTasksDefinitions']),

  },
};
</script>
