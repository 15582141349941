<template>
  <div class="main-giswms-map">
    <div class="content-gis-map">
      <div
        v-if="(treeLocations.length !== 0)"
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
      <div
        v-else
        style="display: block; text-align: center;"
      >
        {{ $t('GISGeoJsonMap_not_locations') }}
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'GISGeoJsonMap',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: moment().format('YYYY-MM-DD'),
      actualPlantation: {},
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Sector', ['currentPlantation', 'currentSector']),
    ...mapState('Explotaciones', ['actualUser']),
  },
  mounted() {
    if (this.treeLocations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        let treeLocationIds = {};
        const group = L.featureGroup();
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            group.addLayer(layer);
            const bounds = layer.getBounds();
            this.layerBounds = bounds;
            treeLocationIds = { explotationId: geoData.bd_id, parcelId: children.bd_id };
            this.layersEvents(layer, treeLocationIds);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
              treeLocationIds = {
                explotationId: geoData.bd_id, parcelId: children.bd_id, sectorId: children2.bd_id,
              };
              this.layersEvents(layer2, treeLocationIds);
            }
          }
        }
        if (this.center !== null) {
          this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        } else {
          const bounds = group.getBounds();
          this.groupBounds = bounds;
          this.map.fitBounds(bounds);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target, treeLocationIds) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            L.DomEvent.stopPropagation(e);
            self.locationProperties = {};
            self.actualPlantation = {};
            self.openLayerDataPopup(e.target.feature.properties, e, treeLocationIds);
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="fa fa-home" style="line-height:1.65 blue;"></i>',
            zoomHomeTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
              `${controlName}-home`, container, this.zoomHome);

            return container;
          },

          zoomHome() {
            self.map.fitBounds(self.groupBounds);
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    async openLayerDataPopup(properties, e, treeLocationIds) {
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        await this.getActivePlantationIdBySectorId(treeLocationIds.sectorId);
      }
      this.locationProperties = { ...properties, ...treeLocationIds, ...this.actualPlantation };
      let htmlString = `
      <div class='popup-content'>
        <div>
          <p>
          <img id="find-explotation" src="../../../static/img/find.svg" styles="cursor: pointer"/>
          <b>${this.$t('Explotation')}:</b> ${properties.explotation}
          </p>
          <p>
          <img id="find-parcel" src="../../../static/img/find.svg"/>
          <b>${this.$t('Parcel')}:</b> ${properties.parcel}
          </p>`;
      const htmlStringSector = `
          <p>
            <img id="find-sector" src="../../../static/img/find.svg"/>
            <b>${this.$t('Sector')}:</b> ${properties.sector ? properties.sector : ''}
          </p>`;
      const htmlStringPlantation = `
          <p>
          <img id="find-plantation" src="../../../static/img/find.svg"/>
          <b>${this.$t('Plantation')}:</b> ${this.locationProperties.plantationName ? this.locationProperties.plantationName : ''}
          </p>`;
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        htmlString += htmlStringSector;
      }
      if (typeof this.actualPlantation.plantationId !== 'undefined') {
        htmlString += htmlStringPlantation;
      }
      htmlString += `
        </div>
      </div>`;
      this.openPopup(htmlString, e.latlng, treeLocationIds);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng, treeLocationIds) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
      const buttonExplotation = document.getElementById('find-explotation');
      buttonExplotation.addEventListener('click', () => {
        this.goTo('explotation');
      });
      const buttonParcel = document.getElementById('find-parcel');
      buttonParcel.addEventListener('click', () => {
        this.goTo('parcel');
      });
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        const buttonSector = document.getElementById('find-sector');
        buttonSector.addEventListener('click', () => {
          if (typeof this.locationProperties.sectorId !== 'undefined' && this.locationProperties.sectorId !== null) {
            this.goTo('sector');
          }
        });
      }
      if (typeof this.actualPlantation.plantationId !== 'undefined') {
        const buttonPlantation = document.getElementById('find-plantation');
        buttonPlantation.addEventListener('click', () => {
          if (typeof this.locationProperties.plantationId !== 'undefined' && this.locationProperties.plantationId !== null) {
            this.goTo('plantation');
          }
        });
      }
    },
    async goTo(levelLocation) {
      const self = this;
      try {
        await this.fetchActualLocation({ id: this.locationProperties.explotationId });
        switch (levelLocation) {
          case 'explotation':
            this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'parcel':
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'sector':
            this.setCurrentName(this.locationProperties.parcel);
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.setCurrentSectorId(this.locationProperties.sectorId);
            this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          case 'plantation':
            this.setCurrentName(this.locationProperties.parcel);
            this.setCurrentParcelId(this.locationProperties.parcelId);
            this.setCurrentSectorId(this.locationProperties.sectorId);
            this.setIdPlantation(this.locationProperties.plantationId);
            this.$f7.views.main.router.navigate('/viewPlantation/', { reloadCurrent: true });
            self.map.closePopup();
            break;
          default:
            break;
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    async getActivePlantationIdBySectorId(sectorId) {
      try {
        await this.getSectorById({ companyId: this.actualUser.id, currentSectorId: sectorId });
        for (const plantation of this.currentSector.plantation) {
          if (moment(this.actualDate).isBetween(plantation.init_date, plantation.end_date)) {
            this.actualPlantation = { plantationId: plantation.id, plantationName: plantation.species };
          }
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
    ...mapActions('Explotaciones', ['fetchActualLocation']),
    ...mapActions('Parcel', ['setCurrentParcelId', 'setCurrentName']),
    ...mapActions('Sector', ['setCurrentSectorId', 'getSectorById']),
    ...mapActions('Plantation', ['setIdPlantation']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
