<template>
  <f7-navbar :sliding="false">
    <f7-link
      panel-toggle="left"
    />
    <span class="icon-hamburguer" />
    <button
      v-if="pathBack != ''"
      class="button-back"
      @click="backTo"
    >
      <img
        class="ic-back"
      >
    </button>
    <f7-nav-title
      class="title-head"
    >
      {{ textUpperCase }}
    </f7-nav-title>
    <f7-nav-right sliding>
      <ExportExcel
        v-if="list!=null"
        :list="list"
        :type-export="typeExport"
      />
      <ExtraElement
        v-for="(item, index) in elements"
        :key="index"
        :icon-enable="item.iconEnable"
        :text-enable="item.textEnable"
        :bagde-enable="item.badgeEnable"
        :info="item.info"
      />
      <f7-chip>
        {{ user.user.contact.name }} {{ user.user.contact.surname }}
      </f7-chip>
      <span class="icon-person margin-horizontal" />
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import {
  mapState,
} from 'vuex';
import ExtraElement from './extraElement.vue';
import ExportExcel from './ExportExcel.vue';

export default {
  components: {
    ExtraElement,
    ExportExcel,
  },
  props: {
    elements: { type: Array, default: () => [] },
    list: { type: Array, default: () => null },
    typeExport: { type: String, default: '' },
    pathBack: { type: String, default: '' },
    text: { type: String, default: '' },
  },
  data() {
    return {
      textUpperCase: '',
    };
  },
  computed: {
    ...mapState('authentication', ['user']),
  },
  beforeMount() {
    this.textUpperCase = this.text.toUpperCase();
  },
  methods: {
    backTo() {
      this.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
    },
  },
};
</script>
<style>
.chip{
  flex-direction: row-reverse;
  padding-right: 0;
  background: transparent;
}
.chip-media + .chip-label{
  margin-right: 20px!important;
}
.chip-label{
  color: whitesmoke!important;
}
.chip-media{
  background: var(--fr-chocolat);
}
.icon-hamburguer {
  background: url('../static/img/ic_hamburguer.svg') no-repeat left center;
  background-size: contain;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 15px;
  filter: hue-rotate(45deg) invert(1);
}
.icon-person {
  background: url('../static/img/ic_user.svg') no-repeat left center;
  background-size: contain;
  width: 22px;
  height: 22px;
  filter: hue-rotate(136deg) invert(1);
}
.ic-back{
  background: url("../static/img/ic_back.svg");
  width: 100%;
  height: 100%;
  filter: hue-rotate(45deg) invert(1);
}
.button-back{
  background-color: #D6B35A;
  border: none;
}
</style>
