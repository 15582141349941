<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${title}`" />
    <DxCreateEmployee
      class="content-planning"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToEmployeesPage"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="createEmployee"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxCreateEmployee from '../../../components/configuration/employees/DxCreateEmployee.vue';
import navbar from '../../../components/NavBar.vue';
import Api from '../../../services/Api';

export default {
  name: 'CreateEmployee',
  components: {
    DxCreateEmployee,
    navbar,
  },
  computed: {
    ...mapState('EmployeeManager',
      [
        'employee',
        'name',
        'surname',
        'identification_number',
        'group',
        'status',
        'role',
        'type',
        'drive',
        'shift',
        'email',
        'password',
        'type_identification',
        'code_identification',
        'expirationDateSelected',
        'applicator',
      ]),
    ...mapState('PlanningManager', ['actualLocationsSelectedLevel0']),
  },
  beforeMount() {
    if (this.employee === null) {
      this.title = this.$t('CreateEmployee_Title');
    } else {
      this.title = this.$t('EditEmployee_Title');
    }
    // this.setNewPrescription(); AQUI DEBO DE CONTROLAR EL TEXTO DEL NAV, DEPENDIENDO DE SI VA A CREATE O A EDIT EMPLOYEE
  },
  methods: {
    goToEmployeesPage() {
      this.$f7.views.main.router.navigate('/employees/', { reloadCurrent: true });
    },
    async createEmployee() {
      if (this.name === '' || this.name === undefined) {
        this.$f7.dialog.alert(this.$t('CreateEmployee_ValidationName'));
      } else if (this.surname === '' || this.surname === undefined) {
        this.$f7.dialog.alert(this.$t('CreateEmployee_ValidationSurname'));
      } else if (this.identification_number === '' || this.identification_number === undefined) {
        this.$f7.dialog.alert(this.$t('CreateEmployee_ValidationIdentificationNumber'));
      } else if (this.role === 'SUPERVISOR' && (typeof (this.email) === 'undefined' || this.email === '')) { // TODO: Sustituir el literal de SUPERVISOR por el enumerado obtenido del back
        this.$f7.dialog.alert(this.$t('CreateEmployee_ValidationEmail'));
      } else {
        this.$f7.preloader.show();
        try {
          this.newEmployee = {};
          if (this.code_identification !== '') {
            this.newEmployee.auth = [
              { type: this.type_identification, code: this.code_identification },
            ];
          }
          this.newEmployee.name = this.name;
          this.newEmployee.surname = this.surname;
          this.newEmployee.code = this.identification_number;
          this.newEmployee.role = this.role;
          this.newEmployee.type = this.type;
          this.newEmployee.driver = this.drive;
          this.newEmployee.status = (this.status === 'Active');
          this.newEmployee.properties = [
            { key: 'applicator', value: this.applicator === true ? 'true' : 'false' },
            { key: 'expiration_sag', value: this.expirationDateSelected[0] },
          ];
          if (this.group !== '') {
            this.newEmployee.properties.push({ key: 'group', value: this.group });
          }
          if (this.shift !== '') {
            this.newEmployee.properties.push({ key: 'shift', value: this.shift });
          }
          this.supervisor = false;
          if (this.role === 'SUPERVISOR') { // TODO: Sustituir el literal de SUPERVISOR por el enumerado obtenido del back
            this.newEmployee.email = this.email;
            if (this.password !== '') {
              this.newEmployee.password = this.password;
            }
            this.supervisor = true;
          }
          this.newEmployee.farm = this.actualLocationsSelectedLevel0;
          let response = '';
          if (this.employee === null) {
            response = await Api.createEmployee(this.newEmployee);
          } else {
            response = await Api.editEmployee(this.newEmployee, this.employee.ID);
          }
          if (response.status <= 204) {
            this.goToEmployeesPage(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
          } else if (response.status === 409) {
            this.$f7.dialog.alert(this.$t('CreateEmployee_EmployeeCodeDuplicated'));
          } else {
            this.$f7.dialog.alert(this.$t(`CreateEmployee_EmployeeGenericError ${response.responseText}`));
          }
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
