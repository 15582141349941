import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import { treatmentPlanService, treatmentPlanStatus } from '@/services/treatmentPlanService';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'treatment-plan',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
  },
  props: [],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      const dataPlantation = {
        companyId: this.currentPlantation.companyId,
        idPlantation: this.currentPlantation.id,
      };
      treatmentPlanService.getAll(dataPlantation).then((response) => {
        this.items = response.data;
      });
    },
    add() {
      this.initTreatmentPlan();
      this.$f7.views.main.router.navigate('/utilities/treatment/treatment-step-one',
        { reloadCurrent: true });
    },
    getNameStatus(status) {
      switch (status) {
        case treatmentPlanStatus.EXECUTED:
          return this.$t('treatment.treatmentPanel.executed');
        case treatmentPlanStatus.CANCELLED:
          return this.$t('treatment.treatmentPanel.cancel');
        default:
        case treatmentPlanStatus.PLANNED:
          return this.$t('treatment.treatmentPanel.planned');
      }
    },
    getClassStatus(status) {
      switch (status) {
        case treatmentPlanStatus.EXECUTED:
          return 'label-success';
        case treatmentPlanStatus.CANCELLED:
          return 'label-danger';
        default:
        case treatmentPlanStatus.PLANNED:
          return 'label-primary';
      }
    },
    viewItem(item) {
      this.setCurrentTreatmentId(item.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-detail-plan',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    editItem(item) {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-step-one',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    deleteItem(item) {
      const self = this;
      this.$f7.dialog.create({
        title: this.$t('Confirm_Delete_Title'),
        text: this.$t('Confirm_Delete_Text'),
        closeByBackdropClick: 'true',
        buttons: [
          {
            text: this.$t('No'),
          },
          {
            text: this.$t('Ok'),
            onClick() {
              treatmentPlanService.delete(item.id, self.currentPlantation.companyId).finally(() => {
                self.initData();
              });
            },
          },
        ],
      }).open();
    },
    formatingDate(e) {
      const initDate = moment(e.init_date).format('DD/MM/YYYY');
      const endDate = moment(e.end_date).format('DD/MM/YYYY');

      return `${initDate}  ~  ${endDate}`;
    },
    ...mapActions('treatmentStepper', ['initTreatmentPlan', 'setCurrentTreatmentId']),
  },
};
