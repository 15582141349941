<template>
  <f7-block>
    <div>
      <f7-row>
        <f7-col>
          <div class="content-devx no-margin margin-half margin-top">
            <f7-row>
              <f7-col width="80">
                <f7-block-title class="no-padding">
                  <span>{{ `${$t('Campania_title_deatil')}` }}</span>
                </f7-block-title>
              </f7-col>
              <f7-col width="20">
                <f7-block-title>
                  <f7-button
                    class="dx-btn-pdf"
                    raised
                    type="default"
                    styling-mode="outlined"
                    @click="editCampaign()"
                  >
                    <f7-icon
                      md="f7:pencil"
                    />
                  </f7-button>
                </f7-block-title>
              </f7-col>

              <f7-block>
                <f7-row class="firstRow">
                  <f7-col>{{ $t('DxClients_client') }}</f7-col>
                </f7-row>
                <f7-row>
                  <f7-col class="bold">
                    {{ actualUser.name }}
                  </f7-col>
                </f7-row>
              </f7-block>
            </f7-row>
            <f7-block>
              <f7-row class="firstRow">
                <f7-col>{{ $t('DxRegisterCampania_nombre') }}</f7-col>
                <f7-col>{{ $t('ParcelSystemCampania_status') }}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="bold">
                  {{ actualCampaign.name }}
                </f7-col>
                <f7-col>
                  <div :class="setClassByStatus(getStatusCampaign(actualCampaign.init_date, actualCampaign.end_date))">
                    {{ getStatusCampaign(actualCampaign.init_date, actualCampaign.end_date) }}
                  </div>
                </f7-col>
              </f7-row>
            </f7-block>
            <f7-block class="margin-top-half">
              <f7-row>
                <f7-col>{{ $t('DxRegisterCampania_fecha_inicio') }}</f7-col>
                <f7-col>{{ $t('DxRegisterCampania_fecha_finalizacion') }}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="bold">
                  {{ viewDateFormatting(actualCampaign.init_date) }}
                </f7-col>
                <f7-col class="bold">
                  {{ viewDateFormatting(actualCampaign.end_date) }}
                </f7-col>
              </f7-row>
            </f7-block>
          </div>
        </f7-col>
        <f7-col>
          <f7-block class="no-padding padding-top padding-right">
            <GISGeoJsonMap
              v-if="loaded"
              :zoom="zoom"
              :name-map="mainMap"
              :tree-locations="treeLocations"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-block class="no-margin no-padding">
        <div class="content-devx no-margin margin-half">
          <f7-block-title class="no-padding">
            <span>{{ `${$t('title_datagrid_plantation')}` }}</span>
          </f7-block-title>
          <f7-block>
            <DxDataGrid
              id="gridContainer"
              :data-source="dataPlantation"
              :remote-operations="true"
              :column-min-width="70"
              :allow-column-reordering="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="false"
              :column-auto-width="true"
              :row-alternation-enabled="true"
              @exporting="onExporting"
              @toolbar-preparing="onToolbarPreparing($event)"
            >
              <DxSelection
                mode="single"
              />
              <DxStateStoring
                :enabled="true"
                type="localStorage"
                :storage-key="`${keyName}`"
              />
              <DxHeaderFilter :visible="true" />
              <DxExport
                :enabled="false"
              />
              <DxSelection
                mode="single"
              />
              <DxPaging :page-size="10" />
              <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="pageSizes"
                :show-info="true"
              />
              <DxSearchPanel
                :visible="true"
                :width="200"
                :highlight-case-sensitive="true"
                :placeholder="$t('Search_placeholder')"
              />
              <DxColumn
                data-field="species"
                name="species"
                :caption="$t('ViewCampania_species')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="variety"
                name="variety"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_variety')"
                alignment="left"
              />
              <DxColumn
                data-field="initDate"
                name="initDate"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_init_date')"
                alignment="center"
                :customize-text="dateFormatting"
              />
              <DxColumn
                data-field="endDate"
                name="endDate"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_end_date')"
                alignment="center"
                :customize-text="dateFormatting"
              />
              <DxColumn
                data-field="farmName"
                name="farmName"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_farm')"
                alignment="center"
              />
              <DxColumn
                data-field="parcelsName"
                name="parcelsName"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_parcel')"
                alignment="center"
              />
              <DxColumn
                data-field="sectorsName"
                name="sectorsName"
                :allow-header-filtering="false"
                :caption="$t('ViewCampania_sector')"
                alignment="center"
              />

              <DxColumn
                data-field="status"
                :caption="$t('plantations.grid.status')"
                alignment="center"
                :header-filter="{ dataSource: filterStatus }"
                cell-template="statusTemplate"
                :calculate-cell-value="calculateStatus"
                width="120"
              />

              <template #statusTemplate="{ data }">
                <div :class="setClassByStatus(data.data.status)">
                  {{ data.data.status }}
                </div>
              </template>

              <DxColumn
                :buttons="editButtons"
                type="buttons"
                width="80"
              />
            </DxDataGrid>
          </f7-block>

          <DxPopUpRegisterCamapnia />
        </div>
      </f7-block>
    </div>
  </f7-block>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import DxPopUpRegisterCamapnia from './popUpRegisterCampania/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';

export default {
  name: 'ViewCampania',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxPopUpRegisterCamapnia,
    GISGeoJsonMap,
  },
  data() {
    return {
      loaded: false,
      editButtons: [{
        icon: 'find',
        onClick: this.viewPlantation,
      }],
      filterStatus: [{
        text: this.$t('plantations.grid.active'),
        value: this.$t('plantations.grid.active'),
      }, {
        text: this.$t('plantations.grid.end'),
        value: this.$t('plantations.grid.end'),
      },
      {
        text: this.$t('plantations.grid.pending'),
        value: this.$t('plantations.grid.pending'),
      }],
      pageSizes: [5, 10, 20],
      keyName: 'ViewCampania',
      actualDate: moment().format('YYYY-MM-DD'),
      zoom: 17,
      mainMap: 'campaignMainMap',
    };
  },
  computed: {
    ...mapState('Campania', ['actualCampaign', 'dataPlantation']),
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async beforeMount() {
    this.setIsPlantationCreated(false);
    // cargamos las localizaciones de nivel 0 para cuando le demos a guardar.
    try {
      await this.getListExplotation();
      await this.fetchTreeLocationParcelSystemById({ companyId: this.actualUser.id, locationId: this.actualCampaign.id });
      this.loaded = true;
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    }
  },
  methods: {
    dateFormatting(date) {
      return moment(date.value).format('DD/MM/YYYY');
    },
    viewDateFormatting(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    // SET CSS CLASSES TO CELLS
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    calculateStatus(row) {
      row.status = this.getStatusCampaign(row.initDate, row.endDate);
    },
    viewPlantation(e) {
      this.setIsView(false);
      this.setIdPlantation(e.row.data.id);
      this.setIsEdit(false);
      this.$f7.views.main.router.navigate('/ViewPlantation/', { reloadCurrent: true });
    },
    editCampaign() {
      this.$f7.views.main.router.navigate('/registerCampania/', { reloadCurrent: true });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ViewCampaña_Plantation');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ViewCampaña_Plantation.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          text: this.$t('title_create_campania'),
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setIsView(true);
            this.resetSelectBoxStepUno();
            this.setActualPlot([]);
            this.setParcelSelected('');
            this.setSectorSelected('');
            this.setActualPlantation({});
            this.setIsEdit(false);
            this.setViewCampaignId(this.actualCampaign.id);
            this.$f7.views.main.router.navigate('/RegisterPlantationStep1/', { reloadCurrent: true });
          },
        },
      });
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case this.$t('plantations.grid.active'):
          colorCell = 'Activa';
          break;
        case this.$t('plantations.grid.end'):
          colorCell = 'Finalizada';
          break;
        case this.$t('plantations.grid.pending'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    ...mapActions('Campania', ['getCampaignsByCompany', 'getListExplotation', 'setIsView', 'setActualPlot', 'setActualCampaign', 'setViewCampaignId']),
    ...mapActions('Plantation', ['setIdPlantation', 'fetchTreeLocationParcelSystemById', 'setTreeLocations']),
    ...mapActions('RegisterPlantation', ['setIsEdit', 'resetSelectBoxStepUno', 'setParcelSelected', 'setSectorSelected', 'setActualPlantation', 'setIsPlantationCreated']),
  },
};
</script>
<style scoped>
@import './viewCampania.styles.scss';

  .Activa {
    color: #219653 !important;
    background: #DEFCE8 !important;
    margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #219653;
  }
  .Finalizada {
    color: #74838C;
    background: #F4F5F7;
  margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #74838C;
  }
  .Pendiente {
    color: #23465E;
    background: white;
     margin-right:auto;
    text-align:center;
    width: 100px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #23465E;
  }
  .Activa, .Finalizada, .Pendiente {
    border-radius: 2px;
    font-size: 12px;
    padding: 0px 2px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2!important;
  }

</style>
