<template>
  <div class="content-devx">
    <f7-block>
      {{ $t('Register_campaign') }}
    </f7-block>
    <f7-block>
      <DxForm
        id="form"
        :col-count="1"
        :caption="$t('Campania_title')"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('DxRegisterCampania_nombre')"
            name="name"
            :caption="$t('DxRegisterCampania_nombre')"
            :editor-options="{
              value: currentCampaign.name,
              showClearButton: true,
              onValueChanged: setNameChanged,
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_name_require')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            name="init-date"
            :data-field="$t('DxRegisterCampania_fecha_inicio')"
            editor-type="dxDateBox"
            :editor-options="{
              value: currentCampaign.initDate,
              onValueChanged: onChangeInitDate,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: $t('dates_isInvalid'),
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_init_date_require')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="end-date"
            :data-field="$t('DxRegisterCampania_fecha_finalizacion')"
            editor-type="dxDateBox"
            :editor-options="{
              value: currentCampaign.endDate,
              onValueChanged: onChangeEndDate,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: $t('dates_isInvalid'),
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_end_date_require')" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
      <DxPopUpRegisterCamapnia />
    </f7-block>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import { mapActions } from 'vuex';
import DxPopUpRegisterCamapnia from '../popUpRegisterCampania/index.vue';

export default {
  name: 'RegisterCampania',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxPopUpRegisterCamapnia,
  },
  props: {
    currentCampaign: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setNameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualName = e.value;
      this.setName(e.value);
    },
    onChangeInitDate(e) {
      this.setInitDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    onChangeEndDate(e) {
      this.setEndDate(moment(e.value, 'YYYY/MM/DD').format('YYYY-MM-DD'));
    },
    ...mapActions('Campania', ['setEndDate', 'setInitDate', 'setName']),
  },
};
</script>
