<template>
  <f7-block>
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
  </f7-block>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import 'leaflet-timedimension-new';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import Api from '../../../services/Api';
import EventBus from '../../../js/event-bus';

export default {
  name: 'GISWMSTimeDimensionMap',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '450px' },
    nameMap: { type: String, default: '' },
    index: { type: String, default: 'NDVI' },
    layer: {
      type: Object,
      default: () => {},
    },
    center: {
      type: Object,
      default: () => {},
    },
    timeDimension: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      initialDate: Date.now(),
      currentLayers: [],
      currentDates: [],
      timeDimensionControl: null,
      timeDimensionLayer: null,
      currentIndex: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'featureInfo',
      'datesList',
      'currentInitIntervalDates',
      'currentEndIntervalDates',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  beforeMount() {
    EventBus.$on(`indexLayer${this.nameMap}`, this.indexLayer);
    EventBus.$on(`updateTimeDimension${this.nameMap}`, this.updateTimeDimension);
  },
  mounted() {
    this.setCurrentLayersMap([]);
    this.renderMap();
    this.addFuncionalities();
    this.addOverlayLayer();
    this.updateLayer();
    this.indexLayer(this.index);
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        const layer = L.tileLayer.wms(this.layer.baseUrl, this.layer.options);
        this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        // this.layerDates(layer.options.layers.split(':')[1]);

        const item = this.layer;
        this.currentLayers.push({ item, layer });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    indexLayer(index) {
      if (this.timeDimensionLayer !== null) {
        this.timeDimensionLayer.setParams({
          styles: index,
        }).addTo(this.map);
      }

      this.currentIndex = index;

      const legendContent = `<img src=${Api.getGeoServerTimacagro()}REQUEST=GetLegendGraphic&VERSION=2.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${this.layer.options.layers.split(':')[1]}&style=${index}>`;
      this.map.legend.setContent(legendContent);
    },

    /**
     * Anidamos los datos para adjuntar en la ruta.
     */
    generateRouteTocenterMapWMSLayer(item) {
      const layersArray = item.options.layers.split(':');
      if (layersArray.length < 2) return '';
      const fullUrl = `getbbox/?ws=${layersArray[0]}&layer=${layersArray[1]}&ds=${item.ds}`;
      return fullUrl;
    },

    /**
     *
     */
    async dataRequestWMSLayers(route, e) {
      await this.fetchFeatureInfo({ queryParams: `wms/${route}` });
      if (!('features' in this.featureInfo) || !this.featureInfo.features.length) {
        // return false;
      } else {
        const features = this.featureInfo.features[0];
        const { properties } = features;

        this.openLayerDataPopup(properties, e);
      }
    },

    /**
     *
     */
    generateRouteToDataRequestWMSLayers(item, e) {
      const BBOX = `${e.latlng.lng - 0.00002},${e.latlng.lat - 0.00002},${e.latlng.lng + 0.00002},${e.latlng.lat + 0.00002}`;
      // TODO: PONER PARÁMETRO TIME PARA CUANDO SE SELECCIONE UNA FECHA DE UNA CAPA
      const queryParams = {
        REQUEST: 'GetFeatureInfo',
        SRS: 'EPSG:4326',
        BBOX,
        HEIGHT: this.map.getSize().y,
        WIDTH: this.map.getSize().x,
        LAYERS: item,
        QUERY_LAYERS: item,
        INFO_FORMAT: 'application/json',
        X: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).x),
        Y: Math.round(this.map.layerPointToContainerPoint(e.layerPoint).y),
      };

      const fullUrl = L.Util.getParamString(queryParams);
      this.dataRequestWMSLayers(fullUrl, e);
    },

    async updateLayer() {
      this.$f7.preloader.show();
      try {
        const currentOverlayLayer = this.layer.options.layers.split(':')[1];
        await this.fetchDatesLayer(currentOverlayLayer);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        if (this.timeDimension) {
          this.createTimeDimension(this.currentLayers[0].item);
        }
      }
    },
    updateTimeDimension() {
      this.createTimeDimension(this.currentLayers[0].item);
    },
    createTimeDimension(item) {
      if (this.timeDimensionControl !== null) {
        this.timeDimensionControl.remove(this.map);
        this.timeDimensionLayer.remove(this.map);
      }
      const url = item.baseUrl;
      let dateList = this.datesList.avaiable_dates;
      if (this.currentInitIntervalDates[this.nameMap] !== '') {
        dateList = dateList.filter((date) => moment(this.currentInitIntervalDates[this.nameMap]).isSameOrBefore(date));
      }
      if (this.currentEndIntervalDates[this.nameMap] !== '') {
        dateList = dateList.filter((date) => moment(this.currentEndIntervalDates[this.nameMap]).isSameOrAfter(date));
      }
      const timeDimension = L.timeDimension({
        times: dateList,
      });
      const temporalLayer = L.tileLayer.wms(url, {
        layers: item.options.layers,
        format: item.options.format,
        transparent: item.options.transparent,
        visible: item.visible,
        styles: this.index,
      }, timeDimension);

      this.timeDimensionControl = L.control.timeDimension({
        timeDimension,
        autoPlay: false,
        loopButton: true,
        timeSteps: 1,
        playReverseButton: false,
        backwardButton: false,
        forwardButton: false,
        speedSlider: false,
        limitSliders: true,
        maxSpeed: 10,
        speedStep: 10,
        timeZones: ['Local', 'UTC'],
        playerOptions: {
          buffer: 1,
          transitionTime: 1500,
          loop: true,
        },
      }).addTo(this.map);

      this.timeDimensionLayer = L.timeDimension.layer.wms(temporalLayer, {
        timeDimension,
        requestTimeFromCapabilities: false,
        updateTimeDimension: false,
        setDefaultTime: false,
      }).addTo(this.map);
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.setMapEvents();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Thäles from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="fa fa-home" style="line-height:1.65 blue;"></i>',
            zoomHomeTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
              `${controlName}-home`, container, this.zoomHome);

            return container;
          },

          zoomHome() {
            self.map.setView([self.center.latitude, self.center.longitude], self.zoom);
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);

        L.Control.legend = L.Control.extend({
          options: {
            position: 'bottomright',
          },
          onAdd() {
            self.map.legend = this;

            const container = L.DomUtil.create('div', 'legend-control-container');

            if (this.options.content) {
              container.innerHTML = this.options.content;
            }
            return container;
          },
          onRemove() {
            delete self.map.legend;
          },

          setContent(str) {
            this.getContainer().innerHTML = str;
          },
        });
        // eslint-disable-next-line new-cap
        const legend = new L.Control.legend();
        legend.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      const bands = 'Band1';
      let result = 0;
      if (bands in properties) {
        switch (this.currentIndex) {
          case 'NDVI':
            result = (properties.Band8 - properties.Band4) / (properties.Band8 + properties.Band4);
            break;
          case 'SAVI':
            result = ((properties.Band8 - properties.Band4) / (properties.Band8 + properties.Band4 + 0.428)) * 0.428;
            break;
          case 'GNDVI':
            result = (properties.Band8 - properties.Band3) / (properties.Band8 + properties.Band3);
            break;
          case 'ARVI':
            result = (properties.Band8 - (2 * properties.Band4) + properties.Band2) / (properties.Band8 + (2 * properties.Band4) + properties.Band2);
            break;
          case 'AVI':
            result = ((properties.Band8 * (1 - properties.Band4) * (properties.Band8 - properties.Band4)) ** 1 / 3);
            break;
          case 'EVI':
            result = 2.5 * ((properties.Band8 - properties.Band4) / (properties.Band8 + 6 * properties.Band4 - 7.5 * properties.Band2 + 1));
            break;
          case 'VARI':
            result = (properties.Band3 - properties.Band4) / (properties.Band3 + properties.Band4 - properties.Band2);
            break;
          case 'LAI':
            result = 0.57 ** (2.33 * ((properties.Band8 - properties.Band4) / (properties.Band8 + properties.Band4)));
            break;
          case 'NDRE':
            result = (properties.Band8 - properties.Band5) / (properties.Band8 + properties.Band5);
            break;
          case 'RECI':
            result = (properties.Band8 - properties.Band11) / (properties.Band8 + properties.Band11);
            break;
          case 'NDMI':
            result = properties.Band8 / (properties.Band8 + properties.Band11);
            break;
          case 'MSI':
            result = properties.Band11 / properties.Band8;
            break;
          case 'GCI':
            result = (properties.Band9 / properties.Band3) - 1;
            break;
          case 'NBRI':
            result = properties.Band8 / (properties.Band8 + properties.Band2);
            break;
          default:
          case 'BSI':
            result = (properties.Band11 + properties.Band4) - (properties.Band8 + properties.Band2) / (properties.Band11 + properties.Band4) + (properties.Band8 + properties.Band2);
            break;
        }
        listHtml += `<li><b>${this.currentIndex}</b>: ${result.toFixed(2)}</li>`;
      } else {
        for (const property in properties) {
          if (properties[property] == null) continue;
          if (typeof properties[property] === 'object') {
            listHtml += this.getLayerDataPropertiesHtml(properties[property]);
          } else {
            listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
          }
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    /**
     *
     */
    setMapEvents() {
      const self = this;
      this.map.on('click', (e) => {
        if (this.currentLayers.length < 1) return;
        const layerId = this.currentLayers[0].item.options.layers.split(':');
        self.generateRouteToDataRequestWMSLayers(layerId[1], e);
      });
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer', 'fetchFeatureInfo', 'fetchDatesLayer']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
