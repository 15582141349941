<template>
  <f7-block>
    <div class="content-devx">
      <f7-block class="block-title">
        {{ $t('Searcher.irrigation.title') }}
      </f7-block>
      <f7-block>
        <f7-row>
          <f7-col>
            <DxDataGrid
              :data-source="plantations"
              :remote-operations="false"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              :show-borders="true"
              @legend-click="onLegendClick"
            >
              <DxSearchPanel
                :visible="true"
                :width="200"
                :highlight-case-sensitive="true"
                :placeholder="$t('Search_placeholder')"
              />

              <DxColumn
                data-field="company"
                :caption="$t('DxClients_client')"
              />
              <DxColumn
                data-field="explotation"
                :caption="$t('searchEngine_farm')"
                alignment="left"
                :allow-header-filtering="true"
              />
              <DxColumn
                data-field="campaign"
                :caption="$t('searchEngine_campaign')"
                alignment="left"
              />
              <DxColumn
                data-field="name"
                :caption="$t('Plantation')"
                alignment="left"
              />
              <DxColumn
                data-field="species"
                :caption="$t('searchEngine_botanical_species')"
                alignment="left"
                :allow-header-filtering="true"
              />
              <DxColumn
                data-field="initDate"
                data-type="date"
                :caption="$t('searchEngine_init_date')"
                alignment="right"
                :allow-header-filtering="false"
                :customize-text="dataFormatted"
              />
              <DxColumn
                data-field="endDate"
                data-type="date"
                :caption="$t('searchEngine_end_date')"
                alignment="right"
                :allow-header-filtering="false"
                :customize-text="dataFormatted"
              />
              <DxColumn
                width="48px"
                data-field="id"
                caption=""
                cell-template="grid-cell"
              />
              <template #grid-cell="{ data }">
                <div>
                  <DxButton
                    v-if="isIrrigationselectedIrrigationDataId(data.text)"
                    name="delete"
                    type="danger"
                    icon="close"
                    @click="deselectIrrigationData(data)"
                  />
                  <DxButton
                    v-else
                    name="edit"
                    type="success"
                    icon="add"
                    @click="selectIrrigationData(data)"
                  />
                </div>
              </template>
              <DxPaging :page-size="5" />
              <DxPager
                :visible="true"
                display-mode="full"
                :allowed-page-sizes="pageSizes"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />
            </DxDataGrid>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>

    <DxLoadPanel
      :visible="loading"
      shading-color="rgba(0,0,0,0.4)"
    />

    <div class="content-devx">
      <div class="card-body">
        <section>
          <DxChart
            palette="SoftPastel"
            :data-source="chartData"
            @legend-click="onLegendClick"
          >
            <DxCommonSeriesSettings
              argument-field="plantation_name"
              type="stackedbar"
            />
            <DxValueAxis>
              <DxTitle text="L/m2" />
            </DxValueAxis>
            <DxSeries
              value-field="evapotranspiration"
              :name="$t('irrigationAnalysis.eto')"
            />
            <DxSeries
              value-field="irrigation"
              :name="$t('irrigationAnalysis.irrigation')"
            />
            <DxSeries
              value-field="pluviometry"
              :name="$t('irrigationAnalysis.pluviometry')"
            />
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="right"
            />
            <DxTooltip
              :enabled="true"
              :z-index="999999"
              :customize-tooltip="customizeTooltip"
            />
            <DxScrollBar :visible="true" />
            <DxZoomAndPan argument-axis="both" />
          </DxChart>
        </section>
      </div>
    </div>
    <f7-block />
  </f7-block>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import {
  DxChart,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxScrollBar,
  DxZoomAndPan,
  DxValueAxis,
  DxTitle,
} from 'devextreme-vue/chart';
import moment from 'moment';
import DxButton from 'devextreme-vue/button';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import Api from '../../../services/Api';

export default {
  name: 'AnalysisIrrigationComponent',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxLoadPanel,
    DxButton,
    DxChart,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxValueAxis,
    DxTitle,
    DxPaging,
  },
  props: {
    plantations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIrrigationDataIds: [],
      chartData: [],
      plantationId: '',
      loading: false,
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${pointInfo.argumentText}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('weather_parameter')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}:</span> ${pointInfo.valueText}
        </span> </div></div></div>`,
      };
    },
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText).format('DD-MM-YYYY');
    },
    isIrrigationselectedIrrigationDataId(irrigationId) {
      for (const id of this.selectedIrrigationDataIds) {
        if (irrigationId === id) {
          return true;
        }
      }
      return false;
    },
    selectIrrigationData(data) {
      this.id = data.data.id;
      this.selectedIrrigationDataIds.push(data.text);
      this.recalculateGraphData();
    },

    deselectIrrigationData(data) {
      const index = this.selectedIrrigationDataIds.indexOf(data.text);
      if (index > -1) {
        this.selectedIrrigationDataIds.splice(index, 1);
      }
      this.chartData = this.chartData.filter((datos) => datos.plantation !== data.data.id);
      // this.recalculateGraphData();
    },
    async recalculateGraphData() {
      this.loading = true;
      try {
        const xhr = await Api.fetchIrrigationDataForAnalysis(this.id);
        const json = JSON.parse(xhr.response).data;
        this.chartData.push(json[0]);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
      this.loading = false;
    },
  },
};
</script>
