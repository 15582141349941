const dictionary = {
  es: {
    translation: {
      // Palabras reservadas INIT: NO TRADUCIR A OTROS LENGUAJES
      // hasta que se cambie la forma de generar los formularios
      // por enumerados y no se le envien los valores al back
      DIESEL: 'DIESEL',
      GASOLINE: 'GASOLINE',
      TEMPORARY: 'TEMPORARY',
      PERMANENT: 'PERMANENT',
      CONTRATIST: 'CONTRATIST',
      HARVESTER: 'HARVESTER',
      TRACTOR: 'TRACTOR',
      'CAR & VEHICLE': 'CAR & VEHICLE',
      'SOIL GRADER': 'SOIL GRADER',
      EXCAVATOR: 'EXCAVATOR',
      FORKLIFT: 'FORKLIFT',
      TRUCK: 'TRUCK',
      BULLDOZER: 'BULLDOZER',
      SPRAYER: 'SPRAYER',
      Yes: 'Yes',
      No: 'No',
      DxMachineryDatabase_column_active: 'Active',
      DxMachineryDatabase_column_inactive: 'No Active',
      activeSustance: 'Active',
      active: 'Active',
      noActive: 'No Active',
      DxEmployeeForm_operator: 'Operator',
      DxEmployeeForm_active: 'Active',
      DxEmployeeForm_noActive: 'No Active',
      AMENDMENT: 'AMENDMENT',
      'BIO STIMULANT': 'BIO STIMULANT',
      'FOLIAR FERTILIZER': 'FOLIAR FERTILIZER',
      'GRANULAR FERTILIZER': 'GRANULAR FERTILIZER',
      'SOLUBLE FERTILIZER': 'SOLUBLE FERTILIZER',
      'PHYTO-REGULATOR': 'PHYTO-REGULATOR',
      ACARICIDE: 'ACARICIDE',
      'BIO CONTROL': 'BIO CONTROL',
      ADJUVANT: 'ADJUVANT',
      FUNGICIDE: 'FUNGICIDE',
      HERBICIDE: 'HERBICIDE',
      INSECTICIDE: 'INSECTICIDE',
      DESINFECTANT: 'DESINFECTANT',
      NEMATICIDE: 'NEMATICIDE',
      OTHER: 'OTHER',
      // Palabras reservadas END: NO TRADUCIR A OTROS LENGUAJES
      Login_txtWelcome: 'Acceso',
      Login_inpUsername_placeholder: 'Usuario',
      Login_inpPassword_label: 'Contraseña',
      Login_inpPassword_placeholder: 'Contraseña',
      Login_chkRememberMe: 'Recordar contraseña',
      Login_txtRestorePass: 'Cambiar contraseña',
      Login_txtNeedHelp: '¿Necesitas ayuda?',
      Login_btnSignIn: 'ENTRAR',
      Login_btnSignUp_question: '¿Todavía no te has registrado?',
      Login_btnSignUp_link: 'Registrate aquí',
      Login_msgEmail_title: 'Información',
      Login_msgEmail_content: 'En breve recibirá un email para cambiar su contraseña',
      Login_msgEmail_btnClose: 'Cerrar',
      loggOut_Message: '¿Estás seguro que quieres salír de la aplicación?',
      RecoveryPassword_txtDataAccess: 'Introduzca su email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reestrablecer contraseña',
      RecoveryPassword_btnGoBack: 'Volver',
      ChangePassword_txtDataAccess: 'Introduzca su nueva contraseña',
      ChangePassword_inpEmail_placeholder: 'Contraseña',
      ChangePassword_inpRepeatEmail_placeholder: 'Repita contraseña',
      ChangePassword_btnChangePassword: 'Cambiar contraseña',
      Status_Closed: 'Cerrada',
      Status_notReally_Closed: 'No Cerrada',
      Status_reallyNot_Closed: 'No Cerrada',
      Register_btnEnter: 'ENTRAR',
      Register_inpName_name: 'Nombre',
      Register_inpName_placeholder: 'Nombre',
      Register_inpSurnames_name: 'Apellidos',
      Register_inpSurnames_placeholder: 'Apellidos',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Contrasena',
      Register_inpPassword_placeholder: 'Contraseña',
      Register_inpPasswordVerification_name: 'RepetirContrasena',
      Register_inpPasswordVerification_placeholder: 'Repetir contraseña',
      Register_inpUsername_name: 'NombreUsuario',
      Register_inpUsername_placeholder: 'Nombre de usuario',
      Nfc_into_error_404: 'El empleado no está registrado',
      Nfc_into_error_400: 'El formato de nfc no es válido',
      Nfc_into_error_406: 'La tarea actual ya está creada en esta localización',
      Nfc_into_error_409: 'El trabajador ya está trabajando en otra tarea',
      Nfc_info_txtTitle: 'Lectura de tarjeta',
      Nfc_info_txtSubTitle: 'Aproxime la tarjeta para su lectura',
      Nfc_navbarpresence_incidence: 'Escaneo de NFC',
      Nfc_navbarTask_salida: 'Productividad real',
      Nfc_navbarpresence_manual: 'Añadir NFC manual',
      Nfc_navbarpresence_btnContinue: 'Continuar',
      NfcManually_navbarpresence_btnContinue: 'Continuar',
      NfcManually_listInput_placeholder: 'Nombre o apellido',
      DxSelectSupervisor: 'Se necesita al menos un supervisor',
      Profile_info_profile: 'Información de perfil',
      Profile_name_placeholder: 'Nombre',
      Profile_surname_label: 'Apellidos',
      Profile_surname_placeholder: 'Apellidos',
      Profile_email_label: 'E-mail',
      Profile_email_placeholder: 'E-mail',
      Profile_mobile_label: 'Teléfono',
      Profile_mobile_placeholder: 'Teléfono',
      Profile_password_profile: 'Contraseña',
      Profile_oldPass_label: 'Contraseña anterior',
      Profile_oldPass_placeholder: 'Contraseña anterior',
      Profile_newPass_label: 'Nueva contraseña',
      Profile_newPass_placeholder: 'Nueva contraseña',
      Profile_checkNewPass_label: 'Confirmar nueva contraseña',
      Profile_change_password: 'Guardar contraseña',
      Profile_photo_profile: 'Foto perfil',
      Profile_update_photo: 'Actualizar foto perfil',
      Profile_setup_profile: 'Configuración',
      Profile_language_label: 'Idioma',
      Profile_theme_label: 'Tema',
      Profile_save_changes: 'Guardar cambios',
      Home_info_txtTitle: 'No hay trabajadores',
      ExportWorkerList_btnExport: 'Exportar a Excel',
      ExportExcel_thNombre: 'Nombre',
      ExportExcel_thTipo: 'Tipo',
      ExportExcel_thFecha: 'Fecha',
      PlanningComponent_numberWorkers_caption: 'Número de trabajadores',
      ExportExcel_captionListaEmpleados: 'Lista de trabajadores',
      SheetExport_sheet_export_ok: 'Fichero descargado: ',
      SheetExport_sheet_export_error: 'Error en la descarga',
      TasksList_navbar_text: 'Ordenes de trabajo',
      TasksList_lstNewTasks_title: 'Iniciar tarea',
      TasksList_lstInProgressTasks_title: 'Tareas en progreso',
      TasksList_lstCompletedTasks_title: 'Tareas completadas',
      AccordionList_workers: 'Trabajadores',
      AccordionList_worker: 'Trabajador',
      Home_conductor: 'Conductor',
      Start_order: 'Selecciona trabajador',
      ManualList_listItem_title: 'Ningun empleado encontrado',
      checkOut_Productivity_placeholder: 'Campo Numérico',
      checkOut_Productivity: 'Productividad real',
      checkOut_Task: 'Tarea',
      list_content_farm_img_alt: 'Cambiar de unidad territorial',
      farmSelection_navbar_text: 'Seleccionar unidad territorial',
      activitySelection_navbar_text: 'Seleccionar actividad',
      locationSelect_navbar_text: 'Seleccionar Localización',
      ActivitySelection_navbarTask_text: 'Seleccionar actividad',
      ActivitySelection_txtSearchCodeTask: 'Comenzar tarea por código: ',
      ActivitySelection_txtSearchActivity: 'Elige una actividad: ',
      ActivitySelection_taskError: 'No existe esa tarea',
      TasksList_filterTask_title: 'Seleccionar tarea',
      DxDailyPlanning_text: 'Crear nueva OT',
      DxAddEmployee_text: 'Añadir nuevo empleado',
      DxMonthlyPlanning_text: 'Crear nueva planificación mensual',
      DetailTabs_machinary_title: 'Maquinaria',
      DetailTabs_attachment_title: 'Implementos',
      DxEmployeeForm_name_require: 'Nombre es requerido',
      DxEmployeeForm_surname_require: 'Apellido es requerido',
      DxEmployeeForm_ID_require: 'Numero de identificacion es requerido',
      DxEmployeeForm_status_require: 'Estatus es requerido',
      DxEmployeeForm_role_require: 'Rol es requerido',
      DxEmployeeForm_type_require: 'Tipo es requerido',
      DxEmployeeForm_email_require: 'Email es requerido',
      DxEmployeeForm_password_require: 'Contraseña es requerida',
      DetailTabs_tools_title: 'Herramientas',
      DetailTabs_products_title: 'Productos',
      DetailTabs_workers_title: 'Trabajadores',
      DetailTabs_locations_title: 'Localizaciones',
      DxDailyPlanning_date_caption: 'Fecha',
      DxListEmployee_name_caption: 'Nombre',
      DxListEmployee_surname_caption: 'Apellidos',
      DxListEmployee_type_caption: 'Tipo',
      DxListEmployee_nfc_caption: 'identificación de la placa',
      DxListEmployee_code_caption: 'RUT',
      DxListEmployee_status_caption: 'Estado',
      DxListEmployee_driver_caption: 'Conductor',
      DxListEmployee_info_caption: 'Info',
      DxListEmployee_farm_caption: 'Campo',
      DxDailyPlanning_task_caption: 'Tarea',
      DxDailyPlanning_activity_caption: 'Actividad',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Trabajadores',
      DxDailyPlanning_location_caption: 'Localización',
      DxDailyPlanning_workingArea_caption: 'Área de Trabajo Planificada (HA)',
      DxMonthlyPlanning_goal_caption: 'Productividad planificada',
      DxDailyPlanning_dialogDelete_title: 'Borrar la planificación',
      DxDailyPlanning_dialogDelete_text: '¿Está seguro de borrar la planificación?',
      DxDailyPlanning_dialogDelete_confirm: 'Confirmar',
      DxDailyPlanning_dialogDelete_cancel: 'Cancelar',
      DxDailyWorkOrders_dialogDelete_title: 'Borrar orden de trabajo',
      DxDailyWorkOrders_dialogDelete_text: '¿Está seguro de borrar la orden de trabajo?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirmar',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancelar',
      DxDailyWorkOrders_dialogDelete_noOpen: 'No se puede eliminar una orden de trabajo ya empezada.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'No se puede editar una orden de trabajo ya empezada.',
      MonthlyComponent_type_caption: 'Tipo',
      MonthlyComponent_performance_caption: 'Productividad real',
      MonthlyComponent_performanceunit_caption: 'Rendimiento Unitario',
      MonthlyComponent_consumption_caption: 'Consumo',
      MonthlyComponent_consumptionunit_caption: 'Unidad Consumo',
      MonthlyComponent_quantity_caption: 'Cantidad',
      MonthlyComponent_typeconsumption_caption: 'Tipo Consumo',
      MonthlyComponent_notes_caption: 'Notas',
      PlanningComponent_caption_text: 'Actividad',
      PlanningComponent_activityName_caption: 'Nombre Actividad',
      PlanningComponent_activitycode_caption: 'Codigo Actividad',
      PlanningComponent_workingarea_caption: 'Área de Trabajo Planificada',
      EmployeeComponent_name_caption: 'Nombre',
      EmployeeComponent_surname_caption: 'Apellidos',
      EmployeeComponent_identificationNumber_caption: 'RUT',
      EmployeeComponent_status_caption: 'Estado',
      EmployeeComponent_role_caption: 'Rol',
      EmployeeComponent_type_caption: 'tipo',
      EmployeeComponent_drive_caption: 'Conductor',
      EmployeeComponent_group_caption: 'Grupo',
      EmployeeComponent_shift_caption: 'Turno',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'Nueva Contraseña',
      EmployeeComponent_identification_title: 'Identificacion del trabajador',
      EmployeeComponent_typeIdentification_caption: 'Tipo',
      EmployeeComponent_code_caption: 'Codigo',
      PlanningComponent_taskName_caption: 'Nombre Tarea',
      PlanningComponent_taskCode_caption: 'Codigo Tarea',
      PlanningComponent_date_caption: 'Fecha',
      PlanningComponent_numberWokers_caption: 'Numero de trabajadores',
      PlanningComponent_family_caption: 'Familia',
      PlanningComponent_addMachineButton_caption: 'Añadir Maquinaria',
      PlanningComponent_shift_caption: 'Turno',
      PlanningComponent_groupName_caption: 'Nombre Grupo',
      PlanningComponent_dailyPlanning: 'PLAN DIARIO',
      PlanningComponent_harvesting: 'COSECHA',
      PlanningComponent_edit_dailyPlanning: 'Editar planificación',
      CreatePlanning_ButtonSave_caption: 'Guardar',
      CreatePlanning_Title: 'CREAR NUEVA PLANIFICACIÓN',
      CreateMonthlyPlanning_Title: 'CREAR NUEVA PLANIFICACIÓN MENSUAL',
      EditMonthlyPlanning_Title: 'EDITAR PLANIFICACIÓN MENSUAL',
      CreateEmployee_Title: 'CREAR TRABAJADOR',
      EditEmployee_Title: 'EDITAR TRABAJADOR',
      DxTaskOrders_text: 'Registrar nueva orden de trabajo',
      StoreProduct_Buttoncancel_caption: 'Cancelar',
      StoreProduct_Buttonsave_caption: 'Guardar',
      CreatePlanning_ButtonCancel_caption: 'Cancelar',
      CreatePlanning_ValidationDate: 'La fecha es necesaria.',
      CreatePlanning_ValidationTaskName: 'El nombre de la tarea es necesaria.',
      CreatePlanning_ValidationActivityName: 'El nombre de la actividad es necesaria.',
      CreatePlanning_ValidationNumberOfWorker: 'El numero trabajadores debe de ser mayor que cero.',
      CreatePlanning_ValidationNumberOfDriver: 'El numero de conductores debe de ser superior a cero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Es necesario seleccionar una localización.',
      CreateEmployee_ValidationIdentificationNumber: 'Numero de indentificacion es requerido',
      CreateEmployee_ValidationName: 'Nombre es requerido.',
      CreateEmployee_ValidationSurname: 'Apellido es requerido.',
      CreateEmployee_ValidationEmail: 'Email es requerido.',
      CreateEmployee_ValidationPassword: 'Contraseña es requerido.',
      EmployeeComponent_caption_text: 'Datos del Trabajador',
      CreateOrder_ValidationSupervisorsLocation: 'Hay localizaciones sin un supervisor asignado.',
      CreateOrder_ValidationItemsWorkOrder: 'Hay items sin asignar.',
      CreateOrder_ValidationLocationExists: 'Ya hay una tarea con esta localización para la misma fecha.',
      DxDailyPlanning_edit_text: 'Editar',
      DxDailyPlanning_edit_hint: 'Editar',
      DxDailyPlanning_new_order_text: 'Nueva orden',
      DxDailyPlanning_new_order_hint: 'Nueva orden',
      DxDailyPlanning_task_status_caption: 'Estado Tarea',
      DxDailyPlanning_work_order_status_caption: 'Estado Orden Trabajo',
      DxDailyPlanning_supervisors_caption: 'Supervisores',
      DxDailyPlanning_vehicle_driver: 'Maquinaría: Conductor',
      DetailTabs_supervisors_title: 'Supervisores',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Localización',
      WorkOrder_title: 'ESTADO ORDEN DE TRABAJO',
      FuelRegistration_title: 'REGISTRO COMBUSTIBLE',
      Employee_title: 'LISTA TRABAJADORES',
      WorkOrderDetail_select_supervisor: 'Seleccionar supervisor',
      WorkOrderDetail_select_maquinaria: 'Seleccionar maquinaria',
      WorkOrderDetail_order: 'ORDEN',
      DxWorkOrders_edit_text: 'Editar',
      DxWorkOrders_edit_hint: 'Editar',
      DxWorkOrders_modal_title: 'Editar ordenes de trabajo',
      DxWorkOrders_machinery_caption: 'Maquinaria',
      DxWorkOrders_driver_caption: 'Conductor',
      DxWorkOrders_km_start_caption: 'Km comienzo',
      DxWorkOrders_km_finish_caption: 'Km fin',
      DxWorkOrders_horometer_start_caption: 'Horometro comienzo',
      DxWorkOrders_horometer_finish_caption: 'Horometro fin',
      DxWorkOrders_fuel_consumption_caption: 'Combustibles consumido (L)',
      DxWorkOrders_fuel_type_caption: 'Tipo Combustible',
      DxWorkOrders_working_area_caption: 'Area trabajo',
      DxWorkOrders_aditional_info_caption: 'Información adicional',
      DxProductPerformance_unit: 'Unidad',
      DxProductPerformance_products_name: 'Producto',
      DxProductPerformance_products_qtyCollected: 'Cantidad recolectada',
      DxProductPerformance_products_qtyPlanned: 'Cantidad planificada',
      DxProductPerformance_machinery_qtyTask: 'Cantidad devuelva a almacén',
      DxProductPerformance_products_qtyUsed: 'Cantidad usada',
      DxProductPerformance_products_qtyReturnWarehouse: 'Cantidad devuelta',
      DxProductPerformance_products_totalRealPrescription: 'Disolución Utilizada (L)',
      DxProductPerformance_products_realSolution: 'Real solución',
      DxProductPerformance_products_realDilutionFactor: 'Factor de dilución real',
      DxProductPerformance_products_appliedDose: 'Dosis aplicada',
      DxProductPerformance_products_theoricalDose: 'Dosis teórica',
      EditOrder_ButtonSave_caption: 'Guardar',
      EditOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ButtonSave_caption: 'Guardar',
      CreateOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ValidationOutDate: 'No puede crear una orden de trabajo con una planificación fuera de fecha',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Ya está seleccionado',
      DxDailyPlanning_delete_text: 'Eliminar',
      DxDailyPlanning_delete_hint: 'Eliminar',
      DxWorkOrder_Button_View_text: 'Vista',
      DxWorkOrder_Button_View_hint: 'Vista',
      DetailTabWorkers_sin_trabajadores: 'No existen trabajadores asignados a la tarea',
      WorkOrderDetail_select_date: 'Seleccionar fecha',
      DxSelectDate_date_caption: 'Fecha',
      PlanningComponent_typeUnit_caption: 'Tipo unidad',
      DxDailyPlanning_performanceha_caption: 'Objetivo',
      DxDailyPlanning_totalArea: 'Area total (ha)',
      PlanningComponent_performanceUnit_caption: 'Rendimiento Unidad',
      PlanningComponent_performanceCount_caption: 'Productividad Planificada',
      PlanningComponent_distributed_caption: 'Distribuido',
      EmployeeComponent_expirationDate_caption: 'Fecha de expiración',
      EmployeeComponent_datepicker_expirationDate_placeholder: 'Seleccione Fecha',
      EmployeeComponent_applicator_caption: 'Aplicador',
      Status_Open: 'Abierta',
      Status_InProgress: 'En progreso',
      Status_Completed: 'Completa',
      Status_Pending: 'Pendiente',
      Status_Deleted: 'Eliminada',
      DetailTabWorkers_sin_items: 'No existe maquinaria asignada a la tarea',
      DetailTab_sin_seleccion: 'No existe selección',
      WorkOrderDetail_additional_info: 'Información adicional',
      WorkOrderDetail_select_driver: 'Seleccionar conductor',
      CreateOrder_ValidationItemsDriversWorkOrder: 'Hay conductores sin asignar',
      Login_inpUsername_validate: 'Completa este campo',
      Login_inpPassword_validate: 'Completa este campo',
      EffortAgriFarmCockpit_navbar_title: 'REPORTE DE ESFUERZO AGRI FARM COCKPIT',
      AgriFarmCockpit_navbar_title: 'REPORTE AGRI FARM COCKPIT', // TODO: Revisar nombre
      WeatherFarmCockpit_navbar_title: 'GRAFICOS DEL CLIMA',
      EffortTask_navbar_title: 'REPORTE DE ESFUERZO TAREA',
      PrecisionFarming_navbar_title: 'PRECISION FARMING',
      LeftPanel_title_reporting: 'Reportes',
      LeftPanel_title_plan: 'Plan',
      LeftPanel_title_execution: 'EJECUCIÓN',
      LeftPanel_title_prescriptions: 'PRESCRIPCIONES',
      LeftPanel_title_weather: 'CLIMA',
      LeftPanel_title_warehouse: 'BODEGUERO',
      LeftPanel_subtitle_fuelRegistration: 'Registro combustible',
      LeftPanel_subtitle_precisionFarming: 'Precision Farming',
      LeftPanel_title_fertilizers: 'FERTILIZANTES',
      LeftPanel_title_information: 'INFORMACION',
      LeftPanel_subtitle_dailyPlanning: 'Planificación Diaria',
      LeftPanel_subtitle_workOrders: 'Estado orden de trabajo',
      LeftPanel_subtitle_unplannedWorkOrders: 'Registro manual OT',
      LeftPanel_subtitle_postWork: 'Órdenes tras completar', // TODO: Revisar nombre
      LeftPanel_subtitle_logOut: 'Salir',
      LeftPanel_subtitle_home: 'Inicio',
      LeftPanel_subtitle_activeIngredients: 'Principios Activos autorizados EU',
      LeftPanel_subtitle_prescriptionManager: 'Gestión de prescripciones',
      LeftPanel_subtitle_fertilizersPrescriptions: 'Gestión de Prescripciones',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Informe de actividad',
      LeftPanel_subtitle_weatherFarmCockpit: 'Gráficos del Clima',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Base de Datos local de fitosanitarios',
      LeftPanel_subtitle_employee: 'Trabajadores',
      LeftPanel_title_dataConfiguration: 'CONFIGURACION DATOS',
      LeftPanel_subtitle_productsLocalDatabase: 'Base de Datos local de productos',
      LeftPanel_subtitle_monthlyPlanning: 'Budget Anual',
      LeftPanel_subtitle_harvesting: 'Cosecha',
      DxEffort_level_caption: 'Nivel',
      DxMonthlyMachinary_text: 'Añadir nueva maquinaria',
      DxDailyPlanning_days_caption: 'Dias',
      DxDailyPlanning_p_caption: 'Permanente',
      DxDailyPlanning_t_caption: 'Temporal',
      DxDailyPlanning_c: 'Contratista',
      DxDailyPlanning_coste: 'Coste',
      DxEffort_activity_caption: 'Actividad',
      DxEffort_task_caption: 'Tarea',
      DxEffort_hrHours_caption: 'Horas RH',
      DxEffort_totalHours_caption: 'Horas totales',
      DxEffort_totalHoursHa_caption: 'Horas totales/Ha',
      DxEffort_totalHoursTotalHa_caption: '% de horas totales vs total de Ha',
      DxEffort_progressCompleted_caption: 'Progreso (% Completado)',
      Search_placeholder: 'Buscar',
      WeatherForecast_title: 'Predicción Meteorológica',
      WeatherStatus_title: 'Estado Meteorológico',
      GisMap_title: 'Mapa',
      PlanningComponent_machinary_caption: 'Maquinaria',
      DxProducts_dxColumn_Unit: 'Unidad',
      DxAttachment_dxColumn_TypeAttachment: 'Tipo',
      DxAttachment_dxColumn_QuantityAttachment: 'Cantidad',
      DxMachinery_dxColumn_TypeMachinery: 'Tipo',
      DxMachinery_dxColumn_QuantityMachinery: 'Cantidad',
      DxProducts_dxColumn_activeIngredients: 'Ingredientes activos',
      DxProducts_dxColumn_productName: 'Nombre producto',
      DxProducts_dxColumn_dosis: 'Dosis',
      DxProducts_dxColumn_dosisUt: 'Dosis Unid',
      DxProducts_dxColumn_dosisTotal: 'Cantidad Planificada',
      DxProducts_dxColumn_solucion: 'Solución',
      DxProducts_dxColumn_solucionUt: 'Solución Unid',
      DxProducts_dxColumn_dilutionFactor: 'Factor de mojamiento',
      DxProducts_dxColumn_unit: 'Unidad',
      DxProducts_dxColumn_dilutionFactorUt: 'Unidad',
      DxProducts_dxColumn_solutionTotal: 'Disolucion planificada (L)',
      DxProducts_dxColumn_caution: 'Precaución',
      DxProducts_dxColumn_restrictedEntryInterval: 'Intervalo de entrada',
      DxProducts_dxColumn_preharvestInterval: 'Intervalo de precosecha',
      ActiveIngredients_navbar_title: 'PRINCIPIOS ACTIVOS AUTORIZADOS EU',
      PrescriptionManager_navbar_title: 'GESTIÓN DE PRESCRIPCIONES',
      DxCreatePrescription_button_text: 'Crear nueva prescripción',
      DxCreatePrescription_column_active: 'Active',
      DxCreatePrescription_column_name: 'Nombre prescripción',
      DxCreatePrescription_column_type: 'Tipo',
      DxCreatePrescription_column_method: 'Huerto / Vivero',
      ORCHARD: 'Huerto',
      NURSERY: 'Vivero',
      DxCreatePrescription_column_disease: 'Disolución',
      DxCreatePrescription_column_activeSince: 'Activo desde',
      DxCreatePrescription_column_notes: 'Notas',
      CreatePrescription_Title: 'CREAR PRESCRIPCION',
      EditPrescription_Title: 'EDITAR PRESCRIPCION',
      EditProduct_navbar_create: 'EDITAR PRODUCTO',
      EditProduct_navbar_edit: 'CREAR NUEVO PRODUCTO',
      DxFormProduct_code_caption: 'SAP ID',
      DxFormProduct_code_required: 'El código es requerido',
      DxFormProduct_status_caption: 'ESTADO',
      DxFormProduct_comment_caption: 'COMENTARIO',
      DxFormProduct_description_caption: 'DESCRIPCION ARTICULO SAP',
      DxFormProduct_component_caption: 'INGREDIENTE ACTIVO',
      DxFormProduct_family_caption: 'NOMBRE GRUPO SAP',
      DxFormProduct_subfamily_caption: 'SUBGRUPO',
      DxFormProduct_sapunit_caption: 'U.M SAP',
      DxFormProduct_toxicity_caption: 'TOXICIDAD',
      DxFormProduct_description_required: 'La descripcion es requerida.',
      DxInformationPrescription_name_caption: 'NOMBRE',
      DxInformationPrescription_active_caption: 'ACTIVO',
      DxInformationPrescription_dateofactivation_caption: 'FECHA DE ACTIVACION',
      DxInformationPrescription_typeofprescription_caption: 'TIPO DE PRESCRIPCION',
      DxInformationPrescription_methodofapplication_caption: 'HUERTO / VIVERO',
      DxInformationPrescription_notes_caption: 'NOTAS',
      DxInformationPrescription_name_required: 'Nombre requerido',
      DxInformationPrescription_date_required: 'Fecha requerida',
      DxInformationPrescription_typeofprescription_required: 'Tipo de prescripción requerido',
      DxInformationPrescription_methodofapplication_required: 'El campo Huerto/Vivero es requerido',
      totalArea_field: 'Área total (ha)',
      totalArea_title: 'Resumen de parcelas seleccionadas',
      DxAttachment_Title: 'Aperos',
      DxMachineryPerformance_Title: 'Rendimiento de Maquinaria',
      DxProductsPerformance_Title: 'Rendimiento de Productos',
      DxMachinery_Title: 'Maquinaria',
      DxTools_Title: 'Herramientas',
      DxProducts_Title: 'Productos',
      DxTools_dxColumn_TypeTool: 'Tipo',
      DxTool_dxColumn_QuantityTool: 'Cantidad',
      CreatePrescription_ValidationName: 'El nombre es necesario',
      CreatePrescription_ValidationNameDuplicated: 'El nombre indicado ya está en uso',
      CreatePrescription_ValidationProducts: 'Es necesario incluir al menos un producto',
      CreatePrescription_ValidationMethod: 'Es necesario seleccionar un método de aplicación',
      CreatePrescription_ValidationType: 'Es necesario seleccionar un tipo de prescripción',
      CreatePrescription_ValidationDate: 'Es necesario seleccionar una fecha',
      CreatePrescription_ValidationActive: 'Una nueva prescripción debe ser activa',
      DxPlanningProducts_prescription: 'Nombre Prescripción',
      DxPlanningProducts_prescription_required: 'Prescripción requerida',
      DxPlanningProducts_typePrescription: 'Tipo Prescripción',
      DxPlanningProducts_typePrescription_required: 'Tipo Prescripción requerido',
      DxPlanningProducts_typeOfPrescription: 'Tipo de presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Tipo de presc. requerido',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Sustancia activa',
      approval_date: 'Fecha de aprobación',
      approval_expiration: 'Fecha de expiración',
      authorisation_in_progress: 'Autorización en curso',
      authorised: 'Autorizada',
      category: 'Categoría',
      organic_approved: 'Orgánica aprobada',
      remark: 'Observación',
      status: 'Estado',
      substitution_candidate: 'Candidata a sustitución',
      countries: 'Países',
      FertilizersPrescriptions_title: 'PRESCRIPCIONES DE FERTILIZANTES',
      ProductsDatabase_title: 'BASE DE DATOS DE PRODUCTOS',
      DxAttachmentMachinary_dxColumn_Machinary: 'Maquinaria',
      DxAttachmentMachinary_dxColumn_Attachment: 'Apero',
      DxWeatherElementLinesGraph_average: 'Media',
      DxWeatherElementLinesGraph_averageForecasted: 'Media Predictiva',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Predictivo',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'El rango debe ser superior a 0',
      DxProduts_rule_solution_required: 'La solución es requerida',
      DxProduts_rule_dosis_required: 'Dosis es requerida',
      DxProdutsDataBase_button_text: 'Crear nuevo producto',
      DxProdutsDataBase_edit_text: 'Editar',
      DxProdutsDataBase_edit_hint: 'Editar',
      DxEmployeeTable_Title: 'Trabajadores',
      DxPostWorkEmployeeTable_rule_range: 'El rango debe ser superior a 0',
      DxPostWorkEmployeeTable_Name: 'Nombre',
      DxPostWorkEmployeeTable_Surname: 'Apellido',
      DxPostWorkEmployeeTable_Checkin: 'Checkin',
      DxPostWorkEmployeeTable_Checkout: 'Checkout',
      DxPostWorkEmployeeTable_Productivity: 'Productividad real',
      DxPostWorkEmployeeTable_Unit: 'Unidad',
      DxPostWorkEmployeeTable_Notes: 'Notas',
      DxTaskOrders_navbar_Name: 'REGISTRO DE ORDEN DE TRABAJO',
      PlanningComponent_datepicker_multiple_placeholder: 'Seleccionar fechas',
      CreatePostWork_Navbar_Title: 'REGISTRAR NUEVA ORDEN DE TRABAJO',
      ViewPostWork_Navbar_Title: 'VISTA DE LA ORDEN DE TRABAJO',
      DxPostWorkEmployeeTable_dialogCreate_wrongHour: 'La hora de inicio debe ser anterior a la hora de fin.',
      CreatePostWork_Validation_PrescriptionNeeded: 'Debe añadir una receta en esta tarea.',
      DxCreatePrescription_column_family: 'Familia',
      DxProducts_dxColumn_productGroup: 'tipo',
      DxProducts_dxColumn_productFamily: 'Grupo',
      DxPlanningForm_activity_name: 'Nombre de la actividad obligatorio',
      DxPlanningForm_Task_name: 'Nombre de la tarea obligatorio',
      DxFertilizersDataBase_active_caption: 'Activo',
      DxFertilizersDataBase_description_caption: 'Descripción',
      DxFertilizersDataBase_family_caption: 'Familia',
      DxFertilizersDataBase_subfamily_caption: 'Subfamilia',
      DxPhytosanitariesDataBase_active_caption: 'Activo',
      DxPhytosanitariesDataBase_description_caption: 'Descripción',
      DxPhytosanitariesDataBase_family_caption: 'Familia',
      DxPhytosanitariesDataBase_subfamily_caption: 'Subfamilia',
      DxProductsDataBase_status_caption: 'Estado',
      DxProductsDataBase_code_caption: 'Código SAP',
      DxProductsDataBase_description_caption: 'Descripción artículo SAP',
      DxProductsDataBase_family_caption: 'Nombre grupo SAP',
      DxProductsDataBase_subfamily_caption: 'Subgrupo',
      DxProductsDataBase_component_caption: 'Ingrediente Activo',
      DxPostWorkEmployeeTable_dialogCreate_wrongCheckIn: 'La fecha de inicio tiene que ser la misma que la fecha de la tarea.',
      LeftPanel_title_machinery: 'MAQUINARIA',
      LeftPanel_subtitle_machineryDataBase: 'Base de datos de Maquinaria',
      Machinery_title: 'MAQUINARIA',
      CreateMachinery_Title: 'CREAR MAQUINARIA',
      EditMachinery_Title: 'EDITAR MAQUINARIA',
      DxMachineryDatabase_column_status: 'Estado',
      DxMachineryDatabase_column_family: 'Familia',
      DxMachineryDatabase_column_subfamily: 'Subfamilia',
      DxMachineryDatabase_column_description: 'Descripción artículo SAP',
      DxMachineryDatabase_column_brand: 'Marca',
      DxMachineryDatabase_column_code: 'Código Interno',
      DxMachineryDatabase_column_attachment: 'Implementos',
      DxMachineryDatabase_button_text: 'Crear nueva maquinaria',
      DxMachineryForm_code_caption: 'Código SAP',
      DxMachineryForm_code_required: 'Código es requerido',
      DxMachineryForm_description_caption: 'Descripción',
      DxMachineryForm_description_required: 'Descripción es requerida',
      DxMachineryForm_brand_caption: 'Marca',
      DxMachineryForm_brand_required: 'Marca es requerida',
      DxMachineryForm_model_caption: 'Modelo',
      DxMachineryForm_model_required: 'Modelo es requerido',
      DxMachineryForm_serial_number_caption: 'Número de serie',
      DxMachineryForm_serial_number_required: 'Número de serie requerido',
      DxMachineryForm_plate_caption: 'Placa',
      DxMachineryForm_plate_required: 'Placa es requerida',
      DxMachineryForm_internal_code_caption: 'Código interno',
      DxMachineryForm_internal_code_required: 'Código interno es requerido',
      DxMachineryForm_family_caption: 'Familia',
      DxMachineryForm_family_required: 'Familia es requerida',
      DxMachineryForm_subfamily_caption: 'Subfamilia',
      DxMachineryForm_subfamily_required: 'Subfamilia es requerida',
      DxMachineryForm_attachment_caption: 'Implementos',
      DxMachineryForm_purchase_caption: 'Fecha adquisición',
      DxMachineryForm_purchase_required: 'Fecha adquisición es requerida',
      DxMachineryForm_status_caption: 'Estado',
      Machinery_ValidationSAPCode: 'El campo código SAP es obligatorio',
      Machinery_ValidationInternalCode: 'El campo código interno es obligatorio',
      Machinery_ValidationBrand: 'El campo marca es obligatorio',
      Machinery_ValidationModel: 'El campo modelo es obligatorio',
      Machinery_ValidationSerialNumber: 'El campo nuúmero de serie es obligatorio',
      Machinery_ValidationPlate: 'El campo placa es obligatorio',
      Machinery_ValidationDescription: 'El campo descripción es obligatorio',
      Machinery_ValidationFamily: 'El campo familia es obligatorio',
      Machinery_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Machinery_ValidationPurchase: 'El campo adquisición es obligatorio',
      // Generics
      Button_cancel_text: 'Cancelar',
      Button_save_text: 'Guardar',
      LeftPanel_subtitle_productsDataBase: 'Base de datos de productos',
      LeftPanel_subtitle_reportingWorker: 'Trabajadores',
      LeftPanel_subtitle_timeWorked: 'Entrada / Salida',
      ReportingWorker_navbar_title: 'REPORTE TRABAJADORES',
      ReportingTimeWorked_navbar_title: 'ENTRADA / SALIDA',
      DxWorker_date_caption: 'Fecha',
      DxWorker_task_caption: 'Tarea',
      DxWorker_typeTask_caption: 'Tipo',
      DxWorker_codeTask_caption: 'Código tarea',
      DxWorker_workerName_caption: 'Trabajador',
      DxWorker_name_caption: 'Nombre',
      DxWorker_workerSurname_caption: 'Apellido',
      DxWorker_idNumber_caption: 'Número de ID',
      DxWorker_typeWorker_caption: 'Permanente/Temporal/Contratista',
      DxWorker_territorialUnit_caption: 'Localización (Unidades Territoriales)',
      DxWorker_totalHours_caption: 'Nº de Horas',
      DxWorker_workingDay_caption: 'Jornada',
      DxWorker_performanceUnit_caption: 'U. MEDIDA',
      DxWorker_performanceCount_caption: 'Productividad real',
      DxWorker_note_caption: 'Nota',
      CreateMonthlyPlanning_ValidationDate: 'El número de días de trabajo debe corresponder con la cantidad de fechas seleccionadas',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'Debe de planificar al menos un día de trabajo',
      DxMonthlyForm_activityTask: 'Tarea de actividad',
      DxMonthlyForm_goalUnit: 'Obejtivo Unitario',
      DxMonthlyForm_DateProgrammed: 'Fecha Programada',
      DxMonthlyForm_numberWorkers_caption: 'Número de trabajadores',
      DxMonthlyForm_performanceWorker_caption: 'RENDIMIENTO/TRABAJADOR',
      DxMonthlyForm_workersDaysRequired: 'DIAS DE TRABAJO REQUERIDOS',
      DxMonthlyForm_machineryDaysRequired: 'DIAS DE MAQUINARIA REQUERIDOS',
      DxMonthlyForm_toolsConsumptionTotal: 'CONSUMO TOTAL HERRAMIENTAS (L)',
      DxMonthlyForm_estimatedCostActivity: 'COSTE ESTIMADO ACTIVIDAD',
      DxMonthlyForm_taskDuration: 'DURACIÓN DE LA TAREA',
      DxToolsMonthlyTable_type_caption: 'Tipo',
      DxToolsMonthlyTable_consumption_caption: 'consumo',
      DxToolsMonthlyTable_consumption_type_caption: 'tipo de consumo',
      DxToolsMonthlyTable_consumption_unit_caption: 'Obejtivo Unitario',
      DxMachineryMonthlyTableHADAY: 'HA/DIA',
      DxMachineryMonthlyTableUNDIA: 'UN/DIA',
      Home_navbar_title: 'INICIO',
      // CRUD Costes.
      Costs_title: 'COSTES',
      DxCostsDatabase_column_name: 'Nombre',
      DxCostsDatabase_edit_text: 'Editar',
      DxCostsDatabase_edit_hint: 'Editar',
      LeftPanel_subtitle_costsDataBase: 'Base de datos de costes',
      DxCostsDatabase_column_code: 'Codigo',
      DxCostsDatabase_column_id: 'Id',
      DxCostsDatabase_column_price: 'Precio',
      DxCostsDatabase_column_currency: 'Moneda',
      DxCostsDatabase_column_currency_code: 'Codigo Moneda',
      DxCostsDatabase_column_unit: 'Unidad',
      DxCost_button_text: 'Crear nuevos Costes',
      DxCost_title_creation: 'CREAR COSTES',
      DxCostForm_code_required: 'El codigo es necesario',
      DxCostForm_name_required: 'El Nombre es necesario',
      DxCostForm_price_required: 'El Precio es necesario',
      DxCostForm_Unit_required: 'La unidad es necesaria',
      DxCostForm_Currency_required: 'La Monead es necesaria',
      DxCostForm_Id_required: 'Id es necesario',
      CreacteCost_ValidationCode: 'El campo código es obligatorio',
      CreacteCost_ValidationName: 'El campo name es obligatorio',
      CreacteCost_ValidationPrecio: 'El campo Precio es obligatorio',
      CreacteCost_ValidationUnit: 'El campo unidad de serie es obligatorio',
      CreacteCost_ValidationCurrency: 'El campo moneda es obligatorio',
      CreacteCost_ValidationCurrencyCode: 'El campo codigo moneda es obligatorio',
      CreacteCost_ValidationId: 'El campo ID es obligatorio',
      CreateCost_dialogDelete_title: 'Eliminar Coste',
      DeleteCost_dialogDelete_text: '¿Estas seguro de eliminar el coste seleccinado?',
      // Summary BPAS
      DxDxBpas_Date_caption: 'Fecha',
      DxDxBpas_Location_caption: 'Unidades Territoriales',
      DxDxBpas_ActiveIngredients_caption: 'Principio Activos',
      DxDxBpas_dosisUt_caption: 'Dosis',
      DxDxBpas_attachment_caption: 'Apero',
      DxDxBpas_machineryfamily_caption: 'Familia maquinaria',
      DxDxBpas_attachmentFamily_caption: 'Familia apero',
      DxDxBpas_Machinary_caption: 'Maquinaria',
      DxDxBpas_Name_caption: 'Nombre Comercial',
      DxDxBpas_QtyUsed_caption: 'Gasto total Producto',
      DxDxBpas_SolutionTotal_caption: 'Mojamiento Planificado',
      DxDxBpas_Variety_caption: 'Variedad',
      DxDxBpas_Worker_caption: 'Aplicador',
      DxDxBpas_Task_caption: 'Tarea',
      DxDxBpas_CodeTask_caption: 'Código Tarea',
      DxDxBpas_TypeTask_caption: 'Tipo',
      DxDxBpas_Formulas_caption: 'Nombre Prescripción',
      DxListEmployee_role_caption: 'Rol',
      DxPrescriptionManager_dosis: 'Dosis',
      DxPrescriptionManager_solution: 'Mojamiento',
      DxPrescriptionManager_description: 'Descripción artículo SAP',
      DxMachineryDatabase_company: 'Localizacion',
      // Summary Machinery
      DxMachinery_Date_caption: 'Fecha',
      DxMachinery_Location_caption: 'Unidad Territorial',
      DxMachinery_Attachment_caption: 'Implementos',
      DxMachinery_unit_caption: 'U. Medida',
      DxMachinery_init_caption: 'km inicial',
      DxMachinery_end_caption: 'km final',
      DxMachinery_total_caption: 'km realizados',
      DxMachinery_Name_caption: 'Máquina',
      DxMachinery_workingArea_caption: 'Área trabajada',
      DxMachinery_SolutionTotal_caption: 'Mojamiento',
      DxMachinery_Variety_caption: 'Variedad',
      DxMachinery_Worker_caption: 'Conductor',
      DxMachinery_Task_caption: 'Tarea',
      DxMachinery_typeFuel_caption: 'Tipo de Combustible',
      DxMachinery_quantityFuel_caption: 'Cantidad de Combustible',
      DxMachinery_performance_caption: 'Productividad real',
      DxMachinery_quantity_caption: 'Cantidad',
      DxMachinery_CodeTask_caption: 'Código Tarea',
      DxMachinery_InternalCode_caption: 'Códgio Interno',
      DxMachinery_TypeTask_caption: 'Tipo',
      DxMachinery_Formulas_caption: 'Formulación',
      DxMachinery_comment_caption: 'Notas',
      DxWorker_ActivityCode_caption: 'Código de Actividad',
      DxWorker_group_caption: 'Grupo',
      DxWorker_shift_caption: 'Turno',
      DxWorker_Activity_caption: 'Actividad',
      DxWorker_location_caption: 'Localización',
      DxWorker_initDate_caption: 'Hora Inicio',
      DxWorker_endDate_caption: 'Hora Fin',
      DxWorker_checkIn_caption: 'Check-In',
      DxWorker_checkOut_caption: 'Check-out',
      DxWorker_jornada_caption: 'Jornada',
      ReportingMachinery_navbar_title: 'REPORTE MAQUINARIA',
      ReportingBPAS_navbar_title: 'INFORME USO FITOSANITARIO',
      DxDxBpas_activity_caption: 'Actividad',
      DxDxBpas_activityCode_caption: 'Código Actividad',
      DxMachinery_Activity_caption: 'Actividad',
      DxMachinery_ActivityCode_caption: 'Código Actividad',
      DxMonthlyPlanning_totalCost: 'Coste total',
      DxPostWorkEmployeeTable_WorkingArea: 'Área de Trabajo Real',
      CreatePlanning_ValidationTotalWorkingDays: 'El número de días de trabajo de la maquinaria supera el número de días de trabajo totales. Ajuste el rendimiento de la maquinaria.',
      CreateOrder_ValidationAttachments: 'Hay maquinaria sin asignar en los aperos',
      UploadHarvestingPlan_preloader: 'Subiendo el fichero. Esto puede tardar un poco de tiempo según el tamaño del fichero',
      UploadHarvestingPlan_success: 'Fichero subido correctamente. Estamos procesando  las works orders. Puede tardar un tiempo en verlas',
      UploadHarvestingPlan_error: 'Se ha producido el siguiente error:',
      UploadHarvestingPlan_button: 'Cargar fichero',
      UploadHarvestingPlan_empty: 'Por favor, seleccione un fichero',
      LeftPanel_subtitle_logs: 'Cosecha Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Fecha',
      DxErrorLogs_Time_caption: 'Hora',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Descripción',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'El supervisor es requerido',
      LeftPanel_subtitle_reportingBpas: 'Informe Uso fitosanitario',
      CalendarInitEndDate_btnClear: 'Limpiar',
      CalendarInitEndDate_btnSearch: 'Buscar',
      PlanningComponent_seasional_caption: 'Cantidad',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescripcion',
      DxDxBpas_totalRealPrescription_caption: 'Mojamiento utilizado',
      LeftPanel_subtitle_reportingMachinery: 'Maquinaría',
      DxWorkOrders_notes_caption: 'Notas',
      DxWorkOrders_variety_caption: 'Variedad',
      ReportingEmployeeEffectiveness_navbar_title: 'EFECTIVIDAD DE EMPLEADO',
      ReportingEmployeeEffectiveness_date: 'Fecha',
      ReportingEmployeeEffectiveness_task: 'Tarea',
      ReportingEmployeeEffectiveness_taskCode: 'Código Tarea',
      ReportingEmployeeEffectiveness_Activity: 'Actividad',
      ReportingEmployeeEffectiveness_activityCode: 'Código Actividad',
      ReportingEmployeeEffectiveness_performance: 'Productividad real',
      ReportingEmployeeEffectiveness_timeSpent: 'Tiempo Empleado',
      ReportingEmployeeEffectiveness_fullname: 'Nombre',
      ReportingEmployeeEffectiveness_type: 'Tipo',
      ReportingEmployeeEffectiveness_IdTask: 'Id Tarea',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Suma',
      HOURS: 'Horas',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Tiempo Empleado',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Rendimiento Total',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Productividad Planificada',
      DxDailyPlanning_realGoal_caption: 'Productividad real',
      DxDailyPlanning_goalVsReal_caption: '% planificación completada',
      DxDailyPlanning_realCost: 'Coste real',
      DxWorkOrders_PlannMachinary_caption: 'Maquinaria Planeada',
      DxWorkOrders_WorkedMachinary_caption: 'Maquinaria trabajando',
      DxPlannReal_plannWorkers_caption: 'Empleado planificado',
      DxPlannReal_workers_caption: 'Empleado trabajando',
      DxPlannReal_Totalworkers_caption: 'Total trabajadores',
      ReportingWorkOrders_navbar_title: 'INFORME ÓRDENES DE TRABAJO',
      LeftPanel_subtitle_reportingWorkOrders: 'Órdenes de Trabajo',
      DxListTaskGroupedActivivty_task: 'Tarea',
      DxListTaskGroupedActivivty_taskCode: 'Código Tarea',
      DxListTaskGroupedActivivty_performanceUnit: 'Objetivo Unitario',
      DxListTaskGroupedActivivty_Activity: 'Actividad',
      DxListTaskGroupedActivivty_NavBarName: 'ACTIVIDAD Y TAREAS',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Actividad y Tareas',
      PlanningComponent_variety_caption: 'Variedad',
      DxPlanningForm_variety_required: 'Variedad requerida',
      DxDailyPlanning_CostVsReal: '% Coste Completado',
      planned_title: 'PLANEADO VS REAL',
      ReportingplannedVsReal_navbar_title: 'Planeado vs Real',
      Variety_title: 'VARIEDAD',
      LeftPanel_subtitle_varietyDatabase: 'Base de datos de Variedad',
      DxVarietyDatabase_column_code: 'Código',
      DxVarietyDatabase_column_name: 'Nombre',
      DxProductsDataBase_UMSAP_caption: 'U.M SAP',
      DxProductTable_dialogCreate_solutionNotEmpty: 'El campo solución no puede estar vacio o ser menor de 0',
      DxProducts_dxColumn_solution: 'Mojamiento',
      DxProducts_dxColumn_solutionUt: 'Unidad Mojamiento',
      DxDetailTabs_products_quantityPlanned: 'Cantidad Planificada',
      DxDetailTabs_products_waterPlanned: 'Disolución planificada',
      CreatePlanning_ValidationPrescription: 'Es necesario seleccionar una prescripción',
      DxBpas_Machinary_InternalCodecaption: 'Código Interno',
      DxDxBpas_QtyUsedUnit_caption: 'U. Medida',
      DxWorkOrders_kmTotal_caption: 'Km realizados',
      DxWorkOrders_HorometerTotal_caption: 'Horas realizadas',
      DxProductPerformance_products_doseUsed: 'Dosis usada',
      PlanningComponent_grouped_caption: 'Agrupado',
      Workers_StackedBarPlanned: 'Trabajadores',
      machinary_StackedBarPlanned: 'Maquinarías',
      ChartPlanned_StackedBarPlanned: 'Planificado vs Trabajando',
      plannWorkers_StackedBarPlanned: 'Trabajadores Planificados',
      plannMachinery_StackedBarPlanned: 'Maquinarías Planificadas',
      DxPostWorkMachinaryDriverTable_machinery_validating: 'Esta maquinaria ya está seleccionada',
      dxSideBySideBar_totalWorkerByActivity: 'Trabajadores Totales Por Actividad',
      dxSideBySideBar_Worker: 'Trabajadores',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Jornada trabajada por Actividad',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Jornada',
      DxToolsDatabase_column_status: 'Estado',
      DxToolsDatabase_column_code: 'Código',
      DxToolsDatabase_column_family: 'Familia',
      DxToolsDatabase_column_subfamily: 'Subfamilia',
      DxToolsDatabase_column_description: 'Descripción',
      DxToolsDatabase_column_brand: 'Matricula',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'Combustible',
      DxToolsDatabase_column_itemId: 'Id articulo',
      LeftPanel_subtitle_tools: 'Herramientas',
      editTool_Title: 'EDITAR HERRAMIENTA',
      createTool_Title: 'CREAR HERRAMIENTA',
      Tools_ValidationCode: 'El campo código es obligatorio',
      Tools_ValidationBrand: 'El campo marca es obligatorio',
      Tools_ValidationID: 'El campo ID es obligatorio',
      Tools_ValidationItemID: 'El campo elemento ID es obligatorio',
      Tools_ValidationDescription: 'El campo descripción es obligatorio',
      Tools_ValidationFamily: 'El campo familia es obligatorio',
      Tools_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Tools_ValidationFuel: 'El campo Combustible es obligatorio',
      DxWorkOrder_short_id: 'ID orden de trabajo',
      DxManualPerformance_total_performance: 'Rendimiento total',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Rendimiento total',
      DxPopupWorkOrder_Title_machinery: 'Maquinaria',
      DxPopupWorkOrder_Title_product: 'Productos',
      DxWorkOrders_manualPerformance_caption: 'Kg Cosechados',
      PlanningComponent_manualPerformance_caption: 'Rendimiento total',
      ProductCollection_navbar_title: 'RETIRAR PRODUCTOS',
      LeftPanel_subtitle_productCollection: 'Retirar Productos',
      ReturnedProduct_navbar_title: 'DEVOLVER PRODUCTOS',
      LeftPanel_subtitle_returnedProduct: 'Devolver Productos',
      DxProductWarehouse_column_date: 'Fecha',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Actividad',
      DxProductWarehouse_column_activityCode: 'Código Actividad',
      DxProductWarehouse_column_task: 'Tarea',
      DxProductWarehouse_column_taskCode: 'Código Tarea',
      DxProductWarehouse_column_productName: 'Producto',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Cantidad Recolectada',
      DxProductWarehouse_column_statusCollection: 'Estado',
      DxProductWarehouse_column_statusConfirmed: 'Enviado',
      DxProductWarehouse_column_statusNotConfirmed: 'No enviado',
      DxProductWarehouse_column_status: 'Estado de orden',
      DxProductWarehouse_column_cost_center: 'Centro de costes',
      DxProductWarehouse_column_cost_center_required: 'Centro de costes requerido',
      DxProductWarehouse_column_warehouse: 'Almacén',
      DxProductWarehouse_column_warehouse_required: 'Almacén requerido',
      DxProductWarehouse_column_COMPLETED: 'Completada',
      DxProductWarehouse_column_IN_PROGRESS: 'En progreso',
      DxProductWarehouse_column_planedQuantity: 'Cantidad Planificada',
      // Greenhouse
      DxBins_dxColumn_Code: 'Código',
      DxBins_dxColumn_CreationDate: 'Fecha de creación',
      DxBins_dxColumn_NameBins: 'Nombre',
      BatchesForm_create_batches_info: 'Información del lote',
      BatchesForm_edit_batches_info: 'Información del lote',
      CreateBatch_Title: 'CREAR LOTE',
      EditBatch_Title: 'EDITAR LOTE',
      CreateBatch_ButtonSave_caption: 'CREAR',
      DxBatches_dxColumn_BinsList: 'Bins Contenidos',
      DxBatches_dxColumn_NameBatch: 'Nombre Lote',
      DxBatches_dxColumn_CreationDate: 'Fecha Creación',
      DxBatches_dxColumn_Code: 'Código',
      LeftPanel_subtitle_create_batches: 'Crear Lotes',
      LeftPanel_subtitle_batches: 'Consultar Lotes',
      LeftPanel_subtitle_create_bins: 'Crear Bins',
      LeftPanel_subtitle_bins: 'Consultar Bins',
      LeftPanel_subtitle_received_bins: 'Listado de bins recibidos',
      LeftPanel_subtitle_reclassification: 'Reclasificación',
      LeftPanel_subtitle_create_bare_roots: 'Crear Estacas',
      LeftPanel_subtitle_bare_roots: 'Consultar Estacas',
      BareRootsForm_bare_root_info: 'Datos de la Estaca',
      BareRoots_create_navbar_title: 'CREAR ESTACA',
      BareRoots_edit_navbar_title: 'EDITAR ESTACA',
      BareRoots_title: 'ESTACAS',
      BareRoots_content_title: 'Características de la Estaca',
      CreateBareRoot_ButtonSave_caption: 'Crear Estaca',
      BareRoots_content_form_level_0: 'Nivel 0',
      BareRoots_content_form_variety: 'Variedad',
      BareRoots_content_form_variety_required: 'Variedad requerida',
      BareRoots_content_form_class: 'Clase',
      BareRoots_content_form_class_required: 'Clase requerida',
      BareRoots_content_form_level_last: 'Nivel',
      BareRoots_content_form_category: 'Categoría',
      BareRoots_content_form_category_required: 'Categoría requerida',
      BareRoots_content_form_plants: 'Nº de Plantas',
      BareRoots_content_form_plants_required: 'Nº de plantas debe ser superior a 0',
      BareRoots_content_form_note: 'Nota',
      EditBareRoot_ButtonSave_caption: 'Guardar cambios',
      Batches_Title: 'LOTES',
      Bins_create_navbar_title: 'CREAR BIN',
      Bins_reclassify_navbar_title: 'RECLASIFICAR BIN',
      Bins_edit_navbar_title: 'EDITAR BIN',
      BinsForm_bins_info: 'Datos del Bin',
      DxBinsInfoForm_date_caption: 'Fecha',
      DxBinsInfoForm_name_caption: 'Nombre',
      DxBinsInfoForm_bins_location_caption: 'Campo',
      Bins_content_title: 'Contenido del Bin',
      DxBinsContentGrid_code: 'Codigo',
      DxBinsContentGrid_parent_code: 'Codigo del Padre',
      DxBinsContentGrid_location_level_0_caption: 'Nivel 0',
      DxBinsContentGrid_location_level_last_caption: 'Nivel',
      DxBinsContentGrid_variety_caption: 'Variedad',
      DxBinsContentGrid_category_caption: 'Categoría',
      DxBinsContentGrid_classification_caption: 'Clase',
      DxBinsContentGrid_plants_count_caption: 'Nº de Plantas',
      Bins_Title: 'LISTADO DE BINS',
      Reclassification_Title: 'RECLASIFICACIÓN',
      DxBinsTable_State_Created: 'Creado',
      DxBinsTable_State_Registered: 'Registrado',
      DxBinsTable_State_Closed: 'Cerrado',
      DxBinsTable_State_Sent: 'Enviado',
      DxBinsTable_State_Received: 'Recibido',
      DxBinsTable_State_ToReceive: 'Pendiente de recibir',
      DxBinsTable_dxColumn_Code: 'Código',
      DxBinsTable_dxColumn_Name: 'Nº Bins',
      DxBinsTable_dxColumn_LotName: 'Nº Lote',
      DxBinsTable_dxColumn_Origin: 'Farm Origen',
      DxBinsTable_dxColumn_Destination: 'Farm Destino',
      DxBinsTable_dxColumn_State: 'Estado Envío',
      DxDetailTabBins_dxColumn_Variety: 'Variedad',
      DxDetailTabBins_dxColumn_NumPlants: 'Nº Plantas',
      DxDetailTabBins_dxColumn_Category: 'Categoría',
      DxDetailTabBins_dxColumn_Classification: 'Clasificación',
      DxDetailTabsBins_BinsData: 'Datos del bin',
      DxDetailTabsBins_Origin: 'Origen',
      DxDetailTabsBins_Destination: 'Destino',
      DxDetailTabBins_dxColumn_Code: 'Código',
      DxDetailTabBins_dxColumn_LastLevel: 'Nivel',
      DxBinsTable_ButtonText_Register: 'Registrar',
      DxBinsTable_ButtonText_Send: 'Enviar',
      DxBinsTable_ButtonText_Move: 'Mover',
      DxBinsTable_ButtonText_Close: 'Cerrar',
      DxBinsTable_ButtonText_ConfirmReception: 'Confirmar Recepción',
      DxSelectLevel0Location_modal_title: 'Seleccione destino:',
      DxSelectLevel0Location_requiredRule_farm: 'Este campo es requerido',
      DxSelectLevel0Location_farm: 'Destino',
      DxBinsTable_button_send: 'Enviar',
      DxBinsTable_button_cancel: 'Cancelar',
      CreateBin_ButtonSave_caption: 'CREAR',
      ReclassifyBin_ButtonSave_caption: 'RECLASIFICAR',
      MoveBin_ButtonSave_caption: 'MOVER',
      EditBin_ButtonSave_caption: 'GUARDAR',
      EditBatch_ButtonSave_caption: 'GUARDAR',
      ButtonCancel_caption: 'CANCELAR',
      DxBinsContentGridPlanning_bin: 'Bin',
      DxBinsContentGridPlanning_variety: 'Variedad',
      DxBinsContentGridPlanning_category: 'Categoría',
      DxBinsContentGridPlanning_classification: 'Clase',
      DxBinsContentGridPlanning_content: 'Contenido',
      DxBinsContentGridPlanning_plants_count: 'Nº Plantas',
      DxNursery_Title: 'Viveros',
      CreatePlanning_Duplicated_Nursery_Rows: 'Usted tiene una o más filas duplicadas en la tabla Vivero',
      Nursery_OnlyOneLastLevelMessage: '* IMPORTANTE: Seleccionar sólo un último nivel',
      DetailTabs_nursery_title: 'Viveros',
      BatchesInfoForm_name: 'Nombre',
      DxProductWarehouse_edit_validation: 'El producto ya está enviado',
      DxProductWarehouse_Product_Not_Completed: 'No puede editar este producto: La orden del producto no está completada',
      Bins_move_navbar_title: 'MOVER CONTENIDO DEL BIN',
      AnnualBudgets_Title: 'BUDGET ANUAL',
      DxAnnualBudgets_year_caption: 'Año',
      DxAnnualBudgets_version_caption: 'Versión',
      DxAnnualBudgets_status_caption: 'Estado',
      DxAnnualBudgets_createAnnualBudget_text: 'Crear budget anual',
      CreateAnnualBudget_Title: 'CREAR BUDGET ANUAL',
      AnnualBudget_version_validation: 'El campo Versión es requerido',
      AnnualBudget_year_validation: 'El campo Año es requerido',
      DxAnnualBudgetForm_version_caption: 'Versión',
      DxAnnualBudgetForm_year_caption: 'Año',
      PlanningComponent_monthlyPlanning: 'Budget anual',
      CloneAnnualBudget_Title: 'CLONAR VERSIÓN BUDGET ANUAL',
      AnnualBudget_catch_NAME_EXIST_case: 'Ya existe una versión con el mismo nombre',
      AnnualBudget_catch_NOT_CLOSED_case: 'Todas las veriones tienen que estar cerradas',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'No se encontró la versión',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'No se encontró el budget',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'No puede haber más de 3 versiones en un budget',
      DxBins_dxColumn_Location: 'Ubicación',
      LeftPanel_title_nursery: 'Vivero',
      Rules_count_more_than_zero: 'La cantidad tiene que ser superior a 0.',
      LeftPanel_subtitle_pestsDiseases: 'Plagas y enfermedades',
      DxPestsDiseasesDatabase_edit_hint: 'Editar',
      DxPestsDiseasesDatabase_column_type: 'Tipo',
      DxPestsDiseasesDatabase_column_name: 'Nombre',
      DxPestsDiseasesDatabase_column_scientific_name: 'Nombre científico',
      DxPestsDiseasesDatabase_column_symthoms: 'Síntomas',
      DxPestsDiseasesDatabase_column_photos: 'Fotos',
      DxPestsDiseasesDatabase_button_create_text: 'Crear nueva plaga/enfermedad',
      PestsDiseases_title: 'PLAGAS Y ENFERMEDADES',
      LeftPanel_subtitle_extremeWeatherCondicions: 'Condiciones climáticas extremas',
      ExtremeWeatherCondicions_title: 'CONDICIONES CLIMÁTICAS EXTREMAS',
      DxExtremeWeatherCondicionsDatabase_edit_hint: 'Editar',
      DxExtremeWeatherCondicionsDatabase_column_type: 'Tipo',
      DxExtremeWeatherCondicionsDatabase_column_name: 'Nombre',
      DxExtremeWeatherCondicionsDatabase_button_create_text: 'Crear nueva condición climática extrema',
      LeftPanel_subtitle_phenologies: 'Fenologías',
      PhenologiesDatabase_title: 'FENOLOGIAS',
      DxPhenologiesDatabase_edit_hint: 'Editar',
      DxPhenologiesDatabase_column_photos: 'Fotos',
      DxPhenologiesDatabase_column_code: 'Código',
      DxPhenologiesDatabase_column_phase: 'Fase',
      DxPhenologiesDatabase_column_description: 'Descripción',
      DxPhenologiesDatabase_button_create_text: 'Crear nueva fenología',
      DxPlannReal_workersOut_caption: 'Trabajadores saliendo',
      LeftPanel_title_scouting: 'MONITOREO',
      LeftPanel_subtitle_monitoringPoints: 'Puntos de monitoreo',
      MonitoringPoints_title: 'PUNTOS DE MONITOREO',
      CreatePoint_Title: 'CREAR PUNTO',
      EditPoint_Title: 'EDITAR PUNTO',
      DxMonitoringPoints_name: 'Nombre',
      DxMonitoringPoints_location: 'Unidad territorial',
      DxMonitoringPoints_coordinates: 'Coordenadas',
      DxMonitoringPoints_numberPlants: 'Número de plantas',
      DxMonitoringPoints_variety: 'Variedad',
      DxMonitoringPoints_type: 'Tipo',
      DxMonitoringPoints_yearPlanting: 'Año de plantación',
      DxMonitoringPoints_statusFormatted: 'Estado',
      DxMonitoringPoints_note: 'Nota',
      DxMonitoringPoints_createPoint_button: 'Crear punto',
      Point_name_validation: 'Name field is required',
      DxPointForm_name_caption: 'Name',
      DxPointForm_latitude_caption: 'Latitude',
      DxPointForm_longitude_caption: 'Longitude',
      DxPointForm_numberPlants_caption: 'Número de plantas',
      DxPointForm_variety_caption: 'Variedad',
      DxPointForm_yearPlanting_caption: 'Año de plantación',
      DxPointForm_note_caption: 'Nota',
      DxPointForm_name_required: 'Name field is required',
      DxPointForm_type_caption: 'Tipo',
      DxTreePlanning_onlyLevel1_location_already_selected: 'Ya hay una localización seleccionada',
      DxPointForm_location_caption: 'Unidad territorial',
      Point_type_validation: 'Tipo requerido',
      Button_confirm_text: 'Confirmar',
      DxMonitoringPoints_dialogDelete_title: 'Borrar punto de monitoreo',
      DxMonitoringPoints_dialogDelete_text: '¿Está seguro de borrar el punto de monitoreo?',
      Point_date_validation: 'Año requerido',
      DxWarehouse_totalKm_caption: 'Km total',
      DxWarehouse_totalHorometer_caption: 'Horómetro total',
      DxWarehouse_totalKm_validation: 'Km inicio no puede ser mayor que Km final',
      DxWarehouse_totalHorometer_validation: 'Horómetro inicio no puede ser mayor que Horómetro final',
      StoreProduct_ValidationComponent: 'Componente requerido',
      StoreProduct_ValidationCode: 'Código requerido',
      StoreProduct_ValidationFamily: 'Familia requerida',
      StoreProduct_ValidationSubfamily: 'Subfamilia requerida',
      StoreProduct_ValidationSapunit: 'Unidad SAP requerida',
      StoreProduct_ValidationDescription: 'Descripción requerida',
      StoreProduct_ValidationCodeDuplicated: 'Código esta duplicado',
      DxProductPerformance_products_qtyTask: 'Cantidad devuelta al almacén',
      DetailTabProduct_no_seleccionado: 'Ningún producto seleccionado',
      DetailTabAttachemnt_not_selected: 'Ningún implemento seleccionado',
      TasksList_WorkersSelection_subtitle: 'Pulsar el botón superior para seleccionar trabajadores',
      TasksList_WorkersSelection_title: 'No hay trabajadores en la tarea',
      TasksList_Workers_title: 'Trabajadores',
      scs_banned: 'SCS banned',
      DxTimeWorked_location_caption: 'Localización',
      DxPostWorkMachinaryDriverTable_km_validating: 'Km inicio no puede ser mayor que Km final',
      DxPostWorkMachinaryDriverTable_horometer_validating: 'Horómetro inicio no puede ser mayor que Horómetro final',
      DxWorkOrders_fuel_horometerDiff_caption: 'Horas de funcionamiento',
      Monitoring_column_date: 'Fecha',
      Monitoring_point_type: 'Tipo de punto',
      Monitoring_point_description: 'Descripción del punto',
      Monitoring_point_location: 'Localización',
      Monitoring_point_longitude: 'Longitud',
      Monitoring_point_latitude: 'Latitud',
      Monitoring_point_number_plants: 'Nº Plantas',
      Monitoring_point_variety: 'Variedad',
      Monitoring_point_planting_year: 'Año de plantado',
      Monitoring_point_note: 'Notas del punto',
      Monitoring_number: 'Numbero',
      Monitoring_plagues_and_diseases: 'Plagas/Enfermedades',
      Monitoring_state: 'Estado',
      Monitoring_phenological_phase: 'Fase fenologica',
      Monitoring_comments: 'Comentarios',
      Monitoring_photos: 'Fotos',
      LeftPanel_subtitle_monitoringPests: 'Plagas',
      LeftPanel_subtitle_monitoringDiseases: 'Enfermedades',
      LeftPanel_subtitle_monitoringPhenologies: 'Fenologías',
      LeftPanel_subtitle_monitoringWeatherCondicions: 'Condiciones climáticas',
      LeftPanel_subtitle_monitoringOthers: 'Otros',
      Monitoring_pests_navbar_title: 'MONITOREO - PLAGAS',
      Monitoring_diseases_navbar_title: 'MONITOREO - ENFERMEDADES',
      Monitoring_phenologies_navbar_title: 'MONITOREO - FENOLOGIAS',
      Monitoring_weather_condicions_navbar_title: 'MONITOREO - CONDICIONES CLIMÁTICAS',
      Monitoring_others_navbar_title: 'MONITOREO - OTROS',
      LeftPanel_subtitle_climateGrids: 'Tablas del Clima',
      ClimateGrids_navbar_title: 'TABLAS DEL CLIMA',
      ClimateGrids_DxWeatherHumidityGrid_blockTitle: 'HUMEDAD',
      ClimateGrids_DxWeatherPrecipitationGrid_blockTitle: 'PRECIPITACIÓN',
      ClimateGrids_DxWeatherTemperatureGrid_blockTitle: 'TEMPERATURA',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Temperature: 'Temperatura media',
      ClimateGrids_DxWeatherTemperatureGrid_max_Temperature: 'Temperatura máxima',
      ClimateGrids_DxWeatherTemperatureGrid_min_Temperature: 'Temperatura mínima',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Humidity: 'Humedad media',
      ClimateGrids_DxWeatherTemperatureGrid_max_Humidity: 'Humedad máxima',
      ClimateGrids_DxWeatherTemperatureGrid_min_Humidity: 'Humedad mínima',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Precipitation: 'Precipitación media',
      Monitoring_point_name: 'PUNTO DE CONTROL',
      Monitoring_point_variety_code: 'Código Variedad',
      no_photos: 'No hay imágenes que mostrar',
      show: 'Mostrar',
      LeftPanel_subtitle_Irrigation: 'Riego',
      UploadFile_error: 'Se ha producido el siguiente error:',
      UploadFile_preloader: 'Subiendo el fichero. Esto puede tardar un poco de tiempo según el tamaño del fichero',
      UploadFile_success: 'Fichero subido correctamente. Estamos procesandolo. Puede tardar un tiempo en los cambios',
      UploadFile_button: 'Cargar fichero',
      UploadFile_empty: 'Por favor, seleccione un fichero',
      DxIrrigationTable_dxColumn_from: 'Desde',
      DxIrrigationTable_dxColumn_to: 'Hasta',
      DxIrrigationTable_dxColumn_location: 'Campo',
      DxIrrigationTable_dxColumn_sector: 'Sector',
      DxIrrigationTable_dxColumn_time: 'Tiempo (minutos)',
      DxIrrigationTable_dxColumn_waterVolume: 'Volumen de Agua',
      DxIrrigationTable_dxColumn_f1: 'F1',
      DxIrrigationTable_dxColumn_f2: 'F2',
      DxIrrigationTable_dxColumn_f3: 'F3',
      DxIrrigationTable_dxColumn_f4: 'F4',
      DxIrrigationTable_dxColumn_f5: 'F5',
      DxIrrigationTable_dxColumn_f6: 'F6',
      DxIrrigationTable_dxColumn_f7: 'F7',
      DxIrrigationTable_dxColumn_f8: 'F8',
      DxIrrigationTable_dxColumn_avgPh: 'AVG PH',
      DxIrrigationTable_dxColumn_avgCe: 'AVG CE',
      DxIrrigationTable_dxColumn_realFlow: 'Flujo Real (m3/h)',
      DxIrrigationTable_dxColumn_prevFlow: 'Flujo Previo (m3/h)',
      DxIrrigationTable_dxColumn_team: 'Equipo',
      Point_location_validation: 'Seleccionar solo una localización',
      Point_no_selected_location_validation: 'Seleccione una localización válida',
      CreateEmployee_EmployeeCodeDuplicated: 'El ID de empleado se encuentra en uso',
      CreateEmployee_EmployeeGenericError: 'Ha ocurrido un error: ',
      DxDailyPlanning_creation_caption: 'Creación',
      DxDailyPlanning_CreationPlanned_caption: 'Planificado',
      DxDailyPlanning_CreationManually_caption: 'Manual',
      DxWorkOrders_ExportTranslation_WorkerType: 'TIPO TRABAJADOR',
      DxWorkOrders_ExportTranslation_NurseryCode: 'VIVERO CÓDIGO',
      DxWorkOrders_ExportTranslation_NurseryVariety: 'VIVERO VARIEDAD',
      DxWorkOrders_ExportTranslation_NurseryCategory: 'VIVERO CATEGORÍA',
      DxWorkOrders_ExportTranslation_NurseryClassification: 'VIVERO CLASIFICACIÓN',
      DxWorkOrders_ExportTranslation_NurseryNumPlants: 'VIVERO Nº PLANTAS',
      ExportDataToExcel_Button: 'Exportar Todo',
      company_not_found: 'Empresa no encontrada',
      task_type_not_found: 'Tipo de tarea no encontrado',
      planner_not_found: 'Planificación no encontrada',
      task_not_found: 'Tarea no encontrada',
      item_not_found: 'Item no encontrado',
      farm_not_found: 'Granja no encontrada',
      product_not_found: 'Producto no encontrado',
      product_not_in_prescription: 'El producto no se encuentra en la prescripción',
      product_not_in_task: 'El producto no se encuentra en la tarea',
      product_stock_is_less: 'El stock del producto es inferior al seleccionado',
      prescription_not_found: 'Prescripción no encontrada',
      badget_not_found: 'Budget no encontrada',
      badget_version_not_found: 'Versión de Budget no encontrada',
      badget_version_task_not_found: 'No se encuentra la tarea en la versión actual de Budget',
      variety_not_found: 'Variedad no encontrada',
      irrigation_task_not_found: 'Tarea de riego no encontrada',
      scouting_not_found: 'Exploración no encontrada',
      scouting_point_not_found: 'Punto de monitoreo no encontrado',
      warehouse_log_not_found: 'Log de Almacén no encontrado',
      warehouse_not_found: 'Almacén no encontrado',
      cost_center_not_found: 'Centro de coste no encontrado',
      badget_version_is_closed: 'La versión de Budget ya está cerrada',
      planner_not_in_planning_status: 'No se encuentra la planifiación',
      task_location_exist_with_same_date: 'Ya hay una tarea en esta localización y con esta fecha',
      task_not_referenced: 'Tarea no referenciada',
      planner_not_referenced: 'Planificación no referenciada',
      task_deleted_correctly: 'Tarea eliminada correctamente',
      no_task_for_selected_date_404: 'No hay tareas para la fecha actual',
      location_not_found: 'Localización no encontrada',
      worker_not_found: 'Trabajador no encontrado',
      worker_already_exist_in_task: 'El trabajador ya se encuentra en esta tarea',
      worker_already_exist_in_other_task: 'El trabajador se encuentra en otra tarea',
      worker_not_exist_in_task: 'El trabajador no existe en esta tarea',
      code_not_exist_in_request: 'No existe código en la petición',
      code_not_exist: 'El código no existe',
      outdated: 'Fuera de fecha',
      invalid_responsible: 'Responsable no válido',
      invalid_item_family: 'Familia no válida',
      Not_Found: 'No encontrado',
      ID_Must_be_especified: 'El ID debe ser especificado',
      Phenological_phase_not_found: 'Fase fenológica no encontrada',
      Plagues_and_diseases_not_found: 'Plagas y enfermedades no encontradas',
      Scouting_point_required: 'Punto de monitoreo requerido',
      CreatePlanning_DuplicatedEmployees: 'Trabajadores duplicados: ',
      PlanningComponent_comments_caption: 'Comentarios',
      DxPostWorkEmployeeTable_dialogCreate_wrongWorker: 'Hay un trabajador que no es seleccionable.',
      DxDailyPlanning_CostVsReal_versionName: 'Versión',
      DxProductWarehouse_Popup_Send_Title: 'Enviar:',
      DxProductWarehouse_ButtonText_ReSend: 'Reenviar',
      DxProductWarehouse_ButtonText_Adjust: 'Ajustar',
      DxProductWarehouse_Alert_FieldsRequired: 'Todos los campos son requeridos',
      DxProductWarehouse_Alert_CorrectResend: 'Producto reenviado correctamente',
      DxBinsContentGrid_PlantingYear: 'Año de plantación',
      DxProducts_dxColumn_solution_total: 'Mojamiento total',
      DxLocationSelector_ParentLevel: 'Nivel Superior',
      DxListEmployee_group_caption: 'Grupo',
      DxListEmployee_shift_caption: 'Turno',
      DxWeatherGrid_date_time: 'Hora',
      Monitoring_point_code: 'Código',
      DxFormProduct_maxdosis: 'Máxima Dosis Permitida',
      DxFormProduct_currentIn: 'Intervalo de entrada restringida',
      DxFormProduct_SCS_autorizado: 'SCS Autorizado',
      DxFormProduct_EU_autorizado: 'EU Autorizado',
      DxFormProduct_preHarvest: 'Intervalo de precosecha',
      DxProductsDataBase_currentIN: 'I.E.R.',
      DxProductsDataBase_preHarverst: 'Intervalo P.',
      DxMonthlyForm_jornada: 'jornadas',
      DxMachinery_AttachmentInternalCode_caption: 'Código implemento',
      DxWorkOrders_ExportTranslation_Group: 'Grupo',
      DxWorkOrders_ExportTranslation_Shift: 'Turno',
      DxMonthlyPlanning_comments: 'Notas',
      DxMonthlyPlanning_goal_harvesting_caption: 'Kg planif.',
      DxDailyPlanning_workers_performance_caption: 'Productividad real',
      DxMachinery_horometer_start_caption: 'Horómetro start',
      DxMachinery_horometer_end_caption: 'Horómetro finish',
      DxMachinery_horometer_total_caption: 'Horómetro total',
      DxScouting_fullName: 'Nombre Completo',
      LeftPanel_subtitle_territorialUnit: 'Unidades Terrritoriales',
      LeftPanel_subtitle_territorialUnitcaps: 'UNIDADES TERRITORIALES',
      DxTerritorialUnit_area: 'Area',
      DxTerritorialUnit_code: 'Codigo',
      DxTerritorialUnit_farm: 'Farm',
      DxTerritorialUnit_id: 'ID',
      DxTerritorialUnit_name: 'Nombre',
      DxTerritorialUnit_pattern: 'Patrón',
      DxTerritorialUnit_planting_year: 'Año de plantado',
      DxTerritorialUnit_plants: 'Plantas',
      DxTerritorialUnit_variety_code: 'Código variedad',
      DxTerritorialUnit_variety_name: 'Nombre variedad',
      DetailTabDrivers_sin_items: 'No hay datos',
      DetailTabs_driver_title: 'Conductores',
      Stock_Title: 'Stock',
      LeftPanel_subtitle_stock: 'Stock',
      DxStockTable_dxColumn_Code: 'Código',
      DxStockTable_dxColumn_Description: 'Descripción',
      DxStockTable_dxColumn_Stock: 'Stock',
      DxPostWorkMachinaryDriverTable_km_or_horometer_validating: 'Debe seleccionar un Km o un Horómetro de inicio',
      DxPostWorkEmployeeManualHarvesting_Kg: 'Kg Cosechados',
      DxProductWarehouse_column_productWarehouse_required: 'La cantidad es requerida',
      DxWorkOrders_ExportTranslation_Variety: 'Variedad',
      DxWorkOrders_ExportTranslation_Driver: 'Conductor',
      DxDailyPlanning_export_activity_code: 'Código de actividad',
      DxDailyPlanning_export_areaEfective: 'Área efectiva',
      DxDailyPlanning_export_Comments: 'Notas',
      DxDailyPlanning_export_TaskCode: 'Código de tarea',
      DxDailyPlanning_export_variety: 'Variedad',
      DxDailyPlanning_export_performance: 'Productividad real',
      DxDailyPlanning_export_machinary_consumption: 'Consumo de maquinaria',
      DxDailyPlanning_export_machinary_consumption_type: 'Tipo de consumo',
      DxDailyPlanning_export_machinary_notes: 'Notas de maquinaria',
      DxDailyPlanning_export_machinary_performance: 'Rendimiento de maquinaria',
      DxDailyPlanning_export_machinary_type: 'Tipo de maquinaria',
      DxDailyPlanning_export_tools_consumption: 'Consumo de herramienta',
      DxDailyPlanning_export_tools_consumption_type: 'Tipo de consumo',
      DxDailyPlanning_export_tools_count: 'Cantidad',
      DxDailyPlanning_export_tools_type: 'Tipo de herramienta',
      DxDailyPlanning_export_products_code: 'Código de producto',
      DxDailyPlanning_export_products_dilution_factor: 'Factor de dilución',
      DxDailyPlanning_export_products_dosis: 'Dosis del producto',
      DxDailyPlanning_export_products_dosis_total: 'Dosis total',
      DxDailyPlanning_export_products_name: 'Nombre del producto',
      DxDailyPlanning_export_products_solution: 'Solución del producto',
      DxDailyPlanning_export_products_solution_total: 'Solución total',
      DxStockTable_dxColumn_Unit: 'Unidad',
      DxTaskOrder_origen: 'Origen',
      DxBareRootTable_dxColumn_CreationDate: 'Fecha de creación',
      DxBareRootTable_dxColumn_Name: 'Nombre',
      DxBareRootTable_dxColumn_Origin: 'Origen',
      DxBareRootTable_dxColumn_PlantYear: 'Año de clasificación',
      DxBareRootTable_dxColumn_Variety: 'Variedad',
      DxBareRootTable_dxColumn_Category: 'Categoría',
      DxBareRootTable_dxColumn_ClassType: 'Clase',
      DxBareRootTable_dxColumn_NumPlants: 'Nº Plantas',
      DxBareRootTable_dxColumn_BinCode: 'Bin',
      DxBatchesInfoForm_Farm: 'Ubicación',
      DxBatchesInfoForm_Farm_Rule: 'Es necesario establecer una ubicación',
      DxBatchesInfoForm_Name_Rule: 'El nombre es requerido',
      DxBinsInfoForm_guide_number_caption: 'Nº Guía Despacho',
      DxBinsContentGrid_egress_caption: 'Egreso',
      DxBpas_short_id: 'ID OT',
      DxDxBpas_Farm_caption: 'Campo',
      DxDxBpas_Working_Area_caption: 'Área de trabajo real',
      DxToolsTable_Add_Change_typeTools: 'La herramienta ya está seleccionada',
      DxToolsTable_tools: 'Herramienta',
      error_401: 'El usuario o contraseña son incorrectos',
      TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED: 'Los Kg totales cosechados deben ser mayores a cero',
      TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE: 'Los datos de maquinaría están incompletos',
      TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO: 'Cuando las unidades son "Cc/Hl", "Gr/Hl" debe seleccionar una total prescription distinta de 0.',
      error_403: 'No tiene permisos para realizar esta acción. Por favor, póngase en contacto con su administrador',
      DxToolsTable_Add_Change_error_count: 'No hay suficientes herramientas',
      LeftPanel_subtitle_nurseryReport: 'Vivero',
      Nursery_navbar_title: 'VIVERO',
      DxTable_Date_caption: 'Fecha',
      DxTable_ut_caption: 'UT',
      DxTable_type_caption: 'Tipo',
      DxTable_variety_caption: 'Variedad',
      DxTable_plantYear_caption: 'Año plantación',
      DxTable_category_caption: 'Categoría',
      DxTable_amount_caption: 'Cantidad',
      DxTable_notes_caption: 'Notas',
      DxNurseryStockReport_typeReclassified_caption: 'Tipo reclasificación',
      DxNurseryStockReport_pottedYear_caption: 'Año enmacetado',
      DxNurseryStockReport_categoryReclassified_caption: 'Categoría reclasificación',
      DxNurseryStockReport_finalDestination_caption: 'Destino final',
      DxNurseryReport_classification: 'Clasificación',
      DxNurseryReport_stock: 'Stock',
      DxNurseryReport_egresos: 'Egresos',
      DxNurseryEgresosReport_egresosType_caption: 'Tipo de egreso',
      DxNurseryEgresosReport_bins_caption: 'Bins',
      DxNurseryEgresosReport_orm_caption: 'N Guia / ORM',
      DxNurseryEgresosReport_destiny_caption: 'Destino',
      DxNurseryEgresosReport_state_caption: 'Estado de envio',
      DxProductWarehouse_BookedQuantity: 'Cantidad Comprada',
      DxProductWarehouse_BookedQuantity_required: 'Booked Quantity es requerida',
      DxPointForm_plantingYear_caption: 'Año',
      BareRoots_content_form_type: 'Tipo',
      BareRoots_content_form_type_required: 'El tipo es requerido',
      DxBareRootTable_dxColumn_Type: 'Tipo',
      DxDailyPlanning_workers_performance_unit_caption: 'U. Medida',
      Date_OutOfRange: 'Fecha seleccionada fuera de rango. Máximo rango: ',
      DxPlannReal_WorkedMachinaryOut_caption: 'Maquinaria saliendo',
      DxWorkOrders_productivity_planned_caption: 'Productividad planificada',
      DxWorkOrders_productivity_caption: 'Productividad real',
      DxHarvestActivitiesReport_menu_title: 'Reporte de actividades de cosecha',
      date: 'Fecha',
      team: 'Equipo',
      farm: 'Campo',
      variety: 'Variedad',
      kilos: 'Kilos',
      bags: 'Sacos',
      task: 'Tarea',
      harvestReport_working_area: 'Área de trabajo real',
      machinery: 'Maquinaria',
      operator: 'Operador',
      numWorkers: 'Num. Personas',
      harvestReport_horometer: 'Horas (Horómetro)',
      observation: 'Observación',
      DxWorker_supervisor_caption: 'Supervisor',
      DxWorker_workingArea_caption: 'Área de trabajo real',
      DxWorker_variety_caption: 'Variedad (cosecha)',
      DxWorker_productivity_caption: 'Productividad Real',
      DxWorker_performanceUnitProductivity_caption: 'U. Medida',
      DxWorker_prescription_caption: 'Prescripción',
      DxWorker_productsUsed_caption: 'LTS/KG APLICADOS',
      DxWorker_workingTime_caption: 'HORAS TRABAJADAS',
      DxWorker_machineId_caption: 'MAQUINA (ID)',
      DxWorker_implementoId_caption: 'IMPLEMENTO (ID)',
      DxWorker_notes_caption: 'Notas',
      DxEffort_activity_numberWorker_caption: 'Número de trabajadores',
      DxSideBySideTypeofWorker_number_hours: 'Nº DE HORAS',
      DxSideBySideTypeofWorker_title_graph: 'Horas trabajadas por campo',
      DxGraphLinePoint_title_graph: 'Total (kilos/día)',
      ReportingHarvest_navbar_title: 'REPORTE COSECHA',
      LeftPanel_subtitle_reportingHarvest: 'Cosecha',
      DxReportingHarvest_variety: 'Variedad',
      DxReportingHarvest_farm: 'EQUIPO',
      DxReportingHarvest_mechanical: 'MEC',
      DxReportingHarvest_manual: 'MAN',
      DxReportingHarvest_total: 'Total general',
      DxReportingHarvest_date: 'Fecha',
      DxReportingHarvest_totalKgDay: 'TOTAL (kilos/día)',
      DxReportingHarvest_accumulated: 'Acumulado',
      DxSeries_Permanent_name: 'PERMANENTE',
      DxSeries_Temporary_name: 'TEMPORAL',
      DxSeries_Contratist_name: 'CONTRATISTA',
      Reporting_DxWorkOrder_short_id: 'ID orden de trabajo',
      DxEffortActivity_totalHoursHa_caption: 'Total horas trabajadas / HA',
      DxEffortActivity_totalHours_caption: 'Total horas trabajadas',
      DxMachineryForm_internal_code_sap_caption: 'Código Interno',
      DxMonthlyForm_updateActualDate_numberWorkingDays_alert: 'No ha definido el rendimiento',
      LeftPanel_title_harvesting: 'Cosecha',
      LeftPanel_subtitle_reportingHarvest_ot: 'Cosecha OT',
      LeftPanel_subtitle_harvest_logs: 'Cosecha logs',
      LeftPanel_subtitle_harvest_activities_report: 'Reporte de Actividades de cosecha',
      LeftPanel_subtitle_harvesting_advance: 'Avance cosecha',
      DxDailyPlanning_export_areawork: 'Área de trabajo real',
      DxDxBpas_SolutionUnit_caption: 'U. Medida',
      Duration: 'Duración',
      DxWarehouse_location: 'Unidad territorial',
      Draft: 'Draft',
      Confirm: 'Confirmar',
      Confirmar_WorkOrder_Navbar_Title: 'Confirmar orden de trabajo',
      DxWorkOrder_RealworkingArea_caption: 'Área trabajada',
      DxMachinery_plannedworkingArea_caption: 'Área de trabajo planificada',
      DxPopupWorkOrder_Title_tool: 'Herramientas',
      DxPopupWorkOrder_description_tool: 'Nombre Herramienta',
      DxDailyPlanning_plannedWorkers_caption: 'Trabajadores planificados',
      DxDailyPlanning_realWorkers_caption: 'Trabajadores reales',
      EditOrder_navbar_title_edit_preworkorder: 'Editar pre-orden de trabajo',
    },
  },
};
export default dictionary;
