<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Observations_Title')}`" />
    <HeaderTemplateForm
      :templates-categories="templatesCategories"
      :template-category="currentObservationTemplate.category"
      :form-name="currentObservationTemplate.subcategory"
    />
    <AttributesTemplateForm
      :attributes="currentObservationTemplate.form"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          styling-mode="contained"
          name="ButtonCancel"
          @click="goToObservations"
        >
          {{ $t('Button_cancel_text') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          styling-mode="outlined"
          name="ButtonSave"
          @click="callEditObservationTemplate"
        >
          {{ $t('Button_save_text') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import HeaderTemplateForm from '../../../components/configuration/observations/headerTemplateForm/index.vue';
import AttributesTemplateForm from '../../../components/configuration/observations/attributesTemplateForm/index.vue';

export default {
  name: 'EditObservationTemplate',
  components: {
    navbar,
    HeaderTemplateForm,
    AttributesTemplateForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Observations', ['currentObservationTemplate', 'templatesCategories', 'currentObservationTemplateId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchTemplatesCategories();
      await this.getTemplateById(this.currentObservationTemplateId);
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async callEditObservationTemplate() {
      if (this.currentObservationTemplate.category === '') {
        this.$f7.dialog.alert(this.$t('Configuration.Category_Validation'));
      } else if (this.currentObservationTemplate.subcategory === '') {
        this.$f7.dialog.alert(this.$t('Configuration.Subcategory_Validation'));
      } else if (this.currentObservationTemplate.form.length === 0) {
        this.$f7.dialog.alert(this.$t('Configuration.Form_Validation'));
      } else {
        this.$f7.preloader.show();
        try {
          await this.updateObservationTemplate({ observationTemplateId: this.currentObservationTemplateId, observationTemplate: this.currentObservationTemplate });
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
          this.$f7.views.main.router.navigate('/observations/', { reloadCurrent: true });
        }
      }
    },
    goToObservations() {
      this.$f7.views.main.router.navigate('/observations/', { reloadCurrent: true });
    },
    ...mapActions('Observations', ['updateObservationTemplate', 'fetchTemplatesCategories', 'getTemplateById']),
  },
};
</script>
