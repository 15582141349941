<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Costs_title')}`" />
    <DxCostsDatabase />
  </f7-page>
</template>

<script>

import DxCostsDatabase from '../../../components/configuration/costs/DxCostsDatabase.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  components: {
    DxCostsDatabase,
    navbar,
  },
};
</script>

<style>

</style>
