<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${$t('Page_subtitle_clients')} > ${actualUser.name} > ${$t('Location_title')}`" />
    <RegisterLocation
      :title="this.$t('Register_farm')"
      :current-explotation="{
        name,
        registerNumber,
        province,
        farm,
        ownerFarm,
        mobile,
        postalCode,
      }"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerFarmAndCreatePlot"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterLocation from '../../components/systemParcelExplotaciones/RegsiterLocation/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'RegisterLocationPage',
  components: {
    RegisterLocation,
    navbar,
  },
  computed: {
    ...mapState('RegisterLocation', ['name', 'registerNumber', 'province', 'mobile', 'farm', 'ownerFarm', 'postalCode']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
  },
  beforeMount() {
    this.setShowPopUp(false);
  },
  methods: {
    async registerFarmAndCreatePlot() {
      this.$f7.preloader.show();
      try {
        if (this.name === '') {
          this.$f7.dialog.alert(this.$t('DxRegisterLocation_nombre_validation'));
        } else if (this.province === '') {
          this.$f7.dialog.alert(this.$t('register_location_required_province'));
        } else if (this.farm === '') {
          this.$f7.dialog.alert(this.$t('register_location_required_locality'));
        } else {
          this.setallProperties();
          await this.createExplotation(this.actualUser.id);
          this.setShowPopUp(true);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', { reloadCurrent: true });
    },
    setallProperties() {
      const newProperties = [
        { key: 'number_register', value: this.registerNumber },
        { key: 'owner_Farm', value: this.ownerFarm },
        { key: 'mobile', value: this.mobile },
        { key: 'province', value: this.province },
        { key: 'locality', value: this.farm },
        { key: 'postal_code', value: this.postalCode },
      ];
      this.setProperties(newProperties);
    },
    ...mapActions('Explotaciones', ['setShowPopUp']),
    ...mapActions('RegisterLocation', ['setProperties', 'initStore', 'createExplotation']),
  },
};
</script>

<style scoped>
</style>
