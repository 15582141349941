import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'reference-value',
  components: { DxDataGrid, DxColumn },
  props: [],
  data() {
    return {
      referenceProduction: '',
      observation: ''
    };
  },
  computed: {
    ...mapGetters('referenceValueStore', ['referenceValues']),
    ...mapState('nutritionStepper', ['stepData']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async created() {
    if (this.stepData.referenceValues.length > 0) {
      return;
    }

    await this.getReferenceValues(this.currentPlantation.species);
    this.stepData.referenceValues = this.referenceValues;

    this.referenceValues.forEach((item) => {
      this.referenceProduction = item.referenceProduction;
      this.observation = item.observation;
    });
  },
  methods: {
    ...mapActions('referenceValueStore', ['getReferenceValues']),
  },
};
