var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx main-dx-binsinfoform no-shadow"},[_c('f7-block-title',{staticClass:"no-padding"},[_vm._v("\n    "+_vm._s(_vm.$t('BareRootsForm_bare_root_info'))+"\n  ")]),_vm._v(" "),_c('DxForm',{staticClass:"content-binsinfoform"},[(_vm.creating)?[_c('DxSimpleItem',{attrs:{"validation-rules":_vm.validationRules.name,"editor-options":{
          mode: 'text',
          onValueChanged: _vm.bareRootNameChanged
        },"data-field":_vm.$t('DxBinsInfoForm_name_caption')},model:{value:(_vm.currentBareRootName),callback:function ($$v) {_vm.currentBareRootName=$$v},expression:"currentBareRootName"}})]:[_c('DxSimpleItem',{attrs:{"validation-rules":_vm.validationRules.name,"editor-options":{
          value: _vm.currentBareRootName,
          mode: 'text',
          onValueChanged: _vm.bareRootNameChanged
        },"data-field":_vm.$t('DxBinsInfoForm_name_caption')}})],_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('DxBinsInfoForm_date_caption'),"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"form-calendar",attrs:{"id":"datepicker-multiple","type":"datepicker","disabled":"","readonly":"","calendar-params":{
            openIn: 'customModal',
            header: false,
            footer: true,
            dateFormat: 'yyyy-mm-dd'
          },"value":[_vm.currentDate]}})]},proxy:true}])}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"plantingYear","data-field":_vm.$t('DxPointForm_plantingYear_caption'),"editor-type":"dxDateBox","editor-options":{
        value: _vm.getPlantingYear(),
        onValueChanged: _vm.plantingYearChanged,
        maxZoomLevel:'decade',
        minZoomLevel:'decade',
        max: _vm.currentDate,
        zoomLevel: 'decade',
        displayFormat: 'yyyy',
      }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"levelZeroLocation","editor-type":"dxSelectBox","data-field":_vm.$t('DxBinsInfoForm_bins_location_caption'),"validation-rules":_vm.validationRules.levelZeroLocation,"editor-options":{
        dataSource: _vm.usersLevelZeroNames,
        searchEnabled: true,
        searchTimeout: _vm.searchTimeoutOption,
        minSearchLength: _vm.minSearchLengthOption,
        showDataBeforeSearch: _vm.showDataBeforeSearchOption,
        onItemClick: _vm.updateSelectedLevelZeroLocation,
        onValueChanged: _vm.updateSelectedLevelZeroLocation,
        value: _vm.bareRootsLocationName,
      }}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }