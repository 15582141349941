<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('AnalysisGis.title')" />
    <f7-block>
      <f7-row>
        <f7-col width="50">
          <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}Left`] === ''">
            <div class="loadingFalse">
              <div class="preloader" />
            </div>
          </f7-block>
          <f7-block v-else>
            <GISWMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Left`"
              :layer="layerLeft"
              :index="currentIndexes[`${mainMap}Left`]"
              :height="'450px'"
              :center="LeftComparatorCoordinates"
            />
          </f7-block>
        </f7-col>
        <f7-col width="50">
          <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}Right`] === ''">
            <div class="loadingFalse">
              <div class="preloader" />
            </div>
          </f7-block>
          <f7-block v-else>
            <GISWMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Right`"
              :layer="layerRight"
              :index="currentIndexes[`${mainMap}Right`]"
              :height="'450px'"
              :center="RightComparatorCoordinates"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="50">
          <GISLayerOptionsComparator
            :key-id="`${mainMap}Left`"
            :default-index="'NDVI'"
            :dates-list="datesListLeft"
          />
        </f7-col>
        <f7-col width="50">
          <GISLayerOptionsComparator
            :key-id="`${mainMap}Right`"
            :default-index="'NDVI'"
            :dates-list="datesListRight"
          />
        </f7-col>
      </f7-row>

      <f7-row v-if="loadedGIS">
        <f7-col>
          <f7-button
            class="dx-btn-cancel margin-right"
            raised
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goToGisGrid()"
          >
            {{ $t('Button_go_to_back') }}
          </f7-button>
        </f7-col>
        <f7-col />
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';
import GISWMSMap from '../../components/Gis/GISWMSMap/index.vue';
import GISLayerOptionsComparator from '../../components/Gis/LayerOptionsComparator/index.vue';

export default {
  name: 'GisComparatorPage',
  components: {
    navbar,
    GISWMSMap,
    GISLayerOptionsComparator,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'gis',
      datesListLeft: {},
      datesListRight: {},
      layerLeft: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      layerRight: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
    };
  },
  computed: {
    ...mapState('Plantation', ['sectorIdLeftComparator', 'sectorIdRightComparator', 'LeftComparatorCoordinates', 'RightComparatorCoordinates']),
    ...mapState('Gis', ['currentIndexes', 'datesList']),
  },
  methods: {
    resetConfig(key) {
      this.setCurrentIndexes({
        key,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key,
        newCurrentInitIntervalDate: '',
      });
      this.setCurrentEndIntervalDates({
        key,
        newCurrentEndIntervalDate: '',
      });
    },
    async beforein() {
      this.$f7.preloader.show();
      try {
        this.layerLeft.options.layers = `client_efemis:${this.sectorIdLeftComparator}`;
        this.layerRight.options.layers = `client_efemis:${this.sectorIdRightComparator}`;
        await this.fetchDatesLayer(this.layerLeft.options.layers.split(':')[1]);
        this.datesListLeft = this.datesList;
        await this.fetchDatesLayer(this.layerRight.options.layers.split(':')[1]);
        this.datesListRight = this.datesList;
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.loadedGIS = true;
      }
    },
    goToGisGrid() {
      this.$f7.views.main.router.navigate('/gisGrid/', { reloadCurrent: true });
    },
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchDatesLayer']),
  },
};
</script>

<style>

</style>
