<template>
  <DxForm>
    <DxGroupItem>
      <!--Tools-->
      <DxSimpleItem
        v-for="(item, index) in toolsSelected"
        :key="index"
        :data-field="`${item.data.description}`"
        :name="item.data.description"
        :editor-options="{ value: item.count, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    toolsSelected: { type: Array, default: () => [] },
  },
  data() {
    return {
    };
  },
};
</script>
