<template>
  <!-- <div v-if="loaded"> -->
  <div>
    <f7-row>
      <f7-col>
        <DxForm id="bareRootContentForm">
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_level_0')"
              :editor-options="{
                value: bareRootsLocationName,
                disabled: true,
              }"
            />
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_variety')"
              :editor-options="{
                dataSource: bareRootsVarieties,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: updateBareRootsSelectedVariety,
                value: bareRootsSelectedVariety,
              }"
              editor-type="dxSelectBox"
            >
              <DxRequiredRule
                :message="$t('BareRoots_content_form_variety_required')"
              />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_category')"
              :editor-options="{
                dataSource: bareRootsClasses,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                searchEnabled: true,
                onValueChanged: updateBareRootsSelectedClass,
                value: bareRootsSelectedClass
              }"
              editor-type="dxSelectBox"
            >
              <DxRequiredRule
                :message="$t('BareRoots_content_form_category_required')"
              />
            </DxSimpleItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
      <f7-col>
        <DxForm>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_level_last')"
              :editor-options="{
                disabled: true,
                value: bareRootsSelectedLastLevel,
              }"
            />
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_type')"
              :editor-options="{
                dataSource: bareRootsCategories,
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
                onValueChanged: updateBareRootsSelectedCategory,
                searchEnabled: true,
                value: bareRootsSelectedCategory
              }"
              editor-type="dxSelectBox"
            >
              <DxRequiredRule
                :message="$t('BareRoots_content_form_type_required')"
              />
            </DxSimpleItem>
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_plants')"
              name="plants"
              editor-type="dxNumberBox"
              :editor-options="{
                min: 0,
                mode: 'number',
                onValueChanged: updateBareRootsCurrentPlants,
                placeholder: '',
                showClearButton: true,
                showSpinButtons: false,
                step: 1,
                value: bareRootsCurrentPlants,
              }"
            >
              <DxRequiredRule
                :message="$t('BareRoots_content_form_plants_required')"
              />
            </DxSimpleItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <DxForm>
          <DxGroupItem>
            <DxSimpleItem
              :data-field="$t('BareRoots_content_form_note')"
              name="note"
              :editor-options="{
                maxLength: 140,
                onValueChanged: updateBareRootsCurrentNote,
                value: currentNote,
              }"
            />
          </DxGroupItem>
        </DxForm>
        <!-- Nota -->
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
  },
  data() {
    return {
      bareRootsSelectedVariety: '',
      bareRootsSelectedClass: '',
      bareRootsSelectedCategory: '',
      bareRootsCurrentPlants: 0,
      currentNote: '',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
    };
  },
  computed: {
    ...mapState('PlanningManager',
      [
        'actualLocationsSelected',
        'actualLocationsTreeFormated',
      ]),
    ...mapState('greenhouse',
      [
        'bareRootsLocationName',
      ]),
    ...mapGetters('greenhouse',
      [
        'getBareRootToEdit',
        'bareRootsCategories',
        'bareRootsClasses',
        'bareRootsVarieties',
      ]),
    bareRootsSelectedLastLevel() {
      let lastLevelLocationName = '';
      if (this.actualLocationsSelected) {
        const lastLevelLocation = this.actualLocationsTreeFormated.filter(
          (location) => location.bd_id === this.actualLocationsSelected[0],
        );
        if (lastLevelLocation.length > 0) {
          lastLevelLocationName = lastLevelLocation[0].name;
          return lastLevelLocationName;
        }
      }
      return lastLevelLocationName;
    },
  },
  created() {
    if (this.getBareRootToEdit.id !== '') {
      this.bareRootsSelectedVariety = this.getBareRootToEdit.variety;
      this.bareRootsSelectedClass = this.getBareRootToEdit.class_type;
      this.bareRootsSelectedCategory = this.getBareRootToEdit.category;
      this.bareRootsCurrentPlants = this.getBareRootToEdit.count;
      this.currentNote = this.getBareRootToEdit.comments;
    }
  },
  // created() {
  //   // TODO: get and update data() variables with available fetched classes, varieties.
  //   // TODO: if editing, update data() variables with prop bareRootContent.
  // },
  methods: {
    ...mapActions('greenhouse',
      [
        'updateBareRootsClass',
        'updateBareRootsVariety',
        'updateBareRootsCategory',
        'updateBareRootsPlants',
        'updateBareRootsNote',
        'updateBareRootsLastLevel',
      ]),
    updateBareRootsSelectedVariety(e) {
      this.bareRootsSelectedVariety = e.value;
      this.updateBareRootsVariety(e.value);
    },
    updateBareRootsSelectedClass(e) {
      this.bareRootsSelectedClass = e.value;
      this.updateBareRootsClass(e.value);
    },
    updateBareRootsSelectedCategory(e) {
      this.bareRootsSelectedCategory = e.value;
      this.updateBareRootsCategory(e.value);
    },
    updateBareRootsCurrentPlants(e) {
      this.bareRootsCurrentPlants = e.value;
      this.updateBareRootsPlants(e.value);
    },
    updateBareRootsCurrentNote(e) {
      this.updateBareRootsCurrentNote = e.value;
      this.updateBareRootsNote(e.value);
    },
    updateBareRootsCurrentLastLevel(e) {
      this.updateBareRootsLastLevel(e.value);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
