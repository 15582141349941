import HomePage from '../pages/home.vue';
// Accounts
import Login from '../pages/accounts/loginPage.vue';
import RecoveryPasswordPage from '../pages/accounts/recoveryPasswordPage.vue';
import ChangePasswordPage from '../pages/accounts/changePasswordPage.vue';
import Register from '../pages/accounts/register.vue';
import Logout from '../pages/accounts/logout.vue';

// DevExtreme
import TabPanel from '../pages/tabPanel.vue';

// Others
import NotFoundPage from '../pages/404.vue';

// TERRITORIAL UNITS
import TerritorialUnit from '../pages/territorialUnit/territorialUnitPage.vue';

// CONFIGURATION

import Products from '../pages/configuration/products/products.vue';
import ViewProductDetail from '../pages/configuration/products/viewProductDetail.vue';
import CreateProduct from '../pages/configuration/products/createProduct.vue';
import EditProduct from '../pages/configuration/products/editProduct.vue';
import Varieties from '../pages/configuration/varieties/varieties.vue';
import ViewVarietyDetail from '../pages/configuration/varieties/viewVarietyDetail.vue';
import Observations from '../pages/configuration/observations/observations.vue';
import ViewObservationTemplate from '../pages/configuration/observations/viewObservationTemplate.vue';
import CreateObservationTemplate from '../pages/configuration/observations/createObservationTemplate.vue';
import EditObservationTemplate from '../pages/configuration/observations/editObservationTemplate.vue';
import Tasks from '../pages/configuration/tasks/tasks.vue';
import ViewTaskDetail from '../pages/configuration/tasks/viewTaskDetail.vue';
import CreateTaskDefinition from '../pages/configuration/tasks/createTaskDefinition.vue';
import EditTaskDefinition from '../pages/configuration/tasks/editTaskDefinition.vue';
import Employees from '../pages/configuration/employees/employees.vue';
import CreateEmployee from '../pages/configuration/employees/createEmployee.vue';
import MachineryDatabase from '../pages/configuration/machinery/machineryDatabase.vue';
import CreateMachinery from '../pages/configuration/machinery/createMachinery.vue';
import EditMachinery from '../pages/configuration/machinery/editMachinery.vue';
import VarietyDatabase from '../pages/configuration/variety/DxVarietyDatabase.vue';
import ListTaskAgruped from '../pages/configuration/activityTasks/listTaskAgruped.vue';
import ToolsDatabase from '../pages/configuration/dataTools/toolsDatabase.vue';
import CreateTool from '../pages/configuration/dataTools/createTool.vue';
import EditTool from '../pages/configuration/dataTools/editTool.vue';
import CostsDatabase from '../pages/configuration/costs/costsDatabase.vue';
import CreateCosts from '../pages/configuration/costs/createCost.vue';
import EditCosts from '../pages/configuration/costs/editCost.vue';
import Activities from '../pages/configuration/activities/activities.vue';
import ViewActivityDetail from '../pages/configuration/activities/viewActivityDetail.vue';
import CreateActivity from '../pages/configuration/activities/createActivity.vue';
import EditActivity from '../pages/configuration/activities/editActivity.vue';
import PrescriptionManager from '../pages/configuration/prescriptionManager/prescriptionManager.vue';
import CreatePrescription from '../pages/configuration/prescriptionManager/createPrescription.vue';
import EditPrescription from '../pages/configuration/prescriptionManager/editPrescription.vue';

// UTILITIES
import Clients from '../pages/utilities/clients/clients.vue';
import ViewClient from '../pages/utilities/clients/viewClient.vue';
import Slices from '../pages/utilities/slices/slices.vue';
import Plans from '../pages/utilities/plans/plans.vue';
import ViewPlanDetail from '../pages/utilities/plans/viewPlanDetail.vue';
import Converter from '../pages/converter/index.vue';

// SISTEMA PARCELARIO

import SystemParcelClients from '../pages/systemParcelClient/systemParcelClientsPage.vue';
import SystemParcelViewClients from '../pages/systemParcelClient/systemParcelViewClientsPage.vue';
import RegisterLocation from '../pages/systemParcelLocationPage/registerLocation.vue';
import EditLocation from '../pages/systemParcelLocationPage/editLocation.vue';
import Campania from '../pages/campaña/campania.vue';
import RegisterCampania from '../pages/campaña/registerCampania.vue';
import ViewCampania from '../pages/campaña/viewCampania.vue';
import ViewLocation from '../pages/systemParcelLocationPage/viewLocation.vue';
import RegisterParcelStep1 from '../pages/parcel/registerParcelStep1.vue';
import RegisterParcelStep2 from '../pages/parcel/registerParcelStep2.vue';
import RegisterPlantationStep2 from '../pages/plantation/registerPlantation/registerPlantationStep2/registerPlantationStep2.vue';
import ViewPlantation from '../pages/plantation/viewPlantation/index.vue';
import RegisterPlantationStep1 from '../pages/plantation/registerPlantation/registerPlantationStep1/registerPlantationStep1.vue';
import ViewParcel from '../pages/parcel/viewParcel.vue';
import RegisterSectorStep1Page from '../pages/sector/registerSectorStep1Page.vue';
import RegisterSectorStep2Page from '../pages/sector/registerSectorStep2Page.vue';
import ViewSector from '../pages/sector/viewSector.vue';
// Análisis
import Weather from '../pages/analysis/weather.vue';
import TreatmentAnalysis from '../pages/analysis/treatment.vue';
import NutritionAnalysis from '../pages/analysis/nutrition.vue';
import IrrigationAnalysis from '../pages/analysis/irrigation.vue';
import GisGrid from '../pages/analysis/gisGrid.vue';
import GisComparator from '../pages/analysis/gisComparator.vue';

// VADEMECUM
import Vademecum from '../pages/utilities/vademecum/vademecum.vue';
import ViewNutritionalProductDetail from '../pages/utilities/vademecum/viewNutritionalProductDetail.vue';
import phytosanitaryProducts from '../components/utilities/vademecum/phytosanitaryProducts/index.vue';
import nutritionalProducts from '../components/utilities/vademecum/nutritionalProducts/index.vue';

import phytosanitaryProductsProduct from '../components/utilities/vademecum/phytosanitaryProducts/phytosanitaryProductsProduct/index.vue';
import phytosanitaryProductsPlague from '../components/utilities/vademecum/phytosanitaryProducts/phytosanitaryProductsPlague/index.vue';

import ViewPhytosanitaryProductPlagueDetail from '../pages/utilities/vademecum/viewPhytosanitaryProductPlagueDetail.vue';
import ViewPhytosanitaryProductProductDetail from '../pages/utilities/vademecum/viewPhytosanitaryProductProductDetail.vue';

// Buscador
import SearchEngine from '../pages/searchEngine/serachEnginePage.vue';

// SEARCHER
import Searcher from '../pages/searcher/searcher.vue';
import TabHome from '../components/searcher/home/index.vue';
import TabObservations from '../components/searcher/observations/observationsDatabase/index.vue';
import TabNutrition from '../components/searcher/nutrition/index.vue';
import TabTreatment from '../components/searcher/treatment/index.vue';
import TabIrrigation from '../components/searcher/irrigation/index.vue';
import TabTasks from '../components/searcher/tasks/index.vue';
import TabweatherGraph from '../components/searcher/weatherGraph/index.vue';
import TabDetailPlantation from '../components/searcher/detail/index.vue';
import TabGIS from '../components/searcher/gis/index.vue';
import ViewNutritionReport from '../pages/wizardNutrition/viewNutritionReport.vue';
import ViewTreatmentReport from '../pages/wizardTreatment/viewTreatmentReport.vue';
import SubTabGisWMS from '../components/searcher/gis/gisWMS/index.vue';
import SubTabGisTimeDimension from '../components/searcher/gis/gisTimeDimension/index.vue';
import SubTabGisScanning from '../components/searcher/gis/gisScanning/index.vue';
import ViewCopyNutritionPlan from '../pages/wizardNutrition/nutrition-copy-nutrition-plan/index.vue';
import ViewCopyTreatmentPlan from '../pages/wizardTreatment/treatment-copy-treatment-plan/index.vue';

// SEARCHER OBSERVATIONS
import CreateObservation from '../pages/searcher/observations/createObservation.vue';
import EditObservation from '../pages/searcher/observations/editObservation.vue';
import ViewObservation from '../pages/searcher/observations/viewObservation.vue';
import CreateObservationPoint from '../pages/searcher/observations/createObservationPoint.vue';

// NUTRITION
import ViewLaboratoryAnalaysis from '../pages/laboratoryNutrition/viewLaboratoryAnalaysis.vue';
import NutritionPanel from '../pages/wizardNutrition/nutrition-panel/index.vue';
import NutritionDetail from '../pages/wizardNutrition/nutrition-detail/index.vue';
import NutritionStepOneComponent from '../pages/wizardNutrition/nutrition-step-one/index.vue';
import NutritionStepTwoComponent from '../pages/wizardNutrition/nutrition-step-two/index.vue';
import NutritionStepThreeComponent from '../pages/wizardNutrition/nutrition-step-three/index.vue';
import NutritionStepFourComponent from '../pages/wizardNutrition/nutrition-step-four/index.vue';
import NutritionStepFiveComponent from '../pages/wizardNutrition/nutrition-step-five/index.vue';

// Treatment
import TreatmentStepOneComponent from '../pages/wizardTreatment/treatment-step-one/index.vue';
import TreatmentStepTwoComponent from '../pages/wizardTreatment/treatment-step-two/index.vue';
import TreatmentStepThreeComponent from '../pages/wizardTreatment/treatment-step-three/index.vue';
import TreatmentCreateOrderComponent from '../pages/wizardTreatment/treatment-create-order/index.vue';
import TreatmentDetailOrderComponent from '../pages/wizardTreatment/treatment-detail-order/index.vue';
import TreatmentEditOrderComponent from '../pages/wizardTreatment/treatment-edit-order/index.vue';
import TreatmentDetailPlanComponent from '../pages/wizardTreatment/treatment-detail-plan/index.vue';

// POSTWORK
import PostWork from '../pages/postWork/postWork.vue';
import createPostWork from '../pages/postWork/createPostWork.vue';
import ViewPostWork from '../pages/postWork/viewPostWork.vue';

// NURSERY
import BinsFormPage from '../pages/greenhouse/binsFormPage.vue';
import createBatch from '../pages/greenhouse/createBatch.vue';
import editBatch from '../pages/greenhouse/editBatch.vue';
import batches from '../pages/greenhouse/batches.vue';
import bins from '../pages/greenhouse/bins.vue';
import binsReclassification from '../pages/greenhouse/binsReclassification.vue';
import BareRootsFormPage from '../pages/greenhouse/bareRootsFormPage.vue';
import BareRoots from '../pages/greenhouse/bareRoots.vue';

// Reporting
import Cockpit from '../pages/reporting/cockpit.vue';
import EffortActivity from '../pages/reporting/effortActivity.vue';
import EffortTask from '../pages/reporting/effortTask.vue';
import WeatherFarmCockpitPage from '../pages/reporting/weatherFarmCockpitPage.vue';
import ReportingWorker from '../pages/reporting/reportingWorker.vue';
import ReportingBpas from '../pages/reporting/reportingBpas.vue';
import ReportingMachinery from '../pages/reporting/reportingMachinery.vue';
import TimeWorked from '../pages/reporting/timeWorked.vue';
import EmployeeEffectiveness from '../pages/reporting/reportingEmployeeEffectiveness.vue';
import ReportingWorkOrders from '../pages/reporting/reportingWorkOrders.vue';
import ReportingListTaskAgruped from '../pages/reporting/listTaskAgruped.vue';
import PlannedVsReal from '../pages/reporting/plannedVsReal.vue';
import Irrigation from '../pages/reporting/irrigation.vue';
import NurseryReport from '../pages/reporting/nursery.vue';

// BUDGET PLANNING
import MonthlyPlanning from '../pages/monthly/monthlyPlanning.vue';
import CreateMonthlyPlanning from '../pages/monthly/createMonthlyPlanning.vue';
import EditMonthlyPlanning from '../pages/monthly/editMonthlyPlanning.vue';
import AnnualBudgets from '../pages/monthly/annualBudgets.vue';
import CreateAnnualBudget from '../pages/monthly/createAnnualBudget.vue';
import CloneAnnualBudget from '../pages/monthly/cloneAnnualBudget.vue';

// PLANNING
import DailyPlanning from '../pages/dailyPlanning.vue';
import CreatePlanning from '../pages/createPlanning.vue';
import EditPlanning from '../pages/editPlanning.vue';

// WORKORDER
import CreateOrder from '../pages/createOrder.vue';
import EditOrder from '../pages/editOrder.vue';
import WorkOrders from '../pages/workOrders.vue';

// HARVEST
import ReportingHarvest from '../pages/reporting/reportingHarvest.vue';
import HarvestActivitiesReport from '../pages/reporting/harvestActivitiesReportPage.vue';
import Harvesting from '../pages/harvesting.vue';

const routes = [
  // Default
  {
    path: '/',
    component: SearchEngine,
  },
  // Accounts
  {
    path: '/accounts/loginPage/',
    component: Login,
  },
  {
    path: '/recoveryPasswordPage/',
    component: RecoveryPasswordPage,
  },
  {
    path: '/changePasswordPage/',
    component: ChangePasswordPage,
  },
  {
    path: '/register/',
    component: Register,
  },
  {
    path: '/accounts/logout/',
    component: Logout,
  },
  {
    path: '/territorialUnit/',
    component: TerritorialUnit,
  },
  // Others
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/tabPanel/',
    component: TabPanel,
  },
  // CONFIGURATION
  {
    path: '/products/',
    component: Products,
  },
  {
    path: '/viewProductDetail/',
    component: ViewProductDetail,
  },
  {
    path: '/createProduct/',
    component: CreateProduct,
  },
  {
    path: '/editProduct/',
    component: EditProduct,
  },
  {
    path: '/varieties/',
    component: Varieties,
  },
  {
    path: '/viewVarietyDetail/',
    component: ViewVarietyDetail,
  },
  {
    path: '/observations/',
    component: Observations,
  },
  {
    path: '/viewObservationTemplate/',
    component: ViewObservationTemplate,
  },
  {
    path: '/createObservationTemplate/',
    component: CreateObservationTemplate,
  },
  {
    path: '/editObservationTemplate/',
    component: EditObservationTemplate,
  },
  {
    path: '/tasks/',
    component: Tasks,
  },
  {
    path: '/viewTaskDetail/',
    component: ViewTaskDetail,
  },
  {
    path: '/createTaskDefinition/',
    component: CreateTaskDefinition,
  },
  {
    path: '/editTaskDefinition/',
    component: EditTaskDefinition,
  },
  {
    path: '/employees/',
    component: Employees,
  },
  {
    path: '/createEmployee/',
    component: CreateEmployee,
  },
  {
    path: '/machineryDatabase/',
    component: MachineryDatabase,
  },
  {
    path: '/createMachinery/',
    component: CreateMachinery,
  },
  {
    path: '/editMachinery/',
    component: EditMachinery,
  },
  {
    path: '/varietyDatabase/',
    component: VarietyDatabase,
  },
  {
    path: '/reportingListTaskAgruped/',
    component: ListTaskAgruped,
  },
  {
    path: '/toolsDatabase/',
    component: ToolsDatabase,
  },
  {
    path: '/createTool/',
    component: CreateTool,
  },
  {
    path: '/editTool/',
    component: EditTool,
  },
  {
    path: '/costsDatabase/',
    component: CostsDatabase,
  },
  {
    path: '/createcosts/',
    component: CreateCosts,
  },
  {
    path: '/editcosts/',
    component: EditCosts,
  },
  {
    path: '/activities/',
    component: Activities,
  },
  {
    path: '/viewActivityDetail/',
    component: ViewActivityDetail,
  },
  {
    path: '/createActivity/',
    component: CreateActivity,
  },
  {
    path: '/editActivity/',
    component: EditActivity,
  },
  {
    path: '/presciptionManager/',
    component: PrescriptionManager,
  },
  {
    path: '/createPrescription/',
    component: CreatePrescription,
  },
  {
    path: '/editPrescription/',
    component: EditPrescription,
  },
  // UTILITIES
  {
    path: '/clients/',
    component: Clients,
  },
  {
    path: '/viewClient/:pathBack/',
    component: ViewClient,
  },
  {
    path: '/campania/',
    component: Campania,
  },
  {
    path: '/registerCampania/',
    component: RegisterCampania,
  },
  {
    path: '/registerParcelStep1/',
    component: RegisterParcelStep1,
  },
  {
    path: '/registerParcelStep2/',
    component: RegisterParcelStep2,
  },
  {
    path: '/slices/',
    component: Slices,
  },
  {
    path: '/plans/',
    component: Plans,
  },
  {
    path: '/viewPlanDetail/',
    component: ViewPlanDetail,
  },

  // ANALISIS
  {
    path: '/weather/',
    component: Weather,
  },
  {
    path: '/treatment/',
    component: TreatmentAnalysis,
  },
  {
    path: '/nutrition/',
    component: NutritionAnalysis,
  },
  {
    path: '/irrigation/',
    component: IrrigationAnalysis,
  },
  {
    path: '/gisGrid/',
    component: GisGrid,
  },
  {
    path: '/gisComparator/',
    component: GisComparator,
  },
  // VADEMECUM
  {
    path: '/vademecum/',
    component: Vademecum,
    tabs: [
      {
        path: '/phytosanitary/',
        id: 'tab-phytosanitary',
        component: phytosanitaryProducts,
      },
      {
        path: '/plague',
        id: 'tab-phytosanitary-plague',
        component: phytosanitaryProductsPlague,
      },
      {
        path: '/phytosanitary/product/',
        id: 'tab-phytosanitary-product',
        component: phytosanitaryProductsProduct,
      },
      {
        path: '/nutritional/',
        id: 'tab-nutritional',
        component: nutritionalProducts,
      },
    ],
  },
  // SISTEMA PARCELARIO
  {
    path: '/systemParcelClients/',
    component: SystemParcelClients,
  },
  {
    path: '/systemParcelViewClientsPage/',
    component: SystemParcelViewClients,
  },
  {
    path: '/registerLocation/',
    component: RegisterLocation,
  },
  {
    path: '/editLocation/',
    component: EditLocation,
  },
  {
    path: '/viewCampania/',
    component: ViewCampania,
  },
  {
    path: '/viewLocation/',
    component: ViewLocation,
  },
  {
    path: '/registerPlantationStep2/',
    component: RegisterPlantationStep2,
  },
  {
    path: '/viewPlantation/',
    component: ViewPlantation,
  },
  {
    path: '/registerPlantationStep1/',
    component: RegisterPlantationStep1,
  },
  {
    path: '/viewParcel/',
    component: ViewParcel,
  },
  {
    path: '/registerSectorStep1Page/',
    component: RegisterSectorStep1Page,
  },
  {
    path: '/registerSectorStep2Page/',
    component: RegisterSectorStep2Page,
  },
  {
    path: '/viewSector/',
    component: ViewSector,
  },
  // Análisis
  {
    path: '/viewNutritionalProductDetail/',
    component: ViewNutritionalProductDetail,
  },
  {
    path: '/viewPhytosanitaryProductPlagueDetail/',
    component: ViewPhytosanitaryProductPlagueDetail,
  },
  {
    path: '/viewPhytosanitaryProductProductDetail/',
    component: ViewPhytosanitaryProductProductDetail,
  },
  // Converter
  {
    path: '/converter/',
    component: Converter,
  },
  // Nutrition Panel
  {
    path: '/utilities/nutrition/nutrition-panel',
    component: NutritionPanel,
  },
  // Nutrition Detail
  {
    path: '/utilities/nutrition/nutrition-detail/:id',
    name: 'utilities_nutrition_nutrition-detail',
    component: NutritionDetail,
  },
  // Nutrition Step One
  {
    path: '/utilities/nutrition/nutrition-step-one',
    component: NutritionStepOneComponent,
  },
  // Nutrition Step One
  {
    name: 'utilities_nutrition_nutrition-step-one',
    path: '/utilities/nutrition/nutrition-step-one/:id',
    component: NutritionStepOneComponent,
  },
  // Nutrition Step Two
  {
    path: '/utilities/nutrition/nutrition-step-two',
    component: NutritionStepTwoComponent,
  },
  // Nutrition Step Three
  {
    path: '/utilities/nutrition/nutrition-step-three',
    component: NutritionStepThreeComponent,
  },
  // Nutrition Step Four
  {
    path: '/utilities/nutrition/nutrition-step-four',
    component: NutritionStepFourComponent,
  },
  // Nutrition Step Five
  {
    path: '/utilities/nutrition/nutrition-step-five',
    component: NutritionStepFiveComponent,
  },
  // Treatment Step One
  {
    name: 'utilities_treatment_treatment-step-one',
    path: '/utilities/treatment/treatment-step-one/:id',
    component: TreatmentStepOneComponent,
  },
  // Treatment Step One
  {
    path: '/utilities/treatment/treatment-step-one',
    component: TreatmentStepOneComponent,
  },
  // Treatment Step Two
  {
    path: '/utilities/treatment/treatment-step-two',
    component: TreatmentStepTwoComponent,
  },
  // Treatment Step Three
  {
    path: '/utilities/treatment/treatment-step-three',
    component: TreatmentStepThreeComponent,
  },
  // Treatment Create Order
  {
    path: '/utilities/treatment/treatment-create-order',
    component: TreatmentCreateOrderComponent,
  },
  // Treatment Detail Order
  {
    name: 'utilities_treatment_treatment-detail-order',
    path: '/utilities/treatment/treatment-detail-order/:id',
    component: TreatmentDetailOrderComponent,
  },
  {
    name: 'utilities_treatment_treatment-edit-order',
    path: '/utilities/treatment/treatment-edit-order/:id',
    component: TreatmentEditOrderComponent,
  },
  // Treatment Detail Plan
  {
    name: 'utilities_treatment_treatment-detail-plan',
    path: '/utilities/treatment/treatment-detail-plan/:id',
    component: TreatmentDetailPlanComponent,
  },
  // Search Engine
  {
    path: '/searchEngine/',
    component: SearchEngine,
  },
  // SEARCHER
  {
    path: '/searcher/',
    component: Searcher,
    tabs: [
      {
        path: '/detail/',
        id: 'tab-detail',
        component: TabDetailPlantation,
      },
      {
        path: '/home/',
        id: 'tab-home',
        component: TabHome,
      },
      {
        path: '/weatherGraph/',
        id: 'tab-weatherGraph',
        component: TabweatherGraph,
      },
      {
        path: '/nutrition/',
        id: 'tab-nutrition',
        component: TabNutrition,
      },
      {
        path: '/treatment/',
        id: 'tab-treatment',
        component: TabTreatment,
      },
      {
        path: '/irrigation/',
        id: 'tab-irrigation',
        component: TabIrrigation,
      },
      {
        path: '/observations/',
        id: 'tab-observations',
        component: TabObservations,
      },
      {
        path: '/tasks/',
        id: 'tab-tasks',
        component: TabTasks,
      },
      {
        path: '/gissearcher/',
        id: 'tab-gis',
        component: TabGIS,
      },
      {
        path: '/gisWMS/',
        id: 'tab-gisWMS',
        component: SubTabGisWMS,
      },
      {
        path: '/gisTimeDimension/',
        id: 'tab-gisTimeDimension',
        component: SubTabGisTimeDimension,
      },
      {
        path: '/gisScanning/',
        id: 'tab-gisScanning',
        component: SubTabGisScanning,
      },
    ],
  },
  // SEARCHER OBSERVATIONS
  {
    path: '/createObservation/',
    component: CreateObservation,
  },
  {
    path: '/editObservation/',
    component: EditObservation,
  },
  {
    path: '/viewObservation/',
    component: ViewObservation,
  },
  {
    path: '/createObservationPoint/',
    component: CreateObservationPoint,
  },
  // NUTRITION
  {
    path: '/viewLaboratoryAnalaysis/',
    component: ViewLaboratoryAnalaysis,
  },
  {
    path: '/viewNutritionReport/',
    component: ViewNutritionReport,
  },
  {
    path: '/viewTreatmentReport/',
    component: ViewTreatmentReport,
  },
  {
    path: '/viewCopyNutritionPlan',
    component: ViewCopyNutritionPlan,
  },
  {
    path: '/viewCopyTreatmentPlan',
    component: ViewCopyTreatmentPlan,
  },
  // POSTWORK
  {
    path: '/postWork/',
    component: PostWork,
  },
  {
    path: '/viewPostWork/',
    component: ViewPostWork,
  },
  {
    path: '/createPostWork/',
    component: createPostWork,
  },
  // NURSERY
  {
    path: '/batches/create/',
    component: createBatch,
  },
  {
    path: '/batches/edit/',
    component: editBatch,
  },
  {
    path: '/batches/',
    component: batches,
  },
  {
    path: '/bins/',
    component: bins,
  },
  {
    path: '/stock-management/reclassification/',
    component: binsReclassification,
  },
  {
    path: '/bins/create/',
    component: BinsFormPage,
  },
  {
    path: '/bins/edit/',
    component: BinsFormPage,
  },
  {
    path: '/bins/reclassify/',
    component: BinsFormPage,
  },
  {
    path: '/bins/move/',
    component: BinsFormPage,
  },
  {
    path: '/bare-roots/',
    component: BareRoots,
  },
  {
    path: '/bare-roots/create/',
    component: BareRootsFormPage,
  },
  {
    path: '/bare-roots/edit/',
    component: BareRootsFormPage,
  },
  {
    path: '/nurseryReport/',
    component: NurseryReport,
  },
  // Reporting
  {
    path: '/cockpit/',
    component: Cockpit,
  },
  {
    path: '/effortActivity/',
    component: EffortActivity,
  },
  {
    path: '/effortTask/',
    component: EffortTask,
  },
  {
    path: '/weatherFarmCockpit/',
    component: WeatherFarmCockpitPage,
  },
  {
    path: '/reportingWorker/',
    component: ReportingWorker,
  },
  {
    path: '/reportingBpas/',
    component: ReportingBpas,
  },
  {
    path: '/reportingMachinery/',
    component: ReportingMachinery,
  },
  {
    path: '/reportingHarvestByVariety/',
    component: ReportingHarvest,
  },
  {
    path: '/reportingHarvestByDays/',
    component: ReportingHarvest,
  },
  {
    path: '/timeWorked/',
    component: TimeWorked,
  },
  {
    path: '/employeeEffectiveness/',
    component: EmployeeEffectiveness,
  },
  {
    path: '/reportingWorkOrders/',
    component: ReportingWorkOrders,
  },
  {
    path: '/reportingListTaskAgruped/',
    component: ReportingListTaskAgruped,
  },
  {
    path: '/plannedVsReal/',
    component: PlannedVsReal,
  },
  {
    path: '/irrigation/',
    component: Irrigation,
  },
  {
    path: '/nurseryReport/',
    component: NurseryReport,
  },
  // BUDGET PLANNING
  {
    path: '/monthlyPlanning/',
    component: MonthlyPlanning,
  },
  {
    path: '/createMonthly/',
    component: CreateMonthlyPlanning,
  },
  {
    path: '/editMonthly/',
    component: EditMonthlyPlanning,
  },
  {
    path: '/annualBudgets/',
    component: AnnualBudgets,
  },
  {
    path: '/createAnnualBudget/',
    component: CreateAnnualBudget,
  },
  {
    path: '/cloneAnnualBudget/',
    component: CloneAnnualBudget,
  },
  // PLANNING
  {
    path: '/dailyPlanning/',
    component: DailyPlanning,
  },
  {
    path: '/createPlanning/',
    component: CreatePlanning,
  },
  {
    path: '/editPlanning/',
    component: EditPlanning,
  },
  // WORKORDER
  {
    path: '/workOrders/',
    component: WorkOrders,
  },
  {
    path: '/createOrder/',
    component: CreateOrder,
  },
  {
    path: '/editOrder/:pathBack/',
    component: EditOrder,
  },
  // HARVEST
  {
    path: '/reportingHarvest/',
    component: ReportingHarvest,
  },
  {
    path: '/harvestActivitiesReport/',
    component: HarvestActivitiesReport,
  },
  {
    path: '/harvesting/',
    component: Harvesting,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
