import navbar from '@/components/NavBar.vue';
import treatmentDetailPlanComponent from '@/components/wizardTreatment/treatmentDetailPlan/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'treatment-detail-plan',
  components: {
    navbar,
    treatmentDetailPlanComponent,
  },
  props: ['id'],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};
