import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '../../../components/wizardNutrition/stepper-navigation/index.vue';
import ReferenceValue from '../../../components/wizardNutrition/referenceValue/index.vue';
import NutritionalNeeds from '../../../components/wizardNutrition/nutritionalNeeds/index.vue';
import { fertilizationService } from '../../../services/fertilizationService';

export default {
  name: 'nutrition-step-one',
  components: {
    Stepper,
    StepperNavigation,
    navbar,
    ReferenceValue,
    NutritionalNeeds,
    DxLoadPanel,
  },
  props: ['id'],
  data() {
    return {
      fertilization: {},
      loaded: false,
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['nutritionSteps']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async mounted() {
    this.loaded = false;

    if (this.id) {
      const response = await fertilizationService
        .getById(this.id, this.currentPlantation.companyId);
      await this.updateEnableWatchers(false);
      await this.updateWizardData(response);
      await this.updateEnableWatchers(true);
    }
    this.setStep(0);
    if (!this.$f7router.previousRoute.path.startsWith('/utilities/nutrition/nutrition')) {
      this.$f7router.on('routeChange', this.resetValues);
    }

    this.loaded = true;
  },
  methods: {
    resetValues(newRoute) {
      if (!newRoute.path.startsWith('/utilities/nutrition/nutrition')) {
        this.$f7router.off('routeChange', this.resetValues);
      }
    },
    ...mapActions('nutritionStepper', ['updateClasses', 'setStep', 'resetWizard', 'updateWizardData', 'updateEnableWatchers']),
  },
};
