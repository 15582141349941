<template>
  <div>
    <f7-block v-if="!loaded">
      <div class="loadingFalse">
        <div class="preloader" />
      </div>
    </f7-block>
    <DxTabPanel
      class="backgroundColor"
    >
      <DxItem
        name="DetailTabs_machinary_title"
        :title="$t('DetailTabs_machinary_title')"
        template="machinary-tab"
      />
      <DxDetailTabMachinary
        slot="machinary-tab"
        :data="currentTaskFormatted.machinery"
        type-tab="machinary"
      />
      <DxItem
        name="DetailTabs_driver_title"
        :title="$t('DetailTabs_driver_title')"
        template="drivers-tab"
      />
      <DxDetailTabDriver
        slot="drivers-tab"
        :data="currentTaskFormatted.drivers"
        type-tab="drivers"
      />
      <!-- Attachments -->
      <DxItem
        name="DetailTabs_attachment_title"
        :title="$t('DetailTabs_attachment_title')"
        template="Attachments-tab"
      />
      <DxDetailTabAttachment
        slot="Attachments-tab"
        :attachments-selected="currentTaskFormatted.attachments"
        type-tab="Attachment"
      />
      <!-- tools -->
      <DxItem
        name="DetailTabs_tools_title"
        :title="$t('DetailTabs_tools_title')"
        template="tools-tab"
      />
      <DxDetailTab
        slot="tools-tab"
        :tools-selected="currentTaskFormatted.tools"
        type-tab="tools"
      />
      <!-- Products -->
      <DxItem
        name="DetailTabs_products_title"
        :title="$t('DetailTabs_products_title')"
        template="products-tab"
      />
      <DxDetailTabProduct
        slot="products-tab"
        :products-selected="currentTaskFormatted"
        type-tab="products"
      />
      <DxItem
        name="DetailTabs_workers_title"
        :title="$t('DetailTabs_workers_title')"
        template="workers-tab"
      />
      <DxDetailTabWorkers
        slot="workers-tab"
        :workers-selected="currentTaskFormatted.workers"
        type-tab="workers"
      />
      <DxItem
        name="DetailTabs_locations_title"
        :title="$t('DetailTabs_locations_title')"
        template="locations-tab"
      />
      <DxDetailTabLocations
        slot="locations-tab"
        :locations="currentTaskFormatted.location"
        :locations-tree="locationsTree"
        type-tab="locations"
      />
      <DxItem
        name="DetailTabs_nursery_title"
        title="Nursery"
        template="nursery-tab"
      />
      <DxContentBin
        slot="nursery-tab"
        :nursery="currentTaskFormatted.nursery"
        type-tab="nursery"
      />
    </DxTabPanel>
  </div>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import DxDetailTabMachinary from './DxDetailTabMachinary.vue';
import DxDetailTabDriver from './DxDetailTabDriver.vue';
import DxDetailTabLocations from './DxDetailTabLocations.vue';
import DxDetailTabWorkers from './DxDetailTabWorkers.vue';
import DxDetailTabAttachment from './DxDetailTabAttachment.vue';
import DxContentBin from '../greenhouse/DxContentBin.vue';
import DxDetailTabProduct from './DxDetailTabProduct.vue';
import DxDetailTab from './DxDetailTab.vue';
import Api from '../../services/Api';

export default {
  components: {
    DxTabPanel, DxItem, DxDetailTabMachinary, DxDetailTabLocations, DxDetailTabWorkers, DxDetailTab, DxDetailTabAttachment, DxDetailTabProduct, DxContentBin, DxDetailTabDriver,
  },
  props: {
    currentTaskId: {
      type: String,
      default: '',
    },
    locationsTree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      dataDrivers: [],
      currentTaskFormatted: {},
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      const xhr = await Api.getTaskByID(this.currentTaskId);
      const currentTask = JSON.parse(xhr.response);
      this.currentTaskFormatted = {
        Planner: currentTask.planner,
        machinery: this.formattingMachinery(currentTask.items_drivers),
        drivers: this.formattingDrivers(currentTask.items_drivers),
        attachments: currentTask.attachments_vehicles,
        tools: currentTask.tools,
        workers: currentTask.workers,
        location: currentTask.location,
        nursery: currentTask.nursery,
      };
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    formattingMachinery(itemsDrivers) {
      const dataMachine = [];
      const typeMachinary = {};
      for (const itemDriver of itemsDrivers) {
        if (typeof typeMachinary[`${itemDriver.item_data.subfamily}`] === 'undefined') {
          typeMachinary[`${itemDriver.item_data.subfamily}`] = 1;
        } else {
          typeMachinary[`${itemDriver.item_data.subfamily}`] += 1;
        }
      }
      for (const type in typeMachinary) {
        if (Object.hasOwnProperty.call(typeMachinary, type)) {
          const count = typeMachinary[type];
          dataMachine.push({ type, count });
        }
      }
      return dataMachine;
    },
    /* formattingMachinery(items) {
      const machineryFormatted = [];
      for (const item of items) {
        machineryFormatted.push({
          count: item.count,
          type: item.type,
        });
      }
      return machineryFormatted;
    }, */
    formattingDrivers(drivers) {
      const driversFormatted = [];
      for (const driver of drivers) {
        driversFormatted.push({
          vehicle: `${driver.item_data.description} ${driver.item_data.code}`,
          driver: `${driver.driver_data.name} ${driver.driver_data.surname}`,
        });
      }
      return driversFormatted;
    },
  },
};
</script>
<style scoped>
.backgroundColor{
  background-color:white;
}
</style>
