import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'nutritional-needs',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxNumberBox,
  },
  props: [],
  data() {
    return {
      columns: [
        this.$t('nutritionalNeeds.productionInput'),
        this.$t('nutritionalNeeds.n'),
        this.$t('nutritionalNeeds.p2o5'),
        this.$t('nutritionalNeeds.k2o'),
        this.$t('nutritionalNeeds.cao'),
        this.$t('nutritionalNeeds.mgo'),
        this.$t('nutritionalNeeds.so3'),
      ],
      currentNutritionalNeeds: [],
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['stepData']),
    ...mapState('referenceValueStore', ['referenceValues', 'nutritionalNeeds']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  async created() {
    this.$f7.preloader.show();
    await this.initData();
    this.currentNutritionalNeeds.push(this.stepData.nutritionalNeeds[1]);
    this.$f7.preloader.hide();
  },
  methods: {
    async initData() {
      await this.getReferenceValues(this.currentPlantation.species);
      if (this.stepData.nutritionalNeeds && this.stepData.nutritionalNeeds.length > 0) {
        return;
      }

      this.stepData.expectedProduction = 1;

      // Si no hay un registro en el array de 'this.nutritionalNeeds',
      //   se dispara un error al añadir los datos al stepData.
      // El arreglo más sencillo es crear un objeto de nutrición vacío.
      let firstNeed = this.nutritionalNeeds[0];
      if (firstNeed == undefined) {
        firstNeed = {
          n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
        };
      }

      this.stepData.nutritionalNeeds.push(firstNeed);
      this.stepData.nutritionalNeeds.push({
        id: null,
        production: 1,
        n: this.roundValue(firstNeed.n * 1),
        p: this.roundValue(firstNeed.p * 1),
        k: this.roundValue(firstNeed.k * 1),
        c: this.roundValue(firstNeed.c * 1),
        m: this.roundValue(firstNeed.m * 1),
        s: this.roundValue(firstNeed.s * 1),
      });
    },
    onKeyUpExpectedProduction(e) {
      this.stepData.nutritionalBalanceData = [];
      let { value } = e.event.target;
      if (value.length === 0) {
        value = 0;
        this.stepData.expectedProduction = 1;
      }

      this.stepData.nutritionalNeeds[1].production = value;
      this.calculateValue(this.stepData.nutritionalNeeds, 'n', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'p', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'k', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'c', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 'm', value);
      this.calculateValue(this.stepData.nutritionalNeeds, 's', value);
    },
    cleanNutritionalBalanceData() {
      this.stepData.nutritionalBalanceData = [];
    },
    calculateValue(array, param, value) {
      array[1][param] = this.roundValue(array[0][param] * value);
    },

    roundValue(val) {
      return parseFloat(val.toFixed(2));
    },
    ...mapActions('referenceValueStore', ['getReferenceValues']),
  },
};
