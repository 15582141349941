<template>
  <div>
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link
        href="./plague/"
        route-tab-id="tab-phytosanitary-plague"
        @click="toShowPlagueTab()"
      >
        {{ $t('Plague') }}
      </f7-link>
      <f7-link
        tab-link
        href="./phytosanitary/product/"
        route-tab-id="tab-phytosanitary-product"
        @click="toShowProductTab()"
      >
        {{ $t('Product') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      animated
      routable
    >
      <f7-tab
        id="tab-phytosanitary-plague"
        class="page-content"
      >
        <phytosanitaryProductsPlague v-if="!flagToShowTabs" />
      </f7-tab>
      <f7-tab
        id="tab-phytosanitary-product"
        class="page-content"
      >
        <phytosanitaryProductsProduct v-if="flagToShowTabs" />
      </f7-tab>
    </f7-tabs>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import phytosanitaryProductsPlague from './phytosanitaryProductsPlague/index.vue';
import phytosanitaryProductsProduct from './phytosanitaryProductsProduct/index.vue';

export default {
  name: 'PhytosanitaryProducts',
  components: {
    phytosanitaryProductsPlague,
    phytosanitaryProductsProduct,
  },
  data() {
    return {
      flagToShowTabs: false,
    };
  },
  beforeMount() {
    this.flagToShowTabs = false;
  },
  methods: {
    toShowProductTab() {
      this.flagToShowTabs = true;
      this.setCurrentPage('phytosanitaryProductsProduct');
    },
    toShowPlagueTab() {
      this.flagToShowTabs = false;
      this.setCurrentPage('phytosanitaryProductsPlague');
    },
    ...mapActions('Utilities', ['setCurrentPage']),
  },
};
</script>
<style lang="scss" scoped>
@import './PhytosanitaryProducts.styles.scss';
</style>
