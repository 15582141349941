<template>
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('editTool_Title')}`" />
    <DxToolsForm
      class="content-planning"
      :edit-form="true"
    />
    <f7-block-footer
      class="content-action-botton"
    >
      <f7-button
        class="dx-btn-cancel margin-right"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToToolsDatabase"
      >
        {{ $t("CreatePlanning_ButtonCancel_caption") }}
      </f7-button>
      <f7-button
        class="dx-btn-success"
        raised
        type="success"
        styling-mode="contained"
        name="CreatePlanning_ButtonSave_caption"
        @click="updateTools"
      >
        {{ $t('CreatePlanning_ButtonSave_caption') }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import DxToolsForm from '../../../components/configuration/dataTools/DxToolsForm.vue';
import navbar from '../../../components/NavBar.vue';
import Api from '../../../services/Api';

export default {
  name: 'UpdateTools',
  components: {
    DxToolsForm,
    navbar,
  },
  computed: {
    ...mapState('Tool', ['actualTools']),
  },
  methods: {
    goToToolsDatabase() {
      this.$f7.views.main.router.navigate('/toolsDatabase/', { reloadCurrent: true });
    },
    async updateTools() {
      if (this.actualTools.code === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationCode'));
      } else if (this.actualTools.brand === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationBrand'));
      } else if (this.actualTools.id === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationID'));
      } else if (this.actualTools.itemId === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationItemID'));
      } else if (this.actualTools.description === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationDescription'));
      } else if (this.actualTools.family === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationFamily'));
      } else if (this.actualTools.subfamily === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationSubfamily'));
      } else if (this.actualTools.fuel === '') {
        this.$f7.dialog.alert(this.$t('Tools_ValidationFuel'));
      } else {
        this.$f7.preloader.show();
        try {
          const updateTools = {
            code: this.actualTools.code,
            brand: this.actualTools.brand,
            id: this.actualTools.id,
            description: this.actualTools.description,
            itemId: this.actualTools.itemId,
            status: this.actualTools.status,
            family: this.actualTools.family,
            subfamily: this.actualTools.subfamily,
            properties: [
              {
                key: 'fuel',
                value: this.actualTools.fuel,
              },
            ],
          };
          await Api.updateTool(this.actualTools.id, updateTools);
          this.goToToolsDatabase(); // TODO: Habría que mostrar alguna notificación de que se creó correctamente
        } catch (reason) {
          const message = this.$helpers.getFilteredErrorMessage(reason);
          if (message.includes('default_') && !message.includes('404')) {
            this.$f7.dialog.alert(message.split('_').pop());
          } else if (!message.includes('404')) {
            this.$f7.dialog.alert(this.$t(message));
          }
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
  },
};
</script>
