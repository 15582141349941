import moment from 'moment-timezone';
import { treatmentPlanPeriod } from '../../services/treatmentPlanService';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';
import db from '../../services/DexieDatabase';
import Vademecum from '../../js/models/Vademecum';
import Product from '../../js/models/Product';

function getTimeValues(dateStart, dateEnd, period) {
  const timeValues = {};
  switch (period) {
    case 'SEMANAL':
      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
        timeValues[varName] = 0;
        dateStart.add(1, 'week');
      }
      break;
    case 'MENSUAL':
    default:
      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
        timeValues[varName] = 0;
        dateStart.add(1, 'month');
      }
      break;
  }
  return timeValues;
}

const state = {

  stepIndex: 0,
  treatmentSteps: [
    {
      label: 'Paso 1', status: 1, complete: false, page: '/utilities/treatment/treatment-step-one',
    },
    {
      label: 'Paso 2', status: 0, complete: false, page: '/utilities/treatment/treatment-step-two',
    },
    {
      label: 'Paso 3', status: 0, complete: false, page: '/utilities/treatment/treatment-step-three',
    },
  ],
  currentStep: {},
  treatmentPlan: {
    plantation: '609aafbf90cca00ff2285aae',
    area: 5.52,
    description: '',
    init_date: '',
    end_date: '',
    period: treatmentPlanPeriod.MONTHLY,
    dose_type: '',
    agent: '',
    products: [],
    timeValues: [],
    dosages: [],
    totalApplications: [],
    productResume: [],
  },
  treatmentProducts: [],
  treatmentProduct: {},
  currentTreatmentId: '',
  hasTreatmentError: {},
};

// to handle state
const getters = {
  treatmentSteps: (stateL) => stateL.treatmentSteps,
  treatmentPlan: (stateL) => stateL.treatmentPlan,
  currentStep: (stateL) => stateL.currentStep,
  stepIndex: (stateL) => stateL.stepIndex,
  treatmentError: (stateL) => stateL.hasTreatmentError,
};

// to handle actions
const actions = {
  treatmentSteps({ commit }) {
    commit('SET_TREATMENT_STEPS', state.treatmentSteps);
  },
  getTreatmentPlan({ commit }) {
    commit('SET_TREATMENT_PLAN', state.treatmentPlan);
  },
  getCurrentStep({ commit }) {
    commit('SET_CURRENT_STEP', state.currentStep);
  },
  getStepIndex({ commit }) {
    commit('SET_STEP_INDEX', state.stepIndex);
  },
  findSelectedIndex(context) {
    for (let i = 0; context.state.treatmentSteps; i += 1) {
      if (context.state.treatmentSteps[i].status === 0) {
        if (i === 0) {
          return 0;
        }
        return i - 1;
      }
    }
    return 0;
  },
  updateClasses(context) {
    context.state.treatmentSteps.forEach((step, index) => {
      switch (step.status) {
        case 1:
          step.statusClass = 'dot-activate';
          break;
        default:
        case 0:
          step.statusClass = '';
          break;
      }
      if (index < context.state.stepIndex) {
        step.statusClass += ' dot-complete';
      }
    });
    context.state.treatmentSteps[context.state.stepIndex].statusClass += ' dot-current';
  },
  next(context) {
    context.state.stepIndex += 1;

    if (context.state.stepIndex >= context.state.treatmentSteps.length) {
      context.state.stepIndex = context.state.treatmentSteps.length - 1;
    }
    context.state.treatmentSteps[context.state.stepIndex].status = 1;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  back(context) {
    context.state.stepIndex -= 1;
    if (context.state.stepIndex < 0) {
      context.state.stepIndex = 0;
    }
    context.state.treatmentSteps[context.state.stepIndex + 1].status = 0;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  setStep(context, stepIndex) {
    context.state.stepIndex = stepIndex;
    context.state.treatmentSteps.forEach((step) => {
      step.status = 0;
    });

    context.state.treatmentSteps.forEach((step, index) => {
      if (index <= stepIndex) {
        step.status = 1;
      }
    });
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  initTreatmentPlan({ commit }) {
    commit('SET_TREATMENT_PLAN', {
      plantation: '609aafbf90cca00ff2285aae',
      area: 5.52,
      description: '',
      init_date: '',
      end_date: '',
      period: treatmentPlanPeriod.MONTHLY,
      dose_type: '',
      agent: '',
      products: [],
      timeValues: [],
      dosages: [],
      totalApplications: [],
      productResume: [],

    });
    commit('SET_TREATMENT_PRODUCT_NOT_FOUND', { status: false, products: [] });
  },
  async updateTreatmentPlan({ commit }, payload) {
    commit('SET_TREATMENT_PRODUCT_NOT_FOUND', { status: false, products: [] });
    const timeValuesTmp = payload.products.map((product) => {
      const timeValues = getTimeValues(moment(payload.init_date, 'YYYY/MM/DD').locale('es'),
        moment(payload.end_date, 'YYYY/MM/DD').locale('es'),
        payload.period);
      let counter = -1;
      for (const prop in timeValues) {
        if (prop) {
          timeValues[prop] = product.periods[counter += 1];
        }
      }
      return timeValues;
    });
    const dosages = timeValuesTmp.map((timeValues) => {
      let counter = 0;
      for (const prop in timeValues) {
        if (prop) {
          counter += timeValues[prop];
        }
      }
      return { dosage: counter };
    });

    const totalApplications = timeValuesTmp.map((timeValues) => {
      let totalProps = 0;
      for (const prop in timeValues) {
        if (prop) {
          if (timeValues[prop] > 0) {
            totalProps += 1;
          }
        }
      }
      return { total: totalProps };
    });

    const xhr = await Api.getTreatmentProducts(payload.species, payload.agent);
    const treatmentProducts = JSON.parse(xhr.response).data;

    const productsNotFound = [];
    const products = await Promise.all(payload.products.map(async (product) => {
      const productFound = treatmentProducts
        .find((item) => item.name === JSON.parse(JSON.stringify(product.product)));
      if (!productFound) {
        console.error('Product not found', product.product);
        productsNotFound.push(product.product);
        commit('SET_TREATMENT_PRODUCT_NOT_FOUND', { status: true, products: productsNotFound });
        return {};
      }
      const xhrProd = await Api.getTreatmentProduct(
        payload.species,
        payload.agent,
        productFound.id,
      );
      const productService = JSON.parse(xhrProd.response);

      let dosageRange = '';
      if (productService.dose !== null) {
        dosageRange = `${productService.dose.max} - ${productService.dose.min} (${productService.dose.unit})`;
      }
      let noApplications = 0;
      if (productService.application_num !== null) {
        noApplications = productService.application_num.max;
      }

      return {
        name: productService.name,
        dosageRange,
        dose: productService.dose ? {
          min: productService.dose.min,
          max: productService.dose.max,
        } : null,
        noApplications,
        ...product,
      };
    }));

    commit('SET_TREATMENT_PLAN', {
      id: payload.id,
      plantation: payload.plantation,
      area: payload.area,
      description: payload.description,
      period: payload.period,
      init_date: payload.init_date,
      end_date: payload.end_date,
      dose_type: payload.dose_type,
      agent: payload.agent,
      products,
      timeValues: timeValuesTmp,
      dosages,
      totalApplications,
      productResume: [],

    });
  },
  async fetchTreatmentProducts({ commit, rootState }, { crop, agent }) {
    try {
      const xhr = await Api.getTreatmentProducts(crop, agent, rootState.Plantation.currentPlantation.companyId);
      const treatmentProducts = JSON.parse(xhr.response).data;
      commit('setTreatmentProducts', treatmentProducts);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  async fetchTreatmentProduct({ commit, rootState }, {
    crop, agent, id,
  }) {
    try {
      const xhr = await Api.getTreatmentProduct(crop, agent, id, rootState.Plantation.currentPlantation.companyId);
      commit('setTreatmentProduct', JSON.parse(xhr.response));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  setCurrentTreatmentId({ commit }, id) {
    commit('setCurrentTreatmentId', id);
  },
  async getTreatmentPlanByIdandCompanyId({ commit }, { companyId, treatmentId }) {
    try {
      const xhr = await Api.getTreatmentPlanByIdandCompanyId(companyId, treatmentId);
      const currentTreatment = JSON.parse(xhr.response);
      commit('getTreatmentPlanByIdandCompanyId', currentTreatment);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
};

// to handle mutations
const mutations = {
  SET_TREATMENT_STEPS(stateL, treatmentSteps) {
    stateL.treatmentSteps = treatmentSteps;
  },
  SET_TREATMENT_PLAN(stateL, treatmentPlan) {
    stateL.treatmentPlan = treatmentPlan;
  },
  SET_CURRENT_STEP(stateL, currentStep) {
    stateL.currentStep = currentStep;
  },
  SET_STEP_INDEX(stateL, stepIndex) {
    stateL.stepIndex = stepIndex;
  },
  setTreatmentProducts(stateL, newTreatmentProducts) {
    stateL.treatmentProducts = newTreatmentProducts;
  },
  setTreatmentProduct(stateL, newTreatmentProduct) {
    stateL.treatmentProduct = newTreatmentProduct;
  },
  setCurrentTreatmentId(stateL, value) {
    stateL.currentTreatmentId = value;
  },
  getTreatmentPlanByIdandCompanyId(stateL, value) {
    stateL.treatmentPlan = value;
  },
  SET_TREATMENT_PRODUCT_NOT_FOUND(statel, value) {
    statel.hasTreatmentError = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
