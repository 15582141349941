<template>
  <div>
    <f7-row>
      <f7-col width="50">
        <TreatmentOrderComponent />
      </f7-col>
      <f7-col width="50">
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISWMSMap
            :zoom="zoom"
            :name-map="mainMap"
            :layer="layer"
            :index="'NDRE'"
            :center="center"
            :height="'450px'"
          />
        </f7-block>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col width="100">
        <TreatmentPlanComponent />
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSMap from '../../Gis/GISWMSMap/index.vue';
import TreatmentOrderComponent from '../../wizardTreatment/treatmentOrder/index.vue';
import TreatmentPlanComponent from '../../wizardTreatment/treatmentPlan/index.vue';
import Api from '../../../services/Api';

export default {
  name: 'Treatment',
  components: {
    GISWMSMap,
    TreatmentOrderComponent,
    TreatmentPlanComponent,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'treatment',
      layer: {
        name: 'treatment',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: '',
        },
      },
      center: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
  },
  async beforeMount() {
    try {
      this.layer.options.layers = `client_efemis:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
  },
};
</script>

<style lang="scss" scoped>
@import './Treatment.styles.scss';
</style>
