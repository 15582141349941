<template>
  <f7-block v-if="!loaded">
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </f7-block>
  <f7-block
    v-else
    class="no-padding-right"
  >
    <div
      class="content-devx"
    >
      <f7-block-title>
        <span>{{ `${$t('Location_title')}` }}</span>
      </f7-block-title>
      <DxDataGrid
        id="Location"
        :data-source="listExplotation"
        :remote-operations="true"
        :column-min-width="50"
        :column-auto-width="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :on-editing-start="onEditingStart"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="DxSystemParcelLocation"
        />
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxPaging :page-size="5" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="name"
          :width="90"
          :caption="$t('ParcelSystemLocation_designacion')"
          :allow-header-filtering="false"
          alignment="left"
          sort-order="desc"
        />
        <DxColumn
          data-field="parcel"
          :caption="$t('ParcelSystemLocation_parcelas')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="plantation"
          :caption="$t('ParcelSystemLocation_plantations')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="sector"
          :caption="$t('ParcelSystemLocation_sectores')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          type="buttons"
        >
          <DxButton
            icon="find"
            alignment="right"
            :on-click="showViewLocation"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
  </f7-block>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'DxSystemParcelLocation',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
  },
  data() {
    return {
      loaded: false,
      LocationFormated: [],
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
    };
  },
  computed: {
    isEditing() {
      return this.isEdit === true ? this.$t('DxClient_edit_Client') : this.$t('DxClient_register_Client');
    },
    ...mapState('Campania', ['listExplotation']),

  },
  async beforeMount() {
    try {
      await this.getListExplotation();
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async showViewLocation(e) {
      await this.fetchActualLocation(e.row.data);
      this.setIsFromCampaign(false);
      this.$f7.views.main.router.navigate('/viewLocation/', { reloadCurrent: true });
    },
    onHidden(e) {
      this.isEdit = false;
    },
    onEditingStart(e) {
      this.isEdit = true;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SystemParcelLocation');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SystemParcelLocation.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initStore();
            this.$f7.views.main.router.navigate('/registerLocation/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Explotaciones', ['fetchActualLocation']),
    ...mapActions('RegisterLocation', ['initStore']),
    ...mapActions('Campania', ['getListExplotation', 'setIsFromCampaign']),

  },
};
</script>
