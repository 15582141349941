import {
  DxColumn, DxDataGrid,
} from 'devextreme-vue/data-grid';
import { mapGetters } from 'vuex';

export default {
  name: 'element-resume',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: [],
  data() {
    return {
      elementResume: [],
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
  },
  mounted() {
    this.elementResume.push(this.addRowDataSource('Nec. nutricionales', this.stepData.nutritionalBalanceData[0]));
    this.elementResume.push(this.addRowDataSource('Balance previo', this.stepData.nutritionalBalanceData[5]));
    this.elementResume.push(this.addRowDataSource('UF aportadas', this.calculateUfContributed()));

    this.elementResume.push(this.addRowDataSource('Balance final',
      this.calculateFinalBalance()));
  },
  methods: {
    calculateUfContributed() {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
      this.stepData.timeValues.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          totalProps += item[prop];
        }
        this.stepData.dosages[index].dosage = totalProps;
      });

      this.stepData.dosageProduct.forEach((item, index) => {
        for (const prop in item) {
          ufContributed[prop] += this.calculateElementBalance(index, prop);
        }
      });

      return ufContributed;
    },
    calculateElementBalance(index, prop) {
      return Math.round((this.stepData.dosageProduct[index][prop] / 100)
          * this.stepData.dosages[index].dosage);
    },
    addRowDataSource(titleColumn, element) {
      return {
        uf: titleColumn,
        n: element.n,
        p: element.p,
        k: element.k,
        c: element.c,
        m: element.m,
        s: element.s,
      };
    },
    calculateFinalBalance() {
      return {
        n: (this.elementResume[1].n + this.elementResume[2].n).toFixed(2),
        p: (this.elementResume[1].p + this.elementResume[2].p).toFixed(2),
        k: (this.elementResume[1].k + this.elementResume[2].k).toFixed(2),
        c: (this.elementResume[1].c + this.elementResume[2].c).toFixed(2),
        m: (this.elementResume[1].m + this.elementResume[2].m).toFixed(2),
        s: (this.elementResume[1].s + this.elementResume[2].s).toFixed(2),
      };
    },
  },

};
