<template>
  <DxForm
    id="form"
    :caption="$t('Campania_title')"
  >
    <DxGroupItem :col-count="2">
      <DxSimpleItem
        name="code"
        :data-field="$t('TaskDefinition.code')"
        :editor-options="{
          value: currentTaskDefinition.code,
          onValueChanged: onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.code_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="name"
        :data-field="$t('TaskDefinition.name')"
        :editor-options="{
          value:currentTaskDefinition.name,
          onValueChanged:onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.name_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="activity"
        :data-field="$t('TaskDefinition.activity')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: activities,
          value:currentTaskDefinition.activity_code,
          onValueChanged: onValueChanged,
          displayExpr: 'name',
          valueExpr: 'code',
          searchEnabled: true,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.activity_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="unit"
        :data-field="$t('TaskDefinition.unit')"
        :editor-options="{
          value:currentTaskDefinition.unit,
          onValueChanged:onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.unit_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="performance_unit"
        :data-field="$t('TaskDefinition.performance_unit')"
        editor-type="dxSelectBox"
        :editor-options="{
          items: performanceUnits,
          value:currentTaskDefinition.performance_unit,
          onValueChanged: onValueChanged,
          searchEnabled: true,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.unit_performance_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="price"
        :data-field="$t('TaskDefinition.price')"
        :editor-options="{
          value:currentTaskDefinition.price,
          onValueChanged:onValueChanged,
        }"
      >
        <DxRequiredRule :message="$t('TaskDefinition.price_validation')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="prescription"
        :data-field="$t('TaskDefinition.prescription')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: currentTaskDefinition.prescription,
          onValueChanged: onValueChanged,
        }"
      />
      <DxSimpleItem
        name="nursery"
        :data-field="$t('TaskDefinition.nursery')"
        editor-type="dxCheckBox"
        :editor-options="{
          value: currentTaskDefinition.nursery,
          onValueChanged: onValueChanged,
        }"
      />
    </DxGroupItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions } from 'vuex';

export default {
  name: 'TaskDefinitionForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    performanceUnits: {
      type: Array,
      default: () => [],
    },
    currentTaskDefinition: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onValueChanged(e) {
      const inputChanged = e.element.getElementsByTagName('input')[0].getAttribute('name');
      let property = '';
      const { value } = e;
      switch (inputChanged) {
        case this.$t('TaskDefinition.code'):
          property = 'code';
          break;
        case this.$t('TaskDefinition.name'):
          property = 'name';
          break;
        case this.$t('TaskDefinition.activity'):
          property = 'activity_code';
          break;
        case this.$t('TaskDefinition.unit'):
          property = 'unit';
          break;
        case this.$t('TaskDefinition.performance_unit'):
          property = 'performance_unit';
          break;
        case this.$t('TaskDefinition.price'):
          property = 'price';
          break;
        case this.$t('TaskDefinition.prescription'):
          property = 'prescription';
          break;
        case this.$t('TaskDefinition.nursery'):
          property = 'nursery';
          break;
        default:
          break;
      }
      this.updateCurrentTaskDefinitionProperty({ property, newValue: value });
    },
    ...mapActions('TaskDefinition', ['updateCurrentTaskDefinitionProperty']),

  },
};
</script>
<style lang="scss" scoped>
@import './TaskDefinitionForm.styles.scss';
</style>
