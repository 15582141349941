<template>
  <DxForm
    id="ActivityForm-form"
    :col-count="2"
  >
    <DxSimpleItem
      name="code"
      :data-field="$t('Activity.code')"
      :editor-options="{
        value: currentActivity.code,
        onValueChanged: onValueChanged,
      }"
    >
      <DxRequiredRule :message="$t('Activity.code_validation')" />
    </DxSimpleItem>
    <DxSimpleItem
      name="name"
      :data-field="$t('Activity.name')"
      :editor-options="{
        value: currentActivity.name,
        onValueChanged: onValueChanged,
      }"
    >
      <DxRequiredRule :message="$t('Activity.name_validation')" />
    </DxSimpleItem>
  </DxForm>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions } from 'vuex';

export default {
  name: 'ActivityForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
  },
  props: {
    currentActivity: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onValueChanged(e) {
      const inputChanged = e.element.getElementsByTagName('input')[0].getAttribute('name');
      let property = '';
      const { value } = e;
      switch (inputChanged) {
        case this.$t('Activity.code'):
          property = 'code';
          break;
        case this.$t('Activity.name'):
          property = 'name';
          break;
        default:
          break;
      }
      this.updateCurrentActivityProperty({ property, newValue: value });
    },
    ...mapActions('Activity', ['updateCurrentActivityProperty']),

  },
};
</script>
<style lang="scss" scoped>
@import './ActivityForm.styles.scss';
</style>
