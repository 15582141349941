import {
  DxDataGrid, DxColumn, DxSorting,
  DxScrolling,
  DxPaging,
  DxEditing,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { DxAutocomplete } from 'devextreme-vue/autocomplete';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'dosage-product',
  components: {
    DxAutocomplete,
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSelectBox,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    DxTexts,
  },
  props: [],
  data() {
    return {
      productFertilizerSelected: '',
      productFertilizerSelectedValue: '',
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapGetters('productFertilizerStore', ['productFertilizers']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  created() {
    this.getProductFertilizers(this.currentPlantation.companyId);
    if (this.stepData.dosageProduct.length > 0) {
      if (this.stepData.dosageProductBalance.length === 1) {
        this.stepData.dosageProductBalance.push(this.getRowDataSource(this.$t('dosageProduct.finalBalance')));

        this.calcTotalBalance(this.stepData.timeValues);
      }
      return;
    }
    this.stepData.dosageProductBalance = [];
    this.stepData.dosageProduct = [];
    this.stepData.timeValues = [];
    this.stepData.dosages = [];

    this.stepData.dosageProductBalance.push(this.stepData.nutritionalBalanceData[5]);
    this.stepData.dosageProductBalance.push(this.getRowDataSource(this.$t('dosageProduct.finalBalance')));
  },
  methods: {
    selectProductItem(e) {
      if (!e.value) {
        this.productFertilizerSelected = '';
        return;
      }

      if (!e.event && e.previousValue !== this.productFertilizerSelected.id) {
        this.productFertilizerSelected = this.productFertilizers
          .find((item) => item.id === e.value);
        this.productFertilizerSelectedValue = this.productFertilizerSelected.name;
      }
    },
    getRowDataSource(titleColumn) {
      return {
        uf: titleColumn, n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
    },
    addProductFertilizer() {
      if (!this.productFertilizerSelected) {
        this.$f7.dialog.alert(this.$t('requiredNutritionFertiliser'));
        return;
      }

      const timeValues = [];
      const dateStart = moment(this.stepData.initDate).locale('es');
      const dateEnd = moment(this.stepData.endDate).locale('es');
      const arrayIndex = timeValues.length;
      switch (this.stepData.temporalFrame.id) {
        case 1:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.isoWeek()} - ${dateStart.isoWeekYear()}`;
            if (!timeValues[arrayIndex]) {
              timeValues.push({ [varName]: 0 });
            } else {
              timeValues[arrayIndex][varName] = 0;
            }
            dateStart.add(1, 'week');
          }
          break;
        case 0:
        default:
          while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            const varName = `${dateStart.format('MMM').split('.')[0].toUpperCase()} - ${dateStart.format('yy')}`;
            if (!timeValues[arrayIndex]) {
              timeValues.push({ [varName]: 0 });
            } else {
              timeValues[arrayIndex][varName] = 0;
            }
            dateStart.add(1, 'month');
          }
          break;
      }
      this.stepData.dosageProduct.push(this.productFertilizerSelected);
      this.stepData.dosages.push({ dosage: 0 });
      this.stepData.timeValues = this.stepData.timeValues.concat(timeValues);
    },
    calcTotalBalance(array) {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
      array.forEach((item, index) => {
        let totalProps = 0;
        for (const prop in item) {
          totalProps += item[prop];
        }
        this.stepData.dosages[index].dosage = totalProps;
      });

      this.stepData.dosageProduct.forEach((item, index) => {
        for (const prop in item) {
          ufContributed[prop] += this.calculateElementBalance(index, prop);
        }
      });

      this.updateElementBalance(ufContributed, this.stepData.dosageProductBalance[1]);

      this.differenceElementBalance('n');
      this.differenceElementBalance('p');
      this.differenceElementBalance('k');
      this.differenceElementBalance('c');
      this.differenceElementBalance('m');
      this.differenceElementBalance('s');
    },
    calculateElementBalance(index, prop) {
      return Math.round((this.stepData.dosageProduct[index][prop] / 100)
          * this.stepData.dosages[index].dosage);
    },
    differenceElementBalance(prop) {
      this.stepData.dosageProductBalance[1][prop] = this.stepData
        .dosageProductBalance[0][prop]
          + this.stepData.dosageProductBalance[1][prop];

      this.stepData.dosageProductBalance[1][prop] = Number(this.stepData.dosageProductBalance[1][prop]).toFixed(2);
    },
    updateElementBalance(elementSource, elementTarget) {
      elementTarget.n = elementSource.n;
      elementTarget.p = elementSource.p;
      elementTarget.k = elementSource.k;
      elementTarget.c = elementSource.c;
      elementTarget.m = elementSource.m;
      elementTarget.s = elementSource.s;
    },
    deleteItem(item) {
      this.$f7.dialog.confirm(this.$t('dosageProduct.deleteDialogMessage',
        { product: this.stepData.dosageProduct[item.row.rowIndex].name }),
      this.$t('dosageProduct.deleteDialogTitle'),
      () => {
        this.stepData.timeValues.splice(item.row.rowIndex, 1);
        this.stepData.dosages.splice(item.row.rowIndex, 1);
        this.stepData.dosageProduct.splice(item.row.rowIndex, 1);
      });
    },
    ...mapActions('productFertilizerStore', ['getProductFertilizers']),
  },
  watch: {
    'stepData.timeValues': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalBalance(val);
      },
      deep: true,
    },
  },
};
