<template>
  <div v-if="loaded">
    <f7-row>
      <f7-col
        width="25"
        medium="30"
      >
        <DxTreePlanning
          :locations-tree="locationsTree"
          :planning="{locations: actualFarm}"
          :only-level0="true"
          :show-grouped="false"
        />
      </f7-col>
      <f7-col
        width="75"
        medium="70"
      >
        <DxForm
          id="form"
          :col-count="1"
          caption="Employee Data"
        >
          <DxGroupItem>
            <DxGroupItem
              :caption="$t('EmployeeComponent_caption_text')"
            >
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_name_caption')"
                name="name"
                :caption="$t('EmployeeComponent_name_caption')"
                :editor-options="{
                  value: actualName,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_name_caption'),
                  showClearButton: true,
                  onValueChanged: setNameChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_name_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_surname_caption')"
                name="surname"
                :caption="$t('EmployeeComponent_surname_caption')"
                :editor-options="{
                  value: actualSurname,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_surname_caption'),
                  showClearButton: true,
                  onValueChanged: setSurnameChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_surname_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_identificationNumber_caption')"
                name="identificationNumber"
                :caption="$t('EmployeeComponent_identificationNumber_caption')"
                :editor-options="{
                  value: actualIdentificationNumber,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_identificationNumber_caption'),
                  showClearButton: true,
                  onValueChanged: setIdentificationNumberChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_ID_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_status_caption')"
                :editor-options="{
                  items: itemsStatus,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: setStatusEvent,
                  value: actualstatusName,
                  displayExpr: translateStatus,
                }"
                editor-type="dxSelectBox"
                name="status"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_status_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_role_caption')"
                :editor-options="{
                  items: itemsRole,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: setRoleEvent,
                  value: actualRole,
                  displayExpr: translateRol,
                }"
                editor-type="dxSelectBox"
                name="role"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_role_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_type_caption')"
                :editor-options="{
                  items: itemsType,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: setTypeEvent,
                  value: actualType,
                  displayExpr: translateTypeEmployee,
                }"
                editor-type="dxSelectBox"
                name="type"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_type_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_drive_caption')"
                :editor-options="{
                  onValueChanged: setDriveChenged,
                  value: actualDrive,
                }"
                editor-type="dxCheckBox"
                name="drive"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_applicator_caption')"
                name="applicator"
                :caption="$t('EmployeeComponent_applicator_caption')"
                editor-type="dxCheckBox"
                :editor-options="{
                  value: applicator,
                  onValueChanged: applicatorChanged,
                }"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_expirationDate_caption')"
                :visible="expirationDateVisible"
                name="date"
              >
                <template #default>
                  <f7-input
                    id="datepicker-expiration"
                    class="form-calendar"
                    type="datepicker"
                    :placeholder="$t('EmployeeComponent_datepicker_expirationDate_placeholder')"
                    readonly
                    clear-button
                    :calendar-params="{openIn: 'customModal', header: false, footer: true, dateFormat: 'dd/mm/yyyy', multiple: false, minDate: minDate, maxDate: '', disabled:sundays}"
                    :value="expirationDateSelected"
                    @calendar:change="expirationDateSelectedEvent"
                  />
                </template>
              </DxSimpleItem>
              <!-- NO APLICA EN CHILE
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_group_caption')"
                name="group"
                :caption="$t('EmployeeComponent_group_caption')"
                :editor-options="{
                  value: actualGroup,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_group_caption'),
                  showClearButton: true,
                  onValueChanged: setGroupChanged,
                }"
              />
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_shift_caption')"
                :editor-options="{
                  items: itemsShift,
                  searchEnabled: true,
                  searchTimeout: searchTimeoutOption,
                  minSearchLength: minSearchLengthOption,
                  showDataBeforeSearch: showDataBeforeSearchOption,
                  onValueChanged: setShiftEvent,
                  value: actualShift,
                }"
                editor-type="dxSelectBox"
                name="shift"
              />
              -->
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_email_caption')"
                name="email"
                :caption="$t('EmployeeComponent_email_caption')"
                :visible="isSupervisor"
                :editor-options="{
                  value: actualEmail,
                  mode: 'email',
                  placeholder: $t('EmployeeComponent_email_caption'),
                  showClearButton: true,
                  onValueChanged: setEmailChanged,
                }"
              >
                <DxRequiredRule :message="$t('DxEmployeeForm_email_require')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('EmployeeComponent_password_caption')"
                name="password"
                :visible="isSupervisor"
                :caption="$t('EmployeeComponent_password_caption')"
                :editor-options="{
                  value: actualPassword,
                  mode: 'text',
                  placeholder: $t('EmployeeComponent_password_caption'),
                  showClearButton: true,
                  onValueChanged: setPasswordChanged,
                }"
              />
            </DxGroupItem>
            <DxGroupItem>
              <DxGroupItem
                name=""
                :caption="$t('EmployeeComponent_identification_title')"
              >
                <DxSimpleItem
                  :data-field="$t('EmployeeComponent_typeIdentification_caption')"
                  :editor-options="{
                    dataSource: ['NFC'],
                    searchEnabled: true,
                    searchTimeout: searchTimeoutOption,
                    minSearchLength: minSearchLengthOption,
                    showDataBeforeSearch: showDataBeforeSearchOption,

                    onValueChanged: setTypeIdentificationEvent,
                    value: actualTypeIdentification,
                  }"
                  editor-type="dxSelectBox"
                  name="typeIdentification"
                  :caption="$t('EmployeeComponent_typeIdentification_caption')"
                />
                <DxSimpleItem
                  :data-field="$t('EmployeeComponent_code_caption')"
                  name="codeIdentification"
                  :caption="$t('EmployeeComponent_code_caption')"
                  :editor-options="{
                    value: actualCode,
                    mode: 'text',
                    placeholder: $t('EmployeeComponent_code_caption'),
                    showClearButton: true,
                    onValueChanged: setCodeChanged,
                  }"
                />
              </DxGroupItem>
            </DxGroupItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import * as overlay from 'devextreme/ui/overlay';
import DxTreePlanning from '../../DxTreePlanning.vue';
import Api from '../../../services/Api';

export default {
  name: 'DxEmployeeForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxTreePlanning,
  },
  data() {
    return {
      loaded: false,
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      isSupervisor: false,
      actualName: '',
      actualSurname: '',
      actualIdentificationNumber: '',
      actualGroup: '',
      actualType: '',
      actualTypeIdentification: 'NFC',
      actualCode: '',
      actualRole: 'OPERATOR', // TODO: Sustituir el literal por el enumerado obtenido del back
      actualShift: '',
      actualEmail: '',
      actualPassword: '',
      itemsStatus: [this.$t('DxEmployeeForm_active'), this.$t('DxEmployeeForm_noActive')],
      itemsRole: ['OPERATOR', 'SUPERVISOR'], // TODO: Sustituir los literales por los enumerados obtenidos del back
      itemsType: ['TEMPORARY', 'PERMANENT', 'CONTRATIST'], // TODO: Sustituir los literales por los enumerados obtenidos del back
      itemsDrive: [this.$t('No'), this.$t('Yes')],
      itemsShift: ['', 'Shift 1', 'Shift 2', 'Shift 3'],
      locationsTree: [],
      actualFarm: [],
      applicator: false,
      expirationDateVisible: false,
      expirationDateSelected: [moment().format('MM/DD/YYYY')],
      minDate: moment().format('MM/DD/YYYY'),
    };
  },
  computed: {
    ...mapState('EmployeeManager', ['employee']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    this.locationsTree = await this.loadLocationsTree();
    if (this.employee === null) {
      this.actualstatusName = this.$t('DxEmployeeForm_active');
      this.actualType = this.$t('TEMPORARY');
      this.actualDrive = false;
      this.setApplicator(false);
      this.setExpirationDateSelected([moment().format('MM/DD/YYYY')]);
    } else {
      this.actualName = this.employee.name;
      this.actualSurname = this.employee.surname;
      this.actualstatusName = this.employee.status;
      this.actualType = this.employee.type;
      this.actualDrive = this.employee.drive;
      this.actualRole = this.employee.supervisor ? 'SUPERVISOR' : 'OPERATOR'; // TODO: Sustituir los literales por los enumerados obtenidos del back
      this.actualIdentificationNumber = this.employee.code;
      this.getAuthNFC();
      this.actualRole = this.employee.supervisor === true ? this.itemsRole[1] : this.itemsRole[0];
      this.actualEmail = this.employee.email;
      this.isSupervisor = this.employee.supervisor;
      this.actualFarm = this.employee.farm_id;
      this.applicator = this.employee.applicator;
      this.setApplicator(this.applicator);
      if (this.employee.expirationDate !== '') {
        this.setExpirationDateSelected(this.formattingDates([this.employee.expirationDate], 'YYYY-MM-DD', 'YYYY-MM-DD'));
        this.expirationDateSelected = this.formattingDates([this.employee.expirationDate], 'YYYY-MM-DD', 'YYYY/MM/DD');
      } else {
        this.setExpirationDateSelected(this.expirationDateSelected, 'DD-MM-YYYY', 'YYYY-MM-DD');
      }
      this.expirationDateVisible = this.applicator;
    }
    this.setName(this.actualName);
    this.setSurname(this.actualSurname);
    this.setIdentificationNumber(this.actualIdentificationNumber);

    if (this.actualGroup.length === 0) {
      this.actualGroup = '';
    }
    this.setGroup(this.actualGroup);
    this.setCodeIndentification(this.actualCode);
    this.setTypeIdentification(this.actualTypeIdentification);
    this.setEmail(this.actualEmail);
    this.setPassword('');
    this.setDriver(this.actualDrive);
    this.setStatus(this.actualstatusName);
    this.setRole(this.actualRole);
    this.setType(this.actualType);
    this.setShift(this.actualShift);
    this.setFarm(this.actualFarm);
    this.loaded = true;
    this.$f7.preloader.hide();
  },
  methods: {
    applicatorChanged(e) {
      if (e.value === null) {
        e.value = false;
      }
      if (e.value === true) {
        this.expirationDateVisible = true;
      } else {
        this.expirationDateVisible = false;
      }
      this.applicator = e.value;
      this.setApplicator(e.value);
    },
    expirationDateSelectedEvent(e) {
      this.expirationDateSelected = this.formattingDates(e, 'MM/DD/YYYY', 'MM/DD/YYYY');
      this.setExpirationDateSelected(this.formattingDates(e, 'MM/DD/YYYY', 'YYYY-MM-DD'));
    },
    getAuthNFC() {
      for (let i = 0; i < this.employee.auth.length; i += 1) {
        if (this.employee.auth[i].type === 'NFC') {
          this.actualTypeIdentification = this.employee.auth[i].type;
          this.actualCode = this.employee.auth[i].code;
        }
      }
    },
    setSurnameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualSurname = e.value;
      this.setSurname(e.value);
    },
    setNameChanged(e) {
      if (e.value === null) {
        e.value = '';
      }
      this.actualName = e.value;
      this.setName(e.value);
    },
    setIdentificationNumberChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualIdentificationNumber = e.value;
      this.setIdentificationNumber(e.value);
    },
    setDriveChenged(e) {
      if (e.value === null) {
        e.value = false;
      }
      this.actualDrive = e.value;
      this.setDriver(e.value);
    },
    setGroupChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualGroup = e.value;
      this.setGroup(e.value);
    },
    setCodeChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualCode = e.value;
      this.setCodeIndentification(e.value);
    },

    setEmailChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualEmail = e.value;
      this.setEmail(e.value);
    },
    setPasswordChanged(e) {
      if (e.value === null) {
        e.value = 0;
      }
      this.actualPassword = e.value;
      this.setPassword(e.value);
    },
    setStatusEvent(e) {
      this.actualstatusName = e.value;
      this.setStatus(this.actualstatusName);
    },
    setRoleEvent(e) {
      if (e.value === 'SUPERVISOR') { // TODO: Sustituir el literal de SUPERVISOR por el enumerado obtenido del back
        this.isSupervisor = true;
      } else {
        this.isSupervisor = false;
      }
      this.actualRole = e.value;
      this.setRole(this.actualRole);
    },
    setTypeEvent(e) {
      this.actualType = e.value;
      this.setType(this.actualType);
    },
    setShiftEvent(e) {
      this.actualShift = e.value;
      this.setShift(e.value);
    },
    setTypeIdentificationEvent(e) {
      this.actualTypeIdentification = e.value;
      this.setTypeIdentification(this.actualTypeIdentification);
    },
    async loadLocationsTree() {
      const xhr = await Api.getTreeLocation();
      return JSON.parse(xhr.response);
    },
    formattingDates(dates, formatOrigin, format = 'DD-MM-YYYY') {
      const formatedDates = [];
      for (const date of dates) {
        formatedDates.push(moment(date, formatOrigin).format(format));
      }
      return formatedDates;
    },
    sundays(date) {
      return [0].indexOf(date.getDay()) >= 0;
    },
    translateStatus(e) {
      if (e === 'Active') {
        return this.$t('status_active');
      } if (e === 'No Active') {
        return this.$t('status_noActive');
      }
      return '';
    },
    translateRol(e) {
      if (e === 'OPERATOR') {
        return this.$t('rol_operator');
      } if (e === 'SUPERVISOR') {
        return this.$t('rol_supervisor');
      }
      return '';
    },
    translateTypeEmployee(e) {
      if (e === 'TEMPORARY') {
        return this.$t('typeEmployee_temporary');
      } if (e === 'PERMANENT') {
        return this.$t('typeEmployee_permanent');
      } if (e === 'CONTRATIST') {
        return this.$t('typeEmployee_contratist');
      }
      return '';
    },
    ...mapActions('EmployeeManager',
      [
        'setName',
        'setSurname',
        'setIdentificationNumber',
        'setGroup',
        'setStatus',
        'setRole',
        'setType',
        'setDriver',
        'setShift',
        'setEmail',
        'setPassword',
        'setTypeIdentification',
        'setCodeIndentification',
        'setFarm',
        'setApplicator',
        'setExpirationDateSelected',
      ]),
  },
};
</script>

<style lang="scss">
#form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.content-activity{
  .dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection{
    padding-top: 0;
    margin-top: -5px;
  }
  &__area{
    background-color: #EAE3D8;
    border-radius: 3px;
    padding: 8px 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    span, input{
      color: var(--f7-theme-color);
      opacity: 1;
      text-transform: none;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
    input{
      font-weight: normal;
    }
  }
}
.content-machinery{
  .content-devx{
    margin: 0 0 15px 0!important;
  }
  .dx-form-group-caption{
    padding: 0 0 10px 0!important;
    display: block;
  }
}
</style>
