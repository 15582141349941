<template>
  <f7-page>
    <navbar :text="$t('planned_title')" />
    <DxPlannedVsReal
      v-if="loaded"
      :locations-tree="locationsTree"
      :badgets="badgets"
      :list-of-costs="listOfCosts"
      :daily-real-performance-tasks="dailyRealPerformanceTasks"
      :init-year-badget="checkedInitEndDate.init"
      :end-year-badget="checkedInitEndDate.end"
    />
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import * as overlay from 'devextreme/ui/overlay';
import DxPlannedVsReal from '../../components/reporting/DxPlannedVsReal.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';

export default {
  components: {
    DxPlannedVsReal,
    navbar,
  },
  data() {
    return {
      loaded: false,
      locationsTree: [],
      badgets: [],
      listOfCosts: [],
      dailyRealPerformanceTasks: [],
      currentYear: moment().format('yyyy'),
      yearBefore: moment().subtract(1, 'years').format('YYYY'),
      keyName: 'plannedVsReal',
      checkedInitEndDate: {},
    };
  },

  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  async beforeMount() {
    overlay.baseZIndex(9999);
    this.$f7.preloader.show();
    try {
      if (typeof this.actualFilter.plannedVsReal === 'undefined') {
        const currentYear = moment().format('yyyy');
        const yearBefore = moment().subtract(1, 'years').format('YYYY');
        this.setActualFilter({
          keyName: this.keyName,
          init: moment.tz(`${yearBefore}-09-01`, 'UTC').format('YYYY-MM-DD'),
          end: moment.tz(`${currentYear}-10-31`, 'UTC').format('YYYY-MM-DD'),
        });
      }
      let xhr = await Api.getTreeLocation();
      this.locationsTree = JSON.parse(xhr.response);
      xhr = await Api.getMonthlyTasks();
      this.badgets = JSON.parse(xhr.response).data;
      xhr = await Api.getAllCosts();
      this.listOfCosts = JSON.parse(xhr.response).data;
      this.checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
      xhr = await Api.getDailyRealPerformance(`${this.checkedInitEndDate.init}`, `${this.checkedInitEndDate.end}`);
      this.dailyRealPerformanceTasks = JSON.parse(xhr.response).data;
    } catch (e) {
      const message = this.$helpers.getFilteredErrorMessage(e);
      if (message.includes('default_') && !message.includes('404')) {
        this.$f7.dialog.alert(message.split('_').pop());
      } else if (!message.includes('404')) {
        this.$f7.dialog.alert(this.$t(message));
      }
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('CalendarInitEndDate', ['setActualFilter']),
  },
};
</script>

<style>

</style>
