import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    activities: [],
    currentActivity: {},
  },
  actions: {
    async fetchActivities({ commit }) {
      try {
        const xhr = await Api.fetchActivitiesAvailables();
        const activities = JSON.parse(xhr.response).data;
        commit('fetchActivities', activities);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getActivityById({ commit }, activityID) {
      try {
        const xhr = await Api.getActivityById(activityID);
        const activity = JSON.parse(xhr.response);
        commit('setCurrentActivity', activity);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createActivity({ commit }, newActivity) {
      try {
        const xhr = await Api.createActivity(newActivity);
        const activity = JSON.parse(xhr.response);
        commit('updateCurrentActivityProperty', { property: 'id', newValue: activity.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateActivity({ commit }, currentActivity) {
      try {
        const xhr = await Api.updateActivity(currentActivity);
        const activity = JSON.parse(xhr.response);
        commit('updateCurrentActivityProperty', { property: 'id', newValue: activity.id });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteActivity(_, activityID) {
      try {
        await Api.deleteActivity(activityID);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    updateCurrentActivityProperty({ commit }, { property, newValue }) {
      commit('updateCurrentActivityProperty', { property, newValue });
    },
    initStore({ commit }) {
      commit('initStore');
    },
  },
  mutations: {
    fetchActivities(state, value) {
      state.activities = value;
    },
    setCurrentActivity(state, value) {
      state.currentActivity = value;
    },
    updateCurrentActivityProperty(state, { property, newValue }) {
      state.currentActivity[property] = newValue;
    },
    initStore(state) {
      state.currentActivity.code = '';
      state.currentActivity.name = '';
    },
  },
};
