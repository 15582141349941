<template>
  <div class="margin-top-half">
    <DxDataGrid
      id="TasksDatabase-datagrid"
      :data-source="tasksDefinitions"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @RowRemoved="callToDeleteTask"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="TasksDatabase"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-updating="true"
        :allow-deleting="true"
        :confirm-delete="true"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxColumn
        data-field="activity"
        :width="350"
        :caption="$t('TaskDefinition.activity')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="name"
        :caption="$t('TaskDefinition.name')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="unit"
        :caption="$t('TaskDefinition.unit')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="performance_unit"
        :caption="$t('TaskDefinition.performance_unit')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="price"
        :caption="$t('TaskDefinition.price')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        type="buttons"
        :fixed="true"
        fixed-position="right"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="showDetail"
        />
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editTask"
        />
        <DxButton
          name="delete"
          icon="trash"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'TasksDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
  },
  props: {
    tasksDefinitions: { type: Array, default: () => [] },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    showDetail(rowSelected) {
      if (rowSelected.row.data.id) {
        this.initStore();
        this.updateCurrentTaskDefinitionProperty({ property: 'id', newValue: rowSelected.row.data.id });
        this.$f7.views.main.router.navigate('/viewTaskDetail/', { reloadCurrent: true });
      }
    },
    editTask(rowSelected) {
      if (rowSelected.row.data.id) {
        this.initStore();
        this.updateCurrentTaskDefinitionProperty({ property: 'id', newValue: rowSelected.row.data.id });
        this.$f7.views.main.router.navigate('/editTaskDefinition/', { reloadCurrent: true });
      }
    },
    async callToDeleteTask(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.deleteTaskDefinition(rowSelected.data.id);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.$f7.views.main.router.navigate('/createTaskDefinition/', { reloadCurrent: true });
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('TasksDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'TasksDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('TaskDefinition', ['updateCurrentTaskDefinitionProperty', 'deleteTaskDefinition', 'initStore']),

  },
};
</script>
<style lang="scss" scoped>
@import './TasksDatabase.styles.scss';
</style>
