import { fertilizationStatus } from '@/services/fertilizationService';
import numeral from 'numeral';
import moment from 'moment';

const numeralFormat = '0,0.00';

export default {
  name: 'nutrition-detail',
  components: {},
  props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    plantationProperty: {
      type: Object,
      default: () => {},
    },
    hideReportButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getNameStatus(status) {
      return this.$t(status);
    },
    goToViewCopyNutritionPlan() {
      this.$f7.views.main.router.navigate('/viewCopyNutritionPlan/', { reloadCurrent: true });
    },
    getClassStatus(status) {
      switch (status) {
        case fertilizationStatus.ACTIVE:
          return 'label-success';
        case fertilizationStatus.CANCELLED:
          return 'label-danger';
        default:
        case fertilizationStatus.PLANNED:
          return 'label-primary';
      }
    },
    formatNumber(number) {
      return numeral(number).format(numeralFormat);
    },
    formatingDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    viewReport() {
      this.$f7.views.main.router.navigate('/viewNutritionReport/', { reloadCurrent: true });
    },
  },
};
