<template>
  <DxForm
    v-if="nameProducts.length !== 0"
    style="background-color:white"
  >
    <DxGroupItem>
      <!-- Products-->
      <DxSimpleItem
        v-for="(item, index) in nameProducts"
        :key="index"
        :data-field="item.type"
        :name="item.type"
        :editor-options="{ value: item.count, disabled:true}"
      />
    </DxGroupItem>
  </DxForm>
  <f7-block
    v-else
    name="sin_items"
  >
    {{ $t('DetailTabProduct_no_seleccionado') }}
  </f7-block>
</template>

<script>

import { DxForm, DxSimpleItem, DxGroupItem } from 'devextreme-vue/form';

export default {
  components: { DxForm, DxSimpleItem, DxGroupItem },
  props: {
    productsSelected: { type: Object, default: () => [] },
  },
  data() {
    return {
      nameProducts: [],
    };
  },
  beforeMount() {
    this.getproductsData();
  },
  methods: {
    // Obtenemos los datos necesarios para mostrar Productos.
    getproductsData() {
      if (this.productsSelected.Planner.prescription !== null) {
        const listProducts = this.productsSelected.Planner.prescription.products;
        for (let i = 0; i < listProducts.length; i += 1) {
          const productSelected = listProducts[i];
          const calcSolutionTotal = (productSelected.solution !== '0.00') ? (parseFloat(productSelected.solution) * parseFloat(this.productsSelected.Planner.area_effective)) : 0;
          const selectedObject = this.productsSelected;
          const seletedProduct = this.productsSelected.Planner.prescription.products[i];
          const quantityPlanned = this.calulateQuantityPlanned(selectedObject, seletedProduct);
          const unitQuantityPlanned = this.calulateUnitQuantityPlanned(selectedObject, seletedProduct);
          this.nameProducts.push({
            count: `${this.$t('DxDetailTabs_products_quantityPlanned')}: ${quantityPlanned} ${unitQuantityPlanned}
            ${this.$t('DxDetailTabs_products_waterPlanned')}: ${calcSolutionTotal.toFixed(0).toString()} L`,
            type: listProducts[i].product.description,
          });
        }
      }
    },
    calulateQuantityPlanned(selectedObject, seletedProduct) {
      let quantityPlanned = parseFloat(selectedObject.area_effective * seletedProduct.dosis);
      if (!this.isKilogrameOrliter(seletedProduct)) {
        quantityPlanned = parseFloat((quantityPlanned * seletedProduct.dilution_factor) / 1000).toFixed(2);
      }
      return quantityPlanned;
    },
    calulateUnitQuantityPlanned(selectedObject, seletedProduct) {
      const unitQuantityPlanned = (typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('gr/'))
        ? 'Kg' : 'L';
      return unitQuantityPlanned;
    },
    isKilogrameOrliter(seletedProduct) {
      return typeof (seletedProduct.dosisUt) === 'undefined' || seletedProduct.dosisUt.toLowerCase().includes('kg/') || seletedProduct.dosisUt.toLowerCase().includes('l/');
    },
  },
};
</script>
