<template>
  <div>
    <DxTreeList
      id="locationsTree"
      ref="treeList"
      :data-source="locationsTreeFormated"
      :show-row-lines="true"
      :show-borders="true"
      :column-auto-width="true"
      :auto-expand-all="false"
      key-expr="ID"
      parent-id-expr="HEAD_ID"
    >
      <DxColumn
        data-field="location"
      />
    </DxTreeList>
  </div>
</template>

<script>

import {
  DxTreeList, DxColumn,
} from 'devextreme-vue/tree-list';

export default {
  components: {
    DxTreeList, DxColumn,
  },
  props: {
    locations: { type: Array, default: () => [] },
    locationsTree: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: true },
    showExpanded: { type: Boolean, default: true },
    recursive: { type: Boolean, default: true },
  },
  data() {
    return {
      locationsTreeFormated: [],
      selectedRowKeys: [],
      expandedRowKeys: [],
      locationsSelected: [],
    };
  },
  mounted() {
    for (const item of this.locationsTree) {
      this.setLocationTreeFormat(item);
      this.recursiveAddChildrens(item.children);
    }
    this.locationsSelected = this.locations;
    this.setSelectedRowKeys();
    const auxLocationsTreeFormated = [];
    for (const location of this.locationsTreeFormated) {
      for (const expandedRowKey of this.expandedRowKeys) {
        if (location.ID === expandedRowKey) {
          auxLocationsTreeFormated.push(location);
          break;
        }
      }
    }
    this.locationsTreeFormated = auxLocationsTreeFormated;
  },
  methods: {
    setExpandedRowKeys(location) {
      let parentLocation = location;
      while (parentLocation.HEAD_ID !== null) {
        if (this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
          this.expandedRowKeys.push(parentLocation.ID);
        }
        parentLocation = this.locationsTreeFormated[parentLocation.HEAD_ID - 1];
      }
      if (this.expandedRowKeys.indexOf(parentLocation.ID) === -1) {
        this.expandedRowKeys.push(parentLocation.ID);
      }
    },
    recursiveSelectedKeys(location) {
      if (this.locations.indexOf(location.bd_id) > -1) {
        if (this.selectedRowKeys.indexOf(location.ID) === -1) {
          this.selectedRowKeys.push(location.ID);
          if (this.showExpanded) {
            this.setExpandedRowKeys(location);
          }
        }
      }
      for (let i = 0; i < location.children.length; i += 1) {
        this.recursiveSelectedKeys(location.children[i]);
      }
    },
    setSelectedRowKeys() {
      this.selectedRowKeys = [];
      for (let i = 0; i < this.locationsTreeFormated.length; i += 1) {
        this.recursiveSelectedKeys(this.locationsTreeFormated[i]);
      }
    },
    recursiveAddChildrens(childrens) {
      for (let i = 0; i < childrens.length; i += 1) {
        this.setLocationTreeFormat(childrens[i]);
        this.recursiveAddChildrens(childrens[i].children);
      }
    },
    setLocationTreeFormat(item) {
      this.locationsTreeFormated.push({
        location: item.name,
        bd_id: item.bd_id,
        ID: item.ID,
        HEAD_ID: item.HEAD_ID,
        children: item.children,
      });
    },
    saveLocations(selection) {
      this.locationsSelected = [];
      for (let i = 0; i < selection.selectedRowsData.length; i += 1) {
        this.recursiveLocationsSelected(selection.selectedRowsData[i]);
      }
      this.setActualLocationsSelected(this.locationsSelected);
    },
    recursiveLocationsSelected(locationSelected) {
      if (locationSelected.children.length === 0) {
        this.addLocationsSelected(locationSelected.bd_id);
      } else {
        for (let i = 0; i < locationSelected.children.length; i += 1) {
          this.recursiveLocationsSelected(locationSelected.children[i]);
        }
      }
    },
    addLocationsSelected(bdId) {
      if (this.locationsSelected.indexOf(bdId) === -1) {
        this.locationsSelected.push(bdId);
      }
    },
  },
};
</script>
