import db from '../../services/DexieDatabase';

export default {
  getAll() {
    return db.vademecum.toArray();
  },
  /**
   * Filtramos por cultivo para mostrar el listado de plagas posibles (añadimos a mano TIMAC)
   * @param {*} cultive
   * @returns
   */
  async getByCultive(cultive = '') {
    const agents = [];
    const vademecumList = await db.vademecum.toArray();
    if (cultive !== '') {
      vademecumList.forEach((element) => {
        for (let i = 0; i < element.cultive.length; i += 1) {
          if (element.cultive[i] === cultive) {
            agents.push({
              id: element.id,
              commercialName: element.comercial_name,
              crop: element.cultive,
              agent: element.agent,
              holder: element.owner,
              registrationNumber: element.code,
              activeProduct: element.composition,
            });
          }
        }
      });
      // agents.push('TIMAC');
    }
    return [...new Set(agents)].sort();
  },

  /**
   * Filtramos por cultivo y agente para mostrar el listado de podructos posibles
   * @param {*} cultive
   * @returns
   */
  async getByCultiveAndAgent(cultive = '', agent = '') {
    const products = [];
    const vademecumList = await db.vademecum.toArray();
    if (cultive !== '') {
      vademecumList.forEach((element) => {
        for (let i = 0; i < element.recommendations.length; i += 1) {
          if (element.recommendations[i].cultive === cultive && element.recommendations[i].agent === agent) {
            products.push({
              id: element.id,
              commercialName: element.comercial_name,
              crop: element.cultive,
              agent: element.agent,
              holder: element.owner,
              registrationNumber: element.code,
              activeProduct: element.composition,
            });
          }
        }
      });
    }
    return [...new Set(products)].sort();
  },

  async getAgentsByCultive(cultive) {
    const products = [];
    const vademecumList = await db.vademecum.toArray();

    if (cultive !== '') {
      vademecumList.forEach((element) => {
        for (let i = 0; i < element.recommendations.length; i += 1) {
          if (element.recommendations[i].cultive === cultive) {
            products.push(element.recommendations[i].agent);
          }
        }
      });
    }

    return [...new Set(products)].sort();
  },
  async getDetailByIdCropAndAgent(id, crop, agent) {
    let product;
    const vademecumList = await db.vademecum.toArray();

    if (id !== '') {
      vademecumList.forEach((element) => {
        for (let i = 0; i < element.cultive.length; i += 1) {
          for (let j = 0; j < element.agent.length; j += 1) {
            if (id === element.id && element.cultive[i] === crop && element.agent[j] === agent) {
              product = element;
            }
          }
        }
      });
    }

    return this.formatRecommendationsSingleProduct(product, crop, agent);
  },
  async getDetailsByCrop(id, crop) {
    let product;
    const vademecumList = await db.vademecum.toArray();

    if (id !== '') {
      vademecumList.forEach((element) => {
        for (let i = 0; i < element.cultive.length; i += 1) {
          if (id === element.id && element.cultive[i] === crop) {
            product = element;
          }
        }
      });
    }

    return this.formatRecommendationsSingleProduct(product, crop);
  },
  async getDetailsById(id) {
    let product;
    const vademecumList = await db.vademecum.toArray();

    if (id !== '') {
      vademecumList.forEach((element) => {
        if (id === element.id) {
          product = element;
        }
      });
    }

    return product;
  },
  formatRecommendationsSingleProduct(product, crop, agent) {
    const newRecommendations = [];

    if (product && crop && agent) {
      product.recommendations.forEach((element) => {
        if (element.agent === agent && element.cultive === crop) {
          newRecommendations.push(element);
        }
      });
    } else if (product && crop) {
      product.recommendations.forEach((element) => {
        if (element.cultive === crop) {
          newRecommendations.push(element);
        }
      });
    }

    product.recommendations = newRecommendations;
    return product;
  },
  async getCultives() {
    const cultives = [];
    const vademecumList = await db.vademecum.toArray();

    vademecumList.forEach((element) => {
      for (let i = 0; i < element.cultive.length; i += 1) {
        if (cultives.indexOf(element.cultive[i]) === -1) {
          cultives.push(element.cultive[i]);
        }
      }
    });

    return cultives.sort();
  },
};
