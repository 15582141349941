<template>
  <f7-block>
    <GISLayerOptions
      :key-id="`${mainMap}`"
    />
    <f7-row>
      <f7-col width="100">
        <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}`] === ''">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-block v-else>
          <GISWMSTimeDimensionMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            :layer="layer"
            :index="currentIndexes[`${mainMap}`]"
            :center="center"
            :height="'650px'"
          />
        </f7-block>
      </f7-col>
      <!-- TODO: Se oculta hasta que la linea de tiempo esté integrada en back
      <f7-col
        width="50"
      >
        <f7-block v-if="!timelineIndexes">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <f7-row v-else>
          <TimelineGraph
            :timeline-indexes="timelineIndexes"
            :index="currentIndexes.GisTimeDimension"
          />
        </f7-row>
      </f7-col>
      -->
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import GISWMSTimeDimensionMap from '../../../Gis/GISWMSTimeDimensionMap/index.vue';
import GISLayerOptions from '../../../Gis/LayerOptions/index.vue';
import TimelineGraph from '../timelineGraph/index.vue';
import TimelineTasksGrid from '../timelineTasksGrid/index.vue';
import Api from '../../../../services/Api';
import EventBus from '../../../../js/event-bus';

export default {
  name: 'GisTimeDimension',
  components: {
    GISWMSTimeDimensionMap,
    GISLayerOptions,
    TimelineGraph,
    TimelineTasksGrid,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'GisTimeDimension',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerTimacagro(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      filteredTasks: null,
    };
  },
  computed: {
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'currentInitIntervalDates', 'currentEndIntervalDates', 'timelineIndexes']),
    ...mapState('TaskByCompany', ['tasks']),
  },
  async beforeMount() {
    try {
      this.setTimelineIndexes(null);
      this.setTasks(null);
      this.setCurrentIndexes({
        key: this.mainMap,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key: this.mainMap,
        newCurrentInitIntervalDate: moment(this.currentPlantation.initDate).format('YYYY-MM-DDT00:00:00'),
      });
      this.setCurrentEndIntervalDates({
        key: this.mainMap,
        newCurrentEndIntervalDate: moment().format('YYYY-MM-DDT00:00:00'),
      });
      this.layer.options.layers = `client_efemis:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      this.setIdCompanyTask(this.currentPlantation.companyId);
      await this.fetchTimelineIndexes({
        location: this.currentPlantation.sectorId, initDate: this.currentPlantation.initDate, endDate: moment().format('YYYY-MM-DD'), companyId: this.currentPlantation.companyId,
      });
      // await this.getTasks(this.currentPlantation.id);
      EventBus.$on('filteringTasks', this.filteringTasks);
      // this.filteredTasks = this.filteringTasks();
      this.loadedGIS = true;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    }
  },
  methods: {
    filteringTasks() {
      const filteredTasks = [];
      for (const task of this.tasks) {
        if (moment(task.init_date).isBetween(moment(this.currentPlantation.initDate), moment(this.currentEndIntervalDates.GisTimeDimension), 'days', '[]')) {
          filteredTasks.push(task);
        }
      }
      return filteredTasks;
    },
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchTimelineIndexes', 'setTimelineIndexes']),
    ...mapActions('TaskByCompany', ['setIdCompanyTask', 'getTasks', 'setTasks']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisTimeDimension.styles.scss';
</style>
