<template>
  <div class="content-devx">
    <f7-block class="content__barsgraph">
      <DxChart
        id="chart"
        :data-source="dataSourceFiltered"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          argument-field="date"
        />
        <DxSeries
          v-if="hasMeasuredTemperature"
          pane="top"
          :color="measureColor"
          :value-field="`avgmeasured${weatherElement}`"
          :name="`${$t('DxWeatherElementLinesGraph_total')} ${weatherElement}, ${measurement}`"
          type="bar"
          :bar-width="50"
        />
        <DxSeries
          v-if="hasForecastTemperature"
          pane="top"
          :color="forecastColor"
          :value-field="`avgforecasted${weatherElement}`"
          type="bar"
          :bar-width="50"
          :name="`${$t('DxWeatherElementLinesGraph_totalForecasted')} ${weatherElement}, ${measurement} `"
        />

        <!---INCLUIR LÓGICA DE EVAPOTRANSPIRACIÓN CUANDO ESTÉ DISPONIBLE. DE MOMENTO SE DESHABILITAN LOS EJES PUES NO TIENEN INFORMACIÓN-->
        <DxSeries
          v-if="false"
          pane="top"
          type="line"
          color="#7b3294"
          value-field="avgmeasuredEvapotranspiración"
          :name="`${$t('DxWeatherElementLinesGraph_average')} ${$t('Evaporacion')}, ${measurement}`"
        />
        <DxSeries
          v-if="false"
          pane="top"
          type="line"
          color="#7b329499"
          value-field="avgforecastedEvapotranspiración"
          :name="`${$t('DxWeatherElementLinesGraph_totalForecasted')} ${$t('Evaporacion')}, ${measurement}`"
        />

        <DxPane name="top" />
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
        >
          <DxLabel
            display-mode="stagger"
            format="d MMM yyyy - hh:mm"
          />
        </DxArgumentAxis>
        <DxAdaptiveLayout
          :width="80"
          :keep-labels="true"
        />
        <DxValueAxis pane="top">
          <DxGrid :visible="true" />
          <DxTitle :text="`${weatherElement}, ${measurement}`" />
        </DxValueAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
        <DxExport :enabled="false" />
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxTitle :text="`${weatherElement}`" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </f7-block>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
  DxAdaptiveLayout,
} from 'devextreme-vue/chart';
import i18next from 'i18next';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
    DxAdaptiveLayout,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: i18next.t('Precipitation'),
    },
  },
  data() {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      humidityMeasureColor: '#0077c8',
      humidityForecastColor: '#76c2f5',
      measurement: '',

    };
  },
  beforeMount() {
    if (typeof this.dataSource !== 'undefined' && this.dataSource.length > 0) {
      if (typeof this.dataSource[1].unitOfMessurement !== 'undefined') {
        switch (this.dataSource[1].unitOfMessurement) {
          case 'meter_second':
            this.measurement = 'M/s';
            break;
          case 'liter_m2':
            this.measurement = 'L/m2';
            break;
          case 'percentage':
            this.measurement = '%';
            break;
          case 'celsius':
            this.measurement = '°C';
            break;
          case 'wat_m2':
            this.measurement = 'Wat/m2';
            break;
          default:
            break;
        }
      }
    }
  },
  computed: {
    ...mapState('weather', [
      'selectedFarmID',
      'startDate',
      'endDate',
      'selectedMeasuringFrequency',
    ]),
    ...mapState('Reporting', ['selectedFarmList']),
    ...mapState('weatherFarm', [
      'hasMeasuredTemperature',
      'hasForecastTemperature',
    ]),
    lineWidth() {
      return this.selectedMeasuringFrequency === 'DAILY' ? 1 : 0;
    },
    weatherElementMeasure() {
      return this.weatherElement === this.$t('Precipitation') ? 'mm' : '%';
    },
    measureColor() {
      return this.weatherElement === this.$t('Precipitation') ? this.humidityMeasureColor : '';
    },
    forecastColor() {
      return this.weatherElement === this.$t('Precipitation') ? this.humidityForecastColor : '';
    },
    ...mapState('CalendarInitEndDate', ['actualFilter']),
    dataSourceFiltered() {
      return typeof this.actualFilter['searcher/weatherGraph'].init === 'undefined' || this.actualFilter['searcher/weatherGraph'].init === '' ? this.dataSource : this.dataSource.filter(
        (dataRow) => moment(dataRow.date).format('YYYY-MM-DD') <= moment(this.actualFilter['searcher/weatherGraph'].end).format('YYYY-MM-DD')
        && moment(dataRow.date).format('YYYY-MM-DD') >= moment(this.actualFilter['searcher/weatherGraph'].init).format('YYYY-MM-DD'),
      );
    },
  },

  methods: {
    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const date = moment(pointInfo.argument).locale('es').format('ddd D MMM YYYY HH:mm');

      return {
        text: `${seriesName} on ${date}: 
          ${pointInfo.value.toFixed(2)} ${this.measurement} `,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
#chart-demo {
    height: 700px;
}
#chart {
    width: 100%;
    min-width: 600px;
    height: 400px;
    margin: 0 0 15px;
}
</style>
